import { useState } from 'react';
import { useInvalidateQuery, useSession, useTagWallets } from '../../../hooks';
import { Button, Dialog, DialogContent, DialogTrigger, InputLabel, SimpleMenu, Tooltip } from 'ui';
import toast from 'react-hot-toast';
import { deriveError } from '../../templates/utils';
import { useUserObjects } from '../../../hooks/useUserObjects';

export const TagSourcesModal = ({ sourceIds, showTrigger = true, onSuccess }) => {
  const [open, onOpenChange] = useState(false);
  const { invalidateWallets } = useInvalidateQuery();
  const { mutateAsync: tagWallets, isLoading: isTaggingWallets } = useTagWallets();
  const [selectedOption, setSelectedOption] = useState<{ label: string; value: string }>();

  const { organizationId } = useSession();

  const { tags } = useUserObjects();

  const handleTagWallets = async (tagId: string) => {
    if (!organizationId) return;
    try {
      await tagWallets(
        {
          organizationId,
          walletIds: sourceIds,
          tagId,
        },
        {
          onSuccess: async () => {
            invalidateWallets();
            toast.success('Source(s) tagged successfully.');
          },
          onError: (error) => {
            console.error(error);
            toast.error(deriveError(error));
          },
        },
      );
      onSuccess && onSuccess();
    } catch (error) {
      console.error(error);
      toast.error(deriveError(error));
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      {showTrigger && (
        <DialogTrigger className='mx-4 my-0 py-0 px-0' asChild>
          <Tooltip content={`Tag`}>
            <Button
              className='h-full mr-4'
              onClick={() => onOpenChange(true)}
              label={`Tag `}
              emphasis='high'
              variant='sm'
            />
          </Tooltip>
        </DialogTrigger>
      )}
      <DialogContent
        actions={[
          {
            label: 'Confirm',
            onClick: () => handleTagWallets(selectedOption?.value as string),
            disabled: isTaggingWallets,
            isLoading: isTaggingWallets,
          },
        ]}
      >
        <div className='grid grid-cols-1 gap-4'>
          <div>
            <InputLabel tooltip='' heading='Tag sources' />
            <SimpleMenu
              options={tags.map((tag) => ({ label: `${tag.entry.key}: ${tag.entry.value}`, value: tag._id }))}
              onChange={(option) => setSelectedOption(option)}
              selectedValue={selectedOption?.value}
              isLoading={isTaggingWallets}
              placeholder='Select a tag'
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
