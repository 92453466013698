import React, { useEffect, useState } from 'react';
import SidebarHeader from '../../atoms/Sidebar/SidebarHeader/SidebarHeader';
import { useSession } from '../../../hooks/useSession';
import { useCreateLegalEntity } from '../../../hooks/http';
import {
  Button,
  Currency,
  CurrencyMenu,
  InputLabel,
  InputWithExtras,
  SingleSelectMenu,
  TextareaInput,
  currencyOptions,
} from 'ui';
import { toast } from 'react-hot-toast';
import { deriveError } from '../../templates/utils';
import { SidebarSection, SidebarSectionHeader } from '../../atoms/Sidebar/SidebarBody';
import { ENTITY_TYPES } from '../../../constants';
import { SidebarBody, SidebarFooter } from '../../atoms/Sidebar';
import { useInvalidateQuery } from '../../../hooks';

interface FormState {
  entityName: string;
  addressString: string;
  entityType: string;
  currency?: Currency;
}
const defaultFormState: FormState = { entityName: '', addressString: '', entityType: '' };

const AddLegalEntityFormForSidebar = ({ onCancel, 'data-cy': dataCy = 'addLegalEntity' }) => {
  const [formState, setFormState] = useState<FormState>(defaultFormState);
  const { mutateAsync: addLegalEntity, isLoading: isAddingLegalEntity } = useCreateLegalEntity();
  const { organizationId, userId } = useSession();

  useEffect(() => {
    setFormState((prev) => ({ ...prev, currency: currencyOptions.find((option) => option.value === 'USD') }));
  }, [currencyOptions]);

  const { invalidateLegalEntities } = useInvalidateQuery();

  const onFormSubmit = async () => {
    if (!formState.currency) return toast.error('Please select a currency');
    if (!formState.entityName) return toast.error('Please enter a name for legal entity');
    if (!userId || !organizationId) {
      console.log(
        'AddLegalEntityFormForSidebar: onFormSubmit: called before userId or organizationId were initialized',
      );
      return toast.error('Please try again after some time.');
    }
    try {
      await addLegalEntity({
        legalEntity: {
          organizationId,
          userId,
          entityName: formState.entityName,
          currency: formState.currency.value,
          entityType: formState.entityType,
          addressString: formState.addressString,
          createdBy: userId,
        },
      });

      invalidateLegalEntities();

      setFormState(defaultFormState);

      toast.success('Legal entity added successfully');
      onCancel();
    } catch (error) {
      console.log('BusinessInforForm error:', error);
      toast.error(deriveError(error));
    }
  };
  return (
    <>
      <SidebarHeader data-cy={dataCy} title={'Add legal entity'} />
      <SidebarBody>
        <SidebarSectionHeader title='Details' />
        <SidebarSection numberOfColumns={1}>
          <div>
            <InputLabel heading='Business name' />
            <InputWithExtras
              data-cy={`${dataCy}__businessName`}
              value={formState.entityName}
              onChange={(e) => setFormState((prev) => ({ ...prev, entityName: e.target.value }))}
            />
          </div>
          <div>
            <InputLabel heading='Business type' />

            <SingleSelectMenu
              fullWidth
              isOnSidepanel
              data-cy={`${dataCy}__businessType`}
              options={ENTITY_TYPES}
              placeholder='Select business type'
              onChange={({ value }: any) => {
                setFormState((prev) => ({ ...prev, entityType: value }));
              }}
              value={ENTITY_TYPES.find((option) => option.value === formState.entityType)}
              onClearValue={() => {
                setFormState((prev) => ({
                  ...prev,
                  entityType: '',
                }));
              }}
            />
          </div>
          <div>
            <InputLabel heading='Business address' />
            <TextareaInput
              data-cy={`${dataCy}__businessAddress`}
              value={formState.addressString}
              onChange={(e) => setFormState((prev) => ({ ...prev, addressString: e.target.value }))}
            />
          </div>
          <div>
            <InputLabel heading='Currency' />
            <CurrencyMenu
              data-cy={`${dataCy}__currency`}
              options={currencyOptions}
              value={formState.currency}
              onChange={(currency) => setFormState((prev) => ({ ...prev, currency }))}
            />
          </div>
        </SidebarSection>
      </SidebarBody>
      <SidebarFooter
        primaryBtn={
          <Button
            data-cy={`${dataCy}__saveButton`}
            onClick={onFormSubmit}
            isLoading={isAddingLegalEntity}
            label={'Save'}
          />
        }
        secondaryBtn={<Button onClick={onCancel} emphasis='medium' label='Cancel' />}
      />
    </>
  );
};

export default AddLegalEntityFormForSidebar;
