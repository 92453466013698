import { capitalizeFirstLetter } from '../Nav/utils';
import currencyInfo from '../../mocks/CurrencyInfo.json';
import Router, { useRouter } from 'next/router';
import { loginUser, registerUser } from 'services/http/auth';
import { deriveError, formatAccountingPeriodDate, formatDollars, getCreditOrDebitNormal } from '../templates/utils';
import bigDecimal from 'js-big-decimal';
import { toast } from 'react-hot-toast';
import { formatDistance } from 'date-fns';
import { parse } from 'date-fns';
import { classNames } from 'ui';
import Image from '../image-with-fallback';
import { TableHeaderProps } from '../dashboard/types';
import { AccountingPeriod, ReportResponse } from 'services/http/response.types';
import {
  DateProps,
  MapTableKeyToOriginalKeyType,
  ReportTableRowDataObject,
  GetUpdateTabSidebarOptions,
  NestedObject,
  CryptoIconType,
} from './types';
import { LedgerAccount } from 'schemas';
import { FormState } from '../templates/types';
import { FormatLedgerAccountTableDataType, LedgerAccountForm, MergedLedgerAccountType } from '../ledger-account/types';
import {
  CONFIGURE_PAGE_SECONDARY_NAVIGATION,
  LEDGER_PAGE_SECONDARY_NAVIGATION,
  ORGANIZATION_SETTINGS_SECONDARY_NAVIGATION,
  PROFILE_PAGE_SECONDARY_NAVIGATION,
  REPORT_PAGE_SECONDARY_NAVIGATION,
} from '../../constants';
import { SidebarRoutes } from '../Sidebar/SidebarGlobal';
import { JournalEntryLinePayload } from '../jorunalEntry/types';
import { checkValidity } from './valid-contracts';

const CRYPTO_ICON_HOSTING_URL = 'https://ef-crypto-icons.s3.us-east-1.amazonaws.com/crypto-currency-icons';

export const currencyNameHandler = ({ symbol_native, code, name_plural }) => {
  return `${symbol_native}${code} (${currencyNameFormatter(name_plural)})`;
};

export const getFormattedCurrencyName = (currencyName: string) => {
  const currencyDetails = Object.values(currencyInfo).find(
    ({ code }) => code?.toLowerCase() === currencyName?.toLowerCase(),
  );
  if (currencyDetails) {
    const { symbol_native, code, name_plural } = currencyDetails;
    return `${symbol_native}${code} (${currencyNameFormatter(name_plural)})`;
  }
  return '';
};

export const currencyImg = (imgName: string, contract?: string) => {
  try {
    if (contract) {
      const valid = checkValidity(contract, imgName);
      if (!valid) return '';
    }
    let icon = '';
    imgName = imgName.toLowerCase();
    if (imgName === 'kraken') {
      icon = '/kraken.png';
    } else if (imgName === 'polygon') {
      icon = `${CRYPTO_ICON_HOSTING_URL}/matic.png?raw=true`;
    } else if (imgName === 'ausdc') {
      icon = `${CRYPTO_ICON_HOSTING_URL}/aave.png?raw=true`;
    } else if (imgName === 'usdc') {
      icon = `${CRYPTO_ICON_HOSTING_URL}/usdc.png?raw=true`;
    } else if (imgName === 'sol') {
      icon = `https://s2.coinmarketcap.com/static/img/coins/64x64/5426.png`;
    } else if (imgName === 'dust') {
      icon =
        'https://github.com/solana-labs/token-list/blob/main/assets/mainnet/DUSTawucrTsGU8hcqRdHDCbuYhCPADMLM2VcCb8VnFnQ/logo.jpg?raw=true';
    } else if (imgName === 'bsc') {
      icon = `${CRYPTO_ICON_HOSTING_URL}/bnb.png?raw=true`;
    } else if (imgName === 'near') {
      icon = `https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/near-protocol.png?raw=true`;
    } else if (imgName === 'sweat') {
      icon = 'https://s2.coinmarketcap.com/static/img/coins/64x64/21351.png?raw=true';
    } else if (imgName === 'weth') {
      icon = 'https://github.com/spothq/cryptocurrency-icons/blob/master/32/color/eth.png?raw=true';
    } else if (imgName === 'avalanche') {
      icon = 'https://ef-crypto-icons.s3.us-east-1.amazonaws.com/crypto-currency-icons/avax.png';
    } else if (imgName === 'arb') {
      icon = 'https://cryptologos.cc/logos/arbitrum-arb-logo.png?v=025';
    } else if (imgName === 'opt') {
      icon = 'https://cryptologos.cc/logos/optimism-ethereum-op-logo.png?v=025';
    } else {
      icon = `${CRYPTO_ICON_HOSTING_URL}/${imgName}.png?raw=true`;
    }
    return icon;
  } catch (error) {
    return '';
  }
};

export const CryptoIcon = ({
  symbol = '',
  alt = '',
  className = '',
  size = '',
  rawContractAddress = '',
}: CryptoIconType): React.JSX.Element => {
  const src = currencyImg(symbol, rawContractAddress);
  if (!src) return <></>;

  return (
    <Image
      src={src}
      fallbackImage='/images/gray-bg.png'
      className={classNames('rounded-[100%]', className, size == 'sm' && 'h-5 w-5')}
      width={size === 'sm' ? 24 : 32}
      height={size === 'sm' ? 24 : 32}
      alt={`${alt ?? symbol}`}
    />
  );
};

export const dateConverter = (date: DateProps) => {
  if (new Date(date).getFullYear() !== new Date().getFullYear()) {
    return new Date(date).toLocaleDateString('en', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: false,
    });
  } else {
    return shortDateConverter(date);
  }
};

export const shortDateConverter = (date: DateProps) => {
  return new Date(date).toLocaleDateString('en', {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false,
  });
};

export const handleGainLoss = (gainLossPercentage: number) => {
  if (gainLossPercentage > 0) {
    return {
      value: `+${gainLossPercentage}%`,
      color: 'text-[#008000]',
    };
  } else if (gainLossPercentage < 0) {
    return {
      value: `${gainLossPercentage}%`,
      color: 'text-[#FF0000]',
    };
  } else if (gainLossPercentage === 0) {
    return {
      value: `+${gainLossPercentage}%`,
      color: 'text-[#111111]',
    };
  }
  return {
    value: '',
    color: '',
  };
};

export const currencyNameFormatter = (currencyName: string) => {
  const format = currencyName
    .split(' ')
    .map((item, index) => {
      if (!index) {
        return item.split('').join('.');
      } else {
        return capitalizeFirstLetter(item);
      }
    })
    .join('.');
  return format;
};

export const getCurrencyInfo = (currencyName: string) => {
  return currencyInfo[currencyName];
};

export function navigate(url: string) {
  Router.push(url);
}

export const fixedTableColumn = {
  right: (type, index, headerGroupLength) => {
    if (type === 'fixedRight') {
      index = headerGroupLength - index - 1;
      let allWidth = 0;
      for (let i = index; i > 0; i--) {
        allWidth += document.querySelectorAll('.fixedRight')[i]?.getBoundingClientRect().width;
      }
      return allWidth;
    }
  },
  left: (type, index) => {
    if (type === 'fixedLeft') {
      let allWidth = 0;
      for (let i = 0; i < index; i++) {
        allWidth += document.querySelectorAll('.fixedLeft')[i]?.getBoundingClientRect().width;
      }
      return allWidth;
    }
  },
};

export const signupFunction = async (setLoading, setUser, setToast, tempUser) => {
  setLoading(true);
  try {
    const response = await registerUser({
      user: tempUser,
    });
    const { user: newUser, organizations } = response.data;
    setUser({ ...newUser, organizations });
    setLoading(false);
    navigate('/onboarding/entities');
  } catch (error) {
    setToast({
      title: 'Error',
      desc: deriveError(error),
      duration: 3000,
      open: true,
      variant: 'Error',
    });
  }
  setLoading(false);
};

export const loginFunction = async (setLoading, setUser, setToast, tempUser) => {
  setLoading(true);
  try {
    const response = await loginUser({ user: tempUser });
    const { user: newUser, organizations } = response.data;
    setUser({ ...newUser, organizations });
    setLoading(false);
    navigate('/overview');
  } catch (error: any) {
    setLoading(false);
    setToast({
      title: 'Error',
      desc: deriveError(error),
      duration: 3000,
      open: true,
      variant: 'Error',
    });
  }
};

function createRow(tableData, object) {
  const childArray = tableData
    .filter((account) => account.parentLedgerAccountId === object._id && object._id !== account._id)
    .map((account) => createRow(tableData, account));
  object.subRows = childArray;
  return object;
}

export const formatLedgerAccountTableData = (mergedLedgerAccounts: MergedLedgerAccountType[]) => {
  const displayedRes = mergedLedgerAccounts.map((ledgerAccount) => {
    const created = {
      title: ledgerAccount?.userId?.email,
      desc: dateConverter(ledgerAccount?.createdAt ?? ''),
    };
    const account = `${ledgerAccount?.ledgerAccountSequence}: ${ledgerAccount?.ledgerAccountName}`;
    return {
      ...ledgerAccount,
      created,
      account,
    };
  });

  const formattedTableData = displayedRes
    .filter((account) => !account.parentLedgerAccountId)
    .map((account) => createRow(displayedRes, account));

  return formattedTableData;
};

export const formatLedgerAccountResequencingTable = (
  ledgerAccounts: (LedgerAccount & { oldSequence?: number; newSequence?: number })[],
) => {
  const displayedRes = ledgerAccounts.map((ledgerAccount) => {
    return {
      ...ledgerAccount,
      account: ledgerAccount?.ledgerAccountName,
      'Account type': ledgerAccount?.ledgerAccountType,
      oldSequence: ledgerAccount?.oldSequence,
      newSequence: ledgerAccount?.newSequence || ledgerAccount?.ledgerAccountSequence,
    };
  });

  const formattedTableData = displayedRes
    .filter((account) => !account.parentLedgerAccountId)
    .map((account) => createRow(displayedRes, account));

  return formattedTableData;
};

export const getSelectedLedgerAccount = (
  data: FormatLedgerAccountTableDataType[],
  id: string,
): FormatLedgerAccountTableDataType | undefined => {
  for (const item of data) {
    if (item._id === id) {
      return item;
    }
    if (item.subRows && item.subRows.length > 0) {
      const foundItem = getSelectedLedgerAccount(item.subRows, id);
      if (foundItem) {
        return foundItem;
      }
    }
  }
  return undefined;
};

function getDataChildren(
  id: string,
  jsonData: { incomeStatement?: ReportResponse },
  headers: TableHeaderProps,
  accountingPeriods: AccountingPeriod[],
  onBalanceClick: (balanceOverviewSidebarProps) => void,
) {
  const childArray: ReportTableRowDataObject[] = [];
  if (jsonData.incomeStatement) {
    for (const { parentLedgerAccountId, ledgerAccountSequence, ledgerAccountName, _id } of jsonData.incomeStatement
      .ledgerAccounts) {
      if (parentLedgerAccountId === id) {
        childArray.push(
          handleReportTableCreateRow(
            {
              income: `${ledgerAccountSequence}: ${ledgerAccountName}`,
              _id,
            },
            _id,
            jsonData,
            headers,
            accountingPeriods,
            onBalanceClick,
          )?.object as ReportTableRowDataObject,
        );
      }
    }
  }
  return childArray;
}

export const getChildrenLedgerAccountIdsFromReport = (report: ReportResponse, parentLedgerAccountId: string) => {
  return report.ledgerAccounts
    .filter((account) => account.parentLedgerAccountId === parentLedgerAccountId)
    .map((la) => la._id);
};

export const handleReportTableCreateRow = (
  object: ReportTableRowDataObject,
  id: string,
  jsonData: { incomeStatement?: ReportResponse },
  headers: TableHeaderProps,
  accountingPeriods: AccountingPeriod[],
  onBalanceClick: (balanceOverviewSidebarProps) => void,
) => {
  if (jsonData?.incomeStatement) {
    const report = jsonData.incomeStatement;
    const { accountingPeriodIds, balances } = jsonData.incomeStatement;
    for (const accountingPeriodId of accountingPeriodIds) {
      const accountingPeriod = accountingPeriods?.find((item) => item._id === accountingPeriodId);
      if (!accountingPeriod) continue;
      const { accountingPeriodName, _id, startDate, endDate, status } = accountingPeriod;
      const periodName = accountingPeriodName ? accountingPeriodName : formatAccountingPeriodDate(startDate);
      if (_id) {
        const debitsAndCreditsPerLedgerAccount = balances[_id]?.debitsAndCreditsPerLedgerAccount;
        if (!debitsAndCreditsPerLedgerAccount) continue;
        for (const balance of debitsAndCreditsPerLedgerAccount) {
          // console.log(balance);
          if (balance?.ledgerAccountId === id) {
            if (!headers.find((item) => item.key === periodName)) {
              headers.push({
                header: capitalizeFirstLetter(periodName.toLowerCase()),
                key: periodName,
                textAligned: 'text-right',
              });
            }
            const { credits, debits } = balance;
            const ledgerAccount = jsonData.incomeStatement.ledgerAccounts.find((item) => item._id === id);
            object[periodName] = (
              <button
                onClick={() =>
                  onBalanceClick({
                    accountingPeriodId: _id,
                    accountingPeriodName: periodName,
                    accountingPeriodStatus: status,
                    startDate,
                    endDate,
                    ledgerAccountSequence: ledgerAccount?.ledgerAccountSequence,
                    ledgerAccountName: ledgerAccount?.ledgerAccountName,
                    ledgerAccountId: balance.ledgerAccountId,
                    ledgerAccountIds: [
                      balance.ledgerAccountId,
                      ...getChildrenLedgerAccountIdsFromReport(report, balance.ledgerAccountId),
                    ],
                    startingBalance: formatDollars(Number(balance.openingBalance.value)),
                    currentBalance: formatDollars(Number(balance.currentBalance.value)),
                    endingBalance: formatDollars(
                      getCreditOrDebitNormal(ledgerAccount) === 'CREDIT'
                        ? parseFloat(bigDecimal.subtract(credits.value, debits.value))
                        : parseFloat(bigDecimal.subtract(debits.value, credits.value)),
                    ),
                  })
                }
                className='hover:underline'
              >
                {formatDollars(
                  getCreditOrDebitNormal(ledgerAccount) === 'CREDIT'
                    ? parseFloat(bigDecimal.subtract(credits.value, debits.value))
                    : parseFloat(bigDecimal.subtract(debits.value, credits.value)),
                )}
              </button>
            );
          }
        }
      }
    }

    const sortedHeaders = headers.sort(
      (a, b) => parse(a.header, 'MMM yyyy', new Date()).getTime() - parse(b.header, 'MMM yyyy', new Date()).getTime(),
    );

    object.subRows = getDataChildren(id, jsonData, sortedHeaders, accountingPeriods, onBalanceClick);

    return {
      object,
      headers: sortedHeaders,
    };
  }
};

export const objectKeyChecker = (obj: object, key: string = ''): boolean => {
  return Object.prototype.hasOwnProperty.call(obj, key);
};

export const addTemplateLineValidation = (templateLine: FormState): boolean => {
  if (!templateLine.legalEntityId && !templateLine.useTransactionLegalEntity) {
    toast.error('Legal Entity is required');
    return false;
  } else if (!templateLine.ledgerAccountId) {
    toast.error('Ledger Account is required');
    return false;
  } else if (!templateLine.amountType) {
    toast.error('Amount type is required');
    return false;
  } else if (!templateLine?.allocation) {
    toast.error('Allocation % is required enter an amount');
    return false;
  } else {
    return true;
  }
};

export const jeLineValidation = (jeLine?: JournalEntryLinePayload) => {
  if (!jeLine?.legalEntityId) {
    toast.error('Legal Entity is required');
    return false;
  } else if (!jeLine?.ledgerAccountId) {
    toast.error('Ledger Account is required');
    return false;
  } else if (!jeLine?.creditOrDebit) {
    toast.error('Credit or Debit is required');
    return false;
  } else if (!jeLine?.amount) {
    toast.error('Amount is required');
    return false;
  } else {
    return true;
  }
};

export const addLedgerAccountValidation = (form: LedgerAccountForm, error: any) => {
  toast.error(deriveError(error));
};

export const fromNow = (timstamp: DateProps) => formatDistance(new Date(timstamp), new Date(), { addSuffix: true });

export const scrollToTop = () => {
  const scrollToTop = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    if (scrollTop > 0) {
      window.requestAnimationFrame(scrollToTop);
      window.scrollTo(0, scrollTop - scrollTop / 8);
    }
  };

  scrollToTop();
};

export const scrollToElementById = (id: string) => {
  // get element by id
  const element = document.getElementById(id);
  scrollTo({ top: element?.offsetTop, behavior: 'smooth' });
};

export const pageLayoutNavHandler = () => {
  const { pathname } = useRouter();
  const EXTRACT_THE_FIRST_PATHNAME = pathname.split('/')[1]?.toLowerCase() ?? '';
  switch (EXTRACT_THE_FIRST_PATHNAME) {
    case 'ledger':
      return LEDGER_PAGE_SECONDARY_NAVIGATION;
    case 'configure':
      return CONFIGURE_PAGE_SECONDARY_NAVIGATION;
    case 'reports':
      return REPORT_PAGE_SECONDARY_NAVIGATION;
    case 'profile':
      return PROFILE_PAGE_SECONDARY_NAVIGATION;
    case 'organization-settings':
      return ORGANIZATION_SETTINGS_SECONDARY_NAVIGATION;
  }
};

export const mapTableKeyToOriginalKey = (tableKey: string, type: MapTableKeyToOriginalKeyType) => {
  if (type === 'Transactions') {
    switch (tableKey) {
      case 'grossAmount':
        return 'grossPrice';
      case 'netAmount':
        return 'netPrice';
      case 'transactionAssets':
        return 'assetType';
      case 'fee':
        return 'feePrice';
      case 'sequenceNumber':
        return 'createdAt';
      default:
        return tableKey;
    }
  } else if (type === 'Sources') {
    switch (tableKey) {
      case 'sourceInfo':
        return 'name';
      case 'created':
        return 'createdAt';
      default:
        return tableKey;
    }
  } else if (type === 'Journals') {
    switch (tableKey) {
      case 'journalId':
        return 'createdAt';
      case 'created':
        return 'createdAt';
      default:
        return tableKey;
    }
  } else if (type === 'Assets') {
    switch (tableKey) {
      case 'currentValue_gainLossPercentage':
        return 'currentValue';
      case 'quantity_remainingQuantity':
        return 'remainingQuantity';
      default:
        return tableKey;
    }
  } else if (type === 'Templates') {
    switch (tableKey) {
      case 'created':
        return 'createdAt';
      default:
        return tableKey;
    }
  } else if (type === 'Rulesets') {
    switch (tableKey) {
      case 'created':
        return 'createdAt';
      case 'updated':
        return 'updatedAt';
      default:
        return tableKey;
    }
  } else if (type === 'Impairment') {
    switch (tableKey) {
      case 'created':
        return 'createdAt';
      case 'updated':
        return 'updatedAt';
      default:
        return tableKey;
    }
  } else if (type === 'Ledger Account') {
    switch (tableKey) {
      case 'created':
        return 'createdAt';
      case 'account':
        return 'ledgerAccountSequence';
      default:
        return tableKey;
    }
  } else if (type === 'Entities') {
    switch (tableKey) {
      case 'created':
        return 'createdAt';
      case 'updated':
        return 'updatedAt';
      default:
        return tableKey;
    }
  } else if (type === 'Tags') {
    switch (tableKey) {
      case 'created':
        return 'createdAt';
      case 'tag':
        return 'entry.key';
      case 'usage':
        return 'usageCount';
      default:
        return tableKey;
    }
  }
  return '';
};

export function getUpdatedTabSidebarObject<T extends NestedObject>({
  sidebarState,
  keys = [''],
  fullUpdate = false,
  ...newItem
}: GetUpdateTabSidebarOptions<T>) {
  const clonedObject = structuredClone(sidebarState);
  let current: NestedObject = clonedObject;

  for (let i = 0; i < keys.length - 1; i++) {
    const key = keys[i];
    if (Object.prototype.hasOwnProperty.call(current, key) && typeof current[key] === 'object') {
      current = current[key];
    } else {
      current[key] = {};
      current = current[key];
    }
  }

  const lastKey = keys[keys.length - 1];

  current[lastKey] = fullUpdate ? { ...newItem } : { ...current[lastKey], ...newItem };

  return clonedObject;
}

export const updateRouteStackedDefaultState = ({ sidebarState, updateTabSidebarState, ...defaultState }) => {
  const updatedSidebarState = structuredClone(sidebarState);
  const lastSecondRouteStackItemIndex = updatedSidebarState.secondRouteStack?.length - 1;
  if (lastSecondRouteStackItemIndex >= 0 && !!updatedSidebarState.secondRouteStack.length) {
    updatedSidebarState.secondRouteStack[lastSecondRouteStackItemIndex] = {
      ...updatedSidebarState.secondRouteStack[lastSecondRouteStackItemIndex],
      defaultState: {
        ...updatedSidebarState.secondRouteStack[lastSecondRouteStackItemIndex].defaultState,
        ...defaultState,
      },
    };
    updateTabSidebarState({ secondRouteStack: updatedSidebarState.secondRouteStack });
  }
};

export const getPath = (route: SidebarRoutes | string | undefined, id: string) => {
  switch (route) {
    case 'transactions':
      return `/ledger/transactions/${id}`;
    case 'sources':
      return `/ledger/sources/${id}`;
    case 'journals':
      return `/ledger/journals/${id}`;
    case 'assets':
      return `/ledger/assets/${id}`;
    case 'templates':
      return `/configure/templates/${id}`;
    case 'rulesets':
      return `/configure/rulesets/${id}`;
    case 'tags':
      return `/configure/tags/${id}`;
    case 'impairment-rules':
      return `/configure/impairment-rules/${id}`;
    case 'entities':
      return `/configure/entities/${id}`;
    case 'ledger-accounts':
      return `/configure/ledger-accounts/${id}`;
  }
};

export const extractPageIdFromObjectByUrl = (url, obj) => {
  const regex = /\/([^/]+)(?:\/[^/]+)?\/\[(.+)\]/;
  const match = url.match(regex);
  if (match && match[2] && obj[match[2]]) {
    return obj[match[2]];
  }
  return '';
};

export const getUrlWithoutId = (url: string) => {
  // /ledger/transactions/65014916affd4a03538ddd4f --> /ledger/transactions
  if (url.length) {
    return url?.split('/').slice(0, 3).join('/');
  }
  return '';
};

const StringContainsURLRegex = new RegExp(
  '([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?',
);

export const containsUrl = (str: string) => {
  return StringContainsURLRegex.test(str);
};
