import React from 'react';
import { SidebarAnim, SidebarAnimationParent } from '../../atoms';
import CreateTag from '../../CreateTag';
import EditDeleteTag from '../../EditDeleteTag';

export const TagsSidebar = ({ showEdit, setShowEdit, selectedTag, setSelectedTag, isMe }) => {
  return (
    <>
      <SidebarAnimationParent>
        {showEdit && (
          <SidebarAnim data-cy='tags__createTag'>
            <CreateTag
              sidebarId='create-tag-model'
              onCancel={() => {
                setShowEdit(false);
              }}
            />
          </SidebarAnim>
        )}
      </SidebarAnimationParent>
      <SidebarAnimationParent>
        {selectedTag && (
          <SidebarAnim data-cy='tags__editTag'>
            <EditDeleteTag
              onCancel={() => {
                setSelectedTag(undefined);
              }}
              selectedTag={selectedTag}
              isMe={isMe}
            />
          </SidebarAnim>
        )}
      </SidebarAnimationParent>
    </>
  );
};

export default TagsSidebar;
