import React from 'react';
import { useBulkDeleteLegalEntity, useBulkArchiveLegalEntity } from '../../hooks/http';
import { deriveError } from '../templates/utils';
import { Button } from 'ui';
import { toast } from 'react-hot-toast';
import { useSession } from '../../hooks/useSession';
import { useInvalidateQuery } from '../../hooks';

export function LegalEntityMultiSelectActionsComponent({ selectedRows, table }) {
  const legalEntityIds = selectedRows?.map((item) => item?.original?._id);
  const { mutateAsync: bulkDeleteLegalEntity, isLoading: isDeleting } = useBulkDeleteLegalEntity();
  const { mutateAsync: bulkArchiveLegalEntity, isLoading: isArchiving } = useBulkArchiveLegalEntity();

  const { invalidateLegalEntities } = useInvalidateQuery();

  const { organizationId } = useSession();

  const handleBulkDeleteLegalEntity = async () => {
    try {
      await bulkDeleteLegalEntity(
        {
          organizationId,
          legalEntityIds,
        },
        {
          onSuccess: async () => {
            invalidateLegalEntities();
            toast.success('Legal entity(ies) deleted successfully');
          },
          onError: (error) => {
            console.error(error);

            toast.error(deriveError(error));
          },
        },
      );
      table.resetRowSelection();
    } catch (error) {
      console.error(error);

      toast.error(deriveError(error));
    }
  };

  const handleBulkArchiveLegalEntity = async () => {
    try {
      await bulkArchiveLegalEntity(
        {
          organizationId,
          legalEntityIds,
        },
        {
          onSuccess: async () => {
            invalidateLegalEntities();
            toast.success('Legal Entity(ies) archived successfully.');
          },
          onError: (error) => {
            console.error(error);
            toast.error(deriveError(error));
          },
        },
      );
      table.resetRowSelection();
    } catch (error) {
      console.error(error);
      toast.error(deriveError(error));
    }
  };

  return (
    <div className='flex ml-auto gap-3'>
      <Button
        emphasis='medium'
        status='danger'
        onClick={handleBulkDeleteLegalEntity}
        isLoading={isDeleting}
        label='Delete'
      />
      <Button onClick={handleBulkArchiveLegalEntity} isLoading={isArchiving} label='Archive' />
    </div>
  );
}
