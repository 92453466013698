import { GPTServiceIntentEnum, transformGptInstruction } from 'services';
import { invalidateQueriesBasedOnIntent } from '../../components/CommandPallete/utils';
import { useCommandPallete } from '../../components/CommandPallete/useCommandPallete';
import { useGPT } from '../http/useGPT';
import { useKBar } from 'farish-kbar';
import { useInvalidateQuery } from '../useInvalidateQuery';
import { useSession } from '../useSession';
import { useEffect, useState } from 'react';

export const useAskAI = () => {
  const { setGptChatResponses, selectedIntent, setSelectedIntent, setIsLoading } = useCommandPallete();
  const { query } = useKBar();
  const [instruction, setInstruction] = useState('');

  const { mutateAsync: askGPT, isLoading } = useGPT();
  const invalidateQueries = useInvalidateQuery();
  const { organizationId, userId } = useSession();
  const handleAskAI = async () => {
    try {
      const { rawInstruction } = transformGptInstruction(instruction, selectedIntent);
      query.getInput().value = rawInstruction;
      const res = await askGPT({ organizationId, userId, instruction, selectedIntent });
      if (res.status === 200) {
        invalidateQueriesBasedOnIntent(
          res.data.fullGptResponse.intent ?? GPTServiceIntentEnum.INVALID_INTENT,
          invalidateQueries,
        );
        setGptChatResponses((prev) => [...prev, { ...res.data.fullGptResponse, query: rawInstruction }]);
      }
      setInstruction('');
    } catch (err) {
      console.log(err);
    }
    setSelectedIntent(null);
  };
  useEffect(() => {
    setIsLoading(isLoading); // make the parent component aware of the loading state
  }, [isLoading]);
  return { handleAskAI, isLoading, instruction, setInstruction };
};
