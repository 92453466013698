import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import {
  createAccountPostingRuleSet,
  deleteAccountPostingRuleSet,
  getAccountPostingRuleSetById,
  getAccountPostingRuleSets,
  patchAccountPostingRuleSet,
  runManualTransactionHandler,
  runSpecificRuleSetManualTransactionHandler,
  getJobOrganizationConfig,
  upsertJobOrganizationConfig,
  RunSpecificRuleSetManualTransactionHandlerPayload,
  RunAllRulesetsManualTransactionHandlerPayload,
  GetScheduledRulesetJobsParams,
  getScheduledRulesetJobs,
  bulkMoveAccountPostingRules,
  bulkDuplicateAccountPostingRules,
  bulkRemoveAccountPostingRules,
} from 'services';

import { ColumnSort } from '@tanstack/react-table';
import { useSession } from '../useSession';
import { QUERY_KEY, useOrgBasedQueryKey } from '../useInvalidateQuery';

export const useAccountPostingRuleSets = (params?: { sort?: ColumnSort; pageSize?: number; searchTerm?: string }) => {
  const { organizationId } = useSession();
  const sort = params?.sort ?? { id: 'createdAt', desc: true };
  const searchTerm = params?.searchTerm;

  const getKey = useOrgBasedQueryKey();

  return useInfiniteQuery(
    getKey(QUERY_KEY.ACCOUNT_POSTING_RULESETS, { sort, searchTerm }),
    async ({ pageParam = 0 }) => {
      const response = await getAccountPostingRuleSets({
        organizationId,
        page: pageParam,
        pageSize: params?.pageSize ?? 100,
        sort,
        searchTerm,
      });
      return response.data;
    },
    {
      getPreviousPageParam: (firstPage) => firstPage.prevPage ?? undefined,
      getNextPageParam: (lastPage) => lastPage.nextPage,
      enabled: !!organizationId,
      refetchOnWindowFocus: false,
      staleTime: 300000,
    },
  );
};

export const useCreateAccountPostingRuleSet = () => useMutation((data: any) => createAccountPostingRuleSet(data));

export const usePatchAccountPostingRuleSet = () => useMutation((data) => patchAccountPostingRuleSet(data));
export const useBulkMoveAccountPostingRules = () =>
  useMutation((data: { ruleset: string; rules: string[] }) => bulkMoveAccountPostingRules(data));
export const useBulkDuplicateAccountPostingRules = () =>
  useMutation((data: { ruleset: string; rules: string[] }) => bulkDuplicateAccountPostingRules(data));
export const useBulkRemoveAccountPostingRules = () =>
  useMutation((data: { ruleset: string; rules: string[] }) => bulkRemoveAccountPostingRules(data));

export const useDeleteAccountPostingRuleSet = () => useMutation((data: any) => deleteAccountPostingRuleSet(data));

export type GetAccountPostingRuleSetByIdParams = {
  accountPostingRuleSetId: string;
};

export const useGetAccountPostingRuleSetById = (data: GetAccountPostingRuleSetByIdParams) => {
  const { organizationId } = useSession();
  const getKey = useOrgBasedQueryKey();

  return useQuery(
    getKey(QUERY_KEY.ACCOUNT_POSTING_RULESETS, { accountPostingRulesetId: data.accountPostingRuleSetId }),
    async () => {
      const response = await getAccountPostingRuleSetById(data);
      const { accountPostingRuleSet } = response.data;
      return accountPostingRuleSet;
    },
    {
      enabled: !!data.accountPostingRuleSetId && !!organizationId,
      refetchOnWindowFocus: false,
      staleTime: 300000,
    },
  );
};
export const useTriggerManualTransactionHandler = () =>
  useMutation((data: RunAllRulesetsManualTransactionHandlerPayload) => runManualTransactionHandler(data));

export const useTriggerSpecificRulesetManualTransactionHandler = () =>
  useMutation((data: RunSpecificRuleSetManualTransactionHandlerPayload) =>
    runSpecificRuleSetManualTransactionHandler(data),
  );

export const useUpsertOrganizationRulesetConfig = () =>
  useMutation((data: { organizationId: string; cronExpression?: string }) =>
    upsertJobOrganizationConfig({ ...data, jobType: 'RULESET' }),
  );

export const useGetOrganizationRulesetConfig = () => {
  const { organizationId } = useSession();
  const getKey = useOrgBasedQueryKey();

  return useQuery(
    getKey(QUERY_KEY.ACCOUNT_POSTING_RULESET_CONFIGS),
    async () => {
      const response = await getJobOrganizationConfig({ organizationId, jobType: 'RULESET' });
      const { jobConfig } = response.data;
      return jobConfig;
    },
    {
      enabled: !!organizationId,
    },
  );
};

export const useGetScheduledRulesetJobsQuery = (params: GetScheduledRulesetJobsParams) => {
  const { organizationId } = useSession();
  const getKey = useOrgBasedQueryKey();
  return useInfiniteQuery({
    queryKey: getKey(QUERY_KEY.SCHEDULED_RULESET_JOBS),
    queryFn: () => getScheduledRulesetJobs(params),
    getNextPageParam: (lastPage) => lastPage.nextCursor,
    enabled: !!organizationId,
  });
};
