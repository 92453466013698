import React, { useState } from 'react';
import * as RadixDialog from '@radix-ui/react-dialog';
import { Tooltip } from '../atoms';
import { MdArrowBack, MdClose } from 'react-icons/md';
import { Button, DESCRIPTIVE_LOADER_STATES, DescriptiveLoader, DescriptiveLoaderProps } from 'ui';
const RadixDialogContent = (props: any) => <RadixDialog.Content {...props} />;
export interface DialogProps {
  title?: string;
  description?: string;
  dialogForm?: React.ReactNode;
  buttonPrimaryCallback?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  buttonSecondaryCallback?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  buttonPrimaryText?: string;
  buttonSecondaryText?: string;
  trigger?: React.ReactNode;
  primaryButtonDisabled?: boolean;
  hideButtons?: boolean;
  open: boolean;
  onClose: () => void;
  backButtonCallback?: React.MouseEventHandler<SVGElement> | undefined;
  status?: DESCRIPTIVE_LOADER_STATES;
  descriptiveLoaderProps?: DescriptiveLoaderProps;
  showStatusIndicator?: boolean;
}

export function Dialog({
  title = 'Close accounting period',
  description = 'All accounting must be closed in chronological order from old to new.',
  dialogForm = <></>,
  buttonPrimaryCallback,
  buttonSecondaryCallback,
  buttonPrimaryText = 'Save',
  buttonSecondaryText = 'Close',
  trigger,
  primaryButtonDisabled = false,
  hideButtons = false,
  open,
  showStatusIndicator,
  status,
  descriptiveLoaderProps,
  onClose,
  backButtonCallback,
}: DialogProps) {
  const [isLoadingPrimaryButton, setIsLoadingPrimary] = useState(false);
  return (
    <RadixDialog.Root
      open={open}
      onOpenChange={(isOpen) => {
        if (!isOpen && open) onClose();
      }}
    >
      <RadixDialog.Trigger asChild data-testid='close-accounting-button'>
        {trigger}
      </RadixDialog.Trigger>

      <RadixDialog.Portal>
        <RadixDialog.Overlay className='transition-all animate-fadeOverlay fixed inset-0 h-screen w-screen bg-black opacity-50 z-modal' />
        <RadixDialogContent className='max-w-[640px] transition-all animate-fade max-h-[750px] rounded-input w-[560px] fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-modal bg-white h-fit pr-6 py-8 pl-8'>
          {!showStatusIndicator && (
            <>
              <RadixDialog.Title className='text-2xl font-semibold'>
                <div className='flex justify-between items-center'>
                  <div className='grid grid-cols-[max-content_max-content] items-center'>
                    {backButtonCallback && (
                      <Tooltip content={'Go back'}>
                        <MdArrowBack onClick={backButtonCallback} className='mr-4' />
                      </Tooltip>
                    )}

                    {title}
                  </div>

                  <Button
                    onClick={(e) => {
                      onClose();
                      buttonSecondaryCallback && buttonSecondaryCallback(e);
                    }}
                    emphasis='medium'
                    className='border-0 w-10 h-10'
                    label={<MdClose className='w-5 h-5' />}
                  />
                </div>
              </RadixDialog.Title>
              <RadixDialog.Description className='text-base mt-3 mb-6 text-[#111111]'>
                {description}
              </RadixDialog.Description>
              {dialogForm}
              {!hideButtons && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <div className='grid gap-y-4 gap-x-2 grid-flow-col mt-6'>
                    <Button
                      data-testid='dialog-secondary-button'
                      onClick={(e) => {
                        onClose();
                        buttonSecondaryCallback && buttonSecondaryCallback(e);
                      }}
                      emphasis='low'
                      label={buttonSecondaryText}
                    />
                    <Button
                      data-testid='dialog-primary-button'
                      disabled={primaryButtonDisabled}
                      onClick={async (e) => {
                        setIsLoadingPrimary(true);
                        buttonPrimaryCallback && (await buttonPrimaryCallback(e));
                        setIsLoadingPrimary(false);
                      }}
                      label={buttonPrimaryText}
                      isLoading={isLoadingPrimaryButton}
                    />
                  </div>
                </div>
              )}
            </>
          )}
          {showStatusIndicator && status === DESCRIPTIVE_LOADER_STATES.LOADING && (
            <div className='pb-8'>
              <DescriptiveLoader
                {...descriptiveLoaderProps}
                title={descriptiveLoaderProps?.title ?? 'Processing'}
                status={status}
              />
            </div>
          )}
          {showStatusIndicator && status === DESCRIPTIVE_LOADER_STATES.ERROR && (
            <div className='pb-8'>
              <DescriptiveLoader
                {...descriptiveLoaderProps}
                title={descriptiveLoaderProps?.title ?? 'Failure'}
                status={status}
              />
            </div>
          )}
          {showStatusIndicator && status === DESCRIPTIVE_LOADER_STATES.SUCCESS && (
            <div className='pb-8'>
              <DescriptiveLoader
                {...descriptiveLoaderProps}
                title={descriptiveLoaderProps?.title ?? 'Success'}
                status={status}
              />
            </div>
          )}
        </RadixDialogContent>
      </RadixDialog.Portal>
    </RadixDialog.Root>
  );
}
