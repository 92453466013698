/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext, useEffect, useLayoutEffect, useReducer, useState } from 'react';
import { JOB_NAME, LeanJobConfig } from 'services/http';
import {
  // current,
  produce,
} from 'immer';
import { AnimatePresence, motion } from 'framer-motion';
import { HiChevronDown } from 'react-icons/hi2';
import { MdClose } from 'react-icons/md';
import { TrackJobInTaskManager } from '../components/job-progress/TrackJobInTaskManager';
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket';
import { WS_URL } from 'services/config';
// import { toast } from 'react-hot-toast';
import { useJobNameBasedQueryInvalidator, useSession } from '../hooks';
import { ReadyState } from 'react-use-websocket';
import { BG_JOB_STATE } from '../components/job-progress/types';
import { useInvalidateOnJobCompletions } from '../hooks/useInvalidateOnJobCompletions';
import { useInterval } from 'usehooks-ts';

const enum ACTION_TYPE {
  START_TRACKING = 'START_TRACKING',
  STOP_TRACKING = 'STOP_TRACKING',
  CLEAR_ALL = 'CLEAR_ALL',
  UPDATE_PROGRESS = 'UPDATE_PROGRESS',
  SHOW_IN_TASK_MANAGER = 'SHOW_IN_TASK_MANAGER',
  HIDE_FROM_TASK_MANAGER = 'HIDE_FROM_TASK_MANAGER',
  HIDE_ALL_TASKS_FROM_TASK_MANAGER = 'HIDE_ALL_TASKS_FROM_TASK_MANAGER',
}

interface State {
  jobs: {
    [key: string]: {
      referenceId: string;
      jobName: JOB_NAME;
      organizationId: string;
      jobId: string;
      isVisibleInTaskManager: boolean;
    };
  };
  jobIds: string[];
  progress: {
    [key: string]: LeanJobConfig;
  };
}

const reducer = (state: State, action) => {
  // console.log('JobProgressProvider', { action });
  switch (action.type) {
    case ACTION_TYPE.START_TRACKING:
      // console.log('JobProgressProvider: START_TRACKING', action.payload);
      return produce(state, (draft) => {
        const jobs = Object.values(draft.jobs);
        const existingJob = jobs.find(
          (job) => job.referenceId === action.payload.referenceId && job.jobName === action.payload.jobName,
        );

        if (existingJob) {
          draft.jobs[existingJob.jobId].isVisibleInTaskManager = action.payload.isVisibleInTaskManager;
          delete draft.progress[existingJob.jobId];
          return;
        }
        const jobId = `${Date.now()}`;
        draft.jobs[jobId] = {
          ...action.payload,
          jobId,
        };
        draft.jobIds.push(jobId);
        // console.log('JobProgressProvider: after START_TRACKING', current(draft));
      });
    case ACTION_TYPE.STOP_TRACKING:
      return produce(state, (draft) => {
        const jobs = Object.values(draft.jobs);
        const existingJob = jobs.find(
          (job) => job.referenceId === action.payload.referenceId && job.jobName === action.payload.jobName,
        );
        if (!existingJob) return draft;
        delete draft.jobs[existingJob.jobId];
        delete draft.progress[existingJob.jobId];
        draft.jobIds = draft.jobIds.filter((jobId) => jobId !== existingJob.jobId);
      });

    case ACTION_TYPE.SHOW_IN_TASK_MANAGER:
      return produce(state, (draft) => {
        const jobs = Object.values(draft.jobs);
        const existingJob = jobs.find(
          (job) => job.referenceId === action.payload.referenceId && job.jobName === action.payload.jobName,
        );
        if (!existingJob) return draft;
        draft.jobs[existingJob.jobId].isVisibleInTaskManager = true;
      });
    case ACTION_TYPE.HIDE_FROM_TASK_MANAGER:
      return produce(state, (draft) => {
        const jobs = Object.values(draft.jobs);
        const existingJob = jobs.find(
          (job) => job.referenceId === action.payload.referenceId && job.jobName === action.payload.jobName,
        );
        if (!existingJob) return draft;
        draft.jobs[existingJob.jobId].isVisibleInTaskManager = false;
      });

    case ACTION_TYPE.HIDE_ALL_TASKS_FROM_TASK_MANAGER:
      return produce(state, (draft) => {
        Object.values(draft.jobs).forEach((job) => (draft.jobs[job.jobId].isVisibleInTaskManager = false));
      });

    case ACTION_TYPE.UPDATE_PROGRESS:
      return produce(state, (draft) => {
        draft.progress[action.payload.jobId] = action.payload.update;
      });

    case ACTION_TYPE.CLEAR_ALL:
      return defaultState;
    default:
      return state;
  }
};

const defaultState: State = {
  jobs: {},
  jobIds: [],
  progress: {},
};

type JobProgressContextType = {
  state: State;
  startTrackingJob: (referenceId: string, jobName: JOB_NAME, showJobInTaskMangerAlso?: boolean) => void;
  stopTrackingJob: (referenceId: string, jobName: JOB_NAME) => void;
  showJobInTaskManger: (referenceId: string, jobName: JOB_NAME) => void;
  hideJobFromTaskManager: (referenceId: string, jobName: JOB_NAME) => void;
  getJobProgressFromReferenceAndName: (referenceId: string, jobName: JOB_NAME) => LeanJobConfig | undefined;
  clearAll: () => void;
  hideAllJobsFromTaskManager: () => void;
  onTrackerClose: (bgJobState: BG_JOB_STATE, jobName: JOB_NAME, referenceId?: string) => void;
};

export const JobProgressContext = createContext<JobProgressContextType>({
  state: defaultState,
  startTrackingJob: () => {},
  stopTrackingJob: () => {},
  showJobInTaskManger: () => {},
  hideJobFromTaskManager: () => {},
  getJobProgressFromReferenceAndName: () => undefined,
  clearAll: () => {},
  hideAllJobsFromTaskManager: () => {},
  onTrackerClose: () => {},
});

const useJobProgress = () => {
  const [state, dispatch] = useReducer(reducer, defaultState);

  const startTrackingJob = (referenceId: string, jobName: JOB_NAME, showJobInTaskMangerAlso = true) =>
    dispatch({
      type: ACTION_TYPE.START_TRACKING,
      payload: { referenceId, jobName, isVisibleInTaskManager: showJobInTaskMangerAlso },
    });

  const stopTrackingJob = (referenceId: string, jobName: JOB_NAME) =>
    dispatch({ type: ACTION_TYPE.STOP_TRACKING, payload: { referenceId, jobName } });
  const showJobInTaskManger = (referenceId: string, jobName: JOB_NAME) =>
    dispatch({ type: ACTION_TYPE.SHOW_IN_TASK_MANAGER, payload: { referenceId, jobName } });

  const hideJobFromTaskManager = (referenceId: string, jobName: JOB_NAME) => {
    dispatch({ type: ACTION_TYPE.HIDE_FROM_TASK_MANAGER, payload: { referenceId, jobName } });
    dispatch({ type: ACTION_TYPE.STOP_TRACKING, payload: { referenceId, jobName } });
  };

  const updateProgress = (jobConfig) => dispatch({ type: ACTION_TYPE.UPDATE_PROGRESS, payload: jobConfig });

  const clearAll = () => dispatch({ type: ACTION_TYPE.CLEAR_ALL });
  const hideAllJobsFromTaskManager = () => dispatch({ type: ACTION_TYPE.HIDE_ALL_TASKS_FROM_TASK_MANAGER });

  const getJobProgressFromReferenceAndName = (referenceId: string, jobName: JOB_NAME) =>
    Object.values(state.progress).find((p) => p.referenceId === referenceId && p.jobName === jobName);

  const onTrackerClose = (bgJobState: BG_JOB_STATE, jobName: JOB_NAME, referenceId?: string) => {
    if (!referenceId) {
      // console.log('onTrackerClose: No reference id', { bgJobState, jobName, referenceId });
      return;
    }
    if (shouldPassJobToTaskManager(bgJobState)) {
      // console.log('onTrackerClose: passing the job to task manager', { bgJobState, jobName, referenceId });
      showJobInTaskManger(referenceId, jobName);
    } else {
      // console.log('onTrackerClose: stopping job tracking', { bgJobState, jobName, referenceId });
      stopTrackingJob(referenceId, jobName);
    }
  };

  return {
    state,
    startTrackingJob,
    stopTrackingJob,
    showJobInTaskManger,
    hideJobFromTaskManager,
    clearAll,
    hideAllJobsFromTaskManager,
    updateProgress,
    getJobProgressFromReferenceAndName,
    onTrackerClose,
  };
};

export const shouldPassJobToTaskManager = (bgJobState: BG_JOB_STATE) =>
  [BG_JOB_STATE.PENDING_CREATE, BG_JOB_STATE.BEING_CREATED, BG_JOB_STATE.QUEUED, BG_JOB_STATE.PROCESSING].includes(
    bgJobState,
  );

enum WS_CLIENT_MESSAGE_TYPE {
  HANDSHAKE = 'HANDSHAKE',
  REQUEST_FOR_UPDATES_ON_QUEUED_JOBS = 'REQUEST_FOR_UPDATES_ON_QUEUED_JOBS',
}
enum WS_SERVER_MESSAGE_TYPE {
  HANDSHAKE_SUCCESS = 'HANDSHAKE_SUCCESS',
  JOB_PROGRESS_UPDATE = 'JOB_PROGRESS_UPDATE',
}

export const JobProgressProvider = ({ children }) => {
  useInvalidateOnJobCompletions();

  const { organizationId, userId } = useSession();
  const {
    state,
    startTrackingJob,
    stopTrackingJob,
    showJobInTaskManger,
    hideJobFromTaskManager,
    hideAllJobsFromTaskManager,
    clearAll,
    updateProgress,
    getJobProgressFromReferenceAndName,
    onTrackerClose,
  } = useJobProgress();

  const [handshakeDone, setHandshakeDone] = useState(false);

  const { sendMessage, lastMessage, readyState } = useWebSocket(WS_URL, { shouldReconnect: () => !!organizationId });

  useInterval(() => {
    if (organizationId && !handshakeDone && readyState === ReadyState.OPEN) {
      // console.log('Attempting websocket handshake');
      sendMessage(JSON.stringify({ payload: { organizationId, userId }, type: WS_CLIENT_MESSAGE_TYPE.HANDSHAKE }));
    }
  }, 3000);

  // useEffect(() => {
  //   console.log('websocket state:', readyState);
  // }, [readyState]);

  useLayoutEffect(() => {
    setHandshakeDone(false);
    clearAll();
  }, [organizationId]);

  useEffect(() => {
    if (readyState !== ReadyState.OPEN) setHandshakeDone(false);
  }, [readyState]);

  useInterval(() => {
    if (organizationId && handshakeDone && readyState === ReadyState.OPEN) {
      const jobs = state.jobIds
        .filter((id) => !state.progress[id])
        .map((id) => ({ referenceId: state.jobs[id].referenceId, jobName: state.jobs[id].jobName }));
      if (jobs.length) {
        // console.log(`Requesting updates on ${jobs.length} jobs`);
        sendMessage(
          JSON.stringify({
            payload: { jobs, organizationId },
            type: WS_CLIENT_MESSAGE_TYPE.REQUEST_FOR_UPDATES_ON_QUEUED_JOBS,
          }),
        );
      }
    }
  }, 3000);

  const handleHandshakeSuccessWSMessage = () => setHandshakeDone(true);
  const handleJobProgressUpdateWSMessage = (message) => {
    const jobConfig = message.payload.jobConfig;
    if (!jobConfig) return;
    const allJobs = Object.values(state.jobs);

    const matchingTrackedJob = allJobs.find(
      (job) => job && job.jobName === jobConfig.jobName && job.referenceId === jobConfig.referenceId,
    );

    if (!matchingTrackedJob) {
      // console.log(`No matching tracked job found for ${jobConfig.jobName} ${jobConfig.referenceId}`, {
      //   state: state.jobs,
      // });
      return;
    }

    // console.log('jobId on new message');
    updateProgress({ jobId: matchingTrackedJob.jobId, update: jobConfig });
  };

  useEffect(() => {
    if (lastMessage !== null) {
      const message: { type: WS_SERVER_MESSAGE_TYPE; payload: any } = JSON.parse(lastMessage.data);
      // console.log('ws:', { data: message, lastMessage });
      if (!message.type) return;
      switch (message.type) {
        case WS_SERVER_MESSAGE_TYPE.HANDSHAKE_SUCCESS:
          handleHandshakeSuccessWSMessage();
          return;
        case WS_SERVER_MESSAGE_TYPE.JOB_PROGRESS_UPDATE:
          handleJobProgressUpdateWSMessage(message);
          return;
        default:
        // console.log('ws: bad message', { messageString: lastMessage, data: message });
      }
    }
  }, [lastMessage]);

  const [showTaskRows, setShowTaskRows] = useState(false);
  const jobNameBasedInvalidator = useJobNameBasedQueryInvalidator();

  const onStartTrackingJob = async (referenceId: string, jobName: JOB_NAME, showJobInTaskMangerAlso = true) => {
    let websocketRetryCount = 0;
    while (readyState !== ReadyState.OPEN && websocketRetryCount < 16) {
      // 2sec
      await new Promise((resolve) => setTimeout(resolve, 250));
      websocketRetryCount++;
    }

    startTrackingJob(referenceId, jobName, showJobInTaskMangerAlso);
  };

  const onStopTrackingJob = (referenceId: string, jobName: JOB_NAME) => {
    stopTrackingJob(referenceId, jobName);
    jobNameBasedInvalidator(jobName);
  };

  const [visibleTasks, setVisibleTasks] = useState<any[]>([]);

  useEffect(() => {
    setVisibleTasks(Object.values(state.jobs).filter((job) => job.isVisibleInTaskManager));
  }, [state]);

  return (
    <JobProgressContext.Provider
      value={{
        state,
        startTrackingJob: onStartTrackingJob,
        stopTrackingJob: onStopTrackingJob,
        showJobInTaskManger,
        hideJobFromTaskManager,
        hideAllJobsFromTaskManager,
        clearAll,
        getJobProgressFromReferenceAndName,
        onTrackerClose,
      }}
    >
      {children}
      <AnimatePresence>
        {visibleTasks.length > 0 && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className='fixed z-30 bottom-0 right-0 mx-5 my-20'
          >
            <div
              className='bg-zinc-800 text-white rounded-lg font-medium overflow-hidden'
              style={{ width: 296 }}
              data-cy={`taskManager_container`}
            >
              <div className='flex items-center p-4 pr-5'>
                <span className='flex-grow mr-2' data-cy={`taskManager_status`}>
                  {visibleTasks.length} {visibleTasks.length === 1 ? 'task is running' : 'tasks are running'}
                </span>
                <button
                  className='mr-3.5 hover:text-gray-500'
                  onClick={() => setShowTaskRows((prev) => !prev)}
                  data-cy={`taskManager_chevronButton`}
                >
                  <HiChevronDown
                    className={`transition duration-500 ease-in-out w-5 h-5 ${showTaskRows ? '-rotate-180' : ''}`}
                  />
                </button>
                <button
                  onClick={hideAllJobsFromTaskManager}
                  className='hover:text-gray-500'
                  data-cy={`taskManager_closeButton`}
                >
                  <MdClose className='w-5 h-5' />
                </button>
              </div>
              {showTaskRows && (
                <div
                  className={`transition-all duration-500 max-h-32 overflow-hidden ${showTaskRows ? '' : 'max-h-0 '}`}
                >
                  <div className='max-h-32 overflow-auto divide-y divide-gray-700 border-t border-gray-700 bg-black'>
                    {visibleTasks.map((jobConfig, i) => (
                      <TrackJobInTaskManager
                        referenceId={jobConfig.referenceId}
                        jobName={jobConfig.jobName}
                        key={i}
                        onClose={() => hideJobFromTaskManager(jobConfig.referenceId, jobConfig.jobName)}
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </JobProgressContext.Provider>
  );
};
