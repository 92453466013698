import React, { useContext, useState } from 'react';
import { Table, TableWrapper } from '../../dashboard';
import { SearchInput } from 'ui';
import { EditColumns } from '../../atoms/filter';
import { SidebarGlobalContext } from '../../../context/SidebarGlobalProvider';
import { jouranlEntriesTableHeader } from '../../../constants/tableHeaders';
import { TableHeaderProps } from '../../dashboard/types';
import { mapTableKeyToOriginalKey } from '../../utils';
import { TABLE_TYPE } from '../../../context';

export const JournalsTable = ({
  displayedJEs,
  searchTerm,
  setSearchTerm,
  isSearching,
  setSortState,
  setShowCreateJournal,
  fetchNextPage,
  isLoading,
  isFetchingNextPage,
}) => {
  const [hasTableStateChanged, setHasTableStateChanged] = useState(false);
  const [tableHeader, setTableHeader] = useState<TableHeaderProps>(jouranlEntriesTableHeader);
  const { openSidebar } = useContext(SidebarGlobalContext);
  return (
    <TableWrapper>
      <Table
        data-cy='journals'
        tableTopActions={
          <div className='flex items-center justify-between gap-3 md:gap-0 px-3 py-2'>
            <div className='flex items-center gap-3 '>
              <SearchInput
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                loading={isSearching}
                placeholder='Search journals...'
                className='placeholder:text-zinc-500 '
                containerClassName=' min-w-0 '
                resultCount={displayedJEs?.length}
              />
            </div>
            <div className='flex gap-3'>
              <EditColumns
                tableHeader={jouranlEntriesTableHeader}
                tableHeaderState={tableHeader}
                hasTableStateChanged={hasTableStateChanged}
                getFilteredTableHeader={(filteredTableHeader) => {
                  setTableHeader(filteredTableHeader);
                }}
              />
            </div>
          </div>
        }
        tableHeader={tableHeader}
        tableData={{
          Data: displayedJEs,
        }}
        onRowClick={(row) => {
          setShowCreateJournal(false);
          openSidebar('journals', { id: row.original._id });
        }}
        useInViewCallback={() => fetchNextPage()}
        isLoading={isLoading}
        isFetchingNextPage={isFetchingNextPage}
        emptyBtn={{
          emptyMsg: 'No journal entry found',
        }}
        tableType={TABLE_TYPE.ALL_JOURNALS}
        onSortingChange={(state) => {
          if (state.length === 0) {
            setSortState([]);
            return;
          }
          setSortState([{ id: mapTableKeyToOriginalKey(state[0].id, 'Journals'), desc: state[0].desc }]);
        }}
        canHideColumns
        setHasTableStateChanged={setHasTableStateChanged}
        getFilteredTableHeader={(filteredTableHeader) => {
          setTableHeader(filteredTableHeader);
        }}
      />
    </TableWrapper>
  );
};

export default JournalsTable;
