import { useEffect, useState } from 'react';
import { useSources } from './useSources';
import { Wallet } from 'services/http/response.types';

export const useAllWallets = () => {
  const [wallets, setWallets] = useState<Wallet[]>([]);
  const { data, isLoading } = useSources();

  useEffect(() => {
    if (!isLoading && data) setWallets(data.pages.map((page) => page.wallets).flat());
  }, [data, isLoading]);

  return { wallets, isLoading };
};
