import { HiXMark } from 'react-icons/hi2';
import { useEffect, useState } from 'react';
import { usePopper } from 'react-popper';
import { JOB_NAME, JOB_STATUS } from 'services/http';
import { useSyncedBgJobTrackerProps } from './useSyncedBgJobTrackerProps';
import { useBgJobTrackerState } from './useBgJobTrackerState';
import { BG_JOB_STATE } from './types';

interface TrackJobInTaskManagerProps {
  referenceId: string;
  jobName: JOB_NAME;
  onClose: () => void;
}

export const useJobProgressDescriptor = (jobName, jobConfig) => {
  const [description, setDescription] = useState<string>(jobName);

  useEffect(() => {
    let d: string = jobName;
    if (!jobConfig) d = `${jobName} queued`;
    else if (jobConfig.jobStatus === JOB_STATUS.IN_PROGRESS)
      d = `${jobConfig?.completedCount}/${jobConfig?.toProcessCount} ${jobName}`;
    setDescription(d);
  }, [jobConfig, jobName]);
  return description;
};

export const TrackJobInTaskManager = ({ referenceId, jobName, onClose }: TrackJobInTaskManagerProps) => {
  const [bgJobState, setBgJobState] = useBgJobTrackerState(BG_JOB_STATE.QUEUED);

  const { description, icon } = useSyncedBgJobTrackerProps({
    jobName,
    referenceId,
    bgJobState,
    setBgJobState,
  });

  const [showToolTip, setShowTooltip] = useState(false);
  const [referenceElement, setReferenceElement] = useState<any>(null);
  const [popperElement, setPopperElement] = useState<any>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'auto-start',
  });

  return (
    <div className='flex items-center justify-between p-4 pr-5 text-left'>
      {icon && (
        <span
          className='ml-1 mr-4'
          style={{
            transform: 'scale(2)',
          }}
        >
          {icon}
        </span>
      )}
      <span className='mr-3.5 flex-grow w-full truncate'>
        <span>
          <span
            ref={setReferenceElement}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          >
            {description}
          </span>

          {description.length > 20 && showToolTip && (
            <span ref={setPopperElement} style={styles.popper} {...attributes.popper} className='z-40 w-full'>
              <span className='text-xs flex py-1 px-2 shadow rounded-lg bg-black opactity-70 text-white m-2 whitespace-normal break-all'>
                {description}
              </span>
            </span>
          )}
        </span>
      </span>

      {onClose && (
        <button onClick={onClose} className='hover:text-gray-500'>
          <HiXMark className='w-5 h-5' />
        </button>
      )}
    </div>
  );
};
