import { useKBar } from 'farish-kbar';
import React, { useEffect, useRef } from 'react';
import { MdAutoAwesome, MdSearch } from 'react-icons/md';
import { useCommandPallete } from './useCommandPallete';
import { useGPT } from '../../hooks/http/useGPT';
import { Button, classNames } from 'ui';
import { useSession } from '../../hooks/useSession';
import { Tooltip } from '../atoms';
import { SearchBarProps } from './types';
import { AppMentionsInput } from './AppMentionsInput';
import { OnChangeHandlerFunc } from 'react-mentions';
import { invalidateQueriesBasedOnIntent } from './utils';
import { useInvalidateQuery } from '../../hooks';
import { GPTServiceIntentEnum } from 'services';

export const SearchBar: React.FC<SearchBarProps> = ({ disabled = false, value, onChange }) => {
  const {
    secondViewPath,
    setIsLoading,
    setGptChatResponses,
    searchBarDisabled,
    setSecondViewPath,
    selectedIntent,
    setSelectedIntent,
  } = useCommandPallete();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const inputRef = useRef<HTMLTextAreaElement | null>(null);
  const suggestionsRef = useRef<HTMLDivElement>(null);

  const { mutateAsync: askGPT, isLoading: isGPTLoading } = useGPT();
  const { organizationId, userId } = useSession();

  const invalidateQueries = useInvalidateQuery();

  const { query } = useKBar();

  const handleAskAI = async () => {
    try {
      setSecondViewPath('gpt-chat');
      const res = await askGPT({ organizationId, userId, instruction: value, selectedIntent });
      if (res.status === 200) {
        invalidateQueriesBasedOnIntent(
          res.data.fullGptResponse.intent ?? GPTServiceIntentEnum.INVALID_INTENT,
          invalidateQueries,
        );
        setGptChatResponses((prev) => [...prev, { ...res.data.fullGptResponse, query: query?.getInput()?.value }]);
      }
    } catch (err) {
      console.log(err);
    }
    setSelectedIntent(null);
  };

  useEffect(() => {
    // listen to enter key
    const listener = () => {
      // click button if has focus
      (document?.activeElement as HTMLButtonElement | undefined)?.click();
    };
    addEventListener('keydown', listener);
    return () => {
      removeEventListener('keydown', listener);
    };
  }, []);

  useEffect(() => {
    setIsLoading(isGPTLoading);
  }, [isGPTLoading]);

  const autoHeight = (elem: HTMLTextAreaElement | HTMLDivElement) => {
    /* javascript */
    elem.style.height = '1px';
    elem.style.height = elem.scrollHeight + 'px';
  };

  useEffect(() => {
    autoHeight(query.getInput() as unknown as HTMLTextAreaElement);
  }, [value]);

  useEffect(() => {
    if (inputRef.current) {
      // set style of the input, because the library doesn't allow us to do it
      inputRef.current.className = classNames(
        inputRef.current.className,
        'text-base w-full placeholder:text-zinc-900 focus:!outline-none disabled:text-gray-700 disabled:bg-white resize-none max-h-60 scrollbar-hide h-full',
      );
    }
  }, [inputRef.current]);

  const onChangeHandlerFunc: OnChangeHandlerFunc = (e, newValue) => {
    onChange({ target: { value: newValue } });
  };

  return (
    <div className={classNames('flex justify-between flex-col relative px-2', secondViewPath && 'hidden')}>
      <div className={`flex justify-between relative ${secondViewPath ? 'hidden' : ''}`}>
        <div className={`px-3 py-2 mr-2 max-h-60 border rounded-lg scrollbar-hide h-full w-full flex max-w-[81%]`}>
          <MdSearch className='w-6 h-6 text-zinc-500 mr-2' />
          <AppMentionsInput
            autoCorrect='off'
            className='text-[16px] w-full placeholder:!text-zinc-500 focus:!outline-none disabled:text-gray-700 disabled:bg-white resize-none max-h-60 scrollbar-hide h-full break-words'
            autoFocus={true}
            disabled={disabled || !!secondViewPath || searchBarDisabled}
            inputRefSetter={(ref) => {
              query.inputRefSetter(ref);
              inputRef.current = ref;
            }}
            placeholder={!disabled ? 'Search or type a command' : 'Please login to use search'}
            value={value}
            onInputChange={onChange}
            onChange={onChangeHandlerFunc}
            id='kbar-search'
            rows={1}
            customSuggestionsContainer={(children) => {
              return (
                <div className='fixed inset-x-2 top-14 translate-y-0 p-2 z-[9999] rounded-md shadow-[rgba(67,_71,_85,_0.27)_0px_0px_0.25em,_rgba(90,_125,_188,_0.05)_0px_0.25em_1em] bg-white'>
                  <div
                    ref={suggestionsRef}
                    className='max-h-[42vh] overflow-auto h-fit  flex flex-col gap-1 [&_li]:mb-1 last:[&_li]:mb-0'
                  >
                    {children}
                  </div>
                </div>
              );
            }}
          />
        </div>
        <div>
          <Tooltip content='Ask AI'>
            <Button
              leadingIcon={<MdAutoAwesome className='text-gray-300' size={20} />}
              emphasis='high'
              disabled={disabled || isGPTLoading || !value}
              onClick={handleAskAI}
              label='Ask AI'
              type='submit'
              ref={buttonRef}
            />
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
