import toast from 'react-hot-toast';
import { useInvalidateQuery, useMarkTransactionsAsSpam } from '../../hooks';
import { deriveError } from '../templates/utils';
import { Button } from 'ui';

export function SpamTransactionsMultiSelectActionsComponent({ selectedRows, table }) {
  const { invalidateTransactions } = useInvalidateQuery();
  const selectedIds = selectedRows?.map((item) => item?.original?._id);

  const { mutateAsync: markTransactionsAsNotSpam, isLoading } = useMarkTransactionsAsSpam();

  const bulkUnspam = async () => {
    const toastId = toast.loading('Unmarking transactions as spam');
    try {
      await markTransactionsAsNotSpam({
        transactionIds: selectedIds,
      });

      table.resetRowSelection();
      toast.success('Transactions no longer marked as spam', { id: toastId });
      invalidateTransactions();
    } catch (error) {
      toast.error(deriveError(error), { id: toastId });
    }
  };
  return (
    <div className='flex ml-auto'>
      <Button label='Unmark as spam' emphasis='medium' onClick={bulkUnspam} isLoading={isLoading} />
    </div>
  );
}
