import React from 'react';
import { MdArrowDropDown } from 'react-icons/md';
import { DropdownIndicatorProps } from 'react-select';
import { components } from 'react-select';
export const DropdownIndicator = (props: DropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...props}>
      <MdArrowDropDown className='w-6 h-6' />
    </components.DropdownIndicator>
  );
};

export default DropdownIndicator;
