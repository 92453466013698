import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { bulkDeleteTag, createTag, deleteTag, getTags, patchTag } from 'services/http/core';
import { ColumnSort } from '@tanstack/react-table';
import { useSession } from '../useSession';
import { QUERY_KEY, useOrgBasedQueryKey } from '../useInvalidateQuery';

type useTagsParams =
  | {
      sort?: ColumnSort;
    }
  | undefined;

export const useTags = (payload?: useTagsParams) => {
  const { id: sortBy, desc } = payload?.sort ?? { id: 'createdAt', desc: true };

  const { organizationId } = useSession();
  const getKey = useOrgBasedQueryKey();

  return useInfiniteQuery(
    getKey(QUERY_KEY.TAGS, { sort: payload?.sort }),
    async ({ pageParam = 0 }) => {
      const response = await getTags({
        organizationId,
        page: pageParam,
        sortBy,
        sortDirection: desc ? 'desc' : 'asc',
      });
      return response?.data?.tags ?? [];
    },
    {
      enabled: !!organizationId,
      getPreviousPageParam: (firstPage) => firstPage.prevPage ?? undefined,
      getNextPageParam: (lastPage) => lastPage.nextPage,
    },
  );
};

export const useUpdateTag = () => useMutation((data) => patchTag(data));

export const useCreateTag = () => useMutation((data) => createTag(data));

export const useDeleteTag = () => useMutation((data) => deleteTag(data));

export const useBulkDeleteTag = () =>
  useMutation((data: { organizationId: string; tagIds: string[] }) => bulkDeleteTag(data));
