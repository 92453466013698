import { useContext, useMemo } from 'react';
import { useAllLegalEntities, useGetSourceById, useTransactions } from '../../hooks/http';
import { getDisplayedLegalEntities, getDisplayedTags } from '../templates/utils';
import { useAllTags } from '../../hooks/http/useAllTags';
import { SidebarGlobalContext } from '../../context/SidebarGlobalProvider';
import { SidebarBody, SidebarSection, SidebarSectionHeader } from '../atoms/Sidebar/SidebarBody';
import { DetailsItem } from '../atoms';
import { CryptoIcon } from '../utils';
import { getHost } from '../../lib/utils';
import SidebarHeader from '../atoms/Sidebar/SidebarHeader/SidebarHeader';
import { Table } from '../dashboard';
import { TransactionsHeaderData } from '../../constants/tableHeaders';
import { getDisplayedTransactions } from './utils';
import { shortText } from '../rules/utils';

export const ViewSourceSidebarContent = ({ sourceId, onPatch }) => {
  const { data: wallet, isLoading: isLoadingWallet } = useGetSourceById(sourceId);
  const { legalEntities, isLoading: isLoadingEntities } = useAllLegalEntities();
  const LEGAL_ENTITY_SELECT_OPTIONS = useMemo(() => getDisplayedLegalEntities(legalEntities), [legalEntities]);
  const { data: recentTransactions, isLoading: recentTransactionLoading } = useTransactions({
    pageSize: 25,
    sourceIds: [sourceId],
    walletIds: [sourceId],
    sort: { id: 'transactionDate', desc: true },
  });
  const { tags, isLoading: isLoadingTags } = useAllTags();
  const SELECTED_TAGS = useMemo(
    () =>
      getDisplayedTags(tags)
        .filter((tag) => wallet?.tags?.includes(tag.value))
        .map((tag) => tag.label),
    [tags, wallet],
  );

  const { openSidebar } = useContext(SidebarGlobalContext);

  let transactionsToDisplay = [];
  if (recentTransactions) {
    transactionsToDisplay = getDisplayedTransactions(recentTransactions);
  }

  const dataCy = 'viewSource';

  return (
    <>
      <SidebarHeader
        title={wallet?.name ?? shortText(wallet?.address) ?? ''}
        status={{
          label: wallet?.status?.toLowerCase() ?? '',
          type: wallet?.status == 'ACTIVE' ? 'positive' : 'neutral',
        }}
        actions={[
          {
            label: 'Edit',
            onClick: onPatch,
            variant: 'primary',
            'data-cy': `editButton`,
          },
        ]}
        data-cy={dataCy}
      />
      <SidebarBody>
        <SidebarSectionHeader title='Details' />
        <SidebarSection numberOfColumns={2} loading={isLoadingWallet || isLoadingEntities || isLoadingTags}>
          <DetailsItem tag='Type' value={wallet?.walletType as string} />
          <DetailsItem tag='Name' value={wallet?.name as string} />
          <DetailsItem
            tag='Chain'
            value={wallet?.chain.toUpperCase() as string}
            leadingComponent={<CryptoIcon symbol={wallet?.chain} size='sm' />}
          />
          {typeof wallet?.addresses?.length === 'number' && wallet.addresses.length > 1 ? (
            <DetailsItem tag='Addresses' value={wallet?.addresses} />
          ) : (
            <DetailsItem
              tag='Address'
              maxCharacters={10}
              textToCopy={wallet?.address}
              value={wallet?.address as string}
            />
          )}
          <DetailsItem
            tag='Legal entity'
            value={LEGAL_ENTITY_SELECT_OPTIONS.filter((option) => option.value === wallet?.legalEntityId)[0]?.label}
            variant={'secondary'}
            textToCopy={`${getHost()}/configure/entities/${wallet?.legalEntityId}`}
            itemId={wallet?.legalEntityId as string}
            onClick={() =>
              openSidebar('entities', { id: wallet?.legalEntityId as string, primaryOrSecondary: 'secondary' })
            }
          />
          <DetailsItem tag='Tags' value={SELECTED_TAGS} />
        </SidebarSection>
        <SidebarSectionHeader title='Recent Transactions' />
        <SidebarSection>
          <Table
            onRowClick={(row) => {
              openSidebar('transactions', { id: row.original._id, primaryOrSecondary: 'secondary' });
            }}
            hideCheckboxes
            isLoading={recentTransactionLoading}
            tableHeader={TransactionsHeaderData}
            tableData={{ Data: transactionsToDisplay || [] }}
            tableClasses='min-w-[0px]'
            isSidePanel
            enableColumnPinning={false}
          />
        </SidebarSection>
      </SidebarBody>
    </>
  );
};
