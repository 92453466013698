import React from 'react';
import { toast } from 'react-hot-toast';
import { deriveError } from '../../templates/utils';
import { Button } from 'ui';
import { useAddExternalSource, useInvalidateQuery, useMarkTransactionsAsSpam, useSession } from '../../../hooks';
import { TransactionClassificationModal } from './TransactionClassificationModal';
import { TransactionRecategorizationModal } from './TransactionRecategorizationModal';
import { TABLE_TYPE, useTableActionBarContext } from '../../../context';

export const TransactionsMultiSelectActionsComponent = ({ selectedRows, table }) => {
  const { mutateAsync: markTransactionsAsSpam, isLoading } = useMarkTransactionsAsSpam();
  const selectedTxs = selectedRows?.map((item) => ({ id: item?.original?._id, chain: item?.original?.chain }));
  const { mutateAsync: patchExternalSource, isLoading: isLoadingExternal } = useAddExternalSource();
  const { invalidateWallets, invalidateTransactions } = useInvalidateQuery();
  const { organizationId, userId } = useSession();

  const { getPropsForTableType } = useTableActionBarContext();

  const { toggleAllRowsSelected } = getPropsForTableType(TABLE_TYPE.ALL_TRANSACTIONS);

  const handleBulkAddExternalSources = async () => {
    try {
      for (let i = 0; i < selectedTxs.length; i++) {
        await patchExternalSource(
          {
            userId: userId,
            organizationId: organizationId,
            transactionIds: [selectedTxs[i].id],
            body: {
              chain: selectedTxs[i].chain,
            },
          },
          {
            onSuccess: () => {
              invalidateWallets();
              toggleAllRowsSelected(false);
            },
          },
        );
      }
      table.resetRowSelection();
      toast.success('External source added successfully');
    } catch (error) {
      console.error(error);
      toast.error(deriveError(error));
    }
  };
  return (
    <div className='flex ml-auto px-4'>
      <TransactionClassificationModal
        onSuccess={() => toggleAllRowsSelected(false)}
        transactionIds={selectedTxs.map((tx) => tx.id)}
      />
      <TransactionRecategorizationModal
        onSuccess={() => toggleAllRowsSelected(false)}
        transactionIds={selectedTxs.map((tx) => tx.id)}
      />
      <Button
        label='Add External Sources'
        emphasis='medium'
        onClick={handleBulkAddExternalSources}
        isLoading={isLoadingExternal}
      />
      <Button
        isLoading={isLoading}
        disabled={isLoading}
        label='Mark as spam'
        className='ml-4'
        emphasis='medium'
        onClick={async () => {
          try {
            await markTransactionsAsSpam({
              transactionIds: selectedTxs.map((tx) => tx.id),
            });
            toast.success('Transactions marked as spam successfully');
            toggleAllRowsSelected(false);
            invalidateTransactions();
          } catch (error) {
            toast.error(deriveError(error));
          }
        }}
      />
    </div>
  );
};

export default TransactionsMultiSelectActionsComponent;
