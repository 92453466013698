import { Mention, MentionsInput, MentionsInputProps } from 'react-mentions';
import { currencyImg } from '../utils';
import { useUserObjects } from '../../hooks/useUserObjects';
import { useEffect, useRef, useState } from 'react';
import { MdAccountBalance, MdAccountBalanceWallet, MdChevronLeft, MdChevronRight, MdDomain } from 'react-icons/md';
import { Avatar, Button } from 'ui';
import { getAssetsInfo } from '../../constants/assetsNames';

type SUB_MENU_OPTIONS = 'Legal Entity' | 'Wallet' | 'Ledger Account' | 'default';

export const AppMentionsInput = (
  props: Omit<MentionsInputProps, 'children'> & {
    onInputChange: (e: { target: { value: string } }) => void;
    inputRefSetter: (ref: any) => void;
  },
) => {
  const [subMenu, setSubMenu] = useState<SUB_MENU_OPTIONS>('default');
  const [searchTerm, setSearchTerm] = useState('');
  const { assetTypes, legalEntities, wallets, ledgerAccounts } = useUserObjects();
  const { onInputChange } = props;

  const inputRef = useRef<HTMLTextAreaElement | null>(null);
  const inputRefSetter = (ref) => {
    if (typeof props.inputRefSetter === 'function') {
      props.inputRefSetter(ref);
    } else {
      props.inputRef = ref;
    }

    inputRef.current = ref;
  };

  const subMenuData = {
    'Legal Entity': legalEntities.map((legalEntity) => ({
      id: `LEGAL_ENTITY_${legalEntity._id}`,
      display: legalEntity.entityName,
    })),
    Wallet: wallets.map((wallet) => ({ id: `WALLET_${wallet._id}`, display: wallet.name })),
    'Ledger Account': ledgerAccounts.map((ledgerAccount) => ({
      id: `LEDGER_ACCOUNT_${ledgerAccount._id}`,
      display: ledgerAccount.ledgerAccountName,
    })),
  };

  useEffect(() => {
    const input = inputRef.current;
    if (!input) return;

    const searchTermStartIndex = input.value.slice(0, input.selectionStart).lastIndexOf('/');
    if (searchTermStartIndex === -1) return setSearchTerm('');
    const newSearchTerm = input.value.slice(searchTermStartIndex + 1).split(' ')[0];
    setSearchTerm(newSearchTerm);
  }, [props.value]);

  useEffect(() => {
    const input = inputRef.current;
    if (!input) return;
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === '/') {
        setSubMenu('default');
      }
    };
    if (input) {
      input.addEventListener('keydown', onKeyDown);
      return () => {
        input.removeEventListener('keydown', onKeyDown);
      };
    }
  }, [inputRef.current]);

  const onAdd = (id: string | number, display: string) => {
    const input = inputRef.current;
    if (!input) return;
    input.blur();
    const selectionStart = input.selectionStart || 0;
    // add display to the input at cursor position
    const newValue = input.value.slice(0, selectionStart) + `@[${display}](${id})` + input.value.slice(selectionStart);
    input.value = newValue;
    onInputChange({ target: { value: newValue } });
    input.focus();
  };

  const onAddById = (id: string | number, display: string) => {
    setSubMenu(display as SUB_MENU_OPTIONS);
    const input = inputRef.current;
    if (!input) return;
    const currentValue = props.value ?? '';

    const currentValueWithoutMenuTrigger = currentValue.replace('@[/]', '/');

    const newValue = currentValueWithoutMenuTrigger;
    onInputChange({ target: { value: newValue } });
    input.blur();
    input.focus();
  };

  const closeSuggestions = () => {
    const input = inputRef.current;

    if (input) {
      const currentValue = input.value ?? '';
      input.blur();
      onInputChange({ target: { value: '' } });
      input.value = '';
      onInputChange({ target: { value: currentValue } });
      input.value = currentValue;
      input.focus();
      setSubMenu('default');
    }
  };

  return (
    <MentionsInput
      autoCorrect='off'
      autoComplete='off'
      allowSuggestionsAboveCursor
      allowSpaceInQuery
      inputRef={inputRefSetter}
      {...props}
    >
      <Mention
        trigger='$'
        data={assetTypes.map((assetType) => ({ id: assetType, display: assetType }))}
        appendSpaceOnAdd
        className='rounded-md bg-indigo-50 relative'
        renderSuggestion={(suggestion, search, highlightedDisplay, index, focused) => {
          return (
            <>
              {index === 0 && subMenu !== 'default' && (
                <BackButton
                  label={'Assets'}
                  onClick={() => {
                    const input = inputRef.current;

                    if (input) {
                      const currentValue = input.value ?? '';
                      input.blur();
                      input.value = 's';
                      onInputChange({ target: { value: currentValue } });

                      input.focus();
                    }
                  }}
                />
              )}
              <div
                className={`flex px-2 py-2 whitespace-nowrap gap-2 text-zinc-900 font-medium mb-1  rounded-md items-center ${
                  focused ? 'bg-indigo-50' : 'bg-white'
                }`}
              >
                <Avatar
                  src={currencyImg(suggestion.display?.toLowerCase() ?? '')}
                  size='large'
                  alt={suggestion.display ?? ''}
                />
                <div>
                  <p className='text-zinc-900 font-medium'>{getAssetsInfo(suggestion.id?.toString() ?? '').name}</p>
                  <p className='text-zinc-500'>{getAssetsInfo(suggestion.id?.toString() ?? '').abbreviation}</p>
                </div>
              </div>
            </>
          );
        }}
      />
      <Mention
        trigger='/'
        data={
          searchTerm
            ? subMenuData['Ledger Account'].filter((ledgerAccount) =>
                ledgerAccount.display.toLowerCase().includes(searchTerm.toLowerCase()),
              )
            : []
        }
        appendSpaceOnAdd
        renderSuggestion={(suggestion, search, highlightedDisplay, index, focused) => {
          return (
            <>
              {index === 0 && subMenu !== 'default' && <BackButton label={subMenu} onClick={closeSuggestions} />}
              <div
                className={`flex px-2 py-2 text-zinc-900 font-medium whitespace-nowrap rounded-md items-center ${
                  focused ? 'bg-indigo-50' : 'bg-white'
                }`}
              >
                {highlightedDisplay}
              </div>
            </>
          );
        }}
      />
      <Mention
        trigger='/'
        data={
          searchTerm
            ? subMenuData['Legal Entity'].filter((legalEntity) =>
                legalEntity.display.toLowerCase().includes(searchTerm.toLowerCase()),
              )
            : []
        }
        appendSpaceOnAdd
        renderSuggestion={(suggestion, search, highlightedDisplay, index, focused) => {
          return (
            <>
              {index === 0 && subMenu !== 'default' && <BackButton label={subMenu} onClick={closeSuggestions} />}
              <div
                className={`flex px-2 py-2 text-zinc-900 font-medium whitespace-nowrap rounded-md items-center ${
                  focused ? 'bg-indigo-50' : 'bg-white'
                }`}
              >
                {highlightedDisplay}
              </div>
            </>
          );
        }}
      />
      <Mention
        trigger='/'
        data={
          searchTerm
            ? subMenuData['Wallet'].filter((wallet) => wallet.display?.toLowerCase().includes(searchTerm.toLowerCase()))
            : []
        }
        appendSpaceOnAdd
        renderSuggestion={(suggestion, search, highlightedDisplay, index, focused) => {
          return (
            <>
              {index === 0 && subMenu !== 'default' && <BackButton label={subMenu} onClick={closeSuggestions} />}
              <div
                className={`flex px-2 py-2 text-zinc-900 font-medium whitespace-nowrap rounded-md items-center ${
                  focused ? 'bg-indigo-50' : 'bg-white'
                }`}
              >
                <MdAccountBalanceWallet className='w-6 h-6 text-zinc-900 mr-2' />
                {highlightedDisplay}
              </div>
            </>
          );
        }}
      />
      <Mention
        trigger='/'
        data={
          subMenu === 'default' && !searchTerm
            ? [
                { id: '/', display: 'Ledger Account' },
                { id: '/', display: 'Legal Entity' },
                { id: '/', display: 'Wallet' },
              ]
            : []
        }
        markup='[__id__]'
        onAdd={onAddById}
        renderSuggestion={(suggestion, search, highlightedDisplay, index, focused) => {
          if (searchTerm) return;
          return (
            <div
              className={`flex px-2 font-medium py-1.5 whitespace-nowrap justify-between rounded-md items-center ${
                focused ? 'bg-indigo-50' : 'bg-white'
              }`}
            >
              <div className='flex'>
                {suggestion.display === 'Ledger Account' ? (
                  <MdDomain className='w-6 h-6 text-zinc-900 mr-2' />
                ) : suggestion.display === 'Legal Entity' ? (
                  <MdAccountBalance className='w-6 h-6 text-zinc-900 mr-2' />
                ) : suggestion.display === 'Wallet' ? (
                  <MdAccountBalanceWallet className='w-6 h-6 text-zinc-900 mr-2' />
                ) : null}
                {highlightedDisplay}
              </div>
              <div className='ml-2'>
                <MdChevronRight className='w-6 h-6 text-zinc-500' />
              </div>
            </div>
          );
        }}
      />
      <Mention
        trigger='/'
        data={subMenuData[subMenu] || []}
        appendSpaceOnAdd
        onAdd={() => {
          setSubMenu('default');
        }}
        renderSuggestion={(suggestion, search, highlightedDisplay, index, focused) => {
          return (
            <>
              {index === 0 && subMenu !== 'default' && <BackButton label={subMenu} onClick={closeSuggestions} />}
              <div
                className={`flex px-2 py-2 text-zinc-900 font-medium whitespace-nowrap rounded-md items-center ${
                  focused ? 'bg-indigo-50' : 'bg-white'
                }`}
              >
                {subMenu === 'Ledger Account' ? (
                  <MdDomain className='w-6 h-6 text-zinc-900 mr-2' />
                ) : subMenu === 'Legal Entity' ? (
                  <MdAccountBalance className='w-6 h-6 text-zinc-900 mr-2' />
                ) : subMenu === 'Wallet' ? (
                  <MdAccountBalanceWallet className='w-6 h-6 text-zinc-900 mr-2' />
                ) : null}
                {highlightedDisplay}
              </div>
            </>
          );
        }}
      />

      <Mention
        trigger='ledger account '
        data={subMenuData['Ledger Account'].map((ledgerAccount) => ({
          id: ledgerAccount.id,
          display: ledgerAccount.display,
        }))}
        onAdd={(id, display) => {
          onAdd(id, display);
        }}
        appendSpaceOnAdd
        renderSuggestion={(suggestion, search, highlightedDisplay, index, focused) => {
          console.log(index);
          return (
            <>
              {index === 0 && subMenu !== 'default' && <BackButton label={subMenu} onClick={closeSuggestions} />}
              <div
                className={`flex px-2 py-2 text-zinc-900 font-medium whitespace-nowrap rounded-md items-center ${
                  focused ? 'bg-indigo-50' : 'bg-white'
                }`}
              >
                <MdDomain className='w-6 h-6 text-zinc-900 mr-2' />
                {highlightedDisplay}
              </div>
            </>
          );
        }}
      />
      <Mention
        trigger='legal entity '
        data={subMenuData['Legal Entity'].map((legalEntity) => ({ id: legalEntity.id, display: legalEntity.display }))}
        appendSpaceOnAdd
        onAdd={(id, display) => {
          onAdd(id, display);
        }}
        renderSuggestion={(suggestion, search, highlightedDisplay, index, focused) => {
          return (
            <>
              {index === 0 && subMenu !== 'default' && <BackButton label={subMenu} onClick={closeSuggestions} />}
              <div
                className={`flex px-2 py-2 text-zinc-900 font-medium whitespace-nowrap rounded-md items-center ${
                  focused ? 'bg-indigo-50' : 'bg-white'
                }`}
              >
                <MdAccountBalance className='w-6 h-6 text-zinc-900 mr-2' />
                {highlightedDisplay}
              </div>
            </>
          );
        }}
      />
      <Mention
        trigger='wallet '
        data={subMenuData['Wallet'].map((wallet) => ({ id: wallet.id, display: wallet?.display }))}
        appendSpaceOnAdd
        onAdd={(id, display) => {
          onAdd(id, display);
        }}
        renderSuggestion={(suggestion, search, highlightedDisplay, index, focused) => {
          return (
            <div
              className={`flex px-2 py-2 text-zinc-900 font-medium whitespace-nowrap rounded-md items-center  ${
                focused ? 'bg-indigo-50' : 'bg-white'
              }`}
            >
              <MdAccountBalanceWallet className='w-6 h-6 text-zinc-900 mr-2' />
              {highlightedDisplay}
            </div>
          );
        }}
      />
    </MentionsInput>
  );
};

interface BackButtonProps {
  label: string;
  onClick: () => void;
}

const BackButton = ({ label, onClick }: BackButtonProps) => {
  return (
    <Button
      label={label}
      leadingIcon={<MdChevronLeft />}
      onClick={(e) => {
        onClick();
        e.stopPropagation();
      }}
      className='hover:bg-indigo-50 mb-4'
      emphasis='low'
      disableRingOnFocus
    />
  );
};
