import { setCookie } from 'cookies-next';
import { useSession } from './useSession';

const redirectOnOrgChange = (host: string, token: string, organizationId: string) => {
  window.location.replace(`${host}/change-workspace?token=${token}&organizationId=${organizationId}`);
};

const isProductionHref = (href: string) =>
  href.startsWith('https://') &&
  href.includes('.entendre.finance') &&
  !href.includes('https://staging.entendre.finance') &&
  !href.includes('https://preview.entendre.finance');

export const useOrgChangeRedirect = () => {
  const { organizations, updateSelectedOrganizationId, token } = useSession();
  const redirectIfNeeded = (organizationId: string) => {
    const selectedOrganization = organizations.find((o) => o._id === organizationId);
    updateSelectedOrganizationId(organizationId);
    setCookie('organizationId', organizationId);

    if (!window?.location.href) return;

    const href = window.location.href;
    const subdomain = selectedOrganization?.webAddress;

    if (!token) return;
    if (!subdomain || subdomain.length === 0 || subdomain === 'undefined') return;

    if (isProductionHref(href))
      redirectOnOrgChange(
        `https://${selectedOrganization.webAddress}.entendre.finance`,
        token,
        selectedOrganization._id,
      );
    else if (href.includes('localhost') || href.includes('staging') || href.includes('preview')) {
      const URL = href.includes('localhost') ? `http://${window.location.host}` : `https://${window.location.host}`;
      redirectOnOrgChange(URL, token, selectedOrganization._id);
    }
  };
  return redirectIfNeeded;
};
