import React from 'react';
import ConditionItem from '../ConditionItem';
import { setSaveData } from '../../../rules/utils';
import SearchFilter from './SearchFilter';
import { ConditionMenusProps } from './types';

export const TransactionsCondition = ({ ruleSetData, getRuleSetData, isFirstLevel }: ConditionMenusProps) => {
  return (
    <ConditionItem label='Transaction'>
      <SearchFilter
        data={['Gross Amount', 'Net Amount', 'Fee']}
        renderItem={(item) => (
          <ConditionItem
            key={item}
            label={item}
            onClick={() => setSaveData('Transaction', item, isFirstLevel, ruleSetData, getRuleSetData)}
          />
        )}
      />
    </ConditionItem>
  );
};

export default TransactionsCondition;
