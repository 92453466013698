import { useEffect, useRef, useState } from 'react';
import {
  CheckboxListItem,
  Dropdown,
  DropdownBody,
  DropdownContent,
  DropdownGroupBody,
  DropdownTitleWithToggle,
  DropdownTrigger,
} from '../dropdown';
import { Button } from '../button';
import { classNames } from '../utils';
import { IoMdCloseCircle } from 'react-icons/io';
import { MdArrowDropDown, MdSearch } from 'react-icons/md';
import { MultiSelectProps, SingleSelectProps } from './types';
import { InputWithExtras } from '../input-with-extras';

export const MultiSelectMenu = <T,>({
  disabled,
  value,
  isLoading,
  'data-cy': dataCy,
  placeholder = 'Select',
  getTriggerLabel,
  clearable,
  onClearValue,
  onChange,
  options = [],
  getLabelFromOption,
  fullWidth,
  isOnSidepanel = false,
  enableSearch = false,
  onInputChange,
  isModal = true,
  side,
  getIsOptionChecked,
  dropdownContentClassName,
}: MultiSelectProps<T>) => {
  const triggerRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);

  const handleOnChange = (newValue: T[]) => {
    onChange && onChange(newValue);
  };

  return (
    <Dropdown open={open} onOpenChange={setOpen}>
      <DropdownTrigger ref={triggerRef}>
        <Button
          data-cy={dataCy}
          isFocused={open}
          emphasis='medium'
          disabled={disabled}
          isLoading={isLoading}
          onClick={() => setOpen((prev) => !prev)}
          label={
            value && value.length > 0 ? (
              <span>
                {getTriggerLabel((value as T[])[0])} {value && value.length > 1 && <span>+{value.length - 1}</span>}
              </span>
            ) : (
              placeholder
            )
          }
          className={classNames('duration-100 py-0', fullWidth && 'w-full flex justify-between')}
          labelContainerClassname='font-normal'
          trailingIconContainerClassname={classNames(
            value && value.length > 0 && 'mr-0 p-2 duration-100 rounded-r-lg bg-white',
          )}
          trailingIcon={
            <div className='flex items-center'>
              {value && value.length > 0 && clearable && (
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    onClearValue && onClearValue();
                  }}
                >
                  <IoMdCloseCircle className='w-6 h-6 text-zinc-500' />
                </span>
              )}

              <MdArrowDropDown className={classNames('duration-300 w-6 h-6 text-black', open && 'rotate-180')} />
            </div>
          }
        />
      </DropdownTrigger>
      <DropdownContent
        avoidCollisions
        side={side}
        triggerRef={triggerRef}
        sameWidthAsTrigger={fullWidth}
        enableHeightCalculation={side !== 'top'}
        className={classNames('p-1', isOnSidepanel && '!z-50', dropdownContentClassName)}
        withoutPortal={isModal}
      >
        <DropdownTitleWithToggle
          title='Select all'
          checked={(value && value.length === options.length) ?? false}
          onChange={(checked: boolean) => (checked ? handleOnChange([...(options as T[])]) : handleOnChange([]))}
        />
        <DropdownBody>
          <div className='overflow-hidden flex flex-col gap-1 flex-shrink-0'>
            {enableSearch && options.length !== 0 && (
              <InputWithExtras
                placeholder={placeholder}
                onChange={(e) => onInputChange && onInputChange(e.target.value)}
                leading={<MdSearch className='w-5 h-5 text-zinc-500 ml-1' />}
                trailing={
                  <div className='flex items-center'>
                    {value && value.length > 0 && clearable && (
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                          onClearValue && onClearValue();
                        }}
                      >
                        <IoMdCloseCircle className='w-6 h-6 text-zinc-500' />
                      </span>
                    )}

                    <MdArrowDropDown className={classNames('duration-300 w-6 h-6 text-black', open && 'rotate-180')} />
                  </div>
                }
                className='rounded-lg text-zinc-500 items-center leading-5 text-base placeholder:-translate-y-0.5 shadow-[0px_2px_4px_0px_rgba(0,_0,_0,_0.05)]'
              />
            )}

            {options.length !== 0 &&
              options.map((option, idx) => (
                <CheckboxListItem
                  key={idx}
                  enableSelectOnly={false}
                  label={getLabelFromOption(option)}
                  checked={
                    typeof getIsOptionChecked === 'function'
                      ? getIsOptionChecked(option)
                      : (value && !!(value as T[]).find((v) => JSON.stringify(v) === JSON.stringify(option))) || false
                  }
                  onCheckedChange={(checked) => {
                    if (checked) {
                      handleOnChange([...(value as T[]), option]);
                    } else {
                      handleOnChange([...(value as T[]).filter((v) => JSON.stringify(v) !== JSON.stringify(option))]);
                    }
                  }}
                />
              ))}

            {options.length === 0 && (
              <p className='text-zinc-900 text-center font-medium py-1.5 px-2'>No options available</p>
            )}
          </div>
        </DropdownBody>
      </DropdownContent>
    </Dropdown>
  );
};
