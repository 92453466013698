import Stripe from 'stripe';
import { DisplayedAICreditRechargeOption } from './types';
import toast from 'react-hot-toast';
import { deriveError } from '../templates/utils';
import { ProductsWithPrices } from 'services';

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  currencyDisplay: 'narrowSymbol',
  // do not round
  // These options are needed to round to whole numbers if that's what you want.
  // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});
export const formatStripeAmount = (v?: number) => formatter.format(v ? v / 100 : 0);

export const createOptionFromStripePrice = (price: Stripe.Price) => {
  if (!price.unit_amount) return;
  if (price.type === 'recurring')
    return {
      id: price.id,
      label: 'Unlimited',
      description: `${formatStripeAmount(price.unit_amount)} per ${price.recurring?.interval}`,
      amount: price.unit_amount,
      currency: price.currency,
    };
  if (price.transform_quantity?.divide_by)
    return {
      id: price.id,
      label: `${price.transform_quantity.divide_by} credits`,
      description: `${formatStripeAmount(price.unit_amount)} / ${formatStripeAmount(
        price.unit_amount / price.transform_quantity.divide_by,
      )} per credit`,
      amount: price.unit_amount,
      currency: price.currency,
    };
};

export const formatStripeAddress = (
  address?: {
    city?: string | null;
    country?: string | null;
    line1?: string | null;
    line2?: string | null;
    postal_code?: string | null;
    state?: string | null;
  } | null,
) => {
  if (!address) return '';
  let a = [address.line1, address.line2, address.city, address.state].filter(Boolean).join(', ');
  if (address.postal_code) a += a === '' ? `${address.postal_code}` : ` - ${address.postal_code}`;
  if (address.country) a += a === '' ? `${address.country}` : `, ${address.country}`;
  return a;
};

export const prepareInvoiceTableData = (invoicesFromStripe: any[] = []) => ({
  Data: invoicesFromStripe
    .filter((invoice) => invoice.status !== 'draft')
    .map((invoice: Stripe.Invoice) => {
      const timestamp = invoice.due_date ?? invoice.effective_at;

      const date = timestamp ? new Date(timestamp * 1000) : undefined;

      const issueTimestamp = invoice.status_transitions.finalized_at;
      const issueDate = issueTimestamp ? new Date(issueTimestamp * 1000) : undefined;

      const card = (invoice.charge as Stripe.Charge)?.payment_method_details?.card;

      const transactionHash = invoice.metadata?.transactionHash;
      const chain = invoice.metadata?.chain;

      return {
        invoiceNumberAndIssueDate: {
          number: invoice.number,
          issueDate,
        },
        status: invoice.status,
        dueDate: date,
        paymentMethod: {
          brand: card?.brand,
          last4: card?.last4,
          transactionHash,
          chain,
        },
        totalAmount: invoice.amount_due / 100,
        links: invoice,
      };
    }),
});

export const getStripeSubscriptionPlanName = (subscription) => {
  try {
    return subscription.items.data[0].price.product.name;
  } catch (error) {
    console.error(error);
    return '';
  }
};

export const prepareDisplayedOptionsForAddAICreditModal = (aiCreditRechargeProducts, cadence = 'month') => {
  const options: DisplayedAICreditRechargeOption[] = [];
  aiCreditRechargeProducts?.forEach((product) => {
    product.prices
      .sort((a) => (a.type === 'recurring' ? 1 : -1))
      .filter((price) => {
        if (!price.recurring?.interval) return true;
        if (price.recurring.interval === cadence) return true;
        return false;
      })
      .forEach((price) => {
        const option = createOptionFromStripePrice(price);
        if (option) options.push(option);
      });
  });
  return options;
};

export const prepareDisplayedOptionsForEditSubscriptionModal = (
  subscriptionProducts?: ProductsWithPrices,
  currentCadence?: Stripe.Price.Recurring.Interval,
) => {
  const options: DisplayedAICreditRechargeOption[] = [];
  subscriptionProducts
    ?.filter((p) => p.product.metadata.isCustomPackage !== 'true')
    .forEach((productWithPrices) => {
      const prices = currentCadence
        ? productWithPrices.prices.filter((price) => price.recurring?.interval === currentCadence)
        : productWithPrices.prices;

      prices.forEach((price) => {
        const option = {
          id: price.id,
          label: productWithPrices.product.name,
          description: `${formatStripeAmount(price.unit_amount as number)} per ${price.recurring?.interval}`,
          amount: price.unit_amount as number,
          currency: price.currency,
        };
        if (option) options.push(option);
      });
    });
  // console.log({ subscriptionProducts });
  return options;
};

type ToastMessages = Partial<{
  loading: string;
  success: string;
}>;
type WithToastsCallback = () => any | Promise<any>;
export const withToasts = (callback: WithToastsCallback, messages: ToastMessages) => async () => {
  const toastId = toast.loading(messages.loading ?? 'Loading');
  try {
    const shouldNotShowToast = await callback();
    if (!shouldNotShowToast) toast.success(messages.success ?? 'Success', { id: toastId });
    else toast.dismiss(toastId);
  } catch (error) {
    toast.error(deriveError(error), { id: toastId });
  }
};

export const getStatusIndicatorTypeForSubscription = (status) => {
  switch (status) {
    case 'incomplete':
      return 'danger';
    case 'incomplete_expired':
      return 'danger';
    case 'trialing':
      return 'warning';
    case 'active':
      return 'positive';
    case 'past_due':
      return 'danger';
    case 'canceled':
      return 'info';
    case 'unpaid':
      return 'warning';

    default:
      return undefined;
  }
};

export const getSubscriptionInterval = (price) => {
  const interval = price?.recurring?.interval;
  const intervalCount = price?.recurring?.interval_count;

  if (intervalCount === 1) return `per ${interval}`;
  return `every ${intervalCount} ${interval}s`;
};
