import React from 'react';
import styled from 'styled-components';
import { FilterContainerProps } from './types';
const FilterStyle = styled.div`
  & > div[data-radix-popper-content-wrapper] {
    display: block !important;
  }
  @media screen and (max-width: 1400px) {
    & > div {
      display: none;
    }
  }
`;

export const FilterContainer = ({ children, className, style }: FilterContainerProps) => {
  return (
    <FilterStyle className={`flex gap-x-2 ${className}`} style={style}>
      {children}
    </FilterStyle>
  );
};

export default FilterContainer;
