import React, { useEffect, useState } from 'react';
import { ConditionsGroup } from '../ConditionsGroup';
import { useConditionSelectorContext } from '../condition-selector-context/ConditionConfiguratorContext';
import { ExchangeSource, Tag, Wallet } from 'schemas';
import { FACT } from '../../types';
import { SourcePicker, filterExchangesFn, filterTagsFn, filterWalletsFn } from '../../../../source-picker';
import { useAddCondition } from '../useAddCondition';
import { useSourceTags, useSources } from '../../../../../hooks';
import { useGetExchangeSource } from '../../../../../hooks/http/useExchangeSource';
import { ConditionGroupFilterConfig } from '../types';
import { formatExchangeTypeToName } from '../../../../PageComponents';
import { ConditionSelectorRow } from '../ConditionSelectorRow';
import { MdCheck } from 'react-icons/md';
import { getWalletTags } from '../../../utils';

export const useSourceConditionGroups = () => {
  const { data: walletPages, isLoading: isLoadingWallets } = useSources({ pageSize: 1000 });
  const [allWallets, setAllWallets] = useState<Wallet[]>([]);

  const { data: exchanges, isLoading: isLoadingExchanges } = useGetExchangeSource();
  const [allExchanges, setAllExchanges] = useState<ExchangeSource[]>([]);

  const { data: tagPages, isLoading: isLoadingTags } = useSourceTags();
  const [allTags, setAllTags] = useState<Tag[]>([]);

  useEffect(() => {
    if (tagPages) setAllTags(getWalletTags(tagPages));
  }, [tagPages]);

  useEffect(() => {
    if (walletPages)
      setAllWallets(walletPages.pages.map((wr) => wr.wallets.map((wallet) => ({ ...wallet, selected: false }))).flat());
  }, [walletPages]);

  useEffect(() => {
    if (exchanges) {
      setAllExchanges(
        exchanges?.sources?.map((source) => ({
          ...source,
          chain: 'Exchange',
          address: formatExchangeTypeToName(source.exchangeSourceType),
          walletType: 'internal',
          selected: false,
        })),
      );
    }
  }, [exchanges]);

  const [conditionGroups, setConditionGroups] = useState<Omit<ConditionGroupFilterConfig<any>, 'rows'>[]>([
    {
      heading: 'Wallets',
      data: allWallets,
      filterFn: filterWalletsFn,
      Row: WalletConditionSelectorRow,
    },
    {
      heading: 'Exchanges',
      data: allExchanges,
      filterFn: filterExchangesFn,
      Row: ExchangeConditionSelectorRow,
    },
    {
      heading: 'Tags',
      data: allTags,
      filterFn: filterTagsFn,
      Row: TagConditionSelectorRow,
    },
  ]);

  useEffect(() => {
    setConditionGroups([
      {
        heading: 'Wallets',
        data: allWallets,
        filterFn: filterWalletsFn,
        Row: WalletConditionSelectorRow,
      },
      {
        heading: 'Exchanges',
        data: allExchanges,
        filterFn: filterExchangesFn,
        Row: ExchangeConditionSelectorRow,
      },
      {
        heading: 'Tags',
        data: allTags,
        filterFn: filterTagsFn,
        Row: TagConditionSelectorRow,
      },
    ]);
  }, [allWallets, allExchanges, allTags]);

  return {
    conditionGroups,
    isLoading: isLoadingWallets || isLoadingExchanges || isLoadingTags,
    isLoadingTags,
    isLoadingExchanges,
    isLoadingWallets,
  };
};

export const WalletConditionSelectorRow = ({ conditionData }) => {
  const addCondition = useAddCondition();
  const { state } = useConditionSelectorContext();
  const wallet = conditionData.rowData as Wallet;
  return (
    <ConditionSelectorRow asChild>
      <div
        className='hover:bg-[#F8F8F8] py-1 px-4 rounded-lg flex items-center justify-between cursor-pointer'
        onClick={() => {
          const data = {
            fact: FACT.WALLET,
            value: {
              internalWallet: {
                address: wallet.address,
                id: wallet._id,
                label: wallet.name || `${wallet.chain} · ${wallet.walletType}`,
              },
            },
            operator: 'isCredited',
          };
          addCondition(data);
        }}
      >
        <div>
          <div>{wallet.name}</div>
          <div>
            {wallet.chain} · {wallet.walletType}
          </div>
        </div>
        <div>{state.selected.value === conditionData._id && <MdCheck />}</div>
      </div>
    </ConditionSelectorRow>
  );
};
export const ExchangeConditionSelectorRow = ({ conditionData }) => {
  const addCondition = useAddCondition();
  const { state } = useConditionSelectorContext();
  const exchangeSource = conditionData.rowData as ExchangeSource;
  return (
    <ConditionSelectorRow asChild>
      <div
        className='hover:bg-[#F8F8F8] py-1 px-4 rounded-lg flex items-center justify-between cursor-pointer'
        onClick={() => {
          const data = {
            fact: FACT.COINBASE_PRIME,
            operator: 'coinbasePrimeIsCredited',
            value: {
              coinbasePrimeId: exchangeSource._id,
            },
          };
          addCondition(data);
        }}
      >
        <div>
          <div>{exchangeSource.exchangeSourceType === 'COINBASE_PRIME' ? 'Coinbase Prime' : 'Exchange'}</div>
        </div>
        <div>{state.selected.value === exchangeSource._id && <MdCheck />}</div>
      </div>
    </ConditionSelectorRow>
  );
};

export const TagConditionSelectorRow = ({ conditionData }) => {
  const addCondition = useAddCondition();
  const { state } = useConditionSelectorContext();
  const tag = conditionData.rowData as Tag;
  return (
    <ConditionSelectorRow asChild>
      <div
        className='hover:bg-[#F8F8F8] py-1 px-4 rounded-lg flex items-center justify-between cursor-pointer'
        onClick={() => {
          const data = {
            fact: FACT.WALLET,
            operator: 'isCredited',
            value: {
              internalWallet: {
                tagId: tag._id,
                label: `${tag.entry.key}: ${tag.entry.value}`,
              },
            },
          };
          addCondition(data);
        }}
      >
        <div>
          <div>
            {tag.entry.key} · {tag.entry.value}
          </div>
        </div>
        <div>{state.selected.value === tag._id && <MdCheck />}</div>
      </div>
    </ConditionSelectorRow>
  );
};

export const SourceConditionsMenu = () => {
  const { searchTerm, setSearchTerm } = useConditionSelectorContext();
  const addCondition = useAddCondition();

  const onSourceChange = (source: Wallet | ExchangeSource | Tag) => {
    if ((source as ExchangeSource).exchangeSourceType) {
      if ((source as ExchangeSource).exchangeSourceType === 'COINBASE_PRIME') {
        const conditionData = {
          fact: FACT.COINBASE_PRIME,
          operator: 'coinbasePrimeIsCredited',
          value: {
            coinbasePrimeId: source._id,
          },
        };
        addCondition(conditionData);
      }
    } else if ((source as Tag)?.entry?.key) {
      const conditionData = {
        fact: FACT.WALLET,
        operator: 'isCredited',
        value: {
          internalWallet: {
            tagId: source._id,
            label: `${(source as Tag).entry.key}: ${(source as Tag).entry.value}`,
          },
        },
      };
      addCondition(conditionData);
    } else {
      const conditionData = {
        fact: FACT.WALLET,
        value: {
          internalWallet: {
            address: (source as Wallet).address,
            id: source._id,
            label: (source as Wallet).name || (source as ExchangeSource).exchangeSourceType,
          },
        },
        operator: 'isCredited',
      };
      addCondition(conditionData);
    }
  };

  return (
    <ConditionsGroup label='Sources' hideSearchBar>
      <SourcePicker searchTerm={searchTerm} setSearchTerm={setSearchTerm} onChange={onSourceChange} />
    </ConditionsGroup>
  );
};
