import React, { useState } from 'react';
import { DetailsItem, SidebarSection } from '../../../atoms';
import toast from 'react-hot-toast';
import { HiOutlinePencil } from 'react-icons/hi2';
import {
  deriveError,
  formatDollars,
  formatSignificantDigits,
  getFloatFromDollarFormat,
} from '../../../templates/utils';
import { useBulkEditTransactionsCostBasis, useInvalidateQuery } from '../../../../hooks';
import { formatTableNumbers } from 'global-utils';

const CostBasis = ({ transaction, isLoading }) => {
  const [isEditingCostBasis, setIsEditingCostbasis] = useState(false);
  const { invalidateTransactionById } = useInvalidateQuery();
  const { mutateAsync: editTransactionCostBasis, isLoading: isUpdatingTransactionCostBasis } =
    useBulkEditTransactionsCostBasis();
  return (
    <div>
      <SidebarSection loading={isLoading}>
        {isEditingCostBasis ? (
          <>
            <div className='flex flex-row'>
              <DetailsItem
                onEditConfirm={async (costBasis) => {
                  // this should not run if the transaction is undefined (cost of using one component to do everything)
                  if (!transaction) return;
                  let toastId = toast.loading(`Updating cost basis for transaction ${transaction.sequenceNumber}`);
                  try {
                    await editTransactionCostBasis(
                      [
                        {
                          _id: transaction._id,
                          costBasis: getFloatFromDollarFormat(costBasis),
                        },
                      ],
                      {},
                    );
                    setIsEditingCostbasis(false);
                    await invalidateTransactionById({ transactionId: transaction._id });
                    toast.success(`Cost basis for transaction ${transaction.sequenceNumber} updated`, {
                      id: toastId,
                    });
                  } catch (error) {
                    toast.error(deriveError(error), { id: toastId });
                  }
                }}
                tag='Cost Basis'
                value={formatDollars(transaction.assetUnitPrice?.$numberDecimal)}
                interactionType='edit'
                disabled={isUpdatingTransactionCostBasis}
              />
            </div>
          </>
        ) : (
          <>
            <div className='flex flex-row'>
              <DetailsItem
                trailingComponent={<HiOutlinePencil className='cursor' onClick={() => setIsEditingCostbasis(true)} />}
                tag='Cost Basis'
                value={formatDollars(transaction?.assetUnitPrice?.$numberDecimal)}
              />
            </div>
          </>
        )}
        <DetailsItem tag='Token quantity' value={formatSignificantDigits(transaction?.grossAmount?.$numberDecimal)} />
        <DetailsItem tag='Gross price' value={formatTableNumbers({ value: transaction?.grossPrice?.$numberDecimal })} />
        <DetailsItem tag='Net price' value={formatTableNumbers({ value: transaction?.netPrice?.$numberDecimal })} />
        <DetailsItem tag='Fee price' value={formatTableNumbers({ value: transaction?.feePrice?.$numberDecimal })} />
      </SidebarSection>
    </div>
  );
};

export default CostBasis;
