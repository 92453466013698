import { useSsr } from 'usehooks-ts';
import axios, { AxiosRequestConfig } from 'axios';
import queryString from 'query-string';
import { format } from 'date-fns';
import jwtDecode from 'jwt-decode';
import { ObjectType } from '../../../apps/next/src/context';

declare module 'axios' {
  export interface AxiosRequestConfig {
    excludeAuthorizationHeader?: boolean;
    skipOrganizationId?: boolean;
    forwardOrganizationIdAsArray?: boolean;
    skipEverything?: boolean;
  }
}

export const convertQuery = (queryData: string[], queryName: string) => {
  const result = queryData?.length > 0 ? `&${queryData.map((item) => `${queryName}=${item}`).join('&')}` : '';

  // console.log(`convertQuery: `, { queryData, result });
  return result;
};

const { isBrowser } = useSsr();

export const unprotectedPaths: (string | undefined)[] = [
  '',
  '/',
  '/login',
  '/sign-up',
  '/forgot-password',
  '/reset-password',
  '/slice-simulator',
  '/landing',
  '/about',
  '/profile/accept-organization-invite-existing-user',
  '/profile/accept-organization-invite',
  '/reset-password',
  '/change-workspace',
];

export const extractSessionFromLocalstorage = () => {
  try {
    const sessionString = localStorage.getItem('session');
    if (sessionString === 'undefined') throw new Error('Session string is "undefined"');
    if (sessionString === null) throw new Error('Session string is null');
    const session = JSON.parse(sessionString);
    return session;
  } catch (error) {
    console.error('Could not extract session from local storage.', error);
  }
  return {};
};

export const isSessionTokenValid = (token: string) => {
  try {
    const decoded: { exp: number } = jwtDecode(token);
    if (!decoded) throw new Error('Could not parse jwt');
    if (Date.now() / 1000 >= decoded.exp) throw new Error('jwt expired');
    return true;
  } catch {
    return false;
  }
};

export const addInterceptor = () => {
  if (process.env.IS_STORYBOOK) return;

  if (isBrowser) {
    axios.interceptors.request.use(
      function (config: AxiosRequestConfig) {
        const validPath =
          unprotectedPaths.includes(
            unprotectedPaths.find(
              (path) => window.location.pathname === path || window.location.pathname === path + '/',
            ),
          ) ||
          window.location.pathname.includes('/accept-organization-invite') ||
          window.location.pathname.includes('/reset-password');

        if (validPath) return config;
        if (config.skipEverything) return config;

        const session = extractSessionFromLocalstorage();

        try {
          if (!config.excludeAuthorizationHeader) {
            // do not send request if there is no session token, reload window to force a logout
            if (!isSessionTokenValid(session.token)) {
              localStorage.removeItem('session');
              return window.location.reload();
            }

            // try to attach authorization header
            if (!config.headers) config.headers = {};
            config.headers.Authorization = session.token || '';

            if (!config.skipOrganizationId) {
              // try to attach organizationId if needed
              const url = new URL(`${config.url}`);
              const params = queryString.parse(url.search);
              if (session.organizationId && !params?.organizationId && !params?.organizationIds) {
                if (config.forwardOrganizationIdAsArray) params.organizationIds = session.organizationId;
                else params.organizationId = session.organizationId;
              }
              return { ...config, url: `${url.origin}${url.pathname}?${queryString.stringify(params)}` };
            }
          }
        } catch (error) {
          console.error(error);
        }

        return config;
      },
      function (error) {
        // Do something with request error

        return Promise.reject(error);
      },
    );
    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (error.response?.status === 401) {
          localStorage.removeItem('session');
        }

        return Promise.reject(error);
      },
    );

    // console.log('axios interceptor attached');
  }
};

export const formatDateForQuery = (date: Date) => format(date, 'yyyy-MM-dd');

export function formatExchangeTypeToName(str?: string) {
  return str
    ? str
        .toLowerCase()
        .split('_')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    : '';
}

export const getSubdomain = () => {
  if (typeof window !== 'undefined') {
    const { hostname } = window.location;
    const subdomain = hostname.split('.')[0];
    if (subdomain === 'com') {
      return 'entendre';
    }
    return subdomain;
  }
  return;
};

export const formatDate = (date?: Date) => {
  if (!date) return 'Invalid date';

  if (date.getFullYear() === new Date().getFullYear()) {
    return date.toLocaleDateString('en', {
      month: 'short',
      day: 'numeric',
    });
  } else {
    return date.toLocaleDateString('en', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });
  }
};

export const transformGptInstruction = (instruction: string, selectedIntent: string | undefined | null) => {
  const regex = new RegExp(/@\[(.*?)\]\((.*?)\)/g);
  let mentionedObject: ObjectType | undefined;
  instruction.match(regex)?.forEach((match) => {
    // find object type
    if (match.includes('WALLET_')) {
      mentionedObject = 'WALLET';
      instruction = instruction.replace('WALLET_', '');
    } else if (match.includes('LEDGER_ACCOUNT_')) {
      mentionedObject = 'LEDGER_ACCOUNT';
      instruction = instruction.replace('LEDGER_ACCOUNT_', '');
    } else if (match.includes('LEGAL_ENTITY_')) {
      mentionedObject = 'LEGAL_ENTITY';
      instruction = instruction.replace('LEGAL_ENTITY_', '');
    }
  });

  // if selected intent is of CRUD type, append the intent to the instruction
  if (selectedIntent) {
    const intent = selectedIntent.toLowerCase();
    if (intent.includes('create') || intent.includes('update') || intent.includes('delete')) {
      const rawInstruction = instruction.replace(regex, '$1');
      return {
        instructionWithMarkup: `${intent} ${instruction}`,
        rawInstruction: `${intent} ${rawInstruction}`,
        mentionedObject,
      };
    }
  }

  const rawInstruction = instruction.replace(regex, '$1');

  return {
    instructionWithMarkup: instruction,
    rawInstruction,
    mentionedObject,
  };
};
