import { googleLogout } from '@react-oauth/google';
import { useSession } from './useSession';
import { deleteCookie } from 'cookies-next';
import { useLogoutMutation } from './http';
import toast from 'react-hot-toast';
import { useRouter } from 'next/navigation';

export const useLogout = () => {
  const { clearSession } = useSession();
  const { mutateAsync: logout } = useLogoutMutation();
  const router = useRouter();

  const cleanup = async () => {
    try {
      await logout();
    } catch (error) {
      console.error(error);
    }

    googleLogout();
    clearSession();
    localStorage.removeItem('workspace');
    deleteCookie('organizationId');
    deleteCookie('jwt');
    toast.success('Logged out');
    router.replace('/login');
  };

  return cleanup;
};
