import { DropDownSelect } from '../../../../atoms';
import { useRuleConfigurator } from '../../rule-configurator-context';
import { selectorStyle } from './selector-styles';
import { ConditionRow } from './ConditionRow';
import { FACT } from '../../types';
import { AssetInlineConditionMenu } from '../../../AssetInlineConditionMenu';
import { getMappedValue } from '../../utils';
import { useCondition } from '../useCondition';

const Row = () => {
  const { updateCondition, isDisabled } = useRuleConfigurator();
  const condition = useCondition();
  return (
    <>
      <DropDownSelect customStyle={selectorStyle} defaultValue={{ label: 'Asset', value: 'Asset' }} isDisabled />
      <DropDownSelect
        isDisabled={isDisabled}
        customStyle={selectorStyle}
        options={getMappedValue(FACT.ASSET, 'operators')}
        defaultValue={{ label: 'Equals', value: 'equal' }}
        value={getMappedValue(FACT.ASSET, 'operators', condition.operator)}
        onChange={({ value }) => {
          updateCondition(condition.id, {
            operator: value,
          });
        }}
      />
      <AssetInlineConditionMenu
        isDisabled={isDisabled}
        rule={{ ...condition, value: condition.value.toUpperCase() }}
        callbackFn={(value) => {
          updateCondition(condition.id, {
            value: value.toLowerCase(),
          });
        }}
      />
    </>
  );
};

export const AssetRuleCondition = () => (
  <ConditionRow facts={[FACT.ASSET]}>
    <Row />
  </ConditionRow>
);
