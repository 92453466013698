import axios from 'axios';
import { SERVER_URL_CONFIGURATION_SERVICE, SERVER_URL_CORE } from '../../config';
import { buildMultiParams, prepareSearchParams } from './utils';
import { useAddExternalSourcePropsType } from '../types';
import { BulkCategorizeTransactionsParams } from '../../../../apps/next/src/hooks';

export const getTransactionById = async (data) => {
  const transactionIdsString = buildMultiParams(data.transactionIds, 'transactionIds');
  return axios.get(
    `${SERVER_URL_CORE}/transaction?organizationId=${data.organizationId}${transactionIdsString}&page=${data.page}&userId=${data.userId}&includeSpam=${data.includeSpam}`,
  );
};
export const getTransactionStats = async (params) => {
  const stringifiedParams = prepareSearchParams(params, { defaultSortFilterPropertyName: 'transactionDate' });
  return axios.get(`${SERVER_URL_CORE}/transaction-stats?${stringifiedParams}`);
};

export const patchTransaction = async (data) =>
  axios.patch(
    `${SERVER_URL_CORE}/transaction?id=${data.transactionId}&organizationId=${data.organizationId}`,
    data.body,
  );

export const postTransactionExternalSources = async (data: useAddExternalSourcePropsType) =>
  axios.post(
    `${SERVER_URL_CORE}/transaction-source?transactionIds=${data.transactionIds}&organizationId=${data.organizationId}&userId=${data.userId}`,
    data.body,
  );

export const bulkRecategorizeTransactions = async (data: BulkCategorizeTransactionsParams) => {
  return axios.post(`${SERVER_URL_CONFIGURATION_SERVICE}/recategorize-transactions`, data);
};

export const getLastTransactionsDate = async (data: { organizationId: string }) =>
  axios.get(`${SERVER_URL_CORE}/tx-last-queried?organizationId=${data.organizationId}`);

export const getTransactions = async (params) => {
  console.log(params);
  const stringifiedParams = prepareSearchParams(params, {
    isPaginated: true,
    defaultSortFilterPropertyName: 'transactionDate',
  });
  return axios.get(`${SERVER_URL_CORE}/transaction?${stringifiedParams}`);
};

export const bulkEditTransactionsCostBasis = (data) => {
  return axios.post(`${SERVER_URL_CONFIGURATION_SERVICE}/bulk-edit-tx-cost-basis`, {
    transactions: data,
  });
};
