import React from 'react';
import { setSaveData } from '../../../rules/utils';
import { NewConditionSelector, ConditionItem } from '..';
import ChainsCondition from './ChainsCondition';
import TransactionsCondititon from './TransactionsCondititon';
import RecentsCondition from './RecentsCondition';
import AssetCondition from './AssetCondition';
import SourcesCondition from './SourcesCondition';
import LegalEntityCondition from './LegalEntityCondition';
import { ConditionMenusProps } from './types';
import RawContractAddressCondition from './RawContractAddressCondition';
import HedgeyCondition from './HedgeyCondition';
import TransactionDirectionCondition from './TransactionDirectionCondition';
import RaincardsCondition from './RaincardsCondition';
import LoopCondition from './LoopCondition';
import TransactionClassificationCondition from './TransactionClassificationCondition';

export const MainCondition = ({ ruleSetData, getRuleSetData, isFirstLevel }: ConditionMenusProps) => {
  const defaultProps = {
    ruleSetData,
    getRuleSetData,
    isFirstLevel,
  };
  return (
    <NewConditionSelector>
      <RecentsCondition {...defaultProps} />
      <AssetCondition {...defaultProps} />
      <ChainsCondition {...defaultProps} />
      <SourcesCondition {...defaultProps} />
      <TransactionsCondititon {...defaultProps} />
      <TransactionDirectionCondition {...defaultProps} />
      <TransactionClassificationCondition {...defaultProps} />
      <RaincardsCondition {...defaultProps} />
      <LoopCondition {...defaultProps} />
      <ConditionItem
        label='Gain'
        onClick={() => setSaveData('Gain', undefined, isFirstLevel, ruleSetData, getRuleSetData)}
      />
      <ConditionItem
        label='Loss'
        onClick={() => setSaveData('Loss', undefined, isFirstLevel, ruleSetData, getRuleSetData)}
      />
      <LegalEntityCondition {...defaultProps} />
      <RawContractAddressCondition {...defaultProps} />
      <HedgeyCondition {...defaultProps} />
    </NewConditionSelector>
  );
};

export default MainCondition;
