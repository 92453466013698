import axios from 'axios';
import { SERVER_URL_CONFIGURATION_SERVICE, SERVER_URL_CORE, SERVER_URL_JOB_QUEUE } from '../../config';
import { convertQuery } from '../utils';
import { prepareSearchParams } from './utils';
import { JobConfiguration } from 'schemas';

export const getAccountPostingRuleSets = async (data) => {
  const { sort = { id: 'createdAt', desc: true } } = data;
  return axios.get(
    `${SERVER_URL_CORE}/account-posting-rule-set?organizationId=${data.organizationId}&page=${data.page}&sortBy=${
      sort.id
    }&sortDirection=${sort?.desc ? 'desc' : 'asc'}&searchTerm=${data.searchTerm || ''}`,
  );
};

export const getAccountPostingRuleSetById = async (data) =>
  axios.get(`${SERVER_URL_CORE}/account-posting-rule-set-id?accountPostingRuleSetId=${data.accountPostingRuleSetId}`);

export const createAccountPostingRuleSet = async (data) =>
  axios.post(`${SERVER_URL_CORE}/account-posting-rule-set`, {
    ...data,
  });

export const patchAccountPostingRuleSet = async (data) =>
  axios.patch(`${SERVER_URL_CORE}/account-posting-rule-set?accountPostingRuleSetId=${data._id}`, {
    ...data,
  });

export const deleteAccountPostingRuleSet = async (data) =>
  axios.delete(
    `${SERVER_URL_CORE}/account-posting-rule-set?accountPostingRuleSetId=${data._id}&organizationId=${data.organizationId}`,
  );

export type RunAllRulesetsManualTransactionHandlerPayload = {
  organizationId: string;
  userId: string;
  legalEntityIds: string[];
  accountingPeriodIds: string[];
  processTransactionsWithNoAccounting?: boolean;
  overWrite?: boolean;
};

export const runManualTransactionHandler = async (data: RunAllRulesetsManualTransactionHandlerPayload) =>
  axios.post(
    `${SERVER_URL_JOB_QUEUE}/transaction-job-manuel?organizationId=${data.organizationId}&userId=${
      data.userId
    }${convertQuery(data.legalEntityIds, 'legalEntityIds')}${convertQuery(
      data.accountingPeriodIds,
      'accountingPeriodIds',
    )}&processTransactionsWithNoAccounting=${
      data.processTransactionsWithNoAccounting ? 'true' : 'undefined'
    }&overWrite=${data.overWrite}`,
  );

export type RunSpecificRuleSetManualTransactionHandlerPayload = {
  organizationId: string;
  userId: string;
  ruleSetId: string;
  legalEntityIds: string[];
  accountingPeriodIds: string[];
  processTransactionsWithNoAccounting?: boolean;
  overWrite?: boolean;
};

export const runSpecificRuleSetManualTransactionHandler = async (
  data: RunSpecificRuleSetManualTransactionHandlerPayload,
) =>
  axios.post(
    `${SERVER_URL_JOB_QUEUE}/transaction-job-manuel?ruleSetId=${data.ruleSetId}&organizationId=${
      data.organizationId
    }&userId=${data.userId}${convertQuery(data.legalEntityIds, 'legalEntityIds')}${convertQuery(
      data.accountingPeriodIds,
      'accountingPeriodIds',
    )}&processTransactionsWithNoAccounting=${
      data.processTransactionsWithNoAccounting ? 'true' : 'undefined'
    }&overWrite=${data.overWrite}`,
  );

export type GetScheduledRulesetJobsParams = {
  page: number;
  pageSize: number;
  sortDirection: 'asc' | 'desc';
  sortBy: string;
};

export const getScheduledRulesetJobs = async (params: GetScheduledRulesetJobsParams) => {
  const searchParams = prepareSearchParams(params);

  const response = await axios.get(`${SERVER_URL_CONFIGURATION_SERVICE}/scheduled-ruleset-jobs?${searchParams}`);
  return {
    jobConfigurations: response.data.jobConfigurations as JobConfiguration[],
    nextCursor: response.data.jobConfigurations.length === params.pageSize ? params.page + 1 : false,
  };
};
