function isScientificNotation(numberOrString: number | string): boolean {
  const numberString = numberOrString.toString();
  return /^[+-]?\d+(\.\d+)?[eE][+-]?\d+$/.test(numberString);
}

export const formatTableNumbers = ({
  value,
  isAmount = false,
  decimalPlaces = 0,
}: {
  value: string | number;
  isAmount?: boolean;
  decimalPlaces?: number;
}): string => {
  const currencySymbol = isAmount ? '' : '$';
  let numericValue: number = parseFloat(`${value}`);
  let formattedResult: string = '';

  if (numericValue > 0.1) {
    formattedResult = `${currencySymbol}${numericValue.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  } else if (numericValue < 0.1 && numericValue !== 0 && numericValue > 0) {
    if (isScientificNotation(numericValue)) {
      numericValue = parseFloat(numericValue.toFixed(20));
    }
    const numericValueString = numericValue.toString().slice(1).split('');
    let digit = '';
    let checkDigitGreaterThanZero = false;

    for (let index = 0; index < numericValueString.length; index++) {
      digit += numericValueString[index];

      if (Number(numericValueString[index]) > 0) {
        checkDigitGreaterThanZero = true;
      }

      if ((checkDigitGreaterThanZero && decimalPlaces <= index) || index === numericValueString.length - 1) {
        formattedResult = `${currencySymbol}0${digit}`;
        break;
      }
    }
  } else {
    formattedResult = `${currencySymbol}${numericValue.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  }

  return formattedResult;
};

export const getTransactionChain = (assetType: string, chain: string, istransactionFromKraken: boolean) => {
  if (istransactionFromKraken) return 'KRAKEN';
  if (assetType === 'USD') return 'USD';
  return chain;
};
