import { useMutation, useQuery } from '@tanstack/react-query';
import {
  getRevaluationConfiguration,
  revalueAssetsForPeriod,
  upsertRevaluationConfiguration,
} from 'services/http/core';
import { useSession } from '../useSession';
import { QUERY_KEY, useOrgBasedQueryKey } from '../useInvalidateQuery';

export const enum REVALUATION_MODE {
  SINGLE_ACCOUNT = 'SINGLE',
  SEPARATE_GAIN_LOSS_ACCOUNTS = 'TUPLE',
}

export type RevaluationConfigurationType = {
  organizationId: string;
  setting: REVALUATION_MODE;
  single?: string;
  tuple?: {
    gain?: string;
    loss?: string;
  };
};

export const useUpsertRevaluationConfiguration = () =>
  useMutation((data: RevaluationConfigurationType) => upsertRevaluationConfiguration(data));

export type RevaluationAssetForPeriodType = {
  organizationId: string;
  accountingPeriodId: string;
  userId: string;
};

export const useRevalueAssetsForPeriod = () =>
  useMutation((data: RevaluationAssetForPeriodType) => revalueAssetsForPeriod(data));

export const useGetRevaluationConfiguration = () => {
  const { organizationId } = useSession();
  const getKey = useOrgBasedQueryKey();

  return useQuery(
    getKey(QUERY_KEY.ORGANIZATION_IMPAIRMENT_CONFIG),
    async () => {
      const response = await getRevaluationConfiguration({ organizationId });
      const { revaluationConfiguration } = response.data;
      return revaluationConfiguration;
    },
    {
      enabled: !!organizationId,
    },
  );
};
