import React, { useRef, useState } from 'react';
import SwitchGroup from '../atoms/SwitchGroup';
import {
  getDisplayedAccountingPeriods,
  mergeAccountingPeriods,
  getDisplayedLegalEntities,
  mergeLegalEntities,
} from '../templates/utils';
import { useLegalEntities, useAccountingPeriods } from '../../hooks/http';
import {
  InputLabel,
  Button,
  SingleSelectMenu,
  Dropdown,
  CheckboxListItem,
  DropdownBody,
  DropdownTitleWithToggle,
  DropdownContent,
  classNames,
  DropdownTrigger,
} from 'ui';
import { IoMdCloseCircle } from 'react-icons/io';
import { MdArrowDropDown } from 'react-icons/md';

interface RunAllRulesetsDialogFormProps {
  onClose: () => void;
  organizationId: string;
  onSubmit: (params: any) => void;
}

interface FormState {
  legalEntityIds: string[];
  accountingPeriodIds: string[];
  processTransactionsWithNoAccounting: boolean;
  overWrite: boolean;
}

const defaultFormState: FormState = {
  legalEntityIds: [],
  accountingPeriodIds: [],
  processTransactionsWithNoAccounting: false,
  overWrite: false,
};

export const RunAllRulesetsDialogForm = ({ onClose, onSubmit }: RunAllRulesetsDialogFormProps) => {
  const [form, setForm] = useState<FormState>(defaultFormState);
  const triggerRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);

  const { data: legalEntities } = useLegalEntities();
  let mergedLegalEntities = mergeLegalEntities(legalEntities);
  const displayedLegalEntities = getDisplayedLegalEntities(mergedLegalEntities);

  const accountingPeriods = useAccountingPeriods({ sort: -1, status: 'Open' });
  const mergedAccountingPeriods = mergeAccountingPeriods(accountingPeriods.data);
  let displayedAccountingPeriods = getDisplayedAccountingPeriods(mergedAccountingPeriods);

  const { legalEntityIds, accountingPeriodIds, processTransactionsWithNoAccounting, overWrite } = form;

  const runRule = () =>
    onSubmit({
      legalEntityIds,
      accountingPeriodIds,
      processTransactionsWithNoAccounting,
      overWrite,
    });

  const onCloseClick = () => {
    setForm(defaultFormState);
    onClose();
  };

  return (
    <>
      <div className='w-full flex flex-col flex-nowrap gap-8'>
        <div>
          <InputLabel heading='Entities' />

          <Dropdown open={open} onOpenChange={setOpen} modal>
            <DropdownTrigger ref={triggerRef}>
              <Button
                isFocused={open}
                emphasis='medium'
                onClick={() => setOpen((prev) => !prev)}
                label={
                  !legalEntityIds.length
                    ? 'Select entities'
                    : legalEntityIds.length === 1
                      ? displayedLegalEntities.find((option) => option.value === legalEntityIds[0])?.label
                      : legalEntityIds.length > 1
                        ? `${displayedLegalEntities.find((option) => option.value === legalEntityIds[0])?.label} +${
                            legalEntityIds.length - 1
                          }`
                        : ''
                }
                className='duration-100 py-0 w-full flex justify-between'
                trailingIconContainerClassname={classNames(
                  form.legalEntityIds.length && 'mr-0 p-2 duration-100 rounded-r-lg bg-white',
                )}
                trailingIcon={
                  <div className='flex items-center'>
                    {form.legalEntityIds.length > 0 && (
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                          setForm({ ...form, legalEntityIds: [] });
                        }}
                      >
                        <IoMdCloseCircle className='w-6 h-6 text-zinc-500' />
                      </span>
                    )}

                    <MdArrowDropDown className={classNames('duration-300 w-6 h-6 text-black', open && 'rotate-180')} />
                  </div>
                }
              />
            </DropdownTrigger>
            <DropdownContent
              avoidCollisions
              triggerRef={triggerRef}
              sameWidthAsTrigger
              enableHeightCalculation={true}
              className='p-1 !z-50'
            >
              <DropdownTitleWithToggle
                title='Select all'
                checked={form.legalEntityIds?.length === displayedLegalEntities.length}
                onChange={(checked: boolean) => {
                  if (checked) {
                    setForm({ ...form, legalEntityIds: displayedLegalEntities.map((option) => option.value) });
                  } else {
                    setForm({ ...form, legalEntityIds: [] });
                  }
                }}
              />
              <DropdownBody>
                <div className='overflow-hidden flex flex-col gap-1 flex-shrink-0'>
                  {displayedLegalEntities &&
                    displayedLegalEntities.map((option: any, idx) => (
                      <CheckboxListItem
                        key={idx}
                        enableSelectOnly={false}
                        label={option.label}
                        checked={legalEntityIds.includes(option.value)}
                        onCheckedChange={(checked) => {
                          if (checked) {
                            setForm({ ...form, legalEntityIds: [...legalEntityIds, option.value] });
                          } else {
                            setForm({ ...form, legalEntityIds: legalEntityIds.filter((id) => id !== option.value) });
                          }
                        }}
                      />
                    ))}
                </div>
              </DropdownBody>
            </DropdownContent>
          </Dropdown>
        </div>
        <div>
          <InputLabel heading='Run Period' />
          <SingleSelectMenu
            fullWidth
            isOnSidepanel
            isModal
            placeholder='Select period'
            value={displayedAccountingPeriods.find((option) => option.value === accountingPeriodIds[0])}
            options={displayedAccountingPeriods ?? []}
            onChange={({ value }) => setForm({ ...form, accountingPeriodIds: [value] })}
            onClearValue={() => setForm({ ...form, accountingPeriodIds: [] })}
          />
        </div>
        <SwitchGroup
          switchGroupItems={[
            { label: 'Only process transactions with no accounting', labelLeft: false, checked: false },
            { label: 'Overwrite accounting with latest match', labelLeft: false, checked: false },
          ]}
          switchStyle={'place-self-start'}
          clickFn={(switchGroupValue) => {
            setForm({
              ...form,
              processTransactionsWithNoAccounting: !!switchGroupValue[0].checked,
              overWrite: !!switchGroupValue[1].checked,
            });
          }}
          className='place-self-start flex flex-col items-start !gap-6'
        />
      </div>
      <div className='flex justify-end place-self-end mt-12 gap-2'>
        <Button onClick={onCloseClick} emphasis='medium' label='Cancel' />
        <Button onClick={runRule} label='Run rulesets' />
      </div>
    </>
  );
};
