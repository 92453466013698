import { useEffect, useState } from 'react';
import { FilterState } from './types';
import { FilterFn } from './types';

export function useSearch<T>(state: FilterState<T>, filterFn: FilterFn<T>) {
  const [searched, setSearched] = useState('');
  const [results, setResults] = useState<FilterState<T>>(state);

  useEffect(() => {
    const matchingIds = filterFn(state, searched);
    const matchingEntities = matchingIds.reduce((es, _id) => {
      es[_id] = state.entities[_id];
      return es;
    }, {});

    setResults((prev) => ({
      ...prev,
      entities: matchingEntities,
      ids: matchingIds,
    }));
  }, [state, filterFn, searched]);

  return { searched, setSearched, results };
}
