import { formatISO } from 'date-fns';
import { twMerge } from 'tailwind-merge';

export const classNames = twMerge;
export const getTransactionExplorerLink = (chain: string) => {
  if (chain === 'btc') return 'https://www.blockchain.com/explorer/transactions/btc';
  if (chain === 'eth') return 'https://etherscan.io/tx';
  if (chain === 'sol') return 'https://solscan.io/tx';
  if (chain === 'polygon') return 'https://polygonscan.com/tx';
  if (chain === 'bsc') return 'https://bscscan.com/tx';
  if (chain === 'near') return `https://explorer.near.org/transactions`;
  if (chain === 'arb') return 'https://arbiscan.io/tx';
  if (chain === 'opt') return 'https://optimistic.etherscan.io/tx';
  if (chain === 'base') return 'https://basescan.org/tx';
  if (chain === 'avalanche') return 'https://snowtrace.io/tx';
};

export const getAddressExplorerLink = (chain: string) => {
  if (chain === 'btc') return 'https://www.blockchain.com/explorer/addresses/btc';
  if (chain === 'eth') return 'https://etherscan.io/address';
  if (chain === 'sol') return 'https://solscan.io/account';
  if (chain === 'polygon') return 'https://polygonscan.com/address';
  if (chain === 'bsc') return 'https://bscscan.com/address';
  if (chain === 'near') return 'https://explorer.near.org/accounts';
  if (chain === 'arb') return 'https://arbiscan.io/address';
  if (chain === 'opt') return 'https://optimistic.etherscan.io/address';
  if (chain === 'base') return 'https://basescan.org/address';
  if (chain === 'avalanche') return 'https://snowtrace.io/address';
};

export const formatDateAsString = (date: Date) => formatISO(date, { representation: 'date' });

type TGetValueFromDataFn<T> = (d: T) => string;
type TGetOptionDataFnKey<T> = string | TGetValueFromDataFn<T>;
type TGetOptionLabelFromDataFnKey<T> = TGetOptionDataFnKey<T>;
type TGetOptionValueFromDataFnKey<T> = TGetOptionDataFnKey<T>;

export type TGetOptionsFromFn = <T>(
  data: T[],
  keys: [TGetOptionLabelFromDataFnKey<T>, TGetOptionValueFromDataFnKey<T>],
) => { label: string; value: string }[];

const getValueFromData = <T>(d: T, key: TGetOptionDataFnKey<T>) => {
  if (typeof key === 'string') return d[key as keyof T] as string;
  return key(d);
};

export const getMenuOptionsFrom: TGetOptionsFromFn = (data, keys) =>
  data.map((d) => ({ label: getValueFromData(d, keys[0]), value: getValueFromData(d, keys[1]) }));

export const truncateParagraph = (paragraph: string, maxLength?: number) => {
  if (typeof maxLength === 'undefined') maxLength = 75;
  if (typeof paragraph === 'number') return paragraph;
  if (typeof paragraph !== 'string') return '';

  if (paragraph.length <= maxLength) {
    return paragraph;
  }

  const words = paragraph.split(' ');
  let truncatedString = '';
  let currentLength = 0;

  for (const word of words) {
    if (currentLength + word.length + 1 <= maxLength) {
      truncatedString += word + ' ';
      currentLength += word.length + 1;
    } else {
      break;
    }
  }

  truncatedString = truncatedString.trim(); // Remove trailing space
  truncatedString = truncatedString.substring(0, truncatedString.length - 3) + '...'; // Replace last space with '...'

  return truncatedString;
};

export const currencyImg = (imgName: string) => {
  const CRYPTO_ICON_HOSTING_URL = 'https://ef-crypto-icons.s3.us-east-1.amazonaws.com/crypto-currency-icons';

  try {
    let icon = '';
    imgName = imgName.toLowerCase();
    if (imgName === 'kraken') {
      icon = '/kraken.png';
    } else if (imgName === 'polygon') {
      icon = `${CRYPTO_ICON_HOSTING_URL}/matic.png?raw=true`;
    } else if (imgName === 'ausdc') {
      icon = `${CRYPTO_ICON_HOSTING_URL}/aave.png?raw=true`;
    } else if (imgName === 'usdc') {
      icon = `${CRYPTO_ICON_HOSTING_URL}/usdc.png?raw=true`;
    } else if (imgName === 'sol') {
      icon = `https://s2.coinmarketcap.com/static/img/coins/64x64/5426.png`;
    } else if (imgName === 'dust') {
      icon =
        'https://github.com/solana-labs/token-list/blob/main/assets/mainnet/DUSTawucrTsGU8hcqRdHDCbuYhCPADMLM2VcCb8VnFnQ/logo.jpg?raw=true';
    } else if (imgName === 'bsc') {
      icon = `${CRYPTO_ICON_HOSTING_URL}/bnb.png?raw=true`;
    } else if (imgName === 'near') {
      icon = `https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/near-protocol.png?raw=true`;
    } else if (imgName === 'sweat') {
      icon = 'https://s2.coinmarketcap.com/static/img/coins/64x64/21351.png?raw=true';
    } else if (imgName === 'weth') {
      icon = 'https://github.com/spothq/cryptocurrency-icons/blob/master/32/color/eth.png?raw=true';
    } else if (imgName === 'avalanche') {
      icon = 'https://ef-crypto-icons.s3.us-east-1.amazonaws.com/crypto-currency-icons/avax.png';
    } else if (imgName === 'arb') {
      icon = 'https://cryptologos.cc/logos/arbitrum-arb-logo.png?v=025';
    } else if (imgName === 'opt') {
      icon = 'https://cryptologos.cc/logos/optimism-ethereum-op-logo.png?v=025';
    } else {
      icon = `${CRYPTO_ICON_HOSTING_URL}/${imgName}.png?raw=true`;
    }
    return icon;
  } catch (error) {
    return '';
  }
};
