import React, { useEffect, useState } from 'react';
import { useLedgerAccount, useLegalEntities, useTags } from '../../hooks/http';
import { SidebarBody } from '../atoms/Sidebar';
import {
  getDisplayedLedgerAccounts,
  getDisplayedLegalEntities,
  getDisplayedTags,
  getIdFromPopulatedInstance,
  mergeLedgerAccounts,
  mergeLegalEntities,
  mergeTags,
} from '../templates/utils';
import { SidebarSection } from '../atoms/Sidebar/SidebarBody/SidebarSection';
import { InputLabel, AmountInput, TextareaInput, SelectableCard, MultiSelectMenu, SingleSelectMenu } from 'ui';

export const JournalEntryLineBody = ({ existingLine, setLine, 'data-cy': dataCy }) => {
  const { data: legalEntities, isLoading: isLoadingLegalEntities } = useLegalEntities();
  const mergedLegalEntities = mergeLegalEntities(legalEntities);
  const displayedLegalEntities = getDisplayedLegalEntities(mergedLegalEntities);
  const { data: ledgerAccounts, isLoading: isLoadingLedgerAccounts } = useLedgerAccount({
    pageSize: 1000,
    isLeaf: true,
  });
  const mergedLedgerAccounts = mergeLedgerAccounts(ledgerAccounts);
  const displayedLedgerAccounts = getDisplayedLedgerAccounts(mergedLedgerAccounts);
  const { data: tags } = useTags();
  const mergedTags = mergeTags(tags);
  const displayedTags = getDisplayedTags(mergedTags);
  const selectedTags = getDisplayedTags(existingLine.tags);

  const [radioGroupItems, setRadioItems] = useState([
    { label: 'Credit', value: 'CREDIT' },
    { label: 'Debit', value: 'DEBIT' },
  ]);

  useEffect(() => {
    setRadioItems(
      radioGroupItems.map((radioItem) => ({ ...radioItem, checked: radioItem.value === existingLine?.creditOrDebit })),
    );
  }, [existingLine]);
  return (
    <SidebarBody>
      <SidebarSection numberOfColumns={1}>
        <div>
          <InputLabel heading='Legal Entity' />
          <SingleSelectMenu
            fullWidth
            isOnSidepanel
            data-cy={`${dataCy}__legalEntity`}
            options={displayedLegalEntities}
            value={displayedLegalEntities.find(
              (legalEntity) => legalEntity.value === getIdFromPopulatedInstance(existingLine?.legalEntityId),
            )}
            isLoading={isLoadingLegalEntities}
            onChange={(option) => setLine({ ...existingLine, legalEntityId: option.value })}
            onClearValue={() => setLine({ ...existingLine, legalEntityId: undefined })}
          />
        </div>
        <div>
          <InputLabel heading='Ledger Account' />
          <SingleSelectMenu
            fullWidth
            isOnSidepanel
            data-cy={`${dataCy}__ledgerAccount`}
            options={displayedLedgerAccounts}
            value={displayedLedgerAccounts.find(
              (ledgerAccount) => ledgerAccount.value === getIdFromPopulatedInstance(existingLine?.ledgerAccountId),
            )}
            isLoading={isLoadingLedgerAccounts}
            onChange={(option) => setLine({ ...existingLine, ledgerAccountId: option.value })}
            onClearValue={() => setLine({ ...existingLine, ledgerAccountId: undefined })}
          />
        </div>
        <div>
          <InputLabel heading='Type' />
          <div className='grid grid-cols-2 gap-4'>
            {radioGroupItems.map((radioItem, i) => (
              <SelectableCard
                data-cy={`${dataCy}__${radioItem.label}`}
                label={radioItem.label}
                selected={existingLine.creditOrDebit === radioItem.value}
                onClick={() => setLine((prev) => ({ ...prev, creditOrDebit: radioItem.value }))}
                key={i}
              />
            ))}
          </div>
        </div>
        <div>
          <InputLabel heading='Amount' />
          <AmountInput
            data-cy={`${dataCy}__amount`}
            value={existingLine?.amount || ''}
            onChange={(e) => setLine({ ...existingLine, amount: e.target.rawValue })}
            containerClassName='!w-full'
          />
        </div>
        <div>
          <InputLabel heading='Memo' />
          <TextareaInput
            data-cy={`${dataCy}__memo`}
            value={existingLine?.memo || ''}
            onChange={(e) => setLine({ ...existingLine, memo: e.target.value })}
          />
        </div>
        <div>
          <InputLabel heading='Tags' />
          <MultiSelectMenu
            side='top'
            data-cy={`${dataCy}__tags`}
            isOnSidepanel
            fullWidth
            options={displayedTags ?? []}
            value={selectedTags ?? []}
            onChange={(values) => {
              const tags = values
                .map((value: any) => mergedTags.find((tag) => tag._id === value.value))
                .filter((item) => item);
              setLine({ ...existingLine, tags });
            }}
            getLabelFromOption={(option: any) => option.label}
            getTriggerLabel={(option: any) => option?.label}
          />
        </div>
      </SidebarSection>
    </SidebarBody>
  );
};
