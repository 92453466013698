import React from 'react';
import { JournalsBarChart } from 'ui';
import { FILTER_TYPE } from '../../filters';
import { useGetJournalVolume } from '../../../hooks/http/useGetJournalVolume';

export const JournalsChart = ({ filterHelpers }) => {
  const { data: journalVolumeData, isLoading: isLoadingJournalEntryVolumeData } = useGetJournalVolume({
    status: filterHelpers[FILTER_TYPE.STATUS]?.getAllSelectedWithTransform(),
    accountingPeriodIds: filterHelpers[FILTER_TYPE.ACCOUNTING_PERIOD]?.getAllSelectedWithTransform(),
    legalEntityIds: filterHelpers[FILTER_TYPE.LEGAL_ENTITY]?.getAllSelectedWithTransform() ?? [],
  });
  return (
    <JournalsBarChart
      data={journalVolumeData?.data}
      isLoading={isLoadingJournalEntryVolumeData}
      accountingPeriodIds={filterHelpers[FILTER_TYPE.ACCOUNTING_PERIOD]?.getAllSelectedWithTransform()}
    />
  );
};

export default JournalsChart;
