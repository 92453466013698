import { useQuery } from '@tanstack/react-query';
import { getStripeDetails } from 'services';
import { QUERY_KEY, useOrgBasedQueryKey } from '../../../useInvalidateQuery';

export const useGetStripeDetailsForOrgQuery = () => {
  const getKey = useOrgBasedQueryKey();
  return useQuery(getKey(QUERY_KEY.STRIPE_DETAILS_FOR_ORG), async () => {
    const response = await getStripeDetails();
    return response.data.stripeDetails;
  });
};
