import axios from 'axios';
import { buildMultiParams, prepareSearchParams } from './utils';
import { SERVER_URL_CONFIGURATION_SERVICE, SERVER_URL_CORE, SERVER_URL_IMPAIRMENT_ENGINE } from '../../config';
import { PatchIRPayload, PostIRPayload } from '../response.types';
import { JobConfiguration } from 'schemas';
import { format } from 'date-fns';

export const getImpairmentRules = async (params) => {
  let qs = '';
  qs += buildMultiParams(params.organizationIds, 'organizationIds');
  qs += buildMultiParams(params.impairmentRuleIds, 'impairmentRuleIds');
  qs += `&page=${params.page}`;
  qs += `&pageSize=${params.pageSize}`;
  qs += `&startDate=${params.startDate}`;
  qs += `&endDate=${params.endDate}`;

  if (params.sort) {
    qs += `&sortBy=${params.sort.id}`;
    qs += `&sortDirection=${params.sort.desc ? 'desc' : 'asc'}`;
  }
  return axios.get(`${SERVER_URL_CORE}/impairment-rule?organizationId=${params.organizationId}${qs}`);
};

export const createImpairmentRule = async (body: PostIRPayload) => {
  return axios.post(`${SERVER_URL_CORE}/impairment-rule`, body.impairmentRule);
};

export const editImpairmentRule = async (body: PatchIRPayload) => {
  return axios.patch(`${SERVER_URL_CORE}/impairment-rule`, body);
};

export const duplicateImpairmentRule = async (body: { _id: string }) => {
  return axios.patch(`${SERVER_URL_CORE}/duplicate-impairment-rule`, body);
};

export const deleteImpairmentRule = async (params) => {
  const qs = buildMultiParams(params.impairmentRuleIds, 'impairmentRuleIds');
  return axios.delete(`${SERVER_URL_CORE}/impairment-rule?${qs}`);
};

export const triggerImpairmentRuleRun = async (params: {
  ruleId: string;
  impairmentRunDate?: Date;
  accountingPeriodId?: string;
}) => {
  const impairOnString = params.impairmentRunDate ? format(params.impairmentRunDate, 'yyyy-MM-dd') : undefined;

  return axios.post(`${SERVER_URL_IMPAIRMENT_ENGINE}/trigger-impairment-rule-run`, {
    ...params,
    impairmentRunDate: impairOnString,
  });
};

export type GetScheduledImpairmentJobsParams = {
  page: number;
  pageSize: number;
  sortDirection: 'asc' | 'desc';
  sortBy: string;
};

export const getScheduledImpairmentJobs = async (params: GetScheduledImpairmentJobsParams) => {
  const searchParams = prepareSearchParams(params);

  const response = await axios.get(`${SERVER_URL_CONFIGURATION_SERVICE}/scheduled-impairment-jobs?${searchParams}`);
  return {
    jobConfigurations: response.data.jobConfigurations as JobConfiguration[],
    nextCursor: response.data.jobConfigurations.length === params.pageSize ? params.page + 1 : false,
  };
};
