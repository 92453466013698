import axios from 'axios';
import { PatchLEPayload, PostLEPayload } from '../response.types';
import { SERVER_URL_CORE } from '../../config';
import { buildMultiParams } from './utils';
import { convertQuery } from '../utils';

export const createLegalEntity = async (data: PostLEPayload) => axios.post(`${SERVER_URL_CORE}/legal-entity`, data);

export const patchLegalEntity = async (data: PatchLEPayload) =>
  axios.patch(`${SERVER_URL_CORE}/legal-entity?legalEntityId=${data._id}`, data);

export const deleteLegalEntity = async (data) =>
  axios.delete(
    `${SERVER_URL_CORE}/legal-entity?legalEntityId=${data.legalEntityId}&organizationId=${data.organizationId}`,
  );

export const getLegalEntity = async (data) => {
  const legalEntityIds = buildMultiParams(data.legalEntityIds, 'legalEntityIds');
  return axios.get(
    `${SERVER_URL_CORE}/legal-entity?organizationId=${data.organizationId}&page=${data.page}&sortBy=${
      data.sort.id
    }&sortDirection=${data.sort.desc ? 'desc' : 'asc'}${legalEntityIds}`,
  );
};

export const bulkArchiveLegalEntity = async (data) =>
  axios.patch(
    `${SERVER_URL_CORE}/bulk-archive-legal-entity?organizationId=${data.organizationId}${convertQuery(
      data.legalEntityIds,
      'legalEntityIds',
    )}`,
  );

export const bulkDeleteLegalEntity = async (data) =>
  axios.delete(
    `${SERVER_URL_CORE}/bulk-delete-legal-entity?organizationId=${data.organizationId}${convertQuery(
      data.legalEntityIds,
      'legalEntityIds',
    )}`,
  );
