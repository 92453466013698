import { useEffect, useState } from 'react';
import { useLedgerAccount } from './http';
import { useExternalAccountMappings, useExternalAccounts } from './http/useGlIntegrationAccountQueries';

import { GLType } from 'services/http/types';
import { ExternalLedgerAccount } from 'services/http/response.types';
import { produce } from 'immer';
import { NormalizedLedgerEntries } from '../components/integrations/common/types';
import { useSession } from './useSession';

const buildInternalAccountIdToExternalAccountMap = (externalMappings): { [key: string]: string } => {
  if (!externalMappings) {
    return {};
  }
  const map = {};
  for (const externalMapping of externalMappings) {
    map[externalMapping.internalLedgerAccountId] = externalMapping.externalLedgerAccountId;
  }
  return map;
};

type MappingState = {
  [key: string]: string;
};

export const useLedgerAccountOptions = (glType: GLType, realmId: string) => {
  const { organizationId } = useSession();
  const { data: ledgerAccountResponse, isLoading: isLoadingLedgerAccount } = useLedgerAccount({
    pageSize: 1000,
  });

  const { data: externalAccountsResponse, isLoading: isExternalAccountLoading } = useExternalAccounts(
    { organizationId, glType, realmId },
    !!organizationId,
  );

  const [mappingState, setMappingState] = useState<MappingState>({});
  const {
    data: existingInternalAccountIdToExternalAccountIdMapping,
    isLoading,
    isSuccess,
  } = useExternalAccountMappings(organizationId, glType, realmId);
  useEffect(() => {
    if (!isLoading && isSuccess) {
      const internalAccountIdToExternalAccountMap = buildInternalAccountIdToExternalAccountMap(
        existingInternalAccountIdToExternalAccountIdMapping?.ledgerAccountMappings,
      );
      setMappingState(internalAccountIdToExternalAccountMap);
    }
  }, [existingInternalAccountIdToExternalAccountIdMapping, isLoading, isSuccess]);
  const [externalLedgerAccountOptions, setExternalLedgerAccountOptions] = useState<ExternalLedgerAccount[]>([]);

  useEffect(() => {
    if (externalAccountsResponse) {
      setExternalLedgerAccountOptions(externalAccountsResponse.externalLedgerAccounts);
    }
  }, [externalAccountsResponse]);

  const [normalizedEntries, setNormalizedEntries] = useState<NormalizedLedgerEntries>({
    ledgerAccountTypes: [],
    entities: {},
    ids: [],
  });

  useEffect(() => {
    if (ledgerAccountResponse) {
      setNormalizedEntries((prev) =>
        produce(prev, (draft) => {
          ledgerAccountResponse.pages.flat().forEach((entry) => {
            if (!draft.ids.includes(entry._id)) {
              draft.ids.push(entry._id);
            }
            draft.entities[entry._id] = {
              ...entry,
              value: mappingState[entry._id],
              children: [],
            };
            draft.ledgerAccountTypes = Array.from(new Set([...draft.ledgerAccountTypes, entry.ledgerAccountType]));
          });

          ledgerAccountResponse.pages.flat().forEach((entry) => {
            if (entry.parentLedgerAccountId) draft.entities[entry.parentLedgerAccountId]?.children.push(entry._id);
          });
        }),
      );
    }
  }, [ledgerAccountResponse, mappingState]);

  return {
    normalizedEntries,
    externalLedgerAccountOptions,
    isLoading: isLoadingLedgerAccount || isExternalAccountLoading,
  };
};
