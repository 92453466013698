import { useMemo } from 'react';
import { classNames } from '../utils';
import { PageHeaderDataCy, PageHeaderProps } from './types';
import useDimensions from 'react-cool-dimensions';

export const PageHeader = ({ title, description, actions }: PageHeaderProps) => {
  const { observe: containerRef, width: containerWidth } = useDimensions();
  const shouldShrink = useMemo(() => containerWidth < 500, [containerWidth]);

  return (
    <div ref={containerRef} className='w-full px-6 mt-6 mb-12'>
      <div
        className={classNames('w-full flex', shouldShrink ? 'flex-col items-start' : 'items-center justify-between')}
      >
        <div className='flex flex-col'>
          <p
            data-cy={`${PageHeaderDataCy.Title}${title.toLowerCase().split(' ').join('_')}`}
            className='text-4xl font-semibold mb-1'
          >
            {title}
          </p>
          {description && (
            <p
              data-cy={`${PageHeaderDataCy.Description}${title.toLowerCase().split(' ').join('_')}`}
              className='max-w-450 whitespace-normal'
            >
              {description} &emsp;
            </p>
          )}
        </div>
        <div className={classNames('flex items-center', shouldShrink ? 'mt-3' : 'flex-row-reverse')}>
          {actions} &emsp;
        </div>
      </div>
    </div>
  );
};
