import { MdChevronLeft } from 'react-icons/md';
import { Button } from 'ui';
import { useCommandPallete } from '../useCommandPallete';
import AddLegalEntityForm from '../../onboarding/AddLegalEntityForm';
import { useState } from 'react';
import { navigate } from '../../utils';
import { getHost } from '../../../lib/utils';
import { useKBar } from 'farish-kbar';

const CreateLegalEntity = () => {
  // on success, show success view with button to view created legal entity with this id
  const [legalEntityId, setLegalEntityId] = useState<string | null>(null);

  const { setSecondViewPath } = useCommandPallete();

  const { query } = useKBar();

  const SuccessView = () => {
    return (
      <div className='w-full z-commandpallet absolute bg-white h-full rounded-[14px] overflow-auto flex flex-col min-h-[300px] max-h-[90vh]'>
        <div className='flex flex-col items-center justify-center flex-grow'>
          <p className='text-2xl font-medium mt-4'>Legal entity added</p>
          <div className='gap-2 flex'>
            <Button onClick={() => setSecondViewPath('')} emphasis='medium' label='Command Bar' className='mt-4' />
            <Button
              onClick={() => {
                query.toggle();
                navigate(`${getHost()}/configure/entities/${legalEntityId}`);
              }}
              emphasis='high'
              label='View'
              className='mt-4'
            />
          </div>
        </div>
      </div>
    );
  };

  if (legalEntityId) return <SuccessView />;

  return (
    <div className='w-full z-commandpallet bg-white h-full rounded-[14px] overflow-auto min-h-[600px] flex flex-col max-h-[90vh]'>
      <div className='flex items-center px-6 py-4 border-b gap-x-4'>
        <Button
          label='Add legal entity'
          leadingIcon={<MdChevronLeft />}
          onClick={() => setSecondViewPath('')}
          className='hover:bg-indigo-50'
          emphasis='low'
          disableRingOnFocus
        />
      </div>
      <AddLegalEntityForm
        isOnboarding={false}
        hideHeader={true}
        onCancel={() => setSecondViewPath('')}
        onSuccess={(legalEntity) => setLegalEntityId(legalEntity._id)}
      />
    </div>
  );
};

export default CreateLegalEntity;
