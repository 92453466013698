export const redirectToLogin = () => {
  const shouldSkipLoginRedirect = localStorage.getItem('shouldSkipLoginRedirect');
  if (!shouldSkipLoginRedirect) {
    // set the flag and redirect to login
    console.warn('Request encountered a session error, redirecting to /login');
    localStorage.setItem('shouldSkipLoginRedirect', 'true');
    window.location.replace(`${window.location.origin}/login`);
  }
  console.warn('Request encountered a session error, but /login redirect was skipped');
};
