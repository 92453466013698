import React, { useEffect, useState } from 'react';
import { ClearInputTrailingAction } from '../clear-input-trailing-action';

import { useProbablyControlledStateForInput } from '../input-with-extras';
import { AmountInputProps } from './types';

import Cleave from 'cleave.js/react';
import 'cleave.js/dist/addons/cleave-phone.i18n';
import { classNames } from '../utils';
import useDimensions from 'react-cool-dimensions';

export const AmountInput = ({ currencyDescriptor, containerClassName, onEnter, ...props }: AmountInputProps) => {
  const { observe: leadingRef, width: leadingWidth } = useDimensions();
  const { observe: trailingRef, width: trailingWidth } = useDimensions();
  const { inputProps } = useProbablyControlledStateForInput({ value: props.value, onChange: props.onChange });
  const [padding, setPadding] = useState({ left: 0, right: 0 });

  const inputClassName = classNames(
    'form-input w-full h-full absolute rounded-lg border border-1.5 border-zinc-200 pl-3 pr-[14px] py-2',
    props.isError && '!border-[#ff0000]',
    props.isError && 'hover:ring-[#cc0000]',
    !props.disabled && 'hover:ring-2 hover:ring-[#808080]',
    props.isError && 'focus:ring-[#ff0000] focus:border-[#ff0000]',
    !props.disabled && 'focus:ring-2 focus:ring-[#0000ff]',
    props.disabled && 'opacity-50 pointer-none border-zinc-200 hover:ring-0',
    props.className,
  );

  useEffect(() => {
    setPadding({
      left: leadingWidth + 20,
      right: trailingWidth + 26,
    });
  }, [leadingWidth, trailingWidth]);
  return (
    <div
      className={classNames(
        'w-[280px] h-[40px] rounded-lg relative shadow-[0_1px_2px_rgba(0,0,0,0.05)]',
        containerClassName,
      )}
    >
      <Cleave
        {...props}
        {...inputProps}
        options={{ numeral: true, numeralThousandsGroupStyle: 'thousand' }}
        style={{
          paddingLeft: padding.left,
          paddingRight: padding.right,
        }}
        className={inputClassName}
        onKeyDown={(event) => {
          if (event.key === 'Enter' && onEnter) {
            onEnter();
          }
        }}
      />

      <span className='absolute flex items-center h-full left-[12px]' ref={leadingRef}>
        {currencyDescriptor}
      </span>
      <span className='absolute flex items-center h-full right-[14px]' ref={trailingRef}>
        <ClearInputTrailingAction value={inputProps.value} setValue={inputProps.onChange} disabled={props.disabled} />
      </span>
    </div>
  );
};
