import { useContext } from 'react';
import { JobProgressContext } from '../context/JobProgressProvider';
import { useSession } from './useSession';
import { JOB_NAME } from 'services/http/job-queue';
import { useSyncJournalEntriesJobMutation } from './http/useGlIntegrationAccountQueries';
import { GLType } from 'services/http/types';

export type CreateSyncJournalEntriesBgJobProps = {
  onSuccess?: () => void;
  onError?: (error: any) => void;
  realmId: string;
};

export const useCreateSyncJournalEntriesBgJob = ({
  onSuccess,
  onError,
  realmId,
}: CreateSyncJournalEntriesBgJobProps) => {
  // const { addJobProgress, isWebSocketReady } = useContext(JobProgressContext);
  const { startTrackingJob } = useContext(JobProgressContext);
  const { organizationId, userId } = useSession();

  const { mutateAsync } = useSyncJournalEntriesJobMutation();

  const createJournalEntryJob = async (accountingPeriodId: string, integrationType: GLType) => {
    try {
      await mutateAsync({
        organizationId,
        userId,
        accountingPeriodId,
        integrationType,
        realmId,
      });
      startTrackingJob(accountingPeriodId, JOB_NAME.SYNC_JOURNAL_ENTRY_JOB, false);
      if (onSuccess) onSuccess();
    } catch (error) {
      if (onError) onError(error);
      else throw error;
    }
  };

  return createJournalEntryJob;
};
