import { useSsr } from 'usehooks-ts';
import { onRequest } from './on-request';
import { onResponse } from './on-response';
import { onResponseError } from './on-response-error';
import { onRequestError } from './on-request-error';
import axios from 'axios';

const { isBrowser } = useSsr();

export const attachInterceptor = () => {
  if (process.env.IS_STORYBOOK) return;

  if (isBrowser) {
    axios.interceptors.request.use(onRequest, onRequestError);
    axios.interceptors.response.use(onResponse, onResponseError);
    // console.log('axios interceptor attached');
  }
};
