import axios from 'axios';
import { SERVER_URL_CORE, SERVER_URL_JOB_QUEUE } from '../../config';
import { convertQuery } from '../utils';

export const getExchangeSource = (params) => {
  return axios.get(
    `${SERVER_URL_CORE}/exchange-sources?organizationId=${params.organizationId}${convertQuery(
      params.legalEntityIds,
      'legalEntityIds',
    )}`,
  );
};

export const createExchangeSource = (body) => {
  return axios.post(`${SERVER_URL_CORE}/exchange-sources`, body);
};

export const updateExchangeSource = (params) => {
  const organizationId = params.organizationId;
  delete params.organizationId;
  return axios.patch(`${SERVER_URL_CORE}/exchange-sources?organizationId=${organizationId}`, params);
};

export const deleteExchangeSource = (params) => {
  return axios.delete(
    `${SERVER_URL_CORE}/exchange-sources?organizationId=${params.organizationId}&sourceId=${params.sourceId}`,
  );
};

export const getExchangeSourceSyncHistory = (params) => {
  return axios.get(
    `${SERVER_URL_CORE}/exchange-sources-sync-history?organizationId=${params.organizationId}&exchangeSourceId=${params.sourceId}`,
  );
};

export const createExchangeJob = (body) => {
  return axios.post(
    `${SERVER_URL_JOB_QUEUE}/exchange-jobs?organizationId=${body.organizationId}${
      body.accountingPeriodId ? '&accountingPeriodId=' + body.accountingPeriodId : ''
    }`,
    body,
  );
};
