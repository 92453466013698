import { useCommandPallete } from './useCommandPallete';
import { GPTServiceIntentEnum, JOB_NAME, FAILURE_MODE } from 'services';
import { useKBar } from 'farish-kbar';
import { getHost } from '../../lib/utils';
import { formatLedgerAccountResequencingTable, navigate } from '../utils';
import { Asset, LedgerAccount, LegalEntity, Tag, Wallet } from 'schemas';
import { FollowUpForms } from './FollowUpForms';
import { useContext } from 'react';
import { JobProgressContext } from '../../context/JobProgressProvider';
import { WALLET_TYPE } from 'services/http/response.types';
import { DetailsCard } from './DetailsCard';
import { MultipleEntitiesView } from './MultipleEntitiesView';
import { GPTChatResponse } from '../../context/CommandPalleteProvider';
import { GPTAnalyticsContainer } from './GPTAnalyticsContainer';
import { useUserObjects } from '../../hooks/useUserObjects';
import { ResequencingTable } from './ResequencingTable';
import { ResponseContainer } from './ResponseContainer';
import { FunctionCallJournalsTable, tableToFunctionMap } from './FunctionCallTables';
import { getDataFromConversation } from './utils';

const FailView = ({
  query,
  errorMessage = 'Please try again with another instruction.',
}: {
  query: string;
  errorMessage?: string;
}) => {
  return (
    <>
      <ResponseContainer isQuery>
        <p>{query}</p>
      </ResponseContainer>
      <ResponseContainer>
        <p>Sorry, I couldn&apos;t execute your instruction.</p>
        <p className='break-all'>{errorMessage}</p>
      </ResponseContainer>
    </>
  );
};

const getErrorMessageFromGPTResponse = (response: GPTChatResponse) => {
  if (!response.successERA || !response.successERA) {
    return response.devError;
  }
  if (!response.successCC) {
    return response.bulkCrudCallingResponses[0].devError;
  }
};

export const GPTFeedback: React.FC<{ gptChatResponse: GPTChatResponse }> = ({ gptChatResponse }) => {
  const { setGptChatResponses } = useCommandPallete();
  const { query } = useKBar();

  const { startTrackingJob } = useContext(JobProgressContext);

  const clearGPTResponseAndToggleKBar = () => {
    setGptChatResponses([]);
    query.toggle();
  };

  // check if it's validation error (missing fields)
  if (gptChatResponse.success === false) {
    if (gptChatResponse.failureMode === FAILURE_MODE.ASK_FOR_CONFIRMATION) {
      return <MultipleEntitiesView gptChatResponse={gptChatResponse} />;
    }
    if (!gptChatResponse) return null;

    // check if it's validation error (missing fields)
    if (gptChatResponse.success === false) {
      let crudResponses: any[] = [];
      let isValidationError = false;
      try {
        crudResponses = gptChatResponse.bulkCrudCallingResponses.map((res) => JSON.parse(res.coreCrudResponse.body));
        crudResponses.forEach((res) => {
          if (res.message.includes('ValidationError')) {
            isValidationError = true;
          }
        });
      } catch (e) {
        null;
      }
      gptChatResponse.bulkCrudCallingResponses.forEach((res) => {
        if (res.missingFields?.length || 0 > 0) {
          isValidationError = true;
        }
        if (res.devError?.includes('ValidationError')) {
          isValidationError = true;
        }
      });

      if (isValidationError) return <FollowUpForms gptResponse={gptChatResponse} query={gptChatResponse.query} />;
      return <FailView query={gptChatResponse.query} errorMessage={getErrorMessageFromGPTResponse(gptChatResponse)} />;
    }
  }

  query.setSearch(''); // clear NLP search bar if instruction was successful

  switch (gptChatResponse.intent) {
    case GPTServiceIntentEnum.LEGAL_ENTITY_RENAME:
    case GPTServiceIntentEnum.LEGAL_ENTITY_EDIT_TYPE:
    case GPTServiceIntentEnum.LEGAL_ENTITY_EDIT_CURRENCY:
    case GPTServiceIntentEnum.LEGAL_ENTITY_EDIT_ADDRESS: {
      const { addressString, entityName, _id } = gptChatResponse.returnedDocument as Partial<LegalEntity>;

      return (
        <ResponseContainer>
          {gptChatResponse.query}
          <DetailsCard
            number={1}
            title={entityName}
            subtitle={addressString}
            onClick={() => {
              navigate(`${getHost()}/configure/entities/${_id}`);
              clearGPTResponseAndToggleKBar();
            }}
          />
        </ResponseContainer>
      );
    }
    case GPTServiceIntentEnum.LEDGER_ACCOUNT_RENAME:
    case GPTServiceIntentEnum.LEDGER_ACCOUNT_EDIT_TYPE:
    case GPTServiceIntentEnum.LEDGER_ACCOUNT_EDIT_CLEARING:
    case GPTServiceIntentEnum.LEDGER_ACCOUNT_EDIT_PARENT: {
      const { ledgerAccountName, ledgerAccountSequence, _id, ledgerAccountType } =
        gptChatResponse.returnedDocument as Partial<LedgerAccount>;

      return (
        <ResponseContainer>
          {gptChatResponse.query}
          <DetailsCard
            number={1}
            title={`${ledgerAccountSequence}: ${ledgerAccountName}`}
            subtitle={ledgerAccountType}
            onClick={() => {
              navigate(`${getHost()}/configure/ledger-accounts/${_id}`);
              clearGPTResponseAndToggleKBar();
            }}
          />
        </ResponseContainer>
      );
    }
    case GPTServiceIntentEnum.WALLET_EDIT_ADDRESS:
    case GPTServiceIntentEnum.WALLET_EDIT_ALIAS:
    case GPTServiceIntentEnum.WALLET_EDIT_STATUS:
    case GPTServiceIntentEnum.WALLET_EDIT_LEGAL_ENTITY: {
      const { name, address, _id, chain } = gptChatResponse.returnedDocument as Partial<Wallet>; // TODO: fix this in the backend to return wallet doc not crud response

      return (
        <ResponseContainer>
          {gptChatResponse.query}
          <DetailsCard
            number={1}
            title={name || address}
            subtitle={chain}
            onClick={() => {
              navigate(`${getHost()}/ledger/sources/${_id}`);
              clearGPTResponseAndToggleKBar();
            }}
          />
        </ResponseContainer>
      );
    }
    case GPTServiceIntentEnum.WALLET_ADD_TAG: {
      const crudResponses = gptChatResponse.bulkCrudCallingResponses;
      return (
        <ResponseContainer>
          {gptChatResponse.query}
          {crudResponses.map((crudResponse, index) => {
            if (crudResponse.successCC) {
              const { name, address, _id } = crudResponse.crudResponse as Wallet;
              return (
                <DetailsCard
                  key={index}
                  number={index + 1}
                  title={name}
                  subtitle={address}
                  onClick={() => {
                    navigate(`${getHost()}/ledger/sources/${_id}`);
                    clearGPTResponseAndToggleKBar();
                  }}
                />
              );
            }
          })}
        </ResponseContainer>
      );
    }

    case GPTServiceIntentEnum.TAG_CREATE:
    case GPTServiceIntentEnum.TAG_EDIT_VALUE: {
      const { entry } = gptChatResponse.returnedDocument as unknown as Tag; // TODO: fix this in the backend to return wallet doc not crud response
      return (
        <ResponseContainer>
          {gptChatResponse.query}
          <DetailsCard
            number={1}
            title={entry.key}
            subtitle={entry.value}
            onClick={() => {
              navigate(`${getHost()}/configure/tags`);
              clearGPTResponseAndToggleKBar();
            }}
          />
        </ResponseContainer>
      );
    }
    case GPTServiceIntentEnum.ASSET_CREATE: {
      const { _id, assetType, chain } = gptChatResponse.returnedDocument as unknown as Asset; // TODO: fix this in the backend to return wallet doc not crud response
      return (
        <ResponseContainer>
          {gptChatResponse.query}
          <DetailsCard
            number={1}
            title={chain}
            subtitle={assetType}
            onClick={() => {
              navigate(`${getHost()}/ledger/assets/${_id}`);
              clearGPTResponseAndToggleKBar();
            }}
          />
        </ResponseContainer>
      );
    }
    case GPTServiceIntentEnum.LEGAL_ENTITY_CREATE: {
      const crudResponses = gptChatResponse.bulkCrudCallingResponses;

      return (
        <ResponseContainer>
          {gptChatResponse.query}
          {crudResponses.map((crudResponse, index) => {
            if (crudResponse.successCC) {
              const { entityName, addressString, _id } = crudResponse.crudResponse as LegalEntity;
              return (
                <DetailsCard
                  key={index}
                  number={index + 1}
                  title={entityName}
                  subtitle={addressString}
                  onClick={() => {
                    navigate(`${getHost()}/configure/entities/${_id}`);
                    clearGPTResponseAndToggleKBar();
                  }}
                />
              );
            }
          })}
        </ResponseContainer>
      );
    }
    case GPTServiceIntentEnum.WALLET_CREATE: {
      const crudResponses = gptChatResponse.bulkCrudCallingResponses;

      return (
        <ResponseContainer>
          {gptChatResponse.query}
          {crudResponses.map((crudResponse, index) => {
            if (crudResponse.successCC) {
              const { name, address, _id, chain, walletType } = crudResponse.crudResponse as Partial<Wallet>; // TODO: fix this in the backend to return wallet doc not crud response

              if (_id && walletType === WALLET_TYPE.INTERNAL) {
                startTrackingJob(_id, JOB_NAME.IMPORT_TRANSACTION_JOB);
              }

              return (
                <DetailsCard
                  key={index}
                  number={index + 1}
                  title={name || address}
                  subtitle={chain}
                  onClick={() => {
                    navigate(`${getHost()}/ledger/sources/${_id}`);
                    clearGPTResponseAndToggleKBar();
                  }}
                />
              );
            }
          })}
        </ResponseContainer>
      );
    }
    case GPTServiceIntentEnum.LEDGER_ACCOUNT_EDIT_SEQUENCE:
    case GPTServiceIntentEnum.LEDGER_ACCOUNT_CREATE: {
      const crudResponses = gptChatResponse.bulkCrudCallingResponses;
      const ledgerAccounts = crudResponses.map((crudResponse) => ({
        ...crudResponse.crudResponse,
        ...crudResponse.accountResequencingInfo,
      })) as (LedgerAccount & { oldSequence: number; newSequence: number })[];
      const parentAccountIds = new Set(ledgerAccounts.map((ledgerAccount) => ledgerAccount.parentLedgerAccountId));
      const { ledgerAccounts: userLedgerAccounts } = useUserObjects();
      const parentAccounts = userLedgerAccounts.filter((ledgerAccount) => parentAccountIds.has(ledgerAccount._id));

      const ledgerAccountsAndParents = [...ledgerAccounts, ...parentAccounts];

      const resequencingTableData = formatLedgerAccountResequencingTable(ledgerAccountsAndParents);

      const newAccounts = crudResponses.filter((crudResponse) => !crudResponse.accountResequencingInfo?.oldSequence);

      return (
        <>
          <ResponseContainer isQuery>{gptChatResponse.query}</ResponseContainer>
          {newAccounts.length > 0 && (
            <ResponseContainer>
              New ledger account
              {newAccounts.map((crudResponse, index) => {
                if (crudResponse.successCC) {
                  const { ledgerAccountName, ledgerAccountSequence, _id, ledgerAccountType } =
                    crudResponse.crudResponse as Partial<LedgerAccount>;

                  const accountResequencingInfo = crudResponse.accountResequencingInfo;

                  const isNewAccount = !!accountResequencingInfo?.newSequence && !accountResequencingInfo?.oldSequence;

                  if (!isNewAccount) return;

                  return (
                    <DetailsCard
                      key={index}
                      number={index + 1}
                      title={`${ledgerAccountSequence}: ${ledgerAccountName}`}
                      subtitle={ledgerAccountType}
                      onClick={() => {
                        navigate(`${getHost()}/configure/ledger-accounts/${_id}`);
                        clearGPTResponseAndToggleKBar();
                      }}
                    />
                  );
                }
              })}
            </ResponseContainer>
          )}
          {resequencingTableData.length > 0 && (
            <ResponseContainer>
              Re-sequenced ledger accounts
              <ResequencingTable
                data={resequencingTableData}
                emptyMessage='No ledger account found'
                tableClasses='!min-w-full'
              />
            </ResponseContainer>
          )}
        </>
      );
    }
    case GPTServiceIntentEnum.LEGAL_ENTITY_DELETE_SPECIFIC_ENTITY: {
      const { entityName } = gptChatResponse.entityRecognitionResponse?.entitiesJson as Partial<LegalEntity>;

      return (
        <>
          <ResponseContainer isQuery>{gptChatResponse.query}</ResponseContainer>
          <ResponseContainer>{entityName} successfully deleted.</ResponseContainer>
        </>
      );
    }
    case GPTServiceIntentEnum.WALLET_DELETE: {
      return (
        <>
          <ResponseContainer isQuery>{gptChatResponse.query}</ResponseContainer>
          <ResponseContainer>Wallet successfully deleted.</ResponseContainer>
        </>
      );
    }
    case GPTServiceIntentEnum.LEDGER_ACCOUNT_DELETE_PARENT:
    case GPTServiceIntentEnum.LEDGER_ACCOUNT_DELETE: {
      return (
        <>
          <ResponseContainer isQuery>{gptChatResponse.query}</ResponseContainer>
          <ResponseContainer>Ledger account successfully deleted.</ResponseContainer>
        </>
      );
    }
    case GPTServiceIntentEnum.TAG_DELETE: {
      return (
        <>
          <ResponseContainer isQuery>{gptChatResponse.query}</ResponseContainer>
          <ResponseContainer>Tag successfully deleted.</ResponseContainer>
        </>
      );
    }
    case GPTServiceIntentEnum.READ_ANALYTICS:
      return (
        <>
          <ResponseContainer isQuery>{gptChatResponse.query}</ResponseContainer>

          <GPTAnalyticsContainer gptChatResponse={gptChatResponse} />
        </>
      );
    case GPTServiceIntentEnum.SPAM_ASSISTANT:
    case GPTServiceIntentEnum.GL_ASSISTANT: {
      const { assistantConversation } = gptChatResponse?.assistantsResponse || {};
      if (!assistantConversation)
        return (
          <>
            {' '}
            <ResponseContainer isQuery>{gptChatResponse.query}</ResponseContainer>
            <ResponseContainer>Sorry I could not understand the instruction.</ResponseContainer>
          </>
        );
      const { message, functionName, outputs } = getDataFromConversation(assistantConversation);
      return (
        <>
          <ResponseContainer isQuery>{gptChatResponse.query}</ResponseContainer>
          <ResponseContainer>{message}</ResponseContainer>
          {tableToFunctionMap[functionName] && <FunctionCallJournalsTable outputs={outputs} />}
        </>
      );
    }
    case GPTServiceIntentEnum.INVALID_INTENT:
      return (
        <>
          <ResponseContainer isQuery>{gptChatResponse.query}</ResponseContainer>
          <ResponseContainer>Sorry I could not understand the instruction.</ResponseContainer>
        </>
      );
    case GPTServiceIntentEnum.JOURNAL_ENTRY_CREATE_BY_TEMPLATE:
    case GPTServiceIntentEnum.LEDGER_ACCOUNT_AGENT:
    default:
      // VIEW FOR UNSUPPORTED INTENT BUT SUCCESSFUL RESPONSE
      return (
        <>
          <ResponseContainer isQuery>{gptChatResponse.query}</ResponseContainer>
          <ResponseContainer>Task was successfully executed.</ResponseContainer>
        </>
      );
  }
};
