import { useQuery } from '@tanstack/react-query';
import { getAiCreditUsage } from 'services';
import { QUERY_KEY, useOrgBasedQueryKey } from '../../../useInvalidateQuery';

export const useGetAiCreditUsageForOrgQuery = () => {
  const getKey = useOrgBasedQueryKey();

  return useQuery(getKey(QUERY_KEY.USAGE), async () => {
    const response = await getAiCreditUsage();
    return response.data;
  });
};
