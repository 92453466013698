import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import {
  getLedgerAccount,
  createLedgerAccount,
  deleteLedgerAccount,
  getLedgerAccountById,
  updateLedgerAccount,
} from 'services/http';
import { ColumnSort } from '@tanstack/react-table';
import { useSession } from '../useSession';
import { GetLedgerAccountType, LedgerAccount, PostLAPayload } from 'services/http/response.types';
import { QUERY_KEY, useOrgBasedQueryKey } from '../useInvalidateQuery';

export interface UseLedgerAccountPayload {
  pageSize?: number;
  ledgerAccountTypes?: string[]; //'Liability', 'Asset', 'Equity', 'Income', 'Expense'
  isLeaf?: boolean;
  sort?: ColumnSort;
  page?: number;
  ledgerAccountIds?: string[];
}

export interface GetLedgerAccountPayload extends UseLedgerAccountPayload {
  organizationId?: string;
}

export const useLedgerAccount = (payload: UseLedgerAccountPayload = {}) => {
  const {
    pageSize,
    ledgerAccountTypes,
    isLeaf = false,
    sort = { id: 'createdAt', desc: true },
    ledgerAccountIds,
  } = payload;
  const { organizationId } = useSession();
  const getKey = useOrgBasedQueryKey();

  return useInfiniteQuery<GetLedgerAccountType[]>(
    getKey(QUERY_KEY.LEDGER_ACCOUNTS, { sort }),
    async ({ pageParam = 0 }) => {
      const response = await getLedgerAccount({
        organizationId,
        page: pageParam,
        sort,
        pageSize: pageSize ?? 1000,
        ledgerAccountTypes,
        isLeaf,
        ledgerAccountIds,
      });
      return response?.data?.ledgerAccounts;
    },
    {
      enabled: !!organizationId,
      getPreviousPageParam: (firstPage: any) => firstPage.prevPage ?? undefined,
      getNextPageParam: (lastPage: any) => lastPage.nextPage,
    },
  );
};

export const useGetLedgerAccountById = (id) => {
  const { organizationId } = useSession();
  const getKey = useOrgBasedQueryKey();

  return useQuery(
    getKey(QUERY_KEY.LEDGER_ACCOUNTS, { ledgerAccountId: id }),
    async () => {
      const response = await getLedgerAccountById({
        ledgerAccountId: id,
      });
      return response.data?.ledgerAccount ?? [];
    },
    {
      enabled: !!id && !!organizationId,
    },
  );
};

export const useDeleteLedgerAccount = () =>
  useMutation((data: { ledgerAccountId: string; organizationId: string }) => deleteLedgerAccount(data));

export const useUpdateLedgerAccount = () =>
  useMutation((data: { ledgerAccountId: string; ledgerAccount: Partial<Omit<LedgerAccount, '_id'>> }) =>
    updateLedgerAccount(data),
  );

export const useCreateLedgerAccount = () => useMutation((data: PostLAPayload) => createLedgerAccount(data));
