import { useState } from 'react';
import { useSession } from './useSession';
import { registerUser } from 'services/http/auth';
import { toast } from 'react-hot-toast';
import { deriveError } from '../components/templates/utils';

export const useSignup = () => {
  const { setSessionData } = useSession();
  const [isLoading, setIsLoading] = useState(false);
  const signup = async (formState) => {
    setIsLoading(true);
    try {
      const response = await registerUser({
        user: formState,
      });
      const { user } = response.data;
      setSessionData({ user, memberships: [], organizationId: '' });
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(deriveError(error));
    }
    setIsLoading(false);
  };

  return { isLoading, signup };
};
