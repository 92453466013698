import React, { useEffect, useRef, useState } from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useOnClickOutside } from 'usehooks-ts';
import { StyledContent, StyledTrigger } from '../../../styles/FilterTagsStyle';
import { FilterTagsProps } from './types';
import { Button, classNames } from 'ui';
import { getNumberOfSelectedFilters } from './utils';
import { MdClose } from 'react-icons/md';
import { DotsIcon } from '../../../assets/generated/svg';

export function FilterTags({
  children,
  openMenu = false,
  contentLabel = 'All filters',
  triggerLabel = <DotsIcon className='!text-xl' />,
  triggerLeadingIcon,
  showLabel = true,
  align = 'start',
  className,
  allFilter,
  setAllFilter,
  ...props
}: FilterTagsProps) {
  const ref = useRef(null);

  const [open, setOpen] = useState(openMenu);

  useEffect(() => {
    setOpen(openMenu);
  }, [openMenu]);

  useOnClickOutside(ref, () => setOpen(false));

  return (
    <DropdownMenu.Root open={open} onOpenChange={setOpen} modal={false} {...props}>
      <StyledTrigger
        className={classNames(
          `btn--square !gap-3 p-2 icon !w-fit !shadow !border hover:!border-[#b3b3b3]`,
          open && '!border-[#b3b3b3]',
        )}
      >
        <div className='flex items-center justify-center gap-3'>
          {triggerLeadingIcon}
          {triggerLabel}
        </div>
        {getNumberOfSelectedFilters(allFilter)}
      </StyledTrigger>
      <StyledContent className={`${className} relative !p-0`} align={align} avoidCollisions={false} ref={ref}>
        {showLabel && (
          <div className='sticky top-0 border-b bg-white flex justify-between items-center pl-5 pr-3 py-2'>
            <p className='text-xl font-medium'>{contentLabel}</p>
            <Button emphasis='low' leadingIcon={<MdClose fontSize={24} />} onClick={() => setOpen(false)} />
          </div>
        )}
        <div className='mb-2 px-2'>{children}</div>
        <div className='sticky w-full bottom-0 bg-white flex justify-end px-4 py-2 border-t z-10'>
          <Button
            onClick={() => {
              Object.keys(allFilter ?? {}).map((objectKey) => {
                if (Array.isArray(allFilter[objectKey])) {
                  allFilter[objectKey]?.map((item) => (item.checked = false));
                } else if (typeof allFilter[objectKey] === 'string') {
                  allFilter[objectKey] = '';
                }
              });
              setAllFilter && setAllFilter({ ...allFilter });
            }}
            emphasis='low'
            className='!w-fit !border-none'
            label='Clear all'
          />
        </div>
      </StyledContent>
    </DropdownMenu.Root>
  );
}

export default FilterTags;
