import { useEffect, useState } from 'react';
import { useSession } from './useSession';
import { DatePickerSelection } from '../components/DatePickerComp.types';
import { formatDateForQuery } from 'services/http';

export type DateInterval = {
  start?: string;
  end?: string;
};

export const useDateSelections = () => {
  const { selectedOrganization } = useSession();

  const [dateSelections, setDateSelections] = useState<DatePickerSelection[]>([]);
  const [datesWithTzOffset, setDatesWithTzOffset] = useState<DateInterval>({});

  useEffect(() => {
    if (!selectedOrganization) return;
    if (!dateSelections[0]) return;
    const next: DateInterval = {};
    if (dateSelections[0]?.startDate) next.start = formatDateForQuery(dateSelections[0].startDate);
    if (dateSelections[0]?.endDate) next.end = formatDateForQuery(dateSelections[0].endDate);

    setDatesWithTzOffset(next);
  }, [dateSelections, selectedOrganization]);

  return { dateSelections, setDateSelections, datesWithTzOffset };
};
