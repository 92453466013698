import { ForwardedRef, forwardRef } from 'react';
import * as RadixDialog from '@radix-ui/react-dialog';
import { MdClose } from 'react-icons/md';
import { Button } from '../button';
import { ModalContentProps } from './types';
import { BackButton } from './back-button';
import { ModalActions } from './modal-actions';
import { classNames } from '../utils';
import useDimensions from 'react-cool-dimensions';

const ModalContentWrapper = (props: any) => <RadixDialog.Content {...props} />;

export const ModalContent = forwardRef(function Content(
  { children, actions, title, description, onClose, backAction, className }: ModalContentProps,
  forwardedRef: ForwardedRef<HTMLDivElement>,
) {
  const { observe, height } = useDimensions();
  return (
    <RadixDialog.Portal>
      <RadixDialog.Overlay className='transition-all animate-fadeOverlay fixed inset-0 h-screen w-screen bg-black opacity-50 z-[32]' />
      <ModalContentWrapper
        ref={forwardedRef}
        className={classNames(
          'w-full sm:min-w-[320px] sm:max-w-[640px] md:w-[560px]',
          'max-h-[750px] h-fit',
          'transition-all animate-fade',
          'fixed top-1/2 left-1/2',
          'transform -translate-x-1/2 -translate-y-1/2',
          'z-modal',
          'rounded-xl',
          'bg-white px-6 py-6',
          className,
        )}
      >
        <div className='flex flex-col max-h-[750px]'>
          <div className='w-full flex items-start justify-between'>
            <div className='flex-grow flex'>
              <div>
                <div className='flex items-center gap-x-2'>
                  {backAction && (
                    <div className='w-10 relative'>
                      <div className='absolute -top-5'>
                        <BackButton tooltipMessage='go back' onClick={backAction.onClick} />
                      </div>
                    </div>
                  )}
                  {title && <RadixDialog.Title className='text-2xl font-semibold'>{title}</RadixDialog.Title>}
                </div>
                {description && (
                  <RadixDialog.Description className='mt-3 mb-6 text-[#111111]'>{description}</RadixDialog.Description>
                )}
              </div>
            </div>
            {onClose && (
              <div className='w-10 h-10 relative'>
                <div className='absolute'>
                  <Button
                    disableRingOnFocus
                    leadingIcon={<MdClose className='w-5 h-5' />}
                    emphasis='low'
                    onClick={onClose}
                  />
                </div>
              </div>
            )}
          </div>
          {children}
          {actions && (
            <div className='pt-2'>
              <ModalActions actions={actions} />
            </div>
          )}
        </div>
      </ModalContentWrapper>
    </RadixDialog.Portal>
  );
});

export const ModalTrigger = RadixDialog.Trigger;
export const Modal = RadixDialog.Root;

export const DialogActions = ModalActions;
export const DialogContent = ModalContent;
export const DialogTrigger = ModalTrigger;
export const Dialog = Modal;
