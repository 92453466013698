import { useEffect, useState } from 'react';
import { Button, CurrencyMenu, InputLabel, InputWithExtras, SelectableCard, TextareaInput, currencyOptions } from 'ui';
import { ENTITY_TYPES } from '../../../constants';
import { DetailsItem, LoadingSpinner } from '../../atoms';
import { useCreateLegalEntity, useInvalidateQuery, useSession } from '../../../hooks';
import { getHost } from '../../../lib/utils';
import { useRouter } from 'next/router';
import { useCommandPallete } from '../useCommandPallete';
import { useKBar } from 'farish-kbar';
import { DetailsCard } from '../DetailsCard';
import { MdEdit } from 'react-icons/md';
import { ResponseContainer } from '../ResponseContainer';

enum STEPS {
  ENTITY_NAME = 1,
  ENTITY_TYPE = 2,
  ENTITY_ADDRESS = 3,
  ENTITY_CURRENCY = 4,
  CONFIRMATION = 5,
}

export const LegalEntityForm: React.FC<{ initialFormValues: any; index: number }> = ({
  initialFormValues = {},
  index = 0,
}) => {
  const [legalEntityForm, setLegalEntityForm] = useState({
    ...initialFormValues,
    currency: initialFormValues.currency || 'USD',
  });
  const [step, setStep] = useState(STEPS.ENTITY_NAME);
  const [isEditing, setIsEditing] = useState(false);

  const { mutateAsync: createLegalEntity, isLoading: isCreating, data } = useCreateLegalEntity();
  const { invalidateLegalEntities } = useInvalidateQuery();
  const router = useRouter();
  const { setGptChatResponses } = useCommandPallete();
  const { query } = useKBar();
  const { organizationId, userId } = useSession();

  useEffect(() => {
    if (data) {
      invalidateLegalEntities();
    }
  }, [data]);

  switch (step) {
    case STEPS.ENTITY_NAME: {
      if (initialFormValues.entityName) setStep(STEPS.ENTITY_TYPE);

      return (
        <ResponseContainer>
          Looks like the legal entity name is missing, please enter the legal entity name
          <InputWithExtras
            value={legalEntityForm.entityName}
            onChange={({ target }) => {
              setLegalEntityForm((prev) => ({ ...prev, entityName: target.value }));
            }}
            className='mt-2'
            placeholder='Enter business name'
            onEnter={() => setStep(STEPS.ENTITY_TYPE)}
          />
          <div className='mt-4 flex gap-x-4 justify-end'>
            <Button label='Confirm' onClick={() => setStep(STEPS.ENTITY_TYPE)} disabled={!legalEntityForm.entityName} />
          </div>
        </ResponseContainer>
      );
    }
    case STEPS.ENTITY_TYPE: {
      if (initialFormValues.entityType) setStep(STEPS.ENTITY_ADDRESS);

      return (
        <ResponseContainer>
          Please select an entity type for {legalEntityForm.entityName}
          <div className='grid grid-cols-3 gap-2 mt-2'>
            {ENTITY_TYPES.map((type) => {
              return (
                <SelectableCard
                  key={type.label}
                  label={type.label}
                  onClick={() => {
                    setLegalEntityForm((prev) => ({ ...prev, entityType: type.value }));
                    setStep(STEPS.ENTITY_ADDRESS);
                  }}
                  selected={legalEntityForm.entityType === type.value}
                />
              );
            })}
          </div>
        </ResponseContainer>
      );
    }
    case STEPS.ENTITY_ADDRESS: {
      if (initialFormValues.addressString) setStep(STEPS.ENTITY_CURRENCY);

      return (
        <div className={`my-2 mx-6 py-2 px-4 border rounded-lg bg-gray-100 border-gray-300 font-medium`}>
          {legalEntityForm.entityName} is missing the business address! Please add the business address to create legal
          entity
          <TextareaInput
            value={legalEntityForm.addressString}
            onChange={(e) => setLegalEntityForm((prev) => ({ ...prev, addressString: e.target.value }))}
            onEnter={() => setStep(STEPS.ENTITY_CURRENCY)}
            className='mt-2'
          />
          <div className='mt-4 flex justify-end'>
            <Button
              label='Confirm'
              onClick={() => setStep(STEPS.ENTITY_CURRENCY)}
              disabled={!legalEntityForm.addressString}
            />
          </div>
        </div>
      );
    }
    case STEPS.ENTITY_CURRENCY: {
      if (initialFormValues.currency) setStep(STEPS.CONFIRMATION);

      return (
        <div className={`my-2 mx-6 py-2 px-4 border rounded-lg bg-gray-100 border-gray-300 font-medium`}>
          One last step! Please select a currency for {legalEntityForm.entityName}
          <div className='mt-2'>
            <CurrencyMenu
              options={currencyOptions}
              value={currencyOptions.find((option) => option.value === legalEntityForm.currency)}
              onChange={(currency) => {
                setLegalEntityForm((prev) => ({ ...prev, currency: currency.value }));
              }}
            />
          </div>
          <div className='mt-4 flex justify-end'>
            <Button label='Confirm' onClick={() => setStep(STEPS.CONFIRMATION)} disabled={!legalEntityForm.currency} />
          </div>
        </div>
      );
    }
    case STEPS.CONFIRMATION: {
      if (isCreating) {
        return (
          <div className='text-center'>
            <LoadingSpinner />
          </div>
        );
      }

      if (data) {
        const { entityName, addressString, _id } = data.data.legalEntity;

        return (
          <div className={`my-2 mx-6 py-2 px-4 border rounded-lg bg-gray-100 border-gray-300 font-medium`}>
            <DetailsCard
              number={index + 1}
              title={entityName}
              subtitle={addressString}
              onClick={() => {
                router.push(`${getHost()}/configure/entities/${_id}`);
                setGptChatResponses([]);
                query.toggle();
              }}
            />
          </div>
        );
      }

      if (isEditing) {
        return (
          <div
            className={`my-2 mx-6 py-2 px-4 border rounded-lg bg-gray-100 border-gray-300 font-medium flex gap-y-2 flex-col`}
          >
            <div>
              <InputLabel heading='Entity name' />
              <InputWithExtras
                value={legalEntityForm.entityName}
                onChange={({ target }) => {
                  setLegalEntityForm((prev) => ({ ...prev, entityName: target.value }));
                }}
                className='mt-2'
                placeholder='Enter business name'
              />
            </div>
            <div>
              <InputLabel heading='Entity type' />
              <div className='grid grid-cols-3 gap-2 mt-2'>
                {ENTITY_TYPES.map((type) => {
                  return (
                    <SelectableCard
                      key={type.label}
                      label={type.label}
                      onClick={() => {
                        setLegalEntityForm((prev) => ({ ...prev, entityType: type.value }));
                      }}
                      selected={legalEntityForm.entityType === type.value}
                    />
                  );
                })}
              </div>
            </div>
            <div>
              <InputLabel heading='Address' />
              <TextareaInput
                value={legalEntityForm.addressString}
                onChange={(e) => setLegalEntityForm((prev) => ({ ...prev, addressString: e.target.value }))}
                className='mt-2'
              />
            </div>
            <div>
              <InputLabel heading='Currency' />
              <div className='mt-2'>
                <CurrencyMenu
                  options={currencyOptions}
                  value={currencyOptions.find((option) => option.value === legalEntityForm.currency)}
                  onChange={(currency) => {
                    setLegalEntityForm((prev) => ({ ...prev, currency: currency.value }));
                  }}
                />
              </div>
            </div>
            <div className='mt-4 flex justify-end'>
              <Button label='Confirm' onClick={() => setIsEditing(false)} />
            </div>
          </div>
        );
      }

      return (
        <div className={`my-2 mx-6 py-2 px-4 border rounded-lg bg-gray-100 border-gray-300 font-medium`}>
          <DetailsItem itemId='' value={legalEntityForm.entityName} tag='Entity name' />
          <DetailsItem value={legalEntityForm.entityType} tag='Entity type' />
          <DetailsItem value={legalEntityForm.addressString} tag='Address' />
          <DetailsItem value={legalEntityForm.currency} tag='Currency' />
          <div className='flex justify-end gap-x-2'>
            <Button
              label='Edit'
              leadingIcon={<MdEdit size={20} />}
              onClick={() => setIsEditing(true)}
              emphasis='medium'
            />
            <Button
              className='w-fit self-end'
              label='Confirm'
              onClick={() => {
                createLegalEntity({
                  legalEntity: {
                    organizationId,
                    userId,
                    entityName: legalEntityForm.entityName,
                    currency: legalEntityForm.currency,
                    entityType: legalEntityForm.entityType,
                    addressString: legalEntityForm.addressString,
                  },
                });
              }}
            />
          </div>
        </div>
      );
    }
  }
};
