import { ImpairedFilterDropdownRow } from '../impaired-filter';

export const ImpairedFilterRowGroup = ({ state, onSingleChange, onClearAll }) => {
  if (!state) return null;
  return (
    <div className='px-4 py-2'>
      <div className='font-semibold'>Status</div>
      <div>
        {state.ids.length > 1 ? (
          state.ids
            .map((_id) => state.entities[_id])
            .map((impairedFilterRow) => (
              <ImpairedFilterDropdownRow
                key={impairedFilterRow._id}
                label={impairedFilterRow.value}
                selected={impairedFilterRow.selected}
                onChange={(selected: boolean) => onSingleChange({ ...impairedFilterRow, selected })}
                onSelectOnly={() => {
                  onClearAll();
                  onSingleChange({ ...impairedFilterRow, selected: true });
                }}
              />
            ))
        ) : (
          <p className='text-zinc-900 text-sm px-2'>No status to show.</p>
        )}
      </div>
    </div>
  );
};
