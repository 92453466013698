import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY, useOrgBasedQueryKey } from '../useInvalidateQuery';
import { useSession } from '../useSession';
import { getDistinctFunctionNames } from 'services';

export const useGetDistinctFunctionNames = () => {
  const { organizationId } = useSession();
  const getKey = useOrgBasedQueryKey();
  return useQuery(
    getKey(QUERY_KEY.GET_DISTINCT_FUNCTION_NAMES),
    async () => {
      const response = await getDistinctFunctionNames({ organizationId });
      console.log('response', response.data);
      return response?.data?.functionNames || [];
    },
    {
      staleTime: 1000 * 60,
      cacheTime: 1000 * 60 * 5,
    },
  );
};
