import React, { useEffect, useState } from 'react';
import { Sidebar, SidebarFooter, SidebarTopBar } from '../atoms/Sidebar';
import SidebarHeader from '../atoms/Sidebar/SidebarHeader/SidebarHeader';
import { JournalEntryLineBody } from './JournalEntryLineBody';
import { Button } from 'ui';
import { JournalEntryLinePayload } from './types';
import { jeLineValidation } from '../utils';

export const CreateJournalEntryLinePanel = ({ selectedLine, setSelectedLine, setLines, onCancel }) => {
  const [line, setLine] = useState<JournalEntryLinePayload | undefined>(selectedLine || {});
  useEffect(() => {
    setLine({ ...selectedLine });
  }, [selectedLine]);

  const dataCy = 'createJournalEntryLine';
  return (
    <Sidebar data-cy={dataCy}>
      <SidebarTopBar onClose={onCancel} />
      <SidebarHeader data-cy={dataCy} title='Add journal line' />
      <JournalEntryLineBody data-cy={dataCy} existingLine={line} setLine={setLine} />
      <SidebarFooter
        destructiveBtn={
          <>
            {selectedLine && (
              <Button
                label='Delete'
                emphasis='medium'
                status='danger'
                onClick={() => {
                  setLines((lines) => lines.filter((l) => l.draftId !== selectedLine.draftId));
                  setSelectedLine(null);
                }}
              />
            )}
          </>
        }
        secondaryBtn={
          <Button
            onClick={() => {
              setSelectedLine(null);
              onCancel();
            }}
            label='Cancel'
            emphasis='medium'
          />
        }
        primaryBtn={
          <Button
            data-cy={`${dataCy}__addJournalLineButton`}
            label='Add journal line'
            emphasis='high'
            onClick={() => {
              if (jeLineValidation(line)) {
                if (selectedLine) {
                  setLines((lines) => lines.map((line) => (line?.draftId === selectedLine?.draftId ? line : line)));
                } else {
                  setLines((lines) => [...lines, { ...line, draftId: new Date().getTime() }]);
                }
                setLine(undefined);
                setSelectedLine(null);
                onCancel();
              }
            }}
          />
        }
      ></SidebarFooter>
    </Sidebar>
  );
};
