import { GetAccountingPeriodsPayloadType } from '../types';
import { SERVER_URL_CORE } from '../../config';
import axios from 'axios';
import { createQueryStringFromObject } from './utils';
import { AccountingPeriod } from '../response.types';

export const getAccountingPeriods = async (data: Partial<GetAccountingPeriodsPayloadType>) => {
  return axios.get<{ accountingPeriods: AccountingPeriod[] }>(
    `${SERVER_URL_CORE}/accounting-period?${createQueryStringFromObject(data)}`,
  );
};

export const closeAccountingPeriod = async (data) =>
  axios.patch(
    `${SERVER_URL_CORE}/close-accounting-period?organizationId=${data.organizationId}&accountingPeriodId=${data.accountingPeriodId}`,
  );
