import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { deriveError } from '../../components/templates/utils';
import { getAssets, createAsset, getAssetMetrics } from 'services/http/core';
import { ColumnSort } from '@tanstack/react-table';
import { toast } from 'react-hot-toast';
import { useSession } from '../useSession';
import { QUERY_KEY, useOrgBasedQueryKey } from '../useInvalidateQuery';

export type GetAssetsQueryFilter = Partial<{
  organizationId: string;
  organizationIds: string[];
  legalEntityIds: string[];
  assetTypes: string[];
  impaired: string[];
  startDate: string;
  endDate: string;
  sort: ColumnSort;
  pageSize: number;
}>;

export const useAssets = (params?: GetAssetsQueryFilter) => {
  const { organizationId } = useSession();

  const getKey = useOrgBasedQueryKey();

  return useInfiniteQuery(
    getKey(QUERY_KEY.ASSETS, params),
    async ({ pageParam = 0 }) => {
      const response = await getAssets({
        organizationId,
        organizationIds: [organizationId],
        page: pageParam,
        ...params,
        sort: params?.sort ?? { id: 'dateReceived', desc: true },
        pageSize: params?.pageSize ?? 25,
      });
      return response.data;
    },
    {
      enabled: !!organizationId,
      getPreviousPageParam: (firstPage) => firstPage.prevPage ?? undefined,
      getNextPageParam: (lastPage) => lastPage.nextPage,
      refetchOnWindowFocus: false,
      cacheTime: 5000,
      staleTime: 5000,
      onError: (error) => {
        toast.error(deriveError(error));
      },
    },
  );
};

export const getAssetById = (assetId: string) => {
  const { organizationId } = useSession();

  const getKey = useOrgBasedQueryKey();

  return useQuery(
    getKey(QUERY_KEY.ASSETS, { assetId }),
    async () => {
      const response = await getAssets({
        organizationId,
        sort: -1,
        page: 0,
        pageSize: 25,
        organizationIds: [organizationId],
        assetIds: assetId,
      });
      return response.data;
    },
    {
      enabled: !!organizationId,
      refetchOnWindowFocus: true,
      onError: (error) => {
        toast.error(deriveError(error));
      },
    },
  );
};

export const useCreateAsset = () => useMutation((data) => createAsset(data));

export const useGetAssetMetrics = (params: Omit<GetAssetsQueryFilter, 'sort' | 'pageSize'>) => {
  const { organizationId } = useSession();

  const { legalEntityIds, assetTypes, impaired, startDate, endDate } = params;

  const getKey = useOrgBasedQueryKey();

  return useQuery(
    getKey(QUERY_KEY.ASSET_METRICS, params),
    async () => {
      const response = await getAssetMetrics({
        organizationId,
        legalEntityIds,
        assetTypes,
        impaired,
        startDate,
        endDate,
      });
      return response.data;
    },
    {
      enabled: !!organizationId,
      refetchOnWindowFocus: true,
      onError: (error) => {
        toast.error(deriveError(error));
      },
    },
  );
};
