export const LEDGER_PAGE_SECONDARY_NAVIGATION = [
  { value: 'Transactions', path: '/ledger/transactions' },
  { value: 'Sources', path: '/ledger/sources' },
  { value: 'Journals', path: '/ledger/journals' },
  { value: 'Assets', path: '/ledger/assets' },
];

export const CONFIGURE_PAGE_SECONDARY_NAVIGATION = [
  { value: 'Templates', path: '/configure/templates' },
  { value: 'Rulesets', path: '/configure/rulesets' },
  { value: 'Tags', path: '/configure/tags' },
  { value: 'Impairment', path: '/configure/impairment-rules' },
  {
    value: 'Ledger Accounts',
    path: '/configure/ledger-accounts',
  },
  { value: 'Entities', path: '/configure/entities' },
];

export const REPORT_PAGE_SECONDARY_NAVIGATION = [
  {
    value: 'Income statement',
    path: '/reports/incomestatement',
  },
  {
    value: 'Balance sheet',
    path: '/reports/balancesheet',
  },
  {
    value: 'Trial balance',
    path: '/reports/trialbalance',
  },
  {
    value: 'Schedule of disposition',
    path: '/reports/schedule-of-disposition',
  },
  {
    value: 'Asset tax lots',
    path: '/reports/asset-tax-lots',
  },
  {
    value: 'Realized gain/loss',
    path: '/reports/realized-gain-or-loss',
  },
  {
    value: 'Closing positions',
    path: '/reports/closing-positions',
  },
  // {
  //   value: 'Accounting transactions',
  //   path: '/reports/accountingtransactions',
  // },
];

export const PROFILE_PAGE_SECONDARY_NAVIGATION = [
  {
    value: 'Account',
    path: '/profile/account-information',
  },
  // {
  //   value: 'Notifications',
  //   path: '/profile/notifications',
  // },
  // {
  //   value: 'Subscription',
  //   path: '/profile/subscription',
  // },
  {
    value: 'Billing',
    path: '/profile/billing',
  },
];

export const ORGANIZATION_SETTINGS_SECONDARY_NAVIGATION = [
  {
    value: 'General',
    path: '/organization-settings/general',
  },
  {
    value: 'Team',
    path: '/organization-settings/team',
  },
  {
    value: 'Billing',
    path: '/organization-settings/billing',
  },
  {
    value: 'Spam',
    path: '/organization-settings/spam',
  },
];
