import React from 'react';
import ConditionItem from '../ConditionItem';
import { setSaveData } from '../../../rules/utils';
import { chains } from './constants';
import SearchFilter from './SearchFilter';
import { ConditionMenusProps } from './types';

export const ChainsCondition = ({
  ruleSetData,
  getRuleSetData,
  isFirstLevel,
  isInLineCondition,
  callbackFn,
}: ConditionMenusProps) => {
  return (
    <ConditionItem isInLineCondition={isInLineCondition} label='Chains'>
      <SearchFilter
        data={chains}
        renderItem={(item) => (
          <ConditionItem
            key={item}
            label={item}
            img={item.toLowerCase()}
            onClick={() => {
              if (isInLineCondition) {
                callbackFn && callbackFn(item.toLowerCase());
              } else {
                setSaveData('Chain', item.toLowerCase(), isFirstLevel, ruleSetData, getRuleSetData);
              }
            }}
          />
        )}
      />
    </ConditionItem>
  );
};

export default ChainsCondition;
