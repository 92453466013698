import React from 'react';

import { SelectableCardProps } from './types';
import { classNames } from '../utils';

export const SelectableCardWithRadio = ({
  label,
  description,
  selected,
  disabled,
  onClick,
  className,
  'data-cy': dataCy,
}: SelectableCardProps) => {
  return (
    <button
      className={classNames(
        'w-full p-5',
        'inline-flex flex-col',
        'text-base font-medium rounded-lg',
        'border',
        'gap-x-4',
        selected ? 'bg-[#eef2ff] !border-[#8c88ef]' : 'bg-white',
        disabled ? 'opacity-50' : 'hover:border-[#121212] hover:bg-[#e2e2e2]',
        className,
      )}
      disabled={disabled}
      onClick={onClick}
      data-cy={dataCy}
    >
      <span className='inline-flex items-center gap-x-4'>
        <span>
          <input
            type='radio'
            checked={selected}
            className={classNames(
              'form-radio mb-[2px] text-[#8c88ef] w-[20px] h-[20px] border',
              selected ? 'border-[#8A8AF4]' : 'border-zinc-200',
            )}
          />
        </span>
        <span>{label}</span>
      </span>
      {description && <span className='ml-10 mt-4'>{description}</span>}
    </button>
  );
};
