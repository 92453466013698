import { AccountingPeriod, LegalEntity, Tag, Wallet } from 'services/http/response.types';
import { FILTER_TYPE, FilterRowState, StringFilter } from './filter-dropdowns';

export const transformSelectedValuesIntoQueryParams = {
  [FILTER_TYPE.ACCOUNTING_PERIOD]: ({ _id }: FilterRowState<AccountingPeriod>) => _id,
  [FILTER_TYPE.ACCOUNTING_TREATMENT]: ({ value }: FilterRowState<StringFilter>) => value,
  [FILTER_TYPE.ASSET]: ({ value }: FilterRowState<StringFilter>) => value,
  [FILTER_TYPE.CHAIN]: ({ value }: FilterRowState<StringFilter>) => value.toLowerCase(),
  [FILTER_TYPE.CLASSIFICATION]: ({ value }: FilterRowState<StringFilter>) => value.toUpperCase(),
  [FILTER_TYPE.DIRECTION]: ({ value }: FilterRowState<StringFilter>) => value,
  [FILTER_TYPE.LEGAL_ENTITY]: ({ _id }: FilterRowState<LegalEntity>) => _id,
  [FILTER_TYPE.ORIGINATED_BY]: ({ value }: FilterRowState<StringFilter>) => value,
  [FILTER_TYPE.TAG]: ({ _id }: FilterRowState<Tag>) => _id,
  [FILTER_TYPE.STATUS]: ({ value }: FilterRowState<StringFilter>) => value,
  [FILTER_TYPE.IMPAIRED]: ({ value }: FilterRowState<StringFilter>) => value.toLowerCase(),
  [FILTER_TYPE.WALLET]: (selected: FilterRowState<Wallet>) => selected,
  [FILTER_TYPE.WALLET_TYPE]: ({ value }: FilterRowState<StringFilter>) => value.toLowerCase(),
  [FILTER_TYPE.SPAM_TOKEN]: ({ value }: FilterRowState<StringFilter>) => value,
};
