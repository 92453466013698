import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { styled } from '@stitches/react';

export const StyledSub = styled(DropdownMenu.Sub, {
  width: 250,
  padding: 8,
  borderRadius: 8,
  boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.15)',
  backgroundColor: 'White',
  zIndex: 50,
  left: '-120px',
  background: 'White',
});

export const StyledSubContent = styled(DropdownMenu.SubContent, {
  width: 250,
  padding: 8,
  borderRadius: 8,
  boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.15)',
  backgroundColor: 'White',
  zIndex: 50,
  left: '-120px',
  background: 'White',
});

export const StyledSubTrigger = styled(DropdownMenu.SubTrigger, {
  all: 'unset',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '8px 12px',
  borderRadius: 8,
  transition: '300ms',
  '&[data-highlighted]': { backgroundColor: '#EEEEEE', cursor: 'pointer' },
});
