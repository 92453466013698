import React, { useContext, useEffect, useState } from 'react';
import { SelectAndReviewAccountingPeriod } from './SelectAndReviewAccountingPeriod';
import { RevaluationDialogForm } from './RevaluationDialogFormElements';
import { DESCRIPTIONS, TITLES } from './constants';
import { Button, DESCRIPTIVE_LOADER_STATES, DescriptiveLoader, Dialog, DialogContent, DialogTrigger } from 'ui';
import {
  REVALUATION_MODE,
  RevaluationConfigurationType,
  useCloseAccountingPeriod,
  useGetHedgeySource,
  useGetRevaluationConfiguration,
  useInvalidateQuery,
  useRevalueAssetsForPeriod,
  useSession,
  useUpsertRevaluationConfiguration,
  useGetHedgeyVestingBalances,
  useCreateEntriesForHedgey,
} from '../../../../hooks';
import { toast } from 'react-hot-toast';
import { deriveError } from '../../../templates/utils';
import { CLOSE_ACCOUNTING_PERIOD_PROCESS_STATE } from '../../../process-states';
import TrackJobInDialog from '../../../job-progress/TrackJobInDialog';
import { JOB_NAME } from 'services';
import { useCreateSyncJournalEntriesJob } from '../sync-journal-entry-wizard/useCreateSyncJournalEntriesJob';
import { GLType } from 'services/http/types';
import useGetIntegrationByType from '../../../integrations/gl-integration/useGetIntegrationByType';
import { JobProgressContext } from '../../../../context/JobProgressProvider';
import { HedgeyRevaluation } from './HedgeyRevaluation';

const statesForSimpleLoader = [
  CLOSE_ACCOUNTING_PERIOD_PROCESS_STATE.UPDATING_REVALUATION_CONFIG,
  CLOSE_ACCOUNTING_PERIOD_PROCESS_STATE.COULD_NOT_UPDATE_REVALUATION_CONFIG,
  CLOSE_ACCOUNTING_PERIOD_PROCESS_STATE.REVALUATING,
  CLOSE_ACCOUNTING_PERIOD_PROCESS_STATE.COULD_NOT_REVALUATE,
  CLOSE_ACCOUNTING_PERIOD_PROCESS_STATE.CLOSING_PERIOD,
  CLOSE_ACCOUNTING_PERIOD_PROCESS_STATE.COULD_NOT_CLOSE_PERIOD,
];

export const getCPWLoaderTitle = (processState: CLOSE_ACCOUNTING_PERIOD_PROCESS_STATE) =>
  [
    CLOSE_ACCOUNTING_PERIOD_PROCESS_STATE.UPDATING_REVALUATION_CONFIG,
    CLOSE_ACCOUNTING_PERIOD_PROCESS_STATE.REVALUATING,
    CLOSE_ACCOUNTING_PERIOD_PROCESS_STATE.CLOSING_PERIOD,
  ].includes(processState)
    ? 'Processing'
    : 'Failure';
export const getCPWLoaderStatus = (processState: CLOSE_ACCOUNTING_PERIOD_PROCESS_STATE) =>
  [
    CLOSE_ACCOUNTING_PERIOD_PROCESS_STATE.UPDATING_REVALUATION_CONFIG,
    CLOSE_ACCOUNTING_PERIOD_PROCESS_STATE.REVALUATING,
    CLOSE_ACCOUNTING_PERIOD_PROCESS_STATE.CLOSING_PERIOD,
  ].includes(processState)
    ? DESCRIPTIVE_LOADER_STATES.LOADING
    : DESCRIPTIVE_LOADER_STATES.ERROR;

export const getCPWLoaderDescription = (processState: CLOSE_ACCOUNTING_PERIOD_PROCESS_STATE, error?: string) => {
  switch (processState) {
    case CLOSE_ACCOUNTING_PERIOD_PROCESS_STATE.CLOSING_PERIOD:
      return 'Closing period';
    case CLOSE_ACCOUNTING_PERIOD_PROCESS_STATE.COULD_NOT_CLOSE_PERIOD:
      return `Could not close period${error ? `. \nError: ${error}` : ''}`;
    case CLOSE_ACCOUNTING_PERIOD_PROCESS_STATE.UPDATING_REVALUATION_CONFIG:
      return 'Updating revaluation config';
    case CLOSE_ACCOUNTING_PERIOD_PROCESS_STATE.COULD_NOT_UPDATE_REVALUATION_CONFIG:
      return `Could not close period${error ? `. \nError: ${error}` : ''}`;
    case CLOSE_ACCOUNTING_PERIOD_PROCESS_STATE.REVALUATING:
      return 'Revaluating';
    case CLOSE_ACCOUNTING_PERIOD_PROCESS_STATE.COULD_NOT_REVALUATE:
      return `Could not revaluate${error ? `. \nError: ${error}` : ''}`;
  }
};

export type TemplatesByAssetTypeMap = Map<string, string>;
export const ClosePeriodWizard = () => {
  const { organizationId, userId } = useSession();
  const { onTrackerClose } = useContext(JobProgressContext);
  const [accountingPeriodId, setAccountingPeriodId] = useState<string>();
  const [templatesByAssetTypeMap, setTemplatesByAssetTypeMap] = useState<TemplatesByAssetTypeMap>(new Map());
  const [processState, setProcessState] = useState<CLOSE_ACCOUNTING_PERIOD_PROCESS_STATE>(
    CLOSE_ACCOUNTING_PERIOD_PROCESS_STATE.REVIEW,
  );

  const xeroIntegration = useGetIntegrationByType(GLType.XERO);
  const quickbooksIntegration = useGetIntegrationByType(GLType.QUICKBOOKS);

  const { createJob, bgJobState, setBgJobState } = useCreateSyncJournalEntriesJob({});
  const { mutateAsync: createJournalEntriesForHedgey, isLoading: isCreatingEntriesForVesting } =
    useCreateEntriesForHedgey();

  const [open, setOpen] = useState(false);
  const onClose = () => {
    onTrackerClose(bgJobState, JOB_NAME.SYNC_JOURNAL_ENTRY_JOB, accountingPeriodId);
    setOpen(false);
  };

  useEffect(() => {
    if (!open) {
      setProcessState(CLOSE_ACCOUNTING_PERIOD_PROCESS_STATE.REVIEW);
      setAccountingPeriodId(undefined);
    }
  }, [open]);

  const { mutateAsync: mutateRevaluationConfiguration } = useUpsertRevaluationConfiguration();
  const { mutateAsync: mutateRevalueAssets } = useRevalueAssetsForPeriod();
  const { mutateAsync: closePeriod } = useCloseAccountingPeriod();

  const { data: revaluationData, isLoading: isLoadingRevaluationConfig } = useGetRevaluationConfiguration();

  const [revaluationConfig, setRevaluationConfig] = useState<RevaluationConfigurationType>({
    setting: REVALUATION_MODE.SEPARATE_GAIN_LOSS_ACCOUNTS,
    organizationId,
    single: '',
    tuple: {
      gain: '',
      loss: '',
    },
  });

  const { data: hedgeyData, isLoading: isLoadingHedgeySources } = useGetHedgeySource();
  const { hedgeySources } = hedgeyData || { hedgeySources: [] };
  const { data: vestingData, isLoading: isLoadingHedgeyVestingData } = useGetHedgeyVestingBalances({
    accountingPeriodId: accountingPeriodId ?? '',
  });

  useEffect(() => {
    if (revaluationData) setRevaluationConfig(revaluationData);
  }, [revaluationData]);

  const revaluationDisabled =
    !revaluationConfig.setting ||
    (revaluationConfig.setting === REVALUATION_MODE.SINGLE_ACCOUNT && revaluationConfig.single === '') ||
    (revaluationConfig.setting === REVALUATION_MODE.SEPARATE_GAIN_LOSS_ACCOUNTS &&
      (!revaluationConfig?.tuple?.gain || !revaluationConfig?.tuple?.loss));

  const { invalidateAccountingPeriods, invalidateBalanceSheets, invalidateIncomeStatements } = useInvalidateQuery();

  useEffect(() => {
    if (accountingPeriodId) {
      invalidateBalanceSheets();
      invalidateIncomeStatements();
    }
  }, [accountingPeriodId]);

  const [error, setError] = useState<string>();

  const onCloseAccountingPeriod = async () => {
    try {
      setProcessState(CLOSE_ACCOUNTING_PERIOD_PROCESS_STATE.CLOSING_PERIOD);
      await closePeriod({
        organizationId,
        accountingPeriodId: accountingPeriodId as string,
      });
      setProcessState(CLOSE_ACCOUNTING_PERIOD_PROCESS_STATE.SYNC_ACCOUNTING_APPS);
      invalidateAccountingPeriods();
      toast.success('Your accounting period has been closed');
    } catch (error) {
      toast.error(deriveError(error));
      setError(deriveError(error));
      setProcessState(CLOSE_ACCOUNTING_PERIOD_PROCESS_STATE.COULD_NOT_CLOSE_PERIOD);
    }
  };

  const onConfirmRevaluation = async () => {
    try {
      setProcessState(CLOSE_ACCOUNTING_PERIOD_PROCESS_STATE.UPDATING_REVALUATION_CONFIG);
      await mutateRevaluationConfiguration({ ...revaluationConfig, organizationId });
    } catch (error) {
      toast.error(deriveError(error));
      setError(deriveError(error));
      return setProcessState(CLOSE_ACCOUNTING_PERIOD_PROCESS_STATE.COULD_NOT_UPDATE_REVALUATION_CONFIG);
    }
    try {
      setProcessState(CLOSE_ACCOUNTING_PERIOD_PROCESS_STATE.REVALUATING);
      await mutateRevalueAssets({
        organizationId,
        accountingPeriodId: accountingPeriodId as string,
        userId,
      });
      setProcessState(CLOSE_ACCOUNTING_PERIOD_PROCESS_STATE.SYNC_ACCOUNTING_APPS);
      toast.success('Revaluation successful');
    } catch (error) {
      toast.error(deriveError(error));
      setError(deriveError(error));
      setProcessState(CLOSE_ACCOUNTING_PERIOD_PROCESS_STATE.COULD_NOT_REVALUATE);
    }
  };

  const onSyncQuickbooks = async () => {
    try {
      if (!accountingPeriodId) return toast.error('Please select an accounting period');
      setProcessState(CLOSE_ACCOUNTING_PERIOD_PROCESS_STATE.SYNCING_QUICKBOOKS);
      await createJob(accountingPeriodId, GLType.QUICKBOOKS);
    } catch (e) {
      console.log('CreateSyncBgJob error:', e);
      setError(deriveError(e));
      toast.error(deriveError(e));
    }
  };
  const onSyncXero = async () => {
    try {
      if (!accountingPeriodId) return toast.error('Please select an accounting period');
      setProcessState(CLOSE_ACCOUNTING_PERIOD_PROCESS_STATE.SYNCING_XERO);
      await createJob(accountingPeriodId, GLType.XERO);
    } catch (e) {
      console.log('CreateSyncBgJob error:', e);
      setError(deriveError(e));
      toast.error(deriveError(e));
    }
  };
  const revaluateHedgey = async () => {
    try {
      if (!accountingPeriodId) return toast.error('Please select an accounting period');
      setProcessState(CLOSE_ACCOUNTING_PERIOD_PROCESS_STATE.CREATE_ENTRIES_FOR_HEDGEY);
      console.log({
        templatesByAssetTypeMap,
      });
      for (const [assetType, templateId] of templatesByAssetTypeMap) {
        toast.loading(`Creating journal entries for ${assetType}`);
        await createJournalEntriesForHedgey({
          accountingPeriodId,
          journalEntryTemplateId: templateId,
          assetType,
        });
      }
      toast.dismiss();
      toast.success('Journal entries created successfully');
    } catch (e) {
      setError(deriveError(e));
      toast.error(deriveError(e));
    } finally {
      setProcessState(CLOSE_ACCOUNTING_PERIOD_PROCESS_STATE.SET_REVALUATION_CONFIG);
    }
    setTimeout(() => {
      toast.dismiss();
    }, 1500);
  };
  const skipHedgeyRevaluation = () => {
    setProcessState(CLOSE_ACCOUNTING_PERIOD_PROCESS_STATE.SET_REVALUATION_CONFIG);
  };
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button
          onClick={() => {
            setOpen(true);
          }}
          label='Close period'
        />
      </DialogTrigger>
      {processState === CLOSE_ACCOUNTING_PERIOD_PROCESS_STATE.REVIEW && (
        <DialogContent
          title={TITLES[0]}
          description={DESCRIPTIONS[0]}
          actions={[
            {
              label: 'Next',
              onClick: () => {
                if (hedgeySources.length === 0) {
                  setProcessState(CLOSE_ACCOUNTING_PERIOD_PROCESS_STATE.SET_REVALUATION_CONFIG);
                } else {
                  setProcessState(CLOSE_ACCOUNTING_PERIOD_PROCESS_STATE.REVALUATE_HEDGEY);
                }
              },
            },
            { label: 'Cancel', emphasis: 'medium', onClick: onClose },
          ]}
          onClose={onClose}
        >
          <SelectAndReviewAccountingPeriod
            setAccountingPeriodId={setAccountingPeriodId}
            accountingPeriodId={accountingPeriodId}
          />
        </DialogContent>
      )}
      {processState === CLOSE_ACCOUNTING_PERIOD_PROCESS_STATE.SET_REVALUATION_CONFIG && (
        <DialogContent
          title={TITLES[1]}
          description={DESCRIPTIONS[1]}
          actions={[
            { label: 'Revaluate', onClick: onConfirmRevaluation, disabled: revaluationDisabled },
            { label: 'Close period', emphasis: 'medium', onClick: onCloseAccountingPeriod },
          ]}
          onClose={onClose}
          backAction={{ onClick: () => setProcessState(CLOSE_ACCOUNTING_PERIOD_PROCESS_STATE.REVIEW) }}
        >
          <RevaluationDialogForm
            revaluationConfig={revaluationConfig}
            setRevaluationConfig={setRevaluationConfig}
            isLoading={isLoadingRevaluationConfig}
          />
        </DialogContent>
      )}

      {statesForSimpleLoader.includes(processState) && (
        <DialogContent
          onClose={onClose}
          backAction={
            getCPWLoaderStatus(processState) === DESCRIPTIVE_LOADER_STATES.ERROR
              ? { onClick: () => setProcessState(CLOSE_ACCOUNTING_PERIOD_PROCESS_STATE.SET_REVALUATION_CONFIG) }
              : undefined
          }
        >
          <DescriptiveLoader
            title={getCPWLoaderTitle(processState)}
            description={getCPWLoaderDescription(processState, error)}
            status={getCPWLoaderStatus(processState)}
          />
        </DialogContent>
      )}
      {processState === CLOSE_ACCOUNTING_PERIOD_PROCESS_STATE.SYNC_ACCOUNTING_APPS && (
        <DialogContent
          title={'Sync other accounting systems'}
          description={'The selected accounting period was closed successfully. You can sync other systems as well.'}
          actions={[{ label: 'Close', onClick: onClose }]}
          onClose={onClose}
        >
          <div className='flex items-center gap-x-2'>
            <Button
              emphasis='medium'
              label={'Sync Quickbooks'}
              onClick={onSyncQuickbooks}
              disabled={!quickbooksIntegration?.isLive}
            />
            <Button emphasis='medium' label={'Sync Xero'} onClick={onSyncXero} disabled={!xeroIntegration?.isLive} />
          </div>
        </DialogContent>
      )}
      {processState === CLOSE_ACCOUNTING_PERIOD_PROCESS_STATE.REVALUATE_HEDGEY && (
        <DialogContent
          title={'Hedgey Vesting Plan Balance Sheet Updates'}
          description={
            'Entendre has calculated the vested amount of tokens for this period. Select a template per asset type using the vested amount variable to update the balance sheet with the remaining unvested balance.'
          }
          actions={[
            { label: 'Confirm', onClick: revaluateHedgey, isLoading: isCreatingEntriesForVesting },

            { label: 'Skip', onClick: skipHedgeyRevaluation, emphasis: 'medium' },
          ]}
          onClose={onClose}
          backAction={{ onClick: () => setProcessState(CLOSE_ACCOUNTING_PERIOD_PROCESS_STATE.REVIEW) }}
        >
          <HedgeyRevaluation
            isLoading={isLoadingHedgeySources || isLoadingHedgeyVestingData}
            vestingData={vestingData}
            accountingPeriodId={accountingPeriodId}
            templatesByAssetTypeMap={templatesByAssetTypeMap}
            setTemplatesByAssetTypeMap={setTemplatesByAssetTypeMap}
          />
        </DialogContent>
      )}

      {processState === CLOSE_ACCOUNTING_PERIOD_PROCESS_STATE.CREATE_ENTRIES_FOR_HEDGEY && (
        <DialogContent onClose={onClose}>
          <DescriptiveLoader title={'Creating entries for vested tokens'} status={DESCRIPTIVE_LOADER_STATES.LOADING} />
        </DialogContent>
      )}
      {processState === CLOSE_ACCOUNTING_PERIOD_PROCESS_STATE.SYNCING_QUICKBOOKS && (
        <TrackJobInDialog
          jobName={JOB_NAME.SYNC_JOURNAL_ENTRY_JOB}
          referenceId={accountingPeriodId}
          onClose={onClose}
          bgJobState={bgJobState}
          setBgJobState={setBgJobState}
          error={error}
        />
      )}

      {processState === CLOSE_ACCOUNTING_PERIOD_PROCESS_STATE.SYNCING_XERO && (
        <TrackJobInDialog
          jobName={JOB_NAME.IMPORT_EXCHANGE_SOURCE_JOB}
          referenceId={accountingPeriodId}
          onClose={onClose}
          bgJobState={bgJobState}
          setBgJobState={setBgJobState}
          error={error}
        />
      )}
    </Dialog>
  );
};
