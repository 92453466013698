import { useState } from 'react';
import { useCreateRunRulesetBgJob, useSession } from '../../../hooks';
import { BG_JOB_STATE } from '../../job-progress/types';
import { toast } from 'react-hot-toast';
import { JOB_NAME } from 'services';

export const useTriggerRulesetJobWithTracking = () => {
  const { userId, organizationId } = useSession();
  const [bgJobState, setBgJobState] = useState<BG_JOB_STATE>(BG_JOB_STATE.PENDING_CREATE);

  const createRunRulesetJob = useCreateRunRulesetBgJob();

  const createJob = async ({
    ruleSetId,
    accountingPeriodIds,
    legalEntityIds,
    processTransactionsWithNoAccounting,
    overWrite,
  }) => {
    if (!userId || !organizationId) return toast.error('Something wrong with the session.');

    try {
      setBgJobState(BG_JOB_STATE.BEING_CREATED);
      await createRunRulesetJob({
        ruleSetId,
        accountingPeriodIds,
        legalEntityIds,
        processTransactionsWithNoAccounting,
        overWrite,
      });

      setBgJobState(BG_JOB_STATE.QUEUED);
    } catch (error) {
      return setBgJobState(BG_JOB_STATE.COULD_NOT_BE_CREATED);
    }
    return { jobName: JOB_NAME.RULESET_JOB, ruleSetId }; // return the job type so that it can be tracked
  };

  return { createJob, bgJobState, setBgJobState };
};
