import { useQuery } from '@tanstack/react-query';
import { JOB_NAME, UseJobProgressPayload, getJobProgress } from 'services/http/job-queue';
import { QUERY_KEY, useOrgBasedQueryKey } from '../useInvalidateQuery';
import { useSession } from '../useSession';

export const useJobProgress = (payload: UseJobProgressPayload, options: { enabled: boolean }) => {
  const { jobName, referenceId, organizationId } = payload;
  const getKey = useOrgBasedQueryKey();

  return useQuery(
    getKey(QUERY_KEY.JOB_PROGRESS, { jobName, referenceId }),
    async () => {
      const response = await getJobProgress(payload);
      return response.data;
    },
    {
      enabled: !!jobName && !!referenceId && !!organizationId && options.enabled,
      refetchInterval: 5000,
    },
  );
};

export const useRulesetJobProgress = (referenceId, options: { enabled: boolean }) => {
  const { organizationId } = useSession();
  return useJobProgress({ jobName: JOB_NAME.RULESET_JOB, referenceId, organizationId }, options);
};
