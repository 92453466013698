import { useEffect, useState } from 'react';
import { Button, Checkbox, InputLabel, InputWithExtras, SelectableCard } from 'ui';
import { ACCOUNT_TYPE_OPTIONS } from '../../../constants';
import { DetailsItem, LoadingSpinner } from '../../atoms';
import { useCreateLedgerAccount, useInvalidateQuery, useSession } from '../../../hooks';
import { getHost } from '../../../lib/utils';
import { useRouter } from 'next/router';
import { useCommandPallete } from '../useCommandPallete';
import { useKBar } from 'farish-kbar';
import { DetailsCard } from '../DetailsCard';
import { MdEdit } from 'react-icons/md';

enum STEPS {
  LEDGER_ACCOUNT_NAME = 1,
  LEDGER_ACCOUNT_TYPE = 2,
  LEDGER_ACCOUNT_SEQUENCE = 3,
  CONFIRMATION = 5,
}

export const LedgerAccountForm: React.FC<{ initialFormValues: any; index: number }> = ({
  initialFormValues = {},
  index = 0,
}) => {
  const [ledgerAccountForm, setLedgerAccountForm] = useState(initialFormValues);
  const [step, setStep] = useState(STEPS.LEDGER_ACCOUNT_NAME);
  const [isEditing, setIsEditing] = useState(false);
  const { invalidateLedgerAccounts } = useInvalidateQuery();

  const { mutateAsync: createLedgerAccount, isLoading: isCreating, data } = useCreateLedgerAccount();
  const router = useRouter();
  const { setGptChatResponses } = useCommandPallete();
  const { query } = useKBar();
  const { organizationId, userId } = useSession();

  useEffect(() => {
    invalidateLedgerAccounts();
  }, [data]);

  switch (step) {
    case STEPS.LEDGER_ACCOUNT_NAME: {
      if (initialFormValues.ledgerAccountName) setStep(STEPS.LEDGER_ACCOUNT_TYPE);

      return (
        <div className={`my-2 mx-6 py-2 px-4 border rounded-lg bg-gray-100 border-gray-300 font-medium`}>
          Ledger account name is missing
          <InputWithExtras
            className='mt-2'
            value={ledgerAccountForm.ledgerAccountName}
            onChange={({ target }) => {
              setLedgerAccountForm((prev) => ({ ...prev, ledgerAccountName: target.value }));
            }}
            placeholder='Enter ledger account name'
            onEnter={() => setStep(STEPS.LEDGER_ACCOUNT_TYPE)}
          />
          <div className='mt-4 flex justify-end'>
            <Button
              label='Confirm'
              onClick={() => setStep(STEPS.LEDGER_ACCOUNT_TYPE)}
              disabled={!ledgerAccountForm.ledgerAccountName}
            />
          </div>
        </div>
      );
    }
    case STEPS.LEDGER_ACCOUNT_TYPE: {
      if (initialFormValues.ledgerAccountType) setStep(STEPS.LEDGER_ACCOUNT_SEQUENCE);

      return (
        <div className={`my-2 mx-6 py-2 px-4 border rounded-lg bg-gray-100 border-gray-300 font-medium`}>
          Please select a ledger account type for {ledgerAccountForm.ledgerAccountName}
          <div className='grid grid-cols-3 gap-2 mt-2'>
            {ACCOUNT_TYPE_OPTIONS.map((type) => {
              return (
                <SelectableCard
                  key={type.label}
                  label={type.label}
                  onClick={() => {
                    setLedgerAccountForm((prev) => ({ ...prev, ledgerAccountType: type.value }));
                    setStep(STEPS.LEDGER_ACCOUNT_SEQUENCE);
                  }}
                  selected={ledgerAccountForm.ledgerAccountType === type.value}
                />
              );
            })}
          </div>
        </div>
      );
    }
    case STEPS.LEDGER_ACCOUNT_SEQUENCE: {
      if (initialFormValues.ledgerAccountSequence) setStep(STEPS.CONFIRMATION);

      return (
        <div className={`my-2 mx-6 py-2 px-4 border rounded-lg bg-gray-100 border-gray-300 font-medium`}>
          Please insert a ledger account sequence for {ledgerAccountForm.ledgerAccountName}
          <InputWithExtras
            value={ledgerAccountForm.addressString}
            onChange={(e) => setLedgerAccountForm((prev) => ({ ...prev, ledgerAccountSequence: e.target.value }))}
            onEnter={() => setStep(STEPS.CONFIRMATION)}
            className='mt-2'
            type='number'
          />
          <div className='mt-4 flex justify-end'>
            <Button
              label='Confirm'
              onClick={() => setStep(STEPS.CONFIRMATION)}
              disabled={!ledgerAccountForm.ledgerAccountSequence}
            />
          </div>
        </div>
      );
    }
    case STEPS.CONFIRMATION: {
      if (isCreating) {
        return (
          <div className='text-center'>
            <LoadingSpinner />
          </div>
        );
      }

      if (data) {
        const { ledgerAccountName, ledgerAccountType, ledgerAccountSequence, _id } = data.data.ledgerAccount;

        return (
          <div className={`my-2 mx-6 py-4 px-4 border rounded-lg bg-gray-100 border-gray-300 font-medium`}>
            <DetailsCard
              number={index + 1}
              title={`${ledgerAccountSequence}: ${ledgerAccountName}`}
              subtitle={ledgerAccountType}
              onClick={() => {
                router.push(`${getHost()}/configure/ledger-accounts/${_id}`);
                setGptChatResponses([]);
                query.toggle();
              }}
            />
          </div>
        );
      }

      if (isEditing) {
        return (
          <div
            className={`my-2 mx-6 py-2 px-4 border rounded-lg bg-gray-100 border-gray-300 font-medium flex gap-y-2 flex-col`}
          >
            <div>
              <InputLabel heading='Entity name' />
              <InputWithExtras
                value={ledgerAccountForm.ledgerAccountName}
                onChange={({ target }) => {
                  setLedgerAccountForm((prev) => ({ ...prev, ledgerAccountName: target.value }));
                }}
                className='mt-2'
                placeholder='Enter business name'
              />
            </div>
            <div>
              <InputLabel heading='Entity type' />
              <div className='grid grid-cols-3 gap-2 mt-2'>
                {ACCOUNT_TYPE_OPTIONS.map((type) => {
                  return (
                    <SelectableCard
                      key={type.label}
                      label={type.label}
                      onClick={() => {
                        setLedgerAccountForm((prev) => ({ ...prev, ledgerAccountType: type.value }));
                      }}
                      selected={ledgerAccountForm.ledgerAccountType === type.value}
                    />
                  );
                })}
              </div>
              <Checkbox
                label='Clearing account'
                onChange={(val) => setLedgerAccountForm((prev) => ({ ...prev, isClearingAccount: val }))}
                isSelected={ledgerAccountForm.isClearingAccount}
              />
            </div>
            <div>
              <InputLabel heading='Ledger account sequence' />
              <InputWithExtras
                value={ledgerAccountForm.ledgerAccountSequence}
                onChange={(e) => setLedgerAccountForm((prev) => ({ ...prev, ledgerAccountSequence: e.target.value }))}
                className='mt-2'
                placeholder='Enter ledger account sequence'
              />
            </div>
            <div className='mt-4 flex justify-end'>
              <Button label='Confirm' onClick={() => setIsEditing(false)} />
            </div>
          </div>
        );
      }

      return (
        <div className={`my-2 mx-6 py-2 px-4 border rounded-lg bg-gray-100 border-gray-300 font-medium`}>
          <DetailsItem itemId='' value={ledgerAccountForm.ledgerAccountName} tag='Name' />
          <DetailsItem value={ledgerAccountForm.ledgerAccountType} tag='Type' />
          <DetailsItem value={ledgerAccountForm.ledgerAccountSequence} tag='Sequence Number' />
          <DetailsItem value={ledgerAccountForm.isClearingAccount ? 'True' : 'False'} tag='Clearing account' />
          <div className='flex justify-end gap-x-2'>
            <Button
              label='Edit'
              leadingIcon={<MdEdit size={20} />}
              onClick={() => setIsEditing(true)}
              emphasis='medium'
            />
            <Button
              label='Confirm'
              onClick={() => {
                createLedgerAccount({
                  ledgerAccount: {
                    organizationId,
                    userId,
                    ledgerAccountName: ledgerAccountForm.ledgerAccountName,
                    ledgerAccountType: ledgerAccountForm.ledgerAccountType,
                    ledgerAccountSequence: ledgerAccountForm.ledgerAccountSequence,
                    isClearingAccount: ledgerAccountForm.isClearingAccount,
                    parentLedgerAccountId: ledgerAccountForm.parentLedgerAccountId,
                  },
                });
              }}
            />
          </div>
        </div>
      );
    }
  }
};
