import { useEffect, useMemo, useRef, useState } from 'react';
import {
  Button,
  CheckboxListItem,
  Dropdown,
  DropdownBody,
  DropdownContent,
  DropdownSubHeader,
  DropdownTrigger,
  DropdownFooter,
  DropdownGroupBody,
  DropdownHeader,
  DropdownSearchInput,
  DropdownTitleWithToggle,
  capitalizeFirstLetter,
  classNames,
  getFilterDropdownSectionDataCy,
} from 'ui';
import { FILTER_TYPE, FilterDropdownRowProps, StringFilter } from '../types';
import { MdArrowDropDown } from 'react-icons/md';
import { useSearch } from '../useSearch';
import { assetSearchFilterFn } from './asset-search-filter-fn';
import { currencyImg } from '../../../utils';
import { IoMdCloseCircle } from 'react-icons/io';
import { getAssetsInfo } from '../../../../constants/assetsNames';

const DEFAULT_TRIGGER_LABEL = 'Assets';
export const AssetsFilterDropdown = ({
  state,
  onSelectAll,
  onClearAll,
  onSingleRowSelectChange,
  onOnlySingleRowSelect,
  'data-cy': dataCy,
}: FilterDropdownRowProps<StringFilter>) => {
  const [open, setOpen] = useState(false);
  const triggerRef = useRef<HTMLButtonElement>(null);

  const [triggerLabel, setTriggerLabel] = useState(DEFAULT_TRIGGER_LABEL);
  const [isHoveringClearAllTrailingIcon, setIsHoveringClearAllTrailingIcon] = useState(false);
  const [isHoveringTrigger, setIsHoveringTrigger] = useState(false);

  useEffect(() => {
    setTriggerLabel(() => {
      const selectedIds = state.ids.filter((id) => state.entities[id].selected);
      if (selectedIds.length === 0) return DEFAULT_TRIGGER_LABEL;
      if (selectedIds.length === 1) return `${state.entities[selectedIds[0]].value}`;
      return `${state.entities[selectedIds[0]].value} +${selectedIds.length - 1}`;
    });
  }, [state]);

  const assetsFilterDataCy = useMemo(() => `${dataCy}_${FILTER_TYPE.ASSET}`, [dataCy]);

  const { searched, setSearched, results } = useSearch<StringFilter>(state, assetSearchFilterFn);

  return (
    <Dropdown data-cy={assetsFilterDataCy} open={open} onOpenChange={setOpen}>
      <DropdownTrigger ref={triggerRef}>
        <span onMouseEnter={() => setIsHoveringTrigger(true)} onMouseLeave={() => setIsHoveringTrigger(false)}>
          <Button
            data-cy={getFilterDropdownSectionDataCy(assetsFilterDataCy).trigger}
            isFocused={open}
            isLoading={state.isLoading}
            onClick={() => setOpen((prev) => !prev)}
            label={
              triggerLabel === DEFAULT_TRIGGER_LABEL ? (
                triggerLabel
              ) : (
                <span className='text-indigo-600'>{capitalizeFirstLetter(triggerLabel?.toLowerCase())}</span>
              )
            }
            className={classNames(
              'duration-100 py-0',

              triggerLabel !== DEFAULT_TRIGGER_LABEL &&
                isHoveringTrigger &&
                !isHoveringClearAllTrailingIcon &&
                'shadow',
              triggerLabel !== DEFAULT_TRIGGER_LABEL && 'bg-indigo-50 border-indigo-600',
            )}
            labelContainerClassname={classNames(
              'font-medium ',
              triggerLabel !== DEFAULT_TRIGGER_LABEL && 'text-indigo-600',
            )}
            emphasis='medium'
            trailingIconContainerClassname={classNames(
              triggerLabel !== DEFAULT_TRIGGER_LABEL && 'mr-0 p-2 duration-100 bg-indigo-50 text-blue-500 rounded-r-lg',
              triggerLabel !== DEFAULT_TRIGGER_LABEL && isHoveringTrigger && 'bg-indigo-50',
            )}
            trailingIcon={
              <div className='flex items-center'>
                {triggerLabel !== DEFAULT_TRIGGER_LABEL && (
                  <span
                    onMouseEnter={() => setIsHoveringClearAllTrailingIcon(true)}
                    onMouseLeave={() => setIsHoveringClearAllTrailingIcon(false)}
                    onClick={(e) => {
                      e.stopPropagation();
                      onClearAll();
                    }}
                  >
                    <IoMdCloseCircle className='w-6 h-6 text-zinc-500' />
                  </span>
                )}

                <MdArrowDropDown className={classNames('duration-300 w-6 h-6 text-black', open && 'rotate-180')} />
              </div>
            }
          />
        </span>
      </DropdownTrigger>

      <DropdownContent triggerRef={triggerRef}>
        <DropdownHeader title='Assets' data-cy={getFilterDropdownSectionDataCy(assetsFilterDataCy).header} />
        <DropdownBody data-cy={getFilterDropdownSectionDataCy(assetsFilterDataCy).body}>
          <DropdownSubHeader>
            <DropdownSearchInput
              value={searched}
              onChange={(e) => setSearched(e.target.value)}
              data-cy={getFilterDropdownSectionDataCy(assetsFilterDataCy).searchInput}
            />
            <DropdownTitleWithToggle
              className='px-2'
              title='Select all'
              checked={state.ids.length === state.ids.filter((id) => state.entities[id].selected).length}
              onChange={(checked: boolean) => (checked ? onSelectAll() : onClearAll())}
            />
          </DropdownSubHeader>
          {results?.ids.length === 0 && <div className='p-4 text-center'>No assets to show</div>}
          {results?.ids.length !== 0 && (
            <>
              <DropdownGroupBody>
                {results?.ids.map((rowId) => (
                  <CheckboxListItem
                    key={rowId}
                    enableAvatar
                    label={getAssetsInfo(results.entities[rowId].value?.toLowerCase()).name}
                    bottomText={getAssetsInfo(results.entities[rowId].value?.toLowerCase()).abbreviation}
                    src={currencyImg(results.entities[rowId].value)}
                    checked={results.entities[rowId].selected}
                    onCheckedChange={(selected: boolean) => onSingleRowSelectChange(rowId, selected)}
                    selectOnlyFn={() => onOnlySingleRowSelect(rowId)}
                  />
                ))}
                {
                  // also show rows which might not be included in search results but are currently selected as they affect the results
                  state.ids
                    .filter((id) => !results?.ids.includes(id) && state.entities[id].selected)
                    .map((rowId) => (
                      <CheckboxListItem
                        key={rowId}
                        enableAvatar
                        src={currencyImg(results?.entities[rowId]?.value ?? '')}
                        size='large'
                        label={getAssetsInfo(state.entities[rowId].value?.toLowerCase()).name}
                        bottomText={getAssetsInfo(state.entities[rowId].value?.toLowerCase()).abbreviation}
                        checked={state.entities[rowId].selected}
                        onCheckedChange={(selected: boolean) => onSingleRowSelectChange(rowId, selected)}
                        selectOnlyFn={() => onOnlySingleRowSelect(rowId)}
                      />
                    ))
                }
              </DropdownGroupBody>
            </>
          )}
        </DropdownBody>
        <DropdownFooter>
          <Button
            data-cy={getFilterDropdownSectionDataCy(assetsFilterDataCy).clearAllButton}
            onClick={results?.ids.length !== 0 ? onClearAll : undefined}
            emphasis='low'
            label='Clear'
            labelContainerClassname='text-zinc-500'
          />
        </DropdownFooter>
      </DropdownContent>
    </Dropdown>
  );
};
