import { getDataId } from './configurator';

export function convertData(_data) {
  let convert = {};
  const dict = { AND: 'all', OR: 'any' };
  let prevKey = 'all';
  let newKey;
  for (let condition of _data) {
    if (_data.indexOf(condition) === 0) {
      if (Array.isArray(condition)) {
        condition = convertData(condition);
      }
      convert.all = [condition];
      continue;
    }

    if (dict[condition]) {
      newKey = dict[condition];
      continue;
    }

    if (Array.isArray(condition)) {
      condition = convertData(condition);
    }

    if (prevKey === newKey) {
      convert[newKey].push(condition);
    } else {
      convert = { [newKey]: [convert, condition] };
    }
    prevKey = newKey;
  }
  return convert;
}

export function revertData(_data = {}) {
  if (Object.keys(_data).length === 0) {
    return [];
  }
  const reverted = revert(_data);

  if (!Array.isArray(reverted)) return [[reverted]];
  reverted.forEach((item, index) => {
    if (!Array.isArray(item) && typeof item !== 'string') {
      reverted[index] = [item];
    }
  });

  return reverted;
}

export function revert(_data, key) {
  const dict = { all: 'AND', any: 'OR' };
  const reverted = [];

  if (!Array.isArray(_data)) {
    return revert(Object.values(_data)[0], dict[Object.keys(_data)[0]]);
  }

  for (let condition of _data) {
    if (_data.indexOf(condition) !== 0) {
      reverted.push(key);
    }

    for (const item of Object.keys(dict)) {
      if (condition[item]) {
        condition = revert(condition, dict[item]);
        break;
      }
    }

    reverted.push(condition);
  }

  if (Array.isArray(reverted) && reverted.length === 1) {
    return reverted[0];
  }
  return reverted;
}

export const isScrollToBottom = (e) => {
  return e.target.scrollTop + e.target.clientHeight === e.target.scrollHeight;
};

export function setSaveData(type, value, isFirstLevel, saveData, getRuleSetData) {
  let data;
  switch (type) {
    case 'Nest Condition':
      data = [];
      break;
    case 'Asset':
      data = { fact: type, value: value.toLowerCase(), operator: 'equal' };
      break;
    case 'Wallet':
      data = {
        fact: type,
        operator: 'isCredited',
        value: {
          internalWallet: {
            address: value.address,
            id: value._id,
          },
        },
      };
      break;
    case 'Coinbase Prime':
      data = {
        fact: type,
        operator: 'coinbasePrimeIsCredited',
        value: {
          coinbasePrimeId: value.value,
        },
      };
      break;
    case 'Transaction':
      data = {
        fact: type,
        path: getDataId(type, value, 'values'),
        operator: saveData.operator,
        value: '0',
      };
      break;
    case 'Raincards':
      data = {
        fact: type,
        path: getDataId(type, value, 'paths'),
        operator: 'equal',
        value: value,
      };
      break;
    case 'Raincards Source':
      data = {
        fact: 'Raincards',
        operator: 'raincardIsDebited',
        value: value,
      };
      break;
    case 'Loop':
      data = {
        fact: type,
        path: getDataId(type, value, 'paths'),
        operator: 'equal',
        value: value,
      };
      break;
    case 'Legal entity':
      data = {
        fact: type,
        operator: 'to',
        value: {
          legalEntityOne: value._id,
          legalEntityTwo: undefined,
        },
      };
      break;
    case 'Ledger Account':
      data = {
        fact: type,
        operator: 'equal',
        value,
      };
      break;
    case 'Is Impaired':
      data = {
        fact: type,
        operator: 'equal',
        value,
      };
      break;
    case 'Tag':
      data = {
        fact: 'Wallet',
        operator: 'isCredited',
        value: {
          internalWallet: {
            tagId: value?.tagId,
          },
        },
      };
      break;
    case 'Gain':
      data = {
        fact: type,
        value: true,
        operator: 'equal',
      };
      break;
    case 'Loss':
      data = {
        fact: type,
        value: true,
        operator: 'equal',
      };
      break;
    case 'Loss Tolerance':
      data = {
        fact: type,
        value: 1,
        operator: 'greaterThan',
      };
      break;
    case 'Gain Tolerance':
      data = {
        fact: type,
        value: 1,
        operator: 'greaterThan',
      };
      break;
    case 'Last Impaired On Date':
      data = {
        fact: type,
        value: new Date().getTime(),
        operator: 'greaterThan',
      };
      break;
    case 'Date Received On':
      data = {
        fact: type,
        value: new Date().getTime(),
        operator: 'greaterThan',
      };
      break;
    case 'Chain':
      data = {
        fact: type,
        value: value,
        operator: 'equal',
      };
      break;
    case 'Raw Contract Address':
      data = {
        fact: type,
        value: value,
        operator: 'equal',
      };
      break;
    case 'Hedgey Event':
      data = {
        fact: type,
        value: value,
        operator: 'equal',
      };
      break;
    case 'Transaction Direction':
      data = {
        fact: type,
        value: value,
        operator: 'equal',
      };
      break;
    case 'Transaction Classification':
      data = {
        fact: type,
        value: value,
        operator: 'equal',
      };
      break;
    case 'Transaction Action':
      data = {
        fact: type,
        value: value,
        operator: 'equal',
      };
      break;
    default:
      data = {};
      break;
  }

  if (isFirstLevel && !Array.isArray(data)) {
    data = [data];
  }

  if (saveData?.length !== 0) {
    data = [...saveData, 'AND', data];
  } else {
    data = [data];
  }

  getRuleSetData(data);
}

export const shortText = (str) => {
  return /[0-9]/.test(str) && !/\s/g.test(str) ? `${str.slice(0, 5)}...${str.slice(-5)}` : str;
};

export const getWalletTypeByChain = (walletType, chain, mergedSources) => {
  return mergedSources.filter((item) => item.walletType === walletType && item.chain === chain);
};

export const getDisplayedWalletTypeByChain = (walletType, chain, mergedSources, callbackFn) => {
  return mergedSources
    .filter((item) => item.walletType === walletType && item.chain === chain)
    .map((source) => (
      <div
        key={source._id}
        className='flex items-center cursor-pointer hover:bg-[#EEEEEE] px-2 py-2'
        onClick={() => {
          callbackFn({ type: 'Wallet', value: source });
        }}
      >
        <div className='btn--tag mr-1'>{walletType}</div>
        <div className='!border-none !bg-transparent w-full text-[#222222] hover:bg-[#EEEEEE] py-2 px-1 duration-300 cursor-pointer block text-lg'>
          {source.name || source.address}
        </div>
      </div>
    ));
};
function filterWalletTags(arr) {
  const uniqueIds = {};
  const filteredArr = arr.filter((item) => {
    if (!uniqueIds[item._id]) {
      uniqueIds[item._id] = true;
      return true;
    } else if (item.walletType !== arr.find((i) => i._id === item._id).walletType) {
      return true;
    }
    return false;
  });

  return filteredArr;
}
export const getWalletTags = (tags) => {
  let pages = [];
  let merged = [];
  let _tags = [];
  const filterItems = ['internal', 'external'];

  if (tags?.pages) pages = tags.pages;
  if (pages?.length) {
    pages.map((page) => {
      merged = [...merged, ...page];
    });
  }

  for (let filter of filterItems) {
    const filtered = merged?.filter((item) => item?.walletType === filter);
    for (let i = 0; i < filtered.length; i++) {
      for (let j = 0; j < filtered[i]?.tags?.length; j++) {
        filtered[i].tags[j].walletType = filtered[i].walletType;
        filtered[i].tags[j].isTagged = true;
        _tags.push(filtered[i]?.tags[j]);
      }
    }
  }

  return filterWalletTags(_tags);
};

export const getUniqueWalletChains = (walletData) => {
  // this one is just return array of string ['BTC', 'ETH', ..etc]
  const walletTypes = walletData.map((wallet) => wallet.chain);
  return Array.from(new Set(walletTypes));
};

export const getFilteredData = (saveMergedData, allFilters, showTags = true) => {
  const filteredBySearch = saveMergedData.filter((value) => {
    if (value?.isTagged && showTags) {
      return value?.entry?.value?.toLowerCase().includes(allFilters.searchFilter.toLowerCase());
    } else {
      return (
        value?.name?.toLowerCase().includes(allFilters.searchFilter.toLowerCase()) ??
        value?.address?.toLowerCase().includes(allFilters.searchFilter.toLowerCase())
      );
    }
  });
  const filteredByTypes = filteredBySearch.filter((value) => {
    if (allFilters.typesFilter.length) {
      if (allFilters.typesFilter.includes('tagged') && allFilters.typesFilter.length === 1) {
        return value?.isTagged === true;
      } else {
        return allFilters.typesFilter.includes(value?.walletType);
      }
    } else {
      return value;
    }
  });

  const filterChains = allFilters.chainsFilter
    .filter(({ checked, value }) => {
      if (checked) return value;
    })
    .map((item) => item.value.toLowerCase());
  const filteredByChains = filteredByTypes.filter((value) => {
    if (filterChains.length) {
      return filterChains.includes(value?.chain?.toLowerCase());
    } else return value;
  });
  return filteredByChains;
};

export const filterCheck = (str, allFilters, setAllFilters) => {
  if (allFilters.typesFilter.includes(str)) {
    setAllFilters({ ...allFilters, typesFilter: allFilters.typesFilter.filter((el) => el !== str) });
  } else {
    setAllFilters({ ...allFilters, typesFilter: [...allFilters.typesFilter, str] });
  }
};

export const getDisplayedWalletTagsByType = (type, tags, callbackFn) => {
  let pages = [];
  let merged = [];
  let _tags = {};
  if (tags?.pages) pages = tags.pages;
  if (pages?.length) {
    pages.map((page) => {
      merged = [...merged, ...page];
    });
  }

  const filtered = merged?.filter((item) => item?.walletType === type);

  for (let i = 0; i < filtered.length; i++) {
    for (let j = 0; j < filtered[i]?.tags?.length; j++) {
      _tags[filtered[i]?.tags[j]?._id] = filtered[i]?.tags[j];
    }
  }

  console.log({ _tags, tags });

  return Object.keys(_tags).map((tag) => (
    <div
      key={_tags[tag]?._id}
      className='w-full flex items-center cursor-pointer hover:bg-[#EEEEEE] p-4 gap-2'
      onClick={() => {
        callbackFn({
          type: 'Tag',
          value: {
            tagId: _tags[tag]?._id,
          },
        });
      }}
    >
      <div className='btn--tag'>{_tags[tag]?.entry?.key}</div>
      <div className='!border-none !bg-transparent text-[#222222] hover:bg-[#EEEEEE] duration-300 cursor-pointer block text-lg'>
        {_tags[tag]?.entry?.value}
      </div>
    </div>
  ));
};
