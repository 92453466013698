import { WALLET_CHAIN } from 'services/http/response.types';
import { IMPAIRED_FILTER_ROW, ORIGINATED_BY_FILTER_ROW, STATUS_FILTER_ROW } from '../../components/filters';

export const CHAIN_FILTER_ROWS = Object.values(WALLET_CHAIN).map((chain) => chain.toUpperCase());
export const DIRECTION_FILTER_ROWS = ['Credit', 'Debit'];

export const STATUS_FILTER_ROWS = [
  STATUS_FILTER_ROW.DRAFT,
  STATUS_FILTER_ROW.POSTED,
  STATUS_FILTER_ROW.REVERSED,
  STATUS_FILTER_ROW.UNPOSTED,
];
export const ORIGINATED_BY_FILTER_ROWS = [ORIGINATED_BY_FILTER_ROW.USER, ORIGINATED_BY_FILTER_ROW.SYSTEM];

export const IMPAIRED_FILTER_ROWS = [IMPAIRED_FILTER_ROW.TRUE, IMPAIRED_FILTER_ROW.FALSE];
