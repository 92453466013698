import React from 'react';
import { formatDollars, getDisplayedJournalEntryTemplates, mergeJournalEntryTemplates } from '../../../templates/utils';
import { SimpleMenu } from 'ui';
import { useJournalEntryTemplates } from '../../../../hooks';
import { TableSkeletonLoader } from '../../../atoms';
import { CryptoIcon } from '../../../utils';
import bigDecimal from 'js-big-decimal';
import { TemplatesByAssetTypeMap } from './ClosePeriodWizard';
import { Transaction } from 'schemas';

export type HedgeyVestingDataType = {
  [key: string]: {
    tokensVested: string;
    price: string;
    journalEntryData: {
      price: string;
      tokensVested: string;
      transaction: Transaction;
    };
  };
};
export type HedgeyRevaluationProps = {
  templatesByAssetTypeMap: TemplatesByAssetTypeMap;
  setTemplatesByAssetTypeMap: (map: TemplatesByAssetTypeMap) => void;
  vestingData: HedgeyVestingDataType;
  accountingPeriodId: string | undefined;
};

export const HedgeyRevaluation = ({
  vestingData,
  accountingPeriodId,
  isLoading,
  templatesByAssetTypeMap,
  setTemplatesByAssetTypeMap,
}) => {
  console.log(accountingPeriodId);
  const renderTableData = () => {
    if (vestingData)
      return (
        <VestingTableData
          templatesByAssetTypeMap={templatesByAssetTypeMap}
          setTemplatesByAssetTypeMap={setTemplatesByAssetTypeMap}
          vestingData={vestingData}
        />
      );
    return <></>;
  };
  return (
    <div className='w-full max-w-full'>
      {renderTableData()}

      {isLoading && <TableSkeletonLoader rows={4} />}
    </div>
  );
};

const VestingTableData = ({ vestingData, setTemplatesByAssetTypeMap, templatesByAssetTypeMap }) => {
  let data;
  if (vestingData) {
    data = Object.keys(vestingData).map((key, i) => {
      const value = { ...vestingData[key], assetType: key };
      return (
        <HedgeyVestingCard
          templatesByAssetTypeMap={templatesByAssetTypeMap}
          setTemplatesByAssetTypeMap={setTemplatesByAssetTypeMap}
          assetType={key}
          data={value}
          key={i}
        />
      );
    });
  }

  return <>{data}</>;
};

export type VestingRowProps = {
  templatesByAssetTypeMap: TemplatesByAssetTypeMap;
  setTemplatesByAssetTypeMap: (map: TemplatesByAssetTypeMap) => void;
  data: {
    tokensVested: string;
    price: string;
    journalEntryData: {
      price: string;
      tokensVested: string;
      transaction: Transaction;
    };
  };
  assetType: string;
};

export const HedgeyVestingCard = ({
  assetType,
  data,
  templatesByAssetTypeMap,
  setTemplatesByAssetTypeMap,
}: VestingRowProps) => {
  const { data: journalEntryTemplateData, isLoading: isLoadingTemplates } = useJournalEntryTemplates();
  const merged = mergeJournalEntryTemplates(journalEntryTemplateData);
  const displayedTemplates = getDisplayedJournalEntryTemplates(merged);
  const chain = data?.journalEntryData[0]?.transaction?.chain?.toUpperCase();
  return (
    <div className='flex flex-col md:flex-row flex-wrap max-w-[576px] min-w-[272px] p-4 md:items-center gap-5 rounded-lg border-zinc-300 bg-white border'>
      <div className='flex flex-row gap-x-4 items-center'>
        <CryptoIcon symbol={chain} />
        <div>
          <div className='font-bold'> {chain} </div>
          <div>{assetType}</div>
        </div>
      </div>
      <div className='flex-none'></div>
      <div className='flex-none'>
        <div>{formatDollars(data.price)}</div>
        <div>
          {bigDecimal.round(data.tokensVested, 2)} {assetType}
        </div>
      </div>

      <div className='flex-1'>
        <SimpleMenu
          placeholder='Template'
          options={displayedTemplates}
          onChange={(option) => {
            const mapCopy = new Map(templatesByAssetTypeMap);
            mapCopy.set(assetType, option.value);
            console.log(Array.from(mapCopy.entries())); // Converts map entries to array for logging
            setTemplatesByAssetTypeMap(new Map(mapCopy));
          }}
          //selectedValue={journalEntryTemplateId}
          isLoading={isLoadingTemplates}
        />
      </div>
    </div>
  );
};
