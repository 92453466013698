import { useQuery } from '@tanstack/react-query';
import { StripeProductsQueryParams, getAllStripeProducts } from 'services';
import { QUERY_KEY, useOrgBasedQueryKey } from '../../../useInvalidateQuery';

export const useGetAllStripeProductsQuery = (params: StripeProductsQueryParams) => {
  const getKey = useOrgBasedQueryKey();
  return useQuery(getKey(QUERY_KEY.STRIPE_SUBSCRIPTION_PRODUCTS), async () => {
    const response = await getAllStripeProducts(params);
    return response.data.products;
  });
};
