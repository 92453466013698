import React, { useEffect, useState } from 'react';

import { InputLabel, SimpleMenu, Button } from 'ui';

const CADENCES = [
  { label: 'Hourly', value: '0 * * * *' },
  { label: 'Daily', value: '0 0 * * *' },
  { label: 'Weekly', value: '0 0 * * 0' },
  { label: 'Monthly', value: '0 0 1 * *' },
  { label: 'Quarterly', value: '0 0 1 3,6,9,12 *' },
  { label: 'Half yearly', value: '0 0 1 6,12 *' },
  { label: 'Yearly', value: '0 0 1 1 *' },
];

export const SetJobCadence = ({ onSubmit, onCancel, disabled, isLoading, isSubmitting, defaultCronExpression }) => {
  const [selected, setSelected] = useState(
    CADENCES.find((item) => item.value === defaultCronExpression) ?? CADENCES[0],
  );

  useEffect(() => {
    if (defaultCronExpression)
      setSelected(CADENCES.find((item) => item.value === defaultCronExpression) ?? CADENCES[0]);
  }, [defaultCronExpression]);

  return (
    <div className='flex flex-col items-start w-full'>
      <InputLabel heading='Recurrence' />
      <SimpleMenu
        options={CADENCES}
        value={selected}
        onChange={setSelected}
        isLoading={isLoading}
        disabled={disabled}
      />

      <div className='flex place-self-end mt-12 gap-2'>
        <Button onClick={onCancel} emphasis='medium' label='Cancel' />
        <Button onClick={() => onSubmit(selected.value)} isLoading={isSubmitting} disabled={disabled} label='Done' />
      </div>
    </div>
  );
};
