import React from 'react';
import ConditionItem from '../ConditionItem';
import { setSaveData } from '../../../rules/utils';
import SearchFilter from './SearchFilter';
import { ConditionMenusProps } from './types';
import { TRANSACTION_CLASSIFICATION_OPTIONS, } from './constants';
// to our users its category, to us internally its classification
export const TransactionClassificationCondition = ({
  ruleSetData,
  getRuleSetData,
  isFirstLevel,
  isInLineCondition,
  callbackFn,
}: ConditionMenusProps) => {
  return (
    <ConditionItem isInLineCondition={isInLineCondition} label='Transaction Category'>
      <SearchFilter
        data={TRANSACTION_CLASSIFICATION_OPTIONS.map((item) => item.label)}
        renderItem={(item) => (
          <ConditionItem
            key={item}
            label={item}
            onClick={() => {
              if (isInLineCondition) {
                callbackFn && callbackFn(item.toLowerCase());
              } else {
                setSaveData('Transaction Classification', item.toLowerCase(), isFirstLevel, ruleSetData, getRuleSetData);
              }
            }}
          />
        )}
      />
    </ConditionItem>
  );
};

export default TransactionClassificationCondition;
