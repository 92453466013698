import clsx from 'clsx';
import { classNames as cn } from '../utils';
import { SingleAction } from './SingleAction';
import { MultipleActionsProps } from './types';

export const MultipleActions = ({ actions, state = 'default' }: MultipleActionsProps) => {
  return (
    <div
      className={cn(
        clsx(
          'px-6  flex items-center gap-3 min-w-max',
          'hover:cursor-pointer',
          { 'bg-indigo-50': state === 'selected' },
          { 'border-b-2 bg-indigo-100': state === 'active' },
        ),
      )}
    >
      {actions.map((action, idx) => (
        <SingleAction
          tooltipContent={action.tooltipContent}
          key={idx}
          title={action.title ?? ''}
          icon={action.icon}
          onClickHandler={action.onClickHandler}
        />
      ))}
    </div>
  );
};
