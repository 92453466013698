import { useQueryClient } from '@tanstack/react-query';
import { useSession } from './useSession';

export enum QUERY_KEY {
  OVERVIEW_DATA = 'OVERVIEW_DATA',
  ALGOLIA_KEY = 'ALGOLIA_KEY',
  LEGAL_ENTITIES = 'LEGAL_ENTITIES',
  WALLETS = 'WALLETS',
  WALLET_TAGS = 'WALLET_TAGS',
  WALLET_STATS = 'WALLET_STATS',
  LEDGER_ACCOUNTS = 'LEDGER_ACCOUNTS',
  IMPAIRMENT_RULES = 'IMPAIRMENT_RULES',
  ORGANIZATION_IMPAIRMENT_CONFIG = 'ORGANIZATION_IMPAIRMENT_CONFIG',
  ACCOUNTING_PERIODS = 'ACCOUNTING_PERIODS',
  BALANCE_SHEET = 'BALANCE_SHEET',
  INCOME_STATEMENT = 'INCOME_STATEMENT',
  TAGS = 'TAGS',
  ORGANIZATIONS = 'ORGANIZATIONS',
  MEMBERSHIPS = 'MEMBERSHIPS',
  ASSETS = 'ASSETS',
  SCHEDULEOFDISPOSITION = 'SCHEDULEOFDISPOSITION',
  REALIZEDGAINORLOSS = 'REALIZEDGAINORLOSS',
  CLOSINGPOSITIONS = 'CLOSINGPOSITIONS',
  ASSET_TYPES = 'ASSET_TYPES',
  ASSET_METRICS = 'ASSET_METRICS',
  EXCHANGE_SOURCES = 'EXCHANGE_SOURCES',
  EXCHANGE_SOURCE_SYNC_HISTORY_RECORDS = 'EXCHANGE_SOURCE_SYNC_HISTORY_RECORDS',
  HEDGEY_SOURCES = 'HEDGEY_SOURCES',
  HEDGEY_SOURCE_JOB_HISTORY_RECORDS = 'HEDGEY_SOURCE_JOB_HISTORY_RECORDS',
  EXTERNAL_ACCOUNTS = 'EXTERNAL_ACCOUNTS',
  INTEGRATION_CONFIGS = 'INTEGRATION_CONFIGS',
  INTEGRATION_AUTHS = 'INTEGRATION_AUTHS',
  EXTERNAL_ACCOUNT_MAPPINGS = 'EXTERNAL_ACCOUNT_MAPPINGS',
  JOURNAL_ENTRIES = 'JOURNAL_ENTRIES',
  JOURNAL_ENTRY_FILE_ATTACHMENTS = 'JOURNAL_ENTRY_FILE_ATTACHMENTS',
  ACCOUNT_POSTING_RULESET_CONFIGS = 'ACCOUNT_POSTING_RULESET_CONFIGS',
  ACCOUNT_POSTING_RULE_METRICS = 'ACCOUNT_POSTING_RULE_METRICS',
  ACCOUNT_POSTING_RULESETS = 'ACCOUNT_POSTING_RULESETS',
  JOURNAL_ENTRY_TEMPLATES = 'JOURNAL_ENTRY_TEMPLATES',
  GET_ALL_JOURNAL_ENTRY_TEMPLATES = 'GET_ALL_JOURNAL_ENTRY_TEMPLATES',
  LINE_TEMPLATES = 'LINE_TEMPLATES',
  TRANSACTIONS = 'TRANSACTIONS',
  TRANSACTION_BY_ID = 'TRANSACTION_BY_ID',
  TRANSACTION_STATS = 'TRANSACTION_STATS',
  TRANSACTIONS_LAST_DATE = 'TRANSACTIONS_LAST_DATE',
  USER = 'USER',
  QUICKBOOKS_REDIRECT_URL = 'QUICKBOOKS_REDIRECT_URL',
  XERO_REDIRECT_URL = 'XERO_REDIRECT_URL',
  SYNC_HISTORY_RECORDS = 'SYNC_HISTORY_RECORDS',
  JOB_PROGRESS = 'JOB_PROGRESS',
  JOB_CONFIGS = 'JOB_CONFIGS',
  TRIAL_BALANCE = 'TRIAL_BALANCE',
  ORGANIZATION_MEMBERS = 'ORGANIZATION_MEMBERS',
  RAINCARDS_INTEGRATION_CONFIGURATION = 'RAINCARDS_INTEGRATION_CONFIGURATION',
  RAINCARDS_SOURCES = 'RAINCARDS_SOURCES',
  RAINCARDS_JOB_HISTORY = 'RAINCARDS_JOB_HISTORY',
  SCHEDULED_RULESET_JOBS = 'SCHEDULED_RULESET_JOBS',
  SCHEDULED_IMPAIRMENT_JOBS = 'SCHEDULED_IMPAIRMENT_JOBS',
  RAINCARDS_RULE_META = 'RAINCARDS_RULE_META',
  LOOP_RULE_META = 'LOOP_RULE_META',
  SOURCES_TIMESTAMP = 'SOURCES_TIMESTAMP',
  TRANSACTIONS_TIMESTAMP = 'TRANSACTIONS_TIMESTAMP',
  JOURNALS_TIMESTAMP = 'JOURNALS_TIMESTAMP',
  ASSETS_TIMESTAMP = 'ASSETS_TIMESTAMP',
  TEMPLATES_TIMESTAMP = 'TEMPLATES_TIMESTAMP',
  RULESETS_TIMESTAMP = 'RULESETS_TIMESTAMP',
  TAGS_TIMESTAMP = 'TAGS_TIMESTAMP',
  IMPAIRMENTS_TIMESTAMP = 'IMPAIRMENTS_TIMESTAMP',
  LEDGER_ACCOUNTS_TIMESTAMP = 'LEDGER_ACCOUNTS_TIMESTAMP',
  LEGAL_ENTITIES_TIMESTAMP = 'LEGAL_ENTITIES_TIMESTAMP',
  REPORTS_TIMESTAMP = 'REPORTS_TIMESTAMP',
  INTEGRATIONS_TIMESTAMP = 'INTEGRATIONS_TIMESTAMP',
  TABS_SERVICE = 'TABS_SERVICE',
  JOURNAL_ENTRY_VOLUME = 'JOURNAL_ENTRY_VOLUME',
  HISTORICAL_ASSET_PRICE = 'HISTORICAL_ASSET_PRICE',
  TRANSACTION_ASSET_RELATIVE_PRICES = 'TRANSACTION_ASSET_RELATIVE_PRICES',
  ASSET_BREAKDOWN = 'ASSET_BREAKDOWN',
  TRANSACTIONS_VOLUME = 'TRANSACTIONS_VOLUME',
  LOOP_INTEGRATION_CONFIGURATION = 'LOOP_INTEGRATION_CONFIGURATION',
  STRIPE_AI_CREDITS_RECHARGE_OPTIONS = 'STRIPE_AI_CREDITS_RECHARGE_OPTIONS',
  STRIPE_SUBSCRIPTION_PRODUCTS = 'STRIPE_SUBSCRIPTION_PRODUCTS',
  STRIPE_CARDS = 'STRIPE_CARDS',
  STRIPE_INVOICES = 'STRIPE_INVOICES',
  STRIPE_DETAILS_FOR_ORG = 'STRIPE_DETAILS_FOR_ORG',
  USAGE = 'USAGE',
  GET_ALL_GL_INTEGRATION_CONFIGS = 'GET_ALL_GL_INTEGRATION_CONFIGS',
  GET_DISTINCT_FUNCTION_NAMES = 'GET_DISTINCT_FUNCTION_NAMES',
  GET_DISTINCT_CONTRACT_NAMES = 'GET_DISTINCT_CONTRACT_NAMES',
}

type InvalidateFn = (slug?: any) => Promise<void>;
type InvalidateQuery =
  | 'invalidateOverviewData'
  | 'invalidateAlgoliaKey'
  | 'invalidateLegalEntities'
  | 'invalidateWallets'
  | 'invalidateWalletTags'
  | 'invalidateLedgerAccounts'
  | 'invalidateImpairmentRules'
  | 'invalidateOrganizationImpairmentConfig'
  | 'invalidateAccountingPeriods'
  | 'invalidateBalanceSheets'
  | 'invalidateIncomeStatements'
  | 'invalidateTrialBalances'
  | 'invalidateTags'
  | 'invalidateMemberships'
  | 'invalidateOrganizations'
  | 'invalidateUser'
  | 'invalidateAssets'
  | 'invalidateAssetTypes'
  | 'invalidateAssetMetrics'
  | 'invalidateExchangeSources'
  | 'invalidateExchangeSourceSyncHistoryRecords'
  | 'invalidateHedgeySources'
  | 'invalidateHedgeySourceJobHistoryRecords'
  | 'invalidateExternalAccounts'
  | 'invalidateExternalAccountMappings'
  | 'invalidateIntegrationConfigs'
  | 'invalidateIntegrationAuths'
  | 'invalidateJournalEntries'
  | 'invalidateJournalEntryFileAttachments'
  | 'invalidateJournalEntryTemplates'
  | 'invalidateAllJournalEntryTemplates'
  | 'invalidateLineTemplates'
  | 'invalidateAccountPostingRulesetConfigs'
  | 'invalidateAccountPostingRuleMetrics'
  | 'invalidateAccountPostingRulesets'
  | 'invalidateTransactions'
  | 'invalidateSourcesTimestamp'
  | 'invalidateTransactionsTimestamp'
  | 'invalidateJournalsTimestamp'
  | 'invalidateAssetsTimestamp'
  | 'invalidateTemplatesTimestamp'
  | 'invalidateRulesetsTimestamp'
  | 'invalidateTagsTimestamp'
  | 'invalidateImpairmentsTimestamp'
  | 'invalidateLedgerAccountsTimestamp'
  | 'invalidateLegalEntitiesTimestamp'
  | 'invalidateReportsTimestamp'
  | 'invalidateIntegrationsTimestamp'
  | 'invalidateTransactionById'
  | 'invalidateTransactionStats'
  | 'invalidateTransactionLastDate'
  | 'invalidateQuickbooksRedirectUrl'
  | 'invalidateSyncHistoryRecords'
  | 'invalidateJobProgress'
  | 'invalidateJobConfigs'
  | 'invalidateScheduledRulesetJobs'
  | 'invalidateScheduledImpairmentJobs'
  | 'invalidateTabsService'
  | 'invalidateRaincardsIntegrationConfiguration'
  | 'invalidateLoopIntegrationConfiguration'
  | 'invalidateOrganizationMembers'
  | 'invalidateStripeAICreditsRechargeOptions'
  | 'invalidateStripeCards'
  | 'invalidateStripeDetailsForOrg'
  | 'invalidateStripeInvoices'
  | 'invalidateStripeSubscriptionProducts'
  | 'invalidateUsage';

export type InvalidateQueries = Record<InvalidateQuery, InvalidateFn>;

export const useOrgBasedQueryKey = () => {
  const { organizationId } = useSession();

  const getKey = (key: QUERY_KEY, slug?: any) => (slug ? [key, organizationId, slug] : [key, organizationId]);

  return getKey;
};

export const useInvalidateQuery = (): InvalidateQueries => {
  const queryClient = useQueryClient();
  const getKey = useOrgBasedQueryKey();

  const invalidate = async (key: QUERY_KEY, slug?: any) => {
    await queryClient.invalidateQueries(getKey(key, slug));
    // console.log('queries invalidated for', getKey(key), '\n slug: ', slug);
  };

  const invalidateOrganizationMembers = (slug?: any) => invalidate(QUERY_KEY.ORGANIZATION_MEMBERS, slug);
  const invalidateOverviewData = (slug?: any) => invalidate(QUERY_KEY.OVERVIEW_DATA, slug);
  const invalidateAlgoliaKey = (slug?: any) => invalidate(QUERY_KEY.ALGOLIA_KEY, slug);

  const invalidateLegalEntities = (slug?: any) => invalidate(QUERY_KEY.LEGAL_ENTITIES, slug);

  const invalidateWallets = (slug?: any) => invalidate(QUERY_KEY.WALLETS, slug);
  const invalidateWalletTags = (slug?: any) => invalidate(QUERY_KEY.WALLET_TAGS, slug);

  const invalidateLedgerAccounts = (slug?: any) => invalidate(QUERY_KEY.LEDGER_ACCOUNTS, slug);

  const invalidateImpairmentRules = (slug?: any) => invalidate(QUERY_KEY.IMPAIRMENT_RULES, slug);
  const invalidateOrganizationImpairmentConfig = (slug?: any) =>
    invalidate(QUERY_KEY.ORGANIZATION_IMPAIRMENT_CONFIG, slug);

  const invalidateAccountingPeriods = (slug?: any) => invalidate(QUERY_KEY.ACCOUNTING_PERIODS, slug);

  const invalidateBalanceSheets = (slug?: any) => invalidate(QUERY_KEY.BALANCE_SHEET, slug);
  const invalidateIncomeStatements = (slug?: any) => invalidate(QUERY_KEY.INCOME_STATEMENT, slug);
  const invalidateTrialBalances = (slug?: any) => invalidate(QUERY_KEY.TRIAL_BALANCE, slug);

  const invalidateTags = (slug?: any) => invalidate(QUERY_KEY.TAGS, slug);

  const invalidateMemberships = (slug?: any) => invalidate(QUERY_KEY.MEMBERSHIPS, slug);
  const invalidateOrganizations = (slug?: any) => invalidate(QUERY_KEY.ORGANIZATIONS, slug);
  const invalidateUser = (slug?: any) => invalidate(QUERY_KEY.USER, slug);

  const invalidateAssets = (slug?: any) => invalidate(QUERY_KEY.ASSETS, slug);
  const invalidateAssetTypes = (slug?: any) => invalidate(QUERY_KEY.ASSET_TYPES, slug);
  const invalidateAssetMetrics = (slug?: any) => invalidate(QUERY_KEY.ASSET_METRICS, slug);

  const invalidateExchangeSources = (slug?: any) => invalidate(QUERY_KEY.EXCHANGE_SOURCES, slug);
  const invalidateExchangeSourceSyncHistoryRecords = (slug?: any) =>
    invalidate(QUERY_KEY.EXCHANGE_SOURCE_SYNC_HISTORY_RECORDS, slug);

  const invalidateHedgeySources = (slug?: any) => invalidate(QUERY_KEY.HEDGEY_SOURCES, slug);
  const invalidateHedgeySourceJobHistoryRecords = (slug?: any) =>
    invalidate(QUERY_KEY.HEDGEY_SOURCE_JOB_HISTORY_RECORDS, slug);

  const invalidateExternalAccounts = (slug?: any) => invalidate(QUERY_KEY.EXTERNAL_ACCOUNTS, slug);
  const invalidateExternalAccountMappings = (slug?: any) => invalidate(QUERY_KEY.EXTERNAL_ACCOUNT_MAPPINGS, slug);

  const invalidateIntegrationConfigs = (slug?: any) => invalidate(QUERY_KEY.INTEGRATION_CONFIGS, slug);
  const invalidateIntegrationAuths = (slug?: any) => invalidate(QUERY_KEY.INTEGRATION_AUTHS, slug);

  const invalidateJournalEntries = (slug?: any) => invalidate(QUERY_KEY.JOURNAL_ENTRIES, slug);
  const invalidateJournalEntryFileAttachments = (slug?: any) =>
    invalidate(QUERY_KEY.JOURNAL_ENTRY_FILE_ATTACHMENTS, slug);
  const invalidateJournalEntryTemplates = (slug?: any) => invalidate(QUERY_KEY.JOURNAL_ENTRY_TEMPLATES, slug);
  const invalidateAllJournalEntryTemplates = (slug?: any) =>
    invalidate(QUERY_KEY.GET_ALL_JOURNAL_ENTRY_TEMPLATES, slug);

  const invalidateLineTemplates = (slug?: any) => invalidate(QUERY_KEY.LINE_TEMPLATES, slug);

  const invalidateAccountPostingRulesetConfigs = (slug?: any) =>
    invalidate(QUERY_KEY.ACCOUNT_POSTING_RULESET_CONFIGS, slug);
  const invalidateAccountPostingRuleMetrics = (slug?: any) => invalidate(QUERY_KEY.ACCOUNT_POSTING_RULE_METRICS, slug);
  const invalidateAccountPostingRulesets = (slug?: any) => invalidate(QUERY_KEY.ACCOUNT_POSTING_RULESETS, slug);

  const invalidateTransactions = (slug?: any) => invalidate(QUERY_KEY.TRANSACTIONS, slug);
  const invalidateTransactionById = (slug?: any) => invalidate(QUERY_KEY.TRANSACTION_BY_ID, slug);

  const invalidateTransactionStats = (slug?: any) => invalidate(QUERY_KEY.TRANSACTION_STATS, slug);
  const invalidateTransactionLastDate = (slug?: any) => invalidate(QUERY_KEY.TRANSACTIONS_LAST_DATE, slug);

  const invalidateQuickbooksRedirectUrl = (slug?: any) => invalidate(QUERY_KEY.QUICKBOOKS_REDIRECT_URL, slug);
  const invalidateSyncHistoryRecords = (slug?: any) => invalidate(QUERY_KEY.SYNC_HISTORY_RECORDS, slug);

  const invalidateJobProgress = (slug?: any) => invalidate(QUERY_KEY.JOB_PROGRESS, slug);
  const invalidateJobConfigs = (slug?: any) => invalidate(QUERY_KEY.JOB_CONFIGS, slug);

  const invalidateScheduledRulesetJobs = (slug?: any) => invalidate(QUERY_KEY.SCHEDULED_RULESET_JOBS, slug);
  const invalidateScheduledImpairmentJobs = (slug?: any) => invalidate(QUERY_KEY.SCHEDULED_IMPAIRMENT_JOBS, slug);
  const invalidateSourcesTimestamp = (slug?: any) => invalidate(QUERY_KEY.SOURCES_TIMESTAMP, slug);
  const invalidateTransactionsTimestamp = (slug?: any) => invalidate(QUERY_KEY.TRANSACTIONS_TIMESTAMP, slug);
  const invalidateJournalsTimestamp = (slug?: any) => invalidate(QUERY_KEY.JOURNALS_TIMESTAMP, slug);
  const invalidateAssetsTimestamp = (slug?: any) => invalidate(QUERY_KEY.ASSETS_TIMESTAMP, slug);
  const invalidateTemplatesTimestamp = (slug?: any) => invalidate(QUERY_KEY.TEMPLATES_TIMESTAMP, slug);
  const invalidateRulesetsTimestamp = (slug?: any) => invalidate(QUERY_KEY.RULESETS_TIMESTAMP, slug);
  const invalidateTagsTimestamp = (slug?: any) => invalidate(QUERY_KEY.TAGS_TIMESTAMP, slug);
  const invalidateImpairmentsTimestamp = (slug?: any) => invalidate(QUERY_KEY.IMPAIRMENTS_TIMESTAMP, slug);
  const invalidateLedgerAccountsTimestamp = (slug?: any) => invalidate(QUERY_KEY.LEDGER_ACCOUNTS_TIMESTAMP, slug);
  const invalidateLegalEntitiesTimestamp = (slug?: any) => invalidate(QUERY_KEY.LEGAL_ENTITIES_TIMESTAMP, slug);
  const invalidateReportsTimestamp = (slug?: any) => invalidate(QUERY_KEY.REPORTS_TIMESTAMP, slug);
  const invalidateIntegrationsTimestamp = (slug?: any) => invalidate(QUERY_KEY.INTEGRATIONS_TIMESTAMP, slug);

  const invalidateTabsService = (slug?: any) => invalidate(QUERY_KEY.TABS_SERVICE, slug);

  const invalidateRaincardsIntegrationConfiguration = (slug?: any) =>
    invalidate(QUERY_KEY.RAINCARDS_INTEGRATION_CONFIGURATION, slug);
  const invalidateLoopIntegrationConfiguration = (slug?: any) =>
    invalidate(QUERY_KEY.LOOP_INTEGRATION_CONFIGURATION, slug);

  const invalidateStripeAICreditsRechargeOptions = (slug?: any) =>
    invalidate(QUERY_KEY.STRIPE_AI_CREDITS_RECHARGE_OPTIONS, slug);
  const invalidateStripeDetailsForOrg = (slug?: any) => invalidate(QUERY_KEY.STRIPE_DETAILS_FOR_ORG, slug);
  const invalidateStripeInvoices = (slug?: any) => invalidate(QUERY_KEY.STRIPE_INVOICES, slug);
  const invalidateStripeCards = (slug?: any) => invalidate(QUERY_KEY.STRIPE_CARDS, slug);
  const invalidateStripeSubscriptionProducts = (slug?: any) => invalidate(QUERY_KEY.STRIPE_SUBSCRIPTION_PRODUCTS, slug);

  const invalidateUsage = (slug?: any) => invalidate(QUERY_KEY.USAGE, slug);

  return {
    invalidateSourcesTimestamp,
    invalidateTransactionsTimestamp,
    invalidateJournalsTimestamp,
    invalidateAssetsTimestamp,
    invalidateTemplatesTimestamp,
    invalidateRulesetsTimestamp,
    invalidateTagsTimestamp,
    invalidateImpairmentsTimestamp,
    invalidateLedgerAccountsTimestamp,
    invalidateLegalEntitiesTimestamp,
    invalidateReportsTimestamp,
    invalidateIntegrationsTimestamp,

    invalidateOverviewData,
    invalidateAlgoliaKey,

    invalidateLegalEntities,

    invalidateWallets,
    invalidateWalletTags,

    invalidateLedgerAccounts,

    invalidateImpairmentRules,
    invalidateOrganizationImpairmentConfig,

    invalidateAccountingPeriods,

    invalidateBalanceSheets,
    invalidateIncomeStatements,
    invalidateTrialBalances,

    invalidateTags,

    invalidateMemberships,
    invalidateOrganizations,
    invalidateUser,

    invalidateAssets,
    invalidateAssetTypes,
    invalidateAssetMetrics,

    invalidateExchangeSources,
    invalidateExchangeSourceSyncHistoryRecords,

    invalidateHedgeySources,
    invalidateHedgeySourceJobHistoryRecords,

    invalidateExternalAccounts,
    invalidateExternalAccountMappings,

    invalidateIntegrationConfigs,
    invalidateIntegrationAuths,

    invalidateJournalEntries,
    invalidateJournalEntryFileAttachments,
    invalidateJournalEntryTemplates,
    invalidateAllJournalEntryTemplates,
    invalidateLineTemplates,

    invalidateAccountPostingRulesetConfigs,
    invalidateAccountPostingRuleMetrics,
    invalidateAccountPostingRulesets,

    invalidateTransactions,
    invalidateTransactionById,
    invalidateTransactionStats,
    invalidateTransactionLastDate,

    invalidateQuickbooksRedirectUrl,
    invalidateSyncHistoryRecords,

    invalidateJobProgress,
    invalidateJobConfigs,

    invalidateScheduledRulesetJobs,
    invalidateScheduledImpairmentJobs,
    invalidateTabsService,

    invalidateRaincardsIntegrationConfiguration,
    invalidateLoopIntegrationConfiguration,

    invalidateOrganizationMembers,

    invalidateStripeAICreditsRechargeOptions,
    invalidateStripeCards,
    invalidateStripeDetailsForOrg,
    invalidateStripeInvoices,
    invalidateStripeSubscriptionProducts,

    invalidateUsage,
  };
};
