import React, { useEffect, useState } from 'react';
import {
  ButtonProps,
  DESCRIPTIVE_LOADER_STATES,
  DescriptiveLoader,
  LoaderIcon,
  ModalContent,
  SelectableCardWithRadio,
} from 'ui';
import { deriveError, formatDollars } from '../../../templates/utils';
import {
  useGetCardsFromStripeQuery,
  useGetStripeDetailsForOrgQuery,
  useInvalidateQuery,
  usePayStripeInvoiceMutation,
} from '../../../../hooks';
import { PaymentCard } from '../../common';
import toast from 'react-hot-toast';
import Stripe from 'stripe';

const getStripePaymentIntentDescription = (paymentIntent?: Stripe.PaymentIntent) => {
  switch (paymentIntent?.status) {
    case 'succeeded':
      return 'Payment successful';
    case 'canceled':
      return 'Payment cancelled';
    case 'processing':
      return 'We will notify you once the payment processing is complete';
    case 'requires_action':
      return 'Payment method needs setup';
    case 'requires_payment_method':
      return 'Please specify a valid payment method and try again';
    case 'requires_confirmation':
      return 'Payment method needs setup';
  }
};
const getStripePaymentIntentStatus = (paymentIntent?: Stripe.PaymentIntent) => {
  switch (paymentIntent?.status) {
    case 'succeeded':
      return DESCRIPTIVE_LOADER_STATES.SUCCESS;
    case 'canceled':
      return DESCRIPTIVE_LOADER_STATES.ERROR;
    case 'processing':
      return DESCRIPTIVE_LOADER_STATES.SUCCESS;
    case 'requires_action':
      return DESCRIPTIVE_LOADER_STATES.ERROR;
    case 'requires_payment_method':
      return DESCRIPTIVE_LOADER_STATES.ERROR;
    case 'requires_confirmation':
      return DESCRIPTIVE_LOADER_STATES.ERROR;
  }
};

export const SelectPaymentMethod = ({ invoice, onClose }) => {
  const { data: stripeDetails } = useGetStripeDetailsForOrgQuery();
  const { data: stripeCards, isLoading: isLoadingStripeCards } = useGetCardsFromStripeQuery();
  const { mutateAsync: payInvoice, isLoading, isSuccess, isError, isIdle } = usePayStripeInvoiceMutation();

  const { invalidateStripeInvoices } = useInvalidateQuery();

  const [paymentMethodId, setPaymentMethodId] = useState<string>();

  const [status, setStatus] = useState<DESCRIPTIVE_LOADER_STATES | undefined>(DESCRIPTIVE_LOADER_STATES.LOADING);
  const [description, setDescription] = useState<string | undefined>('');

  const invoiceDescription =
    invoice.lines.data.length > 1
      ? invoice.lines.data[0].description + ` and ${invoice.lines.data.length - 1} more items...`
      : invoice.lines.data[0].description;

  useEffect(() => {
    if (paymentMethodId) return;
    if (!stripeCards) return;

    const defaultPaymentMethodId = stripeDetails?.paymentMethod?.id;

    const dpm = stripeCards.find((card) => card.id === defaultPaymentMethodId);

    console.log(dpm, stripeCards, defaultPaymentMethodId);
    setPaymentMethodId(dpm?.id);
  }, [stripeDetails, stripeCards, paymentMethodId]);

  console.log(paymentMethodId);

  const actions: ButtonProps[] = isIdle
    ? [
        {
          label: 'Confirm',
          onClick: async () => {
            try {
              if (!paymentMethodId) throw new Error('Please select a payment method');
              const response = await payInvoice({ invoiceId: invoice.id, paymentMethodId });
              setStatus(getStripePaymentIntentStatus(response.data.paymentIntent));
              setDescription(getStripePaymentIntentDescription(response.data.paymentIntent));
              invalidateStripeInvoices();
            } catch (error) {
              toast.error(deriveError(error));
            }
          },
        },
        { label: 'Cancel', emphasis: 'medium', onClick: onClose },
      ]
    : [];

  return (
    <ModalContent
      title={isIdle ? 'Confirm payment' : undefined}
      // onClose={onClose}
      // backAction={isIdle ? { onClick: onBack } : undefined}
      actions={actions}
    >
      {isIdle && (
        <div className='mt-4'>
          <div className='bg-zinc-100 p-3 mb-4 rounded font-semibold'>
            <div className='tracking-wide'>{invoice.number}</div>
            <div className='w-full flex items-center justify-between text-sm text-zinc-600'>
              <div>{invoiceDescription}</div>
              <div>{formatDollars(invoice.amount_due / 100)}</div>
            </div>
          </div>
          <div className='mb-3 text-lg text-zinc-500 font-semibold'>
            Please select payment method and confirm payment of invoice.
          </div>
          <div>
            <div className='flex flex-col gap-y-2'>
              {isLoadingStripeCards && !stripeCards && <LoaderIcon />}
              {stripeCards?.length === 0 && <div className='text-sm'>No payment methods found</div>}
              {stripeCards?.map((data, i) =>
                data.card ? (
                  <SelectableCardWithRadio
                    key={i}
                    selected={paymentMethodId === data.id}
                    onClick={() => setPaymentMethodId(data.id)}
                    label={
                      <PaymentCard
                        className='p-0 border-0'
                        brand={data.card.brand}
                        last4={data.card.last4}
                        expiresOn={`${data.card.exp_month}/${`${data.card.exp_year}`.slice(-2)}`}
                        paymentMethodId={data.id}
                        hideRemove
                      />
                    }
                  />
                ) : null,
              )}
            </div>
          </div>
        </div>
      )}
      {isSuccess && <DescriptiveLoader title='Success' description={description} status={status} />}
      {isLoading && <DescriptiveLoader title='Processing payment' status={DESCRIPTIVE_LOADER_STATES.LOADING} />}
      {isError && <DescriptiveLoader title='Error' status={DESCRIPTIVE_LOADER_STATES.ERROR} />}
    </ModalContent>
  );
};
