import axios from 'axios';
import { GetLedgerAccountType, PostLAPayload } from '../response.types';
import { SERVER_URL_CORE } from '../../config';
import { buildMultiParams } from './utils';
import { GetLedgerAccountPayloadType } from '../types';

export const createLedgerAccount = async (data: PostLAPayload) => axios.post(`${SERVER_URL_CORE}/ledger-account`, data);

export const getLedgerAccount = async (data: Partial<GetLedgerAccountPayloadType>) => {
  let params = '';
  const ledgerAccountTypes = buildMultiParams(data.ledgerAccountTypes, 'ledgerAccountTypes');
  if (ledgerAccountTypes) params += ledgerAccountTypes;
  if (data.isLeaf) params += `&isLeaf=${data.isLeaf}`;
  if (data.sort) params += `&sortBy=${data.sort.id}&sortDirection=${data.sort.desc ? 'desc' : 'asc'}`;
  params += `&pageSize=${data.pageSize}`;

  return axios.get<{ ledgerAccounts: GetLedgerAccountType[] }>(`${SERVER_URL_CORE}/ledger-account?${params}`);
};

export const getLedgerAccountById = async (data) =>
  axios.get(`${SERVER_URL_CORE}/ledger-account-id?ledgerAccountId=${data.ledgerAccountId}`);

export const deleteLedgerAccount = async (data: { ledgerAccountId: string; organizationId: string }) =>
  axios.delete(
    `${SERVER_URL_CORE}/ledger-account?ledgerAccountId=${data.ledgerAccountId}&organizationId=${data.organizationId}`,
  );

export const updateLedgerAccount = async (data) =>
  axios.patch(`${SERVER_URL_CORE}/ledger-account?ledgerAccountId=${data.ledgerAccountId}`, {
    ledgerAccount: data.ledgerAccount,
  });
