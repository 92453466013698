import { Button, ButtonProps } from '../button';

export const ModalActions = ({ actions }: { actions: ButtonProps[] }) =>
  actions.length > 0 ? (
    <div className='flex flex-row-reverse gap-x-2 w-full'>
      {actions.map((action, i) => (
        <Button key={i} {...action} />
      ))}
    </div>
  ) : null;
