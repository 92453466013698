import clsx from 'clsx';
import { classNames } from '../utils';
import { DefaultCellProps } from './types';

export function DefaultCell({
  label,
  className = '',
  textAlign = 'text-center',
  size = 'default',
  withoutPaddingLeft = false,
}: DefaultCellProps) {
  return (
    <p
      className={classNames(
        clsx(
          'group relative text-zinc-900 w-[inherit] whitespace-nowrap',
          { 'text-center justify-center': textAlign === 'text-center' },
          { 'text-left justify-start': textAlign === 'text-left' },
          { 'text-right justify-end': textAlign === 'text-right' },
          { 'px-4': size === 'small' },
          { 'px-6': size === 'default' },
          { '!pl-0': withoutPaddingLeft },
          className,
        ),
      )}
    >
      {label}
    </p>
  );
}
