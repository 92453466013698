import React, { useRef, useState } from 'react';
import { Toast } from './atoms';
import { SidebarRoot, Sidebar, SidebarTopBar, SidebarBody, SidebarFooter } from './atoms/Sidebar';
import { useCreateTag } from '../hooks/http';
import { deriveError } from './templates/utils';
import { useOnClickOutside } from 'usehooks-ts';
import { SidebarSection } from './atoms/Sidebar/SidebarBody/SidebarSection';
import SidebarHeader from './atoms/Sidebar/SidebarHeader/SidebarHeader';
import { Button, TagInput } from 'ui';
import { SidebarSectionHeader } from './atoms/Sidebar/SidebarBody';
import { useSession } from '../hooks/useSession';
import { toast } from 'react-hot-toast';
import { useInvalidateQuery } from '../hooks';

function CreateTag({
  onCancel = () => {
    undefined;
  },
  sidebarId,
}) {
  const ref = useRef(null);

  const [loading, setLoading] = useState(false);
  const { mutateAsync } = useCreateTag();
  const [tag, setTag] = useState({ key: '', value: '' });

  const { organizationId, userId } = useSession();
  const { invalidateTags } = useInvalidateQuery();

  const createTag = async () => {
    setLoading(true);
    try {
      const requestedData = {
        entry: {
          organizationId,
          key: tag.key,
          value: tag.value,
        },
        usageCount: 0,
        status: 'unused',
        createdBy: userId,
        lastUpdatedBy: userId,
        organizationId,
      };

      await mutateAsync(requestedData, {
        onSuccess: () => {
          invalidateTags();
          setLoading(false);
          onCancel();
        },
      });
      toast.success('Your tag has been added');
    } catch (e) {
      console.log(e);
      setLoading(false);
      toast.error(deriveError(e));
      invalidateTags();
    }
  };

  useOnClickOutside(ref, () => onCancel());
  return (
    <SidebarRoot>
      <Sidebar rootId={sidebarId}>
        <SidebarTopBar onClose={onCancel} />
        <SidebarHeader title='Create tag' />
        <SidebarBody>
          <SidebarSectionHeader title='Details' subtitle='Select tag type and tag value.' />
          <SidebarSection numberOfColumns={1}>
            <TagInput tag={tag} setTag={setTag} />
          </SidebarSection>
        </SidebarBody>
        <SidebarFooter
          secondaryBtn={<Button label='Cancel' emphasis='medium' onClick={onCancel} />}
          primaryBtn={
            <Button
              label={'Create tag'}
              data-cy='createTag__saveButton'
              onClick={() => {
                if (tag.key && tag.value) {
                  createTag();
                }
              }}
              disabled={loading || !tag.key || !tag.value}
              isLoading={loading}
            />
          }
        />
      </Sidebar>
      <Toast />
    </SidebarRoot>
  );
}

export default CreateTag;
