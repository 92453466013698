import React from 'react';
import { components } from 'react-select';
import { CustomMenuList } from './type';
import { Button } from 'ui';
import TabsComponent from '../../Sidebar/SidebarHeader/Tabs';
import { MdClose } from 'react-icons/md';

export const MenuList = ({
  optionsData,
  setOptionsData,
  setMenuIsOpen,
  menuLabel,
  showClearButton,
  hasTabs = false,
  tabs = [],
  activeTabId = '',
  setActiveTabId = () => undefined,
  isLoading,
  ...props
}: CustomMenuList) => {
  if (isLoading) return <>{props.children}</>;
  if (!optionsData.slice(1).length)
    return <div className='flex justify-center items-center py-2 px-3'>{props.children}</div>;
  return (
    <components.MenuList className='relative flex flex-col gap-y-1.5 items-start !p-0 z-50' {...props}>
      {!!menuLabel?.length && (
        <div className='flex w-full min-w-[248px] items-center justify-between mb-4 px-4 py-1 border-b sticky top-0 bg-white z-10'>
          <div className='text-xl font-medium'>{menuLabel}</div>
          <Button emphasis='low' leadingIcon={<MdClose fontSize={24} />} onClick={() => setMenuIsOpen(false)} />
        </div>
      )}
      {hasTabs && (
        <div className='w-full flex flex-col items-center justify-around border-b-2 pb-0 mb-4'>
          <TabsComponent tabs={tabs} activeTabId={activeTabId} />
        </div>
      )}
      {props.children}
      {showClearButton && (
        <div className='sticky w-full bottom-0 bg-white flex min-w-[248px] justify-end py-2 px-2 border-t z-10'>
          <Button
            onClick={() => {
              optionsData.map((item) => (item.checked = false));
              setOptionsData(optionsData);
              if (hasTabs) {
                setActiveTabId('');
              }
            }}
            emphasis='low'
            className='!w-fit !border-none'
            label='Clear'
          />
        </div>
      )}
    </components.MenuList>
  );
};

export default MenuList;
