import {
  AccountingPeriod,
  CREDIT_OR_DEBIT,
  JournalEntryTemplate,
  LedgerAccount,
  LegalEntity,
  Tag,
  Transaction,
} from 'services/http/response.types';
import { getAllEntriesForSyncHistoryOperationRecord } from 'services/http';
import { useGetAll } from '../';
import { useCsvExport } from './useCsvExport';
import { formatDollars } from '../../components/templates/utils';
import { JournalEntryLineDB, JournalEntryDB, JournalEntrySyncRecord } from 'schemas';

enum SyncHistoryRecord_CSV_HEADER {
  LINE_ID = 'Line id',
  JOURNAL_ENTRY_LINE_LEGAL_ENTITY = 'Legal Entity',
  LEDGER_ACCOUNT = 'Ledger Account',
  DEBIT_CREDIT = 'Debit/Credit',
  JOURNAL_ENTRY_LINE_TAGS = 'Tags',
  JOURNAL_ENTRY_MEMO = 'Journal entry memo',
  JOURNAL_ENTRY_SEQUENCE_ID = 'Journal Entry Sequence Id',
  JOURNAL_ENTRY_LEGAL_ENTITY = 'Legal Entity',
  OPERATIONAL_TRANSACTION = 'Operational Transaction',
  OPERATIONAL_TRANSACTION_HASH = 'Operational Transaction Hash',
  TEMPLATE_NAME = 'Template Name',
  ACCOUNTING_PERIOD = 'Accounting Period',
  ACCOUNTING_DATE = 'Accounting Date',
  TOTAL_DEBIT_AMOUNT = 'Total Debit Amount',
  TOTAL_CREDIT_AMOUNT = 'Total Credit Amount',
  JOURNAL_ENTRY_LINE_MEMO = 'Journal entry line memo',
  JOURNAL_ENTRY_TAGS = 'Tags',
}

const getCsvColumnValueFromSyncHistoryRecord = (
  lineId: string,
  record: JournalEntrySyncRecord,
  column: SyncHistoryRecord_CSV_HEADER,
) => {
  const je = record.journalEntryId as JournalEntryDB;
  const journalEntryLine = (je.journalEntryLineIds as JournalEntryLineDB[]).find((line) => line._id === lineId);
  if (!journalEntryLine) return;
  switch (column) {
    case SyncHistoryRecord_CSV_HEADER.LINE_ID:
      return journalEntryLine._id;
    case SyncHistoryRecord_CSV_HEADER.JOURNAL_ENTRY_LINE_LEGAL_ENTITY:
      return (journalEntryLine.legalEntityId as LegalEntity).entityName;
    case SyncHistoryRecord_CSV_HEADER.LEDGER_ACCOUNT:
      return `${(journalEntryLine.ledgerAccountId as LedgerAccount).ledgerAccountSequence}:${
        (journalEntryLine.ledgerAccountId as LedgerAccount).ledgerAccountName
      }`;
    case SyncHistoryRecord_CSV_HEADER.DEBIT_CREDIT:
      return journalEntryLine.creditOrDebit;
    case SyncHistoryRecord_CSV_HEADER.JOURNAL_ENTRY_LINE_TAGS:
      return (journalEntryLine.tags as unknown as Tag[]).map((t) => t.entry.key).join(':');
    case SyncHistoryRecord_CSV_HEADER.JOURNAL_ENTRY_MEMO:
      return je.memo;
    case SyncHistoryRecord_CSV_HEADER.JOURNAL_ENTRY_SEQUENCE_ID:
      return je.journalSequenceNumber;
    case SyncHistoryRecord_CSV_HEADER.JOURNAL_ENTRY_LEGAL_ENTITY:
      return (je.legalEntityId as LegalEntity).entityName;
    case SyncHistoryRecord_CSV_HEADER.OPERATIONAL_TRANSACTION:
      return (je.transactionId as unknown as Transaction).sequenceNumber;
    case SyncHistoryRecord_CSV_HEADER.OPERATIONAL_TRANSACTION_HASH:
      return (je.transactionId as unknown as Transaction).transactionHash;
    case SyncHistoryRecord_CSV_HEADER.TEMPLATE_NAME:
      return (je.journalEntryTemplateId as JournalEntryTemplate)?.name ?? '';
    case SyncHistoryRecord_CSV_HEADER.ACCOUNTING_PERIOD:
      return (je.accountingPeriodId as AccountingPeriod).accountingPeriodName;
    case SyncHistoryRecord_CSV_HEADER.ACCOUNTING_DATE:
      return journalEntryLine.updatedAt;
    case SyncHistoryRecord_CSV_HEADER.TOTAL_DEBIT_AMOUNT:
      return journalEntryLine.creditOrDebit === CREDIT_OR_DEBIT.DEBIT
        ? formatDollars((je.transactionId as unknown as Transaction).netAmount.$numberDecimal)
        : '';
    case SyncHistoryRecord_CSV_HEADER.TOTAL_CREDIT_AMOUNT:
      return journalEntryLine.creditOrDebit === CREDIT_OR_DEBIT.CREDIT
        ? formatDollars((je.transactionId as unknown as Transaction).netAmount.$numberDecimal)
        : '';
    case SyncHistoryRecord_CSV_HEADER.JOURNAL_ENTRY_LINE_MEMO:
      return journalEntryLine.memo;
    case SyncHistoryRecord_CSV_HEADER.JOURNAL_ENTRY_TAGS:
      return (je.tags as unknown as Tag[]).map((t) => `${t.entry.key}:${t.entry.value}`).join('_');

    default:
      return '';
  }
};

const prepareCsvRowsFromSyncHistoryRecord = (record: JournalEntrySyncRecord) =>
  ((record.journalEntryId as JournalEntryDB).journalEntryLineIds as JournalEntryLineDB[]).map((line) =>
    Object.values(SyncHistoryRecord_CSV_HEADER).map((column) =>
      getCsvColumnValueFromSyncHistoryRecord(line._id, record, column),
    ),
  );

const prepareCsvRowsFromAllSyncHistoryRecords = (allSyncHistoryRecords: JournalEntrySyncRecord[]) =>
  allSyncHistoryRecords.reduce(
    (data, record) => {
      const rows = prepareCsvRowsFromSyncHistoryRecord(record).filter(Boolean) as string[][];
      // console.log({ rows, je });
      const result = [...data, ...rows];
      return result;
    },
    [Object.values(SyncHistoryRecord_CSV_HEADER) as string[]],
  );

const getAllEntriesForSyncHistoryRecordQueryFn = async (filters) => {
  const response = await getAllEntriesForSyncHistoryOperationRecord(filters);
  return response.data.entries as JournalEntrySyncRecord[];
};

const useGetAllSyncHistoryRecords = (params) => {
  return useGetAll<JournalEntrySyncRecord>(getAllEntriesForSyncHistoryRecordQueryFn, { ...params }, 500);
};

export const useCsvExportForQBSyncHistory = (params: { syncHistoryId: string }) => {
  const getAllSyncHistoryRecords = useGetAllSyncHistoryRecords(params);

  const prepareCallback = async () => {
    const allSyncHistoryRecords = await getAllSyncHistoryRecords();
    const data = prepareCsvRowsFromAllSyncHistoryRecords(allSyncHistoryRecords);
    return data;
  };

  const { prepare, csvData } = useCsvExport(prepareCallback);
  return { prepare, csvData };
};
