import axios from 'axios';
import { SERVER_URL_ANALYTICS } from '../../config';
import { prepareSearchParams } from '../core/utils';

type TransactionVolumeItem = {
  Date: string;
  grossPrice: number;
  netPrice: number;
  fee: number;
};

type TransactionVolumeResponse = {
  data: TransactionVolumeItem[];
};

export const getTransactionsVolume = async (data) => {
  const queryParams = prepareSearchParams(data, { isPaginated: false });

  return axios.get<TransactionVolumeResponse>(`${SERVER_URL_ANALYTICS}/get-transactions-volume?${queryParams}`);
};
