import React, { useState } from 'react';
import { InputWithExtras } from 'ui';
import { MdSearch } from 'react-icons/md';
import ConditionItemsWrapper from '../ConditionItemsWrapper';
import { SearchFilterProps } from './types';

export const SearchFilter = ({ data, renderItem, type = '' }: SearchFilterProps) => {
  const [filter, setFilter] = useState(data);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value.toLowerCase();
    let filteredData: Array<string | Record<string, any>> = [];

    if (type === 'raincards') {
      filteredData = data.filter((item) => item?.name?.toLowerCase().includes(inputValue));
    } else if (type === 'legalEntity') {
      filteredData = data.filter((item) => item?.entityName?.toLowerCase().includes(inputValue));
    } else {
      filteredData = data.filter((item) => item?.toLowerCase().includes(inputValue));
    }

    setFilter(filteredData);
  };

  return (
    <>
      <div className='my-2 absolute'>
        <InputWithExtras
          onChange={handleInputChange}
          type='text'
          placeholder='Search...'
          leading={<MdSearch className='w-5 h-5' />}
        />
      </div>
      <ConditionItemsWrapper className='mt-[70px]'>{filter.map((item) => renderItem(item))}</ConditionItemsWrapper>
    </>
  );
};

export default SearchFilter;
