import * as React from 'react';

import * as SelectPrimitive from '@radix-ui/react-select';
import { MdCheck, MdOutlineUnfoldMore } from 'react-icons/md';
import { IoChevronUpSharp, IoChevronDownSharp } from 'react-icons/io5';

import { classNames } from '../utils';

const Select = SelectPrimitive.Root;

const SelectGroup = SelectPrimitive.Group;

const SelectValue = SelectPrimitive.Value;

const SelectTrigger = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger> & {
    trailingIconClassName?: string;
  }
>(({ trailingIconClassName = '', className, children, ...props }, ref) => (
  <SelectPrimitive.Trigger
    ref={ref}
    className={classNames(
      'shadow-[0px_2px_4px_0px_rgba(0,_0,_0,_0.05)] font-normal',
      'flex h-9 w-full items-center justify-between whitespace-nowrap border border-zinc-40 rounded-lg bg-transparent px-3 py-2 text-zinc-900 text-base',
      'placeholder:text-zinc-900 focus:outline-none focus:ring-1 focus:ring-ring disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1',
      className,
    )}
    {...props}
  >
    {children}
    <SelectPrimitive.Icon asChild>
      <MdOutlineUnfoldMore className={classNames('h-6 w-6 p-[2px] opacity-50', trailingIconClassName)} />
    </SelectPrimitive.Icon>
  </SelectPrimitive.Trigger>
));
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;

const SelectScrollUpButton = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.ScrollUpButton>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollUpButton>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.ScrollUpButton
    ref={ref}
    className={classNames('flex cursor-default items-center justify-center py-1', className)}
    {...props}
  >
    <IoChevronUpSharp />
  </SelectPrimitive.ScrollUpButton>
));
SelectScrollUpButton.displayName = SelectPrimitive.ScrollUpButton.displayName;

const SelectScrollDownButton = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.ScrollDownButton>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollDownButton>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.ScrollDownButton
    ref={ref}
    className={classNames('flex cursor-default items-center justify-center py-1', className)}
    {...props}
  >
    <IoChevronDownSharp />
  </SelectPrimitive.ScrollDownButton>
));
SelectScrollDownButton.displayName = SelectPrimitive.ScrollDownButton.displayName;

const MARGIN_BOTTOM = 24;

const SelectContent = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content> & {
    triggerRef?: React.RefObject<HTMLButtonElement>;
  }
>(({ className, children, triggerRef, position = 'popper', ...props }, ref) => {
  const [maxHeight, setMaxHeight] = React.useState(0);
  React.useEffect(() => {
    const triggerButton = triggerRef?.current;
    const calculateCollisionBoundary = () => {
      if (triggerButton) {
        const viewportHeight = window.innerHeight;
        const dropdownBottom = triggerButton.getBoundingClientRect().bottom;
        const availableSpace = viewportHeight - dropdownBottom;

        setMaxHeight(availableSpace - MARGIN_BOTTOM);
      }
    };

    triggerButton?.addEventListener('click', calculateCollisionBoundary);

    // Initial calculation
    calculateCollisionBoundary();

    return () => {
      triggerButton?.removeEventListener('click', calculateCollisionBoundary);
    };
  }, []);
  return (
    <SelectPrimitive.Portal>
      <SelectPrimitive.Content
        ref={ref}
        className={classNames(
          ' rounded border-none bg-white shadow-[0px_10px_10px_-5px_rgba(0,_0,_0,_0.04),_0px_20px_25px_-5px_rgba(0,_0,_0,_0.10)]',
          'relative z-normal min-w-[16rem] overflow-hidden bg-white data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
          position === 'popper' &&
            'data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1',
          className,
        )}
        position={position}
        style={maxHeight > 0 && triggerRef ? { maxHeight } : {}}
        {...props}
      >
        <SelectScrollUpButton />
        <SelectPrimitive.Viewport
          className={classNames(
            'p-1',
            position === 'popper' &&
              'h-[var(--radix-select-trigger-height)] space-y-1 w-full min-w-[var(--radix-select-trigger-width)]',
          )}
        >
          {children}
        </SelectPrimitive.Viewport>
        <SelectScrollDownButton />
      </SelectPrimitive.Content>
    </SelectPrimitive.Portal>
  );
});
SelectContent.displayName = SelectPrimitive.Content.displayName;

const SelectLabel = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Label>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Label>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Label ref={ref} className={classNames('text-base text-zinc-900 ', className)} {...props} />
));
SelectLabel.displayName = SelectPrimitive.Label.displayName;

const SelectItem = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item>
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Item
    ref={ref}
    className={classNames(
      'hover:bg-indigo-100 hover:cursor-pointer data-[state=checked]:bg-indigo-100',
      'relative flex w-full cursor-default select-none items-center rounded-md pl-2 py-1.5  pr-8 outline-none focus:bg-zinc-100 focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
      className,
    )}
    {...props}
  >
    <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
  </SelectPrimitive.Item>
));
SelectItem.displayName = SelectPrimitive.Item.displayName;

const SelectSeparator = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Separator ref={ref} className={classNames('-mx-1 my-1 h-px bg-zinc-200', className)} {...props} />
));
SelectSeparator.displayName = SelectPrimitive.Separator.displayName;

export {
  Select,
  SelectGroup,
  SelectValue,
  SelectTrigger,
  SelectContent,
  SelectLabel,
  SelectItem,
  SelectSeparator,
  SelectScrollUpButton,
  SelectScrollDownButton,
};
