import { useEffect, useState } from 'react';
import { AssetGainLossChartProps } from './types';
import { AreaChart, Card, Text } from '@tremor/react';
import { PricePoint } from 'services/http/analytics/assets';
import { formatDate, formatDollars } from '../../../../apps/next/src/components/templates/utils';
import { format } from 'date-fns';

const sliceData = (data: PricePoint[]) => {
  let newData = data.map((item) => {
    return {
      Price: parseFloat(item.price),
      Date: format(new Date(item.timestamp), 'MM/dd/yyyy hh:mm'),
    };
  });

  const processedData = newData.filter((item: any) => item.Value !== 0);

  return processedData.sort((a, b) => (new Date(a.Date) < new Date(b.Date) ? -1 : 1));
};

export const AssetGainLossChart: React.FC<AssetGainLossChartProps> = ({ isLoading = false, ...props }) => {
  const [slicedData, setSlicedData] = useState<any[]>([]);
  const [yAxisWidth, setYAxisWidth] = useState(80);
  const [maxValue, setMaxValue] = useState(0);

  useEffect(() => {
    const values = slicedData.map((item) => item.Price);
    const max = Math.max(...values);
    setMaxValue(max);
    setYAxisWidth(10 + max.toFixed(2).toString().length * 8);
  }, [slicedData]);

  useEffect(() => {
    setSlicedData(sliceData(props.data));
  }, [props.data]);

  return (
    <div className='px-6 py-6'>
      <Card>
        {isLoading ? (
          <div className='h-[300px] animate-pulse bg-gray-50' />
        ) : (
          <div>
            <AreaChart
              {...props}
              categories={['Price']}
              index={'Date'}
              showLegend={false}
              className='h-[300px]'
              data={slicedData}
              yAxisWidth={yAxisWidth}
              maxValue={maxValue}
              valueFormatter={(value) => formatDollars(value)}
              autoMinValue={true}
              showXAxis={false}
            />
            <div className='flex justify-between pl-[80px]'>
              <Text>{formatDate(new Date(slicedData[0]?.Date))}</Text>
              <Text>{formatDate(new Date(slicedData[slicedData.length - 1]?.Date))}</Text>
            </div>
          </div>
        )}
      </Card>
    </div>
  );
};
