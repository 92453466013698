import { useContext, useMemo, useState } from 'react';
import { Button, InputLabel, InputWithExtras, SelectableCard, SimpleMenu } from 'ui';
import { DetailsItem, LoadingSpinner, TagsInput } from '../../atoms';
import { useAllLegalEntities, useCreateImportTransactionsBgJob, useCreateSource, useSession } from '../../../hooks';
import { getHost } from '../../../lib/utils';
import { useRouter } from 'next/router';
import { useCommandPallete } from '../useCommandPallete';
import { useKBar } from 'farish-kbar';
import { SUPPORTED_CHAIN, WALLET_TYPE_OPTIONS } from '../../Sources';
import { getDisplayedLegalEntities } from '../../templates/utils';
import { toast } from 'react-hot-toast';
import { JobProgressContext } from '../../../context/JobProgressProvider';
import { JOB_NAME } from 'services';
import { DetailsCard } from '../DetailsCard';
import { CreateSourceFormState } from '../../form-elements';
import { WALLET_CHAIN, WALLET_TYPE } from 'services/http/response.types';
import { MdEdit } from 'react-icons/md';
import { ResponseContainer } from '../ResponseContainer';

enum STEPS {
  WALLET_TYPE = 1,
  WALLET_CHAIN = 2,
  WALLET_ADDRESS = 3,
  WALLET_LEGAL_ENTITY = 4,
  TRANSACTION_IMPORT = 5,
  CONFIRMATION = 6,
}

export const WalletForm: React.FC<{ initialFormValues: any; index: number }> = ({
  initialFormValues = {},
  index = 0,
}) => {
  const [walletForm, setWalletForm] = useState<CreateSourceFormState>(initialFormValues);
  const [step, setStep] = useState(STEPS.WALLET_TYPE);
  const [isEditing, setIsEditing] = useState(false);

  const pastThreeMonthData = useMemo(() => {
    const date = new Date();
    date.setMonth(date.getMonth() - 3);
    date.setDate(1);

    const data = {
      value: 'Past three months to date',
      date,
    };
    return data;
  }, []);

  const currentYearFirstDate = useMemo(() => {
    const date = new Date(`January 1, ${new Date().getFullYear()}`);
    const data = {
      value: 'Year to date',
      date,
    };
    return data;
  }, []);

  const { createJob } = useCreateImportTransactionsBgJob();
  const { showJobInTaskManger } = useContext(JobProgressContext);
  const { mutateAsync: createSource, isLoading: isCreatingSource, data } = useCreateSource();
  const { legalEntities, isLoading: isLoadingEntities } = useAllLegalEntities();
  const LEGAL_ENTITY_SELECT_OPTIONS = useMemo(() => getDisplayedLegalEntities(legalEntities), [legalEntities]);

  const router = useRouter();
  const { setGptChatResponses } = useCommandPallete();
  const { query } = useKBar();
  const { organizationId, userId } = useSession();

  switch (step) {
    case STEPS.WALLET_TYPE: {
      return (
        <ResponseContainer>
          Please select wallet type
          <div className='grid grid-cols-3 gap-2 mt-2'>
            {WALLET_TYPE_OPTIONS.map((type) => {
              return (
                <SelectableCard
                  key={type.label}
                  label={type.label}
                  onClick={() => {
                    const typeValue = type.value as WALLET_TYPE;
                    setWalletForm((prev) => ({ ...prev, walletType: typeValue }));
                    setStep(STEPS.WALLET_CHAIN);
                  }}
                />
              );
            })}
          </div>
        </ResponseContainer>
      );
    }
    case STEPS.WALLET_CHAIN: {
      if (initialFormValues.entityName) setStep(STEPS.WALLET_ADDRESS);

      return (
        <ResponseContainer className='h-40'>
          Please select a chain
          <div className='mt-2'>
            <SimpleMenu
              options={SUPPORTED_CHAIN}
              onChange={(option) => {
                setWalletForm((prev) => ({ ...prev, chain: option.value }));
                setStep(STEPS.WALLET_ADDRESS);
              }}
              selectedValue={walletForm.chain}
              data-cy='createSourcesFE_walletChainMenu'
            />
          </div>
        </ResponseContainer>
      );
    }
    case STEPS.WALLET_ADDRESS: {
      if (initialFormValues.entityType) setStep(STEPS.WALLET_LEGAL_ENTITY);

      return (
        <ResponseContainer>
          Wallet address is missing
          <div className='mt-2'>
            {walletForm.chain && Object.values(WALLET_CHAIN).includes(walletForm.chain) && (
              <div>
                <InputWithExtras
                  value={walletForm.address}
                  onChange={(e) => {
                    setWalletForm((prev) => ({ ...prev, address: e.target.value }));
                  }}
                  onEnter={() => setStep(STEPS.WALLET_LEGAL_ENTITY)}
                  placeholder='Enter wallet address'
                  data-cy='createSourcesFE_walletAddressInput'
                />{' '}
                <div className='mt-4 flex justify-end'>
                  <Button
                    label='Confirm'
                    onClick={() => setStep(STEPS.WALLET_LEGAL_ENTITY)}
                    disabled={!walletForm.address}
                  />
                </div>
              </div>
            )}

            {walletForm.chain === 'btc' && (
              <div className='flex flex-col'>
                <TagsInput
                  tags={walletForm.addresses}
                  onEnter={(addressesString) => {
                    const addressesArray = addressesString.split(',').map((str) => str.trim());
                    setWalletForm((prev) => ({
                      ...prev,
                      addresses: prev?.addresses ? [...prev.addresses, ...addressesArray] : addressesArray,
                    }));
                  }}
                  onRemove={(removedAddress) =>
                    setWalletForm((prev) => ({
                      ...prev,
                      addresses: prev.addresses.filter((address) => address !== removedAddress),
                    }))
                  }
                />
                <Button
                  className='self-end mt-2'
                  disabled={!walletForm.addresses || walletForm.addresses.length === 0}
                  onClick={() => setStep(STEPS.WALLET_LEGAL_ENTITY)}
                  label='Next'
                />
              </div>
            )}
          </div>
        </ResponseContainer>
      );
    }
    case STEPS.WALLET_LEGAL_ENTITY: {
      if (initialFormValues.addressString) setStep(STEPS.CONFIRMATION);

      return (
        <ResponseContainer className='h-40'>
          Please select a legal entity for wallet {walletForm.address ? walletForm.address : ''}
          <div className='mt-2'>
            <SimpleMenu
              options={LEGAL_ENTITY_SELECT_OPTIONS}
              onChange={(option) => {
                setWalletForm((prev) => ({ ...prev, legalEntityId: option.value }));
                setStep(STEPS.TRANSACTION_IMPORT);
              }}
              selectedValue={walletForm.legalEntityId}
              disabled={walletForm.walletType === 'external'}
              placeholder='Assign legal entity'
              isLoading={isLoadingEntities}
              data-cy='createSourcesFE_walletLegalEntityMenu'
            />
          </div>
        </ResponseContainer>
      );
    }
    case STEPS.TRANSACTION_IMPORT: {
      if (walletForm.walletType === 'external') setStep(STEPS.CONFIRMATION);

      return (
        <ResponseContainer>
          Pull transaction from past?
          <div className='mt-2 grid grid-cols-2 gap-4'>
            <SelectableCard
              label={'3 months to date'}
              selected={walletForm.import === pastThreeMonthData.value}
              onClick={() => {
                setWalletForm((prev) => ({
                  ...prev,
                  import: pastThreeMonthData.value,
                  importStartDate: pastThreeMonthData.date,
                }));
                setStep(STEPS.CONFIRMATION);
              }}
              data-cy='createSourceFE_past3MonthsTransactionSC'
            />
            <SelectableCard
              label={'Year to date'}
              selected={walletForm.import === currentYearFirstDate.value}
              onClick={() => {
                setWalletForm((prev) => ({
                  ...prev,
                  import: currentYearFirstDate.value,
                  importStartDate: currentYearFirstDate.date,
                }));
                setStep(STEPS.CONFIRMATION);
              }}
              data-cy='createSourceFE_pastYearTransactionSC'
            />
          </div>
        </ResponseContainer>
      );
    }
    case STEPS.CONFIRMATION: {
      if (isCreatingSource) {
        return (
          <div className='text-center'>
            <LoadingSpinner />
          </div>
        );
      }

      if (data) {
        const { walletType, _id, address, chain, name } = data.data.wallet;

        return (
          <ResponseContainer>
            <DetailsCard
              number={index + 1}
              title={name || address}
              subtitle={`${chain} - ${walletType}`}
              onClick={() => {
                router.push(`${getHost()}/ledger/sources/${_id}`);
                setGptChatResponses([]);
                query.toggle();
              }}
            />
          </ResponseContainer>
        );
      }

      if (isEditing) {
        return (
          <ResponseContainer className='flex gap-y-2 flex-col'>
            <div>
              <InputLabel heading='Wallet name (alias)' />
              <InputWithExtras
                value={walletForm.name}
                onChange={({ target }) => {
                  setWalletForm((prev) => ({ ...prev, name: target.value }));
                }}
                className='mt-2'
                placeholder='Enter wallet name'
              />
            </div>
            <div>
              <InputLabel heading='Wallet type' />
              <div className='grid grid-cols-3 gap-2 mt-2'>
                {WALLET_TYPE_OPTIONS.map((type) => {
                  return (
                    <SelectableCard
                      key={type.label}
                      label={type.label}
                      onClick={() => {
                        const typeValue = type.value as WALLET_TYPE;
                        setWalletForm((prev) => ({ ...prev, walletType: typeValue }));
                      }}
                      selected={walletForm.walletType === type.value}
                    />
                  );
                })}
              </div>
            </div>
            <div>
              <InputLabel heading='Wallet chain' />
              <div className='grid grid-cols-3 gap-2 mt-2'>
                <SimpleMenu
                  options={SUPPORTED_CHAIN}
                  onChange={(option) => {
                    setWalletForm((prev) => ({ ...prev, chain: option.value }));
                  }}
                  selectedValue={walletForm.chain}
                />
              </div>
            </div>
            <div>
              <InputLabel heading='Wallet address' />
              <InputWithExtras
                value={walletForm.address}
                onChange={({ target }) => {
                  setWalletForm((prev) => ({ ...prev, address: target.value }));
                }}
                className='mt-2'
                placeholder='Enter wallet address'
              />
            </div>
            <div>
              <InputLabel heading='Legal entity' />
              <div className='grid grid-cols-3 gap-2 mt-2'>
                <SimpleMenu
                  options={LEGAL_ENTITY_SELECT_OPTIONS}
                  onChange={(option) => {
                    setWalletForm((prev) => ({ ...prev, legalEntityId: option.value }));
                  }}
                  selectedValue={walletForm.legalEntityId}
                  disabled={walletForm.walletType === 'external'}
                  placeholder='Assign legal entity'
                  isLoading={isLoadingEntities}
                />
              </div>
            </div>
            <div className='mt-4 flex justify-end'>
              <Button label='Confirm' onClick={() => setIsEditing(false)} />
            </div>
          </ResponseContainer>
        );
      }

      return (
        <ResponseContainer>
          {walletForm.name && <DetailsItem value={walletForm.name} tag='Wallet name' />}
          <DetailsItem itemId='' value={walletForm.walletType} tag='Wallet type' />
          <DetailsItem value={walletForm.chain} tag='Wallet chain' />
          <DetailsItem value={walletForm.address} tag='Wallet address' />
          <DetailsItem
            value={
              legalEntities.find((le) => le._id === walletForm.legalEntityId)?.entityName ||
              walletForm.legalEntityId ||
              ''
            }
            tag='Legal entity'
          />
          <div className='flex justify-end gap-x-2'>
            <Button
              label='Edit'
              leadingIcon={<MdEdit size={20} />}
              onClick={() => setIsEditing(true)}
              emphasis='medium'
            />
            <Button
              label='Confirm'
              onClick={async () => {
                const sourceResponse = await createSource({
                  wallet: { ...walletForm, organizationId, userId, legalEntityId: walletForm.legalEntityId as string },
                });
                if (!sourceResponse?.data?.wallet?._id) return toast.error('Could not create source');
                await createJob(sourceResponse.data.wallet, walletForm.importStartDate || currentYearFirstDate.date);
                showJobInTaskManger(sourceResponse.data.wallet._id, JOB_NAME.IMPORT_TRANSACTION_JOB);
              }}
            />
          </div>
        </ResponseContainer>
      );
    }
  }
};
