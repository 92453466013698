import React from 'react';
import ConditionItem from '../ConditionItem';
import { setSaveData } from '../../../rules/utils';
import SearchFilter from './SearchFilter';
import { ConditionMenusProps } from './types';
import { HEDGEY_EVENTS } from './constants';

export const HedgeyCondition = ({ ruleSetData, getRuleSetData, isFirstLevel }: ConditionMenusProps) => {
  return (
    <ConditionItem label='Hedgey'>
      <SearchFilter
        type='hedgey'
        data={HEDGEY_EVENTS}
        renderItem={(item) => (
          <ConditionItem
            onClick={() => {
              setSaveData('Hedgey Event', item.value, isFirstLevel, ruleSetData, getRuleSetData);
            }}
            key={item.label}
            label={item.label}
          />
        )}
      />
    </ConditionItem>
  );
};

export default HedgeyCondition;
