import React, { useContext, useEffect, useState } from 'react';
import { DescriptiveLoader, Dialog, DialogContent } from 'ui';
import { ORGANIZATION_RULESET_JOB_STATUS, getRunAllRulesetsProcessStateDescriptorProps } from '../../process-states';
import { JOB_NAME } from 'services/http';
import { toast } from 'react-hot-toast';
import TrackJobInDialog from '../../job-progress/TrackJobInDialog';
import { deriveError } from '../../templates/utils';
import { JobProgressContext } from '../../../context/JobProgressProvider';
import { useTriggerOrganizationRulesetJobWithTracking } from './use-trigger-organization-ruleset-jobs-with-tracking';
import { RunAllRulesetsDialogForm } from '../RunAllRulesetsDialogForm';
import {
  useGetOrganizationRulesetConfig,
  useInvalidateQuery,
  useSession,
  useUpsertOrganizationRulesetConfig,
} from '../../../hooks';
import { SetJobCadence } from './set-job-cadence';
import { CREATE_MODE, CreateModeTabs } from './create-mode-tabs';
import { ToggleJobAutorun } from './toggle-job-autorun';

export const ManageAllRulesetRunsModal = ({ open, onOpenChange }) => {
  const { organizationId } = useSession();
  const { onTrackerClose } = useContext(JobProgressContext);
  const [error, setError] = useState<string>();
  const [backgroundJobState, setBackgroundJobState] = useState<ORGANIZATION_RULESET_JOB_STATUS>(
    ORGANIZATION_RULESET_JOB_STATUS.FORM,
  );

  const {
    createJobs,
    bgJobState: runAllRulesetBgJobState,
    setBgJobState: setRunAllRulesetBgJobState,
  } = useTriggerOrganizationRulesetJobWithTracking();
  const onClose = () => {
    onTrackerClose(runAllRulesetBgJobState, JOB_NAME.ORGANIZATION_RULESET_JOB, organizationId);
    onOpenChange(false);
  };
  useEffect(() => {
    if (!open) {
      setBackgroundJobState(ORGANIZATION_RULESET_JOB_STATUS.FORM);
    }
  }, [open]);

  const onSubmit = async (params) => {
    try {
      setBackgroundJobState(ORGANIZATION_RULESET_JOB_STATUS.PROCESSING);
      await createJobs(params);
    } catch (e) {
      console.log('CreateSyncBgJob error:', e);
      setError(deriveError(e));
      toast.error(deriveError(e));
    }
  };
  const { data: instance, isLoading } = useGetOrganizationRulesetConfig();
  const { mutateAsync: updateConfig, isLoading: isUpdating } = useUpsertOrganizationRulesetConfig();

  const { invalidateAccountPostingRulesetConfigs } = useInvalidateQuery();

  const onCronExpressionUpdate = async (cronExpression) => {
    try {
      await updateConfig({ organizationId, cronExpression });
      onOpenChange(false);
      toast.success('Settings saved');
      await invalidateAccountPostingRulesetConfigs();
    } catch (error) {
      toast.error(deriveError(error));
    }
  };

  const [createMode, setCreateMode] = useState<CREATE_MODE>(CREATE_MODE.MANUAL);

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      {open && backgroundJobState === ORGANIZATION_RULESET_JOB_STATUS.FORM ? (
        <DialogContent title='Schedule ruleset runs' onClose={onClose}>
          <ToggleJobAutorun
            jobConfigurationId={instance?._id}
            isPaused={instance?.isPaused}
            cronExpression={instance?.cronExpression}
          />
          <CreateModeTabs value={createMode} onChange={setCreateMode} />
          {createMode === CREATE_MODE.AUTOMATIC && (
            <SetJobCadence
              onSubmit={onCronExpressionUpdate}
              onCancel={() => onOpenChange(false)}
              isSubmitting={isUpdating}
              isLoading={isLoading}
              disabled={isLoading}
              defaultCronExpression={instance?.cronExpression}
            />
          )}
          {createMode === CREATE_MODE.MANUAL && (
            <RunAllRulesetsDialogForm
              onClose={() => onOpenChange(false)}
              organizationId={organizationId}
              onSubmit={onSubmit}
            />
          )}
        </DialogContent>
      ) : backgroundJobState === ORGANIZATION_RULESET_JOB_STATUS.PROCESSING ? (
        <TrackJobInDialog
          jobName={JOB_NAME.ORGANIZATION_RULESET_JOB}
          referenceId={organizationId}
          onClose={onClose}
          bgJobState={runAllRulesetBgJobState}
          setBgJobState={setRunAllRulesetBgJobState}
          error={error}
        />
      ) : (
        <DialogContent onClose={onClose}>
          <div className='h-[200px] w-full flex items-center justify-center'>
            <DescriptiveLoader {...getRunAllRulesetsProcessStateDescriptorProps(backgroundJobState, error)} />
          </div>
        </DialogContent>
      )}
    </Dialog>
  );
};
