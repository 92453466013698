import { useEffect, useState } from 'react';
import { convertAssets, formatDollars, mergeAssets, mergeLegalEntities } from '../../templates/utils';
import { CryptoIcon, dateConverter } from '../../utils';
import { AssetWithExtraData } from 'services/http/response.types';
import { DisplayedAsset } from './type';
import { AllQueryFiltersType, FILTER_TYPE, useFilters } from '../../filters';
import { ASSETS_PAGE_FITLERS, IMPAIRED_FILTER_ROWS } from '../../../constants';
import { useAssetTypes, useDateSelections, useLegalEntities, useMemoizedQuery } from '../../../hooks';
import { AssetBreakdownData } from 'services/http/analytics/assets';
import { getAssets } from 'services/http';
import toast from 'react-hot-toast';

export const useDisplayedAssets = (assetIQData?: { pages: { assets: AssetWithExtraData[] }[] }) => {
  const [displayedAssets, setDisplayedAssets] = useState<DisplayedAsset[]>([]);
  useEffect(() => {
    if (assetIQData)
      setDisplayedAssets(
        mergeAssets(assetIQData).map((asset: AssetWithExtraData) => {
          const assetType = {
            title: asset.assetType,
            desc: dateConverter(asset.dateReceived),
            chain: asset.assetType,
          };
          const costBasis = {
            title: formatDollars(asset.costBasis),
            desc: `${formatDollars(asset.pricePaid)} total`,
          };
          return {
            ...asset,
            assetType,
            costBasis,
            currentValue_gainLossPercentage: {
              currentValue: asset.currentValue,
              gainLossPercentage: asset.gainLossPercentage,
            },
          };
        }),
      );
  }, [assetIQData]);
  return displayedAssets;
};

export const useAssetsPageFilters = (query: Partial<AllQueryFiltersType> = {}) => {
  const memoizedQuery = useMemoizedQuery(query);
  const { state, helpers } = useFilters(ASSETS_PAGE_FITLERS);

  useEffect(() => {
    if (memoizedQuery?.startDate?.length && memoizedQuery?.endDate?.length) {
      const updatedDatePickerSelections = [
        {
          key: 'selection',
          startDate: new Date(memoizedQuery.startDate),
          endDate: new Date(memoizedQuery.endDate),
        },
      ];

      setDatePickerSelections([...updatedDatePickerSelections]);
    }
  }, []);

  const {
    dateSelections: datePickerSelections,
    setDateSelections: setDatePickerSelections,
    datesWithTzOffset,
  } = useDateSelections();

  useEffect(() => {
    helpers[FILTER_TYPE.ACCOUNTING_PERIOD]?.deselectAll();
  }, [datePickerSelections, memoizedQuery]);

  const { data: legalEntitiesData, isLoading: isLoadingLegalEntities } = useLegalEntities();

  useEffect(() => {
    helpers[FILTER_TYPE.LEGAL_ENTITY]?.setIsLoading(isLoadingLegalEntities);
    if (!isLoadingLegalEntities && legalEntitiesData)
      helpers[FILTER_TYPE.LEGAL_ENTITY]?.setAll(
        mergeLegalEntities(legalEntitiesData).map((le) => ({
          ...le,
          selected: memoizedQuery?.legalEntityIds?.includes(le._id) ?? false,
        })),
      );
  }, [legalEntitiesData, isLoadingLegalEntities, memoizedQuery]);

  const { assetTypes, isLoading: isLoadingAssetTypes } = useAssetTypes();
  useEffect(() => {
    helpers[FILTER_TYPE.ASSET]?.setIsLoading(isLoadingAssetTypes);
    if (!isLoadingAssetTypes && assetTypes)
      helpers[FILTER_TYPE.ASSET]?.setAll(
        assetTypes.map((value) => ({
          value,
          _id: value,
          selected: memoizedQuery?.assetTypes?.includes(value) ?? false,
        })),
      );
  }, [assetTypes, isLoadingAssetTypes, memoizedQuery]);

  useEffect(() => {
    helpers[FILTER_TYPE.IMPAIRED]?.setAll(
      IMPAIRED_FILTER_ROWS.map((value) => ({
        value,
        _id: value,
        selected: memoizedQuery?.impaired?.includes(value.toLowerCase()) ?? false,
      })),
    );
  }, [memoizedQuery]);

  return { state, helpers, datesWithTzOffset, datePickerSelections, setDatePickerSelections };
};

export const processAssetBreakdownData = (assetBreakdownData?: AssetBreakdownData) => {
  const assetBreakdown = assetBreakdownData?.data;
  const assetBreakdownArray = Object.values(assetBreakdown || {});
  const processedData: Record<string, any[]> = {
    Quantities: [],
    Values: [],
  };
  if (!assetBreakdownArray) return { processedData, availableAssets: [] };

  const availableAssets = assetBreakdownArray.map((asset) => asset.assetType);
  assetBreakdownArray.forEach((asset) => {
    processedData.Quantities.push({
      name: asset.assetType,
      value: parseFloat(asset.quantity),
      icon: () => <CryptoIcon symbol={asset.assetType} size='sm' className='mr-3 currencyIcon' />,
    });
    processedData.Values.push({
      name: asset.assetType,
      value: parseFloat(asset.value),
      icon: () => <CryptoIcon symbol={asset.assetType} size='sm' className='mr-3 currencyIcon' />,
    });
  });

  return {
    processedData,
    availableAssets,
  };
};

export const handleDownloadAssets = async ({
  setPages,
  assetsLoading,
  setDownloadLoading,
  organizationId,
  sortState,
  assetsCSVParams,
}) => {
  if (assetsLoading) {
    return;
  }
  const toastId = toast.loading('Please wait your CSV will be ready shortly.');
  setDownloadLoading(true);
  setPages([]);
  let allAssets: any[] = [];
  const perQuery = 3000;
  let pastLength = 0;
  let count = 0;
  do {
    const response = await getAssets({
      organizationId,
      page: count++,
      sort: sortState[0],
      organizationIds: [organizationId],
      pageSize: perQuery,
      ...assetsCSVParams,
    });
    const newAssets = response.data?.assets;
    pastLength = allAssets.length;
    allAssets = [...allAssets, ...newAssets];
  } while (pastLength !== allAssets.length);

  setPages(convertAssets(allAssets));
  setDownloadLoading(false);
  toast.success('Download complete', { id: toastId });
};
