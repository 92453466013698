import { useGetImpairmentRuleById } from '../../../hooks/http/useImpairmentRules';
import { ManageImpairmentRuleSidebarContent } from '../../impairment-rules';
import { changeRouteName } from '../../templates/utils';
import { SidebarProps } from '../SidebarGlobal';

const ImpairmentSidebar: React.FC<SidebarProps> = ({
  id,
  onBack,
  isDockPanelAvailable,
  handlePrimaryPanelClose,
  isPrimary,
}) => {
  const { data: impairmentRule, isLoading } = useGetImpairmentRuleById(id);
  changeRouteName({ name: impairmentRule?.name, UUID: id, isLoading });

  return (
    <ManageImpairmentRuleSidebarContent
      onBack={onBack}
      isDockPanelAvailable={isDockPanelAvailable}
      onClose={() => handlePrimaryPanelClose && handlePrimaryPanelClose()}
      impairmentRuleId={id}
      isPrimary={isPrimary}
    />
  );
};

export default ImpairmentSidebar;
