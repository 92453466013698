import { useEffect, useState } from 'react';
import { shrink } from '../../../lib/utils';
import { convertSources, mergeLegalEntities, mergeSources } from '../../templates/utils';
import { dateConverter } from '../../utils';
import { HedgeyIcon } from '../../../assets/generated/icons';
import { DateProps } from '../../utils/types';
import { DisplayedWallet } from './type';
import { Wallet } from '../../../types';
import { AllQueryFiltersType, FILTER_TYPE, useFilters } from '../../filters';
import { CHAIN_FILTER_ROWS, SOURCES_PAGE_FILTERS, WALLET_TYPES_FILTER_ROWS } from '../../../constants';
import { useLegalEntities, useMemoizedQuery, useTags } from '../../../hooks';
import toast from 'react-hot-toast';
import { getWallets } from 'services/http';

export const useDisplayedSources = (sourcesData) => {
  const [displayedSources, setDisplayedSources] = useState<DisplayedWallet[]>();
  useEffect(() => {
    if (sourcesData)
      setDisplayedSources(
        mergeSources(sourcesData).map((source: Wallet) => {
          const address =
            source.addresses?.length > 1
              ? source.addresses?.map((adr) => shrink(adr)).reduce((acc, currentValue) => `${acc}, ${currentValue}`)
              : source.address;

          const sourceInfo = {
            title: source?.name ?? shrink(source?.address),
            desc: source?.walletType,
            chain: source.chain,
            partnerImage: source?.meta?.hedgeyEvent && <HedgeyIcon />,
          };
          const created = {
            title: source?.createdBy,
            desc: dateConverter(source.createdAt as DateProps),
          };
          return {
            ...source,
            sourceInfo,
            address,
            created,
          };
        }),
      );
  }, [sourcesData]);
  return displayedSources;
};

export const useSourcesPageFilters = (query: Partial<AllQueryFiltersType> = {}) => {
  const { state, helpers } = useFilters(SOURCES_PAGE_FILTERS);
  const memoizedQuery = useMemoizedQuery(query);
  const { data: legalEntitiesData, isLoading: isLoadingLegalEntities } = useLegalEntities();

  useEffect(() => {
    helpers[FILTER_TYPE.LEGAL_ENTITY]?.setIsLoading(isLoadingLegalEntities);
    if (legalEntitiesData && !isLoadingLegalEntities)
      helpers[FILTER_TYPE.LEGAL_ENTITY]?.setAll(
        mergeLegalEntities(legalEntitiesData).map((le) => ({
          ...le,
          selected: memoizedQuery?.legalEntityIds?.includes(le?._id) ?? false,
        })),
      );
  }, [legalEntitiesData, isLoadingLegalEntities, memoizedQuery]);

  const { data: tagPages, isLoading: isLoadingTags } = useTags();
  useEffect(() => {
    helpers[FILTER_TYPE.TAG]?.setIsLoading(isLoadingTags);
    if (!isLoadingTags && tagPages) {
      helpers[FILTER_TYPE.TAG]?.setAll(
        tagPages.pages
          .map((tags) => tags.map((tag) => ({ ...tag, selected: memoizedQuery?.tags?.includes(tag?._id) ?? false })))
          .flat(),
      );
    }
  }, [isLoadingTags, tagPages, memoizedQuery]);

  useEffect(() => {
    helpers[FILTER_TYPE.CHAIN]?.setAll(
      CHAIN_FILTER_ROWS.map((value) => ({
        value,
        _id: value,
        selected: memoizedQuery?.chains?.includes(value.toLowerCase()) ?? false,
      })),
    );
    helpers[FILTER_TYPE.ORIGINATED_BY]?.setAll(
      CHAIN_FILTER_ROWS.map((value) => ({ value, _id: value, selected: false })),
    );
    helpers[FILTER_TYPE.WALLET_TYPE]?.setAll(
      WALLET_TYPES_FILTER_ROWS.map((value) => ({
        value,
        _id: value,
        selected: memoizedQuery?.walletTypes?.includes(value.toLowerCase()) ?? false,
      })),
    );
  }, [memoizedQuery]);

  return { state, helpers };
};

export const useDisplayedTableData = ({ sourcesIQData, raincards }) => {
  const displayedWallets = useDisplayedSources(sourcesIQData);

  const displayedRaincards =
    raincards?.map((raincard) => {
      return {
        ...raincard,
        sourceInfo: {
          title: raincard.cardData.nickname,
          img: <img src='/raincards-logo.png' />,
        },
        address: raincard.cardData.last4,
        legalEntityId: raincard.legalEntityId,
        status: raincard.cardData.status,
        isRaincard: true,
        created: raincard.cardData.cardholderFirstName + ' ' + raincard.cardData.cardholderLastName,
      };
    }) || [];
  const displayedTableData = displayedWallets?.concat(displayedRaincards);
  return displayedTableData;
};

export const handleDownloadSources = async ({
  setPages,
  filterHelpers,
  sourcesIQData,
  organizationId,
  isLoading,
  debouncedSearchTerm,
}) => {
  if (!organizationId) return;
  if (isLoading) return;

  setPages([]);
  let allTxs: any = [];
  const perQuery = 3000;
  const totalTx = sourcesIQData?.pages
    .map(({ wallets }) => wallets.length)
    ?.reduce((acc, currentValue) => acc + currentValue);

  const toastId = toast.loading(`Downloading ${totalTx} sources, please wait while we prepare your CSV`);

  const totalQuery = Math.max(1, totalTx / perQuery);

  for (let i = 0; i < totalQuery; i += 1) {
    const response = await getWallets({
      page: i,
      chains: filterHelpers[FILTER_TYPE.CHAIN]?.getAllSelectedWithTransform(),
      walletTypes: filterHelpers[FILTER_TYPE.WALLET_TYPE]?.getAllSelectedWithTransform(),
      nameOrAddress: debouncedSearchTerm || undefined,
      pageSize: perQuery,
    });
    const newTexs = response.data?.wallets;
    allTxs = [...allTxs, ...newTexs];
  }

  setPages(convertSources(allTxs));

  toast.success('Download complete', { id: toastId });
};
