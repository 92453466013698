import { useGetSubdomain } from '../../../lib/utils';
import { useGetStatusForAllIntegrations } from '../../../hooks/http/useGlIntegrationAccountQueries';
import { useGLAuthConnect } from '../../../hooks/http/useGlClientConnect';
import { useAllLegalEntities, useSession } from '../../../hooks';
import { GLType } from 'services/http/types';
import Image from '../../image-with-fallback';
import { GLAuth } from 'schemas';
import { DEFAULT_INTEGRATIONS_CARD_DETAILS } from '../../../constants';

const qbIntegrationDescriptions = {
  glType: GLType.QUICKBOOKS,
  heading: 'Quickbooks',
  description: 'Connect your Quickbooks account to automatically sync your transactions and accounts.',
  icon: <Image src='/quickbooks.png' alt='Quickbooks logo' className='rounded-full border' width={40} height={40} />,
};

const xeroIntegrationDescriptions = {
  glType: GLType.XERO,
  heading: 'Xero',
  description: 'Connect your Xero account to automatically sync your transactions and accounts.',
  icon: <Image src='/xero.svg' alt='Xero logo' className='rounded-full border' width={40} height={40} />,
};

const integrationDescriptions = {
  [GLType.QUICKBOOKS]: qbIntegrationDescriptions,
  [GLType.XERO]: xeroIntegrationDescriptions,
};

export type useGetIntegrationByTypeResponse = {
  heading: string;
  description: string;
  icon: JSX.Element;
  isLive: boolean;
  onConnectUrl: string;
  isLoading: boolean;
  createdOn: string;
  lastUpdatedOn: string;
  glAuths: GLAuth[];
};

const useGetIntegrationByType = (glType: GLType): useGetIntegrationByTypeResponse => {
  if (!GLType[glType]) {
    return DEFAULT_INTEGRATIONS_CARD_DETAILS;
  }
  const { organizationId } = useSession();
  const subdomain = useGetSubdomain();

  const { data: onConnectInfo, isLoading: isLoadingOnConnectInfo } = useGLAuthConnect(
    { organizationId, subdomain: subdomain ?? '' },
    glType,
    !!organizationId,
  );

  const { data: glAuthsResponse, isLoading: isLoadingGlAuths } = useGetStatusForAllIntegrations(organizationId);

  const integrationStatus = glAuthsResponse?.glAuths?.find((info) => info.glType === glType);
  const { legalEntities } = useAllLegalEntities();
  const glAuthsByGL = glAuthsResponse?.glAuths
    ?.filter((info) => info.glType === glType)
    .map((info) => {
      return {
        ...info,
        entityName: legalEntities.find((entity) => entity._id === info?.legalEntityId)?.entityName,
      };
    });

  return {
    ...integrationDescriptions[glType],
    isLive: !!integrationStatus,
    onConnectUrl: onConnectInfo?.uri as string,
    isLoading: isLoadingOnConnectInfo || isLoadingGlAuths,
    createdOn: integrationStatus?.createdAt,
    lastUpdatedOn: integrationStatus?.updatedAt,
    glAuths: glAuthsByGL,
  };
};

export default useGetIntegrationByType;
