import queryString from 'query-string';
import { extractSessionFromLocalstorage, isSessionTokenValid, unprotectedPaths } from '../utils';
import { ExtendedAxiosRequestConfig } from './types';

export class RequestConfigFactory {
  config: ExtendedAxiosRequestConfig;
  session;
  constructor(config: ExtendedAxiosRequestConfig) {
    this.config = config;
    this.session = extractSessionFromLocalstorage();
  }

  appendAuthorizationHeader() {
    if (!this.config.excludeAuthorizationHeader) {
      if (!isSessionTokenValid(this.session.token)) {
        console.warn('Intercepted expired token: ', this.session.token);
        throw new Error('Session expired');
      }
      if (!this.config.headers) this.config.headers = {};
      this.config.headers.Authorization = this.session.token || '';
    }
    return this;
  }
  appendOrganizationId() {
    // append authorization header if needed
    if (!this.config.skipOrganizationId) {
      // try to attach organizationId if needed
      const url = new URL(`${this.config.url}`);
      const params = queryString.parse(url.search);
      if (this.session.organizationId && !params?.organizationId && !params?.organizationIds) {
        if (this.config.forwardOrganizationIdAsArray) params.organizationIds = this.session.organizationId;
        else params.organizationId = this.session.organizationId;
      }
      this.config = { ...this.config, url: `${url.origin}${url.pathname}?${queryString.stringify(params)}` };
    }
    return this;
  }
  transform() {
    // if skip request modifications flag is true, return
    if (this.config.skipEverything) return this;

    // if its a request from an unprotectedPath, return
    const isRequestFromUnprotectedPath =
      unprotectedPaths.includes(
        unprotectedPaths.find((path) => window.location.pathname === path || window.location.pathname === path + '/'),
      ) ||
      window.location.pathname.includes('/accept-organization-invite') ||
      window.location.pathname.includes('/reset-password');
    if (isRequestFromUnprotectedPath) return this;

    this.appendAuthorizationHeader();
    this.appendOrganizationId();

    return this;
  }
}
