import { useMutation, useQuery } from '@tanstack/react-query';
import {
  getRaincardsConfiguration,
  createRaincardsConfiguration,
  importRaincardSources,
  getRaincardSources,
  importRaincardTransactions,
  getRaincardJobHistory,
  deleteRaincardsConfiguration,
  getRaincardsRuleMeta,
} from 'services/http/raincards';
import { QUERY_KEY, useOrgBasedQueryKey } from '../useInvalidateQuery';
import { useSession } from '../useSession';
export const useRaincardsIntegrationConfig = () => {
  const getKey = useOrgBasedQueryKey();
  const { organizationId } = useSession();

  return useQuery(
    getKey(QUERY_KEY.RAINCARDS_INTEGRATION_CONFIGURATION),
    async () => {
      const response = await getRaincardsConfiguration();
      console.log({ rcConfig: response.data });
      return response.data.configurations;
    },
    {
      enabled: !!organizationId,
    },
  );
};

export const useCreateRaincardsConfiguration = () => {
  const { organizationId } = useSession();
  return useMutation(async (body: any) => {
    return await createRaincardsConfiguration({ ...body, organizationId });
  });
};

export const useImportRaincardSources = () => {
  const { organizationId, userId } = useSession();
  return useMutation(async () => await importRaincardSources({ organizationId, userId }));
};

export type UseGetRaincardSourcesParam = {
  legalEntityId?: string;
  populateFields?: boolean;
};
export const useGetRaincardSources = (
  { legalEntityId = '', populateFields }: UseGetRaincardSourcesParam = {},
  useInfiniteQueryOptions = {},
) => {
  const getKey = useOrgBasedQueryKey();
  const { organizationId } = useSession();

  return useQuery(
    getKey(QUERY_KEY.RAINCARDS_SOURCES),
    async () => {
      const filter = { organizationId };
      if (legalEntityId) {
        filter['legalEntityId'] = legalEntityId;
      } else {
        filter['legalEntityId'] = '';
      }
      if (populateFields) {
        filter['populateFields'] = true;
      }
      const response = await getRaincardSources(filter);
      return response.data.sources;
    },
    {
      enabled: !!organizationId,
      refetchInterval: 5000,
      ...useInfiniteQueryOptions,
    },
  );
};

export const useImportRaincardTransactions = () => {
  const { organizationId, userId } = useSession();
  return useMutation(async () => await importRaincardTransactions({ organizationId, userId }));
};

export const useRaincardJobHistory = () => {
  const getKey = useOrgBasedQueryKey();
  const { organizationId } = useSession();

  return useQuery(
    getKey(QUERY_KEY.RAINCARDS_JOB_HISTORY),
    async () => {
      const response = await getRaincardJobHistory();
      return response.data.histories;
    },
    {
      enabled: !!organizationId,
    },
  );
};

export const useDeleteRaincardsConfiguration = () => {
  const { organizationId } = useSession();
  return useMutation(async (id: string) => {
    await deleteRaincardsConfiguration({
      organizationId,
      configurationId: id,
    });
  });
};

type RainRulesMetaResponse = {
  uniqueMerchantNames: string[];
  uniqueCategoryNames: string[];
  uniqueTypes: string[];
};
export const useRainRuleMeta = (): RainRulesMetaResponse => {
  const getKey = useOrgBasedQueryKey();
  const { organizationId } = useSession();
  return useQuery(
    getKey(QUERY_KEY.RAINCARDS_RULE_META),
    async () => {
      const response = await getRaincardsRuleMeta();
      console.log(response);
      return response.data.values;
    },
    {
      enabled: !!organizationId,
    },
  ) as unknown as RainRulesMetaResponse;
};
