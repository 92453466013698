import React from 'react';
import { MetricCard, MetricGroup } from '../../atoms';
import { formatDollars } from '../../templates/utils';
import { FILTER_TYPE } from '../../filters';
import { useGetAssetMetrics } from '../../../hooks/http/useAssets';

export const AssetsMetric = ({ debouncedSearchTerm, filterHelpers, datesWithTzOffset }) => {
  const { data: metrics, isLoading: metricsLoading } = useGetAssetMetrics({
    assetTypes: filterHelpers[FILTER_TYPE.ASSET]
      ? debouncedSearchTerm !== ''
        ? [...filterHelpers[FILTER_TYPE.ASSET].getAllSelectedWithTransform(), debouncedSearchTerm]
        : [...filterHelpers[FILTER_TYPE.ASSET].getAllSelectedWithTransform()]
      : [],
    impaired: filterHelpers[FILTER_TYPE.IMPAIRED]?.getAllSelectedWithTransform() ?? [],
    legalEntityIds: filterHelpers[FILTER_TYPE.LEGAL_ENTITY]?.getAllSelectedWithTransform() ?? [],
    startDate: datesWithTzOffset.start,
    endDate: datesWithTzOffset.end,
  });

  return (
    <MetricGroup className='!mb-0 !mt-4'>
      <MetricCard loading={metricsLoading} label='Asset value' metric={formatDollars(metrics?.assetValue)} />
      <MetricCard loading={metricsLoading} label='Impairment gain' metric={formatDollars(metrics?.gainAmouint)} />
      <MetricCard loading={metricsLoading} label='Impairment loss' metric={`${formatDollars(metrics?.lossAmount)}`} />
    </MetricGroup>
  );
};

export default AssetsMetric;
