import React, { useEffect, useState } from 'react';
import { DateInput, InputLabel, SimpleMenu } from 'ui';
import { useAccountingPeriods } from '../../hooks';
import { mergeAccountingPeriods } from '../templates/utils';
import { addDays, isLastDayOfMonth, isValid, startOfMonth, isEqual } from 'date-fns';

const getAllDaysInAMonth = (date: Date) => {
  const firstDay = startOfMonth(date);
  const dates: Date[] = [];
  let d = firstDay;
  while (!isLastDayOfMonth(d)) {
    dates.push(d);
    d = addDays(d, 1);
  }
  dates.push(d);
  return dates;
};
export const getDateWithTzOffset = (date: Date) => {
  return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
};

export const ImpairmentRunManualDialogForm = ({
  selectedImpairmentRule,
  onChange,
  isLoading,
  options,
  onImpairmentRunDateChange,
}) => {
  const initialValue = options.find((item) => item.value === selectedImpairmentRule);

  const { data: accountingPeriodsData, isLoading: isLoadingAccountingPeriods } = useAccountingPeriods({
    pageSize: 1000,
    status: 'Open',
  });

  const [openAccountingPeriods, setOpenAccountingPeriods] = useState<Date[]>([]);
  const [selectedAccountingPeriod, setSelectedAccountingPeriod] = useState<Date | null>(null);
  const [openImpairOnDates, setOpenImpairOnDates] = useState<Date[]>([]);
  const [selectedImpairmentDate, setSelectedImpairmentDate] = useState<Date | null>(null);

  useEffect(() => {
    const oap = mergeAccountingPeriods(accountingPeriodsData);

    setOpenAccountingPeriods(oap.map((ap) => getDateWithTzOffset(new Date(ap.startDateUTC))));
  }, [accountingPeriodsData]);

  useEffect(() => {
    if (selectedAccountingPeriod) setOpenImpairOnDates(getAllDaysInAMonth(selectedAccountingPeriod));
    else setOpenImpairOnDates([]);

    setSelectedImpairmentDate(null);
  }, [selectedAccountingPeriod]);

  useEffect(() => {
    const oap = mergeAccountingPeriods(accountingPeriodsData);

    const accountingPeriodId = selectedAccountingPeriod
      ? oap.find((ap) => isEqual(startOfMonth(new Date(ap.endDate)), selectedAccountingPeriod))?._id ?? null
      : null;
    onImpairmentRunDateChange(selectedImpairmentDate, accountingPeriodId);
  }, [selectedImpairmentDate, selectedAccountingPeriod, accountingPeriodsData]);

  return (
    <div>
      <div className='mb-4'>
        <InputLabel heading='Impairment rule' />
        <SimpleMenu
          defaultValue={initialValue}
          options={options}
          isLoading={isLoading}
          onChange={(option) => onChange(option.value)}
        />
      </div>

      <div className='mb-4'>
        <InputLabel heading='Accounting period' />
        <DateInput
          // {...datePickerProps}
          date={isValid(selectedAccountingPeriod) ? selectedAccountingPeriod : null}
          setDate={(date) => setSelectedAccountingPeriod(date)}
          isLoading={isLoadingAccountingPeriods}
          includeDates={openAccountingPeriods}
          dateFormat='MM/yyyy'
          showMonthYearPicker
        />
      </div>
      {selectedAccountingPeriod && (
        <div>
          <InputLabel heading='Impairment date' />
          <DateInput
            // {...datePickerProps}
            date={isValid(selectedImpairmentDate) ? selectedImpairmentDate : null}
            setDate={(date) => setSelectedImpairmentDate(date)}
            isLoading={isLoadingAccountingPeriods}
            includeDates={openImpairOnDates}
            disabled={!selectedAccountingPeriod}
          />
        </div>
      )}
    </div>
  );
};
