import { useQuery } from '@tanstack/react-query';
import { getCardsFromStripe } from 'services';
import { QUERY_KEY, useOrgBasedQueryKey } from '../../../useInvalidateQuery';

export const useGetCardsFromStripeQuery = () => {
  const getKey = useOrgBasedQueryKey();
  return useQuery(getKey(QUERY_KEY.STRIPE_CARDS), async () => {
    const response = await getCardsFromStripe();
    return response.data.cards;
  });
};
