import React, { useEffect } from 'react';
import { KBarProvider } from 'farish-kbar';

import Container from './Container';
import { useAlgoliaKey } from '../../hooks/http';
import { useCommandPallete } from './useCommandPallete';
import { CommandDialog } from 'ui';
import { getCookie } from 'cookies-next';

function CommandPallete({ children }) {
  const algoliaKey = useAlgoliaKey();

  const { actions, isOpen, setIsOpen } = useCommandPallete();

  const getToken = getCookie('jwt')?.toString() ?? '';

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (!getToken.length) return null;
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setIsOpen((prev) => !prev);
      }
    };

    document.addEventListener('keydown', down);
    return () => document.removeEventListener('keydown', down);
  }, []);

  return (
    <KBarProvider disabled={!getToken.length} actions={actions}>
      <CommandDialog open={isOpen} onOpenChange={setIsOpen}>
        <Container userAlgoliaKey={algoliaKey} />
      </CommandDialog>
      {children}
    </KBarProvider>
  );
}
export default CommandPallete;
