export type ITag = {
  key: string;
  value: string;
};

export type TagInputProps = {
  tag: ITag;
  setTag: (tag: ITag) => void;
};

export enum TAG_TYPES {
  CUSTOMER = 'Customer',
  SUPPLIER = 'Supplier',
  SYSTEM = 'System',
  ID = 'ID',
  BANK_ACCOUNT = 'Bank Account',
}

export const TagTypesOptions = [
  {
    label: 'Customer',
    value: TAG_TYPES.CUSTOMER,
  },
  {
    label: 'Supplier',
    value: TAG_TYPES.SUPPLIER,
  },
  {
    label: 'System',
    value: TAG_TYPES.SYSTEM,
  },
  {
    label: 'ID',
    value: TAG_TYPES.ID,
  },
  {
    label: 'Bank Account',
    value: TAG_TYPES.BANK_ACCOUNT,
  },
];
