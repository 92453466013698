import React, { useMemo } from 'react';
import { ConditionSelectorRow } from '../ConditionSelectorRow';

import { Virtuoso } from 'react-virtuoso';
import { chains } from '../../../../atoms/NewConditionSelector/ConditionsComponents';
import { ConditionsGroup } from '../ConditionsGroup';
import { FACT } from '../../types';
import { useAddCondition } from '../useAddCondition';
import { useFilteredConditions } from '../utils';
import { ConditionGroupFilterConfig, ConvertToRowFn, CSRowProps, FilterFn } from '../types';

const filterChainsFn: FilterFn<string> = (searchTerm) => (chain) =>
  chain.toLowerCase().includes(searchTerm.toLowerCase());
const convertChainToRowFn: ConvertToRowFn<string> = (chain) => ({
  rowData: {
    value: chain.toLowerCase(),
    label: chain,
    symbol: getChainSymbol(chain),
  },
  isSelected: false,
});

export const useChainConditionGroup = (): Omit<ConditionGroupFilterConfig, 'rows'> =>
  useMemo(
    () => ({
      heading: 'Chains',
      data: chains,
      filterFn: filterChainsFn,
      convertToRowFn: convertChainToRowFn,
      Row: ChainConditionSelectorRow,
    }),
    [],
  );

const getChainSymbol = (chain: string) => {
  return chain.toLowerCase();
};

export const ChainConditionSelectorRow = ({ conditionData }: CSRowProps) => {
  const addCondition = useAddCondition();

  // console.log('ChainConditionSelectorRow', { conditionData });

  return (
    <ConditionSelectorRow
      rowData={conditionData.rowData}
      onClick={() => addCondition({ fact: FACT.CHAIN, value: conditionData.rowData.value, operator: 'equal' })}
    />
  );
};

export const ChainConditionsMenu = () => {
  const conditionsData = useFilteredConditions(chains, filterChainsFn, convertChainToRowFn);

  // console.log('ChainConditionsMenu', conditionsData);

  return (
    <ConditionsGroup label='Chains'>
      {conditionsData.length === 0 && <div className='text-center my-4'>No chains to show</div>}
      {conditionsData.length > 0 && (
        <Virtuoso
          style={{ height: 250 }}
          data={conditionsData}
          itemContent={(index, row) => {
            // console.log({ row });
            return <ChainConditionSelectorRow key={index} conditionData={row} />;
          }}
        />
      )}
    </ConditionsGroup>
  );
};
