import React, { useEffect, useState } from 'react';
import {
  Button,
  DESCRIPTIVE_LOADER_STATES,
  DescriptiveLoader,
  InputLabel,
  InputWithExtras,
  Modal,
  ModalContent,
  ModalTrigger,
} from 'ui';
import { useUpdateStripeCustomerMutation } from '../../../hooks/http/billing-service/stripe/use-update-stripe-customer-mutation';
import { useGetStripeDetailsForOrgQuery, useInvalidateQuery, useSession } from '../../../hooks';
import { TimezoneDropdown, useUpdateOrganizationTimezone } from '../../timezone';
import toast from 'react-hot-toast';
import { deriveError } from '../../templates/utils';

enum MODAL_STEP {
  FORM,
  LOADING,
  SUCCESS,
}

export const EditBusinessInfoModal = () => {
  const [step, setStep] = useState(MODAL_STEP.FORM);
  const { data: stripeDetails } = useGetStripeDetailsForOrgQuery();
  const [open, setOpen] = useState(false);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [street, setStreet] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');
  const [zipcode, setZipcode] = useState('');

  const { selectedOrganization } = useSession();

  const [timezone, setTimezone] = useState(selectedOrganization.timezone ?? 'UTC');

  const { mutateAsync: updateStripeCustomer } = useUpdateStripeCustomerMutation();
  const updateTimezone = useUpdateOrganizationTimezone();

  const { invalidateStripeDetailsForOrg } = useInvalidateQuery();

  useEffect(() => {
    if (stripeDetails?.customer?.name) setName(stripeDetails.customer.name);
    if (stripeDetails?.customer?.email) setEmail(stripeDetails.customer.email);
    if (stripeDetails?.customer?.address?.line1) setStreet(stripeDetails.customer.address.line1);
    if (stripeDetails?.customer?.address?.city) setCity(stripeDetails.customer.address.city);
    if (stripeDetails?.customer?.address?.state) setState(stripeDetails.customer.address.state);
    if (stripeDetails?.customer?.address?.country) setCountry(stripeDetails.customer.address.country);
    if (stripeDetails?.customer?.address?.postal_code) setZipcode(stripeDetails.customer.address.postal_code);
  }, [stripeDetails]);

  const onSave = async () => {
    if (!stripeDetails?.customer?.id) return;
    setStep(MODAL_STEP.LOADING);
    try {
      await updateStripeCustomer({
        customerId: stripeDetails?.customer?.id,
        payload: {
          name,
          email,
          address: { line1: street, city, state, country, postal_code: zipcode },
        },
      });
      if (selectedOrganization.timezone !== timezone) await updateTimezone(timezone);
      setStep(MODAL_STEP.SUCCESS);
      invalidateStripeDetailsForOrg();
    } catch (error) {
      setStep(MODAL_STEP.FORM);
      toast.error(deriveError(error));
    }
  };
  const onClose = () => {
    setStep(MODAL_STEP.FORM);
    setOpen(false);
  };

  const title = MODAL_STEP.FORM === step ? 'Edit business info' : undefined;

  return (
    <Modal open={open} onOpenChange={setOpen}>
      <ModalTrigger asChild>
        <Button label='Edit' onClick={() => setOpen(true)} emphasis='medium' />
      </ModalTrigger>
      <ModalContent
        title={title}
        onClose={step === MODAL_STEP.LOADING ? undefined : onClose}
        actions={
          step === MODAL_STEP.FORM
            ? [
                { label: 'Update', onClick: onSave },
                { label: 'Cancel', emphasis: 'medium', onClick: onClose },
              ]
            : []
        }
      >
        {step === MODAL_STEP.FORM && (
          <div className='grid grid-cols-1 gap-6 py-4 pr-2'>
            <div>
              <div className='text-xl text-zinc-600'>Business contact</div>
              <div className='grid grid-cols-1 gap-4 py-4'>
                <div>
                  <InputLabel heading='Contact' />
                  <InputWithExtras value={name} onChange={(e) => setName(e.target.value)} />
                </div>
                <div>
                  <InputLabel heading='Email' />
                  <InputWithExtras value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>
              </div>
            </div>
            <div>
              <div className='text-xl text-zinc-600'>Business address</div>

              <div className='grid grid-cols-1 gap-4 py-4'>
                <div>
                  <InputLabel heading='Street' />
                  <InputWithExtras value={street} onChange={(e) => setStreet(e.target.value)} />
                </div>
                <div className='grid grid-cols-2 gap-4'>
                  <div>
                    <InputLabel heading='City' />
                    <InputWithExtras
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      containerClassName='min-w-20'
                    />
                  </div>
                  <div>
                    <InputLabel heading='State' />
                    <InputWithExtras
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                      containerClassName='min-w-20'
                    />
                  </div>
                </div>
                <div className='grid grid-cols-2 gap-4'>
                  <div>
                    <InputLabel heading='Country' />
                    <InputWithExtras
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                      containerClassName='min-w-20'
                    />
                  </div>
                  <div>
                    <InputLabel heading='Zipcode' />
                    <InputWithExtras
                      value={zipcode}
                      onChange={(e) => setZipcode(e.target.value)}
                      containerClassName='min-w-20'
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className='text-xl text-zinc-600'>Accounting settings</div>
              <div className='grid grid-cols-2 gap-4 py-4'>
                <div>
                  <InputLabel heading='Timezone' />
                  <TimezoneDropdown timezone={timezone} setTimezone={setTimezone} onlyDropdown />
                </div>
              </div>
            </div>
          </div>
        )}
        {step === MODAL_STEP.LOADING && (
          <DescriptiveLoader inModal title='Processing' description='Updating billing information' />
        )}
        {step === MODAL_STEP.SUCCESS && (
          <DescriptiveLoader
            inModal
            title='Success'
            description='Billing information updated'
            status={DESCRIPTIVE_LOADER_STATES.SUCCESS}
          />
        )}
      </ModalContent>
    </Modal>
  );
};
