import { DetailsItem } from '../../atoms';
import { Sidebar, SidebarBody, SidebarTopBar } from '../../atoms/Sidebar';
import { SidebarSection, SidebarSectionHeader } from '../../atoms/Sidebar/SidebarBody';
import SidebarHeader from '../../atoms/Sidebar/SidebarHeader/SidebarHeader';
import { changeRouteName, formatDate, formatDollars, getIdFromPopulatedInstance } from '../../templates/utils';
import { CryptoIcon, handleGainLoss, shortDateConverter } from '../../utils';
import { cryptoSymbol } from 'crypto-symbol';
import { Table } from '../../dashboard';
import { assetImpairmentDetailsHeaders, assetsDetailsHeader } from '../../../constants/tableHeaders';
import { getHost, getValueFromBigDecimal } from '../../../lib/utils';
import { useContext } from 'react';
import { getAssetById } from '../../../hooks/http/useAssets';
import { SidebarGlobalContext } from '../../../context/SidebarGlobalProvider';
import { SidebarProps } from '../SidebarGlobal';
import { AssetGainLossChart } from 'ui';
import { useGetHistoricalAssetPrices } from '../../../hooks/http/useGetHistoricalAssetPrices';

const AssetSidebar: React.FC<SidebarProps> = ({
  id,
  handlePrimaryPanelClose,
  onBack,
  isPrimary,
  isDockPanelAvailable,
}) => {
  const { nameLookup } = cryptoSymbol({});
  const { data, isLoading } = getAssetById(id);
  const selectedAsset = data?.assets[0];
  const { openSidebar } = useContext(SidebarGlobalContext);

  const { data: assetHistoricalPrice, isLoading: isLoadingAssetHistoricalPrice } = useGetHistoricalAssetPrices({
    assetType: selectedAsset?.assetType,
    startDate: new Date(selectedAsset?.dateReceived),
    endDate: new Date(),
  });

  const displayedImpairment = selectedAsset?.impairments.map((impairment) => {
    return {
      journalEntryId: impairment?.journalEntryId?._id,
      costBasis: getValueFromBigDecimal(impairment?.costBasis),
      impairmentQuantity: getValueFromBigDecimal(impairment?.quantity),
      impairmentRuleName: impairment?.impairmentRuleId?.name,
      createdAt: impairment?.createdAt,
      journalEntrySequenceNumber: impairment?.journalEntryId?.journalSequenceNumber,
    };
  });

  const entries = selectedAsset?.journalEntriesAssetOut || [];
  const linesFlattened = entries
    .map((entry) => {
      return entry.journalEntryLineIds.map((line) => {
        return {
          ...line,
          journalEntryId: entry._id,
          journalEntrySequenceNumber: entry.journalSequenceNumber,
        };
      });
    })
    .flat();
  const displayedGainOrLoss = linesFlattened?.map((line) => {
    return {
      createdAt: line.createdAt,
      amount: getValueFromBigDecimal(line.amount),
      journalEntrySequenceNumber: line.journalEntrySequenceNumber,
      creditOrDebit: line.creditOrDebit,
      ledgerAccountName: line.ledgerAccountId?.ledgerAccountName,
      journalEntryId: line.journalEntryId,
    };
  });

  changeRouteName({
    name: `${nameLookup(selectedAsset?.assetType, { exact: true }) ?? ''} (${selectedAsset?.assetType})`,
    isLoading,
  });

  const dataCy = 'viewAsset';

  return (
    <>
      <Sidebar data-cy={dataCy}>
        <SidebarTopBar
          isDockPanelAvailable={isDockPanelAvailable}
          onClose={() => handlePrimaryPanelClose && handlePrimaryPanelClose()}
          onBack={onBack}
          itemId={id}
          isPrimary={isPrimary}
          data-cy={dataCy}
        />
        <SidebarHeader
          loading={isLoading}
          title={`${nameLookup(selectedAsset?.assetType, { exact: true }) ?? ''} (${selectedAsset?.assetType})`}
          link={`${getHost()}/ledger/assets/${id}`}
          subtitles={[`Created ${formatDate(new Date(selectedAsset?.createdAt))}`]}
          data-cy={dataCy}
        />
        <SidebarBody>
          <SidebarSectionHeader title='Details' />
          <SidebarSection loading={isLoading}>
            <DetailsItem tag='Last Impaired value' value={selectedAsset?.impairedCostBasis} />
            <DetailsItem tag='Current value' value={formatDollars(selectedAsset?.currentValue)} />
            <DetailsItem tag='Cost basis' value={formatDollars(selectedAsset?.costBasis)} />
            <DetailsItem tag='Initial quantity' value={selectedAsset?.quantity} />
            <DetailsItem tag='Remaining quantity' value={selectedAsset?.remainingQuantity?.$numberDecimal} />
            <DetailsItem
              valueStyle={handleGainLoss(selectedAsset?.gainLossPercentage).color}
              tag='Gain/loss'
              value={handleGainLoss(selectedAsset?.gainLossPercentage).value}
            />
            <DetailsItem
              tag='Asset type'
              value={`${nameLookup(selectedAsset?.assetType, { exact: true }) ?? ''} ${selectedAsset?.assetType}`}
              leadingComponent={<CryptoIcon symbol={selectedAsset?.assetType.toLowerCase()} size='sm' />}
            />

            <DetailsItem tag='Operational transaction ID' value={selectedAsset?.transactionId} />
            <DetailsItem
              onClick={() => {
                openSidebar('journals', {
                  id: selectedAsset?.journalEntryAssetIn?._id,
                  primaryOrSecondary: 'secondary',
                });
              }}
              className='text-blue-800'
              tag='Journal entry'
              value={selectedAsset?.journalEntryAssetIn?.journalSequenceNumber}
              itemId={selectedAsset?.journalEntryAssetIn?._id}
            />

            <DetailsItem
              tag='Ledger account'
              variant='secondary'
              onClick={() =>
                openSidebar('ledger-accounts', {
                  id: selectedAsset?.ledgerAccountId?._id,
                  primaryOrSecondary: 'secondary',
                })
              }
              value={selectedAsset?.ledgerAccountId?.ledgerAccountName}
            />
            <DetailsItem
              tag='Legal entity'
              value={getIdFromPopulatedInstance(selectedAsset?.legalEntityId, 'entityName')}
              variant='secondary'
              textToCopy={`${getHost()}/configure/entities/${getIdFromPopulatedInstance(selectedAsset?.legalEntityId)}`}
              onClick={() => {
                openSidebar('entities', {
                  id: getIdFromPopulatedInstance(selectedAsset?.legalEntityId),
                  primaryOrSecondary: 'secondary',
                });
              }}
              itemId={getIdFromPopulatedInstance(selectedAsset?.legalEntityId)}
            />
            <DetailsItem tag='Source' value={selectedAsset?.source} />
            <DetailsItem tag='Received at' value={shortDateConverter(selectedAsset?.dateReceived)} />
            <DetailsItem tag='Last impaired at' value={shortDateConverter(selectedAsset?.lastImpairedAt)} />
            <DetailsItem tag='Created' value={selectedAsset?.createdBy} />
          </SidebarSection>
          <SidebarSectionHeader title='Impairment events' />
          <SidebarSection>
            <Table
              onRowClick={(row) => {
                openSidebar('journals', { id: row.original.journalEntryId, primaryOrSecondary: 'secondary' });
              }}
              tableHeader={assetImpairmentDetailsHeaders}
              tableData={{
                Data: displayedImpairment || [],
              }}
              hideCheckboxes
              isSidePanel
              tableClasses={'min-w-fit'}
              enableColumnPinning={false}
            />
          </SidebarSection>
          <SidebarSectionHeader title='Gain & Loss' />
          <AssetGainLossChart
            data={assetHistoricalPrice?.data.timeSeriesPrices || []}
            isLoading={isLoadingAssetHistoricalPrice}
          />
          <SidebarSection>
            {displayedGainOrLoss && displayedGainOrLoss.length !== 0 && (
              <Table
                onRowClick={(row) => {
                  openSidebar('journals', { id: row.original.journalEntryId, primaryOrSecondary: 'secondary' });
                }}
                tableHeader={assetsDetailsHeader}
                tableData={{
                  Data: displayedGainOrLoss,
                }}
              />
            )}
          </SidebarSection>
        </SidebarBody>
      </Sidebar>
    </>
  );
};

export default AssetSidebar;
