import { useEffect } from 'react';
import { useSession } from '../../hooks';
import { useUpdateOrganizationTimezone } from './use-update-organization-timezone';
import { Button, InputLabel, TimezonePicker } from 'ui';

export const TimezoneDropdown = ({ timezone, setTimezone, onlyDropdown = false }) => {
  const { selectedOrganization } = useSession();

  useEffect(() => {
    if (selectedOrganization?.timezone) setTimezone(selectedOrganization.timezone);
  }, [selectedOrganization]);

  const onTimezoneSave = useUpdateOrganizationTimezone();

  if (onlyDropdown)
    return (
      <div className='max-w-sm'>
        <TimezonePicker value={timezone} onChange={setTimezone} />
      </div>
    );

  return (
    <div>
      <InputLabel heading='Select timezone' description='This affects all the data displayed in the application' />
      <div className='max-w-lg'>
        <div className='flex items-center gap-x-2'>
          <div className='max-w-sm'>
            <TimezonePicker value={timezone} onChange={setTimezone} />
          </div>

          <div>
            <Button label='Save' variant='md' onClick={() => onTimezoneSave(timezone)} />
          </div>
        </div>
      </div>
    </div>
  );
};
