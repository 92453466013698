import React from 'react';
import { useGetJournalEntryLinesQuery, useGetLedgerAccountById } from '../../../../hooks';
import { DetailsItem, Sidebar, SidebarBody, SidebarTopBar } from '../../../atoms';
import SidebarHeader from '../../../atoms/Sidebar/SidebarHeader/SidebarHeader';
import { getHost } from '../../../../lib/utils';
import { SidebarSection, SidebarSectionHeader } from '../../../atoms/Sidebar/SidebarBody';

import { formatDate } from '../../../templates/utils';
import { useDisplayedJELines } from './useDisplayedJELines';
import { JournalLinesTable } from './JournalLinesTable';
import { formatDateForQuery } from 'services/http';
import { ACCOUNTING_PERIOD_STATUS } from 'services/http/response.types';

export const BalanceOverviewSidebar = ({
  isDockPanelAvailable,
  onBack,
  handlePrimaryPanelClose,
  isPrimary,
  ledgerAccountId,
  ledgerAccountIds,
  legalEntityIds = [],
  startingBalance,
  currentBalance,
  endingBalance,
  accountingPeriodName,
  accountingPeriodStatus,
  startDate,
  endDate,
  accountingPeriodId,
}: any) => {
  const { data: ledgerAccountResponse, isLoading: isLoadingLedgerAccount } = useGetLedgerAccountById(ledgerAccountId);

  const {
    data: journalEntryLinePages,
    isLoading: isLoadingJournalEntries,
    isFetchingNextPage,
    fetchNextPage,
  } = useGetJournalEntryLinesQuery({
    accountingPeriodId,
    startDate: formatDateForQuery(new Date(startDate)),
    endDate: formatDateForQuery(new Date(endDate)),
    legalEntityIds,
    ledgerAccountIds,
  });
  const displayedJELines = useDisplayedJELines(journalEntryLinePages);

  return (
    <Sidebar>
      <SidebarTopBar
        onClose={handlePrimaryPanelClose}
        isDockPanelAvailable={isDockPanelAvailable}
        onBack={onBack}
        itemId={ledgerAccountId}
        isPrimary={isPrimary}
      />
      <SidebarHeader
        title={`${ledgerAccountResponse?.ledgerAccount?.ledgerAccountSequence}:${ledgerAccountResponse?.ledgerAccount?.ledgerAccountName}`}
        link={`${getHost()}/ledger/impairment-rules/${ledgerAccountId}`}
        loading={isLoadingLedgerAccount}
      />
      <SidebarBody>
        <SidebarSectionHeader title='Details' />
        <SidebarSection numberOfColumns={2} loading={isLoadingLedgerAccount}>
          <DetailsItem tag='Accounting period' value={accountingPeriodName} />
          <DetailsItem tag='Accounting status' value={accountingPeriodStatus} />
          <DetailsItem tag='Starting balance' value={startingBalance} />
          <DetailsItem
            tag={accountingPeriodStatus === ACCOUNTING_PERIOD_STATUS.OPEN ? 'Current balance' : 'Ending balance'}
            value={accountingPeriodStatus === ACCOUNTING_PERIOD_STATUS.OPEN ? currentBalance : endingBalance}
          />
          <DetailsItem
            tag='Last updated'
            value={`
                ${
                  journalEntryLinePages?.pages[0].journalEntryLines[0]?.user.email
                    ? journalEntryLinePages.pages[0].journalEntryLines[0].user.email
                    : ''
                }\n
                ${
                  journalEntryLinePages?.pages[0].journalEntryLines[0]?.accountingDate
                    ? formatDate(new Date(journalEntryLinePages.pages[0].journalEntryLines[0].accountingDate))
                    : ''
                }`}
          />
        </SidebarSection>
        <SidebarSectionHeader title='Journal lines' />
        <SidebarSection numberOfColumns={1} loading={isLoadingJournalEntries}>
          <JournalLinesTable
            isLoading={isLoadingJournalEntries}
            isFetchingNextPage={isFetchingNextPage}
            displayedJELines={displayedJELines}
            fetchNextPage={fetchNextPage}
          />
        </SidebarSection>
      </SidebarBody>
    </Sidebar>
  );
};
