import React from 'react';
import ConditionItem from '../ConditionItem';
import { setSaveData } from '../../../rules/utils';
import SearchFilter from './SearchFilter';
import { ConditionMenusProps } from './types';
import { loopPathValues } from '../../../rules/configurator/data';

export const LoopCondition = ({ ruleSetData, getRuleSetData, isFirstLevel }: ConditionMenusProps) => {
  return (
    <ConditionItem label='Loop'>
      <SearchFilter
        type='loop'
        data={loopPathValues}
        renderItem={(item) => {
          return (
            <ConditionItem
              onClick={() => {
                setSaveData('Loop', item, isFirstLevel, ruleSetData, getRuleSetData);
              }}
              key={item}
              label={item}
            />
          );
        }}
      />
    </ConditionItem>
  );
};

export default LoopCondition;
