import React from 'react';
import { ClearIndicatorProps, components } from 'react-select';
import { XIcon } from '../../../../assets/generated/svg';

export const ClearIndicator = (props: ClearIndicatorProps) => {
  return (
    <components.ClearIndicator className='text-xs !p-3 rounded-lg hover:bg-[#005ef41a] ml-2' {...props}>
      <XIcon
        style={{
          filter: 'invert(21%) sepia(49%) saturate(2406%) hue-rotate(201deg) brightness(96%) contrast(89%)',
        }}
      />
    </components.ClearIndicator>
  );
};

export default ClearIndicator;
