import { useEffect, useState } from 'react';
import { DisplayedJournalEntryLine } from '../../../PageComponents/Journals/type';
import { Transaction } from 'schemas';
import { LineWithDecimalAmount } from '../../../PageComponents/Journals/type';

export const useDisplayedJELines = (journalEntryLinePages) => {
  const [displayedJEs, setDisplayedJEs] = useState<DisplayedJournalEntryLine[]>([]);

  useEffect(() => {
    if (journalEntryLinePages) {
      const jeLines = journalEntryLinePages.pages
        .map((page) => page.journalEntryLines)
        .flat()
        .map((line) => ({
          _id: line._id,
          type: (line.journalEntryLine as LineWithDecimalAmount).creditOrDebit,
          journal: { sequenceNumber: line.journalSequenceNumber },
          transaction: { sequenceNumber: (line.transaction as Transaction)?.sequenceNumber },
          amount: (line.journalEntryLine as LineWithDecimalAmount).amount.$numberDecimal,
        }));

      setDisplayedJEs(jeLines);
    }
  }, [journalEntryLinePages]);

  return displayedJEs;
};
