export enum LOADER_ICON_SIZE {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum LOADER_ICON_PRIMARY_COLOR {
  BLUE = 'blue',
}

export enum LOADER_ICON_SECONDARY_COLOR {
  LIGHTGRAY = 'lightgray',
  DARKGRAY = 'gray',
}

export interface LoaderIconProps {
  size?: LOADER_ICON_SIZE;
  primaryColor?: LOADER_ICON_PRIMARY_COLOR;
  secondaryColor?: LOADER_ICON_SECONDARY_COLOR;
}
