import { useQuery } from '@tanstack/react-query';
import { getAssetTypes } from 'services/http';
import { QUERY_KEY, useOrgBasedQueryKey } from '../useInvalidateQuery';
import { useSession } from '../useSession';

export const useAssetTypes = () => {
  const getKey = useOrgBasedQueryKey();

  const { organizationId } = useSession();

  const { data, isLoading } = useQuery(
    getKey(QUERY_KEY.ASSET_TYPES),
    async () => {
      const response = await getAssetTypes();
      return response.data.assetTypes;
    },
    { enabled: !!organizationId },
  );

  return { assetTypes: data || [], isLoading };
};
