import toast from 'react-hot-toast';
import { deriveError } from '../../components/templates/utils';
import { useInfiniteQuery } from '@tanstack/react-query';
import { QUERY_KEY, useOrgBasedQueryKey } from '../useInvalidateQuery';
import { useSession } from '../useSession';
import { getClosingPositions } from 'services';
import { GetFinanceReportsQueryFilter } from './type';

export const useGetClosingPositions = (params?: GetFinanceReportsQueryFilter) => {
  const { organizationId } = useSession();

  const getKey = useOrgBasedQueryKey();

  return useInfiniteQuery(
    getKey(QUERY_KEY.CLOSINGPOSITIONS, params),
    async ({ pageParam = 0 }) => {
      const response = await getClosingPositions({
        organizationId,
        organizationIds: [organizationId],
        page: pageParam,
        ...params,
        sort: params?.sort ?? { id: 'dateReceived', desc: true },
        pageSize: params?.pageSize ?? 25,
        assetViewOptions: 'HELD',
      });
      return response.data;
    },
    {
      enabled: !!organizationId,
      getPreviousPageParam: (firstPage) => firstPage.prevPage ?? undefined,
      getNextPageParam: (lastPage) => lastPage.nextPage,
      refetchOnWindowFocus: false,
      onError: (error) => {
        toast.error(deriveError(error));
      },
    },
  );
};
