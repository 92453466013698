// This hook return title, description and loader icon/anims for components based on jobconfig

import { SetStateAction, useContext, useEffect, useMemo, useState } from 'react';
import { JobProgressContext } from '../../context/JobProgressProvider';
import { JOB_NAME, JOB_STATUS, LeanJobConfig } from 'services/http';
import {
  getLoaderBgStateFromJobConfig,
  getLoaderDescriptionFromBgJobState,
  getLoaderStatusFromBgJobState,
  getLoaderTitleFromBgJobState,
} from './getLoaderProps';
import { BG_JOB_STATE } from './types';
import { LoaderIcon } from 'ui';
import { MdCheckCircle, MdError } from 'react-icons/md';

interface SyncedBgJobTrackerControllerProps {
  jobName: JOB_NAME;
  referenceId: string;
  bgJobState: BG_JOB_STATE;
  setBgJobState: React.Dispatch<SetStateAction<BG_JOB_STATE>>;
  error?: string;
}

// It starts listening to websocket updates and updates state as needed
export const useSyncedBgJobTrackerProps = ({
  jobName,
  referenceId,
  bgJobState,
  setBgJobState,
  error,
}: SyncedBgJobTrackerControllerProps) => {
  const { state, getJobProgressFromReferenceAndName } = useContext(JobProgressContext);
  const [jobConfig, setJobConfig] = useState<LeanJobConfig>();

  // listen to websocket updates
  useEffect(() => {
    setJobConfig((prev) => {
      const next = getJobProgressFromReferenceAndName(referenceId, jobName);
      // if there is no prev
      if (!prev) return next;
      // if job has already finished, ignore the update
      if (prev.jobStatus === JOB_STATUS.COMPLETED) return prev;
      // reset if this is the first time its running (this needs to move to startTrackingJob)
      if (!next) return next;
      // mark the job as completed on completion update message
      if (next.jobStatus === JOB_STATUS.COMPLETED) return next;

      // only consider updates which have completed count greater than current value
      return (next.completedCount ?? 0) > (prev.completedCount ?? 0) ? next : prev;
    });
  }, [state, referenceId, jobName]);

  // update loader state based on changes in jobconfig (websocket)
  useEffect(() => {
    if (jobConfig) setBgJobState(getLoaderBgStateFromJobConfig(jobConfig));
  }, [jobConfig]);

  const icon = useMemo(() => {
    if (!jobConfig) return <LoaderIcon />;
    // if ([JOB_NAME.IMPORT_TRANSACTION_JOB, JOB_NAME.IMPAIRMENT_JOB].includes(jobName)) return <LoaderIcon />;
    // else return <MdAccessTime />;
    if ([JOB_STATUS.STARTED, JOB_STATUS.IN_PROGRESS].includes(jobConfig.jobStatus)) return <LoaderIcon />;
    if (jobConfig.jobStatus === JOB_STATUS.COMPLETED)
      return <MdCheckCircle className='w-4 h-4 text-green-100 bg-green-800 rounded-full' />;
    if ([JOB_STATUS.JOB_FAILED, JOB_STATUS.CANCELED].includes(jobConfig.jobStatus))
      return <MdError className='w-4 h-4 text-red-100 bg-red-800 rounded-full' />;
  }, [jobConfig]);

  // more props can be added here to support multiple component types (dialog, task manager, and so on...)
  // console.log('useSyncedBgJobTrackerProps', {
  //   description: getLoaderDescriptionFromBgJobState({ bgJobState, jobName, jobConfig, error }),
  // });

  return {
    title: getLoaderTitleFromBgJobState(bgJobState),
    description: getLoaderDescriptionFromBgJobState({ bgJobState, jobName, jobConfig, error }),
    status: getLoaderStatusFromBgJobState(bgJobState),
    jobConfig,
    icon,
  };
};
