import { CheckboxListItem, DropdownGroup, DropdownGroupBody, DropdownGroupTitle } from 'ui';

export const LegalEntitiesFilterRowGroup = ({ state, onSingleChange, onClearAll }) => {
  if (!state) return null;

  return (
    <DropdownGroup>
      <DropdownGroupTitle title='Legal Entities' />
      <DropdownGroupBody>
        {state.ids.length > 1 ? (
          state.ids
            .map((_id) => state.entities[_id])
            .map((legalEntity) => (
              <CheckboxListItem
                key={legalEntity._id}
                label={legalEntity.entityName}
                checked={legalEntity.selected}
                onCheckedChange={(selected: boolean) => onSingleChange({ ...legalEntity, selected })}
                selectOnlyFn={() => {
                  onClearAll();
                  onSingleChange({ ...legalEntity, selected: true });
                }}
              />
            ))
        ) : (
          <p className='text-zinc-900 text-sm px-2'>No legal entities to show.</p>
        )}
      </DropdownGroupBody>
    </DropdownGroup>
  );
};
