import axios from 'axios';
import { SERVER_URL_GL_INTEGRATION } from '../config';
import { ExternalLedgerAccount, LedgerAccountMapping } from './response.types';
import { ExternalAccountPayload, GLType, GlAuthRedirectPayload } from './types';
import { prepareSearchParams } from './core/utils';
import { GLIntegrationConfig, JournalEntrySyncRecord } from 'schemas';

export const getQuickBooksRedirectUrl = async (data: GlAuthRedirectPayload) =>
  axios.get(
    `${SERVER_URL_GL_INTEGRATION}/qb-connect?organizationId=${data.organizationId}&subdomain=${data.subdomain}`,
  );

export const getExternalAccounts = async (data: ExternalAccountPayload) =>
  axios.get<{ externalLedgerAccounts: ExternalLedgerAccount[] }>(
    `${SERVER_URL_GL_INTEGRATION}/external-ledger-accounts?organizationId=${data.organizationId}&integrationType=${data.glType}&realmId=${data.realmId}`,
  );

export const postLedgerAccountMappings = async (
  organizationId: string,
  ledgerAccountMappings: LedgerAccountMapping[],
) => {
  return axios.post(
    `${SERVER_URL_GL_INTEGRATION}/ledger-account-mappings?organizationId=${organizationId}`,
    ledgerAccountMappings,
  );
};

export const getAllIntegrationStatus = async (organizationId: string) => {
  return axios.get(`${SERVER_URL_GL_INTEGRATION}/get-gl-auths?organizationId=${organizationId}`);
};

export const getLedgerAccountMappings = async (organizationId: string, glType: GLType, realmId: string) => {
  return axios.get<{ ledgerAccountMappings: LedgerAccountMapping[] }>(
    `${SERVER_URL_GL_INTEGRATION}/ledger-account-mappings?integrationType=${glType}&realmId=${realmId}`,
  );
};

export const syncJournalEntries = async (organizationId: string, accountingPeriodId, glType: GLType) => {
  return axios.post(
    `${SERVER_URL_GL_INTEGRATION}/sync-journal-entry?organizationId=${organizationId}&accountingPeriodId=${accountingPeriodId}&integrationType=${glType}`,
  );
};
export const createSyncJournalEntryJob = async (
  organizationId: string,
  accountingPeriodId: string,
  userId: string,
  integrationType: GLType,
  realmId: string,
) => {
  return axios.post(
    `${SERVER_URL_GL_INTEGRATION}/sync-journal-entry-job?organizationId=${organizationId}&accountingPeriodId=${accountingPeriodId}&userId=${userId}&integrationType=${integrationType}&realmId=${realmId}`,
  );
};

export const disconnectOAuth2Integration = async (organizationId: string, glType: GLType, realmId: string) =>
  axios.post(
    `${SERVER_URL_GL_INTEGRATION}/oauth-revoke-token?organizationId=${organizationId}&integrationType=${glType}&realmId=${realmId}`,
  );

// QB Sync History related API calls (these might be analogous to Sync Journal Entry related calls)
// entity: SyncHistoryOperationRecord

export interface CreateSyncHistoryOperationRecordApiCallParams {
  organizationId: string;
  accountingPeriodId: string;
  userId: string;
}
export interface SyncHistoryFromServer {
  _id: string;
  organizationId: string;
  accountingPeriodId: {
    _id: string;
    organizationId: string;
    startDate: string;
    endDate: string;
    closedOnDate: any;
    status: string;
    accountingPeriodName: string;
    createdAt: string;
    updatedAt: string;
    __v: number;
  };
  createdBy: {
    _id: string;
    email: string;
    googleAuth: any;
    address: any;
    createdAt: string;
    updatedAt: string;
    __v: number;
  };
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface GetAllSyncHistoriesResponse {
  syncHistories: SyncHistoryFromServer[];
  success: true;
}

export const getAllSyncHistoryOperationRecords = (filters) =>
  axios.get<GetAllSyncHistoriesResponse>(`${SERVER_URL_GL_INTEGRATION}/sync-history?${prepareSearchParams(filters)}`);

export interface GetAllEntriesForSyncHistoryResponse {
  entries: JournalEntrySyncRecord[];
  success: true;
}
export const getAllEntriesForSyncHistoryOperationRecord = (filters) =>
  axios.get<GetAllEntriesForSyncHistoryResponse>(
    `${SERVER_URL_GL_INTEGRATION}/sync-entries?${prepareSearchParams(filters, { isPaginated: true })}`,
  );

export const getGlIntegrationConfig = (organizationId: string, glType: GLType, realmId: string) =>
  axios.get<{ glIntegrationConfig: GLIntegrationConfig | null }>(
    `${SERVER_URL_GL_INTEGRATION}/gl-integration-config?organizationId=${organizationId}&integrationType=${glType}&realmId=${realmId}`,
  );

export const getAllGlIntegrationConfigs = async (organizationId: string, glType: GLType) =>
  axios.get<{ glIntegrations: (GLIntegrationConfig & { legalEntityId: { entityName: string } })[] }>(
    `${SERVER_URL_GL_INTEGRATION}/gl-integrations?organizationId=${organizationId}&glType=${glType}`,
  );

export const updateGlIntegrationConfig = (
  organizationId: string,
  glType: GLType,
  shouldRolledUp: boolean,
  legalEntityId: string,
  realmId: string,
) =>
  axios.post<GLIntegrationConfig>(`${SERVER_URL_GL_INTEGRATION}/gl-integration-config`, {
    organizationId,
    glType,
    shouldRolledUp,
    legalEntityId,
    realmId,
  });

export const getXeroRedirectUrl = async (data: GlAuthRedirectPayload) =>
  axios.get(
    `${SERVER_URL_GL_INTEGRATION}/xero-client-connect?organizationId=${data.organizationId}&subdomain=${data.subdomain}&integrationType=${GLType.XERO}`,
  );
