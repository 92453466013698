/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import { StyledItem } from '../../../styles/DropDownMenuStyle';
import { DropDownItemProps } from './types';

export const DropDownItem = forwardRef<HTMLDivElement, DropDownItemProps>(
  ({ children, disabled, onClick, ...props }, ref) => (
    <StyledItem
      {...props}
      ref={ref}
      onClick={!disabled ? onClick : undefined}
      disabled={disabled}
      className={disabled ? '!opacity-40 !cursor-not-allowed' : ''}
    >
      {children}
    </StyledItem>
  ),
);

export default DropDownItem;
