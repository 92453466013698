import React, { useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { Button } from './atoms';
import { useUploadFile } from '../hooks/http/useFileUpload';
import { ORGANIZATION_FILE_USE_CASE } from 'services/http/response.types';
import { useSession } from '../hooks';

const DropZone = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
`;

const StyledText = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 8px;
`;

const StyledButton = styled(Button)`
  background-color: white;
`;

export function JournalFileUploader({ organizationId, journalId, onSuccess }) {
  const { mutateAsync: uploadAsync, isLoading } = useUploadFile();
  const { userId } = useSession();
  const onDrop = useCallback(
    async (files) => {
      try {
        if (!organizationId || !journalId) {
          return;
        }
        for (const file of files) {
          await uploadAsync({
            userId,
            file,
            metadata: {
              journalentryid: journalId,
              contenttype: file.type,
              usecase: ORGANIZATION_FILE_USE_CASE.JOURNAL_ENTRY,
              userid: userId,
            },
          });
        }
        onSuccess();
      } catch (error) {
        console.error('Error uploading', error);
      }
    },
    [organizationId, journalId],
  );

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone();

  useEffect(() => {
    if (journalId && organizationId && acceptedFiles.length) {
      onDrop(acceptedFiles);
    }
  }, [journalId, organizationId, acceptedFiles.length]);

  return (
    <DropZone {...getRootProps()}>
      <input {...getInputProps()} />
      <StyledText>Drag & drop files here</StyledText>
      <StyledButton> Choose file</StyledButton>
      {!!acceptedFiles.length && !journalId && <span>You Have {acceptedFiles.length} selected files</span>}
      {isLoading && <div>Loading...</div>}
    </DropZone>
  );
}
