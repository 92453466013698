import { useContext } from 'react';
import { JournalEntryLinesTableHeader } from '../../../../constants';
import { Table, TableWrapper } from '../../../dashboard';
import { SidebarGlobalContext, TABLE_TYPE } from '../../../../context';

export const JournalLinesTable = ({ displayedJELines, fetchNextPage, isLoading, isFetchingNextPage }) => {
  const { openSidebar } = useContext(SidebarGlobalContext);
  return (
    <TableWrapper isInSidepanel>
      <Table
        data-cy='journalLines'
        tableHeader={JournalEntryLinesTableHeader}
        tableData={{
          Data: displayedJELines,
        }}
        useInViewCallback={() => fetchNextPage()}
        isLoading={isLoading}
        isFetchingNextPage={isFetchingNextPage}
        onRowClick={(row) => openSidebar('journals', { primaryOrSecondary: 'secondary', id: row.original._id })}
        emptyBtn={{
          emptyMsg: 'No journal entry line found',
        }}
        tableType={TABLE_TYPE.ALL_JOURNALS}
        isSidePanel
        hideCheckboxes
        enableColumnPinning={false}
      />
    </TableWrapper>
  );
};
