import React from 'react';
import * as Dropdown from '@radix-ui/react-dropdown-menu';
import { DotsIcon } from '../../../assets/generated/svg';
import { StyledContent, StyledTrigger } from '../../../styles/DropDownMenuStyle';
import { DropDownMenuProps } from './types';
import { Button } from 'ui';
import { MdArrowDropDown } from 'react-icons/md';

export const DropDownMenu = ({ children, label, className }: DropDownMenuProps) => {
  return (
    <Dropdown.Root>
      <StyledTrigger className={className}>
        {!label ? (
          <Button emphasis='medium' leadingIcon={<DotsIcon className='!text-xl rotate-90' />} />
        ) : (
          <Button label={label} emphasis='medium' trailingIcon={<MdArrowDropDown />} />
        )}
      </StyledTrigger>
      <StyledContent align='start' avoidCollisions={true}>
        {children}
      </StyledContent>
    </Dropdown.Root>
  );
};

export default DropDownMenu;
