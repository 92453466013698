import React from 'react';
import { AppBarList } from 'ui';
import { FILTER_TYPE } from '../../filters';
import { processAssetBreakdownData } from './utils';
import { useGetAssetBreakdown } from '../../../hooks/http/useGetAssetBreakdown';

export const AssetsChart = ({ debouncedSearchTerm, filterHelpers }) => {
  const { data: assetBreakdownData, isLoading } = useGetAssetBreakdown({
    assetTypes: filterHelpers[FILTER_TYPE.ASSET]
      ? debouncedSearchTerm !== ''
        ? [...filterHelpers[FILTER_TYPE.ASSET].getAllSelectedWithTransform(), debouncedSearchTerm]
        : [...filterHelpers[FILTER_TYPE.ASSET].getAllSelectedWithTransform()]
      : [],
    legalEntityIds: filterHelpers[FILTER_TYPE.LEGAL_ENTITY]?.getAllSelectedWithTransform() ?? [],
  });

  const { processedData } = processAssetBreakdownData(assetBreakdownData);
  return (
    <AppBarList
      data={processedData}
      categories={['Values', 'Quantities']}
      title='Asset Breakdown'
      subtitle='Asset type'
      isLoading={isLoading}
      containerId='assets-chart'
    />
  );
};

export default AssetsChart;
