import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { GoArrowUpRight, GoTriangleRight } from 'react-icons/go';
import { BiSolidDockRight } from 'react-icons/bi';

import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  createColumnHelper,
  getSortedRowModel,
  getExpandedRowModel,
  SortingState,
} from '@tanstack/react-table';
import { useInView } from 'react-intersection-observer';
import { TransactionsHeaderData } from '../../constants/tableHeaders';
import StyledTable from '../../styles/Tables';
import { TableSkeletonLoader, Tooltip } from '../atoms';
import {
  Button,
  DefaultCell,
  LOADER_ICON_SIZE,
  LeadingCell,
  LeadingCellExpandable,
  LoaderIcon,
  SingleLineAvatar,
  StatusIndicator,
  TwoLineAvatar,
  TwoLineNumber,
  classNames,
  DefaultTableHeader,
  SingleLineNumber,
  NumberHeader,
  IndeterminateCheckbox,
  CopyAction,
  SingleAction,
  MultipleActions,
  TwoLineIcons,
  getTransactionExplorerLink,
  Avatar,
} from 'ui';
import { formatDollars } from '../templates/utils';
import { currencyImg, dateConverter, fixedTableColumn, handleGainLoss } from '../utils';
import { capitalizeFirstLetter } from '../Nav/utils';
import { MdDragIndicator } from 'react-icons/md';
import { SidebarGlobalContext } from '../../context/SidebarGlobalProvider';
import { TableProps } from './types';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
  MeasuringStrategy,
  DragMoveEvent,
  DragEndEvent,
  UniqueIdentifier,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useTableActionBarContext } from '../../context';
import clsx from 'clsx';
import { useCopyToClipboard } from 'usehooks-ts';
import CurrencyFlag from 'react-currency-flags';
import { PaymentMethodSymbol } from '../billing/common/payment-method-symbol';
import { shrink } from '../../lib/utils';
import { InvoiceLinks } from './cells';
import { cryptoSymbol } from 'crypto-symbol';
import { formatTableNumbers } from 'global-utils';

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  currencyDisplay: 'narrowSymbol',
  // do not round
  // These options are needed to round to whole numbers if that's what you want.
  // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const shortText = (str) => {
  return /[0-9]/.test(str) && !/\s/g.test(str) ? `${str.slice(0, 5)}...${str.slice(-5)}` : str;
};

const formatUnderscoreText = (text: string) => {
  const words = text.split('_').map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());

  const formattedText = words.join(' ');

  return formattedText;
};

const handleTags = (str = []) => {
  return (
    <>
      {str?.map((item: any, index) => (
        <div key={index} className='whitespace-nowrap'>
          <p>
            {capitalizeFirstLetter(item?.entry?.key?.toLowerCase() ?? '')}
            {item?.entry?.key && item?.entry?.value && ':'}
            {capitalizeFirstLetter(item?.entry?.value?.toLowerCase() ?? '')}
          </p>
          {!!index && index + 1 !== str.length && ','}
        </div>
      ))}
    </>
  );
};

const SelectedTable = styled.div`
  position: fixed;
  left: 55%;
  bottom: 46px;
  transform: translate(-50%, -50%);
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 555px;
  padding: 0.5rem;
  border-radius: 0.75rem;
  color: black;
  box-sizing: content-box;
  font-size: 1.25rem;
  line-height: 1.75rem;
  border: 1px solid #e8e8e8;
  z-index: 30;
`;

/**
 *
 * Use onSortingChange to keep a sorting state for server-side sorting
 * @returns
 */

export const TableActionBarWrapper = ({ component, props }) => {
  const ActionBarComponent = useMemo(() => component, [component]);
  if (!props.selectedRows) return null;
  if (props.selectedRows.length === 0) return null;
  if (props.hidden) return null;

  return (
    <SelectedTable>
      <p className='m-0'>({props.selectedRows.length}) Selected</p>
      <ActionBarComponent {...props} />
    </SelectedTable>
  );
};

// need to define default props as a constant outside of the component, to avoid infinite rerenders
// related to https://dev.to/rhuzaifa/how-to-avoid-default-props-render-trap-in-react-4hon
// https://stackoverflow.com/questions/71060000/what-is-the-status-of-the-default-props-rerender-trap-in-react
const DEFAULT_ACTION_BAR_PROPS = {};
const DEFAULT_INITIAL_EXPAND = {};

export const Table = ({
  tableTopActions,
  tableTopActionsContainerClassname,
  tableHeader = TransactionsHeaderData,
  tableData = { Data: [] },
  useInViewCallback = () => {
    null;
  },
  onRowClick,
  emptyBtn = { components: <></>, emptyMsg: 'No data found' },
  isLoading = false,
  isFetchingNextPage = false,
  className = '',
  tableType,
  multiSelectActionBarProps = DEFAULT_ACTION_BAR_PROPS,
  disableMultiSelectActionsComponent = false,
  hideMultiSelectActionsComponent = false,
  testId = '',
  hideCheckboxes = false,
  onSortingChange,
  tableClasses = '',
  initialExpanded = DEFAULT_INITIAL_EXPAND,
  blockFetchingNextPage = false,
  disabledSorting = false,
  isSidePanel = false,
  'data-cy': dataCy,
  isSortable = false,
  canHideColumns = false,
  onSortOrderChange,
  getFilteredTableHeader,
  setHasTableStateChanged,
  enableColumnPinning = true,
}: TableProps) => {
  const { ref, inView } = useInView();
  useEffect(() => {
    if (inView) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useInViewCallback();
    }
  }, [inView]);

  const columnHelper = createColumnHelper<any>();
  const { nameLookup } = cryptoSymbol({});

  const { sidebarIds, openSidebar } = useContext(SidebarGlobalContext);

  const [, copy] = useCopyToClipboard();

  const defaultColumns = [
    ...tableHeader.map(({ header, key, textAligned }, index) => {
      const handleCells = (payload) => {
        const { getValue, row } = payload;

        const compare = (passedKey) => header === passedKey;
        if (key === 'impairmentQuantity' || key === 'journalEntrySequenceNumber' || key === 'impairmentRuleName')
          return <DefaultCell textAlign={textAligned} label={getValue()} withoutPaddingLeft={index === 0} />;

        if (key === 'transactionAssets' || key === 'reportCurrency') {
          const { title, assetType, img, isNFT, rawContractAddress } = getValue();
          let imageToRender = <></>;
          if (isNFT) imageToRender = img;
          else {
            const renderAssetTypeImage = assetType?.split(' ')?.length ? true : false;
            if (renderAssetTypeImage)
              return (
                <SingleLineAvatar
                  label={title}
                  src={currencyImg(assetType?.toLowerCase(), rawContractAddress)}
                  alt={img}
                  size='default'
                  withoutPaddingLeft={index === 0}
                />
              );
          }

          return (
            <div className={`flex gap-2 ${textAligned} whitespace-nowrap`}>
              <div className='w-6 h-6 flex items-center'>{imageToRender}</div>
              <p>{title}</p>
            </div>
          );
        }
        if (row.original.isRaincard && compare('Source')) {
          const title = getValue()?.title;
          return (
            <LeadingCell
              src='/raincards-logo.png'
              alt={title}
              label={title}
              bottomText=''
              textAlign={textAligned}
              withoutPaddingLeft={index === 0}
            />
          );
        }

        if (row.original.isRaincard && compare('Created')) {
          return <DefaultCell textAlign={textAligned} label={getValue()} withoutPaddingLeft={index === 0} />;
        }

        if (
          compare('Cost Basis') ||
          compare('Acquisition Price') ||
          compare('Proceeds') ||
          compare('Total realized return') ||
          compare('Long term (>1 year)') ||
          compare('Short term (<1 year)') ||
          compare('Long term (>1 year)') ||
          compare('Market value') ||
          compare('Unrealized return') ||
          compare('Total cost basis')
        ) {
          const { partnerImage, partnerName } = getValue() || {};
          const partnerText = (partnerImage && '· ' + partnerName) ?? '';
          return (
            <TwoLineNumber
              textAlign={textAligned}
              label={capitalizeFirstLetter(getValue()?.title ?? '')}
              bottomText={capitalizeFirstLetter(getValue()?.desc ?? '') + partnerText}
              withoutPaddingLeft={index === 0}
            />
          );
        }

        if (compare('Source')) {
          const { chain } = getValue() || {};

          return (
            <LeadingCell
              isAvatarVisible={currencyImg(getValue().chain?.toLowerCase()) !== ''}
              src={currencyImg(getValue().chain?.toLowerCase())}
              alt={chain}
              initials={getValue().chain?.toLowerCase() ?? 'AA'}
              label={capitalizeFirstLetter(getValue()?.title?.toLowerCase() ?? '')}
              bottomText={capitalizeFirstLetter(getValue()?.desc?.toLowerCase() ?? '')}
              textAlign={textAligned}
              isCheckboxVisible={false}
              checked={row.getIsSelected()}
              indeterminate={row.getIsSomeSelected()}
              onChange={row.getToggleSelectedHandler()}
              withoutPaddingLeft={index === 0 && !hideCheckboxes}
            />
          );
        }
        if (compare('Created') || compare('Updated') || compare('Team member')) {
          const { partnerImage, partnerName, chain } = getValue() || {};
          const partnerText = (partnerImage && '· ' + partnerName) ?? '';

          return (
            <TwoLineAvatar
              isAvatarVisible={currencyImg(getValue().chain?.toLowerCase()) !== ''}
              src={currencyImg(getValue().chain?.toLowerCase())}
              alt={chain}
              initials={getValue().chain?.toLowerCase() ?? 'AA'}
              label={getValue()?.title?.toLowerCase() ?? ''}
              bottomText={getValue()?.desc ?? '' + partnerText}
              textAlign={textAligned}
              withoutPaddingLeft={index === 0}
            />
          );
        }

        if (compare('ID')) {
          const { partnerImage, partnerName, chain } = getValue() || {};

          const showBrandedImage = chain || partnerName;

          return (
            <LeadingCell
              isAvatarVisible={!!showBrandedImage}
              src={currencyImg(getValue().chain?.toLowerCase())}
              alt={getValue()?.title}
              type={partnerImage && chain ? 'two-avatars' : 'chain'}
              secondAvatarPosition='bottom'
              secondAvatar={partnerImage}
              initials={getValue().chain?.toLowerCase() ?? 'AA'}
              label={capitalizeFirstLetter(getValue()?.title ?? '')}
              bottomText={capitalizeFirstLetter(getValue()?.desc ?? '')}
              textAlign={textAligned}
              isCheckboxVisible={false}
              checked={row.getIsSelected()}
              indeterminate={row.getIsSomeSelected()}
              onChange={row.getToggleSelectedHandler()}
              withoutPaddingLeft={
                (index === 0 && !hideCheckboxes) ||
                (index === 1 && table.getCanSomeRowsExpand()) ||
                (index === 0 && hideCheckboxes)
              }
              isFlipped={partnerImage ? true : false}
            />
          );
        }

        if (compare('Asset')) {
          const { partnerImage, chain } = getValue() || {};

          return (
            <LeadingCell
              isAvatarVisible={currencyImg(getValue().chain?.toLowerCase()) !== ''}
              src={currencyImg(getValue().chain?.toLowerCase())}
              alt={chain}
              type={partnerImage ? 'two-avatars' : 'chain'}
              secondAvatarPosition='bottom'
              secondAvatar={partnerImage}
              initials={getValue().chain?.toLowerCase() ?? 'AA'}
              label={capitalizeFirstLetter(getValue()?.title ?? '')}
              bottomText={capitalizeFirstLetter(getValue()?.desc ?? '')}
              textAlign={textAligned}
              isCheckboxVisible={false}
              checked={row.getIsSelected()}
              indeterminate={row.getIsSomeSelected()}
              onChange={row.getToggleSelectedHandler()}
              withoutPaddingLeft={index === 0 && !hideCheckboxes}
            />
          );
        }

        if (compare('Token')) {
          const token = getValue()?.toLowerCase() ?? '';
          return (
            <LeadingCell
              isAvatarVisible={currencyImg(token) !== ''}
              src={currencyImg(token)}
              alt={token}
              type={'chain'}
              secondAvatarPosition='bottom'
              initials={token ?? 'AA'}
              label={capitalizeFirstLetter(nameLookup(token.toUpperCase(), { exact: true }) ?? '')}
              bottomText={token.toUpperCase()}
              textAlign={textAligned}
              isCheckboxVisible={false}
              checked={row.getIsSelected()}
              indeterminate={row.getIsSomeSelected()}
              onChange={row.getToggleSelectedHandler()}
              withoutPaddingLeft={true}
            />
          );
        }

        if (
          compare('Asset record') ||
          compare('Original qty') ||
          compare('Qty') ||
          compare('Disposed qty') ||
          compare('Remaining qty') ||
          compare('Avg. cost basis') ||
          compare('Market value') ||
          compare('Unrealized return')
        ) {
          return <DefaultCell label={getValue()} textAlign={textAligned} />;
        }

        if (compare('Sale date') || compare('Acquired date') || compare('Acquisition Date')) {
          return <DefaultCell label={getValue()?.length ? dateConverter(getValue()) : ''} textAlign='left' />;
        }

        if (compare('Name')) {
          const { partnerImage, partnerName, chain } = getValue() || {};
          const partnerText = (partnerImage && '· ' + partnerName) ?? '';

          return (
            <LeadingCell
              isAvatarVisible={false}
              src={currencyImg(getValue().chain?.toLowerCase())}
              alt={chain}
              initials={getValue().chain?.toLowerCase() ?? 'AA'}
              label={capitalizeFirstLetter(getValue()?.title ?? '')}
              bottomText={capitalizeFirstLetter(getValue()?.desc ?? '') + partnerText}
              textAlign={textAligned}
              isCheckboxVisible={false}
              checked={row.getIsSelected()}
              indeterminate={row.getIsSomeSelected()}
              onChange={row.getToggleSelectedHandler()}
              withoutPaddingLeft={(index === 0 && !hideCheckboxes) || (index === 0 && hideCheckboxes)}
            />
          );
        }

        if (compare('Currency')) {
          return (
            <div
              className={`px-6 flex items-center ${
                textAligned === 'text-right' ? 'justify-end' : 'justify-start '
              } gap-2`}
            >
              <CurrencyFlag currency={getValue()?.currencyImg} width={30} />
              <p>{getValue()?.currencyDetials}</p>
            </div>
          );
        }
        if (compare('Invoice')) {
          const invoiceNumberAndIssueDate: { number: string; issueDate?: Date } = getValue();

          if (!invoiceNumberAndIssueDate) return null;
          const { number, issueDate } = invoiceNumberAndIssueDate;

          return (
            <div
              className={`flex items-center ${textAligned === 'text-right' ? 'justify-end' : 'justify-start '} gap-2`}
            >
              <div className={`flex flex-col ${textAligned} whitespace-nowrap`}>
                <p
                  className={`${!index ? 'font-semibold' : 'font-medium'} ${
                    compare('Created') || compare('Updated') ? 'text-base' : 'text-lg'
                  }`}
                >
                  {number}
                </p>
                <p className='text-sm'>{issueDate && dateConverter(issueDate)}</p>
              </div>
            </div>
          );
        }
        if (compare('Legal entity(s)')) {
          const legalEntities = [...new Set(getValue())];

          return (
            <div className='relative flex items-center gap-2'>
              {legalEntities.map((entity: any, idx) => (
                <div key={idx} className='flex items-center justify-between  group/legal-entity first:pl-6'>
                  <DefaultCell
                    textAlign={textAligned}
                    label={typeof entity.entityName === 'string' ? entity.entityName : ''}
                    className='group-hover/legal-entity:underline group-hover/legal-entity:underline-offset-4 px-0'
                  />
                  <span className='h-10 mx-2 absolute inset-0 right-4 top-1/2 -translate-y-1/2 hidden group-hover/legal-entity:flex justify-end z-normal'>
                    <MultipleActions
                      actions={[
                        {
                          icon: <BiSolidDockRight />,
                          onClickHandler: (e) => {
                            e.stopPropagation();
                            openSidebar('entities', { id: entity._id, primaryOrSecondary: 'primary' });
                          },
                          tooltipContent: 'View in panel',
                        },

                        {
                          icon: <GoArrowUpRight />,
                          onClickHandler: (e) => {
                            e.stopPropagation();
                            window.open(`entities/${entity._id}`, '_blank');
                          },
                          tooltipContent: 'Open new window',
                        },
                      ]}
                    />
                  </span>
                  {idx + 1 !== legalEntities.length && <>,</>}
                </div>
              ))}
            </div>
          );
        }
        if (compare('Legal entity')) {
          return (
            <div className='flex items-center justify-between relative group/legal-entity'>
              <DefaultCell
                textAlign={textAligned}
                label={getValue()?.entityName}
                className='group-hover/legal-entity:underline group-hover/legal-entity:underline-offset-4'
              />
              <span className='h-10 mx-2 absolute right-4 top-1/2 -translate-y-1/2 hidden group-hover/legal-entity:block'>
                <MultipleActions
                  actions={[
                    {
                      icon: <BiSolidDockRight />,
                      onClickHandler: (e) => {
                        e.stopPropagation();
                        openSidebar('entities', { id: row.original?.legalEntityId._id, primaryOrSecondary: 'primary' });
                      },
                      tooltipContent: 'View in panel',
                    },

                    {
                      icon: <GoArrowUpRight />,
                      onClickHandler: (e) => {
                        e.stopPropagation();
                        window.open(`entities/${row.original?.legalEntityId._id}`, '_blank');
                      },
                      tooltipContent: 'Open new window',
                    },
                  ]}
                />
              </span>
            </div>
          );
        }

        if (compare('Legal Entity')) {
          return (
            <div className='flex items-center justify-between relative group/legal-entity'>
              <DefaultCell
                textAlign={textAligned}
                label={getValue()?.entityName}
                className='group-hover/legal-entity:underline group-hover/legal-entity:underline-offset-4'
              />
              <span className='h-10 mx-2 absolute right-4 top-1/2 -translate-y-1/2 hidden group-hover/legal-entity:block'>
                <MultipleActions
                  actions={[
                    {
                      icon: <BiSolidDockRight />,
                      onClickHandler: (e) => {
                        e.stopPropagation();
                        openSidebar('entities', {
                          id: row.original?.legalEntityId._id,
                          primaryOrSecondary: 'secondary',
                        });
                      },
                      tooltipContent: 'View in panel',
                    },

                    {
                      icon: <GoArrowUpRight />,
                      onClickHandler: (e) => {
                        e.stopPropagation();
                        window.open(`entities/${row.original?.legalEntityId._id}`, '_blank');
                      },
                      tooltipContent: 'Open new window',
                    },
                  ]}
                />
              </span>
            </div>
          );
        }

        if (key === 'transactionDirection') {
          return (
            <>
              {!!getValue()?.length && (
                <StatusIndicator
                  label={getValue().toLowerCase()}
                  type={getValue().toLowerCase()}
                  className='h-fit'
                  withoutPaddingLeft={index === 0}
                />
              )}
            </>
          );
        }

        if (key === 'creditOrDebit') {
          return (
            <StatusIndicator
              label={capitalizeFirstLetter(getValue()?.toLowerCase())}
              type={getValue().toLowerCase()}
              className='h-fit'
              withoutPaddingLeft={index === 0}
            />
          );
        }
        if (compare('Type')) {
          return (
            <DefaultCell
              label={capitalizeFirstLetter(getValue()?.toLowerCase())}
              textAlign={textAligned}
              withoutPaddingLeft={index === 0}
            />
          );
        }

        if (compare('Payment method')) {
          const value = getValue();
          if (!value) return null;
          // CHAIN IS NOT WIRED UP AS WE ONLY ACCEPT USDC, BUT ITS A QUICK REFACTOR IF NEEDED IN FUTURE
          const {
            brand,
            last4,
            // chain,
            transactionHash,
          } = value;

          if (transactionHash)
            return (
              <div
                className={classNames(
                  'group relative px-6  w-[inherit]',
                  'flex items-center gap-2',
                  ' hover:cursor-pointer',
                )}
              >
                <Avatar src={currencyImg('usdc')} alt={'usdc'} type='chain' size='default' />
                {transactionHash && (
                  <p className='text-base text-zinc-900 leading-6 flex-shrink-0 truncate text-ellipsis w-[stretch]'>
                    <a
                      href={`${getTransactionExplorerLink('usdc')}/${transactionHash}`}
                      target='_blank'
                      className='hover:underline'
                    >
                      {shrink(transactionHash)}
                    </a>
                  </p>
                )}
              </div>
            );

          if (!brand || !last4) return null;

          return (
            <DefaultCell
              label={
                <div className='flex items-center gap-x-2'>
                  <PaymentMethodSymbol paymentMethodType={brand?.toLowerCase()} />

                  <span className='capitalize font-semibold'>{brand}</span>
                  <span>...{last4}</span>
                </div>
              }
              textAlign={textAligned}
            />
          );
        }
        if (compare('Status')) {
          return (
            <>
              {!!getValue()?.length && (
                <StatusIndicator
                  label={capitalizeFirstLetter(getValue()?.toLowerCase())}
                  type={getValue().toLowerCase()}
                  className='h-fit'
                  withoutPaddingLeft={index === 0}
                />
              )}
            </>
          );
        }
        if (compare('Auto post')) {
          return (
            <>
              {!!getValue()?.length && (
                <DefaultCell
                  textAlign={textAligned}
                  label={
                    compare('Auto post') ? (getValue() === 'POSTED' ? 'Enabled' : 'Disabled') : getValue().toLowerCase()
                  }
                  withoutPaddingLeft={index === 0}
                />
              )}
            </>
          );
        }
        if (
          compare('Date') ||
          compare('Received') ||
          key === 'createdAt' ||
          key === 'updatedAt' ||
          key === 'accountingDate'
        ) {
          return (
            <DefaultCell
              textAlign={textAligned}
              label={dateConverter(new Date(getValue()))}
              withoutPaddingLeft={index === 0}
            />
          );
        }
        if (compare('Created by') && getValue() && getValue().email) {
          return (
            <TwoLineAvatar
              isAvatarVisible={false}
              textAlign={textAligned}
              label={getValue().email}
              bottomText={dateConverter(row.original.createdAt)}
              withoutPaddingLeft={index === 0}
            />
          );
        }
        if (compare('Total') || key === 'grossPriceAsString') {
          return (
            <SingleLineNumber
              label={formatTableNumbers({ value: getValue() })}
              textAlign={textAligned}
              withoutPaddingLeft={index === 0}
            />
          );
        }
        if (compare('Due date')) {
          return (
            <DefaultCell textAlign={textAligned} label={dateConverter(getValue())} withoutPaddingLeft={index === 0} />
          );
        }
        if (compare('Net') || compare('Gross') || compare('Fee')) {
          return (
            <TwoLineAvatar
              label={getValue()?.value}
              isAvatarVisible={false}
              textAlign={textAligned}
              bottomText={getValue()?.amount}
              withoutPaddingLeft={index === 0}
            />
          );
        }
        if (
          compare('Total Credit') ||
          compare('Total Debit') ||
          compare('Amount') ||
          compare('Cost Basis') ||
          compare('Impaired Value') ||
          compare('Total Value Impaired') ||
          compare('Total Cost')
        )
          return (
            <TwoLineNumber
              label={formatter.format(parseFloat(getValue()))}
              bottomText=''
              textAlign={textAligned}
              withoutPaddingLeft={index === 0}
            />
          );
        if (compare('Allocation')) {
          <SingleLineNumber textAlign={textAligned} label={getValue() + '%'} withoutPaddingLeft={index === 0} />;
        }
        if (compare('Impaired Cost Basis')) {
          if (getValue() === 'N/A')
            return <SingleLineNumber textAlign={textAligned} label='N/A' withoutPaddingLeft={index === 0} />;
          return (
            <SingleLineNumber
              textAlign={textAligned}
              label={formatter.format(parseFloat(getValue()))}
              withoutPaddingLeft={index === 0}
            />
          );
        }
        if (compare('Synced')) return <DefaultCell textAlign={textAligned} label={getValue() ? 'true' : 'false'} />;
        if (compare('Category')) {
          return (
            <TwoLineIcons
              textAlign={textAligned}
              key={key}
              label={capitalizeFirstLetter(getValue()?.toLowerCase())}
              labelClassNames={index === 0 ? 'font-medium' : ''}
              withIconLeft
              withoutPaddingLeft={index === 0}
            />
          );
        }
        if (compare('Account')) {
          return (
            <LeadingCellExpandable
              label={capitalizeFirstLetter(getValue())}
              isAvatarVisible={false}
              bottomText=''
              isCheckboxVisible={false}
              checked={row.getIsSelected()}
              indeterminate={row.getIsSomeSelected()}
              onChange={row.getToggleSelectedHandler()}
              expandFn={row.getToggleExpandedHandler()}
              textAlign='text-left'
              canExpand={false}
              depth={row.depth}
              withoutPaddingLeft={index === 0}
              labelClassNames={index !== 0 ? '!font-normal text-base' : ''}
            />
          );
        }

        if (compare('Ledger account')) {
          return (
            <>
              {index === 0 ? (
                <LeadingCellExpandable
                  label={capitalizeFirstLetter(getValue())}
                  isAvatarVisible={false}
                  bottomText=''
                  isCheckboxVisible={false}
                  checked={row.getIsSelected()}
                  indeterminate={row.getIsSomeSelected()}
                  onChange={row.getToggleSelectedHandler()}
                  expandFn={row.getToggleExpandedHandler()}
                  textAlign='text-left'
                  canExpand={false}
                  depth={row.depth}
                  withoutPaddingLeft={index === 0}
                  labelClassNames={index !== 0 ? '!font-normal text-base' : ''}
                />
              ) : (
                <div className='flex items-center justify-between relative group/legal-entity'>
                  <LeadingCellExpandable
                    label={capitalizeFirstLetter(getValue())}
                    isAvatarVisible={false}
                    bottomText=''
                    isCheckboxVisible={false}
                    checked={row.getIsSelected()}
                    indeterminate={row.getIsSomeSelected()}
                    onChange={row.getToggleSelectedHandler()}
                    expandFn={row.getToggleExpandedHandler()}
                    textAlign='text-left'
                    canExpand={false}
                    depth={row.depth}
                    withoutPaddingLeft={index === 0}
                    labelClassNames={
                      index !== 0
                        ? '!font-normal text-base group-hover/legal-entity:underline group-hover/legal-entity:underline-offset-4'
                        : ''
                    }
                  />
                  <span className='h-10 mx-2 absolute right-4 top-1/2 -translate-y-1/2 hidden group-hover/legal-entity:block'>
                    <MultipleActions
                      actions={[
                        {
                          icon: <BiSolidDockRight />,
                          onClickHandler: (e) => {
                            e.stopPropagation();
                            openSidebar('ledger-accounts', {
                              id: row.original?.ledgerAccountId,
                              primaryOrSecondary: 'secondary',
                            });
                          },
                          tooltipContent: 'View in panel',
                        },

                        {
                          icon: <GoArrowUpRight />,
                          onClickHandler: (e) => {
                            e.stopPropagation();
                            window.open(`/configure/ledger-accounts/${row.original?.ledgerAccountId}`, '_blank');
                          },
                          tooltipContent: 'Open new window',
                        },
                      ]}
                    />
                  </span>
                </div>
              )}
            </>
          );
        }

        if (compare('Amount Type')) {
          return (
            <DefaultCell
              key={key}
              label={formatUnderscoreText(getValue())}
              textAlign={textAligned}
              withoutPaddingLeft={index === 0}
              className={index === 0 ? 'font-semibold' : ''}
            />
          );
        }

        if (
          compare('Ruleset name') ||
          compare('Alias') ||
          compare('Rules') ||
          compare('Created by') ||
          compare('name') ||
          compare('Email') ||
          compare('Role') ||
          compare('Usage') ||
          compare('Legal entites') ||
          compare('Ledger account') ||
          compare('Debit') ||
          compare('Credit') ||
          compare('currency') ||
          compare('entity name') ||
          compare('Company') ||
          compare('Key') ||
          compare('Value') ||
          compare('Account Name') ||
          compare('Account Type') ||
          compare('Account Number') ||
          compare('key') ||
          compare('Organization') ||
          compare('Memo') ||
          compare('Amount Type') ||
          compare('Ledger Account') ||
          compare('Seq ID') ||
          compare('Credit Or Debit') ||
          compare('Template line count') ||
          compare('id') ||
          compare('Feature') ||
          compare('Entendre') ||
          compare('Integral') ||
          compare('Bitwave') ||
          compare('Tres') ||
          compare('Tactic') ||
          compare('Coinbooks') ||
          compare('Coinsolv') ||
          compare('Cryptio')
        ) {
          return (
            <DefaultCell
              key={key}
              label={getValue()}
              textAlign={textAligned}
              withoutPaddingLeft={
                (index === 0 && !hideCheckboxes) ||
                (index === 1 && table.getCanSomeRowsExpand()) ||
                (index === 0 && isSidePanel)
              }
              className={index === 0 ? 'font-semibold' : ''}
            />
          );
        }

        if (compare('Description')) {
          return (
            <DefaultCell
              key={key}
              label={getValue()}
              textAlign={textAligned}
              withoutPaddingLeft={index === 0}
              className={`overflow-hidden text-ellipsis ${index === 0 ? 'font-semibold' : ''}`}
            />
          );
        }

        if (compare('Tag')) {
          const label = getValue()
            ?.name?.split(': ')
            .map((item) => item.toLowerCase().replace(/^./, item?.[0]?.toUpperCase()))
            .join(': ');
          return (
            <LeadingCell
              isAvatarVisible={false}
              label={label}
              bottomText={`${
                getValue()?.usage === 0
                  ? 'Not used'
                  : getValue()?.usage === 1
                    ? 'Used once'
                    : `Used ${getValue()?.usage} times`
              }`}
              textAlign={textAligned}
              isCheckboxVisible={false}
              checked={row.getIsSelected()}
              indeterminate={row.getIsSomeSelected()}
              onChange={row.getToggleSelectedHandler()}
              withoutPaddingLeft={(index === 0 && !hideCheckboxes) || (index === 0 && hideCheckboxes)}
            />
          );
        }
        if (compare('Chain')) {
          const imgIcon = currencyImg(getValue().toLowerCase());

          return (
            <LeadingCell
              isAvatarVisible={!!imgIcon}
              label={getValue().toUpperCase()}
              src={imgIcon}
              alt={getValue().toLowerCase()}
              initials={getValue().toUpperCase()}
              size='default'
              textAlign={textAligned}
              isCheckboxVisible={false}
              withoutPaddingLeft={index === 0}
            />
          );
        }
        if (key === 'quantity_remainingQuantity') {
          return (
            <TwoLineNumber
              textAlign={textAligned}
              label={getValue().remainingQuantity}
              bottomText={getValue().quantity + ' Inital'}
              withoutPaddingLeft={index === 0}
            />
          );
        }
        if (compare('Current Value')) {
          const gainLossPercentage = parseFloat(getValue().gainLossPercentage);
          const { value } = handleGainLoss(gainLossPercentage);

          return (
            <TwoLineNumber
              textAlign={textAligned}
              label={formatDollars(getValue().currentValue)}
              bottomText={value}
              bottomTextClassNames={
                parseFloat(value) > 0 ? 'text-green-600' : parseFloat(value) === 0 ? '' : 'text-red-500'
              }
            />
          );
        }
        if (compare('Address')) {
          return (
            <div className='flex items-center justify-between relative group/address'>
              <DefaultCell
                textAlign={textAligned}
                label={row.original.isRaincard ? '....' + getValue() : shortText(getValue())}
                className='group-hover/address:underline group-hover/address:underline-offset-4'
                withoutPaddingLeft={index === 0}
              />
              <span className='h-10 mx-2 absolute right-4 top-1/2 -translate-y-1/2 hidden group-hover/address:block'>
                <CopyAction copyFn={() => copy(getValue())} />
              </span>
            </div>
          );
        }
        if (compare('To') || compare('From')) {
          const textValue =
            (shortText(getValue().address) || '') +
            (shortText(getValue().exchange) || '') +
            (shortText(getValue().rain) || '');
          const content = textValue !== '[object Object]' ? textValue : '';

          return (
            <TwoLineAvatar
              label={content}
              isAvatarVisible={false}
              textAlign={textAligned}
              bottomText={getValue()?.walletType ? capitalizeFirstLetter(getValue()?.walletType) : ''}
              withoutPaddingLeft={index === 0}
            />
          );
        }
        if (compare('Tags')) {
          return (
            <div className={`${index === 0 ? '' : 'px-6'}`}>
              <>{handleTags(getValue())}</>
            </div>
          );
        }
        if (typeof getValue() === 'number') {
          return (
            <SingleLineNumber
              textAlign={textAligned}
              label={getValue()
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              withoutPaddingLeft={index === 0}
            />
          );
        }
        if (compare('Alias')) {
          return (
            <SingleLineNumber
              textAlign={textAligned}
              label={getValue() ? getValue() : 'N/A'}
              withoutPaddingLeft={index === 0}
            />
          );
        }
        if (key === 'journalSequenceNumber' || key === 'sequenceNumber') {
          return (
            <SingleLineNumber
              textAlign={textAligned}
              label={getValue() ? getValue() : 'N/A'}
              withoutPaddingLeft={index === 0}
            />
          );
        }
        if (compare('Operational transaction ID') || compare('Transaction') || compare('Journal')) {
          return getValue()?.sequenceNumber ? (
            <SingleLineNumber
              textAlign={textAligned}
              label={getValue()?.sequenceNumber ?? ''}
              withoutPaddingLeft={index === 0}
            />
          ) : (
            <SingleLineNumber textAlign={textAligned} label='N/A' withoutPaddingLeft={index === 0} />
          );
        }

        if (!isNaN(new Date(key).getTime())) {
          return (
            <div
              className={classNames(
                'flex items-center justify-between first:pl-6 w-full group/balance relative',
                'before:hover:block before:pointer-events-none before:hidden before:absolute before:inset-0 before:h-16 before:top-1/2 before:-translate-y-1/2',
              )}
            >
              <SingleLineNumber
                textAlign='text-right'
                label={getValue()}
                className='!no-underline group-hover/balance:[&_span_button]:underline'
              />
              <span className='h-10 mx-2 z-20  absolute left-4 hidden group-hover/balance:flex justify-start'>
                <SingleAction
                  icon={<BiSolidDockRight />}
                  onClickHandler={(e) => {
                    e.stopPropagation();
                    getValue().props.onClick();
                  }}
                  tooltipContent='View in panel'
                />
              </span>
            </div>
          );
        }
        if (compare('Links')) {
          const links = getValue();
          if (!links) return null;
          return <DefaultCell label={<InvoiceLinks invoice={getValue()} />} textAlign={textAligned} />;
        }
      };
      return columnHelper.accessor(key, {
        header,
        cell: (payload) => handleCells(payload),
        footer: (info) => info.column.id,
      });
    }),
  ];

  const [columns, setColumns] = useState(defaultColumns);

  useEffect(() => {
    if (hideCheckboxes) {
      setColumns([
        columnHelper.accessor('checkbox', {
          id: 'checkbox',
          header: ({ table }) => {
            return (
              <div className={`flex items-center`}>
                {table.getCanSomeRowsExpand() ? (
                  <Tooltip
                    removeTooltipContainerClassname
                    content={table.getIsAllRowsExpanded() ? 'Collapse all' : 'Expand all'}
                  >
                    <GoTriangleRight
                      onClick={(e) => {
                        e.stopPropagation();
                        table.getToggleAllRowsExpandedHandler()(e);
                      }}
                      className={clsx(
                        'rounded-lg w-6 h-6 p-1 mr-3 text-zinc-800 transition-transform',
                        'hover:bg-zinc-200',
                        {
                          'rotate-90': table.getIsAllRowsExpanded(),
                        },
                      )}
                    />
                  </Tooltip>
                ) : (
                  <div className={!isSortable ? 'w-6' : ''}></div>
                )}
              </div>
            );
          },
          cell: ({ row }) => (
            <div className='flex gap-3 justify-center items-center'>
              {row.getCanExpand() ? (
                <Tooltip content='Expand' removeTooltipContainerClassname>
                  <GoTriangleRight
                    onClick={(e) => {
                      e.stopPropagation();
                      row.getToggleExpandedHandler()();
                    }}
                    className={clsx('rounded-lg w-6 h-6 p-1 text-zinc-800 transition-transform', 'hover:bg-zinc-200', {
                      'rotate-90': row.getIsExpanded(),
                    })}
                  />
                </Tooltip>
              ) : table.getCanSomeRowsExpand() ? (
                <div className='opacity-0 pointer-events-none'>
                  <Tooltip content='Expand' removeTooltipContainerClassname>
                    <GoTriangleRight
                      onClick={(e) => {
                        e.stopPropagation();
                        row.getToggleExpandedHandler()();
                      }}
                      className={clsx(
                        'rounded-lg w-6 h-6 p-1 text-zinc-800 transition-transform',
                        'hover:bg-zinc-200',
                        {
                          'rotate-90': row.getIsExpanded(),
                        },
                      )}
                    />
                  </Tooltip>
                </div>
              ) : null}
            </div>
          ),
          footer: (info) => info.column.id,
        }),
        ...defaultColumns,
      ]);
      return;
    }
    setColumns([
      columnHelper.accessor('checkbox', {
        id: 'checkbox',
        header: ({ table }) => {
          return (
            <div className={`flex items-center`}>
              {table.getCanSomeRowsExpand() && (
                <Tooltip
                  removeTooltipContainerClassname
                  content={table.getIsAllRowsExpanded() ? 'Collapse all' : 'Expand all'}
                >
                  <GoTriangleRight
                    onClick={(e) => {
                      e.stopPropagation();
                      table.getToggleAllRowsExpandedHandler()(e);
                    }}
                    className={clsx(
                      'rounded-lg w-6 h-6 p-1 mr-3 text-zinc-800 transition-transform',
                      'hover:bg-zinc-200',
                      {
                        'rotate-90': table.getIsAllRowsExpanded(),
                      },
                    )}
                  />
                </Tooltip>
              )}
              <IndeterminateCheckbox
                isHeader
                checked={table.getIsAllRowsSelected()}
                indeterminate={table.getIsSomeRowsSelected()}
                onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
              />
            </div>
          );
        },
        cell: ({ row }) => (
          <div className='flex gap-3 justify-center items-center'>
            {row.getCanExpand() ? (
              <Tooltip content='Expand' removeTooltipContainerClassname>
                <GoTriangleRight
                  onClick={(e) => {
                    e.stopPropagation();
                    row.getToggleExpandedHandler()();
                  }}
                  className={clsx('rounded-lg w-6 h-6 p-1 text-zinc-800 transition-transform', 'hover:bg-zinc-200', {
                    'rotate-90': row.getIsExpanded(),
                  })}
                />
              </Tooltip>
            ) : table.getCanSomeRowsExpand() ? (
              <div className='opacity-0 pointer-events-none'>
                <Tooltip content='Expand' removeTooltipContainerClassname>
                  <GoTriangleRight
                    onClick={(e) => {
                      e.stopPropagation();
                      row.getToggleExpandedHandler()();
                    }}
                    className={clsx('rounded-lg w-6 h-6 p-1 text-zinc-800 transition-transform', 'hover:bg-zinc-200', {
                      'rotate-90': row.getIsExpanded(),
                    })}
                  />
                </Tooltip>
              </div>
            ) : null}
            <IndeterminateCheckbox
              checked={row.getIsSelected()}
              indeterminate={row.getIsSomeSelected()}
              onChange={row.getToggleSelectedHandler()}
            />
          </div>
        ),
        footer: (info) => info.column.id,
      }),
      ...defaultColumns,
    ]);
  }, [tableHeader]);

  const [sorting, setSorting] = useState<SortingState>([]);
  const [hoveredHeader, setHoveredHeader] = useState<string | null>(null);

  const [expanded, setExpanded] = useState(initialExpanded ?? {});
  const [rowSelection, setRowSelection] = React.useState({});

  const [data, setData] = useState(() => [...tableData.Data]);

  useEffect(() => {
    // This part is important we used it to refreash the table data we can't remove it
    setData(tableData.Data);
  }, [tableData]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      expanded,
      rowSelection,
    },
    defaultColumn: {
      minSize: 0,
    },
    enableColumnResizing: true,
    columnResizeMode: 'onChange',
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.subRows,
    getExpandedRowModel: getExpandedRowModel(),
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onRowSelectionChange: setRowSelection,
    manualSorting: onSortingChange && true,
  });

  useEffect(() => {
    if (onSortingChange) {
      onSortingChange(sorting);
    }
  }, [sorting]);

  const [sortableState, setSortableState] = useState<any[]>([]);

  useEffect(() => {
    if (onSortOrderChange) onSortOrderChange(sortableState.map((row) => row.original));
  }, [sortableState]);

  useEffect(() => {
    setSortableState(table.getRowModel().rows);
  }, [table.getRowModel().rows]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  function handleDragEnd(event: DragEndEvent) {
    const { active, collisions } = event;
    if (!collisions) return;
    if (active.id !== collisions[0].id) {
      setSortableState((items) => {
        const oldIndex = items.findIndex((row) => row.original._id === active.id);
        const newIndex = items.findIndex((row) => row.original._id === collisions[0].id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
    setActiveId(null);
  }

  const lastOverId = useRef<UniqueIdentifier | null>(null);

  const onDragMove = (event: DragMoveEvent) => {
    const { active, collisions } = event;

    if (!collisions) return;

    if (lastOverId.current === collisions[0].id) return;

    lastOverId.current = collisions[0].id;

    if (active.id !== collisions[0].id) {
      setSortableState((items) => {
        const oldIndex = items.findIndex((row) => row.original._id === active.id);
        const newIndex = items.findIndex((row) => row.original._id === collisions[0].id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  const SortableRowComponent = ({ row, dragActive = false }) => {
    let selectedRow = false;
    if (row.original._id) {
      selectedRow = sidebarIds.includes(row.original._id);
    }

    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: row.original._id });

    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
    };

    return (
      <tr id={dragActive ? `${row.original._id}_draggable` : row.original._id} ref={setNodeRef} style={style}>
        <td
          className={classNames(
            'w-12 !p-0 z-[1] left-0 !sticky min-w-[24px]',
            row.getIsSelected() && 'bg-[#f8f8f8]',
            dragActive && '!z-normal',
            !enableColumnPinning && '!relative',
          )}
        >
          <button className='w-full h-full flex items-center justify-center' {...listeners} {...attributes}>
            <MdDragIndicator className='text-zinc-400' />
          </button>
        </td>
        {row.getVisibleCells().map((cell, idx) => {
          const index = hideCheckboxes ? idx : idx - 1;
          let childWidth: any;
          if (dragActive) {
            childWidth = document
              ?.getElementById(`${row.original._id}`)
              ?.children[idx + 1].getBoundingClientRect().width;
          }
          return (
            <td
              key={cell.id}
              onClick={() => {
                cell.row.getToggleExpandedHandler();
                if (cell.column.columnDef.header !== 'Account' && onRowClick) {
                  // setScrollPosition(window.scrollY);
                  onRowClick(row, cell);
                }
              }}
              data-testid='tbody__td'
              className={classNames(
                // cell.row.depth && 'bg-[#F8F8F8] whitespace-nowrap ml-7.5',
                row.getIsSelected() && 'bg-[#f8f8f8] text-[#111111]',
                tableHeader[index]?.type === 'fixedRight' && 'sticky-table fixedRight',
                tableHeader[index]?.type === 'fixedLeft' && 'sticky-table fixedLeft',
                selectedRow && 'bg-[#F4F4FE]',
                selectedRow && !idx && '!bg-indigo-100 before:!block border-b-2 border-b-zinc-300',
                row?.original?.__className,
                cell.column.id === 'checkbox' &&
                  enableColumnPinning &&
                  !isSortable &&
                  '!p-0 !z-[1] left-12 !sticky min-w-[55px]',
                cell.column.id === 'checkbox' &&
                  enableColumnPinning &&
                  isSortable &&
                  '!p-0 !z-[2] left-6 !sticky min-w-[55px]',
                index === 0 &&
                  isSortable &&
                  enableColumnPinning &&
                  !hideCheckboxes &&
                  '!p-0 !z-[1] left-[79px] !sticky',
                !dragActive && enableColumnPinning && 'sticky',
                isSortable && !enableColumnPinning && index !== -1 && 'pl-0',
              )}
              style={{
                left: fixedTableColumn.left(tableHeader[index]?.type, idx),
                right: fixedTableColumn.right(tableHeader[index]?.type, idx, row.getVisibleCells().length),
                width: dragActive ? childWidth : '',
              }}
            >
              {index === 1 ? (
                <span
                  style={{
                    boxShadow:
                      isScrollable && enableColumnPinning && !isLoading
                        ? '0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)'
                        : '',
                  }}
                  className='flex justify-between items-center w-full h-full relative'
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  {onRowClick ? (
                    <span className='h-10 w-[90px] mx-2 absolute top-1/2 -translate-y-1/2 right-0'>
                      <Tooltip content='View in panel' removeTooltipContainerClassname>
                        <Button
                          onClick={() => {
                            // setScrollPosition(window.scrollY);
                            onRowClick(row, cell);
                          }}
                          className='viewBtn !hidden'
                          label='View'
                          emphasis='medium'
                          leadingIcon={<BiSolidDockRight className='w-5 h-5' />}
                        />
                      </Tooltip>
                    </span>
                  ) : null}
                </span>
              ) : (
                <>{flexRender(cell.column.columnDef.cell, cell.getContext())}</>
              )}
            </td>
          );
        })}
      </tr>
    );
  };

  const MemoizedSortableRowComponent = React.memo(SortableRowComponent);
  const [activeId, setActiveId] = useState(null);
  const sortableRows = React.useMemo(() => {
    return sortableState.map((row) => {
      const dragActive = activeId === row.original._id;
      return <MemoizedSortableRowComponent key={row.original._id} row={row} dragActive={dragActive} />;
    });
  }, [sortableState, activeId]);

  function handleDragStart(event) {
    setActiveId(event.active.id);
  }

  const { setActionBarVisibleForTableType, setPropsForTableType, getPropsForTableType } = useTableActionBarContext();

  // set the table type for which the app should show action bar
  useEffect(() => {
    setActionBarVisibleForTableType(tableType);
    return;
  }, [tableType]);

  // set selectedRows for the action bar
  useEffect(() => {
    // set the current table's action bar as the visible action bar if the selected rows changed
    // only if
    // 1. there is an action bar defined for the table
    // 2. the number of selected rows for the table is greater than 0

    // check if there is an action bar defined for the table
    if (tableType) {
      // get previous props for action bar
      const prevProps = getPropsForTableType(tableType);

      // calculate current selected rows count
      const selectedRowsLength = table.getSelectedRowModel().rows.length;

      // update visible action bar table type if needed
      if (selectedRowsLength > 0 && prevProps?.selectedRows?.length !== selectedRowsLength)
        setActionBarVisibleForTableType(tableType);

      // update props for action bar
      setPropsForTableType(tableType, { selectedRows: table.getSelectedRowModel().rows, table });
    }
  }, [tableType, rowSelection, table.getSelectedRowModel]);

  // set other props passed from table container component, (for eg. page) for the action bar
  useEffect(() => {
    if (tableType) {
      setPropsForTableType(tableType, {
        ...multiSelectActionBarProps,
        disabled: disableMultiSelectActionsComponent,
        hidden: hideMultiSelectActionsComponent,
        toggleAllRowsSelected: table.toggleAllRowsSelected,
      });
    }
  }, [tableType, multiSelectActionBarProps, disableMultiSelectActionsComponent, hideMultiSelectActionsComponent]);

  // deselect all rows when table is unmounted, hide action bar if visible
  useEffect(
    () => () => {
      table.toggleAllRowsSelected(false);
      if (tableType) setPropsForTableType(tableType, { hidden: true, activeFn: table.toggleAllRowsSelected });
    },
    [table.toggleAllPageRowsSelected, tableType],
  );

  const [isScrollable, setIsScrollable] = useState(false);
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const tableRef = useRef<HTMLTableElement>(null);

  const handleScroll = () => {
    const scrollLeft = tableContainerRef.current?.scrollLeft;

    if (scrollLeft === 0) {
      setIsScrollable(false);
    } else {
      setIsScrollable(true);
    }
  };

  return (
    <div
      ref={tableContainerRef}
      onScroll={handleScroll}
      className={classNames(
        'overflow-auto flex-auto mt-4 col-span-full rounded-xl !z-0',
        className,
        !tableData.Data.length && !isLoading && 'flex',
        (!isSidePanel || tableData.Data.length || isLoading) && 'border border-[#CBCBCB]',
        activeId && 'overflow-x-hidden',
      )}
    >
      {!tableData.Data.length && !isLoading ? (
        <div className='w-full flex flex-col'>
          {tableTopActions && (
            <div
              className={classNames('p-3', tableTopActionsContainerClassname)}
              data-cy={`${dataCy}__table_topActionsContainer`}
            >
              {tableTopActions}
            </div>
          )}
          <div className='w-full flex flex-col gap-5 py-4 justify-center items-center flex-grow'>
            <p data-cy='table_emptyMessage' className='text-2xl font-medium'>
              {emptyBtn.emptyMsg}
            </p>
            {emptyBtn.components}
          </div>
        </div>
      ) : (
        <>
          <StyledTable />
          {tableTopActions && (
            <div
              className={classNames('sticky z-[1] left-0 p-3', tableTopActionsContainerClassname)}
              data-cy={`${dataCy}__table_topActionsContainer`}
            >
              {tableTopActions}
            </div>
          )}
          <table
            ref={tableRef}
            data-testid={testId}
            className={tableClasses}
            style={{
              minWidth: '100%',
              width: table.getCenterTotalSize(),
            }}
          >
            <thead data-cy={`${dataCy}__table_head`}>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {isSortable && (
                    <th
                      className={classNames('bg-[#f2f2f2] left-0 sticky z-[5]', !enableColumnPinning && 'relative')}
                    />
                  )}
                  {headerGroup.headers.map((header, idx) => {
                    const index = hideCheckboxes ? idx : idx - 1;
                    if (header.id === 'checkbox') {
                      return (
                        <th
                          key={idx}
                          {...{
                            colSpan: header.colSpan,
                            onClick: !disabledSorting ? header.column.getToggleSortingHandler() : () => {},
                          }}
                          className={`h-12 !p-0 ${
                            tableHeader[index]?.textAligned ? tableHeader[index].textAligned : 'text-center'
                          }
                          ${tableHeader[index]?.__className}
                          ${tableHeader[index]?.type === 'fixedRight' && 'sticky-table bg-[#f4f4f5] fixedRight z-[5]'}
                          ${tableHeader[index]?.type === 'fixedLeft' && 'sticky-table bg-[#f4f4f5] fixedLeft z-[5]'}
                          ${
                            header.id === 'checkbox' &&
                            enableColumnPinning &&
                            '!p-0 w-6 sticky fixedLeft !bg-[#f4f4f5] z-[5]'
                          }
                          ${!hideCheckboxes ? 'min-w-[56px]' : 'min-w-6'}
                          ${!isSortable && header.id === 'checkbox' && 'left-0'}
                          ${index === 1 && !hideCheckboxes && 'left-[55px]'}
                          !border-b-zinc-200 !border-b 
                          `}
                          style={{
                            left: 0,
                            right: fixedTableColumn.right(tableHeader[index]?.type, idx, headerGroup.headers.length),
                          }}
                        >
                          <div className={`flex justify-center ${table.getCanSomeRowsExpand() ? 'pl-4' : 'pl-0'}`}>
                            {flexRender(header.column.columnDef.header, header.getContext())}
                          </div>
                        </th>
                      );
                    }

                    if (
                      header.id !== 'checkbox' &&
                      (tableHeader.find((headerItem) => headerItem.key === header.id)?.textAligned === 'text-right' ||
                        !isNaN(new Date(header.id).getTime()))
                    ) {
                      return (
                        <th
                          key={idx}
                          {...{
                            colSpan: header.colSpan,
                          }}
                          className={`h-12 bg-zinc-100 !p-0  ${
                            tableHeader[index]?.textAligned
                              ? tableHeader[index].textAligned
                              : 'text-center' && header.id !== 'viewBtn'
                          }
                            ${tableHeader[index]?.__className}
                            ${tableHeader[index]?.type === 'fixedRight' && 'sticky-table bg-[#f2f2f2] fixedRight z-[5]'}
                            ${
                              (tableHeader[index]?.type === 'fixedLeft' || idx === 1) &&
                              enableColumnPinning &&
                              'sticky-table bg-[#f4f4f5] fixedLeft z-[5]'
                            }
                            ${header.id === 'checkbox' && '!p-0 w-12 sticky fixedLeft !bg-[#F2F2F2] z-[5]'}
                            ${isSortable && !enableColumnPinning && header.id === 'checkbox' ? 'left-6' : 'left-0'}
                            !border-b-zinc-200 !border-b 
                           
                            `}
                          style={{
                            left:
                              idx === 1
                                ? hideCheckboxes
                                  ? 16
                                  : 56
                                : fixedTableColumn.left(tableHeader[index]?.type, idx),
                            right: fixedTableColumn.right(tableHeader[index]?.type, idx, headerGroup.headers.length),
                            minWidth: header.getSize(),
                          }}
                          onMouseEnter={() => setHoveredHeader(header.id)}
                          onMouseLeave={() => setHoveredHeader(null)}
                        >
                          {header.id !== 'viewBtn' && (
                            <NumberHeader
                              sort={header.column.getIsSorted()}
                              label={flexRender(header.column.columnDef.header, header.getContext())?.toString() ?? ''}
                              resetSorting={() => header.column.clearSorting()}
                              enableHide={canHideColumns}
                              enableSort={!disabledSorting}
                              containerClassNames={
                                (index === 0 && hideCheckboxes) || (!hideCheckboxes && index === 0) ? 'pr-[128px]' : ''
                              }
                              sortFn={
                                !disabledSorting
                                  ? (value: boolean | undefined) => header.column.toggleSorting(value)
                                  : () => {}
                              }
                              hideFn={() => {
                                //get the header from tableHeader by key
                                const headerToUpdate = tableHeader.findIndex((item) => item.key === header.id);

                                const newTableHeader = [...tableHeader];
                                newTableHeader.splice(headerToUpdate, 1);

                                if (typeof getFilteredTableHeader === 'function') {
                                  getFilteredTableHeader(newTableHeader);
                                }
                                if (typeof setHasTableStateChanged !== 'undefined') {
                                  setHasTableStateChanged((prev) => !prev);
                                }
                              }}
                            />
                          )}

                          <div
                            {...{
                              onMouseDown: header.getResizeHandler(),
                              onTouchStart: header.getResizeHandler(),
                              className: `resizer !h-[calc(100%_+_1px)] ${
                                header.column.getIsResizing() ? 'isResizing !bg-blue-700' : ''
                              }`,
                            }}
                          />
                        </th>
                      );
                    }

                    return (
                      <th
                        key={idx}
                        {...{
                          colSpan: header.colSpan,
                        }}
                        className={`h-12 !p-0 bg-zinc-100 ${
                          tableHeader[index]?.textAligned ? tableHeader[index].textAligned : 'text-center'
                        }
                          ${tableHeader[index]?.__className}
                          ${
                            tableHeader[index]?.type === 'fixedRight' &&
                            enableColumnPinning &&
                            'sticky-table bg-[#f2f2f2] fixedRight z-[5]'
                          }
                          ${
                            (tableHeader[index]?.type === 'fixedLeft' || idx === 1) &&
                            enableColumnPinning &&
                            'sticky-table bg-[#f4f4f5] fixedLeft z-[5]'
                          }
                          ${
                            header.id === 'checkbox' &&
                            enableColumnPinning &&
                            '!p-0 w-12 sticky fixedLeft !bg-[#F2F2F2] z-[5]'
                          }
                          ${isSortable && enableColumnPinning && header.id === 'checkbox' ? 'left-6' : 'left-0'}
                          !border-b-zinc-200 !border-b 
                          ${
                            index === 1 &&
                            hideCheckboxes &&
                            enableColumnPinning &&
                            !table.getCanSomeRowsExpand() &&
                            'sticky !left-6 !z-normal'
                          }
                          ${
                            index === 1 &&
                            hideCheckboxes &&
                            enableColumnPinning &&
                            table.getCanSomeRowsExpand() &&
                            'sticky !left-[52px] !z-[5]'
                          }
                          ${
                            index === 0 &&
                            !hideCheckboxes &&
                            enableColumnPinning &&
                            !isSortable &&
                            'sticky !left-[55px] !z-[5]'
                          }
                          ${
                            index === 0 &&
                            !hideCheckboxes &&
                            enableColumnPinning &&
                            isSortable &&
                            'sticky !left-[79px] !z-[5]'
                          }
                          `}
                        style={{
                          //left: fixedTableColumn.left(tableHeader[index]?.type, idx),
                          right: fixedTableColumn.right(tableHeader[index]?.type, idx, headerGroup.headers.length),
                          minWidth: header.getSize(),
                        }}
                        onMouseEnter={() => setHoveredHeader(header.id)}
                        onMouseLeave={() => setHoveredHeader(null)}
                      >
                        {header.id !== 'checkbox' && header.id !== 'viewBtn' && (
                          <DefaultTableHeader
                            isHeader
                            isCheckboxVisible={false}
                            sort={header.column.getIsSorted()}
                            label={flexRender(header.column.columnDef.header, header.getContext())?.toString() ?? ''}
                            checked={table.getIsAllRowsSelected()}
                            indeterminate={table.getIsSomeRowsSelected()}
                            onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
                            enableHide={canHideColumns}
                            enableSort={!disabledSorting}
                            withoutPaddingLeft={
                              (index === 0 && !hideCheckboxes) ||
                              (index === 1 && table.getCanSomeRowsExpand()) ||
                              (index === 1 && hideCheckboxes)
                            }
                            sortFn={
                              !disabledSorting
                                ? (value: boolean | undefined) => header.column.toggleSorting(value)
                                : () => {}
                            }
                            resetSorting={() => header.column.clearSorting()}
                            hideFn={() => {
                              //get the header from tableHeader by key
                              const headerToUpdate = tableHeader.findIndex((item) => item.key === header.id);

                              const newTableHeader = [...tableHeader];
                              newTableHeader.splice(headerToUpdate, 1);

                              if (typeof getFilteredTableHeader === 'function') {
                                getFilteredTableHeader(newTableHeader);
                              }

                              if (typeof setHasTableStateChanged !== 'undefined') {
                                setHasTableStateChanged((prev) => !prev);
                              }
                            }}
                          />
                        )}
                        <div
                          {...{
                            onMouseDown: header.getResizeHandler(),
                            onTouchStart: header.getResizeHandler(),
                            className: `resizer !h-[calc(100%_+_1px)] ${
                              header.column.getIsResizing() ? 'isResizing !bg-blue-700' : ''
                            }`,
                          }}
                        />
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody className={'z-[2]'} data-cy={`${dataCy}__table_body`}>
              {isLoading ? (
                <tr>
                  <td className='pt-0 px-0 pb-0 !bg-white overflow-hidden' colSpan={tableHeader.length + 2}>
                    <TableSkeletonLoader />
                  </td>
                </tr>
              ) : isSortable ? (
                <DndContext
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragEnd={handleDragEnd}
                  onDragStart={handleDragStart}
                  modifiers={[restrictToVerticalAxis]}
                  onDragMove={onDragMove}
                  measuring={{
                    droppable: {
                      strategy: MeasuringStrategy.Always,
                    },
                    draggable: {
                      measure: (node) => {
                        return node.getBoundingClientRect();
                      },
                    },
                  }}
                >
                  <SortableContext items={sortableState} strategy={verticalListSortingStrategy}>
                    {sortableRows}
                    <DragOverlay zIndex={2}>
                      {activeId ? (
                        <MemoizedSortableRowComponent
                          dragActive
                          row={sortableState.find((row) => row.original._id === activeId)}
                        />
                      ) : null}
                    </DragOverlay>
                  </SortableContext>
                </DndContext>
              ) : (
                <>
                  {table.getRowModel().rows.map((row) => {
                    let selectedRow = false;
                    if (row.original._id) {
                      selectedRow = sidebarIds.includes(row.original._id);
                    }

                    return (
                      <tr id={row.original._id} key={row.id} className=' group '>
                        {row.getVisibleCells().map((cell, idx) => {
                          const index = hideCheckboxes ? idx : idx - 1;
                          return (
                            <td
                              key={cell.id}
                              onClick={() => {
                                cell.row.getToggleExpandedHandler();
                                if (cell.column.columnDef.header !== 'Account' && onRowClick) {
                                  // setScrollPosition(window.scrollY);
                                  onRowClick(row, cell);
                                }
                              }}
                              data-cy={`${cell.column.id}__table_body_item`}
                              data-testid='tbody__td'
                              className={classNames(
                                clsx(
                                  'h-16 p-0 whitespace-nowrap relative max-w-sm overflow-hidden',
                                  { '!bg-indigo-50 !text-[#111111]': row.getIsSelected() },
                                  (tableHeader[index]?.type === 'fixedRight' || idx === 1) &&
                                    enableColumnPinning &&
                                    'sticky-table fixedRight',
                                  { '!bg-indigo-100 border-top-none border-b-2 border-b-zinc-300': selectedRow },
                                  {
                                    '!bg-indigo-100 before:!block border-b-2 border-b-zinc-300': selectedRow && !idx,
                                  },
                                  row?.original?.__className,
                                  {
                                    '!p-0 !sticky min-w-[none] z-[5]':
                                      cell.column.id === 'checkbox' && enableColumnPinning,
                                  },
                                  { 'left-6': isSortable, 'left-0': !isSortable },
                                  index === 1 && hideCheckboxes && enableColumnPinning && '!sticky !left-6 z-[5]',
                                  index === 1 &&
                                    hideCheckboxes &&
                                    enableColumnPinning &&
                                    table.getCanSomeRowsExpand() &&
                                    '!sticky !left-[52px] z-[5]',
                                  index === 0 &&
                                    !hideCheckboxes &&
                                    enableColumnPinning &&
                                    !isSortable &&
                                    '!sticky !left-[56px] z-[5]',
                                  index === 0 &&
                                    !hideCheckboxes &&
                                    enableColumnPinning &&
                                    isSortable &&
                                    '!sticky !left-[79px] !z-[5]',
                                ),
                              )}
                              style={{
                                right: fixedTableColumn.right(
                                  tableHeader[index]?.type,
                                  idx,
                                  row.getVisibleCells().length,
                                ),
                                minWidth: cell.column.id === 'checkbox' ? '' : cell.column.getSize(),
                              }}
                            >
                              <div
                                className={`w-[5px] top-0 bottom-[-1px] absolute right-0 ${
                                  cell.getContext().column.getIsResizing() ? 'bg-blue-700' : 'bg-transparent'
                                }
                                ${hoveredHeader === cell.column.id && '!bg-gray-500'}
                                
                                `}
                              />
                              {idx === 1 ? (
                                <div
                                  style={{
                                    boxShadow:
                                      idx === 1 && isScrollable && enableColumnPinning && !isLoading
                                        ? '0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)'
                                        : '',
                                  }}
                                  className='flex justify-between items-center w-full h-full'
                                >
                                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                  {onRowClick ? (
                                    <Tooltip content='View in panel' removeTooltipContainerClassname>
                                      <div className='h-10 w-[90px] mx-2 absolute top-1/2 right-0 -translate-y-1/2'>
                                        <Button
                                          onClick={() => {
                                            // setScrollPosition(window.scrollY);
                                            onRowClick(row, cell);
                                          }}
                                          className='viewBtn !hidden'
                                          label='View'
                                          emphasis='medium'
                                          leadingIcon={<BiSolidDockRight className='w-5 h-5' />}
                                        />
                                      </div>
                                    </Tooltip>
                                  ) : null}
                                </div>
                              ) : (
                                <>{flexRender(cell.column.columnDef.cell, cell.getContext())}</>
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </>
              )}
            </tbody>
          </table>
        </>
      )}

      {!blockFetchingNextPage && !!tableData?.Data.length && (
        <>
          <div ref={ref} />
          {isFetchingNextPage && (
            <div className='w-full flex justify-center items-center my-8'>
              <LoaderIcon size={LOADER_ICON_SIZE.LARGE} />
            </div>
          )}
        </>
      )}
    </div>
  );
};
