import { UserInLocalStorage } from '../components/types';
import { OrganizationMembers, Organization } from 'schemas';
import { createContext, useEffect, useLayoutEffect, useReducer } from 'react';
import { useRouter } from 'next/router';
import { produce } from 'immer';
// import { getAllMembershipsByMemberId } from 'services/http';
export type OrganizationWithBillingInfo = Organization & {
  lastBillingInfoUpdateAt?: string; // @todo akshay explain how this works https://github.com/EntendreFinance/Entendre/pull/1818
};
export interface SessionState {
  organizationId: string;
  userId: string;
  user?: UserInLocalStorage;
  token: string;
  selectedOrganization: OrganizationWithBillingInfo;
  selectedMembership: OrganizationMembers;
  memberships: OrganizationMembers[];
  organizations: OrganizationWithBillingInfo[];
}

const defaultSessionState: SessionState = {
  organizationId: '',
  userId: '',
  token: '' as string,
  selectedOrganization: {} as OrganizationWithBillingInfo,
  selectedMembership: {} as OrganizationMembers,
  memberships: [] as OrganizationMembers[],
  organizations: [] as OrganizationWithBillingInfo[],
};

const defualtSessionContextState = {
  ...defaultSessionState,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  setSessionData: (session?: Pick<SessionState, 'memberships' | 'user' | 'organizationId'> | undefined) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  updateSessionMemberships: (memberships: OrganizationMembers[]) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  updateSessionUser: (user: UserInLocalStorage) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  updateSessionToken: (token: string) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  updateSelectedOrganizationId: (organizationId: string) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  updateSelectedOrganizationTimezone: (organizationId: string, timezone: string) => {},
  clearSession: () => {},
};

enum ACTION_TYPE {
  SET = 'SET',
  CLEAR = 'CLEAR',
  SET_SELECTED_ORGANIZATION = 'SET_SELECTED_ORGANIZATION',
  SET_USER = 'SET_USER',
  SET_TOKEN = 'SET_TOKEN',
  SET_MEMBERSHIPS = 'SET_MEMBERSHIPS',
  SET_ORGANIZATIONS = 'SET_ORGANIZATIONS',
  SET_SELECTED_ORGANIZATION_TIMEZONE = 'SET_SELECTED_ORGANIZATION_TIMEZONE',
}

const reducer = (state: SessionState, action: { type: ACTION_TYPE; payload?: any }) => {
  // console.log('SessionState reducer', action);
  switch (action.type) {
    case ACTION_TYPE.SET:
      return produce(state, (draft) => {
        if (action.payload) {
          if (action.payload.memberships) {
            draft.memberships = action.payload.memberships;
            draft.organizations = action.payload.memberships.map(
              (membership) => membership.organizationId as Organization,
            );
          }
          if (action.payload.organizationId) {
            draft.organizationId = action.payload.organizationId;
            draft.selectedMembership = draft.memberships.find(
              (membership) => (membership.organizationId as Organization)._id === action.payload.organizationId,
            ) as OrganizationMembers;
            draft.selectedOrganization = draft.organizations.find(
              (organization) => organization._id === action.payload.organizationId,
            ) as Organization;
          }
          if (action.payload.user) {
            draft.user = action.payload.user;
            draft.token = action.payload.user.jwt;
            draft.userId = action.payload.user._id;
          }
          if (!draft.organizationId.length && draft.memberships.length) {
            draft.organizationId = (draft.memberships[0].organizationId as Organization)._id;
          }
        } else {
          draft = defaultSessionState;
        }
      });
    case ACTION_TYPE.CLEAR:
      localStorage.removeItem('session');
      return defaultSessionState;
    case ACTION_TYPE.SET_SELECTED_ORGANIZATION:
      return produce(state, (draft) => {
        draft.organizationId = action.payload;
        const selectedMembership =
          draft.memberships.find((membership) => (membership.organizationId as Organization)._id === action.payload) ||
          ({} as OrganizationMembers);
        draft.selectedMembership = selectedMembership;
        draft.selectedOrganization = selectedMembership.organizationId as Organization;
      });
    case ACTION_TYPE.SET_USER:
      return produce(state, (draft) => {
        draft.user = action.payload;
        if (action.payload._id) draft.userId = action.payload._id;
      });
    case ACTION_TYPE.SET_TOKEN:
      return produce(state, (draft) => {
        draft.token = action.payload;
      });
    case ACTION_TYPE.SET_MEMBERSHIPS:
      return produce(state, (draft) => {
        draft.memberships = action.payload;
        draft.organizations = action.payload.map((membership) => membership.organizationId as Organization);
        if (!draft.organizationId.length && draft.memberships.length) {
          draft.organizationId = (draft.memberships[0].organizationId as Organization)._id;
          draft.selectedOrganization = action.payload.find(
            (membership) => membership.organizationId._id === draft.organizationId,
          ).organizationId;
        }
      });
    case ACTION_TYPE.SET_ORGANIZATIONS:
      return produce(state, (draft) => {
        draft.organizations = action.payload;
        if (!draft.organizationId.length && draft.organizations.length) {
          draft.organizationId = draft.organizations[0]._id;
        }
      });
    case ACTION_TYPE.SET_SELECTED_ORGANIZATION_TIMEZONE:
      return produce(state, (draft) => {
        if (!draft.organizationId !== action.payload.organizationId) return;
        draft.organizations = draft.organizations.map((org) => {
          if (org._id === action.payload.organizationId) return org;
          return { ...org, timezone: action.payload.timezone };
        });
      });
  }
};

export const SessionContext = createContext(defualtSessionContextState);

const parseSession = () => {
  try {
    const sessionString = localStorage.getItem('session');
    if (!sessionString) return undefined;
    return JSON.parse(sessionString);
  } catch {
    return undefined;
  }
};

export const SessionProvider = ({ children }) => {
  const [session, dispatch] = useReducer(reducer, defaultSessionState);
  const router = useRouter();

  // useEffect to set session to persisted value
  useLayoutEffect(() => {
    // console.log('Checking persisted session on page:', router.pathname);
    const localSession = parseSession();
    if (localSession) dispatch({ type: ACTION_TYPE.SET, payload: localSession });
    else {
      dispatch({ type: ACTION_TYPE.CLEAR });
    }
  }, [router.pathname]);

  // useEffect(() => {
  //   console.log('session', session);
  // }, [session]);

  // useEffect to update localstorage
  useEffect(() => {
    // console.log('Persisting session', session);
    localStorage.setItem('session', JSON.stringify(session));
  }, [session, router.pathname]);

  const updateSessionMemberships = (memberships: OrganizationMembers[]) =>
    dispatch({ type: ACTION_TYPE.SET_MEMBERSHIPS, payload: memberships });

  const updateSessionUser = (user: UserInLocalStorage) => dispatch({ type: ACTION_TYPE.SET_USER, payload: user });

  const updateSessionToken = (token: string) => dispatch({ type: ACTION_TYPE.SET_TOKEN, payload: token });

  const updateSelectedOrganizationId = (organizationId: string) =>
    dispatch({ type: ACTION_TYPE.SET_SELECTED_ORGANIZATION, payload: organizationId });

  const updateSelectedOrganizationTimezone = (organizationId: string, timezone: string) =>
    dispatch({ type: ACTION_TYPE.SET_SELECTED_ORGANIZATION_TIMEZONE, payload: { organizationId, timezone } });

  const clearSession = () => {
    dispatch({ type: ACTION_TYPE.CLEAR });
  };

  const setSessionData = (data: Partial<SessionState> | undefined) =>
    dispatch({ type: ACTION_TYPE.SET, payload: data });

  return (
    <SessionContext.Provider
      value={{
        organizationId: session.organizationId,
        userId: session.userId,
        user: session.user,
        token: session.token,
        selectedOrganization: session.selectedOrganization,
        selectedMembership: session.selectedMembership,
        memberships: session.memberships,
        organizations: session.organizations?.filter(Boolean),

        setSessionData,
        updateSessionMemberships,
        updateSessionUser,
        updateSessionToken,
        updateSelectedOrganizationId,
        updateSelectedOrganizationTimezone,
        clearSession,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};
