export const mergeMetrics = (data) => {
  let merged: any = {};
  if (data?.pages) merged = { ...data.pages[0] };
  if (data?.pages?.length) {
    data.pages.forEach((page, index) => {
      if (page?.matches?.length && !!index) {
        merged = {
          ...merged,
          matches: [...page.matches, ...merged.matches],
        };
      }
    });
  }

  return { ...merged };
};
