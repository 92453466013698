import { useEffect, useState } from 'react';
import { useToast } from '../useToast';
import { deriveError } from '../../components/templates/utils';

export const useCsvExport = (prepareCallback: () => string[][] | Promise<string[][]>) => {
  const [csvData, setCsvData] = useState<string[][] | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const { create, update } = useToast();

  useEffect(() => {
    // console.log({ csvData });
    if (csvData) {
      update('success', 'Download complete');
      setCsvData(undefined);
    }
  }, [csvData]);

  const prepare = async () => {
    if (!isLoading) {
      setCsvData(undefined);
      try {
        setIsLoading(true);
        create('loading', 'Preparing CSV');

        const data = await prepareCallback();
        setCsvData(data);

        setIsLoading(false);
      } catch (error) {
        update('error', deriveError(error));
        setIsLoading(false);
      }
    }
  };
  return { prepare, csvData };
};
