import { Wallet } from 'schemas';
import { Filters, Sources } from './types';

export const getNewChainOptionsList = (previousChainOptionsList, wallets) => {
  // remove stale
  const list = previousChainOptionsList.filter((chainOption) =>
    wallets.find((wallet) => wallet.chain === chainOption.value),
  );
  // add new
  wallets
    .filter((wallet) => !previousChainOptionsList.find((option) => option.value === wallet.chain))
    .reduce((unique, wallet) => {
      unique.add(wallet.chain);
      return unique;
    }, new Set())
    .forEach((chain) => list.push({ label: chain.toUpperCase(), value: chain }));

  return list;
};

export const getFilteredSources = (data: Sources, filters: Filters) => {
  const results: Sources = {
    wallets: [],
    exchanges: [],
    tags: [],
  };
  if (filters.isInternal || filters.isExternal || filters.isExchange || filters.isTagged) {
    // external
    if (filters.isExternal) {
      results.wallets = [
        ...results.wallets,
        ...data.wallets.filter((wallet: Wallet) => wallet.walletType === 'external'),
      ];
      // external tagged
      if (filters.isTagged)
        results.wallets = [...results.wallets, ...results.wallets.filter((wallet: Wallet) => wallet.tags.length)];
    }

    // internal
    if (filters.isInternal) {
      results.wallets = [
        ...results.wallets,
        ...data.wallets.filter((wallet: Wallet) => wallet.walletType === 'internal'),
      ];
      // internal tagged
      if (filters.isTagged)
        results.wallets = [...results.wallets, ...results.wallets.filter((wallet: Wallet) => wallet.tags.length)];
    }

    // all tags
    if (filters.isTagged) results.tags = [...data.tags];

    // exchanges
    if (filters.isExchange) results.exchanges = data.exchanges;
  } else {
    results.wallets = data.wallets;
    results.exchanges = data.exchanges;
    results.tags = data.tags;
  }
  const selectedChains = filters.chains.filter((option) => option.checked);
  if (selectedChains.length)
    results.wallets = results.wallets.filter((wallet: Wallet) =>
      selectedChains.find((option) => option.value.toLowerCase() === wallet.chain.toLowerCase()),
    );

  return results;
};
