import React, { useContext, useEffect, useMemo, useState } from 'react';
import { DetailsItem } from '../atoms';
import { getDisplayedAccountingPeriods, mergeAccountingPeriods } from '../templates/utils';
import { useAccountingPeriods } from '../../hooks/http';
import { Sidebar, SidebarBody, SidebarFooter, SidebarTopBar } from '../atoms/Sidebar';
import { SidebarSectionHeader } from '../atoms/Sidebar/SidebarBody/SidebarSectionHeader';
import { SidebarSection } from '../atoms/Sidebar/SidebarBody/SidebarSection';
import RuleMetrics from './Metrics/RuleMetrics';
import { generateRandomHexString } from '../templates/utils';
import { navigate } from '../utils';
import SidebarHeader from '../atoms/Sidebar/SidebarHeader/SidebarHeader';
import { InputLabel, Button, DropdownGroupBody, capitalizeFirstLetter } from 'ui';
import { useSession } from '../../hooks/useSession';
import { JobProgressContext } from '../../context/JobProgressProvider';
import { JOB_NAME } from 'services/http';
import { BG_JOB_STATE } from '../job-progress/types';
import {
  useDeriveRuleConfiguratorState,
  parseRuleConfiguratorState,
  RULE_CONFIGURATOR_TYPE,
  RulesConfigurator,
} from './configurator';

import {
  CheckboxListItem,
  Dropdown,
  DropdownBody,
  DropdownContent,
  DropdownTitleWithToggle,
  DropdownTrigger,
  classNames,
} from 'ui';

import { AccountPostingRuleFE, useAccountPostingRuleFormState, JournalEntryTemplateDetailItem } from '../form-elements';

import { MoveRulesModal } from './MoveRulesModal';
import { toast } from 'react-hot-toast';
import { IoMdCloseCircle } from 'react-icons/io';
import { MdArrowDropDown } from 'react-icons/md';

function CreateRule({
  accountPostingRuleSetId,
  onClose = () => {
    navigate(`/ledger/configure/rulesets/${accountPostingRuleSetId}/account-posting-rules/`);
  },
  onSave,
  onDelete,
  selectedAccountPostingRule,
  onDuplicate = () => {},
  hideMoveAction = false,
}) {
  const triggerRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const { organizationId } = useSession();
  const { onTrackerClose } = useContext(JobProgressContext);

  const accountingPeriods = useAccountingPeriods({ sort: -1 });
  const displayedAccountingPeriods = useMemo(() => {
    const mergedAccountingPeriods = mergeAccountingPeriods(accountingPeriods.data);
    return getDisplayedAccountingPeriods(mergedAccountingPeriods);
  }, [accountingPeriods]);

  const topLevelCondition = useMemo(() => selectedAccountPostingRule?.topLevelCondition, [selectedAccountPostingRule]);

  const derivedRuleConfiguratorState = useDeriveRuleConfiguratorState(
    RULE_CONFIGURATOR_TYPE.ACCOUNT_POSTING,
    topLevelCondition,
    selectedAccountPostingRule?.configuratorState,
  );

  const [ruleConfiguratorState, setRuleConfiguratorState] = useState(derivedRuleConfiguratorState);
  const [ruleCondition, setRuleCondition] = useState();
  const [editMode, setEditMode] = useState(selectedAccountPostingRule ? false : true);
  const [showMoveRulesModal, setShowMoveRulesModal] = useState(false);
  const [form, setForm] = useState({ accountingPeriods: [] });
  const [ruleMetricsBgTask, setRuleMetricsBgTask] = useState({
    referenceId: '',
    bgJobState: BG_JOB_STATE.PENDING_CREATE,
  });

  const [accountPostingRuleFS, setAccountPostingRuleFS] = useAccountPostingRuleFormState();
  useEffect(() => {
    setRuleConfiguratorState(derivedRuleConfiguratorState);
  }, [derivedRuleConfiguratorState]);

  useEffect(() => {
    if (selectedAccountPostingRule) {
      setAccountPostingRuleFS({
        name: selectedAccountPostingRule.name,
        description: selectedAccountPostingRule.description,
        journalEntryTemplateId: selectedAccountPostingRule.journalEntryTemplateId,
      });
    }
  }, [selectedAccountPostingRule]);

  const [isDeleting, setIsDeleting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const title = `${selectedAccountPostingRule ? (editMode ? 'Edit' : '') : 'Create'} ${
    selectedAccountPostingRule ? selectedAccountPostingRule.name : 'Account Posting Rule'
  }`;

  const onCreateRuleSidebarClose = () => {
    onTrackerClose(ruleMetricsBgTask.bgJobState, JOB_NAME.RULE_METRICS_JOB, ruleMetricsBgTask.referenceId);
    onClose();
  };

  const isPatching = editMode && !!selectedAccountPostingRule;

  const onSaveClick = async () => {
    if (!accountPostingRuleFS.name.length) return toast.error('Please enter a name for the rule');
    if (!accountPostingRuleFS.journalEntryTemplateId)
      return toast.error('Please select a journal entry template for the rule');
    const payload = {
      topLevelCondition: parseRuleConfiguratorState(ruleConfiguratorState),
      configuratorState: ruleConfiguratorState,
      ...accountPostingRuleFS,
    };
    await onSave(payload);
    if (isPatching) setEditMode(false);
    else onClose();
  };

  const sidebarActions = useMemo(() => {
    if (editMode) return [];
    const actions = [
      {
        label: 'Duplicate',
        onClick: () => onDuplicate(selectedAccountPostingRule),
        variant: 'primary',
      },
    ];
    if (!hideMoveAction)
      actions.push({
        label: 'Move',
        onClick: () => setShowMoveRulesModal(true),
        variant: 'secondary',
      });
    actions.push({
      label: 'Edit',
      onClick: () => setEditMode(true),
      variant: 'secondary',
    });
    return actions;
  }, [editMode, hideMoveAction, selectedAccountPostingRule, onDuplicate]);

  const dataCy = selectedAccountPostingRule ? 'viewRuleset' : editMode ? 'editRuleset' : 'createRuleset';
  return (
    <Sidebar>
      <SidebarTopBar data-cy={dataCy} onClose={onCreateRuleSidebarClose} />
      <SidebarHeader data-cy={dataCy} title={title} actions={sidebarActions} />
      <SidebarBody>
        <SidebarSectionHeader title='Details' />
        <SidebarSection numberOfColumns={1}>
          {editMode ? (
            <AccountPostingRuleFE value={accountPostingRuleFS} setValue={setAccountPostingRuleFS} />
          ) : (
            <>
              <DetailsItem tag='Account posting rule name' value={selectedAccountPostingRule?.name} />
              <DetailsItem tag='Description' value={selectedAccountPostingRule?.description} />
              <JournalEntryTemplateDetailItem
                journalEntryTemplateId={selectedAccountPostingRule?.journalEntryTemplateId}
              />
            </>
          )}
        </SidebarSection>
        <SidebarSectionHeader title='Add conditions' subtitle='Any conditions added will show up here' />
        <SidebarSection numberOfColumns={1}>
          <RulesConfigurator
            configuratorType={RULE_CONFIGURATOR_TYPE.ACCOUNT_POSTING}
            defaultValue={derivedRuleConfiguratorState}
            onChange={setRuleConfiguratorState}
            isDisabled={!editMode}
          />
        </SidebarSection>
        <SidebarSectionHeader title='Rule Metrics' />
        <SidebarSection numberOfColumns={1}>
          <div>
            <InputLabel heading='Accounting Periods' />
            <Dropdown open={open} onOpenChange={setOpen}>
              <DropdownTrigger
                ref={triggerRef}
                className='w-full  [&:has(data-[state=open]).trigger-chevron]:rotate-180'
              >
                <span>
                  <Button
                    label={
                      form.accountingPeriods?.length === 0 ? (
                        'Select accounting periods'
                      ) : (
                        <p>
                          {capitalizeFirstLetter(form.accountingPeriods?.at(0)?.label?.toLowerCase()) + ' '}
                          <span>{form.accountingPeriods?.length > 1 ? form.accountingPeriods?.length + '+' : ''}</span>
                        </p>
                      )
                    }
                    className={classNames('duration-100 py-0 w-full justify-between')}
                    labelContainerClassname={classNames('font-normal text-base')}
                    emphasis='medium'
                    trailingIconContainerClassname={classNames(
                      form.accountingPeriods?.length > 0 && 'mr-0 p-2 duration-100 rounded-r-lg',
                    )}
                    trailingIcon={
                      <div className='flex items-center'>
                        {form.accountingPeriods?.length > 0 && (
                          <span
                            onClick={(e) => {
                              e.stopPropagation();
                              setForm({ ...form, accountingPeriods: [] });
                            }}
                          >
                            <IoMdCloseCircle className='w-6 h-6 text-zinc-500' />
                          </span>
                        )}

                        <MdArrowDropDown
                          className={classNames(
                            'duration-300 w-6 h-6 text-black trigger-chevron',
                            open && 'rotate-180',
                          )}
                        />
                      </div>
                    }
                  />
                </span>
              </DropdownTrigger>
              <DropdownContent sideOffset={16} triggerRef={triggerRef} sameWidthAsTrigger className='z-sidepanel'>
                <DropdownTitleWithToggle
                  title='Select all'
                  checked={form.accountingPeriods?.length === displayedAccountingPeriods.length}
                  onChange={(checked) => {
                    if (checked) {
                      setForm({ ...form, accountingPeriods: displayedAccountingPeriods });
                    } else {
                      setForm({ ...form, accountingPeriods: [] });
                    }
                  }}
                />
                <DropdownBody className='overflow-auto'>
                  <DropdownGroupBody className='px-2 flex flex-col gap-1 '>
                    {displayedAccountingPeriods.map((accountingPeriod) => (
                      <CheckboxListItem
                        containerClassNames='flex-shrink-0'
                        enableSelectOnly={false}
                        key={accountingPeriod.value}
                        label={capitalizeFirstLetter(accountingPeriod.label?.toLowerCase())}
                        checked={
                          form.accountingPeriods?.find((le) => le.value === accountingPeriod.value) ? true : false
                        }
                        onCheckedChange={(selected) => {
                          if (selected) {
                            if (form.accountingPeriods?.length === 0) {
                              setForm({ ...form, accountingPeriods: [accountingPeriod] });
                            } else {
                              setForm({
                                ...form,
                                accountingPeriods: [...(form.accountingPeriods ?? []), accountingPeriod],
                              });
                            }
                          } else {
                            setForm({
                              ...form,
                              accountingPeriods: form.accountingPeriods?.filter(
                                (le) => le.value !== accountingPeriod.value,
                              ),
                            });
                          }
                        }}
                      />
                    ))}
                  </DropdownGroupBody>
                </DropdownBody>
              </DropdownContent>
            </Dropdown>
          </div>
          <RuleMetrics
            ruleId={selectedAccountPostingRule?._id || generateRandomHexString(24)}
            organizationId={organizationId}
            accountingPeriodIds={form?.accountingPeriods?.map((ap) => ap?.value)}
            topLevelCondition={ruleCondition}
            isOneTime={selectedAccountPostingRule ? false : true}
            setRuleMetricsBgTask={setRuleMetricsBgTask}
          />
        </SidebarSection>
        <MoveRulesModal
          sourceRulesetId={accountPostingRuleSetId}
          selectedRuleIds={[selectedAccountPostingRule?._id]}
          open={showMoveRulesModal}
          onOpenChange={setShowMoveRulesModal}
          showTrigger={false}
        />
      </SidebarBody>
      <SidebarFooter
        destructiveBtn={
          isPatching && (
            <Button
              label='Delete'
              status='danger'
              emphasis='medium'
              onClick={async () => {
                setIsDeleting(true);
                await onDelete(selectedAccountPostingRule);
                setIsDeleting(false);
              }}
              isLoading={isDeleting}
            />
          )
        }
        secondaryBtn={
          editMode && (
            <Button
              label='Cancel'
              emphasis='medium'
              onClick={
                selectedAccountPostingRule
                  ? () => {
                      setRuleCondition(selectedAccountPostingRule.configuratorState);
                      setEditMode(false);
                    }
                  : onClose
              }
            />
          )
        }
        primaryBtn={
          editMode && (
            <Button
              label='Save'
              emphasis='high'
              onClick={async () => {
                const getTopLevelConditionKey = Object.keys(parseRuleConfiguratorState(ruleConfiguratorState))[0];
                if (getTopLevelConditionKey === 'undefined') return toast.error('Please add a condition to the rule');
                setIsSaving(true);
                await onSaveClick();
                setIsSaving(false);
              }}
              isLoading={isSaving}
            />
          )
        }
      />
    </Sidebar>
  );
}

export { CreateRule };

export default CreateRule;
