import { DropdownGroup, DropdownGroupBody, DropdownGroupTitle, RadioListItem } from 'ui';

export const AccountingTreatmentFilterRowGroup = ({ state, onSingleChange, onClearAll }) => {
  if (!state) return null;
  return (
    <DropdownGroup>
      <DropdownGroupTitle title='Accounting treatment' />
      <DropdownGroupBody>
        {state.ids.length > 1 ? (
          state.ids
            .map((_id) => state.entities[_id])
            .map((treatment) => (
              <RadioListItem
                key={treatment._id}
                label={treatment.value}
                checked={treatment.selected}
                onCheckedChange={(selected: boolean) => {
                  if (selected) onClearAll();
                  onSingleChange({ ...treatment, selected });
                }}
              />
            ))
        ) : (
          <p className='text-zinc-900 text-sm px-2'>No accounting treatments to show.</p>
        )}
      </DropdownGroupBody>
    </DropdownGroup>
  );
};
