import React from 'react';
import { Table, TableWrapper } from '../../dashboard';
import { mapTableKeyToOriginalKey } from '../../utils';
import { TagsHeader } from '../../../constants';
import { TABLE_TYPE } from '../../../context';

export const TagsTable = ({
  displayedTags,
  selectedTag,
  setShowEdit,
  setSelectedTag,
  isLoading,
  isFetchingNextPage,
  setSortState,
}) => {
  return (
    <TableWrapper>
      <Table
        hideCheckboxes
        testId='tags-items'
        tableData={{
          Data: displayedTags ?? [],
        }}
        useInViewCallback={() => {
          undefined;
        }}
        rowId={selectedTag?._id}
        tableHeader={TagsHeader}
        onRowClick={(row) => {
          setShowEdit(false);
          setSelectedTag(row.original);
        }}
        isLoading={isLoading}
        isFetchingNextPage={isFetchingNextPage}
        emptyBtn={{
          emptyMsg: 'No tag found',
        }}
        tableType={TABLE_TYPE.ALL_TAGS}
        onSortingChange={(state) => {
          if (state.length === 0) {
            return setSortState([]);
          }
          setSortState([{ id: mapTableKeyToOriginalKey(state[0].id, 'Tags'), desc: state[0].desc }]);
        }}
      />
    </TableWrapper>
  );
};

export default TagsTable;
