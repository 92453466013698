import React, { useEffect, useState } from 'react';
import { DetailsItem, Toast } from './atoms';
import { Sidebar, SidebarTopBar, SidebarBody, SidebarFooter } from './atoms/Sidebar';
import { useUpdateTag } from '../hooks/http/useTags';
import { ConfirmationModal } from './onboarding/ConfirmationModal';
import { deriveError } from './templates/utils';
import { SidebarSection } from './atoms/Sidebar/SidebarBody/SidebarSection';
import SidebarHeader from './atoms/Sidebar/SidebarHeader/SidebarHeader';
import { TAG_TYPE_OPTIONS } from '../constants/optionsData';
import { InputLabel, InputWithExtras, Button, SingleSelectMenu } from 'ui';
import { toast } from 'react-hot-toast';
import { SidebarSectionHeader } from './atoms/Sidebar/SidebarBody';
import { useSession } from '../hooks/useSession';
import { useInvalidateQuery } from '../hooks';

function EditDeleteTag({
  onCancel = () => {
    undefined;
  },
  selectedTag,
  isMe,
}) {
  const [deleteTagModal, setDeleteTagModal] = useState(false);
  const [tagKey, setTagKey] = useState(selectedTag?.key);
  const [tagValue, setTagValue] = useState(selectedTag?.value);
  const [viewMode, setViewMode] = useState(true);
  const { mutateAsync, isLoading: isUpdating } = useUpdateTag();

  const { organizationId } = useSession();
  const { invalidateTags } = useInvalidateQuery();

  const updateTag = async (body) => {
    if (tagValue.length) {
      await mutateAsync(
        {
          tagId: selectedTag?._id,
          body: {
            organizationId,
            entry: {
              key: tagKey,
              value: tagValue,
              organizationId: selectedTag?.organizationId,
            },
            isDeleted: body.isDeleted,
          },
        },
        {
          onSuccess: () => {
            invalidateTags();
            if (body.isDeleted) {
              onCancel();
            } else {
              toast.success('Your tag has been updated');
            }
            setViewMode(true);
          },
          onError: (err) => {
            invalidateTags();
            console.error(err.message);
          },
        },
      );
    } else {
      toast.error('Tag must have a value');
    }
  };
  const shouldSeeSaveButton = isMe;
  const shouldSeeDeleteButton = isMe;

  const handleTagValueChange = (event) => {
    setTagValue(event.target.value);
  };

  useEffect(() => {
    setTagKey(selectedTag?.key);
    setTagValue(selectedTag?.value);
  }, [selectedTag]);

  const EditModeView = (
    <>
      <div>
        <InputLabel heading='TagKey' />
        <SingleSelectMenu
          fullWidth
          isOnSidepanel
          options={TAG_TYPE_OPTIONS}
          onChange={(option) => {
            setTagKey(option.value);
          }}
          value={TAG_TYPE_OPTIONS.find((option) => option.value === tagKey)}
          data-cy='tagType'
        />
      </div>
      <div>
        <InputLabel heading='TagValue' />
        <InputWithExtras data-cy='tagValue' value={tagValue} onChange={handleTagValueChange} />
      </div>
    </>
  );

  const ViewModeView = (
    <>
      <DetailsItem data-cy='tagKey' tag='TagKey' list={[tagKey]} />
      <DetailsItem data-cy='tagValue' tag='TagValue' list={[tagValue]} />
    </>
  );

  return (
    <>
      <Sidebar rootId='edit-delete-tags'>
        <SidebarTopBar testId='edit-delete_top-block' onClose={onCancel} />
        <SidebarHeader
          title={viewMode ? 'View tag' : 'Edit tag'}
          status={
            selectedTag && {
              label: selectedTag.status,
              type: selectedTag.status === 'active' ? 'positive' : 'neutral',
            }
          }
          actions={
            viewMode
              ? [
                  {
                    label: 'Edit',
                    onClick: () => {
                      setViewMode(false);
                    },
                    variant: 'primary',
                    'data-cy': 'tags__editButton',
                  },
                ]
              : []
          }
        />
        <SidebarBody testId='edit-delete-tags_body'>
          <SidebarSectionHeader title='Details' />
          <SidebarSection numberOfColumns={viewMode ? 2 : 1}>{!viewMode ? EditModeView : ViewModeView}</SidebarSection>
        </SidebarBody>
        {!viewMode && (
          <SidebarFooter
            destructiveBtn={
              !!(shouldSeeDeleteButton || !!isMe) && (
                <Button
                  label='Delete'
                  status='danger'
                  emphasis='medium'
                  disabled={isUpdating}
                  onClick={() => setDeleteTagModal(true)}
                  data-cy='editTag_deleteButton'
                />
              )
            }
            secondaryBtn={
              <Button
                label='Cancel'
                emphasis='medium'
                onClick={() => {
                  setViewMode(true);
                }}
              />
            }
            primaryBtn={
              shouldSeeSaveButton && (
                <Button
                  emphasis='high'
                  label='Save'
                  data-cy='editTag_saveButton'
                  onClick={async () => {
                    try {
                      await updateTag({ isDeleted: false });
                    } catch (error) {
                      toast.error(deriveError(error));
                    }
                  }}
                  isLoading={isUpdating}
                  disabled={isUpdating}
                />
              )
            }
          />
        )}
      </Sidebar>
      <Toast />
      {deleteTagModal && (
        <ConfirmationModal
          title='Are you sure you want to delete this tag?'
          warningText='Delete tag'
          onDelete={async () => {
            if (selectedTag.status.toLowerCase() !== 'active') {
              try {
                await updateTag({ isDeleted: true });
                toast.success('Your tag has been deleted.');
              } catch (error) {
                console.error(error);
                toast.error(deriveError(error));
              }
            } else {
              toast.error('Tag is in use and cannot be deleted');
              setDeleteTagModal(false);
            }
          }}
          onClose={() => setDeleteTagModal(false)}
          isDeleting={isUpdating}
        />
      )}
    </>
  );
}

export default EditDeleteTag;
