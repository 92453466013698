import {
  deleteLedgerAccount,
  deleteLegalEntity,
  deleteTag,
  deleteWallet,
  getLedgerAccountById,
  getLegalEntity,
  getTags,
  getWalletById,
  GPTServiceIntentEnum,
} from 'services';
import { useEffect, useState } from 'react';
import { LedgerAccount } from 'schemas';
import { Button, Checkbox } from 'ui';
import { LoadingSpinner } from '../atoms';
import { useInvalidateQuery, useSession } from '../../hooks';
import { GPTChatResponse } from '../../context/CommandPalleteProvider';

export type MultipleEntitiesViewProps = {
  gptChatResponse: GPTChatResponse;
};

/**
 * This view is responsible for displaying entities to be selected if GPT recognizes multiple entities in the user's query back to the user for confirmation.
 * @returns {JSX.Element}
 */

export const MultipleEntitiesView: React.FC<MultipleEntitiesViewProps> = ({ gptChatResponse }) => {
  const { entityRecognitionResponse, intent } = gptChatResponse;
  if (!entityRecognitionResponse) return null;
  if (!intent) return null;

  // entitiesJsons contains the similar objects/entities returned by GPT
  const { entitiesJsons } = entityRecognitionResponse;

  return (
    <div>
      <div className={`my-2 mx-6 py-2 px-4 border rounded-lg bg-gray-100 border-gray-300 font-medium`}>
        {gptChatResponse.query}
      </div>
      <div className={`my-2 mx-6 py-2 px-4 border rounded-lg bg-gray-100 border-gray-300 font-medium`}>
        We&apos;ve found multiple entities based on your query.
      </div>
      <div className={`my-2 mx-6 py-2 px-4 border rounded-lg bg-gray-100 border-gray-300 font-medium`}>
        Please select the one you meant.
      </div>
      <EntitySelectionView entitiesJsons={entitiesJsons} intent={intent} />
    </div>
  );
};

const getObjects = async (
  entitiesJsons: Record<string, any>[],
  intent: GPTServiceIntentEnum,
  organizationId: string,
) => {
  switch (intent) {
    case GPTServiceIntentEnum.LEDGER_ACCOUNT_DELETE:
    case GPTServiceIntentEnum.LEDGER_ACCOUNT_DELETE_PARENT:
      return await Promise.all(
        entitiesJsons.map((entityJson) => getLedgerAccountById({ ledgerAccountId: entityJson._id })),
      );
    case GPTServiceIntentEnum.WALLET_DELETE:
      return await Promise.all(
        entitiesJsons.map((entityJson) => getWalletById({ walletId: entityJson.walletId, organizationId })),
      );
    case GPTServiceIntentEnum.LEGAL_ENTITY_DELETE_SPECIFIC_ENTITY:
      return await getLegalEntity({
        organizationId,
        legalEntityIds: entitiesJsons.map((entityJson) => entityJson._id),
      });
    case GPTServiceIntentEnum.TAG_DELETE:
      return await getTags({
        organizationId,
        tagIds: entitiesJsons.map((entityJson) => entityJson._id),
      });
  }
};

const EntitySelectionView = ({
  entitiesJsons,
  intent,
}: {
  entitiesJsons: Record<string, any>[];
  intent: GPTServiceIntentEnum;
}) => {
  const [data, setData] = useState<any>(undefined);
  const [isLoading, setIsLoading] = useState(true);

  const { organizationId } = useSession();

  useEffect(() => {
    if (!entitiesJsons) return;
    const fetchObjects = async () => {
      const res = await getObjects(entitiesJsons, intent, organizationId);
      setIsLoading(false);
      console.log(res);
      setData(res);
    };
    fetchObjects();
  }, []);

  if (isLoading) {
    return (
      <div className='text-center'>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className={`my-2 mx-6 py-2 px-4 border rounded-lg bg-gray-100 border-gray-300 font-medium`}>
      {data ? <SelectionComponent intent={intent} data={data} /> : null}
    </div>
  );
};

const SelectionComponent = ({ intent, data = [] }: { intent: GPTServiceIntentEnum; data: any[] }) => {
  const [selectedObjectIds, setSelectedObjectIds] = useState<string[]>([]);
  const [status, setStatus] = useState<'idle' | 'loading' | 'cancelled' | 'success' | 'error'>('idle');
  const { invalidateLedgerAccounts } = useInvalidateQuery();

  const { organizationId } = useSession();

  const onChange = (isChecked: boolean, objectId: string) => {
    if (isChecked) {
      setSelectedObjectIds([...selectedObjectIds, objectId]);
    } else {
      setSelectedObjectIds(selectedObjectIds.filter((id) => id !== objectId));
    }
  };

  const onConfirm = async () => {
    setStatus('loading');
    try {
      switch (intent) {
        case GPTServiceIntentEnum.LEDGER_ACCOUNT_DELETE:
        case GPTServiceIntentEnum.LEDGER_ACCOUNT_DELETE_PARENT: {
          await Promise.all(
            selectedObjectIds.map((ledgerAccountId) => deleteLedgerAccount({ ledgerAccountId, organizationId })),
          );
          invalidateLedgerAccounts();
          break;
        }
        case GPTServiceIntentEnum.WALLET_DELETE: {
          await Promise.all(selectedObjectIds.map((walletId) => deleteWallet({ walletId, organizationId })));
          break;
        }
        case GPTServiceIntentEnum.LEGAL_ENTITY_DELETE_SPECIFIC_ENTITY: {
          await Promise.all(
            selectedObjectIds.map((legalEntityId) => deleteLegalEntity({ legalEntityId, organizationId })),
          );
          break;
        }
        case GPTServiceIntentEnum.TAG_DELETE: {
          await Promise.all(selectedObjectIds.map((tagId) => deleteTag({ tagId, organizationId })));
          break;
        }
      }
      setStatus('success');
    } catch (err) {
      setStatus('error');
      console.log(err);
      return;
    }
  };

  if (status === 'cancelled') {
    return <p>Actions cancelled</p>;
  }

  if (status === 'error') {
    return <p>Something went wrong</p>;
  }

  if (status === 'success') {
    return <p>Success!</p>;
  }

  switch (intent) {
    case GPTServiceIntentEnum.LEDGER_ACCOUNT_DELETE:
    case GPTServiceIntentEnum.LEDGER_ACCOUNT_DELETE_PARENT: {
      const ledgerAccounts = data?.map((la) => la.data.ledgerAccount.ledgerAccount) as LedgerAccount[];

      return (
        <div className='flex flex-col gap-y-4'>
          Ledger accounts ({ledgerAccounts.length})
          {ledgerAccounts.map((ledgerAccount, index) => (
            <div className='flex gap-x-2 p-4 rounded-xl border bg-white' key={index}>
              <div className='flex'>
                <Checkbox
                  label=''
                  isSelected={selectedObjectIds.includes(ledgerAccount._id)}
                  onChange={(isChecked) => onChange(isChecked, ledgerAccount._id)}
                />
              </div>
              <div className='flex-col gap-y-1 w-full'>
                <p className='font-medium'>
                  {ledgerAccount.ledgerAccountSequence}: {ledgerAccount.ledgerAccountName}
                </p>
                <p className='text-sm text-gray-500'>{ledgerAccount.ledgerAccountType}</p>
              </div>
            </div>
          ))}
          <div className='flex gap-x-2'>
            <Button
              label='Cancel'
              emphasis='medium'
              onClick={() => setStatus('cancelled')}
              disabled={status === 'loading'}
            />
            <Button
              label='Confirm'
              status='danger'
              emphasis='high'
              onClick={onConfirm}
              disabled={selectedObjectIds.length === 0 || status === 'loading'}
            />
          </div>
        </div>
      );
    }
    case GPTServiceIntentEnum.WALLET_DELETE:
      break;
    case GPTServiceIntentEnum.LEGAL_ENTITY_DELETE_SPECIFIC_ENTITY:
      break;
    case GPTServiceIntentEnum.TAG_DELETE:
      break;
  }
};
