import React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { StyledContent } from './StyledRadixDropdownElements';
import { RxChevronDown as ChevronDownIcon } from 'react-icons/rx';
import { createGlobalStyle } from 'styled-components';
import { AssetCondition } from '../atoms/NewConditionSelector/ConditionsComponents';

const G = createGlobalStyle`
[data-disabled] {
  background-color: #fff !important;
  pointer-events: none;
  opacity: .5;
}
`;

export const AssetInlineConditionMenu = ({ rule, callbackFn, isDisabled }) => {
  return (
    <>
      <G />
      <DropdownMenu.Root modal={false}>
        <DropdownMenu.Trigger
          disabled={isDisabled}
          className='border border-[#c4c4c4] bg-white px-3 py-2 rounded-lg flex items-center max-h-10 !justify-center '
        >
          <div className='mr-2 flex h-full justify-center items-center content-center'>{rule.value}</div>
          <ChevronDownIcon />
        </DropdownMenu.Trigger>
        <DropdownMenu.Portal>
          <StyledContent>
            <AssetCondition isInLineCondition callbackFn={callbackFn} />
          </StyledContent>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    </>
  );
};
