import axios from 'axios';
import { PatchWalletPayload, PostWalletPayload } from '../response.types';
import { SERVER_URL_CORE, SERVER_URL_JOB_QUEUE } from '../../config';
import { convertQuery } from '../utils';
import { GetSourcesQueryFilterType, TagWalletsPayload } from '../types';
import { buildMultiParams, prepareSearchParams } from './utils';

export const createWallet = async (data: PostWalletPayload) => axios.post(`${SERVER_URL_CORE}/wallet`, data);

export const deleteWallet = async (data: { walletId: string; organizationId: string }) =>
  axios.delete(`${SERVER_URL_CORE}/wallet?walletId=${data.walletId}&organizationId=${data.organizationId}`);

export const bulkArchiveWallet = async (data) =>
  axios.patch(
    `${SERVER_URL_CORE}/bulk-archive-wallet?organizationId=${data.organizationId}${convertQuery(
      data.walletIds,
      'walletIds',
    )}`,
  );

export const bulkDeleteWallet = async (data) =>
  axios.delete(
    `${SERVER_URL_CORE}/bulk-delete-wallet?organizationId=${data.organizationId}${convertQuery(
      data.walletIds,
      'walletIds',
    )}`,
  );

export const getWallets = async (params: Partial<GetSourcesQueryFilterType>) => {
  const stringifiedParams = prepareSearchParams(params, { isPaginated: true });

  return axios.get(`${SERVER_URL_CORE}/wallet?${stringifiedParams}`);
};
export const getWalletById = async (data: { walletId: string; organizationId: string }) =>
  axios.get(`${SERVER_URL_CORE}/wallet-by-id?${prepareSearchParams(data)}`);

export const getWalletTags = async (data: { organizationId: string; pageSize?: number }) => {
  const stringifiedParams = prepareSearchParams(data);
  return axios.get(`${SERVER_URL_CORE}/wallet-tags?${stringifiedParams}`);
};

export const patchWallet = async (data: PatchWalletPayload) =>
  axios.patch(`${SERVER_URL_CORE}/wallet?walletId=${data._id}`, data);

export const createWalletJob = async (data) => {
  const queryString = buildMultiParams(data.walletIds, 'walletIds');
  return axios.post(
    `${SERVER_URL_JOB_QUEUE}/wallet-jobs?chain=${data.chain}&organizationIds=${data.organizationIds}&legalEntityIds=${data.legalEntityIds}&startDate=${data.startDate}${queryString}`,
    {},
  );
};

export const tagWallets = async (data: TagWalletsPayload) => {
  return axios.post(
    `${SERVER_URL_CORE}/tag-wallets?organizationId=${data.organizationId}`,
    {
      walletIds: data.walletIds,
      tagId: data.tagId,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
};
