import { CheckboxListItem, DropdownGroup, DropdownGroupBody, DropdownGroupTitle } from 'ui';
import { shortText } from '../../../rules/utils';
import { currencyImg } from '../../../utils';

export const WalletsFilterRowGroup = ({ state, onSingleChange, onClearAll }) => {
  if (!state) return null;
  return (
    <DropdownGroup>
      <DropdownGroupTitle title='Wallets' />
      <DropdownGroupBody>
        {state.ids.length > 1 ? (
          state.ids
            .map((_id) => state.entities[_id])
            .map((wallet) => (
              <CheckboxListItem
                key={wallet._id}
                label={wallet.name ?? '' + ' ' + shortText(wallet.address) ?? ''}
                bottomText={wallet.walletType}
                checked={wallet.selected}
                enableAvatar
                src={currencyImg(wallet.chain?.toLowerCase())}
                onCheckedChange={(selected: boolean) => onSingleChange({ ...wallet, selected })}
                selectOnlyFn={() => {
                  onClearAll();
                  onSingleChange({ ...wallet, selected: true });
                }}
              />
            ))
        ) : (
          <p className='text-zinc-900 text-sm px-2'>No wallets to show.</p>
        )}
      </DropdownGroupBody>
    </DropdownGroup>
  );
};
