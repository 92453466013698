import AccountBalanceStatementsRow from './AccountBalanceStatementsRow';
import { AccountBalancesProps, IAccountBalanceRowData } from '../types';
import { useEffect, useState } from 'react';
import { getCreditOrDebitNormal } from '../../templates/utils';

const AccountBalanceStatements = ({
  ledgerAccountsInStatement,
  debitsAndCreditsPerLedgerAccountInStatement,
  type,
}: AccountBalancesProps) => {
  const [statementRows, setStatementRows] = useState<IAccountBalanceRowData[]>([]);
  useEffect(() => {
    if (ledgerAccountsInStatement && debitsAndCreditsPerLedgerAccountInStatement) {
      const filteredDnCPerLedgerAccount = debitsAndCreditsPerLedgerAccountInStatement.filter(({ ledgerAccountId }) =>
        ledgerAccountsInStatement.find(({ _id }) => _id === ledgerAccountId),
      );

      const matchingLedgerAccountsMap = {};
      filteredDnCPerLedgerAccount.forEach((dnc) => {
        const matchingLedgerAccount = ledgerAccountsInStatement.find(({ _id }) => _id === dnc.ledgerAccountId);
        if (matchingLedgerAccount) matchingLedgerAccountsMap[dnc.ledgerAccountId] = matchingLedgerAccount;
      });

      const newStatementRows: IAccountBalanceRowData[] = filteredDnCPerLedgerAccount.map((filteredDnC) => ({
        ledgerAccountName: matchingLedgerAccountsMap[filteredDnC.ledgerAccountId].ledgerAccountName,
        ledgerAccountSequence: matchingLedgerAccountsMap[filteredDnC.ledgerAccountId].ledgerAccountSequence,
        ledgerAccountCreatedAt: new Date(matchingLedgerAccountsMap[filteredDnC.ledgerAccountId].createdAt),
        credits: filteredDnC.credits.value,
        debits: filteredDnC.debits.value,
        type,
        creditOrDebitNormal: getCreditOrDebitNormal(matchingLedgerAccountsMap[filteredDnC.ledgerAccountId]),
      }));
      setStatementRows(newStatementRows);
    }
  }, [ledgerAccountsInStatement, debitsAndCreditsPerLedgerAccountInStatement]);
  return (
    <>
      {statementRows.map((statementRow, index) => (
        <AccountBalanceStatementsRow statementRow={statementRow} key={index} />
      ))}
    </>
  );
};

export default AccountBalanceStatements;
