import clsx from 'clsx';
import { classNames as cn } from '../utils';
import { Avatar } from '../avatar';
import { SingleLineAvatarProps } from './types';

export function SingleLineAvatar({
  label,
  state = 'default',
  src = '',
  alt = '',
  type = 'person',
  withoutPaddingLeft = false,
  ...rest
}: SingleLineAvatarProps) {
  return (
    <div
      className={cn(
        clsx(
          'group relative px-6  w-[inherit]',
          'flex items-center gap-2',
          ' hover:cursor-pointer',
          { 'bg-indigo-50': state === 'selected' },
          { 'border-b-2 bg-indigo-100': state === 'active' },
          { '!pl-0': withoutPaddingLeft },
        ),
      )}
    >
      <Avatar src={src} alt={alt} type={type} {...rest} />
      <p className='text-base text-zinc-900 leading-6 flex-shrink-0 truncate text-ellipsis w-[stretch]'>{label}</p>
    </div>
  );
}
