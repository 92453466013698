import React from 'react';
import { MenuProps, components } from 'react-select';
export const Menu = (props: MenuProps) => {
  return (
    <div
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <components.Menu {...props}>{props.children}</components.Menu>
    </div>
  );
};

export default Menu;
