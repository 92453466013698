import { useEffect } from 'react';
import { useGetAllUserMemberships } from './http';
import { useSession } from './useSession';
import { useRouter } from 'next/router';

const blocklist = [
  '/profile/accept-organization-invite-existing-user',
  '/profile/accept-organization-invite',
  '/reset-password',
];
export const useSyncMemberships = () => {
  console.log('useSyncMemberships');
  const { updateSessionMemberships } = useSession();
  const router = useRouter();

  const { data: memberships } = useGetAllUserMemberships({ enabled: !blocklist.includes(router.pathname) });

  useEffect(() => {
    if (memberships) updateSessionMemberships(memberships);
  }, [memberships]);
};
