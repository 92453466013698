import { theme } from '../../../../../../../../packages/tailwind-config/theme';

export const selectorStyle = {
  container: (provided) => ({
    ...provided,
    width: 'fit-content',
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: theme.extend.zIndex.sidepanel,
  }),
};
