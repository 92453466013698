import React, { useEffect, useRef, useState } from 'react';
import { handleDownloadAssets } from './utils';
import { Button } from 'ui';
import { FILTER_TYPE } from '../../filters';
import dynamic from 'next/dynamic';

const CSVLink = dynamic(() => import('react-csv').then((res) => res.CSVLink), { loading: () => <></>, ssr: false });

export const AssetsCSV = ({
  assetsLoading,
  sortState,
  organizationId,
  filterHelpers,
  debouncedSearchTerm,
  datesWithTzOffset,
}) => {
  const [pages, setPages] = useState([]);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const CSVDownload = useRef<HTMLButtonElement>(null);
  const assetsCSVParams = {
    assetTypes: filterHelpers[FILTER_TYPE.ASSET]
      ? debouncedSearchTerm !== ''
        ? [...filterHelpers[FILTER_TYPE.ASSET].getAllSelectedWithTransform(), debouncedSearchTerm]
        : [...filterHelpers[FILTER_TYPE.ASSET].getAllSelectedWithTransform()]
      : [],
    impaired: filterHelpers[FILTER_TYPE.IMPAIRED]?.getAllSelectedWithTransform() ?? [],
    legalEntityIds: filterHelpers[FILTER_TYPE.LEGAL_ENTITY]?.getAllSelectedWithTransform() ?? [],
    startDate: datesWithTzOffset?.start,
    endDate: datesWithTzOffset?.end,
    sort: sortState[0],
  };
  useEffect(() => {
    if (pages.length > 0) {
      CSVDownload.current?.click();
    }
  }, [pages, CSVDownload]);
  return (
    <>
      <CSVLink
        data={pages}
        target='_blank'
        className='hidden'
        filename={`assets-export-${new Date().toLocaleDateString()}.csv`}
      >
        <span ref={CSVDownload}></span>
      </CSVLink>
      <Button
        emphasis='medium'
        onClick={async () => {
          await handleDownloadAssets({
            assetsLoading,
            setDownloadLoading,
            setPages,
            sortState,
            organizationId,
            assetsCSVParams,
          });
        }}
        isLoading={assetsLoading || downloadLoading}
        disabled={assetsLoading || downloadLoading}
        label='Download'
      />
    </>
  );
};

export default AssetsCSV;
