import React, { useEffect, useMemo, useRef, useState } from 'react';
import { InputWithExtras } from '../input-with-extras';
import { getCountriesForTimezone } from 'countries-and-timezones';
import { useDebounce } from 'usehooks-ts';
import { MdArrowDropDown, MdSearch } from 'react-icons/md';
import { timezones } from './timezones';
import { CountryWithTimezones, TimezonePickerProps } from './types';
import { CountryRow } from './CountryRow';
import { searchTermMatchesCountry, searchTermMatchesOtherDescriptor, searchTermMatchesTimezoneName } from './utils';
import { Dropdown, DropdownBody, DropdownContent, DropdownTrigger } from '../dropdown';
import { IoMdCloseCircle } from 'react-icons/io';
import { classNames } from '../utils';
import { List, CellMeasurer, CellMeasurerCache } from 'react-virtualized';

export const TimezonePicker = ({ value, onChange }: TimezonePickerProps) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [open, setOpen] = useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [showInput, setShowInput] = useState(false);
  const cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 100,
  });
  const results = useMemo(() => {
    const filteredTimezones = timezones.filter(
      (timezone) =>
        searchTermMatchesTimezoneName(searchTerm, timezone) ||
        searchTermMatchesCountry(searchTerm, timezone) ||
        searchTermMatchesOtherDescriptor(searchTerm, timezone),
    );

    const options: CountryWithTimezones[] = [];
    filteredTimezones.forEach((timezone) => {
      const countries = getCountriesForTimezone(timezone);

      countries.forEach((country) => {
        // add the option
        const optionIndex = options.findIndex((option) => option.country === country.name);
        if (optionIndex === -1) return options.push({ country: country.name, timezones: [timezone] });

        // check if that country object has the timezone
        if (!options[optionIndex].timezones.includes(timezone)) options[optionIndex].timezones.push(timezone);
      });
    });

    options.sort((a, b) => {
      if (a.country < b.country) return -1;
      if (a.country > b.country) return 1;
      return 0;
    });

    options.forEach((option) =>
      option.timezones.sort((a, b) => {
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
      }),
    );
    return options;
  }, [debouncedSearchTerm]);

  const inputRef = useRef<HTMLInputElement>(null);
  const triggerRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (showInput)
      setTimeout(() => {
        inputRef.current?.focus();
      }, 10);
  }, [showInput]);

  return (
    <Dropdown open={open} onOpenChange={setOpen}>
      <DropdownTrigger
        ref={triggerRef}
        className='min-w-[384px] relative rounded-lg w-full flex items-center border border-1.5 border-zinc-300 pl-3 pr-[14px] leading-5 py-2'
      >
        <div>
          <span
            onClick={() => {
              setShowInput(true);
              inputRef.current?.focus();
            }}
            className='grow'
          >
            {value ?? 'No timezone selected'}
          </span>

          <div className='flex items-center'>
            {value && (
              <span
                onClick={(e) => {
                  e.stopPropagation();
                  onChange(undefined);
                }}
              >
                <IoMdCloseCircle className='w-6 h-6 text-zinc-500' />
              </span>
            )}

            <MdArrowDropDown className={classNames('duration-300 w-6 h-6 text-black', open && 'rotate-180')} />
          </div>
        </div>
      </DropdownTrigger>
      <DropdownContent triggerRef={triggerRef} className='' sameWidthAsTrigger>
        <div className='p-2 '>
          <InputWithExtras
            ref={inputRef}
            leading={<MdSearch className='w-5 h-5' />}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            containerClassName='min-w-[none]'
          />
        </div>
        <DropdownBody className='w-full '>
          <div className='pl-2 relative'>
            <List
              className='pr-2'
              width={375}
              height={416}
              rowCount={results.length}
              overscanRowCount={10}
              rowHeight={cache.rowHeight}
              rowRenderer={({ index, key, style, parent }) => (
                <CellMeasurer key={key} cache={cache} parent={parent} columnIndex={0} rowIndex={index}>
                  <CountryRow
                    style={style}
                    country={results[index].country}
                    timezones={results[index].timezones}
                    key={key}
                    onSelect={(timezone) => {
                      onChange(timezone);
                      setSearchTerm('');
                      setOpen(false);
                      setShowInput(false);
                    }}
                  />
                </CellMeasurer>
              )}
            />
            {results.length === 0 && (
              <div className='top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex absolute z-10 text-2xl font-medium text-gray-400'>
                No matching timezones
              </div>
            )}
          </div>
        </DropdownBody>
      </DropdownContent>
    </Dropdown>
  );
};
