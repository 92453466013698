import React from 'react';
import ConditionItem from '../ConditionItem';
import { setSaveData } from '../../../rules/utils';
import { ConditionMenusProps } from './types';

export const RawContractAddressCondition = ({ ruleSetData, getRuleSetData, isFirstLevel }: ConditionMenusProps) => {
  return (
    <ConditionItem
      onClick={() => {
        setSaveData('Raw Contract Address', '', isFirstLevel, ruleSetData, getRuleSetData);
      }}
      label={'Smart Contract Address'}
    />
  );
};

export default RawContractAddressCondition;
