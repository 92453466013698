import React from 'react';
import { RuleConfiguratorProvider } from './rule-configurator-context/RuleConfiguratorContext';
import { RULE_CONFIGURATOR_TYPE, RuleConfiguratorState } from './types';
import { ConditionGroupComponent } from './rule-conditions/ConditionGroupComponent';
import { useRuleConfigurator } from './rule-configurator-context/RuleConfiguratorContext';
// import { parseRuleConfiguratorState } from './rule-configurator-context';

const Configurator = () => {
  const { getAllParentConditionGroupIds } = useRuleConfigurator();
  return (
    <div className='grid grid-cols-1 gap-2'>
      {getAllParentConditionGroupIds().map((conditionGroupId) => (
        <ConditionGroupComponent conditionGroupId={conditionGroupId} key={conditionGroupId} />
      ))}
    </div>
  );
};

// const RuleConfiguratorDebugger = () => {
//   const { state } = useRuleConfigurator();
//   const topLevelCondition = parseRuleConfiguratorState(state);
//   return (
//     <div className='fixed top-10 left-10 bg-white shadow z-100'>
//       <div className='flex'>
//         <div className=''>
//           <div className='mb-2'>State</div>
//           <div className='border p-2 grow w-full overflow-auto' style={{ width: 400, height: 700 }}>
//             <pre>{JSON.stringify(state, null, 2)}</pre>
//           </div>
//         </div>
//         <div className=''>
//           <div className='mb-2'>TLC</div>
//           <div className='border p-2 grow w-full overflow-auto' style={{ width: 400, height: 700 }}>
//             <pre className='overflow-auto'>{JSON.stringify(topLevelCondition, null, 2)}</pre>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

export const RulesConfigurator = ({
  configuratorType,
  onChange = console.log,
  defaultValue,
  isDisabled = false,
}: {
  configuratorType: RULE_CONFIGURATOR_TYPE;
  onChange?: (v: RuleConfiguratorState) => void;
  defaultValue?: RuleConfiguratorState;
  isDisabled?: boolean;
}) => {
  return (
    <RuleConfiguratorProvider
      configuratorType={configuratorType}
      onChange={onChange}
      defaultValue={defaultValue}
      isDisabled={isDisabled}
    >
      <div>
        <Configurator />
        {/* <RuleConfiguratorDebugger /> */}
      </div>
    </RuleConfiguratorProvider>
  );
};
