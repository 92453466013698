import React, { useState } from 'react';
import {
  Button,
  DESCRIPTIVE_LOADER_STATES,
  DescriptiveLoader,
  Dialog,
  DialogContent,
  DialogTrigger,
  InputLabel,
  SimpleMenu,
} from 'ui';

import { deriveError, getDisplayedJournalEntryTemplates, mergeJournalEntryTemplates } from '../../templates/utils';
import {
  useJournalEntryTemplates,
  useInvalidateQuery,
  useCreateJournalEntriesWithoutRule,
  useSession,
} from '../../../hooks';
import { Tooltip } from '../../atoms';
import { toast } from 'react-hot-toast';

export const TransactionClassificationModal = ({ transactionIds, showTrigger = true, onSuccess }) => {
  const [open, onOpenChange] = useState(false);
  const { invalidateTransactions } = useInvalidateQuery();
  const { mutateAsync: createJournalEntriesWithoutRule, isLoading: createJournalEntriesLoading } =
    useCreateJournalEntriesWithoutRule();
  const [selectedOption, setSelectedOption] = useState<{ label: string; value: string }>();
  const { data, isLoading: isLoadingTemplates } = useJournalEntryTemplates({
    pageSize: 1000,
  });
  const mergedTemplates = mergeJournalEntryTemplates(data);
  const displayedTemplates = getDisplayedJournalEntryTemplates(mergedTemplates);
  const { organizationId } = useSession();
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      {showTrigger && (
        <DialogTrigger className='mx-4 my-0 py-0 px-0' asChild>
          <Tooltip content={`Move`}>
            <Button
              className='h-full mr-4'
              onClick={() => onOpenChange(true)}
              label={`Classify `}
              emphasis='high'
              variant='sm'
            />
          </Tooltip>
        </DialogTrigger>
      )}

      <DialogContent
        actions={[
          {
            label: 'Confirm',
            onClick: async () => {
              try {
                await createJournalEntriesWithoutRule({
                  organizationId,
                  journalEntryTemplateId: selectedOption?.value as string,
                  transactionIds,
                });
                onOpenChange(false);
                toast.success('success');
                onSuccess();
                await invalidateTransactions();
              } catch (error) {
                toast.error(deriveError(error));
              }
            },
            disabled: createJournalEntriesLoading,
            isLoading: createJournalEntriesLoading,
          },
          {
            label: 'Cancel',
            emphasis: 'medium',
            onClick: () => {
              onOpenChange(false);
              setSelectedOption(undefined);
            },
          },
        ]}
      >
        <div>
          <InputLabel heading='Journal Entry Templates' />
          <SimpleMenu
            options={displayedTemplates}
            onChange={(option) => setSelectedOption(option)}
            value={selectedOption}
            isLoading={isLoadingTemplates}
          />
        </div>
      </DialogContent>

      {false && (
        <DialogContent onClose={() => onOpenChange(false)}>
          <DescriptiveLoader
            status={DESCRIPTIVE_LOADER_STATES.LOADING}
            title='In progress'
            description={`Moving selected`}
          />
        </DialogContent>
      )}
    </Dialog>
  );
};
