import {
  CheckboxListItem,
  DropdownGroup,
  DropdownGroupBody,
  DropdownGroupTitle,
  capitalizeFirstLetter,
  getLeftIcon,
} from 'ui';

export const ClassificationFilterRowGroup = ({ state, onSingleChange, onClearAll }) => {
  if (!state) return null;
  return (
    <DropdownGroup>
      <DropdownGroupTitle title='Transaction Categories' />
      <DropdownGroupBody>
        {state.ids.length > 1 ? (
          state.ids
            .map((_id) => state.entities[_id])
            .map((direction) => (
              <CheckboxListItem
                key={direction._id}
                enableIcon
                icon={getLeftIcon(direction.value?.toUpperCase())}
                label={capitalizeFirstLetter(direction.value?.toLowerCase())}
                checked={direction.selected}
                onCheckedChange={(selected: boolean) => onSingleChange({ ...direction, selected })}
                selectOnlyFn={() => {
                  onClearAll();
                  onSingleChange({ ...direction, selected: true });
                }}
              />
            ))
        ) : (
          <p className='text-zinc-900 text-sm px-2'>No transaction categories to show.</p>
        )}
      </DropdownGroupBody>
    </DropdownGroup>
  );
};
