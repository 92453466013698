import React from 'react';
import { components } from 'react-select';
import SwitchGroup from '../../SwitchGroup';
import { Checkbox } from 'ui';
import { CustomOptionProps } from './type';
export const Option = ({ setOptionsData, optionsData, showOnlyButton, ...props }: CustomOptionProps) => {
  const data = props.data as { label: string; value: string; checked: boolean };
  const setValue = props.setValue as any;

  if (!optionsData.slice(1).length) return <p className='mx-auto py-2 px-3'>No data found</p>;

  if (props.label === 'Select all') {
    for (let option = 1; option < optionsData.length; option++) {
      if (!optionsData[option].checked) {
        optionsData[0].checked = false;
        break;
      } else if (option === optionsData.length - 1) optionsData[0].checked = true;
    }
    return (
      <SwitchGroup
        clickFn={(value) => {
          if (value[0].checked) {
            setValue(optionsData?.slice(1));
            optionsData[0].checked = true;
          } else {
            setValue([]);
            optionsData[0].checked = false;
          }
          setOptionsData([...optionsData]);
        }}
        switchStyle='!w-full !justify-between font-medium'
        className='w-full pl-4 pr-3 mb-4'
        switchGroupItems={[optionsData[0]]}
      />
    );
  } else {
    return (
      <components.Option className='w-full hover:!bg-transparent [&_p]:hover:visible' {...props}>
        <Checkbox label={data?.label} isSelected={data?.checked} />
        {showOnlyButton && (
          <p
            onClick={(event) => {
              event.stopPropagation();
              optionsData.map((item) => (item.checked = false));
              data.checked = true;
              setOptionsData(optionsData);
            }}
            className='text-[#1a73e8] invisible hover:bg-[#1a73e80f] ml-3 px-4 py-1 rounded-full font-medium'
          >
            Only
          </p>
        )}
      </components.Option>
    );
  }
};

export default Option;
