import { FilterFn, StringFilter } from '../types';

export const assetSearchFilterFn: FilterFn<StringFilter> = (state, searched) =>
  state.ids.filter((_id) => {
    if (searched.length === 0) return true;

    const wallet = state.entities[_id];

    if (wallet.value?.toLowerCase().includes(searched.toLowerCase())) return true;
    return false;
  });
