import { AreaChart, BarChart, Legend, Text, Title } from '@tremor/react';
import { GPTChatResponse } from '../../context';
import { ChartType, GptAnalyticsCrudResponse } from 'services/http/gpt-service';
import { formatDollars } from '../templates/utils';
import { exportChart } from '../../lib/utils';
import { useEffect, useMemo, useState } from 'react';
import { MdEdit } from 'react-icons/md';
import { InputWithExtras, TremorCard } from 'ui';
import { ChartExportDropdown } from 'ui';

export const GPTAnalyticsContainer = ({ gptChatResponse }: { gptChatResponse: GPTChatResponse }) => {
  const [title, setTitle] = useState('');
  const [editingTitle, setEditingTitle] = useState(false);
  const { analyticsCrudResponse, query } = gptChatResponse;

  if (!analyticsCrudResponse) {
    return null;
  }

  const chartId = useMemo(() => `gpt-analytics-${query.split(' ').join('-')}`, [query]);

  const { data, categories, index, yAxisWidth, chartType } = processData(analyticsCrudResponse);

  if (!data || data.length === 0) {
    return (
      <TremorCard id={chartId} className='flex justify-center'>
        <Text>No data found</Text>
      </TremorCard>
    );
  }
  useEffect(() => {
    const chartTitle = getChartTitle(categories, index);
    setTitle(chartTitle);
  }, []);

  const valueFormatter = (value: any) => {
    const isUnit =
      JSON.stringify(analyticsCrudResponse.table?.yAxisColumnNames) === JSON.stringify(['count']) ||
      JSON.stringify(analyticsCrudResponse.table?.yAxisColumnNames) === JSON.stringify(['totalTokens']) ||
      JSON.stringify(analyticsCrudResponse.table?.yAxisColumnNames) === JSON.stringify(['transactionsCount']);
    if (isUnit) {
      return value;
    }
    return formatDollars(value);
  };

  const SelectedChart = ({ chartType }: { chartType: ChartType }) => {
    switch (chartType) {
      case ChartType.LINE:
        return (
          <AreaChart
            data={data}
            categories={categories}
            index={index}
            valueFormatter={valueFormatter}
            yAxisWidth={yAxisWidth}
            className='h-[200px]'
            showLegend={false}
          />
        );
      case ChartType.BAR: {
        return (
          <BarChart
            data={data}
            categories={categories}
            index={index}
            valueFormatter={valueFormatter}
            yAxisWidth={yAxisWidth}
            className='h-[200px]'
            showLegend={false}
          />
        );
      }
      default:
        return null;
    }
  };

  return (
    <TremorCard id={chartId} className='p-4'>
      <div className='flex gap-x-2 ignore-export'>
        {editingTitle ? (
          <InputWithExtras
            className='border rounded-lg border-gray-300 px-2 py-1 w-[300px]'
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            onBlur={() => setEditingTitle(false)}
            onEnter={() => setEditingTitle(false)}
            autoFocus
          />
        ) : (
          <Title>{title}</Title>
        )}
        <button
          onClick={() => {
            setEditingTitle(true);
          }}
          className='text-gray-400 hover:text-gray-600'
        >
          <MdEdit />
        </button>
      </div>
      <div className='w-full flex justify-end mb-6 '>
        <Legend categories={categories} />
        <ChartExportDropdown
          onPngExport={() => exportChart({ chartId, format: 'png', title })}
          onSvgExport={() => exportChart({ chartId, format: 'svg', title })}
        />
      </div>
      <SelectedChart chartType={chartType} />
    </TremorCard>
  );
};

const getChartTitle = (xAxesNames: string[], yAxisName: string) => {
  let convertedYAxisName = yAxisName;
  let convertedXAxesNames = xAxesNames.map((xAxisName) => {
    if (xAxisName === 'transactionsCount') {
      return 'Transactions';
    }
    return xAxisName;
  });

  if (yAxisName === 'accountingPeriodName' || yAxisName === 'transactionMonth') {
    convertedYAxisName = 'Time';
  }
  return `${convertedXAxesNames.join(' vs ')} over ${convertedYAxisName}`;
};

const processData = (analyticsCrudResponse: GptAnalyticsCrudResponse) => {
  const { table, supportedChartTypes } = analyticsCrudResponse;

  const xAxisColumnName = table?.xAxisColumnName;
  const yAxisColumnNames = table?.yAxisColumnNames;
  const data = table?.data;
  const headers = table?.headers || [];

  const chartType = supportedChartTypes[0];

  if (!xAxisColumnName || !yAxisColumnNames || !data || !headers) {
    return { data: [], categories: [], index: '', yAxisWidth: 0, chartType };
  }

  const indexOfYAxisColumnNames = yAxisColumnNames.map((yAxisColumnName) => headers.indexOf(yAxisColumnName));

  const processedData: any[] = data.map((row) => {
    const rowObject = {};
    row.forEach((column, index) => {
      if (!column) return null;
      if (!indexOfYAxisColumnNames.includes(index) && index !== headers.indexOf(xAxisColumnName)) {
        return;
      }
      if (column.$numberDecimal) {
        column = parseFloat(column.$numberDecimal);
      }
      const columnName = headers[index];
      rowObject[columnName] = column;
    });
    return rowObject;
  });

  const maxValue = Math.max(
    ...(processedData
      .map((row) => Object.values(row).filter((val: any) => typeof val === 'number'))
      .flat() as number[]),
  );

  const categories = yAxisColumnNames;
  const index = xAxisColumnName;

  const isUnit =
    JSON.stringify(analyticsCrudResponse.table?.yAxisColumnNames) === JSON.stringify(['count']) ||
    JSON.stringify(analyticsCrudResponse.table?.yAxisColumnNames) === JSON.stringify(['totalTokens']) ||
    JSON.stringify(analyticsCrudResponse.table?.yAxisColumnNames) === JSON.stringify(['transactionsCount']);
  const maxValueFormatted = isUnit ? maxValue.toString() : formatDollars(maxValue);

  const yAxisWidth = 5 + maxValueFormatted.length * 9;
  return { data: processedData, categories, index, yAxisWidth, chartType };
};
