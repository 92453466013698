import axios from 'axios';
import { CredentialResponse } from '@react-oauth/google';
import { SERVER_URL_AUTH } from '../config';
import { getSubdomain } from './utils';

export const googleAuthRequest = (data: CredentialResponse) =>
  axios.post(`${SERVER_URL_AUTH}/google-auth`, {
    ...data,
    subdomain: getSubdomain(),
  });

export const initPasswordReset = (email: string) =>
  axios.post(`${SERVER_URL_AUTH}/init-password-reset`, { email, subdomain: getSubdomain() });

export const resetPassword = (data: { token: string; newPassword: string }) =>
  axios.post(`${SERVER_URL_AUTH}/reset-password`, { ...data });

export const registerUser = (data: { user: { email: string; password: string; passwordConfirmation: string } }) =>
  axios.post(`${SERVER_URL_AUTH}/register`, { ...data, subdomain: getSubdomain() });

export const loginUser = (data: { user: { email: string; password: string } }) =>
  axios.post(`${SERVER_URL_AUTH}/login`, { ...data, subdomain: getSubdomain() });

export const logout = () => axios.post(`${SERVER_URL_AUTH}/logout`);

export const getCurrentSession = (token: string) =>
  axios.get(`${SERVER_URL_AUTH}/get-current-session?token=${token}`, { excludeAuthorizationHeader: true });
