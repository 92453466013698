import React from 'react';
import { RadioGroup } from '@headlessui/react';
import { RadioGroupProps } from './types';

export const HeadlessUIRadioGroup = ({ label, value, setValue, radioGroupOptions }: RadioGroupProps) => {
  return (
    <RadioGroup defaultChecked={false} value={value ?? ''} onChange={setValue}>
      <RadioGroup.Label className={'font-medium text-lg'}>{label}</RadioGroup.Label>
      {radioGroupOptions.map(({ label: optionLabel, value: optionValue }) => {
        return (
          <RadioGroup.Option key={optionValue} value={optionValue}>
            {({ checked }) => (
              <div className='flex items-center mt-2 cursor-pointer'>
                <span className='h-5 w-5 rounded-full border border-black flex items-center justify-center'>
                  {checked && <div className='h-3 w-3 rounded-full bg-black' />}
                </span>
                <span className='ml-2'>{optionLabel}</span>
              </div>
            )}
          </RadioGroup.Option>
        );
      })}
    </RadioGroup>
  );
};
