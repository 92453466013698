import React from 'react';
import styled from 'styled-components';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClient, QueryClientProvider, Hydrate } from '@tanstack/react-query';
import GlobalStyles from '../styles/GlobalStyles';
import Grid from '../styles/Grid';
import Buttons from '../styles/Buttons';
import Form from '../styles/Form';
import { Toast } from './atoms';
import { ToastBar, Toaster, toast } from 'react-hot-toast';
import 'react-date-range/dist/styles.css'; // react-date-range main css file
import 'react-date-range/dist/theme/default.css'; // react-date-range theme css file
import SliderProvider from '../context/SliderProvider';
import { changeRouteName } from '../components/templates/utils';
import { CommandPalleteProvider } from '../context/CommandPalleteProvider';
import { MdClose } from 'react-icons/md';
import { JobProgressProvider } from '../context/JobProgressProvider';
// import { useSyncMemberships } from '../hooks';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import SidebarGlobal from './Sidebar/SidebarGlobal';
import { MainDirectoryContextProvider, TableActionBarProvider, UserObjectsProvider } from '../context';
import CommandPallete from './CommandPallete/CommandPallete';

const queryClient = new QueryClient();

const ContentStyles = styled.main``;

export interface LayoutProps {
  children?: React.ReactNode;
  initialData?: any;
  dehydratedState?: any;
}

function Layout({ children, initialData, dehydratedState }: LayoutProps) {
  changeRouteName({
    name: '',
  });

  return (
    <>
      <GlobalStyles />
      <Grid />
      <Buttons />
      <Form />
      <QueryClientProvider client={queryClient}>
        <Hydrate state={dehydratedState}>
          <SliderProvider initialData={initialData}>
            <JobProgressProvider>
              <UserObjectsProvider>
                <CommandPalleteProvider>
                  <CommandPallete>
                    <GoogleOAuthProvider clientId='315799310762-1ij8j316s9u4i585ktcdbtd87tve3an7.apps.googleusercontent.com'>
                      <MainDirectoryContextProvider>
                        <ContentStyles>
                          <TableActionBarProvider>
                            <SidebarGlobal>{children}</SidebarGlobal>
                          </TableActionBarProvider>
                        </ContentStyles>
                      </MainDirectoryContextProvider>
                    </GoogleOAuthProvider>
                  </CommandPallete>
                  <Toast />
                </CommandPalleteProvider>
              </UserObjectsProvider>
            </JobProgressProvider>
          </SliderProvider>
          <Toaster
            position='bottom-right'
            reverseOrder={true}
            toastOptions={{
              className: 'bg-black text-white font-normal',
              loading: {
                iconTheme: {
                  secondary: 'blue',
                  primary: 'white',
                },
              },
              style: {
                wordBreak: 'break-all',
              },
            }}
          >
            {(t) => (
              <ToastBar toast={t} style={{ background: 'black', color: 'white' }}>
                {({ icon, message }) => (
                  <>
                    {icon}
                    {message}
                    {t.type !== 'loading' && (
                      <button onClick={() => toast.dismiss(t.id)}>
                        <MdClose className='text-white bg-black w-5 h-5' />
                      </button>
                    )}
                  </>
                )}
              </ToastBar>
            )}
          </Toaster>
          <ReactQueryDevtools initialIsOpen={false} />
        </Hydrate>
      </QueryClientProvider>
    </>
  );
}

export default Layout;
