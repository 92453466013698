import { omitBy, isNil } from 'lodash';
import { ALL_SUPPORTED_ASSETS } from '../../constants';
import { useSessionStorage } from 'usehooks-ts';
import { useEffect } from 'react';
import { shrink } from '../../lib/utils';
import { AssetWithExtraData, Tag, LegalEntity, AccountingPeriod } from 'services/http/response.types';
import { InfiniteData } from '@tanstack/react-query';
import { dateConverter } from '../utils';
import { MergedLedgerAccountType } from '../ledger-account/types';
import { MergeTransactionsType } from './types';
import { formatTableNumbers } from 'global-utils';

export const getFloatFromDollarFormat = (value: string) => {
  return parseFloat(value.replace(/,/g, ''));
};

export const handleTags = (str = []) => {
  let saveStr = '';
  str.map((item: Tag, index) => {
    saveStr += `${item?.entry?.key}: ${item?.entry?.value}`;
    if (!!index && index + 1 !== str.length) {
      saveStr += ',';
    }
  });
  return saveStr;
};

export const removeEmpty = (originalObject) => omitBy(originalObject, isNil);
export const createQueryStringFromObject = (params) =>
  Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');

export const convertSelectedJournalEntryLineTemplateToState = (selectedJournalEntryLineTemplate) => ({
  ...selectedJournalEntryLineTemplate,
  ledgerAccountId: getIdFromPopulatedInstance(selectedJournalEntryLineTemplate?.ledgerAccountId),
  tags: selectedJournalEntryLineTemplate?.tags || [],
});

export const TEMPLATES_COPY = {
  CREATE_LINE_SIDEBAR_HEADING: 'Add template line',
  EDIT_LINE_SIDEBAR_HEADING: 'Edit template line',
  CREATE_TEMPLATE_SIDEBAR_PRIMARY_BUTTON: 'Submit template',
  EDIT_TEMPLATE_SIDEBAR_PRIMARY_BUTTON: 'Update',
  CREATE_LINE_SECONDARY_BUTTON: 'Cancel',
  EDIT_LINE_SECONDARY_BUTTON: 'Delete',
};

export const USDollarFormater = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

export const mergeLegalEntities = (legalEntities) => {
  let mergedLegalEntities: LegalEntity[] = [];
  if (legalEntities && legalEntities.pages?.length) {
    legalEntities.pages.forEach((page) => {
      mergedLegalEntities = [...mergedLegalEntities, ...page];
    });
  }
  return mergedLegalEntities;
};

export const getDisplayedLegalEntities = (mergedLegalEntities) =>
  mergedLegalEntities.map((legalEntity) => ({
    label: `${legalEntity.entityName}`,
    value: legalEntity._id,
  }));

export const getObjectIdsFromInstances = (instances) => instances.map((instance) => instance._id);

/**
 *
 * @param {*} selectedInstances Database objects
 * @param {*} optionalValues Dropdown objects [{label: string, value: string}]
 * @returns
 */
export const getInputTagDefaultValue = (selectedInstances, optionalInstances) => {
  if (!selectedInstances || !optionalInstances) return [];
  const ids = selectedInstances.map((instance) => instance._id);
  const options = optionalInstances.filter((instance) => ids.includes(instance.value));
  return options;
};

export const getDefaultDropDownValue = (displayedInstances, instanceId) => {
  if (!instanceId) return {};
  return displayedInstances.find((instance) => instance.value === instanceId);
};

export const mergeJournalEntryTemplates = (templates) => {
  let pages = [];
  let merged: any = [];
  if (templates?.pages) pages = templates.pages;
  if (pages.length) {
    pages.map((page: any) => {
      merged = [...merged, ...page.journalEntryTemplates];
    });
  }
  return merged;
};

export const mergeAccountPostingRules = (accountPostingRules) => {
  let merged: any = [];
  let pages = [];
  if (accountPostingRules?.data?.pages) pages = accountPostingRules.data.pages;
  pages.map((page: any) => {
    merged = [...merged, ...page.accountPostingRules];
  });
  return merged;
};

export const getDisplayedJournalEntryTemplates = (templates) => {
  if (templates?.journalEntryTemplates) templates = templates.journalEntryTemplates;

  const displayedRes = templates?.map((template) => ({
    label: `${template.name}`,
    value: template._id,
  }));
  return displayedRes;
};

export const mergeAccountingPeriods = (
  accountingPeriods: InfiniteData<{ accountingPeriods: AccountingPeriod[] }> | undefined,
) => {
  if (!accountingPeriods) return [];
  let pages: AccountingPeriod[] = [];
  if (accountingPeriods?.pages) pages = accountingPeriods.pages.map((page) => page?.accountingPeriods).flat();
  return pages;
};

export const getDisplayedAccountingPeriods = (accountingPeriods) => {
  const displayedRes = accountingPeriods?.map((ap) => ({
    label: ap.accountingPeriodName || formatAccountingPeriodDate(ap.startDate),
    value: ap._id,
  }));
  return displayedRes || [];
};

export const getDisplayedAccountingPeriodsReports = (accountingPeriods) => {
  const displayedRes = accountingPeriods?.map((ap) => ({
    label: `${ap.accountingPeriodName || formatAccountingPeriodDate(ap?.startDate)}`,
    value: ap._id,
  }));
  return displayedRes || [];
};

export const getDisplayedTransactions = (transactions) => {
  const displayedRes = transactions?.map((tx) => ({
    label: tx.sequenceNumber || `${shrink(tx?.fromAddress)}-${tx?.transactionDirection}-${shrink(tx?.toAddress)}`,
    value: tx?._id,
    icon: tx.chain,
    bottomText: dateConverter(tx?.transactionDate),
  }));
  return displayedRes || [];
};

export const formatAccountingPeriodDate = (date) => {
  return new Date(date)
    .toLocaleString('default', {
      month: 'short',
      year: 'numeric',
      timeZone: 'UTC',
    })
    .toUpperCase();
};

export const getLastDayOfMonth = (today) => new Date(today.getFullYear(), today.getMonth() + 1, 0).toLocaleDateString();

export const getFirstDayOfYear = () => new Date(new Date().getFullYear(), 0, 1);

export const mergeSources = (sources) => {
  let pages: any = [];
  let merged: any = [];
  if (sources?.pages) pages = sources.pages;
  if (pages.length) {
    pages.map((page) => {
      merged = [...merged, ...page.wallets];
    });
  }
  return merged;
};

export const mergeSourceTags = (sources) => {
  let pages: any = [];
  let merged: any = [];
  if (sources?.pages) pages = sources.pages;
  if (pages.length) {
    pages.map((page) => {
      const wallets = page.tags;
      const tags: Tag[] = [];
      wallets.forEach((wallet) => {
        for (const tag of wallet.tags) {
          tags.push(tag);
        }
      });
      merged = [...new Set([...merged, ...tags])];
    });
  }
  return merged;
};

export const getDisplayedSources = (sources) => {
  const displayedRes = sources.map((source) => ({
    label: `${source.name}: ${source.walletType}`,
    value: source._id,
  }));
  return displayedRes;
};

export const getDispalyedSourcesForConditions = (sources) => {
  const displayedRes = sources.map((source) => ({
    label: `${source.walletType} ${source.name}`,
    value: {
      address: source.address,
      id: source._id,
    },
  }));
  return displayedRes;
};

export const mergeJournalEntries = (journalEntries) => {
  let merged: any = [];
  if (journalEntries && journalEntries.pages?.length) {
    journalEntries.pages.forEach((page) => {
      merged = [...merged, ...page.journalEntryModels];
    });
  }
  return merged;
};

export const getCreditsDebitsForJe = (je) => {
  if (!je || !Object.keys(je).length) return { credits: '', debits: '' };
  const getLinesByType = (type) => je.journalEntryLineIds.filter((item) => item.creditOrDebit === type);
  const debitLines = getLinesByType('DEBIT');
  const creditLines = getLinesByType('CREDIT');
  const sumDebits = debitLines.reduce((a, item) => a + parseFloat(item.amount.$numberDecimal), 0);
  const sumCredits = creditLines.reduce((a, item) => a + parseFloat(item.amount.$numberDecimal), 0);
  return { credits: sumCredits, debits: sumDebits };
};

export const formatAccountingPeriod = (date: Date) =>
  date.toLocaleString('default', { month: 'short', year: 'numeric', timeZone: 'UTC' });

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});
export const formatDollars = (amount) => formatter.format(amount);

export const formatSignificantDigits = (input: string | number): string => {
  const num = typeof input === 'number' ? input : parseFloat(input);

  if (Number.isInteger(num)) {
    return num.toFixed(2);
  }

  const significantDigits = num.toPrecision().replace(/0+$/, '');
  const decimalPlaces = significantDigits.split('.')[1]?.length || 0;

  return num.toFixed(Math.min(decimalPlaces, 2));
};

export const formatDate = (date?: Date) => {
  if (!date) return 'Invalid date';

  if (date.getFullYear() === new Date().getFullYear()) {
    return date.toLocaleDateString('en', {
      month: 'short',
      day: 'numeric',
    });
  } else {
    return date.toLocaleDateString('en', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });
  }
};

export const getFirstAndLastDatesOfYear = () => {
  const currentYear = new Date().getFullYear();
  const firstDateOfYear = new Date(currentYear, 0, 1);
  const lastDateOfYear = new Date(currentYear, 11, 31);
  return { firstDateOfYear, lastDateOfYear };
};

export const convertJournalEntries = (journals, isPanel = false) => {
  const getAmount = (type, lines) => {
    let total = 0;

    if (!lines) {
      return formatDollars(0);
    }

    for (const line of lines) {
      if (line.creditOrDebit === type.toUpperCase()) {
        total += line.amount.$numberDecimal;
      }
    }

    return formatDollars(total);
  };

  const csvData = journals.map((journal) => {
    return {
      'Journal Entry ID': journal.journalSequenceNumber,
      'Legal Entity': journal.legalEntityId?.entityName,
      'Accounting Period': new Date(journal.accountingPeriodId?.accountingPeriodName),
      'Accounting Date': new Date(journal.accountingDate)
        .toLocaleDateString('en', {
          month: 'short',
          year: 'numeric',
        })
        .toUpperCase(),
      Memo: journal.memo,
      'Total Debit Amount': getAmount('CREDIT', journal?.journalEntryLineIds),
      'Total Credit Amount': getAmount('DEBIT', journal?.journalEntryLineIds),
      'Created At': formatDate(new Date(journal.createdAt)),
      'Updated At': formatDate(new Date(journal.updatedAt)),
      'Created By': journal.originatedBy,
      Template: journal?.journalEntryTemplateId?.name,
    };
  });

  if (isPanel) {
    const activeJournal = journals[0];
    if (activeJournal) {
      for (const line of activeJournal.journalEntryLineIds) {
        csvData.push({
          'Line ID': `${journals[0].journalSequenceNumber} Line ${journals[0].journalEntryLineIds.indexOf(line) + 1}`,
          'Legal Entity': line.legalEntityId?.entityName,
          'Ledger Account': line.ledgerAccountId?.ledgerAccountName,
          'Credit/Debit': line.creditOrDebit,
          'Line Amount': formatDollars(line.amount?.$numberDecimal),
          Tags: handleTags(line.tags),
        });
      }
    }
  }

  return csvData;
};

export const convertJobHistories = (histories) => {
  return histories.map((item) => {
    return {
      ...item,
      createdBy: item?.createdBy?.email,
    };
  });
};

export const convertTransactions = (transactions) => {
  return transactions.map((tsx) => {
    return {
      id: tsx.sequenceNumber,
      Period: formatAccountingPeriodDate(new Date(tsx.transactionDate)),
      'Legal Entity': tsx.legalEntityId?.entityName,
      'Wallet Address': tsx.walletId?.address,
      'Block Number': tsx.blockNumber,
      'Transaction Hash': tsx.transactionHash,
      'Transaction Date': tsx.transactionDate,
      Chain: tsx.chain?.toUpperCase(),
      Category: tsx.category,
      'Asset Type': tsx.assetType?.toUpperCase(),
      'To Address': tsx.toAddress,
      'From Address': tsx.fromAddress,
      'Debit/Credit': tsx.transactionDirection,
      'Gross Token Amount': tsx.grossAmount?.$numberDecimal,
      'Net Token Amount': tsx.netAmount?.$numberDecimal,
      'Fee Token Amount': tsx.fee?.$numberDecimal,
      'Gross Value': formatTableNumbers({ value: tsx.grossPrice?.$numberDecimal }),
      'Net Value': formatTableNumbers({ value: tsx.netPrice?.$numberDecimal }),
      'Fee Value': formatTableNumbers({ value: tsx.feePrice?.$numberDecimal }),
      Currency: tsx.currency,
      'Created At': tsx.createdAt,
      'Updated At': tsx.updatedAt,
    };
  });
};

export const convertAssets = (assets) => {
  return assets.map((assetItem) => {
    const impairedCost = Math.round(assetItem.impairedCostBasis);
    return {
      Asset: assetItem.assetType,
      'Cost Basis': `${formatDollars(assetItem.costBasis)}`,
      'Total Cost': `${formatDollars(assetItem.pricePaid)}`,
      Impaired: `${assetItem.isImpaired}`,
      'Impaired Cost Basis': isNaN(impairedCost) ? 'N/A' : `$${impairedCost.toString()}`,
      'Remaining Qty': `${assetItem.quantity_remainingQuantity.remainingQuantity}`,
      'Initial Qty': `${assetItem.quantity_remainingQuantity.quantity}`,
      'Current Value': formatDollars(assetItem.currentValue),
    };
  });
};

export const convertSources = (sources) => {
  return sources.map((source) => {
    return {
      Chain: source.chain,
      Type: source.walletType,
      Alias: source?.name || 'N/A',
      Address: source.address,
      'Legal entity': source?.legalEntityId?.entityName || 'N/A',
      'Created date': formatDate(new Date(source.createdAt)),
    };
  });
};

export const getTransactionDetail = (transactionDetail) => {
  return transactionDetail
    ? [
        {
          'Transaction ID': transactionDetail.sequenceNumber,
          'Transaction Date': `${new Date(transactionDetail.transactionDate).toLocaleDateString('en', {
            month: 'numeric',
            year: '2-digit',
          })}`,
          Chain: transactionDetail.chain?.toUpperCase(),
          'Debit/Credit': transactionDetail.transactionDirection,
          Currency: transactionDetail.currency,
          'Gross Token Amount': transactionDetail.grossAmount?.$numberDecimal,
          'Net Token Amount': transactionDetail.netAmount?.$numberDecimal,
          'Fee Token Amount': transactionDetail.fee?.$numberDecimal,
          'Gross Value': formatTableNumbers({ value: transactionDetail.grossPrice?.$numberDecimal }),
          'Net Value': formatTableNumbers({ value: transactionDetail.netPrice?.$numberDecimal }),
          'Fee Value': formatTableNumbers({ value: transactionDetail.feePrice?.$numberDecimal }),
          Asset: transactionDetail.assetType?.toUpperCase(),
          'Transaction Hash': transactionDetail.transactionHash,
          'To Address': transactionDetail.toAddress,
          'From Address': transactionDetail.fromAddress,
          Period: transactionDetail.accountingPeriodId?.accountingPeriodName,
          'Internal Source': transactionDetail.walletId?.name,
          'Cost Basis': formatDollars(transactionDetail.assetUnitPrice?.$numberDecimal),
          Memo: transactionDetail.memo,
        },
      ]
    : [];
};

export const mergeLedgerAccounts = (ledgerAccounts?: { pages?: MergedLedgerAccountType[][] }) => {
  let mergedLedgerAccounts: MergedLedgerAccountType[] = [];
  if (ledgerAccounts && ledgerAccounts.pages?.length) {
    ledgerAccounts.pages.forEach((page) => {
      mergedLedgerAccounts = [...mergedLedgerAccounts, ...page];
    });
  }
  return mergedLedgerAccounts;
};

export const mergeAssets = (assetsIQData?: { pages: { assets: AssetWithExtraData[] }[] }) => {
  let merged: AssetWithExtraData[] = [];
  assetsIQData?.pages.forEach((page) => (merged = [...merged, ...page.assets]));

  return merged;
};

export const getDisplayedLedgerAccounts = (ledgerAccounts) => {
  return (
    ledgerAccounts?.map((ledgerAcc) => ({
      label: `${ledgerAcc.ledgerAccountSequence}: ${ledgerAcc.ledgerAccountName}`,
      value: ledgerAcc._id,
    })) || []
  );
};

export const getIdFromPopulatedInstance = (instance, key?: string) => {
  // check if instance is an object or a string
  if (!instance) return '';
  if (typeof instance === 'string') return instance;
  return key ? instance[key] : instance._id;
};
export enum STATEMENT_NORMAL {
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT',
}
export const getCreditOrDebitNormal = (ledgerAccount): STATEMENT_NORMAL => {
  // if ledgerAccountType is liablity equity or income return 'CREDIT'
  // if ledgerAccountType is asset or expense return 'DEBIT'
  return ['Liability', 'Equity', 'Income'].includes(ledgerAccount.ledgerAccountType)
    ? STATEMENT_NORMAL.CREDIT
    : STATEMENT_NORMAL.DEBIT;
};

export const mergeTags = (tags) => {
  let mergedTags: Tag[] = [];
  tags?.pages.forEach((page) => {
    mergedTags = [...mergedTags, ...page];
  });
  return mergedTags;
};

export const getDisplayedTags = (tags) => {
  const displayedTags =
    tags?.map((tag) => ({
      label: `${tag.entry.key} => ${tag.entry.value}`,
      value: tag._id,
    })) || [];
  return displayedTags;
};

export const mergeTransactions = (transactions) => {
  let merged: MergeTransactionsType[] = [];
  if (transactions && transactions.pages?.length) {
    transactions.pages.forEach((page) => {
      merged = [...merged, ...page.transactions];
    });
  }
  return merged;
};

export const mergeImpairmentRules = (transactions) => {
  let merged: any = [];
  if (transactions && transactions.pages?.length) {
    transactions.pages.forEach((page) => {
      merged = [...merged, ...page.impairmentRules];
    });
  }
  return merged;
};

export const getDisplayedSourceStatuses = () => [
  {
    label: 'ACTIVE',
    value: 'ACTIVE',
  },
  {
    label: 'ARCHIVED',
    value: 'ARCHIVED',
  },
];

export const deriveError = (error: any) => {
  // deriveError called with null, undefined, false, 0
  if (!error) return 'Unknown error occured';

  // if its an axios error
  if (error.response) {
    // handling validation error, should be changed to status code based errors (waiting on validation middleware mappings on BE)
    if (error.response.data)
      if (error.response.data.message === 'Validation failed') {
        const requiredFields = error.response.data.fields;
        if (requiredFields)
          return `${requiredFields.join(' ,')} ${requiredFields.length === 1 ? 'field' : 'fields'} required`;
      }
    // this is probably unreachable, why do we have a separate property errorMessage?
    if (error.response.data?.errorMessage) return JSON.stringify(error.response.data.errorMessage);

    // if all else fails, but its a response, just show the message as is
    return JSON.stringify(error?.response?.data?.message);
  }

  // this is probably unreachable, not sure where we would have something like this
  if (error.data?.message) return JSON.stringify(error.data.message);

  // handling 'Document not found', 'Document already exists', and unkown error
  if (error.message) {
    if (typeof error.message === 'string') return error.message;
    return JSON.stringify(error.message);
  }

  // if we are throwing something that's not an error
  return JSON.stringify(error);
};

export const getDisplayedAssets = () => ALL_SUPPORTED_ASSETS.map((token) => ({ label: token, value: token }));

export const changeRouteName = ({ name = '', UUID = '', isLoading = false }) => {
  const [, setRouteName] = useSessionStorage('routeName', { name, UUID });

  useEffect(() => {
    if (isLoading) {
      setRouteName({ name: 'loading', UUID });
    } else {
      setRouteName({ name, UUID });
    }

    return () => {
      setRouteName({ name: '', UUID: '' });
    };
  }, [name, UUID, isLoading]);
};

export const formatLedgerAccountName = (ledgerAccount) => {
  if (ledgerAccount?.ledgerAccountSequence)
    return `${ledgerAccount.ledgerAccountSequence}: ${ledgerAccount.ledgerAccountName}`;
  else return ledgerAccount.ledgerAccountName;
};

export const millisecondsToTime = (ms: number): string => {
  if (typeof ms === 'string') ms = parseFloat(ms);
  const milliseconds = Math.floor((ms % 1000) / 100),
    seconds = Math.floor((ms / 1000) % 60);

  let minutes: string | number = Math.floor((ms / (1000 * 60)) % 60),
    hours: string | number = Math.floor((ms / (1000 * 60 * 60)) % 24);

  let res = '';
  if (hours) {
    hours = hours < 10 ? '0' + hours : hours;
    res += `${hours} hours, `;
  }
  if (minutes) {
    minutes = minutes < 10 ? '0' + minutes : minutes;
    res += `${minutes} min, `;
  }
  if (seconds || milliseconds) {
    res += `${seconds}.${milliseconds} secs`;
  }
  if (!milliseconds && !seconds && !minutes && !hours) res = '0 secs';
  return res;
};

export const generateRandomHexString = (length) => {
  const maxLength = 8;
  const min = Math.pow(16, Math.min(length, maxLength) - 1);
  const max = Math.pow(16, Math.min(length, maxLength)) - 1;
  const n = Math.floor(Math.random() * (max - min + 1)) + min;
  let r = n.toString(16);
  while (r.length < length) {
    r = r + generateRandomHexString(length - maxLength);
  }
  return r;
};
