import React, { useState } from 'react';
import { SidebarBody, SidebarFooter } from '../atoms/Sidebar';
import { ACCOUNT_TYPE_OPTIONS } from '../../constants/misc';
import { useLedgerAccount, useDeleteLedgerAccount, useUpdateLedgerAccount } from '../../hooks/http';
import { mergeLedgerAccounts, getDisplayedLedgerAccounts, deriveError } from '../templates/utils';
import { SidebarSection } from '../atoms/Sidebar/SidebarBody/SidebarSection';
import { Button, CheckboxGroup, InputLabel, InputWithExtras, SingleSelectMenu } from 'ui';
import { SidebarSectionHeader } from '../atoms/Sidebar/SidebarBody';
import { toast } from 'react-hot-toast';
import { useInvalidateQuery } from '../../hooks';
import { EditLedgerAccountProps } from './types';

function EditLedgerAccount({
  onClose,
  selectedLedgerAccount,
  onCancel,
  'data-cy': dataCy = 'editLedgerAccount',
}: EditLedgerAccountProps) {
  const { data: ledgerAccounts, isLoading: isLoadingLedgerAccounts } = useLedgerAccount({
    pageSize: 1000,
  });

  const mergedLedgerAccounts = mergeLedgerAccounts(ledgerAccounts);
  const displayedLedgerAccounts = getDisplayedLedgerAccounts(mergedLedgerAccounts);
  const [form, setForm] = useState(selectedLedgerAccount);
  const [loading, setLoading] = useState(false);

  const { invalidateLedgerAccounts } = useInvalidateQuery();

  const { mutateAsync: deleteAsync, isLoading: isDeleting } = useDeleteLedgerAccount();
  const { mutateAsync: updateAsync, isLoading: isUpdating } = useUpdateLedgerAccount();
  const {
    ledgerAccountName,
    ledgerAccountType,
    ledgerAccountSequence,
    parentLedgerAccountId,
    isClearingAccount = false,
  } = form;

  const handleUpdate = async () => {
    try {
      setLoading(true);
      await updateAsync(
        {
          ledgerAccountId: selectedLedgerAccount?._id,
          ledgerAccount: {
            ledgerAccountName,
            ledgerAccountType,
            ledgerAccountSequence,
            parentLedgerAccountId: parentLedgerAccountId?._id,
            isClearingAccount,
          },
        },
        {
          onSuccess: () => {
            setLoading(false);
            toast.success('Ledger account updated successfully');
            invalidateLedgerAccounts();
            onClose();
          },
          onError: (error) => {
            setLoading(false);

            toast.error(deriveError(error));
          },
        },
      );
    } catch (e) {
      setLoading(false);

      toast.error(deriveError(e));
      console.error(e);
    }
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      await deleteAsync(
        {
          ledgerAccountId: selectedLedgerAccount?._id,
          organizationId: selectedLedgerAccount?.organizationId,
        },
        {
          onSuccess: () => {
            setLoading(false);
            toast.success('Your ledger account has been successfully deleted');
            invalidateLedgerAccounts();
            onClose();
          },
          onError: (error) => {
            setLoading(false);

            toast.error(deriveError(error));
          },
        },
      );
    } catch (e) {
      setLoading(false);

      toast.error(deriveError(e));
      console.error(e);
    }
  };

  return (
    <>
      <SidebarBody>
        <SidebarSectionHeader title='Details' />
        <SidebarSection numberOfColumns={1}>
          <div>
            <InputLabel heading='Name' />
            <InputWithExtras
              data-cy={`${dataCy}__name`}
              placeholder='Enter name'
              onChange={({ target }) =>
                setForm((prevForm) => ({
                  ...prevForm,
                  ledgerAccountName: target.value,
                }))
              }
              value={form.ledgerAccountName}
            />
          </div>
          <div>
            <InputLabel heading='Account type' />

            <SingleSelectMenu
              fullWidth
              isOnSidepanel
              data-cy={`${dataCy}__accountType`}
              options={ACCOUNT_TYPE_OPTIONS}
              value={ACCOUNT_TYPE_OPTIONS.find((option) => option.value === form.ledgerAccountType)}
              onChange={({ value }: any) =>
                setForm((prevForm) => ({
                  ...prevForm,
                  ledgerAccountType: value,
                }))
              }
              onClearValue={() => {
                setForm((prevForm) => ({
                  ...prevForm,
                  ledgerAccountType: '' as any,
                }));
              }}
            />
          </div>
          <div>
            <InputLabel heading='Number' />
            <InputWithExtras
              data-cy={`${dataCy}__number`}
              placeholder='Enter number'
              onChange={({ target }) =>
                setForm((prevForm) => ({
                  ...prevForm,
                  ledgerAccountSequence: Number(target.value),
                }))
              }
              value={String(form.ledgerAccountSequence)}
            />
          </div>
          <div>
            <InputLabel heading='Parent account' />

            <SingleSelectMenu
              fullWidth
              isOnSidepanel
              data-cy={`${dataCy}__parentAccount`}
              options={displayedLedgerAccounts.filter((item) => item.value !== selectedLedgerAccount?._id)}
              value={displayedLedgerAccounts.find((option) => option.value === form.parentLedgerAccountId?._id)}
              onChange={({ value }: any) =>
                setForm((prevForm) => ({
                  ...prevForm,
                  parentLedgerAccountId: { ...prevForm.parentLedgerAccountId, _id: value },
                }))
              }
              isLoading={isLoadingLedgerAccounts}
              onClearValue={() => {
                setForm((prevForm) => ({
                  ...prevForm,
                  parentLedgerAccountId: { ...prevForm.parentLedgerAccountId, _id: '' },
                }));
              }}
            />
          </div>
          <div>
            <CheckboxGroup
              checkboxItems={[
                {
                  label: 'Clearing account',
                  checked: form.isClearingAccount ?? false,
                },
              ]}
              getItems={(items) =>
                setForm((prevForm) => ({
                  ...prevForm,
                  isClearingAccount: items[0].checked,
                }))
              }
            />
          </div>
        </SidebarSection>
      </SidebarBody>
      <SidebarFooter
        primaryBtn={
          <Button
            data-cy={`${dataCy}__updateButton`}
            label='Update'
            emphasis='high'
            onClick={handleUpdate}
            isLoading={isUpdating}
            disabled={loading}
          />
        }
        secondaryBtn={<Button label='Cancel' emphasis='medium' onClick={onCancel} disabled={loading} />}
        destructiveBtn={
          <Button
            data-cy={`${dataCy}__deleteButton`}
            label='Delete'
            status='danger'
            emphasis='medium'
            onClick={handleDelete}
            isLoading={isDeleting}
            disabled={loading}
          />
        }
      />
    </>
  );
}

export default EditLedgerAccount;
