/* eslint-disable no-prototype-builtins */
import React, { useEffect, useState } from 'react';
import ConditionItem from '../ConditionItem';
import { useSourceTags, useSources } from '../../../../hooks/http';
import { mergeSources } from '../../../templates/utils';
import {
  filterCheck,
  getFilteredData,
  getUniqueWalletChains,
  getWalletTags,
  setSaveData,
  shortText,
} from '../../../rules/utils';
import { Button, Checkbox, InputWithExtras } from 'ui';
import { MdSearch } from 'react-icons/md';
import DropDownCheckbox from '../../DropDownCheckbox';
import { allFilterItems } from './constants';
import { ConditionMenusProps, allFilterProps } from './types';
import ConditionItemsWrapper from '../ConditionItemsWrapper';
import { useGetExchangeSource } from '../../../../hooks/http/useExchangeSource';
import { ExchangeSourceType } from 'services/http/types';

export const SourcesCondition = ({
  getRuleSetData,
  ruleSetData,
  isFirstLevel,
  callbackFn,
  showTags = true,
  isInLineCondition = false,
  type = 'dropdown',
}: ConditionMenusProps) => {
  const { data: sources, isLoading } = useSources({
    pageSize: 1000,
  });

  const { data: tags, isLoading: isLoadingTags } = useSourceTags();

  const mergedSources = mergeSources(sources);
  const [saveMergedData, setSaveMergedData] = useState<Array<any>>([]);
  const [filteredData, setFilteredData] = useState<any>(['']);
  const [allFilters, setAllFilters] = useState<allFilterProps>(allFilterItems);
  const { data: integrations } = useGetExchangeSource(ExchangeSourceType.COINBASE_PRIME);
  const integration = integrations?.sources?.find(
    (item) => item.exchangeSourceType === ExchangeSourceType.COINBASE_PRIME,
  );
  const showExchanges = !!integration;
  useEffect(() => {
    if (integration) {
      const coinbasePrime = {
        label: 'Coinbase Prime',
        value: integration?._id,
        checked: false,
        walletType: 'exchanges',
        name: 'Coinbase Prime',
      };
      setFilteredData([...getFilteredData([...saveMergedData, coinbasePrime], allFilters, showTags)]);
    } else {
      setFilteredData([...getFilteredData(saveMergedData, allFilters, showTags)]);
    }
  }, [allFilters, saveMergedData, integration]);

  useEffect(() => {
    if (!isLoading && !isLoadingTags && !saveMergedData.length) {
      setSaveMergedData([...mergedSources, ...getWalletTags(tags)]);
      setAllFilters({
        ...allFilters,
        chainsFilter: getUniqueWalletChains(mergedSources).map((el: any) => ({
          label: el.toUpperCase(),
          value: el,
          checked: false,
        })),
      });
    }
  }, [isLoading, isLoadingTags]);

  return (
    <RenderConditionWrapper type={type} isInLineCondition={isInLineCondition}>
      <div className='sticky bg-white px-2'>
        <div className='my-2'>
          <InputWithExtras
            onChange={({ target }) => setAllFilters({ ...allFilters, searchFilter: target.value })}
            type='text'
            placeholder='Search...'
            leading={<MdSearch className='w-5 h-5' />}
            className='w-[90%]'
          />
        </div>
        <div className='flex flex-wrap gap-2 mt-3'>
          <Button
            className={allFilters.typesFilter.includes('internal') ? '!bg-[#eeeeee]' : ''}
            onClick={() => filterCheck('internal', allFilters, setAllFilters)}
            emphasis='medium'
            label='Internal'
          />
          <Button
            className={allFilters.typesFilter.includes('external') ? '!bg-[#eeeeee]' : ''}
            onClick={() => filterCheck('external', allFilters, setAllFilters)}
            emphasis='medium'
            label='External'
          />
          <Button
            className={allFilters.typesFilter.includes('tagged') ? '!bg-[#eeeeee]' : ''}
            onClick={() => filterCheck('tagged', allFilters, setAllFilters)}
            emphasis='medium'
            label='Tagged'
          />
          {showExchanges && (
            <Button
              className={allFilters.typesFilter.includes('exchanges') ? '!bg-[#eeeeee]' : ''}
              onClick={() => filterCheck('exchanges', allFilters, setAllFilters)}
              emphasis='medium'
              label='Exchanges'
            />
          )}
          <DropDownCheckbox
            getItems={(items) => setAllFilters({ ...allFilters, chainsFilter: items })}
            showOnlyButton={false}
            showClearButton={false}
            placeholder='Chains'
            options={allFilters.chainsFilter}
          />
        </div>
      </div>
      <ConditionItemsWrapper isLoading={isLoading}>
        {filteredData.map((value, index) => {
          if (value?.isTagged && showTags) {
            return (
              <ConditionItem
                checked={value.checked}
                onClick={() => {
                  if (type === 'dropdown') {
                    if (isInLineCondition) {
                      callbackFn && callbackFn({ type: 'Tag', value: { tagId: value._id } });
                    } else {
                      setSaveData('Tag', { tagId: value._id }, isFirstLevel, ruleSetData, getRuleSetData);
                    }
                  } else {
                    value.checked = !value.checked;
                    value.value = value._id;
                    saveMergedData.map((mergedItem) => {
                      if (mergedItem._id === value._id) {
                        if (!mergedItem.hasOwnProperty('checked')) {
                          mergedItem.checked = true;
                        } else {
                          mergedItem.checked != mergedItem.checked;
                        }
                      } else {
                        return mergedItem;
                      }
                    });
                    callbackFn && callbackFn([...filteredData]);
                    setSaveMergedData([...saveMergedData]);
                  }
                }}
                key={`${value?.entry.value} ${index}`}
                label={
                  <>
                    {type === 'dropdown' ? (
                      <div className='ml-3'>
                        <p>{value?.entry.value ?? shortText(value?.address)}</p>
                        <div className='flex items-center justify-start gap-1'>
                          <p className='uppercase'>{value?.entry.key}</p> ·{' '}
                          <p className='capitalize'>{value?.walletType}</p>
                        </div>
                      </div>
                    ) : (
                      <Checkbox
                        label={
                          <div className='ml-3'>
                            <p>{value?.entry.value ?? shortText(value?.address)}</p>
                            <div className='flex items-center justify-start gap-1'>
                              <p className='uppercase'>{value?.entry.key}</p> ·{' '}
                              <p className='capitalize'>{value?.walletType}</p>
                            </div>
                          </div>
                        }
                        isSelected={value?.checked}
                      />
                    )}
                  </>
                }
              />
            );
          } else {
            return (
              <ConditionItem
                checked={value.checked}
                key={`${value?.value} ${index}`}
                onClick={() => {
                  if (type === 'dropdown') {
                    const savedType = value?.walletType === 'exchanges' ? 'Coinbase Prime' : 'Wallet';
                    if (isInLineCondition) {
                      callbackFn && callbackFn({ type: savedType, value });
                    } else {
                      setSaveData(savedType, value, isFirstLevel, ruleSetData, getRuleSetData);
                    }
                  } else {
                    value.checked = !value.checked;
                    value.value = value._id;
                    saveMergedData.map((mergedItem) => {
                      if (mergedItem._id === value._id) {
                        if (!mergedItem.hasOwnProperty('checked')) {
                          mergedItem.checked = true;
                        } else {
                          mergedItem.checked != mergedItem.checked;
                        }
                      } else {
                        return mergedItem;
                      }
                    });
                    callbackFn && callbackFn([...filteredData]);
                    setSaveMergedData([...saveMergedData]);
                  }
                }}
                label={
                  <>
                    {type === 'dropdown' ? (
                      <div className='ml-3'>
                        <p>{value?.name ?? shortText(value?.address)}</p>
                        <div className='flex items-center justify-start gap-1'>
                          <p className='uppercase'>{value?.chain}</p> ·{' '}
                          <p className='capitalize'>{value?.walletType}</p>
                        </div>
                      </div>
                    ) : (
                      <Checkbox
                        label={
                          <div className='ml-3'>
                            <p>{value?.name ?? shortText(value?.address)}</p>
                            <div className='flex items-center justify-start gap-1'>
                              <p className='uppercase'>{value?.chain}</p> ·{' '}
                              <p className='capitalize'>{value?.walletType}</p>
                            </div>
                          </div>
                        }
                        isSelected={value?.checked}
                      />
                    )}
                  </>
                }
              />
            );
          }
        })}
      </ConditionItemsWrapper>
    </RenderConditionWrapper>
  );
};

const RenderConditionWrapper = ({ type, isInLineCondition, children }) => {
  return (
    <>
      {type === 'dropdown' ? (
        <ConditionItem isInLineCondition={isInLineCondition} label='Sources'>
          <div className='flex flex-col gap-2 overflow-y-auto relative h-full'>{children}</div>
        </ConditionItem>
      ) : (
        <div className='min-w-[320px] w-fit h-[390px] pb-3 pt-1 px-1 rounded-md bg-white flex flex-col gap-2 absolute top-10 left-0 cursor-default z-10 shadow-[0px_4px_8px_rgba(0,0,0,0.17)]'>
          <div className='flex flex-col gap-2 overflow-y-auto relative h-full'>{children}</div>
        </div>
      )}
    </>
  );
};
export default SourcesCondition;
