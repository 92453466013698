import { CheckboxListItem, DropdownGroup, DropdownGroupBody, DropdownGroupTitle, capitalizeFirstLetter } from 'ui';

export const AccountingPeriodsFilterRowGroup = ({ state, onSingleChange, onClearAll }) => {
  if (!state) return null;
  return (
    <DropdownGroup>
      <DropdownGroupTitle title='Accounting periods' />
      <DropdownGroupBody>
        {state.ids.length > 1 ? (
          state.ids
            .map((_id) => state.entities[_id])
            .map((accountingPeriod) => (
              <CheckboxListItem
                key={accountingPeriod._id}
                label={capitalizeFirstLetter(accountingPeriod.accountingPeriodName?.toLowerCase())}
                checked={accountingPeriod.selected}
                onCheckedChange={(selected: boolean) => onSingleChange({ ...accountingPeriod, selected })}
                selectOnlyFn={() => {
                  onClearAll();
                  onSingleChange({ ...accountingPeriod, selected: true });
                }}
              />
            ))
        ) : (
          <p className='text-zinc-900 text-sm px-2'>No accounting periods to show.</p>
        )}
      </DropdownGroupBody>
    </DropdownGroup>
  );
};
