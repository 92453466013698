import { AnimatePresence } from 'framer-motion';
import { useContext, useEffect, useMemo, useState } from 'react';
import { AccountPostingRule } from 'schemas';
import { AccountPostingRulesHeader } from '../../../constants/tableHeaders';
import {
  useBulkDuplicateAccountPostingRules,
  useCreateAccountPostingRule,
  useDeleteAccountPostingRule,
  useGetAccountPostingRuleSetById,
  usePatchAccountPostingRule,
  useRulesetJobProgress,
} from '../../../hooks/http';
import { Sidebar, SidebarAnim, SidebarBody, SidebarTopBar } from '../../atoms/Sidebar';
import { SidebarSection, SidebarSectionHeader } from '../../atoms/Sidebar/SidebarBody';
import SidebarHeader, { Action } from '../../atoms/Sidebar/SidebarHeader/SidebarHeader';
import { Table } from '../../dashboard';
import { CreateRule } from '../../rules';
import { changeRouteName, deriveError, formatDate, getObjectIdsFromInstances } from '../../templates/utils';
import { EditRuleSet } from '../../../components/rules/EditRuleSet';
import { SidebarProps } from '../SidebarGlobal';
import { getHost } from '../../../lib/utils';
import { DetailsItem } from '../../atoms';
import { SidebarGlobalContext } from '../../../context/SidebarGlobalProvider';
import { useSession } from '../../../hooks/useSession';
import { toast } from 'react-hot-toast';
import { useInvalidateQuery, useScheduleJobCallbacks } from '../../../hooks';
import { ManageRulesetRunModal } from '../../rules/manage-ruleset-runs';
import cronstrue from 'cronstrue';

const RulesetSidebar: React.FC<SidebarProps> = ({ id, isPrimary, handlePrimaryPanelClose }) => {
  const { data: accountPostingRuleSet, isLoading } = useGetAccountPostingRuleSetById({
    accountPostingRuleSetId: id,
  });

  const { openSidebar, closeSidebar } = useContext(SidebarGlobalContext);
  const { organizationId, userId } = useSession();
  const [selectedAccountPostingRule, setSelectedAccountPostingRule] = useState<AccountPostingRule | null>(null);
  const [showCreateAccountPostingRule, setShowCreateAccountPostingRule] = useState(false);
  const [displayedAccountPostingRules, setDisplayedAccountPostingRules] = useState([]);
  const [accountPostingRules, setAccountPostingRules] = useState([]);
  const [showRun, setShowRun] = useState(false);
  const [, setFormData] = useState({});
  const dataCy = 'viewRuleset';
  const [isEditing, setEditing] = useState(false);

  const editing = isEditing && !!accountPostingRuleSet;

  changeRouteName({ name: accountPostingRuleSet?.name, UUID: id, isLoading });

  const { mutateAsync: createRuleAsync } = useCreateAccountPostingRule();
  const { mutateAsync: editRuleAsync } = usePatchAccountPostingRule();
  const { mutateAsync: deleteRuleAsync } = useDeleteAccountPostingRule();
  useEffect(() => {
    if (accountPostingRuleSet) {
      setFormData({
        ...accountPostingRuleSet,
      });
    }
  }, [accountPostingRuleSet]);

  useEffect(() => {
    if (accountPostingRuleSet) {
      const _legalEntities = getObjectIdsFromInstances(accountPostingRuleSet.legalEntities);
      setFormData({ ...accountPostingRuleSet, _legalEntities });
      setAccountPostingRules(accountPostingRuleSet.accountPostingRuleIds);
      setDisplayedAccountPostingRules(
        accountPostingRuleSet.accountPostingRuleIds.map((rule) => ({
          ...rule,
          name: {
            title: rule.name,
          },
        })),
      );
    }
  }, [accountPostingRuleSet]);

  const { invalidateAccountPostingRulesets } = useInvalidateQuery();

  const onRowClick = (row) => {
    closeSidebar('secondary');
    setShowCreateAccountPostingRule(false);
    setSelectedAccountPostingRule({ ...row.original, name: row.original.name.title });
  };

  const legalEntity = accountPostingRuleSet?.legalEntities[0];

  const { data: jobConfiguration } = useRulesetJobProgress(accountPostingRuleSet?._id, {
    enabled: !!accountPostingRuleSet?._id,
  });

  const cadence = useMemo(() => {
    if (jobConfiguration?.cronExpression)
      return `${cronstrue.toString(jobConfiguration.cronExpression)}${jobConfiguration.isPaused ? ' (Paused)' : ''}`;
    return '';
  }, [jobConfiguration]);

  const { onPause, onResume } = useScheduleJobCallbacks();

  const onPauseClick = async () => {
    if (!jobConfiguration?.jobId) return;
    onPause(jobConfiguration.jobId);
  };
  const onResumeClick = async () => {
    if (!jobConfiguration?.jobId) return;
    onResume(jobConfiguration.jobId);
  };

  const memoizedActions = useMemo(() => {
    const actions: Action[] = [
      {
        label: 'Add rule',
        onClick: () => {
          closeSidebar('secondary');
          if (selectedAccountPostingRule) {
            setSelectedAccountPostingRule(null);
            setShowCreateAccountPostingRule(true);
          } else {
            setShowCreateAccountPostingRule(true);
          }
        },
      },
      {
        label: 'Schedule run',
        onClick: () => {
          setShowRun(true);
        },
      },
      {
        label: 'Edit',
        onClick: () => {
          setEditing(true);
        },
        'data-cy': 'editButton',
      },
    ];

    if (jobConfiguration) {
      actions.push({
        label: jobConfiguration.isPaused ? 'Resume' : 'Pause',
        onClick: () => {
          if (jobConfiguration.isPaused) onResumeClick();
          else onPauseClick();
        },
      });
    }

    return actions;
  }, [jobConfiguration]);

  const ViewMode = (
    <>
      <SidebarHeader
        data-cy={dataCy}
        title={accountPostingRuleSet?.name}
        link={`${getHost()}/configure/rulesets/${id}`}
        actions={memoizedActions}
        loading={isLoading}
      />
      <SidebarBody>
        <SidebarSectionHeader title='Details' />
        <SidebarSection loading={isLoading}>
          <DetailsItem
            tag='Created'
            value={[accountPostingRuleSet?.userId?.email, formatDate(new Date(accountPostingRuleSet?.createdAt))]}
          />
          <DetailsItem
            tag='Legal Entity(s)'
            value={legalEntity?.entityName}
            onClick={() => {
              setShowCreateAccountPostingRule(false);
              setSelectedAccountPostingRule(null);
              openSidebar('entities', { id: legalEntity?._id, primaryOrSecondary: 'secondary' });
            }}
            variant='secondary'
            textToCopy={`${getHost()}/configure/entities/${legalEntity?._id}`}
          />
          <DetailsItem tag='Updated at' value={formatDate(new Date(accountPostingRuleSet?.updatedAt))} />
          <DetailsItem tag='Recurrence' value={cadence} />
        </SidebarSection>
        <SidebarSectionHeader title='Posting rules' />
        <SidebarSection>
          <Table
            hideCheckboxes
            isSidePanel
            tableData={{
              Data: accountPostingRules?.length ? displayedAccountPostingRules : [],
            }}
            tableHeader={AccountPostingRulesHeader}
            onRowClick={onRowClick}
            isLoading={isLoading}
            tableClasses='min-w-0'
            enableColumnPinning={false}
          />
        </SidebarSection>
      </SidebarBody>
    </>
  );

  const { mutateAsync: duplicate, isLoading: isCreatingDuplicates } = useBulkDuplicateAccountPostingRules();

  const onDuplicate = async () => {
    if (isCreatingDuplicates) return;
    if (!selectedAccountPostingRule) return;
    const toastId = toast.loading(`Creating duplicate rule`);
    try {
      const ruleset = id;
      const rules = [selectedAccountPostingRule?._id];

      await duplicate({ ruleset, rules });
      toast.success(`Duplicate rule created`, { id: toastId });

      await invalidateAccountPostingRulesets();
    } catch (error) {
      toast.error(deriveError(error), { id: toastId });
    }
  };

  return (
    <>
      <Sidebar data-cy={dataCy} data-testid='view__ruleset' className='!justify-start'>
        <SidebarTopBar
          onClose={() => handlePrimaryPanelClose && handlePrimaryPanelClose()}
          itemId={id}
          isPrimary={isPrimary}
        />
        {editing ? (
          <EditRuleSet
            onClose={() => handlePrimaryPanelClose && handlePrimaryPanelClose()}
            onCancel={() => setEditing(false)}
            selectedAccountPostingRuleSet={accountPostingRuleSet}
            setSelectedAccountPostingRule={setSelectedAccountPostingRule}
            setShowCreateAccountPostingRule={setShowCreateAccountPostingRule}
          />
        ) : (
          ViewMode
        )}
      </Sidebar>
      <AnimatePresence>
        {showCreateAccountPostingRule && !selectedAccountPostingRule && (
          <SidebarAnim variant='body'>
            <CreateRule
              onClose={() => {
                setShowCreateAccountPostingRule(false);
              }}
              onSave={async (accountPostingRule) => {
                // we now have the rule with conditions
                try {
                  const payload = {
                    ...accountPostingRule,
                    createdBy: userId,
                    organizationId,
                    accountPostingRuleSetId: accountPostingRuleSet._id,
                  };
                  await createRuleAsync(payload, {
                    onSuccess: () => invalidateAccountPostingRulesets(),
                  });

                  toast.success('Your rule has been updated');
                } catch (error) {
                  console.error(error);
                  toast.error(deriveError(error));
                }
              }}
              onDelete={undefined}
              selectedAccountPostingRule={undefined}
              accountPostingRuleSetId={accountPostingRuleSet?._id}
            />
          </SidebarAnim>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {selectedAccountPostingRule && !showCreateAccountPostingRule && (
          <SidebarAnim variant='body'>
            <CreateRule
              onClose={() => setSelectedAccountPostingRule(null)}
              onDuplicate={onDuplicate}
              onSave={async (accountPostingRule) => {
                try {
                  const payload = {
                    ...selectedAccountPostingRule,
                    ...accountPostingRule,
                  };
                  // @todo add last updated by user
                  await editRuleAsync(payload);
                  await invalidateAccountPostingRulesets();
                  const { data } = await editRuleAsync(payload);
                  setSelectedAccountPostingRule(data.updatedRule);
                  toast.success('Your rule has been updated');
                } catch (error) {
                  console.error(error);
                  toast.error(deriveError(error));
                }
              }}
              onDelete={async () => {
                const payload = selectedAccountPostingRule;
                try {
                  await deleteRuleAsync(payload as never, {
                    onSuccess: () => invalidateAccountPostingRulesets(),
                  });
                  setSelectedAccountPostingRule(null);

                  toast.success('Your rule has been updated');
                } catch (error) {
                  console.error(error);
                  toast.error(deriveError(error));
                }
              }}
              selectedAccountPostingRule={selectedAccountPostingRule}
              accountPostingRuleSetId={accountPostingRuleSet?._id}
            />
          </SidebarAnim>
        )}
      </AnimatePresence>
      {showRun && (
        <ManageRulesetRunModal open={showRun} onOpenChange={setShowRun} accountPostingRuleSet={accountPostingRuleSet} />
      )}
    </>
  );
};

export default RulesetSidebar;
