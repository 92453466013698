import React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { StyledContent } from './StyledRadixDropdownElements';
import { RxChevronDown as ChevronDownIcon } from 'react-icons/rx';
import { ChainsCondition } from '../atoms/NewConditionSelector/ConditionsComponents';

export const ChainInlineConditionMenu = ({ rule, callbackFn, isDisabled }) => {
  return (
    <>
      <DropdownMenu.Root modal={false}>
        <DropdownMenu.Trigger
          disabled={isDisabled}
          className='border border-[#c4c4c4] disabled:opacity-50 bg-white px-3 py-2 rounded-lg flex items-center max-h-10 !justify-center '
        >
          <div className='mr-2 flex h-full justify-center items-center content-center'>{rule.value?.toUpperCase()}</div>
          <ChevronDownIcon />
        </DropdownMenu.Trigger>
        <DropdownMenu.Portal>
          <StyledContent>
            <ChainsCondition isInLineCondition callbackFn={callbackFn} />
          </StyledContent>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    </>
  );
};
