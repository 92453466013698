import { useCommandPallete } from '../useCommandPallete';
import { MdChevronLeft, MdSend } from 'react-icons/md';
import { Button, classNames } from 'ui';
import { GPTFeedback } from '../GPTFeedback';
import { Action, useKBar } from 'farish-kbar';
import { ChangeEvent, memo, useEffect, useRef, useState } from 'react';
import { GPTChatResponse } from '../../../context/CommandPalleteProvider';
import { AppMentionsInput } from '../AppMentionsInput';

import { ChatLoader, ResponseContainer } from '../ResponseContainer';
import Suggestions from '../Suggestions';
import { useOnClickOutside } from 'usehooks-ts';
import { motion, AnimatePresence } from 'framer-motion';
import { AnalyzeIntentActions, CreateIntentActions, DeleteIntentActions, EditIntentActions } from '../../../constants';

const SuggestionsByIntent = {
  Create: CreateIntentActions,
  Edit: EditIntentActions,
  Delete: DeleteIntentActions,
  Analyze: AnalyzeIntentActions,
};
import { useAskAI } from '../../../hooks/ai-everything';

export const GPTChat = () => {
  const [isSuggestionOpen, setIsSuggestionOpen] = useState(false);

  const suggestionsRef = useRef<HTMLDivElement>(null);
  const chatBoxRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLTextAreaElement | null>(null);

  useOnClickOutside(suggestionsRef, () => {
    setSelectedIntent(null);
  });

  const { setSecondViewPath, setGptChatResponses, gptChatResponses, isLoading, selectedIntent, setSelectedIntent } =
    useCommandPallete();
  const { query } = useKBar();
  const onBack = () => {
    query.setSearch('');
    setSecondViewPath('');
    setGptChatResponses([]);
    setSelectedIntent(null);
  };
  const { handleAskAI, instruction, setInstruction } = useAskAI();

  const autoHeight = (elem: HTMLTextAreaElement) => {
    /* javascript */
    elem.style.height = '1px';
    elem.style.height = elem.scrollHeight + 'px';
  };

  const scrollToBottom = () => {
    chatBoxRef.current?.scrollTo({
      top: chatBoxRef.current?.scrollHeight,
      behavior: 'smooth',
    });
  };

  // const handleAskAI = async () => {
  //   try {
  //     const newQuery = chatBoxValue;
  //     const { rawInstruction } = transformGptInstruction(newQuery, selectedIntent);
  //     query.getInput().value = rawInstruction;
  //     const res = await askGPT({ organizationId, userId, instruction: newQuery, selectedIntent });
  //     if (res.status === 200) {
  //       invalidateQueriesBasedOnIntent(
  //         res.data.fullGptResponse.intent ?? GPTServiceIntentEnum.INVALID_INTENT,
  //         invalidateQueries,
  //       );
  //       setGptChatResponses((prev) => [...prev, { ...res.data.fullGptResponse, query: rawInstruction }]);
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  //   setChatBoxValue('');
  //   setSelectedIntent(null);
  // };

  useEffect(() => {
    scrollToBottom();
  }, [isLoading, gptChatResponses]);

  useEffect(() => {
    if (!inputRef) return;
    inputRef.current?.focus();
  }, [selectedIntent]);

  useEffect(() => {
    if (inputRef.current) {
      // set style of the input, because the library doesn't allow us to do it
      inputRef.current.className = classNames(
        inputRef.current.className,
        'text-[16px] w-full placeholder:text-gray-600 focus:!outline-none disabled:text-gray-700 disabled:bg-white resize-none max-h-60 scrollbar-hide h-full',
      );
    }
  }, [inputRef.current]);

  const onSelectSuggestion = (action: Action) => {
    let newValue = '';
    switch (selectedIntent) {
      case 'Create':
        newValue = action.name.endsWith('...') ? action.name.slice(0, -3) + ' ' : action.name;
        break;
      case 'Edit':
        newValue = action.name.endsWith('...') ? action.name.slice(0, -3) + ' ' : action.name;
        break;
      case 'Delete':
        newValue = action.name.endsWith('...') ? action.name.slice(0, -3) + ' ' : action.name;
        break;
      case 'Analyze':
        newValue = action.name.endsWith('...') ? action.name.slice(0, -3) + ' ' : action.name;
        break;
      default:
        break;
    }
    if (!inputRef.current) return;
    inputRef.current.value = newValue;
    setInstruction(newValue);
    inputRef.current?.focus();
    autoHeight(inputRef.current);
  };

  return (
    <div className='w-full z-commandpallet bg-white h-full rounded- flex flex-col px-2'>
      <div className='flex items-center gap-x-2'>
        <Button
          label='Copilot'
          leadingIcon={<MdChevronLeft />}
          onClick={onBack}
          className='hover:bg-indigo-50'
          emphasis='low'
          disableRingOnFocus
        />
      </div>
      <div className='h-[400px] break-words break-all' ref={chatBoxRef}>
        <div className='overflow-y-scroll h-full pr-2'>
          <MemoizedResponsesComponent gptChatResponses={gptChatResponses} />
          {isLoading ? (
            <>
              <div>
                <ResponseContainer isQuery>
                  <p>{query.getInput().value}</p>
                </ResponseContainer>
              </div>
              <ChatLoader />
            </>
          ) : null}
        </div>
      </div>
      <div className='mt-4 flex flex-col gap-x-4 relative'>
        <div className='flex flex-col gap-x-2 relative'>
          <div className=''>
            <Suggestions value={instruction} onSearch={setInstruction} />
          </div>
        </div>
        <div className={`px-4 mr-2 max-h-60 scrollbar-hide w-full relative flex`}>
          <AnimatePresence>
            {selectedIntent && !instruction && !isLoading && (
              <motion.div
                ref={suggestionsRef}
                initial={{ opacity: 0, y: 0, scale: 0.9 }}
                animate={{ opacity: 1, y: 0, scale: 1 }}
                className='absolute top-0 -translate-y-[calc(100%_+_4rem)] !transform flex flex-col gap-1 w-full right-0 overflow-clip rounded-md bg-white p-1 shadow-[0px_10px_10px_-5px_rgba(0,_0,_0,_0.04),_0px_20px_25px_-5px_rgba(0,_0,_0,_0.10)]'
              >
                {SuggestionsByIntent[selectedIntent].map((action) => (
                  <button
                    key={action.id}
                    className='px-3 flex items-center gap-2 py-1.5 hover:bg-indigo-50 cursor-pointer rounded-md text-zinc-900 font-medium w-full text-left'
                    onClick={() => onSelectSuggestion(action)}
                  >
                    {action.icon}
                    {action.name}
                  </button>
                ))}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        <div className='w-full flex  flex-row'>
          {/* <Button
            leadingIcon={<MdAddCircle className='w-9 h-9 p-2 text-zinc-900 ' />}
            aria-label='Send'
            disabled={!chatBoxValue || isLoading}
            className='bg-transparent border-0 !p-0 group hover:bg-transparent !shadow-none'
            onClick={handleAskAI}
          /> */}
          <div className='px-3 py-2 min-h-[2.5rem]  mr-2 mb-2 border rounded-lg scrollbar-hide w-full relative flex  z-commandmenu'>
            <AppMentionsInput
              className='text-[16px] w-full h-full placeholder:text-gray-600 focus:!outline-none disabled:text-gray-700 disabled:bg-white resize-none max-h-60 scrollbar-hide break-words'
              placeholder='Enter message'
              onInput={(e: ChangeEvent<HTMLTextAreaElement>) => autoHeight(e.target)}
              value={instruction}
              onChange={(e) => {
                setInstruction(e.target.value);
                if (isSuggestionOpen) setIsSuggestionOpen(false);
              }}
              autoFocus
              onInputChange={(e) => setInstruction(e.target.value)}
              disabled={isLoading}
              inputRefSetter={(ref) => {
                inputRef.current = ref;
              }}
              customSuggestionsContainer={(children) => {
                return (
                  <div className='fixed inset-x-2 bottom-0 -translate-y-16 p-2 z-[9999] rounded-md shadow-[0px_10px_10px_-5px_rgba(0,_0,_0,_0.04),_0px_20px_25px_-5px_rgba(0,_0,_0,_0.10)] bg-white'>
                    <div className='max-h-[60vh] overflow-auto h-fit  flex flex-col gap-1 [&_li]:mb-1 last:[&_li]:mb-0'>
                      {children}
                    </div>
                  </div>
                );
              }}
            />
          </div>
          <Button
            leadingIcon={<MdSend className='w-9 h-9 p-2 text-zinc-900 ' />}
            aria-label='Send'
            disabled={!instruction || isLoading}
            className='bg-transparent border-0 !p-0 group hover:bg-transparent !shadow-none'
            onClick={handleAskAI}
          />
        </div>
      </div>
    </div>
  );
};

const ResponsesComponent = ({ gptChatResponses }: { gptChatResponses: GPTChatResponse[] }) => {
  return (
    <div className='py-2 flex flex-col gap-4'>
      {gptChatResponses.map((response, index) => {
        return <GPTFeedback key={index} gptChatResponse={response} />;
      })}
    </div>
  );
};

const MemoizedResponsesComponent = memo(ResponsesComponent);

export default GPTChat;
