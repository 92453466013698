import bigDecimal from 'js-big-decimal';
import { formatDate, formatDollars } from '../../templates/utils';
import { AccountBalanceRowProps } from '../types';

const AccountBalanceStatementsRow = ({ statementRow }: AccountBalanceRowProps) => (
  <div data-testid={`tab-body-${statementRow.type}-items`} className='w-full border py-4 px-6 rounded-lg h-[88px]'>
    <div className='flex justify-between'>
      <div className='flex flex-col items-start'>
        <p data-testid='review-modal-ledger-account-name' className='text-xl font-medium'>
          {statementRow.ledgerAccountName}
        </p>
        <div className='flex items-center justify-center gap-1'>
          <p data-testid='review-modal-ledger-account-sequence'>ID: {statementRow.ledgerAccountSequence}</p>
          <p>·</p>
          <p data-testid='review-modal-ledger-account-createdAt'>{formatDate(statementRow.ledgerAccountCreatedAt)}</p>
        </div>
      </div>
      <p data-testid='review-modal-balance' className='text-xl font-medium'>
        {formatDollars(
          statementRow.creditOrDebitNormal === 'CREDIT'
            ? parseFloat(bigDecimal.subtract(statementRow.credits, statementRow.debits))
            : parseFloat(bigDecimal.subtract(statementRow.debits, statementRow.credits)),
        )}
      </p>
    </div>
  </div>
);

export default AccountBalanceStatementsRow;
