import { useContext } from 'react';
import { Wallet } from 'schemas';
import { JobProgressContext } from '../context/JobProgressProvider';
import { importTransactions } from '../components/Sources/utils';
import { JOB_NAME } from 'services/http/job-queue';
import { useBgJobTrackerState } from '../components/job-progress/useBgJobTrackerState';
import { BG_JOB_STATE } from '../components/job-progress/types';

export const useCreateImportTransactionsBgJob = (onSuccess?: () => void, onError?: (error: any) => void) => {
  const { startTrackingJob } = useContext(JobProgressContext);

  const [bgJobState, setBgJobState] = useBgJobTrackerState();

  const createJob = async (wallet: Wallet, startDate: Date) => {
    try {
      setBgJobState(BG_JOB_STATE.PENDING_CREATE);
      setBgJobState(BG_JOB_STATE.BEING_CREATED);
      await importTransactions(wallet, startDate);
      startTrackingJob(wallet._id, JOB_NAME.IMPORT_TRANSACTION_JOB, false);

      setBgJobState(BG_JOB_STATE.PROCESSING);
      if (onSuccess) onSuccess();
    } catch (error) {
      setBgJobState(BG_JOB_STATE.COULD_NOT_BE_CREATED);
      if (onError) onError(error);
      else throw error;
    }
  };

  return { createJob, bgJobState, setBgJobState };
};
