import { useMutation, useQuery } from '@tanstack/react-query';
import {
  getHedgeySource,
  createHedgeySource,
  deleteHedgeySource,
  updateHedgeySource,
  createHedgeyJob,
  getHedgeyJobHistories,
  getHedgeyVestedBalances,
  createEntriesForHedgey,
} from 'services/http/core';
import toast from 'react-hot-toast';
import { useSession } from '../useSession';
import { QUERY_KEY, useOrgBasedQueryKey } from '../useInvalidateQuery';

export const useGetHedgeySource = () => {
  const { organizationId } = useSession();

  const getKey = useOrgBasedQueryKey();
  return useQuery(
    getKey(QUERY_KEY.HEDGEY_SOURCES),
    async ({ pageParam }) => {
      const response = await getHedgeySource({ organizationId, pageParam });
      return response.data;
    },
    {
      enabled: !!organizationId,
      onError: async (error) => {
        await toast.error((error as any).message);
      },
      staleTime: 30000,
      cacheTime: 30000,
      refetchOnWindowFocus: false,
    },
  );
};

export const useGetHedgeyJobHistories = () => {
  const { organizationId } = useSession();

  const getKey = useOrgBasedQueryKey();
  return useQuery(
    getKey(QUERY_KEY.HEDGEY_SOURCE_JOB_HISTORY_RECORDS),
    async ({ pageParam }) => {
      const response = await getHedgeyJobHistories({ organizationId, pageParam });
      return response.data;
    },
    {
      enabled: !!organizationId,
      staleTime: 30000,
      cacheTime: 30000,
    },
  );
};

export const useCreateHedgey = () => {
  return useMutation(async (body: any) => await createHedgeySource(body));
};

export const useUpdateHedgey = () => {
  return useMutation(async (body: any) => await updateHedgeySource(body));
};
export const useDeleteHedgey = () => {
  return useMutation(async (body: any) => await deleteHedgeySource(body));
};

export const useCreateHedgeyJob = () => {
  return useMutation(async (body: any) => await createHedgeyJob(body));
};

export type useGetHedgeyVestingBalancesPayload = {
  accountingPeriodId: string;
};

export const useGetHedgeyVestingBalances = ({ accountingPeriodId }: useGetHedgeyVestingBalancesPayload) => {
  const { organizationId } = useSession();

  return useQuery(
    ['GetHedgeyVestedBalances', organizationId, accountingPeriodId],
    async () => {
      const response = await getHedgeyVestedBalances({ organizationId, accountingPeriodId });
      return response.data.vestedBalancesByAssetType;
    },
    {
      enabled: !!organizationId && !!accountingPeriodId,
      staleTime: 30000,
      cacheTime: 30000,
    },
  );
};

export type createEntriesForHedgeyPayload = {
  accountingPeriodId: string;
  journalEntryTemplateId: string;
  assetType: string;
};
export const useCreateEntriesForHedgey = () => {
  return useMutation(async (body: createEntriesForHedgeyPayload) => await createEntriesForHedgey(body));
};
