import React from 'react';
import { groupBy } from 'lodash';
import { useLedgerAccount } from '../../hooks/http';
import { getDisplayedLedgerAccounts, mergeLedgerAccounts } from '../templates/utils';
import { ACCOUNT_TYPE_OPTIONS } from '../../constants/misc';
import { CheckboxGroup, InputLabel, InputWithExtras, SingleSelectMenu } from 'ui';
import { AddLedgerAccountFormProps } from './types';

function AddLedgerAccountForm({ form, setForm, loading = false, 'data-cy': dataCy = '' }: AddLedgerAccountFormProps) {
  const { data: ledgerAccounts, isLoading: isLoadingLedgerAccounts } = useLedgerAccount({
    pageSize: 1000,
  });
  const mergedLedgerAccounts = mergeLedgerAccounts(ledgerAccounts);
  const groupedLedgerAccounts = groupBy(mergedLedgerAccounts || [], 'parentLedgerAccountId');

  if (groupedLedgerAccounts && groupedLedgerAccounts.undefined) {
    groupedLedgerAccounts.parents = groupedLedgerAccounts.undefined;
    delete groupedLedgerAccounts.undefined;
  }
  return (
    <>
      <div>
        <InputLabel heading='Name' />
        <InputWithExtras
          data-cy={`${dataCy}__name`}
          placeholder='Enter name'
          onChange={({ target }) =>
            setForm({
              ...form,
              name: target.value,
            })
          }
          value={form.name}
          disabled={loading}
        />
      </div>
      <div>
        <InputLabel heading='Account type' />

        <SingleSelectMenu
          isModal
          fullWidth
          isOnSidepanel
          isLoading={loading}
          data-cy={`${dataCy}__accountType`}
          options={ACCOUNT_TYPE_OPTIONS}
          onChange={({ value }: any) =>
            setForm({
              ...form,
              type: value,
            })
          }
          value={ACCOUNT_TYPE_OPTIONS.find((option) => option.value === form.type)}
          placeholder='Select account type'
          disabled={loading}
          onClearValue={() => {
            setForm({
              ...form,
              type: '' as any,
            });
          }}
        />
      </div>
      <div>
        <InputLabel heading='Number' />
        <InputWithExtras
          data-cy={`${dataCy}__number`}
          placeholder='Enter number'
          onChange={({ target }) =>
            setForm({
              ...form,
              number: target.value,
            })
          }
          value={form.number}
          disabled={loading}
        />
      </div>
      <div>
        <InputLabel heading='Parent account' />

        <SingleSelectMenu
          isModal
          fullWidth
          isOnSidepanel
          data-cy={`${dataCy}__parentAccount`}
          options={getDisplayedLedgerAccounts(mergedLedgerAccounts)}
          onChange={({ value }: any) =>
            setForm({
              ...form,
              parentAccount: value,
            })
          }
          value={getDisplayedLedgerAccounts(mergedLedgerAccounts).find((option) => option.value === form.parentAccount)}
          isLoading={isLoadingLedgerAccounts}
          placeholder='Select parent account'
          disabled={loading}
          onClearValue={() => {
            setForm({
              ...form,
              parentAccount: '',
            });
          }}
        />
      </div>
      <div>
        <CheckboxGroup
          data-cy={`${dataCy}__isClearingAccount`}
          disabled={loading}
          checkboxItems={[
            {
              label: 'Clearing account',
              checked: form.isClearingAccount ?? false,
            },
          ]}
          getItems={(items) => {
            setForm({
              ...form,
              isClearingAccount: items[0]?.checked ?? false,
            });
          }}
        />
      </div>
    </>
  );
}

export default AddLedgerAccountForm;
