import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY, useOrgBasedQueryKey } from '../useInvalidateQuery';
import { getTransactionAssetRelativePrices } from 'services/http/analytics/assets';

export const useGetTansactionAssetRelativePrices = (params: { transactionId: string }) => {
  const { transactionId } = params;
  const getKey = useOrgBasedQueryKey();

  return useQuery(
    getKey(QUERY_KEY.TRANSACTION_ASSET_RELATIVE_PRICES, params),
    async () => {
      const response = await getTransactionAssetRelativePrices({
        transactionId,
      });
      console.log('response', response.data);
      return response.data;
    },
    {
      staleTime: 1000 * 60 * 30,
      cacheTime: 1000 * 60 * 5,
    },
  );
};
