import { useContext, useEffect, useState } from 'react';
import { JOB_NAME, JOB_STATUS } from 'services/http';
import { useJobNameBasedQueryInvalidator } from './useJobNameBasedQueryInvalidator';
import { JobProgressContext } from '../context/JobProgressProvider';

export const useInvalidateOnJobCompletions = () => {
  const { state } = useContext(JobProgressContext);
  const jobNameBasedInvalidator = useJobNameBasedQueryInvalidator();
  const [completedJobs, setCompletedJobs] = useState<{ referenceId: string; jobName: JOB_NAME }[]>([]);

  useEffect(() => {
    const jobs = Object.values(state.progress);
    const completed = jobs.filter((job) =>
      [JOB_STATUS.CANCELED, JOB_STATUS.COMPLETED, JOB_STATUS.JOB_FAILED].includes(job.jobStatus),
    );

    const newlyCompleted = completed.filter(
      (job) => !completedJobs.find((j) => j.referenceId === job.referenceId && j.jobName === job.jobName),
    );

    if (newlyCompleted.length > 0) setCompletedJobs(newlyCompleted);
  }, [state, completedJobs]);

  useEffect(() => {
    completedJobs.forEach((job) => jobNameBasedInvalidator(job.jobName));
  }, [completedJobs]);
};
