import React from 'react';
import { ValueContainerProps } from 'react-select';
import { components } from 'react-select';
export const ValueContainer = (props: ValueContainerProps) => {
  const value: { label: string; value: string }[] = props?.selectProps?.value as { label: string; value: string }[];
  if (!!value.length && value[0].label === 'Select all') value.shift();
  return (
    <>
      {value?.length ? (
        <p className='text-base font-medium ml-3 mb-0 text-[#1967d2]'>
          {value[0]?.label} {value.length > 1 && `+${value.length - 1}`}
        </p>
      ) : (
        <components.ValueContainer {...props} />
      )}
    </>
  );
};

export default ValueContainer;
