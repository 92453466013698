import { ReactNode } from 'react';
import { FACT, RULE_CONFIGURATOR_TYPE } from '../../types';
import { useCondition } from '../useCondition';
import { useRuleConfigurator } from '../../rule-configurator-context';

export const ConditionRow = ({
  facts,
  children,
  showOnConfiguratorTypes = [RULE_CONFIGURATOR_TYPE.IMPAIRMENT, RULE_CONFIGURATOR_TYPE.ACCOUNT_POSTING],
}: {
  facts: FACT[];
  children: ReactNode;
  showOnConfiguratorTypes?: RULE_CONFIGURATOR_TYPE[];
}) => {
  const { state } = useRuleConfigurator();
  const condition = useCondition();

  //console.log('Checking condition row for facts: ', facts);

  if (!condition?.fact) {
    //console.log('Condition without fact entered the state', condition, facts);
    return null;
  }
  if (!facts.includes(condition.fact)) {
    // console.log('Not rendering condition as its fact is', condition.fact, 'which is not included in', facts);
    return null;
  }

  if (!showOnConfiguratorTypes.includes(state.configuratorType)) {
    // console.log('Condition Row hidden for this configurator type', state.type, facts);
    return null;
  }

  return (
    <div className='bg-[#E5E5E5] border-[#0000001A] border rounded-lg flex flex-row flex-wrap gap-2 p-2'>
      {children}
    </div>
  );
};
