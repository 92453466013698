import React, { useMemo } from 'react';
import { MetricCard, MetricGroup } from '../../atoms';
import { formatDollars } from '../../templates/utils';
import { useTransactionStats } from '../../../hooks';
import {
  getAddressesFromFilteredWallet,
  getShowTransactionsWithoutJournalEntriesBooleanOrUndefined,
  getTop5AssetsByCount,
} from './utils';
import { WALLET_TYPE } from 'services/http/response.types';
import { FILTER_TYPE } from '../../filters';
import { currencyImg } from '../../utils';

export const TransactionsMetricsCard = ({ walletIds, sourceIds, filterHelpers, datesWithTzOffset }) => {
  const transactionsStatsParams = {
    chains: filterHelpers[FILTER_TYPE.CHAIN]?.getAllSelectedWithTransform(),
    classifications: filterHelpers[FILTER_TYPE.CLASSIFICATION]?.getAllSelectedWithTransform(),
    directions: filterHelpers[FILTER_TYPE.DIRECTION]?.getAllSelectedWithTransform(),
    transactionFrom: datesWithTzOffset.start,
    transactionTo: datesWithTzOffset.end,
    accountingPeriodIds: filterHelpers[FILTER_TYPE.ACCOUNTING_PERIOD]?.getAllSelectedWithTransform(),
    legalEntityIds: filterHelpers[FILTER_TYPE.LEGAL_ENTITY]?.getAllSelectedWithTransform(),
    assetTypes: filterHelpers[FILTER_TYPE.ASSET]?.getAllSelectedWithTransform(),
    addresses: filterHelpers[FILTER_TYPE.WALLET]
      ?.getAllSelectedWithTransform()
      .filter(({ walletType }) => walletType === WALLET_TYPE.EXTERNAL)
      .map(getAddressesFromFilteredWallet)
      .flat(), // this return { _id, adress, addresses } for each wallet apps/next/src/components/filters/transforms.ts
    showTransactionsWithoutJournalEntries: getShowTransactionsWithoutJournalEntriesBooleanOrUndefined(filterHelpers),
    walletIds,
    sourceIds,
    includeSpam: filterHelpers[FILTER_TYPE.SPAM_TOKEN]?.getAllSelectedWithTransform()[0] ? true : false,
  };

  const { data: fetchedTransactionsData, isLoading: statsLoading } = useTransactionStats(transactionsStatsParams);

  const totalTransactions = useMemo(
    () => (fetchedTransactionsData?.transactionStats?.length ? fetchedTransactionsData?.transactionStats[0].count : 0),
    [fetchedTransactionsData],
  );

  const totalTokens = useMemo(() => fetchedTransactionsData?.totalTokens, [fetchedTransactionsData]);

  const grossValueTransacted = useMemo(
    () =>
      fetchedTransactionsData?.transactionStats?.length
        ? formatDollars(fetchedTransactionsData?.transactionStats[0].grossValueTransacted)
        : '0',
    [fetchedTransactionsData],
  );

  const top5Assets = useMemo(
    () => getTop5AssetsByCount(fetchedTransactionsData?.tokensByCount ?? {}),
    [fetchedTransactionsData],
  );

  const top5AssetsIcons = useMemo(
    () => top5Assets.map((asset) => ({ url: currencyImg(asset), alt: asset })),
    [top5Assets],
  );

  return (
    <MetricGroup className='!mb-0 !mt-4'>
      <MetricCard loading={statsLoading} label='total transactions' metric={totalTransactions} />
      <MetricCard loading={statsLoading} label='Total Asset Types' metric={totalTokens} icons={top5AssetsIcons} />
      <MetricCard loading={statsLoading} label='Gross value transacted' metric={grossValueTransacted} />
    </MetricGroup>
  );
};

export default TransactionsMetricsCard;
