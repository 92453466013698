import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY, useOrgBasedQueryKey } from '../useInvalidateQuery';
import { useSession } from '../useSession';
import { getAssetBreakdown } from 'services/http/analytics/assets';

export const useGetAssetBreakdown = (params: { assetTypes: string[]; legalEntityIds: string[] }) => {
  const { assetTypes, legalEntityIds } = params;
  const getKey = useOrgBasedQueryKey();
  const { organizationId } = useSession();
  return useQuery(
    getKey(QUERY_KEY.ASSET_BREAKDOWN, params),
    async () => {
      const response = await getAssetBreakdown({
        legalEntityIds,
        assetTypes,
      });
      return response.data;
    },
    {
      enabled: !!organizationId,
      staleTime: 1000 * 60 * 30,
      cacheTime: 1000 * 60 * 5,
    },
  );
};
