import { useContext } from 'react';
import { JobProgressContext } from '../context/JobProgressProvider';
import { useSession } from './useSession';
import { JOB_NAME } from 'services/http/job-queue';
import { useCreateExchangeJob } from '../components/integrations/exchanges/useExchangeSourceQueries';

export const useCreateExchangeSyncBgJob = (onSuccess?: () => void, onError?: (error: any) => void) => {
  const { startTrackingJob } = useContext(JobProgressContext);
  const { organizationId } = useSession();

  const { mutateAsync } = useCreateExchangeJob();

  const createExchangeJob = async (accountingPeriodId: string) => {
    try {
      await mutateAsync({
        organizationId,
        accountingPeriodId,
      });
      startTrackingJob(accountingPeriodId, JOB_NAME.IMPORT_EXCHANGE_SOURCE_JOB, false);

      if (onSuccess) onSuccess();
    } catch (error) {
      if (onError) onError(error);
      else throw error;
    }
  };

  return createExchangeJob;
};
