import React, { useState } from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { MdChevronRight } from 'react-icons/md';
import { StyledSubTrigger, StyledSubContent } from '.';
import { useSources, useSourceTags } from '../../hooks/http';
import { mergeSources } from '../templates/utils';
import { Button } from '../atoms';
import { getDisplayedWalletTagsByType, getDisplayedWalletTypeByChain, isScrollToBottom } from './utils';

interface WalletSubMenuProps {
  triggerLabel?: string;
  description?: string;
  callbackFn?: (data: { type: string; value: string }) => void;
  showTags?: boolean;
}

export const WalletSubMenu = ({
  triggerLabel = 'Wallet',
  description,
  callbackFn,
  showTags = false,
}: WalletSubMenuProps) => {
  const {
    data: sources,
    fetchNextPage,
    hasNextPage,
    isLoading: newSourcesLoading,
  } = useSources({
    pageSize: 1000,
  });

  const { data: tags } = useSourceTags();
  const mergedSources = mergeSources(sources);

  const inViewCallbackFn = async () => hasNextPage && fetchNextPage();
  return (
    <DropdownMenu.Sub>
      <StyledSubTrigger>
        <div className='flex justify-between items-center w-full'>
          <div className='flex items-center py-2'>
            <div className='w-10 h-10 border border-[#F2F2F2] rounded-sm bg-[#F8F8F8] mr-2' />
            <div>
              <p className='text-[#222222] mb-0'>{triggerLabel}</p>
              <p className='text-[#828282] mb-0'>{description}</p>
            </div>
          </div>
          <MdChevronRight width='7' height='11' />
        </div>
      </StyledSubTrigger>

      <StyledSubContent>
        <DropdownMenu.Sub>
          <DropdownMenu.Sub>
            <StyledSubTrigger>
              <div className='flex justify-between items-center w-full'>
                <div className='flex items-center py-2'>
                  <div className='w-10 h-10 border border-[#F2F2F2] rounded-sm bg-[#F8F8F8] mr-2' />
                  <div>
                    <p className='text-[#222222] mb-0'>Internal sources</p>
                    <p className='text-[#828282] mb-0' />
                  </div>
                </div>
                <MdChevronRight width='7' height='11' />
              </div>
            </StyledSubTrigger>
            <StyledSubContent className='z-[500]'>
              <DropdownMenu.Sub>
                <StyledSubTrigger>
                  <div className='flex justify-between items-center w-full'>
                    <div className='flex items-center py-2'>
                      <div className='w-10 h-10 border border-[#F2F2F2] rounded-sm bg-[#F8F8F8] mr-2' />
                      <div>
                        <p className='text-[#222222] mb-0'>Bitcoin</p>
                        <p className='text-[#828282] mb-0' />
                      </div>
                    </div>
                    <MdChevronRight width='7' height='11' />
                  </div>
                </StyledSubTrigger>
                <StyledSubContent className='z-[150]'>
                  <InViewWrapper
                    className='max-h-[150px] overflow-auto'
                    callbackFn={inViewCallbackFn}
                    newSourcesLoading={newSourcesLoading}
                  >
                    {getDisplayedWalletTypeByChain('internal', 'btc', mergedSources, callbackFn)}
                  </InViewWrapper>
                </StyledSubContent>
              </DropdownMenu.Sub>

              <DropdownMenu.Sub>
                <StyledSubTrigger>
                  <div className='flex justify-between items-center w-full'>
                    <div className='flex items-center py-2'>
                      <div className='w-10 h-10 border border-[#F2F2F2] rounded-sm bg-[#F8F8F8] mr-2' />
                      <div>
                        <p className='text-[#222222] mb-0'>Ethereum</p>
                        <p className='text-[#828282] mb-0' />
                      </div>
                    </div>
                    <MdChevronRight width='7' height='11' />
                  </div>
                </StyledSubTrigger>
                <StyledSubContent className='z-[200] overflow-auto'>
                  <div>
                    <InViewWrapper
                      className='max-h-[150px] overflow-auto'
                      callbackFn={inViewCallbackFn}
                      newSourcesLoading={newSourcesLoading}
                    >
                      {getDisplayedWalletTypeByChain('internal', 'eth', mergedSources, callbackFn)}
                    </InViewWrapper>
                  </div>
                </StyledSubContent>
              </DropdownMenu.Sub>

              <DropdownMenu.Sub>
                <StyledSubTrigger>
                  <div className='flex justify-between items-center w-full'>
                    <div className='flex items-center py-2'>
                      <div className='w-10 h-10 border border-[#F2F2F2] rounded-sm bg-[#F8F8F8] mr-2' />
                      <div>
                        <p className='text-[#222222] mb-0'>Polygon</p>
                        <p className='text-[#828282] mb-0' />
                      </div>
                    </div>
                    <MdChevronRight width='7' height='11' />
                  </div>
                </StyledSubTrigger>
                <StyledSubContent className='z-[200] overflow-auto'>
                  <div>
                    <InViewWrapper
                      className='max-h-[150px] overflow-auto'
                      callbackFn={inViewCallbackFn}
                      newSourcesLoading={newSourcesLoading}
                    >
                      {getDisplayedWalletTypeByChain('internal', 'polygon', mergedSources, callbackFn)}
                    </InViewWrapper>
                  </div>
                </StyledSubContent>
              </DropdownMenu.Sub>
            </StyledSubContent>
          </DropdownMenu.Sub>

          <DropdownMenu.Sub>
            <StyledSubTrigger>
              <div className='flex justify-between items-center w-full'>
                <div className='flex items-center py-2'>
                  <div className='w-10 h-10 border border-[#F2F2F2] rounded-sm bg-[#F8F8F8] mr-2' />
                  <div>
                    <p className='text-[#222222] mb-0'>External sources</p>
                    <p className='text-[#828282] mb-0' />
                  </div>
                </div>
                <MdChevronRight width='7' height='11' />
              </div>
            </StyledSubTrigger>
            <StyledSubContent>
              <DropdownMenu.Sub>
                <StyledSubTrigger>
                  <div className='flex justify-between items-center w-full'>
                    <div className='flex items-center py-2'>
                      <div className='w-10 h-10 border border-[#F2F2F2] rounded-sm bg-[#F8F8F8] mr-2' />
                      <div>
                        <p className='text-[#222222] mb-0'>Bitcoin</p>
                        <p className='text-[#828282] mb-0' />
                      </div>
                    </div>
                    <MdChevronRight />
                  </div>
                </StyledSubTrigger>
                <StyledSubContent className='z-[150]'>
                  <InViewWrapper
                    className='max-h-[150px] overflow-auto'
                    callbackFn={inViewCallbackFn}
                    newSourcesLoading={newSourcesLoading}
                  >
                    {getDisplayedWalletTypeByChain('external', 'btc', mergedSources, callbackFn)}
                  </InViewWrapper>
                </StyledSubContent>
              </DropdownMenu.Sub>

              <DropdownMenu.Sub>
                <StyledSubTrigger>
                  <div className='flex justify-between items-center w-full'>
                    <div className='flex items-center py-2'>
                      <div className='w-10 h-10 border border-[#F2F2F2] rounded-sm bg-[#F8F8F8] mr-2' />
                      <div>
                        <p className='text-[#222222] mb-0'>Ethereum</p>
                        <p className='text-[#828282] mb-0' />
                      </div>
                    </div>
                    <MdChevronRight width='7' height='11' />
                  </div>
                </StyledSubTrigger>
                <StyledSubContent className='z-[150]'>
                  <InViewWrapper
                    className='max-h-[150px] overflow-auto'
                    callbackFn={inViewCallbackFn}
                    newSourcesLoading={newSourcesLoading}
                  >
                    {getDisplayedWalletTypeByChain('external', 'eth', mergedSources, callbackFn)}
                  </InViewWrapper>
                </StyledSubContent>
              </DropdownMenu.Sub>
            </StyledSubContent>
          </DropdownMenu.Sub>

          {showTags && (
            <DropdownMenu.Sub>
              <StyledSubTrigger>
                <div className='flex justify-between items-center w-full'>
                  <div className='flex items-center py-2'>
                    <div className='w-10 h-10 border border-[#F2F2F2] rounded-sm bg-[#F8F8F8] mr-2' />
                    <div>
                      <p className='text-[#222222] mb-0'>Tagged</p>
                      <p className='text-[#828282] mb-0' />
                    </div>
                  </div>
                  <MdChevronRight width='7' height='11' />
                </div>
              </StyledSubTrigger>
              <StyledSubContent>
                <DropdownMenu.Sub>
                  <StyledSubTrigger>
                    <div className='flex justify-between items-center w-full'>
                      <div className='flex items-center py-2'>
                        <div className='w-10 h-10 border border-[#F2F2F2] rounded-sm bg-[#F8F8F8] mr-2' />
                        <div>
                          <p className='text-[#222222] mb-0'>Internal</p>
                          <p className='text-[#828282] mb-0' />
                        </div>
                      </div>
                      <MdChevronRight />
                    </div>
                  </StyledSubTrigger>
                  <StyledSubContent className='z-[150]'>
                    <InViewWrapper
                      className='max-h-[150px] overflow-auto'
                      callbackFn={inViewCallbackFn}
                      newSourcesLoading={newSourcesLoading}
                    >
                      {getDisplayedWalletTagsByType('internal', tags, callbackFn)}
                    </InViewWrapper>
                  </StyledSubContent>
                </DropdownMenu.Sub>

                <DropdownMenu.Sub>
                  <StyledSubTrigger>
                    <div className='flex justify-between items-center w-full'>
                      <div className='flex items-center py-2'>
                        <div className='w-10 h-10 border border-[#F2F2F2] rounded-sm bg-[#F8F8F8] mr-2' />
                        <div>
                          <p className='text-[#222222] mb-0'>External</p>
                          <p className='text-[#828282] mb-0' />
                        </div>
                      </div>
                      <MdChevronRight width='7' height='11' />
                    </div>
                  </StyledSubTrigger>
                  <StyledSubContent className='z-[150]'>
                    <InViewWrapper
                      className='max-h-[150px] overflow-auto'
                      callbackFn={inViewCallbackFn}
                      newSourcesLoading={newSourcesLoading}
                    >
                      {getDisplayedWalletTagsByType('external', tags, callbackFn)}
                    </InViewWrapper>
                  </StyledSubContent>
                </DropdownMenu.Sub>
              </StyledSubContent>
            </DropdownMenu.Sub>
          )}
        </DropdownMenu.Sub>
      </StyledSubContent>
    </DropdownMenu.Sub>
  );
};

const InViewWrapper = ({ children, callbackFn, className, newSourcesLoading }) => {
  const [loading, setLoading] = useState(false);
  return (
    <div
      className={className}
      onScroll={async (e) => {
        if (isScrollToBottom(e)) {
          // handle loading
          setLoading(true);
          await callbackFn();
          setLoading(false);
        }
      }}
    >
      {children}
      {(loading || newSourcesLoading) && <Button className='loading' />}
    </div>
  );
};
