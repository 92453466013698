import queryString from 'query-string';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_FOR_CSV_EXPORT, getDefaultSortFilterByPropertyName } from '../constants';

/**
 * @deprecated This util function is deprecated in favor of prepareSearchParams
 * @returns string
 */

export const buildMultiParams = (paramList, key): string => {
  if (!paramList || paramList.length === 0) {
    return '';
  }
  return paramList?.reduce((a, b) => `${a}&${key}=${b}`, '');
};

type PrepareSearchParamsOptions = {
  isPaginated?: boolean;
  defaultSortFilterPropertyName?: string;
};

export const prepareSearchParams = (params, options: PrepareSearchParamsOptions = {}) => {
  const { isPaginated, defaultSortFilterPropertyName = 'createdAt' } = options;
  const { sort = getDefaultSortFilterByPropertyName(defaultSortFilterPropertyName), ...rest } = params;
  const sortFilter = { sortBy: sort.id, sortDirection: sort.desc ? 'desc' : 'asc' };

  // queryString.stringify will handle boolean and truthy values appropriately
  return isPaginated
    ? queryString.stringify({ ...rest, ...sortFilter })
    : queryString.stringify({
        ...rest,
        ...sortFilter,
        page: params.page ?? 0,
        pageSize: params.pageSize ?? params.isForCsvExport ? DEFAULT_PAGE_SIZE_FOR_CSV_EXPORT : DEFAULT_PAGE_SIZE,
      });

  // const filter: any = {};

  // const keys = Object.keys(rest);
  // for (const key of keys) {
  //   if (typeof rest[key] === 'boolean') {
  //     filter[key] = rest[key] ? 'true' : 'false';
  //   } else {
  //     if (rest[key] || rest[key] === 0) filter[key] = rest[key];
  //   }
  // }

  // return isPaginated
  //   ? queryString.stringify({ ...filter, ...sortFilter })
  //   : queryString.stringify({
  //       ...filter,
  //       ...sortFilter,
  //       page: params.page ?? 0,
  //       pageSize: params.pageSize ?? params.isForCsvExport ? DEFAULT_PAGE_SIZE_FOR_CSV_EXPORT : DEFAULT_PAGE_SIZE,
  //     });
};

export const createQueryStringFromObject = (params) =>
  Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');
