import { DropDownSelect } from '../../../../atoms';
import { useRuleConfigurator } from '../../rule-configurator-context';
import { selectorStyle } from './selector-styles';
import { EQUAL_NOTEQUAL_OPERATORS } from '../../data';
import { LedgerAccountInlineConditionMenu } from '../../../LedgerAccountInlineConditionMenu';
import { ConditionRow } from './ConditionRow';
import { FACT } from '../../types';
import { useCondition } from '../useCondition';

const Row = () => {
  const { updateCondition, isDisabled } = useRuleConfigurator();
  const condition = useCondition();
  return (
    <>
      <DropDownSelect
        customStyle={selectorStyle}
        defaultValue={{ label: 'Ledger Account', value: 'Ledger Account' }}
        isDisabled
      />
      <DropDownSelect
        customStyle={selectorStyle}
        options={EQUAL_NOTEQUAL_OPERATORS}
        value={EQUAL_NOTEQUAL_OPERATORS.find((item) => item.value === condition.operator)}
        onChange={({ value }) => {
          updateCondition(condition.id, {
            operator: value,
          });
        }}
        isDisabled={isDisabled}
      />
      <LedgerAccountInlineConditionMenu
        isDisabled={isDisabled}
        rule={condition}
        callbackFn={(value) => {
          updateCondition(condition.id, {
            value,
          });
        }}
      />
    </>
  );
};

export const LedgerAccountRuleCondition = () => (
  <ConditionRow facts={[FACT.LEDGER_ACCOUNT]}>
    <Row />
  </ConditionRow>
);
