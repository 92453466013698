import React from 'react';
import { TabBody, TabRoot } from '../../atoms/headlessUI/Tabs';
import { ReviewBalanceSheetProps } from '../types';
import { useBalanceSheet } from '../../../hooks/http/useBalanceSheet';
import { useIncomeStatement, useLegalEntities } from '../../../hooks';
import { mergeLegalEntities } from '../../templates/utils';
import AccountBalanceStatements from './AccountBalanceStatements';
import { LoaderIcon, LOADER_ICON_SIZE } from 'ui';

export function ReviewBalances({ accountingPeriodId }: ReviewBalanceSheetProps) {
  const { data: legalEntities } = useLegalEntities();
  const legalEntityIds: string[] = mergeLegalEntities(legalEntities).map(({ _id }) => _id);

  const { data: balanceSheet, isLoading: isLoadingBalanceSheet } = useBalanceSheet(legalEntityIds, [
    accountingPeriodId,
  ]);
  const { data: incomeStatement, isLoading: isLoadingIncomeStatement } = useIncomeStatement(legalEntityIds, [
    accountingPeriodId,
  ]);
  return (
    <div className='w-full h-[362px]'>
      <TabRoot
        headerItems={[
          {
            title: 'Income statement',
          },
          {
            title: 'Balance sheet',
          },
        ]}
      >
        <TabBody>
          {!incomeStatement && isLoadingIncomeStatement && (
            <div className='flex justify-center items-center my-12'>
              <LoaderIcon size={LOADER_ICON_SIZE.LARGE} />
            </div>
          )}
          {incomeStatement && (
            <>
              <p className='text-xl mb-2'>
                {incomeStatement.balances[accountingPeriodId]?.debitsAndCreditsPerLedgerAccount?.length || 0} items
              </p>
              <div className={'max-h-[250px] overflow-auto flex flex-col gap-y-2'}>
                <AccountBalanceStatements
                  ledgerAccountsInStatement={incomeStatement.ledgerAccounts}
                  debitsAndCreditsPerLedgerAccountInStatement={
                    incomeStatement.balances[accountingPeriodId]?.debitsAndCreditsPerLedgerAccount
                  }
                  type='incomestatement'
                />
              </div>
            </>
          )}
        </TabBody>
        <TabBody>
          {!balanceSheet && isLoadingBalanceSheet && (
            <div className='flex justify-center items-center my-12'>
              <LoaderIcon size={LOADER_ICON_SIZE.LARGE} />
            </div>
          )}
          {balanceSheet && (
            <>
              <p className='text-xl mb-2'>
                {balanceSheet?.balances[accountingPeriodId]?.debitsAndCreditsPerLedgerAccount?.length || 0} items
              </p>
              <div className={'max-h-[250px] overflow-auto flex flex-col gap-y-2'}>
                <AccountBalanceStatements
                  ledgerAccountsInStatement={balanceSheet?.ledgerAccounts}
                  debitsAndCreditsPerLedgerAccountInStatement={
                    balanceSheet?.balances[accountingPeriodId]?.debitsAndCreditsPerLedgerAccount
                  }
                  type='balancesheet'
                />
              </div>
            </>
          )}
        </TabBody>
      </TabRoot>
    </div>
  );
}
