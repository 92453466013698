import React from 'react';
import { MetricCard, MetricGroup } from '../../atoms';

export const OverviewMetrics = ({ data, loading = false }) => {
  return (
    <MetricGroup className='grid-cols-[repeat(auto-fit,_minmax(350px,_1fr))] max-w-[900px] gap-4 !mt-0 mb-6 !p-0'>
      <MetricCard loading={loading} label='TRANSACTIONS IMPORTED' metric={data?.transactionsCount} />
      <MetricCard loading={loading} label='JOURNAL ENTRIES RECORDED' metric={data?.journalEntriesCount} />
      <MetricCard loading={loading} label='LEDGER ACCOUNTS CREATED' metric={data?.ledgerAccountsCount} />
      <MetricCard loading={loading} label='CONNECTED WALLETS' metric={data?.walletsCount} />
    </MetricGroup>
  );
};

export default OverviewMetrics;
