import { useGetSourceById } from '../../../hooks/http';
import { SidebarProps } from '../SidebarGlobal';
import { changeRouteName } from '../../templates/utils';
import { ManageSourceSidebarContent } from '../../Sources';

const EntitySidebar: React.FC<SidebarProps> = ({
  id,
  onBack,
  handlePrimaryPanelClose,
  isDockPanelAvailable,
  isPrimary,
  defaultState,
}) => {
  const { data: wallet, isLoading } = useGetSourceById(id);

  changeRouteName({ name: wallet?.name ?? wallet?.address, UUID: id, isLoading });

  return (
    <ManageSourceSidebarContent
      onBack={onBack}
      isDockPanelAvailable={isDockPanelAvailable}
      onClose={() => handlePrimaryPanelClose && handlePrimaryPanelClose()}
      sourceId={id}
      isPrimary={isPrimary}
      defaultState={defaultState}
    />
  );
};

export default EntitySidebar;
