import axios from 'axios';
import { SERVER_URL_JOB_QUEUE } from '../config';
import { JobConfiguration } from 'schemas';

export enum JOB_NAME {
  IMPORT_TRANSACTION_JOB = 'IMPORT_TRANSACTION_JOB',
  RULESET_JOB = 'RULESET_JOB',
  IMPAIRMENT_JOB = 'IMPAIRMENT_JOB',
  RULE_METRICS_JOB = 'RULE_METRICS_JOB',
  SYNC_JOURNAL_ENTRY_JOB = 'SYNC_JOURNAL_ENTRY_JOB',
  IMPORT_EXCHANGE_SOURCE_JOB = 'IMPORT_EXCHANGE_SOURCE_JOB',
  ORGANIZATION_RULESET_JOB = 'ORGANIZATION_RULESET_JOB',
  IMPORT_HEDGEY_SOURCE_JOB = 'IMPORT_HEDGEY_SOURCE_JOB',
  ORGANIZATION_HEDGEY_SOURCE_JOB = 'ORGANIZATION_HEDGEY_SOURCE_JOB',
  RAINCARDS_CARD_JOB = 'RAINCARDS_CARD_JOB',
  RAINCARDS_TRANSACTION_JOB = 'RAINCARDS_TRANSACTION_JOB',
  LOOP_TRANSACTION_JOB = 'LOOP_TRANSACTION_JOB',
}

export type UseJobProgressPayload = {
  jobName: JOB_NAME;
  referenceId: string;
  organizationId: string;
};

export const enum JOB_STATUS {
  STARTED = 'STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  JOB_FAILED = 'JOB_FAILED',
  CANCELED = 'CANCELED',
}

export type LeanJobConfig = {
  jobId: string;
  jobStatus: JOB_STATUS;
  referenceId: string;
  jobName: JOB_NAME;
  organizationId: string;
  toProcessCount?: number;
  completedCount?: number;
  message?: string;
  cronExpression?: string;
  isPaused?: boolean;
  isDeleted?: boolean;
};

export const getJobProgress = async (data: UseJobProgressPayload) =>
  axios.get<LeanJobConfig>(
    `${SERVER_URL_JOB_QUEUE}/job-progress?jobName=${data.jobName}&referenceId=${data.referenceId}`,
  );

export const getJobConfigurationById = async (data: { jobConfigurationId: string }) =>
  axios.get<{ jobConfiguration: JobConfiguration }>(
    `${SERVER_URL_JOB_QUEUE}/job-configuration-by-id?jobConfigurationId=${data.jobConfigurationId}`,
  );
export const getJobConfigurationByJobNameAndReferenceId = async (data: { jobName: JOB_NAME; referenceId: string }) =>
  axios.get<{ jobConfiguration: JobConfiguration }>(
    `${SERVER_URL_JOB_QUEUE}/job-configuration?jobName=${data.jobName}&referenceId=${data.referenceId}`,
  );
