import { useMutation, useQuery } from '@tanstack/react-query';
import {
  createSyncJournalEntryJob,
  getAllGlIntegrationConfigs,
  getAllIntegrationStatus,
  getAllSyncHistoryOperationRecords,
  getExternalAccounts,
  getGlIntegrationConfig,
  getLedgerAccountMappings,
  syncJournalEntries,
  updateGlIntegrationConfig,
} from 'services/http/gl-integration-service';
import { ExternalAccountPayload, GLType } from 'services/http/types';
import { useSession } from '../useSession';
import { QUERY_KEY, useOrgBasedQueryKey } from '../useInvalidateQuery';

export const useGetStatusForAllIntegrations = (organizationId: string) => {
  const getKey = useOrgBasedQueryKey();
  return useQuery(
    getKey(QUERY_KEY.INTEGRATION_AUTHS),
    async () => {
      const response = await getAllIntegrationStatus(organizationId);
      return response.data;
    },
    {
      enabled: !!organizationId,
      staleTime: 30000,
      cacheTime: 30000,
      refetchOnWindowFocus: false,
    },
  );
};

export const useExternalAccounts = (payload: ExternalAccountPayload, enabled) => {
  const { organizationId } = useSession();
  const getKey = useOrgBasedQueryKey();
  return useQuery(
    getKey(QUERY_KEY.EXTERNAL_ACCOUNTS, { glType: payload.glType, realmId: payload.realmId }),
    async () => {
      const response = await getExternalAccounts(payload);
      return response.data;
    },
    {
      enabled: enabled && !!organizationId,
      staleTime: 30000,
      cacheTime: 30000,
      refetchOnWindowFocus: false,
    },
  );
};

export const useExternalAccountMappings = (organizationId: string, glType: GLType, realmId: string) => {
  const getKey = useOrgBasedQueryKey();
  return useQuery(
    getKey(QUERY_KEY.EXTERNAL_ACCOUNT_MAPPINGS, { glType, realmId }),
    async () => {
      const response = await getLedgerAccountMappings(organizationId, glType, realmId);
      console.log(response.data);
      return response.data;
    },
    {
      enabled: !!(organizationId && glType && realmId),
      staleTime: 30000,
      cacheTime: 30000,
      refetchOnWindowFocus: false,
    },
  );
};

export const useSyncJournalEntriesMutation = () =>
  useMutation((data: { organizationId: string; accountingPeriodId: string; glType: GLType }) =>
    syncJournalEntries(data.organizationId, data.accountingPeriodId, data.glType),
  );

export const useSyncJournalEntriesJobMutation = () =>
  useMutation(
    (data: {
      organizationId: string;
      accountingPeriodId: string;
      userId: string;
      integrationType: GLType;
      realmId: string;
    }) =>
      createSyncJournalEntryJob(
        data.organizationId,
        data.accountingPeriodId,
        data.userId,
        data.integrationType,
        data.realmId,
      ),
  );

export const useGetAllSyncHistoryRecordsQuery = (filters: {
  organizationId?: string;
  integrationType?: GLType;
  cacheTime?: string;
  staleTime?: string;
}) => {
  const getKey = useOrgBasedQueryKey();

  return useQuery(
    getKey(QUERY_KEY.SYNC_HISTORY_RECORDS, { integrationType: filters?.integrationType }),
    () => getAllSyncHistoryOperationRecords(filters),
    {
      enabled: !!filters.organizationId,
    },
  );
};
export const useGetGlIntegrationConfigQuery = (glType: GLType, realmId: string) => {
  const { organizationId } = useSession();
  const getKey = useOrgBasedQueryKey();
  return useQuery(
    getKey(QUERY_KEY.INTEGRATION_CONFIGS, { glType, realmId }),
    () => getGlIntegrationConfig(organizationId, glType, realmId),
    {
      enabled: !!organizationId,
    },
  );
};

export const useGetAllGlIntegrationsByGlType = (glType: GLType) => {
  const { organizationId } = useSession();
  const getKey = useOrgBasedQueryKey();
  return useQuery(
    getKey(QUERY_KEY.GET_ALL_GL_INTEGRATION_CONFIGS, { glType }),
    () => getAllGlIntegrationConfigs(organizationId, glType),
    {
      enabled: !!organizationId,
    },
  );
};

export const useUpdateGlIntegrationConfigMutation = () => {
  const { organizationId } = useSession();
  return useMutation((data: { glType: GLType; shouldRolledUp: boolean; legalEntityId: string; realmId: string }) =>
    updateGlIntegrationConfig(organizationId, data.glType, data.shouldRolledUp, data.legalEntityId, data.realmId),
  );
};
