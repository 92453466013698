import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useCsvExportForTransactions } from '../../../hooks';
import {
  getAddressesFromFilteredWallet,
  getShowTransactionsWithoutJournalEntriesBooleanOrUndefined,
  getSourceAndWalletdIds,
} from './utils';
import { FILTER_TYPE } from '../../filters';
import { WALLET_TYPE } from 'services/http/response.types';
import { Button } from 'ui';
import { ClosePeriodWizard } from '../../dialog';
import dynamic from 'next/dynamic';

const CSVLink = dynamic(() => import('react-csv').then((res) => res.CSVLink), { loading: () => <></>, ssr: false });

export const TransactionsCSV = ({
  filterHelpers,
  startDate,
  endDate,
  searchTerm,
  sortState,
  displayedTransactions,
  debouncedSearchTerm,
  hideClosePeriodButton = false,
}) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const { sourceIds, walletIds } = getSourceAndWalletdIds();

  const transactionsCSVParams = useMemo(() => {
    return {
      directions: filterHelpers[FILTER_TYPE.DIRECTION]?.getAllSelectedWithTransform(),
      chains: filterHelpers[FILTER_TYPE.CHAIN]?.getAllSelectedWithTransform(),
      transactionFrom: startDate,
      transactionTo: endDate,
      accountingPeriodIds: filterHelpers[FILTER_TYPE.ACCOUNTING_PERIOD]?.getAllSelectedWithTransform(),
      legalEntityIds: filterHelpers[FILTER_TYPE.LEGAL_ENTITY]?.getAllSelectedWithTransform(),
      assetTypes: filterHelpers[FILTER_TYPE.ASSET]?.getAllSelectedWithTransform(),
      addresses: filterHelpers[FILTER_TYPE.WALLET]
        ?.getAllSelectedWithTransform()
        .filter(({ walletType }) => walletType === WALLET_TYPE.EXTERNAL)
        .map(getAddressesFromFilteredWallet)
        .flat(), // this return { _id, adress, addresses } for each wallet apps/next/src/components/filters/transforms.ts
      walletIds,
      sourceIds,
      sort: sortState[0],
      showTransactionsWithoutJournalEntries: getShowTransactionsWithoutJournalEntriesBooleanOrUndefined(filterHelpers),
      searchTerm,
    };
  }, [filterHelpers, startDate, endDate, walletIds, sourceIds, sortState, debouncedSearchTerm]);

  const { prepare: handleDownloadTransactions, csvData } = useCsvExportForTransactions(transactionsCSVParams);

  const csvDownloadRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (csvData?.length) {
      csvDownloadRef.current?.click();
    }
  }, [csvData]);

  return (
    <>
      <CSVLink
        data={csvData ?? ''}
        target='_blank'
        filename={`transactions-export-${new Date().toLocaleDateString()}.csv`}
        className='hidden'
      >
        <span ref={csvDownloadRef} />
      </CSVLink>
      <div className='flex gap-x-2'>
        <Button
          emphasis={hideClosePeriodButton ? 'high' : 'medium'}
          onClick={async () => {
            setIsDeleting(true);
            await handleDownloadTransactions();
            setIsDeleting(false);
          }}
          disabled={!displayedTransactions.length}
          isLoading={isDeleting}
          label='Download'
        />
        {!hideClosePeriodButton && <ClosePeriodWizard />}
      </div>
    </>
  );
};

export default TransactionsCSV;
