import React from 'react';
import { useLedgerAccount } from '../../hooks/http';
import { Button, DropDownSelect } from '../atoms';
import { getDisplayedLedgerAccounts, mergeLedgerAccounts } from '../templates/utils';

export const ImpairmentDialogForm = ({ selectedAssets }) => {
  const { data, isLoading } = useLedgerAccount({
    pageSize: 1000,
  });
  const mergedLedgerAccounts = mergeLedgerAccounts(data);
  const displayedLedgerAccounts = getDisplayedLedgerAccounts(mergedLedgerAccounts);
  const ledgerAccountIds = selectedAssets?.map((asset) => asset?.ledgerAccountId?._id);

  const creditedLedgerAccounts = displayedLedgerAccounts?.filter((item) => ledgerAccountIds?.includes(item.value));
  const renderLedgerAccounts = () =>
    creditedLedgerAccounts.map((item) => (
      <Button key={item} className='btn--modal-badge'>
        {item.label}
      </Button>
    ));
  return (
    <div>
      <div className='font-medium'>Ledger account (Credit)</div>
      <div>Credit asset account balance</div>
      <div className='flex flex-wrap'>{renderLedgerAccounts()}</div>

      <div className='mt-4 font-medium'>Ledger account (Debit)</div>
      <div>Debit impairment loss</div>
      <DropDownSelect className='mt-2' options={displayedLedgerAccounts} isLoading={isLoading} />
    </div>
  );
};
