import React, { useEffect, useRef, useState } from 'react';
import { useCsvExportForJE } from '../../../hooks';
import { FILTER_TYPE } from '../../filters';
import { Button } from 'ui';
import dynamic from 'next/dynamic';

const CSVLink = dynamic(() => import('react-csv').then((res) => res.CSVLink), { loading: () => <></>, ssr: false });

export const JournalsCSV = ({ filterHelpers, datesWithTzOffset, sortState, isLoading, debouncedSearchTerm }) => {
  const [downloadLoading, setDownloadLoading] = useState(false);

  const { csvData, prepare } = useCsvExportForJE({
    transactionIds: [],
    status: filterHelpers[FILTER_TYPE.STATUS]?.getAllSelectedWithTransform(),
    originatedBy: filterHelpers[FILTER_TYPE.ORIGINATED_BY]?.getAllSelectedWithTransform(),
    accountingPeriodIds: filterHelpers[FILTER_TYPE.ACCOUNTING_PERIOD]?.getAllSelectedWithTransform(),
    legalEntityIds: filterHelpers[FILTER_TYPE.LEGAL_ENTITY]?.getAllSelectedWithTransform() ?? [],
    startDate: datesWithTzOffset.start,
    endDate: datesWithTzOffset.end,
    sort: sortState[0],
    searchTerm: debouncedSearchTerm,
  });
  const csvDownloadRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    if (!!csvData?.length && csvDownloadRef?.current) {
      csvDownloadRef.current.click();
    }
  }, [csvData]);

  return (
    <>
      <CSVLink
        data={csvData ?? ''}
        target='_blank'
        className='hidden'
        filename={`journals-export-${new Date().toLocaleDateString()}.csv`}
      >
        <span ref={csvDownloadRef}></span>
      </CSVLink>
      <Button
        label='Download'
        emphasis='medium'
        onClick={async () => {
          setDownloadLoading(true);
          await prepare();
          setDownloadLoading(false);
        }}
        isLoading={downloadLoading}
        disabled={isLoading || downloadLoading}
      />
    </>
  );
};

export default JournalsCSV;
