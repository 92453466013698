import { useEffect, useMemo, useRef, useState } from 'react';
import {
  Button,
  Dropdown,
  DropdownBody,
  DropdownContent,
  DropdownTrigger,
  DropdownFooter,
  DropdownHeader,
  getFilterDropdownSectionDataCy,
} from 'ui';
import { MdFilterList } from 'react-icons/md';
import {
  LegalEntitiesFilterRowGroup,
  AccountingPeriodsFilterRowGroup,
  AccountingTreatmentFilterRowGroup,
  ChainsFilterRowGroup,
  DirectionsFilterRowGroup,
  AssetsFilterRowGroup,
  WalletsFilterRowGroup,
  TagsFilterRowGroup,
  OriginatedByFilterRowGroup,
  StatusFilterRowGroup,
  ImpairedFilterRowGroup,
  WalletTypesFilterRowGroup,
} from './all-filters-dropdown-row-groups';
import { AllFiltersDropdownProps, FILTER_TYPE, StringFilter, FilterState } from './types';
import { AccountingPeriod, LegalEntity, Tag, Wallet } from 'services/http/response.types';
import { ClassificationFilterRowGroup } from './all-filters-dropdown-row-groups/ClassificationFilterRowGroup';

function shouldIncrementActiveFilterTypesCount<T>(state?: FilterState<T>) {
  return state && state.ids.filter((id) => state.entities[id].selected).length > 0;
}

export const AllFiltersDropdown = ({
  state,
  onSingleRowChange,
  onClearAll,
  'data-cy': dataCy,
}: AllFiltersDropdownProps) => {
  const triggerRef = useRef<HTMLButtonElement>(null);
  const [selectedFilterTypes, setSelectedFilterTypes] = useState(0);

  useEffect(() => {
    let count = 0;
    if (shouldIncrementActiveFilterTypesCount<AccountingPeriod>(state[FILTER_TYPE.ACCOUNTING_PERIOD])) count += 1;
    if (shouldIncrementActiveFilterTypesCount<StringFilter>(state[FILTER_TYPE.ASSET])) count += 1;
    if (shouldIncrementActiveFilterTypesCount<StringFilter>(state[FILTER_TYPE.CHAIN])) count += 1;
    if (shouldIncrementActiveFilterTypesCount<StringFilter>(state[FILTER_TYPE.CLASSIFICATION])) count += 1;
    if (shouldIncrementActiveFilterTypesCount<StringFilter>(state[FILTER_TYPE.DIRECTION])) count += 1;
    if (shouldIncrementActiveFilterTypesCount<StringFilter>(state[FILTER_TYPE.IMPAIRED])) count += 1;
    if (shouldIncrementActiveFilterTypesCount<LegalEntity>(state[FILTER_TYPE.LEGAL_ENTITY])) count += 1;
    if (shouldIncrementActiveFilterTypesCount<StringFilter>(state[FILTER_TYPE.ORIGINATED_BY])) count += 1;
    if (shouldIncrementActiveFilterTypesCount<StringFilter>(state[FILTER_TYPE.STATUS])) count += 1;
    if (shouldIncrementActiveFilterTypesCount<Tag>(state[FILTER_TYPE.TAG])) count += 1;
    if (shouldIncrementActiveFilterTypesCount<Wallet>(state[FILTER_TYPE.WALLET])) count += 1;
    if (shouldIncrementActiveFilterTypesCount<StringFilter>(state[FILTER_TYPE.WALLET_TYPE])) count += 1;
    if (shouldIncrementActiveFilterTypesCount<StringFilter>(state[FILTER_TYPE.ACCOUNTING_TREATMENT])) count += 1;

    setSelectedFilterTypes(count);
  }, [state]);

  const allFiltersDataCy = useMemo(() => `${dataCy}_allFilters`, [dataCy]);

  return (
    <Dropdown data-cy={allFiltersDataCy}>
      <DropdownTrigger ref={triggerRef}>
        <Button
          data-cy={getFilterDropdownSectionDataCy(allFiltersDataCy).trigger}
          label='All filters'
          labelContainerClassname='font-medium'
          emphasis='medium'
          //className={classNames(selectedFilterTypes > 0 && 'border-indigo-600')}
          leadingIcon={<MdFilterList className='w-5 h-5' />}
          trailingIcon={
            selectedFilterTypes > 0 && (
              <span className='rounded-full bg-indigo-600 text-white p-3 flex items-center justify-center relative'>
                <span className='absolute'>{selectedFilterTypes}</span>
              </span>
            )
          }
        />
      </DropdownTrigger>

      <DropdownContent triggerRef={triggerRef}>
        <DropdownHeader title='All filters' data-cy={getFilterDropdownSectionDataCy(allFiltersDataCy).header} />
        <DropdownBody data-cy={getFilterDropdownSectionDataCy(allFiltersDataCy).body}>
          <LegalEntitiesFilterRowGroup
            state={state[FILTER_TYPE.LEGAL_ENTITY]}
            onSingleChange={onSingleRowChange[FILTER_TYPE.LEGAL_ENTITY]}
            onClearAll={onClearAll}
          />
          <AccountingPeriodsFilterRowGroup
            state={state[FILTER_TYPE.ACCOUNTING_PERIOD]}
            onSingleChange={onSingleRowChange[FILTER_TYPE.ACCOUNTING_PERIOD]}
            onClearAll={onClearAll}
          />
          <AccountingTreatmentFilterRowGroup
            state={state[FILTER_TYPE.ACCOUNTING_TREATMENT]}
            onSingleChange={onSingleRowChange[FILTER_TYPE.ACCOUNTING_TREATMENT]}
            onClearAll={onClearAll}
          />
          <ChainsFilterRowGroup
            state={state[FILTER_TYPE.CHAIN]}
            onSingleChange={onSingleRowChange[FILTER_TYPE.CHAIN]}
          />
          <ClassificationFilterRowGroup
            state={state[FILTER_TYPE.CLASSIFICATION]}
            onSingleChange={onSingleRowChange[FILTER_TYPE.CLASSIFICATION]}
            onClearAll={onClearAll}
          />
          <DirectionsFilterRowGroup
            state={state[FILTER_TYPE.DIRECTION]}
            onSingleChange={onSingleRowChange[FILTER_TYPE.DIRECTION]}
            onClearAll={onClearAll}
          />
          <AssetsFilterRowGroup
            state={state[FILTER_TYPE.ASSET]}
            onSingleChange={onSingleRowChange[FILTER_TYPE.ASSET]}
            onClearAll={onClearAll}
          />
          <WalletsFilterRowGroup
            state={state[FILTER_TYPE.WALLET]}
            onSingleChange={onSingleRowChange[FILTER_TYPE.WALLET]}
            onClearAll={onClearAll}
          />
          <WalletTypesFilterRowGroup
            state={state[FILTER_TYPE.WALLET_TYPE]}
            onSingleChange={onSingleRowChange[FILTER_TYPE.WALLET_TYPE]}
            onClearAll={onClearAll}
          />
          <TagsFilterRowGroup
            state={state[FILTER_TYPE.TAG]}
            onSingleChange={onSingleRowChange[FILTER_TYPE.TAG]}
            onClearAll={onClearAll}
          />
          <OriginatedByFilterRowGroup
            state={state[FILTER_TYPE.ORIGINATED_BY]}
            onSingleChange={onSingleRowChange[FILTER_TYPE.ORIGINATED_BY]}
            onClearAll={onClearAll}
          />
          <StatusFilterRowGroup
            state={state[FILTER_TYPE.STATUS]}
            onSingleChange={onSingleRowChange[FILTER_TYPE.STATUS]}
            onClearAll={onClearAll}
          />
          <ImpairedFilterRowGroup
            state={state[FILTER_TYPE.IMPAIRED]}
            onSingleChange={onSingleRowChange[FILTER_TYPE.IMPAIRED]}
            onClearAll={onClearAll}
          />
        </DropdownBody>

        <DropdownFooter>
          <Button
            data-cy={getFilterDropdownSectionDataCy(allFiltersDataCy).clearAllButton}
            onClick={onClearAll}
            emphasis='low'
            label='Clear'
            labelContainerClassname='text-zinc-500'
          />
        </DropdownFooter>
      </DropdownContent>
    </Dropdown>
  );
};
