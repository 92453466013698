import clsx from 'clsx';
import { classNames } from '../utils';
import { AvatarContainer, AvatarFallback, AvatarImage } from './Avatar';
import { AvatarProps } from './types';

export const Avatar = ({
  /**some comment */
  size = 'extra-large', // 15px
  type = 'person',
  src,
  alt = '',
  emoji,
  initials,
  secondAvatarPosition = 'top',
  containerClassNames,
  secondAvatar,
  isFlipped = false,
}: AvatarProps) => {
  return (
    <>
      {type === 'initials' && (
        <AvatarContainer
          className={classNames(
            clsx(
              'flex justify-center items-center font-semibold',
              { 'w-4 h-4': size === 'extra-small' },
              { 'w-5 h-5': size === 'small' },
              { 'w-6 h-6': size === 'default' },
              { 'w-8 h-8': size === 'medium' },
              { 'w-9 h-9': size === 'large' },
              { 'w-11 h-11': size === 'extra-large' },
            ),
          )}
        >
          <span>{initials?.slice(0, 2) ?? ''}</span>
        </AvatarContainer>
      )}

      {/* avatar of type 'person' | 'flag' | 'chain' have the same styling */}
      {type !== 'two-avatars' && type !== 'initials' && type !== 'emoji' && (
        <AvatarContainer
          className={classNames(
            clsx(
              'rounded-full cover',
              { 'w-4 h-4': size === 'extra-small' },
              { 'w-5 h-5': size === 'small' },
              { 'w-6 h-6': size === 'default' },
              { 'w-8 h-8': size === 'medium' },
              { 'w-9 h-9': size === 'large' },
              { 'w-11 h-11': size === 'extra-large' },
              containerClassNames,
            ),
          )}
        >
          <AvatarImage src={src} alt={alt} />
          <AvatarFallback>
            <img src='https://craftypixels.com/placeholder-image/400x400/e8e8e8/e8e8e8' alt={alt} />
          </AvatarFallback>
        </AvatarContainer>
      )}

      {type === 'emoji' && (
        <div
          className={classNames(
            clsx(
              'rounded-full bg-white cover border-2 border-zinc-100 flex justify-center items-center',
              { 'w-4 h-4 text-[7px]': size === 'extra-small' },
              { 'w-5 h-5 text-[10px]': size === 'small' },
              { 'w-6 h-6 text-xs': size === 'default' },
              { 'w-8 h-8 text-md': size === 'medium' },
              { 'w-9 h-9 text-lg': size === 'large' },
              { 'w-11 h-11 text-2xl ': size === 'extra-large' },
              containerClassNames,
            ),
          )}
        >
          <p
            className={clsx(
              { '-translate-y-[0.5px]': size === 'extra-small' || size === 'small' },
              { '-translate-y-[1px]': size === 'default' || size === 'medium' || size === 'large' },
              { '-translate-y-0.5': size === 'extra-large' },
              containerClassNames,
            )}
          >
            {emoji}
          </p>
        </div>
      )}

      {type === 'two-avatars' && (
        <div className='relative '>
          {isFlipped ? (
            <div className='[&>svg]:w-11 [&>svg]:h-11 [&>img]:w-11 [&>img]:h-11 w-11 h-11 [&>svg]:border-none'>
              {secondAvatar}
            </div>
          ) : (
            <div
              className={clsx(
                'rounded-full object-cover',
                { 'w-6 h-6': size === 'default' || size === 'small' || size === 'extra-small' },
                { 'w-11 h-11': size === 'extra-large' || size === 'medium' || size === 'large' },
              )}
            >
              <img
                src={src}
                alt={alt}
                className={clsx(
                  'object-cover bg-white',
                  { 'w-6 h-6': size === 'default' || size === 'small' || size === 'extra-small' },
                  { 'w-11 h-11': size === 'extra-large' || size === 'medium' || size === 'large' },
                )}
              />
            </div>
          )}

          <div
            className={classNames(
              clsx(
                'absolute rounded-full  bg-white',
                '[&>img]:border [&>img]:border-white [&>img]:rounded-full [&>svg]:border [&>svg]:border-white  [&>svg]:rounded-full',
                {
                  'top-0 left-0 -translate-x-0.5-translate-y-0.5':
                    secondAvatarPosition === 'top' && (size === 'extra-large' || size === 'medium' || size === 'large'),
                },
                {
                  'right-0 bottom-0 translate-x-0.5 translate-y-0.5':
                    secondAvatarPosition === 'bottom' &&
                    (size === 'extra-large' || size === 'medium' || size === 'large'),
                },

                {
                  'top-0 left-0 -translate-x-2 -translate-y-2':
                    secondAvatarPosition === 'top' &&
                    (size === 'default' || size === 'small' || size === 'extra-small'),
                },
                {
                  'right-0 bottom-0 translate-x-2 translate-y-2':
                    secondAvatarPosition === 'bottom' &&
                    (size === 'default' || size === 'small' || size === 'extra-small'),
                },
                { 'w-4 h-4': size === 'default' || size === 'small' || size === 'extra-small' },
                { 'w-5 h-5': size === 'extra-large' || size === 'medium' || size === 'large' },
              ),
            )}
          >
            <img src={src} alt={alt} className='rounded-full object-cover w-full h-full' />
          </div>
        </div>
      )}
    </>
  );
};
