import React from 'react';
import { MdSearch } from 'react-icons/md';
import { InputWithExtras } from 'ui';
import ConditionItemsWrapper from './ConditionItemsWrapper';

export const NewConditionSelector = ({ children }) => {
  return (
    <div className='w-fit h-[390px] shadow-[0px_4px_8px_rgba(0,0,0,0.17)] pb-3 pt-1 px-4 rounded-md bg-white flex flex-col gap-2 relative'>
      <div className='my-2'>
        <InputWithExtras type='text' placeholder='Search...' leading={<MdSearch className='w-5 h-5' />} />
      </div>
      <ConditionItemsWrapper>{children}</ConditionItemsWrapper>
    </div>
  );
};

export default NewConditionSelector;
