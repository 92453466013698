import { produce } from 'immer';
import { GATE_TYPE, RuleConfiguratorState } from '../../types';
import short from 'short-uuid';
import { copyCondition } from './copyCondition';
const translator = short();

export const copyConditionGroup = (
  state: RuleConfiguratorState,
  childId: string,
  parentId: string,
  shouldReplace = false,
) => {
  const newState = produce(state, (draft) => {
    const conditionGroupToCopy = draft.conditionGroups[childId];
    const newId = translator.new().toString();

    draft.conditionGroups[newId] = {
      ...conditionGroupToCopy,
      id: newId,
      parentId,
    };
    draft.conditionGroupIds.push(newId);
    if (shouldReplace) {
      const indexOfChildInParent = draft.conditionGroups[parentId].childIds.findIndex((id) => id === childId);
      draft.conditionGroups[parentId].childIds.splice(indexOfChildInParent, 1, newId);
    } else {
      const indexOfChildInParent = draft.conditionGroups[parentId].childIds.findIndex((id) => id === childId);
      draft.conditionGroups[parentId].childIds.splice(indexOfChildInParent, 0, newId);
      draft.conditionGroups[parentId].gates.splice(indexOfChildInParent, 0, GATE_TYPE.AND);
    }

    const nested: string[] = [];

    for (const childId of draft.conditionGroups[newId].childIds) {
      if (draft.conditionIds.includes(childId)) {
        const stateWithCopiedConditions = copyCondition(draft, childId, newId, true);
        Object.keys(stateWithCopiedConditions).forEach((key) => {
          draft[key] = stateWithCopiedConditions[key];
        });
      } else {
        nested.push(childId);
      }
    }

    for (const nestedConditionGroupId of nested) {
      const stateWithCopiedConditionGroups = copyConditionGroup(draft, nestedConditionGroupId, newId, true);
      Object.keys(stateWithCopiedConditionGroups).forEach((key) => {
        draft[key] = stateWithCopiedConditionGroups[key];
      });
    }
  });
  console.log('copyConditionGroup', state, newState);
  return newState;
};
