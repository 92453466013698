import React from 'react';
import { InputTooltip } from '../input-tooltip';
import { InputLabelProps } from './types';

export const InputLabel = ({ heading, description, tooltip, tooltipPlacement }: InputLabelProps) => (
  <div className='mb-[8px]'>
    {heading && (
      <div className='flex items-center'>
        <div className='font-medium mr-1'>{heading}</div>
        {tooltip && <InputTooltip message={tooltip} placement={tooltipPlacement} />}
      </div>
    )}

    {description && <div className='text-[13px] mt-[2.5px]'>{description}</div>}
  </div>
);
