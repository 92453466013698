import { LEDGER_ACCOUNT_TYPE } from 'services/http/response.types';
import {
  AssetConditionsMenu,
  ChainConditionsMenu,
  ERCTokenConditionsMenu,
  HedgeyConditionsMenu,
  IsImpairedConditionsMenu,
  LedgerAccountConditionsMenu,
  LedgerAccountsByTypeConditionsMenu,
  LegalEntityConditionsMenu,
  NEARTokenConditionsMenu,
  RaincardsConditionsMenu,
  RaincardsSourcesConditionsMenu,
  SOLTokenConditionsMenu,
  SourceConditionsMenu,
  TransactionConditionsMenu,
  TransactionDirectionConditionsMenu,
  TransactionClassificationConditionsMenu,
  TransactionActionConditionsMenu,
  LoopConditionsMenu,
  MyAssetsConditionMenu,
  TransactionSmartContractConditionsMenu,
} from './nested-menus';

export const NestedMenus = () => (
  <>
    <MyAssetsConditionMenu />
    <AssetConditionsMenu />
    <ERCTokenConditionsMenu />
    <SOLTokenConditionsMenu />
    <NEARTokenConditionsMenu />
    <ChainConditionsMenu />
    <SourceConditionsMenu />
    <TransactionConditionsMenu />
    <TransactionClassificationConditionsMenu />
    <TransactionDirectionConditionsMenu />
    <TransactionActionConditionsMenu />
    <TransactionSmartContractConditionsMenu />
    <LoopConditionsMenu />
    <RaincardsConditionsMenu />
    <RaincardsSourcesConditionsMenu />
    <LegalEntityConditionsMenu />
    <HedgeyConditionsMenu />
    <IsImpairedConditionsMenu />
    <LedgerAccountConditionsMenu />
    {Object.values(LEDGER_ACCOUNT_TYPE).map((ledgerAccountType) => (
      <LedgerAccountsByTypeConditionsMenu ledgerAccountType={ledgerAccountType} key={ledgerAccountType} />
    ))}
  </>
);
