import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  AllFiltersDropdown,
  AccountingPeriodsFilterDropdown,
  AssetsFilterDropdown,
  ChainsFilterDropdown,
  ClassificationFilterDropdown,
  DirectionsFilterDropdown,
  ImpairedFilterDropdown,
  LegalEntitiesFilterDropdown,
  OriginatedByFilterDropdown,
  StatusFilterDropdown,
  TagsFilterDropdown,
  WalletsFilterDropdown,
  WalletTypesFilterDropdown,
  AccountingTreatmentFilterDropdown,
  HideSpamTokensFilterDropdown,
} from './filter-dropdowns';
import { Button, classNames } from 'ui';
import { useWindowSize } from 'usehooks-ts';
import DatePickerComp from '../DatePickerComp';
import { motion, useSpring } from 'framer-motion';
import useDimensions from 'react-cool-dimensions';
import { SliderContext } from '../../context/SliderProvider';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { FILTER_TYPE, FiltersProps } from './filter-dropdowns';

const SCROLL_STEP_SIZE = 200;

const getScrollClamp = (pageWidth: number, filtersWidth: number, sidebarWidth = 0) =>
  pageWidth - sidebarWidth < filtersWidth ? filtersWidth - pageWidth + 100 + sidebarWidth : 0;

const enum SCROLL_DIRECTION {
  LEFT = 'left',
  RIGHT = 'right',
}

const getScrollStep = (
  pageWidth: number,
  filtersWidth: number,
  currentScroll: number,
  direction: SCROLL_DIRECTION,
  sidebarWidth,
) => {
  let step = SCROLL_STEP_SIZE;
  if (direction === SCROLL_DIRECTION.RIGHT) {
    const maxPositiveScroll = 0;
    if (currentScroll === maxPositiveScroll) step = 0;
    else if (currentScroll >= -400) step = 0;
    else step = Math.min(SCROLL_STEP_SIZE, maxPositiveScroll - currentScroll) + currentScroll;
  } else {
    const maxNegativeScroll = getScrollClamp(pageWidth, filtersWidth, sidebarWidth);
    if (currentScroll === -maxNegativeScroll) step = 0;
    else if (-maxNegativeScroll >= currentScroll - 300) step = -maxNegativeScroll;
    else step = currentScroll - Math.min(SCROLL_STEP_SIZE, maxNegativeScroll + currentScroll);
  }
  return step;
};

export const Filters = ({ state, helpers, dateSelections, setDateSelections, 'data-cy': dataCy }: FiltersProps) => {
  const [isFixed, setIsFixed] = useState(false);
  const { sidebarWidth } = useContext(SliderContext);
  const onSingleRowChangeHelpers = useMemo(() => {
    return Object.keys(helpers).reduce((srcHelpers, filterType) => {
      srcHelpers[filterType] = helpers[filterType].setById;
      return srcHelpers;
    }, {});
  }, [helpers]);

  const onClearAllForAllFiltersDropdown = useCallback(() => {
    if (helpers[FILTER_TYPE.ACCOUNTING_PERIOD]) helpers[FILTER_TYPE.ACCOUNTING_PERIOD].deselectAll();
    if (helpers[FILTER_TYPE.ACCOUNTING_TREATMENT]) helpers[FILTER_TYPE.ACCOUNTING_TREATMENT].deselectAll();
    if (helpers[FILTER_TYPE.ASSET]) helpers[FILTER_TYPE.ASSET].deselectAll();
    if (helpers[FILTER_TYPE.CHAIN]) helpers[FILTER_TYPE.CHAIN].deselectAll();
    if (helpers[FILTER_TYPE.CLASSIFICATION]) helpers[FILTER_TYPE.CLASSIFICATION].deselectAll();
    if (helpers[FILTER_TYPE.DIRECTION]) helpers[FILTER_TYPE.DIRECTION].deselectAll();
    if (helpers[FILTER_TYPE.IMPAIRED]) helpers[FILTER_TYPE.IMPAIRED].deselectAll();
    if (helpers[FILTER_TYPE.LEGAL_ENTITY]) helpers[FILTER_TYPE.LEGAL_ENTITY].deselectAll();
    if (helpers[FILTER_TYPE.ORIGINATED_BY]) helpers[FILTER_TYPE.ORIGINATED_BY].deselectAll();
    if (helpers[FILTER_TYPE.STATUS]) helpers[FILTER_TYPE.STATUS].deselectAll();
    if (helpers[FILTER_TYPE.TAG]) helpers[FILTER_TYPE.TAG].deselectAll();
    if (helpers[FILTER_TYPE.WALLET]) helpers[FILTER_TYPE.WALLET].deselectAll();
    if (helpers[FILTER_TYPE.WALLET_TYPE]) helpers[FILTER_TYPE.WALLET_TYPE].deselectAll();
    if (helpers[FILTER_TYPE.SPAM_TOKEN]) helpers[FILTER_TYPE.SPAM_TOKEN].deselectAll();
  }, [helpers]);

  const filtersRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { observe: observeFilters, width: filtersWidth } = useDimensions();

  const [shouldShowScrollButtons, setShouldShowScrollButtons] = useState(false);
  const [shouldShowRightScrollButton, setShouldShowRightScrollButton] = useState(false);
  const [shouldShowLeftScrollButtons, setShouldShowLeftScrollButton] = useState(false);

  const [xScroll, setXScroll] = useState(0);
  const { width: pageWidth } = useWindowSize();

  const smoothScroll = useSpring(xScroll, { duration: 300 });

  useEffect(() => {
    setShouldShowScrollButtons(!!getScrollClamp(pageWidth, filtersWidth, sidebarWidth));
  }, [pageWidth, filtersWidth, sidebarWidth]);

  useEffect(() => {
    setShouldShowLeftScrollButton(shouldShowScrollButtons && xScroll !== 0);
    setShouldShowRightScrollButton(
      shouldShowScrollButtons && xScroll !== -getScrollClamp(pageWidth, filtersWidth, sidebarWidth),
    );
  }, [shouldShowScrollButtons, xScroll, pageWidth, sidebarWidth]);

  useEffect(() => {
    if (!sidebarWidth) smoothScroll.set(!xScroll ? xScroll : xScroll + 35);
    else smoothScroll.set(!xScroll ? xScroll : xScroll + 43);
  }, [xScroll]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      if (containerRef.current) {
        const componentPosition = containerRef.current.offsetTop;

        if (scrollPosition > componentPosition) {
          setIsFixed(true);
        } else {
          setIsFixed(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div
        style={{ width: pageWidth - sidebarWidth - 24 }}
        className={classNames(
          shouldShowLeftScrollButtons && 'left',
          shouldShowRightScrollButton && 'right',
          !sidebarWidth && 'mx-6',
          'h-10 relative ',
          isFixed && 'fixed top-[117px] z-[7]',
        )}
        ref={containerRef}
        data-cy={`${dataCy}_filtersContainer`}
      >
        <div
          className={classNames(
            isFixed &&
              'overflow-hidden fixed inset-0 -inset-x-6 top-[117px] h-14 z-[7] bg-white border-y border-y-zinc-200 py-2',
          )}
        ></div>
        <motion.div
          className={classNames(
            'flex items-center gap-x-2 absolute z-[8] w-fit',
            isFixed && 'fixed inset-x-0 top-[117px] z-normal py-2 pl-6',
          )}
          ref={(el) => {
            observeFilters(el);
            filtersRef.current = el;
          }}
          style={{
            left: smoothScroll,
          }}
        >
          <AllFiltersDropdown
            state={state}
            onSingleRowChange={onSingleRowChangeHelpers}
            onClearAll={onClearAllForAllFiltersDropdown}
            data-cy={dataCy}
          />
          {state[FILTER_TYPE.LEGAL_ENTITY] && helpers[FILTER_TYPE.LEGAL_ENTITY] && (
            <LegalEntitiesFilterDropdown
              state={state[FILTER_TYPE.LEGAL_ENTITY]}
              onSelectAll={helpers[FILTER_TYPE.LEGAL_ENTITY].selectAll}
              onClearAll={helpers[FILTER_TYPE.LEGAL_ENTITY].deselectAll}
              onSingleRowSelectChange={helpers[FILTER_TYPE.LEGAL_ENTITY].setSelectedById}
              onOnlySingleRowSelect={helpers[FILTER_TYPE.LEGAL_ENTITY].setOnly}
              data-cy={dataCy}
            />
          )}
          {state[FILTER_TYPE.ACCOUNTING_PERIOD] && helpers[FILTER_TYPE.ACCOUNTING_PERIOD] && (
            <AccountingPeriodsFilterDropdown
              state={state[FILTER_TYPE.ACCOUNTING_PERIOD]}
              onSelectAll={helpers[FILTER_TYPE.ACCOUNTING_PERIOD].selectAll}
              onClearAll={helpers[FILTER_TYPE.ACCOUNTING_PERIOD].deselectAll}
              onSingleRowSelectChange={helpers[FILTER_TYPE.ACCOUNTING_PERIOD].setSelectedById}
              onOnlySingleRowSelect={helpers[FILTER_TYPE.ACCOUNTING_PERIOD].setOnly}
              data-cy={dataCy}
            />
          )}
          {state[FILTER_TYPE.ACCOUNTING_TREATMENT] && helpers[FILTER_TYPE.ACCOUNTING_TREATMENT] && (
            <AccountingTreatmentFilterDropdown
              state={state[FILTER_TYPE.ACCOUNTING_TREATMENT]}
              onSelectAll={helpers[FILTER_TYPE.ACCOUNTING_TREATMENT].selectAll}
              onClearAll={helpers[FILTER_TYPE.ACCOUNTING_TREATMENT].deselectAll}
              onSingleRowSelectChange={helpers[FILTER_TYPE.ACCOUNTING_TREATMENT].setSelectedById}
              onOnlySingleRowSelect={helpers[FILTER_TYPE.ACCOUNTING_TREATMENT].setOnly}
              data-cy={dataCy}
            />
          )}
          {state[FILTER_TYPE.CHAIN] && helpers[FILTER_TYPE.CHAIN] && (
            <ChainsFilterDropdown
              state={state[FILTER_TYPE.CHAIN]}
              onSelectAll={helpers[FILTER_TYPE.CHAIN].selectAll}
              onClearAll={helpers[FILTER_TYPE.CHAIN].deselectAll}
              onSingleRowSelectChange={helpers[FILTER_TYPE.CHAIN].setSelectedById}
              onOnlySingleRowSelect={helpers[FILTER_TYPE.CHAIN].setOnly}
              data-cy={dataCy}
            />
          )}
          {state[FILTER_TYPE.CLASSIFICATION] && helpers[FILTER_TYPE.CLASSIFICATION] && (
            <ClassificationFilterDropdown
              state={state[FILTER_TYPE.CLASSIFICATION]}
              onSelectAll={helpers[FILTER_TYPE.CLASSIFICATION].selectAll}
              onClearAll={helpers[FILTER_TYPE.CLASSIFICATION].deselectAll}
              onSingleRowSelectChange={helpers[FILTER_TYPE.CLASSIFICATION].setSelectedById}
              onOnlySingleRowSelect={helpers[FILTER_TYPE.CLASSIFICATION].setOnly}
              data-cy={dataCy}
            />
          )}
          {state[FILTER_TYPE.DIRECTION] && helpers[FILTER_TYPE.DIRECTION] && (
            <DirectionsFilterDropdown
              state={state[FILTER_TYPE.DIRECTION]}
              onSelectAll={helpers[FILTER_TYPE.DIRECTION].selectAll}
              onClearAll={helpers[FILTER_TYPE.DIRECTION].deselectAll}
              onSingleRowSelectChange={helpers[FILTER_TYPE.DIRECTION].setSelectedById}
              onOnlySingleRowSelect={helpers[FILTER_TYPE.DIRECTION].setOnly}
              data-cy={dataCy}
            />
          )}
          {state[FILTER_TYPE.ASSET] && helpers[FILTER_TYPE.ASSET] && (
            <AssetsFilterDropdown
              state={state[FILTER_TYPE.ASSET]}
              onSelectAll={helpers[FILTER_TYPE.ASSET].selectAll}
              onClearAll={helpers[FILTER_TYPE.ASSET].deselectAll}
              onSingleRowSelectChange={helpers[FILTER_TYPE.ASSET].setSelectedById}
              onOnlySingleRowSelect={helpers[FILTER_TYPE.ASSET].setOnly}
              data-cy={dataCy}
            />
          )}
          {state[FILTER_TYPE.WALLET] && helpers[FILTER_TYPE.WALLET] && (
            <WalletsFilterDropdown
              state={state[FILTER_TYPE.WALLET]}
              onSelectAll={helpers[FILTER_TYPE.WALLET].selectAll}
              onClearAll={helpers[FILTER_TYPE.WALLET].deselectAll}
              onSingleRowSelectChange={helpers[FILTER_TYPE.WALLET].setSelectedById}
              onOnlySingleRowSelect={helpers[FILTER_TYPE.WALLET].setOnly}
              data-cy={dataCy}
            />
          )}
          {state[FILTER_TYPE.WALLET_TYPE] && helpers[FILTER_TYPE.WALLET_TYPE] && (
            <WalletTypesFilterDropdown
              state={state[FILTER_TYPE.WALLET_TYPE]}
              onSelectAll={helpers[FILTER_TYPE.WALLET_TYPE].selectAll}
              onClearAll={helpers[FILTER_TYPE.WALLET_TYPE].deselectAll}
              onSingleRowSelectChange={helpers[FILTER_TYPE.WALLET_TYPE].setSelectedById}
              onOnlySingleRowSelect={helpers[FILTER_TYPE.WALLET_TYPE].setOnly}
              data-cy={dataCy}
            />
          )}
          {state[FILTER_TYPE.ORIGINATED_BY] && helpers[FILTER_TYPE.ORIGINATED_BY] && (
            <OriginatedByFilterDropdown
              state={state[FILTER_TYPE.ORIGINATED_BY]}
              onSelectAll={helpers[FILTER_TYPE.ORIGINATED_BY].selectAll}
              onClearAll={helpers[FILTER_TYPE.ORIGINATED_BY].deselectAll}
              onSingleRowSelectChange={helpers[FILTER_TYPE.ORIGINATED_BY].setSelectedById}
              onOnlySingleRowSelect={helpers[FILTER_TYPE.ORIGINATED_BY].setOnly}
              data-cy={dataCy}
            />
          )}
          {state[FILTER_TYPE.STATUS] && helpers[FILTER_TYPE.STATUS] && (
            <StatusFilterDropdown
              state={state[FILTER_TYPE.STATUS]}
              onSelectAll={helpers[FILTER_TYPE.STATUS].selectAll}
              onClearAll={helpers[FILTER_TYPE.STATUS].deselectAll}
              onSingleRowSelectChange={helpers[FILTER_TYPE.STATUS].setSelectedById}
              onOnlySingleRowSelect={helpers[FILTER_TYPE.STATUS].setOnly}
              data-cy={dataCy}
            />
          )}
          {state[FILTER_TYPE.TAG] && helpers[FILTER_TYPE.TAG] && (
            <TagsFilterDropdown
              state={state[FILTER_TYPE.TAG]}
              onSelectAll={helpers[FILTER_TYPE.TAG].selectAll}
              onClearAll={helpers[FILTER_TYPE.TAG].deselectAll}
              onSingleRowSelectChange={helpers[FILTER_TYPE.TAG].setSelectedById}
              onOnlySingleRowSelect={helpers[FILTER_TYPE.TAG].setOnly}
              data-cy={dataCy}
            />
          )}
          {state[FILTER_TYPE.IMPAIRED] && helpers[FILTER_TYPE.IMPAIRED] && (
            <ImpairedFilterDropdown
              state={state[FILTER_TYPE.IMPAIRED]}
              onSelectAll={helpers[FILTER_TYPE.IMPAIRED].selectAll}
              onClearAll={helpers[FILTER_TYPE.IMPAIRED].deselectAll}
              onSingleRowSelectChange={helpers[FILTER_TYPE.IMPAIRED].setSelectedById}
              onOnlySingleRowSelect={helpers[FILTER_TYPE.IMPAIRED].setOnly}
              data-cy={dataCy}
            />
          )}
          {state[FILTER_TYPE.DATE] && dateSelections !== undefined && setDateSelections !== undefined && (
            <DatePickerComp dateSelections={dateSelections} setDateSelections={setDateSelections} />
          )}
          {state[FILTER_TYPE.SPAM_TOKEN] && helpers[FILTER_TYPE.SPAM_TOKEN] && (
            <HideSpamTokensFilterDropdown
              state={state[FILTER_TYPE.SPAM_TOKEN]}
              onSelectAll={helpers[FILTER_TYPE.SPAM_TOKEN].selectAll}
              onClearAll={helpers[FILTER_TYPE.SPAM_TOKEN].deselectAll}
              onSingleRowSelectChange={helpers[FILTER_TYPE.SPAM_TOKEN].setSelectedById}
              onOnlySingleRowSelect={helpers[FILTER_TYPE.SPAM_TOKEN].setOnly}
              data-cy={dataCy}
            />
          )}
        </motion.div>
        {shouldShowLeftScrollButtons && (
          <span
            className={classNames(
              'shadow-xl rounded-full  top-1/2',
              'before:absolute before:-left-6 before:rounded-none before:w-[60px] before:h-14 before:-translate-y-2.5 before:bg-[linear-gradient(to_left,rgba(255,255,255,0.8),rgba(255,255,255,0.2))] before:top-0 before:bottom-0 before:z-[0]',
              isFixed ? '-translate-y-1/4' : '-translate-y-1/2',
            )}
            style={{
              position: 'absolute',

              left: 0,
              zIndex: 11,
            }}
          >
            <Button
              leadingIcon={<MdChevronLeft />}
              emphasis='medium'
              className='rounded-full'
              variant='sm'
              onClick={() =>
                setXScroll((currentScroll) => {
                  const step = getScrollStep(
                    pageWidth,
                    filtersWidth,
                    currentScroll,
                    SCROLL_DIRECTION.RIGHT,
                    sidebarWidth,
                  );
                  return step;
                })
              }
            />
          </span>
        )}
        {shouldShowRightScrollButton && (
          <span
            className={classNames(
              'shadow-xl rounded-full relative top-1/2',
              'before:absolute before:-right-6 before:rounded-none before:w-[60px] before:h-14 before:-translate-y-2.5 before:bg-[linear-gradient(to_left,rgba(255,255,255,1),rgba(255,255,255,0.2))] before:top-0 before:bottom-0 before:z-[0]',
              isFixed ? '-translate-y-1/4' : '-translate-y-1/2',
            )}
            style={{
              position: 'absolute',
              right: 24,
              zIndex: 11,
            }}
          >
            <Button
              leadingIcon={<MdChevronRight />}
              emphasis='medium'
              className='rounded-full'
              variant='sm'
              onClick={() =>
                setXScroll((currentScroll) => {
                  const step = getScrollStep(
                    pageWidth,
                    filtersWidth,
                    currentScroll,
                    SCROLL_DIRECTION.LEFT,
                    sidebarWidth,
                  );
                  return step;
                })
              }
            />
          </span>
        )}
      </div>
    </>
  );
};
