import { DESCRIPTIVE_LOADER_STATES } from 'ui';
import { BG_JOB_STATE } from './types';
import { JOB_NAME, JOB_STATUS, LeanJobConfig } from 'services/http';

export const getLoaderStatusFromBgJobState = (bgJobState: BG_JOB_STATE) => {
  switch (bgJobState) {
    case BG_JOB_STATE.PENDING_CREATE:
      return DESCRIPTIVE_LOADER_STATES.LOADING;
    case BG_JOB_STATE.BEING_CREATED:
      return DESCRIPTIVE_LOADER_STATES.LOADING;
    case BG_JOB_STATE.COULD_NOT_BE_CREATED:
      return DESCRIPTIVE_LOADER_STATES.ERROR;
    case BG_JOB_STATE.QUEUED:
      return DESCRIPTIVE_LOADER_STATES.LOADING;
    case BG_JOB_STATE.PROCESSING:
      return DESCRIPTIVE_LOADER_STATES.LOADING;
    case BG_JOB_STATE.SUCCESS:
      return DESCRIPTIVE_LOADER_STATES.SUCCESS;
    case BG_JOB_STATE.FAILURE:
      return DESCRIPTIVE_LOADER_STATES.ERROR;
    case BG_JOB_STATE.CANCELLED:
      return DESCRIPTIVE_LOADER_STATES.ERROR;
  }
};

export const getLoaderBgStateFromJobConfig = (jobConfig: LeanJobConfig) => {
  switch (jobConfig.jobStatus) {
    case JOB_STATUS.STARTED:
      return BG_JOB_STATE.PROCESSING;
    case JOB_STATUS.IN_PROGRESS:
      return BG_JOB_STATE.PROCESSING;
    case JOB_STATUS.COMPLETED:
      return BG_JOB_STATE.SUCCESS;
    case JOB_STATUS.JOB_FAILED:
      return BG_JOB_STATE.FAILURE;
    case JOB_STATUS.CANCELED:
      return BG_JOB_STATE.CANCELLED;
  }
};

export const getLoaderTitleFromBgJobState = (bgJobState: BG_JOB_STATE) => {
  switch (bgJobState) {
    case BG_JOB_STATE.PENDING_CREATE:
      return 'Initialising';
    case BG_JOB_STATE.BEING_CREATED:
      return 'Creating Background job';
    case BG_JOB_STATE.COULD_NOT_BE_CREATED:
      return 'Failure';
    case BG_JOB_STATE.QUEUED:
      return 'Waiting';
    case BG_JOB_STATE.PROCESSING:
      return 'Processing';
    case BG_JOB_STATE.SUCCESS:
      return 'Success';
    case BG_JOB_STATE.FAILURE:
      return 'Failure';
    case BG_JOB_STATE.CANCELLED:
      return 'Cancelled';
  }
};

export interface GetLoaderDescriptionFromBgJobStateProps {
  bgJobState: BG_JOB_STATE;
  jobName: JOB_NAME;
  jobConfig?: LeanJobConfig;
  error?: string;
}

export const getLoaderDescriptionFromBgJobState = ({
  bgJobState,
  jobName,
  jobConfig,
  error,
}: GetLoaderDescriptionFromBgJobStateProps) => {
  // console.log('getLoaderDescriptionFromBgJobState', {
  //   jobConfig,
  //   toProcessCount: jobConfig?.toProcessCount,
  //   completedCount: jobConfig?.completedCount,
  //   bgJobState,
  //   message: `Processed ${jobConfig?.completedCount}/${jobConfig?.toProcessCount} for ${jobName} job.`,
  // });

  let d: string = `${jobName} is now queued up. Waiting for an update.`;
  switch (bgJobState) {
    case BG_JOB_STATE.PENDING_CREATE:
      return '';
    case BG_JOB_STATE.BEING_CREATED:
      return `Creating ${jobName} job`;
    case BG_JOB_STATE.COULD_NOT_BE_CREATED:
      return `Could not create ${jobName} job`;
    case BG_JOB_STATE.QUEUED:
      return `${jobName} job is now queued up. Waiting for an update.`;
    case BG_JOB_STATE.PROCESSING:
      if (jobConfig?.jobStatus === JOB_STATUS.IN_PROGRESS)
        d = `Processed ${jobConfig?.completedCount}/${jobConfig?.toProcessCount} for ${jobName} job.`;
      return d;
    case BG_JOB_STATE.SUCCESS:
      return `${jobName} job completed successfully`;
    case BG_JOB_STATE.FAILURE:
      return `${jobName} job could not be completed.${
        error ? ` Error: ${error}` : jobConfig?.message ? ` Message: ${jobConfig.message}` : ''
      }`;
    case BG_JOB_STATE.CANCELLED:
      return `${jobName} job was cancelled.`;
  }
};
