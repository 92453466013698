import { Tab } from '@headlessui/react';
import React from 'react';
import { activeClassName } from './utils';
import { TabRootProps } from '../types';

export const TabRoot = ({ headerItems, children }: TabRootProps) => {
  return (
    <Tab.Group>
      <Tab.List className='border-2 rounded-lg mb-6 flex justify-center items-center p-0.5 w-full'>
        {headerItems?.map(({ title, className, activeStyle, onClick }, index) => {
          return (
            <Tab
              onClick={onClick}
              data-testid={`tab-header-${title.replace(' ', '').toLowerCase()}`}
              key={index}
              className={({ selected }) => `${activeClassName(selected, activeStyle)} ${className}`}
            >
              {title}
            </Tab>
          );
        })}
      </Tab.List>
      <Tab.Panels className='w-full'>{children}</Tab.Panels>
    </Tab.Group>
  );
};

export default TabRoot;
