import { useGetLegalEntityById } from '../../../hooks/http';
import { EditEntity } from '../../../components/LegalEntities';
import { SidebarProps } from '../SidebarGlobal';
import { changeRouteName } from '../../templates/utils';

const EntitySidebar: React.FC<SidebarProps> = ({
  id,
  onBack,
  isDockPanelAvailable,
  handlePrimaryPanelClose,
  isPrimary,
  defaultState,
}) => {
  const { data, isLoading } = useGetLegalEntityById(id);

  changeRouteName({ name: data?.entityName, UUID: id, isLoading });

  return (
    <EditEntity
      onBack={onBack}
      isDockPanelAvailable={isDockPanelAvailable}
      onClose={() => handlePrimaryPanelClose && handlePrimaryPanelClose()}
      legalEntityId={id}
      isPrimary={isPrimary}
      defaultState={defaultState}
    />
  );
};

export default EntitySidebar;
