import { DropDownSelect } from '../../../../atoms';
import { useRuleConfigurator } from '../../rule-configurator-context';
import { selectorStyle } from './selector-styles';
import { useLegalEntities } from '../../../../../hooks';
import { getDisplayedLegalEntities, mergeLegalEntities } from '../../../../templates/utils';
import { ConditionRow } from './ConditionRow';
import { FACT } from '../../types';
import { useCondition } from '../useCondition';
import { EQUAL_NOTEQUAL_OPERATORS } from '../../data';

const Row = () => {
  const { updateCondition, isDisabled } = useRuleConfigurator();
  const condition = useCondition();
  const { data: legalEntities } = useLegalEntities();
  const mergedLegalEntities = mergeLegalEntities(legalEntities);
  const displayedLegalEntities = getDisplayedLegalEntities(mergedLegalEntities);
  return (
    <>
      <DropDownSelect
        customStyle={selectorStyle}
        defaultValue={{ label: 'Legal Entity', value: 'Legal Entity' }}
        isDisabled={true}
      />
      <DropDownSelect
        customStyle={selectorStyle}
        options={EQUAL_NOTEQUAL_OPERATORS}
        value={EQUAL_NOTEQUAL_OPERATORS.find((operator) => operator.value === condition.operator)}
        onChange={({ value }) => {
          updateCondition(condition.id, {
            operator: value,
          });
        }}
        isDisabled={isDisabled}
      />

      <DropDownSelect
        customStyle={selectorStyle}
        value={displayedLegalEntities.find((option) => option.value === condition?.value)}
        options={displayedLegalEntities}
        onChange={({ value }) => {
          updateCondition(condition.id, {
            value,
          });
        }}
        isDisabled={isDisabled}
      />
    </>
  );
};

export const LegalEntityCondition = () => (
  <ConditionRow facts={[FACT.LEGAL_ENTITY]}>
    <Row />
  </ConditionRow>
);
