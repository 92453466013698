import React, { useLayoutEffect } from 'react';
import Layout from '../components/Layout';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
//import '../css/index.css'; // Tailwind
//import 'slick-carousel/slick/slick.css';
//import 'slick-carousel/slick/slick-theme.css';
import '../css/globals.css'; // Global styles
import { attachInterceptor } from 'services';
import { SessionProvider } from '../context';

if (!process.browser) React.useLayoutEffect = React.useEffect;

export default function app({ Component, pageProps }) {
  const getLayout = Component.getLayout || ((page) => <Layout initialData={pageProps?.initialData}>{page}</Layout>);

  useLayoutEffect(() => {
    attachInterceptor();
  }, []);

  return (
    <SessionProvider>
      {getLayout(
        <>
          <Component {...pageProps} />
        </>,
      )}
    </SessionProvider>
  );
}
