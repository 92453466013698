export const TITLES = {
  0: 'Close accounting period',
  1: 'Revaluation',
  2: 'Sync Journal Entries',
  3: 'Import Exchange transactions',
};
export const DESCRIPTIONS = {
  0: 'All accounting must be closed in chronological order from old to new.',
  1: 'This action cannot be undone. This will close the accounting period and create journal entries for unrealized gain and loss.',
  2: 'This action cannot be undone. This will sync journal entries to your GL integrations.',
  3: 'Select a prior open period to import transactions from the connected Kraken account. If transactions have already been imported for the selected period no action will be taken',
};

export const BUTTONS = {
  0: {
    primary: 'Close',
    secondary: 'Cancel',
  },
  1: {
    primary: 'Revaluate',
    secondary: 'Close period and skip revaluation',
  },
  2: {
    primary: 'Sync',
    secondary: 'Close',
  },
};
