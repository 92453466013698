import React, { useState } from 'react';
import ConditionItem from '../ConditionItem';
import * as ScrollArea from '@radix-ui/react-scroll-area';
import { Virtuoso } from 'react-virtuoso';
import { SUPPORTED_ERC20_TOKEN } from '../../../../constants';
import { setSaveData } from '../../../rules/utils';
import { assets } from './constants';
import { InputWithExtras } from 'ui';
import { MdSearch } from 'react-icons/md';
import SearchFilter from './SearchFilter';
import { ConditionMenusProps } from './types';
import { useAssetTypes } from '../../../../hooks';

export const AssetCondition = ({
  ruleSetData,
  getRuleSetData,
  isFirstLevel,
  callbackFn,
  isInLineCondition,
}: ConditionMenusProps) => {
  const [filterERC, setFilterERC] = useState(SUPPORTED_ERC20_TOKEN);
  const filterAssets = (arr, searchValue) => {
    return arr.filter((el) => el.toLowerCase().includes(searchValue.toLowerCase()));
  };
  const { assetTypes, isLoading: assetsTypeLoading } = useAssetTypes();

  return (
    <ConditionItem isInLineCondition={isInLineCondition} label='Asset'>
      <SearchFilter
        data={assets}
        renderItem={(item) => {
          console.log({ item });
          if (item === 'ERC - 20 Tokens') {
            return (
              <ConditionItem key={item} label='ERC- 20 Tokens' img='eth'>
                <div className='my-2 absolute'>
                  <InputWithExtras
                    type='text'
                    placeholder='Search...'
                    onChange={(e) => setFilterERC(filterAssets(SUPPORTED_ERC20_TOKEN, e.target.value))}
                    leading={<MdSearch className='w-5 h-5' />}
                  />
                </div>
                <div className='mt-15'>
                  {filterERC.length ? (
                    <ScrollArea.Root>
                      <ScrollArea.Viewport>
                        <Virtuoso
                          style={{ height: 250 }}
                          data={filterERC}
                          itemContent={(index, token) => (
                            <ConditionItem
                              onClick={() => {
                                if (isInLineCondition) {
                                  callbackFn && callbackFn(token);
                                } else {
                                  setSaveData('Asset', token, isFirstLevel, ruleSetData, getRuleSetData);
                                }
                              }}
                              img={token.toLowerCase()}
                              label={token.toLowerCase()}
                            />
                          )}
                        />
                      </ScrollArea.Viewport>
                      <ScrollArea.Scrollbar orientation='vertical'>
                        <ScrollArea.Thumb />
                      </ScrollArea.Scrollbar>
                    </ScrollArea.Root>
                  ) : (
                    <div className='w-full text-center'>No data found</div>
                  )}
                </div>
              </ConditionItem>
            );
          } else if (item === 'SOL Tokens') {
            return (
              <ConditionItem key={item} label='SOL Tokens' img='sol'>
                <SearchFilter
                  data={['DUST', 'RAY']}
                  renderItem={(item) => (
                    <ConditionItem
                      key={item}
                      onClick={() => {
                        if (isInLineCondition) {
                          callbackFn && callbackFn(item);
                        } else {
                          setSaveData('Asset', item, isFirstLevel, ruleSetData, getRuleSetData);
                        }
                      }}
                      img={item.toLowerCase()}
                      label={item}
                    />
                  )}
                />
              </ConditionItem>
            );
          } else if (item === 'NEAR Tokens') {
            return (
              <ConditionItem key={item} label='NEAR Tokens' img='near'>
                <SearchFilter
                  data={['NEAR', 'SWEAT']}
                  renderItem={(item) => (
                    <ConditionItem
                      key={item}
                      onClick={() => {
                        if (isInLineCondition) {
                          callbackFn && callbackFn(item);
                        } else {
                          setSaveData('Asset', item, isFirstLevel, ruleSetData, getRuleSetData);
                        }
                      }}
                      img={item.toLowerCase()}
                      label={item}
                    />
                  )}
                />
              </ConditionItem>
            );
          } else if (item === 'My assets') {
            return (
              <ConditionItem img='' isLoading={assetsTypeLoading} key={item} label='My assets'>
                <SearchFilter
                  data={assetTypes ?? []}
                  renderItem={(item) => (
                    <ConditionItem
                      key={item}
                      onClick={() => {
                        if (isInLineCondition) {
                          callbackFn && callbackFn(item);
                        } else {
                          setSaveData('Asset', item, isFirstLevel, ruleSetData, getRuleSetData);
                        }
                      }}
                      img={item?.toLowerCase()}
                      label={item}
                    />
                  )}
                />
              </ConditionItem>
            );
          } else {
            return (
              <ConditionItem
                key={item}
                onClick={() => {
                  if (isInLineCondition) {
                    callbackFn && callbackFn(item);
                  } else {
                    setSaveData('Asset', item, isFirstLevel, ruleSetData, getRuleSetData);
                  }
                }}
                label={item}
                img={item?.toLowerCase()}
              />
            );
          }
        }}
      />
    </ConditionItem>
  );
};

export default AssetCondition;
