import React, { useContext, useState } from 'react';
import { MdContentCopy } from 'react-icons/md';
import { AmountInput, Tag } from 'ui';
import { Tooltip } from './Tooltip';
import { SidebarGlobalContext } from '../../context/SidebarGlobalProvider';

type DetailsItemProps = {
  tag: string;
  value: string | string[];
  onClick?: () => void;
  variant?: 'primary' | 'secondary';
  className?: string;
  valueStyle?: string;
  tagStyle?: string;
  interactionType?: INTERACTION;
  textToCopy?: string;
  list?: string[];
  maxCharacters?: number;
  leadingComponent?: React.ReactNode;
  trailingComponent?: React.ReactNode;
  wrapperComponent?: {
    component: any;
    props: any;
  };
  itemId?: string;
  tooltipContent?: string;
  onEditConfirm?: (value: string) => void;
  'data-cy'?: string;
  disabled?: boolean;
};

type INTERACTION = 'copy' | 'edit';

export const DetailsItem = ({
  tag,
  value,
  onClick,
  variant = 'primary',
  className,
  valueStyle,
  tagStyle,
  interactionType,
  list,
  maxCharacters,
  wrapperComponent,
  textToCopy,
  leadingComponent,
  trailingComponent,
  itemId,
  tooltipContent,
  onEditConfirm,
  'data-cy': dataCy,
  disabled,
}: DetailsItemProps) => {
  const [showInteraction, setShownInteraction] = useState(false);
  const [hideTooltip, setHideTooltip] = useState(true);
  const [editValue, setEditValue] = useState((value as string) || '');

  const { sidebarIds } = useContext(SidebarGlobalContext);

  let truncatedValue: string | null = null;

  if ((!value && !list) || value === 'Invalid Date') {
    return <></>;
  }

  if (maxCharacters && value.length > maxCharacters && typeof value === 'string') {
    truncatedValue =
      value.substring(0, maxCharacters / 2) + '...' + value.substring(value.length - maxCharacters / 2, value.length);
  }

  const wrapWithWrapperComponent = (children: React.ReactNode, WrapperComponent: React.FC, props) => {
    if (tooltipContent) {
      return (
        <Tooltip showTooltipArrow content={tooltipContent} hideTooltip={hideTooltip}>
          <WrapperComponent {...props}>{children}</WrapperComponent>
        </Tooltip>
      );
    }
    return <WrapperComponent {...props}>{children}</WrapperComponent>;
  };

  const onMouseLeave = () => {
    setShownInteraction(false);
    setHideTooltip(true);
  };

  // Active state is when secondary sidebar is viewing this item
  const isActive = itemId && sidebarIds.includes(itemId);

  const copyText = () => {
    const valueString = typeof value === 'string' ? (value as string) : value[0];
    navigator.clipboard.writeText(textToCopy || valueString);
    setHideTooltip(false);
    setTimeout(() => {
      setHideTooltip(true);
    }, 1000);
  };
  const renderValue = () => {
    if (interactionType === 'edit') {
      return (
        <AmountInput
          currencyDescriptor={'$'}
          value={editValue}
          onChange={(e) => setEditValue(e.target.value)}
          containerClassName='w-[200px]'
          onEnter={() => {
            onEditConfirm && onEditConfirm(editValue);
          }}
          disabled={disabled}
        />
      );
    }
    if (truncatedValue) {
      return <div>{truncatedValue}</div>;
    }
    if (typeof value === 'object' && value.length > 0) {
      return (
        <>
          {value.map((item, index) => (
            <div className={`${index !== 0 && 'opacity-80'}`} key={index}>
              {item}
            </div>
          ))}
        </>
      );
    } else {
      return <div>{value}</div>;
    }
  };

  return (
    <>
      <div
        className={`details-item p-2 relative left-[-8px] rounded-lg ${onClick && 'hover:bg-gray-50'} ${className} ${
          isActive && 'border bg-[rgb(244,244,254)] border-blue-600 hover:bg-[rgb(234,234,244)]'
        }`}
        onMouseOver={() => setShownInteraction(true)}
        onMouseLeave={onMouseLeave}
        data-cy={`${dataCy}__detalisItem`}
      >
        <div className={`font-medium text-[#00000066] ${tagStyle}`}>{tag}</div>
        <div className='flex items-center gap-x-2'>
          {wrapWithWrapperComponent(
            <div className='flex items-center'>
              {leadingComponent && <div className='mr-2 flex justify-center'>{leadingComponent} </div>}
              <div
                onClick={onClick}
                className={`${
                  variant === 'secondary' && 'font-medium text-blue-600 cursor-pointer'
                } capitalize w-fit ${valueStyle} ${wrapperComponent && 'hover:underline'}`}
              >
                {!list ? renderValue() : list.map((item, index) => <Tag label={item} key={index} />)}
              </div>
            </div>,
            wrapperComponent?.component || React.Fragment,
            wrapperComponent?.props || {},
          )}
          {(interactionType == 'copy' || textToCopy) && showInteraction ? (
            <Tooltip delayDuration={500} content={`Copy to clipboard`} showTooltipArrow>
              <Tooltip delayDuration={0} content={`Copied to clipboard`} open={!hideTooltip} showTooltipArrow>
                <button className='hover:bg-gray-100 rounded' onClick={copyText}>
                  <MdContentCopy className='m-1' size={16} />
                </button>
              </Tooltip>
            </Tooltip>
          ) : null}

          {trailingComponent && <div className='ml-1'>{trailingComponent} </div>}
        </div>
      </div>
    </>
  );
};

export default DetailsItem;
