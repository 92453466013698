import React from 'react';

import { ConditionIdProvider } from './ConditionIdContext';
import {
  AssetRuleCondition,
  ChainRuleCondition,
  CoinbasePrimeRuleCondition,
  CostBasisRuleCondition,
  DateReceivedOnRuleCondition,
  GainLossRuleCondition,
  HedgeyRuleCondition,
  IsImpairedRuleCondition,
  LastImpairedOnRuleCondition,
  LedgerAccountRuleCondition,
  LegalEntityCondition,
  RaincardsRuleCondition,
  RawContractAddressRuleCondition,
  TransactionDirectionRuleCondition,
  TransactionRuleCondition,
  WalletRuleCondition,
  TransactionClassificationRuleCondition,
  LoopRuleCondition,
  ExchangeRuleCondition,
  TransactionActionRuleCondition,
  TransactionSmartContractRuleCondition,
} from './selected-condition-rows';

export const RuleCondition = ({ conditionId }) => {
  return (
    <ConditionIdProvider conditionId={conditionId}>
      <AssetRuleCondition />
      <ChainRuleCondition />
      <CoinbasePrimeRuleCondition />
      <CostBasisRuleCondition />
      <DateReceivedOnRuleCondition />
      <ExchangeRuleCondition />
      <GainLossRuleCondition />
      <HedgeyRuleCondition />
      <IsImpairedRuleCondition />
      <LastImpairedOnRuleCondition />
      <LedgerAccountRuleCondition />
      <LegalEntityCondition />
      <RaincardsRuleCondition />
      <RawContractAddressRuleCondition />
      <TransactionDirectionRuleCondition />
      <TransactionClassificationRuleCondition />
      <TransactionActionRuleCondition />
      <TransactionSmartContractRuleCondition />
      <TransactionRuleCondition />
      <WalletRuleCondition />
      <LoopRuleCondition />
    </ConditionIdProvider>
  );
};

export default RuleCondition;
