import { FilterState } from '../types';
import { WALLET_TYPE, Wallet } from 'services/http/response.types';

export const extraFiltersForWalletsFn = (
  state: FilterState<Wallet>,
  extraFilters: { isInternal: boolean; isExternal: boolean; isTagged: boolean },
) => {
  if (!extraFilters.isInternal && !extraFilters.isExternal && !extraFilters.isTagged) return state;

  const matchingIds = state.ids.filter((_id) => {
    if (extraFilters.isInternal && state.entities[_id].walletType === WALLET_TYPE.INTERNAL) return true;
    if (extraFilters.isExternal && state.entities[_id].walletType === WALLET_TYPE.EXTERNAL) return true;
    if (extraFilters.isTagged && state.entities[_id].tags.length > 0) return true;
    return false;
  });

  const matchingEntities = matchingIds.reduce((es, _id) => {
    es[_id] = state.entities[_id];
    return es;
  }, {});

  return {
    entities: matchingEntities,
    ids: matchingIds,
  };
};
