import React, { useContext, useEffect, useRef, useState } from 'react';
import { journalEntryLines } from '../../constants/tableHeaders';
import { Button, DetailsItem, TableSkeletonLoader } from '../atoms';
import { SidebarRoot, Sidebar, SidebarTopBar, SidebarBody } from '../atoms/Sidebar';
import { useGetJournalEntryById, useReverseJournalEntry } from '../../hooks/http';
import { Table } from '../dashboard';
import {
  changeRouteName,
  convertJournalEntries,
  deriveError,
  formatAccountingPeriod,
  formatDate,
  getCreditsDebitsForJe,
} from '../templates/utils';
import { JournalEntryFileAttachment } from './JournalEntryFileAttachment';
import { CSVLink } from 'react-csv';
import { SidebarSectionHeader } from '../atoms/Sidebar/SidebarBody/SidebarSectionHeader';
import { SidebarSection } from '../atoms/Sidebar/SidebarBody/SidebarSection';
import SidebarHeader from '../atoms/Sidebar/SidebarHeader/SidebarHeader';
import { getHost } from '../../lib/utils';
import { SidebarGlobalContext } from '../../context/SidebarGlobalProvider';
import CreateJournalEntry from './CreateJournalEntry';
import { useSession } from '../../hooks/useSession';
import { toast } from 'react-hot-toast';
import { useInvalidateQuery } from '../../hooks';
import { formatTableNumbers } from 'global-utils';

function JournalDetails({ journalEntryId, onCancel, hideRoot, isDockPanelAvailable, onBack, isPrimary }) {
  const dataCy = 'journalDetails';
  const [journalEntryData, setJournalEntryData] = useState({});
  const [editMode, setEditMode] = useState(false);
  const { data, isLoading, isSuccess } = useGetJournalEntryById(journalEntryId);
  const { mutateAsync: reverseJournalEntry, isLoading: isReversingJournalEntry } = useReverseJournalEntry();
  const { organizationId, userId } = useSession();
  const ref = useRef(null);
  const { openSidebar } = useContext(SidebarGlobalContext);

  useEffect(() => {
    if (data?.journalEntryModels?.length > 0) setJournalEntryData(data?.journalEntryModels[0]);
  }, [data, isSuccess]);

  let displayedLines = [],
    csvData = data?.journalEntryModels ? convertJournalEntries(data?.journalEntryModels, true) : [];

  displayedLines =
    journalEntryData?.journalEntryLineIds?.map((jel) => ({
      ledgerAccount: jel?.ledgerAccountId?.ledgerAccountName,
      ledgerAccountId: jel?.ledgerAccountId?._id,
      amount: parseFloat(jel.amount.$numberDecimal, 10),
      company: jel?.legalEntityId?.entityName,
      creditOrDebit: jel?.creditOrDebit,
      tags: jel?.tags,
      legalEntityId: {
        _id: jel?.legalEntityId?._id,
        entityName: jel?.legalEntityId?.entityName,
      },
    })) || [];

  changeRouteName({ name: data?.journalEntryModels[0]?.journalSequenceNumber, UUID: journalEntryId, isLoading });
  const { invalidateJournalEntries } = useInvalidateQuery();
  const handleReverseEntry = async () => {
    if (!isReversingJournalEntry)
      try {
        await reverseJournalEntry({
          organizationId,
          journalEntryId: journalEntryData._id,
          userId,
        });

        invalidateJournalEntries();
        toast.success('Your journal entry has been reversed.');
      } catch (error) {
        toast.error(deriveError(error));
      }
  };

  const handleBackButtonClick =
    onBack &&
    (() => {
      onBack();
    });

  if (editMode) {
    return (
      <CreateJournalEntry
        onCancel={() => {
          setEditMode(false);
        }}
        onBack={onBack}
        selectedJournalEntry={journalEntryData}
        title={`Edit ${journalEntryData?.journalSequenceNumber}`}
        data-cy='editJournalEntry'
        onClose={onCancel}
        shouldNestCreateJournalLinePanel={isPrimary}
      />
    );
  }

  const renderSidebar = () => {
    return (
      <Sidebar data-cy={dataCy}>
        <SidebarTopBar
          onClose={onCancel}
          isDockPanelAvailable={isDockPanelAvailable}
          onBack={handleBackButtonClick}
          itemId={journalEntryId}
          isPrimary={isPrimary}
          data-cy={dataCy}
        />
        <SidebarHeader
          data-cy={dataCy}
          title={journalEntryData?.journalSequenceNumber}
          subtitles={[`Created ${formatDate(new Date(journalEntryData?.createdAt))}`]}
          status={{
            label: journalEntryData?.status?.toLowerCase(),
            type:
              journalEntryData?.status === 'POSTED' || journalEntryData?.status === 'SUBMITTED'
                ? 'positive'
                : 'neutral',
          }}
          loading={isLoading}
          link={`${getHost()}/ledger/journals/${journalEntryId}`}
          actions={[
            {
              label: 'Reverse entry',
              onClick: handleReverseEntry,
              props: {
                disabled: isReversingJournalEntry,
              },
              isLoading: isReversingJournalEntry,
            },
            {
              label: 'Download',
              onClick: () => ref?.current?.click(),
            },
            {
              label: 'Edit',
              onClick: () => {
                setEditMode(true);
              },
              props: {
                disabled: journalEntryData?.status === 'POSTED' || journalEntryData?.status === 'SUBMITTED',
              },
              'data-cy': 'editButton',
            },
          ]}
        />
        <SidebarBody>
          <SidebarSectionHeader title='Details' />
          <SidebarSection loading={isLoading}>
            <DetailsItem
              tag='Credit amount'
              value={formatTableNumbers({ value: getCreditsDebitsForJe(journalEntryData).credits })}
            />
            <DetailsItem
              tag='Debit amount'
              value={formatTableNumbers({ value: getCreditsDebitsForJe(journalEntryData).debits })}
            />
            <DetailsItem
              tag='Balanced'
              value={
                getCreditsDebitsForJe(journalEntryData).credits === getCreditsDebitsForJe(journalEntryData).debits
                  ? 'True'
                  : 'False'
              }
            />
            <DetailsItem
              tag='Accounting period'
              value={
                journalEntryData?.accountingPeriodId?.accountingPeriodName ??
                formatAccountingPeriod(new Date(journalEntryData?.accountingPeriodId?.startDateUTC))
              }
            />
            <DetailsItem tag='Accounting Date' value={formatDate(new Date(journalEntryData?.accountingDate))} />
            {journalEntryData?.journalEntryTemplateId?._id ? (
              <DetailsItem
                tag='Template link'
                value={journalEntryData?.journalEntryTemplateId?.name}
                variant='secondary'
                textToCopy={`${getHost()}/configure/templates/${journalEntryData?.journalEntryTemplateId?._id}`}
                itemId={journalEntryData?.journalEntryTemplateId?._id}
                onClick={() => {
                  openSidebar('templates', {
                    id: journalEntryData?.journalEntryTemplateId?._id,
                    primaryOrSecondary: 'secondary',
                  });
                }}
              />
            ) : (
              <DetailsItem tag='Template link' value={'N/A'} variant='primary' />
            )}
            {journalEntryData?.legalEntityId?._id ? (
              <DetailsItem
                tag='Legal entity'
                value={journalEntryData?.legalEntityId?.entityName}
                variant='secondary'
                textToCopy={`${getHost()}/configure/entities/${journalEntryData?.legalEntityId?._id}`}
                itemId={journalEntryData?.legalEntityId?._id}
                onClick={() => {
                  openSidebar('entities', {
                    id: journalEntryData?.legalEntityId?._id,
                    primaryOrSecondary: 'secondary',
                  });
                }}
              />
            ) : (
              <DetailsItem tag='Legal entity' value={journalEntryData?.legalEntityId?.entityName} variant='primary' />
            )}
            <DetailsItem tag='Created by' value={journalEntryData?.originatedBy} />
            <DetailsItem tag='Memo' value={journalEntryData.memo} />

            <DetailsItem
              tag='External reference'
              value={journalEntryData?.journalEntryTemplateId?.externalReference ?? 'N/A'}
            />
            {journalEntryData?.transactionId?._id ? (
              <DetailsItem
                tag='Operational transaction'
                variant='secondary'
                value={journalEntryData?.transactionId?.sequenceNumber}
                textToCopy={`${getHost()}/ledger/transactions/${journalEntryData?.transactionId?._id}`}
                itemId={journalEntryData?.transactionId?._id}
                onClick={() => {
                  openSidebar('transactions', {
                    id: journalEntryData?.transactionId?._id,
                    primaryOrSecondary: 'secondary',
                  });
                }}
              />
            ) : (
              <DetailsItem
                tag='Operational transaction'
                variant='primary'
                value={journalEntryData?.transactionId?.sequenceNumber ?? 'N/A'}
              />
            )}
          </SidebarSection>
          <SidebarSectionHeader title='Attachments' />
          <SidebarSection loading={isLoading} numberOfColumns={1}>
            <JournalEntryFileAttachment jeId={journalEntryId} />
          </SidebarSection>
          <SidebarSectionHeader title='Journal entry lines' />
          <SidebarSection loading={isLoading}>
            <Table
              className='px-0'
              tableHeader={journalEntryLines}
              hideCheckboxes
              isSidePanel
              tableData={{
                Data: (userId && displayedLines) ?? [],
              }}
              tableClasses='min-w-0'
              enableColumnPinning={false}
            />
            {isLoading && <TableSkeletonLoader />}
            {isLoading && (
              <div className='w-full flex justify-center items-center my-8'>
                <Button className='loading text-center' />
              </div>
            )}
          </SidebarSection>
        </SidebarBody>
        <CSVLink
          ref={ref}
          data={csvData}
          target='_blank'
          className='hidden'
          filename={`journal-entry-export-${new Date().toLocaleDateString()}.csv`}
        />
      </Sidebar>
    );
  };

  if (hideRoot) {
    return renderSidebar();
  }

  return <SidebarRoot>{renderSidebar()}</SidebarRoot>;
}

export default JournalDetails;
