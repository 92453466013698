import { useMutation, useQuery } from '@tanstack/react-query';
import {
  createExchangeSource,
  deleteExchangeSource,
  getExchangeSourceSyncHistory,
  updateExchangeSource,
  createExchangeJob,
} from 'services/http';
import { QUERY_KEY, useOrgBasedQueryKey } from '../../../hooks';

interface CreateExchangeInterface {
  userId: string;
  organizationId: string;
  exchangeSourceType: string;
  apiKey: string;
  apiSecret: string;
  apiPassphrase?: string;
  legalEntityId: string;
}

interface UpdateExchangeInterface {
  _id: string;
  apiKey: string;
  apiSecret: string;
  apiPassphrase?: string;
  legalEntityId: string;
  organizationId: string;
}

interface DeleteExchangeInterface {
  sourceId: string;
  organizationId: string;
}

interface CreateExchangeJobInterface {
  organizationId: string;
  accountingPeriodId?: string;
}

export const useCreateExchange = () => {
  return useMutation(async (body: CreateExchangeInterface) => {
    await createExchangeSource(body);
  });
};

export const useUpdateExchange = () => {
  return useMutation(async (body: UpdateExchangeInterface) => {
    await updateExchangeSource(body);
  });
};
export const useDeleteExchange = () => {
  return useMutation(async (params: DeleteExchangeInterface) => {
    await deleteExchangeSource(params);
  });
};

export const useCreateExchangeJob = () => {
  return useMutation(async (params: CreateExchangeJobInterface) => {
    await createExchangeJob(params);
  });
};

export const useGetExchangeSourceSyncHistory = (id: string, organizationId: string) => {
  const getKey = useOrgBasedQueryKey();

  return useQuery(
    getKey(QUERY_KEY.EXCHANGE_SOURCE_SYNC_HISTORY_RECORDS, { exchangeSourceId: id }),
    async () => {
      const response = await getExchangeSourceSyncHistory({
        sourceId: id,
        organizationId,
      });

      return response.data;
    },
    {
      enabled: !!id && !!organizationId,
    },
  );
};
