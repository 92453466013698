import { CheckboxListItem, DropdownGroup, DropdownGroupBody, DropdownGroupTitle, capitalizeFirstLetter } from 'ui';

export const TagsFilterRowGroup = ({ state, onSingleChange, onClearAll }) => {
  if (!state) return null;
  return (
    <DropdownGroup>
      <DropdownGroupTitle title='Tags' />
      <DropdownGroupBody>
        {state.ids.length > 1 ? (
          state.ids
            .map((_id) => state.entities[_id])
            .map((tag) => (
              <CheckboxListItem
                key={tag._id}
                label={capitalizeFirstLetter(tag.entry.value)}
                checked={tag.selected}
                onCheckedChange={(selected: boolean) => onSingleChange({ ...tag, selected })}
                selectOnlyFn={() => {
                  onClearAll();
                  onSingleChange({ ...tag, selected: true });
                }}
              />
            ))
        ) : (
          <p className='text-zinc-900 text-sm px-2'>No tags to show.</p>
        )}
      </DropdownGroupBody>
    </DropdownGroup>
  );
};
