import { DropDownSelect } from '../../../../atoms';
import { useRuleConfigurator } from '../../rule-configurator-context';

import { selectorStyle } from './selector-styles';
import { GREATER_THAN_AND_EQUAL_OPERATORS } from '../../data';
import { InputWithExtras } from 'ui';
import { ConditionRow } from './ConditionRow';
import { FACT } from '../../types';
import { useCondition } from '../useCondition';

const Row = () => {
  const { updateCondition, isDisabled } = useRuleConfigurator();
  const condition = useCondition();
  return (
    <>
      <DropDownSelect
        customStyle={selectorStyle}
        defaultValue={{
          label: 'Smart Contract Address',
          value: 'Smart Contract Address',
        }}
        isDisabled
      />

      <DropDownSelect
        customStyle={selectorStyle}
        options={GREATER_THAN_AND_EQUAL_OPERATORS}
        value={GREATER_THAN_AND_EQUAL_OPERATORS.find((item) => item.value === condition.operator)}
        onChange={({ value }) => {
          updateCondition(condition.id, {
            operator: value,
          });
        }}
        isDisabled={isDisabled}
      />

      <InputWithExtras
        value={condition.value}
        onChange={(e) => {
          updateCondition(condition.id, {
            value: e.target.value?.toLowerCase(),
          });
        }}
        disabled={isDisabled}
      />
    </>
  );
};

export const RawContractAddressRuleCondition = () => (
  <ConditionRow facts={[FACT.RAW_CONTRACT_ADDRESS]}>
    <Row />
  </ConditionRow>
);
