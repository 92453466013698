import { DropDownSelect } from '../../../../atoms';
import { getMappedValue } from '../../utils';
import { useRuleConfigurator } from '../../rule-configurator-context';
import { selectorStyle } from './selector-styles';
import { CoinbasePrimeConditionMenu } from '../../../CoinbasePrimeConditionMenu';
import { FACT } from '../../types';
import { ConditionRow } from './ConditionRow';
import { useCondition } from '../useCondition';

const Row = () => {
  const { updateCondition, isDisabled } = useRuleConfigurator();
  const condition = useCondition();
  return (
    <>
      <CoinbasePrimeConditionMenu isDisabled={isDisabled} />

      <DropDownSelect
        customStyle={{ ...selectorStyle, height: 40 }}
        options={getMappedValue(condition.fact, 'operators')}
        defaultValue={{ label: 'Credits', value: 'Credits' }}
        value={getMappedValue(condition.fact, 'operators', condition.operator)}
        onChange={({ value }) => {
          updateCondition(condition.id, {
            operator: value,
          });
        }}
        isDisabled={isDisabled}
      />
    </>
  );
};

export const CoinbasePrimeRuleCondition = () => (
  <ConditionRow facts={[FACT.COINBASE_PRIME]}>
    <Row />
  </ConditionRow>
);
