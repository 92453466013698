import { ConditionSelectorMenuTrigger } from '../ConditionSelectorMenuTrigger';
import { NestConditionRow } from '../NestConditionRow';
import { ConditionSelectorSearchBar } from '../ConditionSelectorSearchBar';
import { useConditionSelectorContext } from '../condition-selector-context';
import { GroupedVirtuoso } from 'react-virtuoso';
import {
  useChainConditionGroup,
  useHedgeyConditionGroup,
  useLegalEntityConditionGroup,
  useSourceConditionGroups,
  useTransactionConditionGroup,
  useTransactionDirectionConditionGroup,
  useMyAssetConditionGroup,
  ACCOUNT_POSTING_MISC_ROWS,
  MiscConditionSelector,
  useAccountPostingRulesetMiscConditionsGroup,
  useTransactionClassificationConditionGroup,
  useTransactionActionConditionGroup,
  useTransactionSmartContractConditionGroup,
} from '../nested-menus';
import { useEffect, useState } from 'react';
import { SearchResultRow } from '../SearchResultRow';
import { ConditionGroupFilterConfig } from '../types';
import { useFilteredConditionGroups } from '../utils';

const useConditionGroupsForAccountPostingRulesetMenu = (showNestConditionRow) => {
  const assetConditionGroup = useMyAssetConditionGroup();
  const chainConditionGroup = useChainConditionGroup();
  const { conditionGroups: sourceConditionGroups } = useSourceConditionGroups();
  const transactionConditionGroup = useTransactionConditionGroup();
  const transactionDirectionConditionGroup = useTransactionDirectionConditionGroup();
  const transactionClassificationConditionGroup = useTransactionClassificationConditionGroup();
  const { conditionGroup: legalEntityConditionGroup } = useLegalEntityConditionGroup();
  const miscConditionGroup = useAccountPostingRulesetMiscConditionsGroup(showNestConditionRow);
  const hedgeyConditionGroup = useHedgeyConditionGroup();
  const transactionActionGroup = useTransactionActionConditionGroup();
  const transactionSmartContractGroup = useTransactionSmartContractConditionGroup();

  const [groups, setGroups] = useState<Omit<ConditionGroupFilterConfig, 'rows'>[]>([]);

  useEffect(() => {
    setGroups([
      assetConditionGroup,
      chainConditionGroup,
      ...sourceConditionGroups,
      transactionConditionGroup,
      transactionActionGroup,
      transactionSmartContractGroup,
      transactionDirectionConditionGroup,
      transactionClassificationConditionGroup,
      legalEntityConditionGroup,
      miscConditionGroup,
      hedgeyConditionGroup,
    ]);
  }, [
    assetConditionGroup,
    chainConditionGroup,
    sourceConditionGroups,
    transactionConditionGroup,
    transactionActionGroup,
    transactionSmartContractGroup,
    transactionDirectionConditionGroup,
    transactionClassificationConditionGroup,
    legalEntityConditionGroup,
    miscConditionGroup,
    hedgeyConditionGroup,
  ]);

  const groupedConditions = useFilteredConditionGroups(groups);

  return groupedConditions;
};

export const AccountPostingRulesetPrimaryMenu = ({ showNestConditionRow = false }) => {
  const { debouncedSearchTerm } = useConditionSelectorContext();
  const groupedConditions = useConditionGroupsForAccountPostingRulesetMenu(showNestConditionRow);
  // console.log('AccountPostingRulesetPrimaryMenu', { state });

  return (
    <div>
      <ConditionSelectorSearchBar />
      {debouncedSearchTerm === '' && (
        <div className='h-[294px] overflow-auto'>
          <div className='grid grid-cols-1 gap-1'>
            {showNestConditionRow && <NestConditionRow />}
            <ConditionSelectorMenuTrigger label={'Assets'} />
            <ConditionSelectorMenuTrigger label={'Chains'} />
            <ConditionSelectorMenuTrigger label={'Sources'} />
            <ConditionSelectorMenuTrigger label={'Transaction'} />
            <ConditionSelectorMenuTrigger label={'Transaction Action'} />
            <ConditionSelectorMenuTrigger label={'Transaction Categories'} />
            <ConditionSelectorMenuTrigger label={'Transaction Direction'} />
            <ConditionSelectorMenuTrigger label={'Transaction Smart Contract'} />
            <ConditionSelectorMenuTrigger label={'Loop'} />
            <ConditionSelectorMenuTrigger label={'Raincards'} />
            {ACCOUNT_POSTING_MISC_ROWS.map((rowData, i) =>
              i === 0 ? null : <MiscConditionSelector conditionData={{ rowData, isSelected: false }} key={rowData} />,
            )}
            <ConditionSelectorMenuTrigger label={'Hedgey'} />
            <ConditionSelectorMenuTrigger label={'Legal Entity'} />
          </div>
        </div>
      )}
      {debouncedSearchTerm !== '' && (
        <>
          {groupedConditions.headings.length === 0 && <div className='text-center my-2'>No matching results found</div>}
          {groupedConditions.headings.length > 0 && (
            <GroupedVirtuoso
              style={{ height: 250 }}
              groupCounts={groupedConditions.counts}
              itemContent={(index, groupIndex) => (
                <SearchResultRow
                  index={index}
                  groupIndex={groupIndex}
                  groupedConditions={groupedConditions}
                  key={index}
                />
              )}
              groupContent={(groupIndex) => (
                <div className='font-medium text-zinc-500 p-1 bg-white'>{groupedConditions.headings[groupIndex]}</div>
              )}
            />
          )}
        </>
      )}
    </div>
  );
};
