import { CryptoIcon } from '../utils';

export const SUPPORTED_CHAIN = [
  { label: 'Ethereum', value: 'eth', icon: <CryptoIcon symbol={'eth'} /> },
  { label: 'Bitcoin', value: 'btc', icon: <CryptoIcon symbol={'btc'} /> },
  { label: 'Solana', value: 'sol', icon: <CryptoIcon symbol={'sol'} /> },
  { label: 'Polygon', value: 'polygon', icon: <CryptoIcon symbol={'polygon'} /> },
  { label: 'Binance Smart Chain', value: 'bsc', icon: <CryptoIcon symbol={'bsc'} /> },
  { label: 'Avalanche', value: 'avalanche', icon: <CryptoIcon symbol={'avax'} /> },
  { label: 'Near', value: 'near', icon: <CryptoIcon symbol={'near'} /> },
  { label: 'Arbitrum', value: 'arb', icon: <CryptoIcon symbol={'arb'} /> },
  { label: 'Optimism', value: 'opt', icon: <CryptoIcon symbol={'opt'} /> },
  { label: 'Fantom', value: 'ftm', icon: <CryptoIcon symbol={'ftm'} /> },
  { label: 'Base', value: 'base', icon: <CryptoIcon symbol={'base'} /> },
];

export const WALLET_TYPE_OPTIONS = [
  {
    label: 'Internal',
    value: 'internal',
  },
  {
    label: 'External',
    value: 'external',
  },
];

export const WALLET_STATUS_OPTIONS = [
  {
    label: 'ACTIVE',
    value: 'ACTIVE',
  },
  {
    label: 'ARCHIVED',
    value: 'ARCHIVED',
  },
];

export const SUPPORTED_CHAINS_WITHOUT_ICON = SUPPORTED_CHAIN.map((chain) => ({
  label: chain.label,
  value: chain.value,
}));
