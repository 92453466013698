import { useQuery } from '@tanstack/react-query';
import { getExchangeSource } from 'services/http/core';
import toast from 'react-hot-toast';
import { useSession } from '../useSession';
import { QUERY_KEY, useOrgBasedQueryKey } from '../useInvalidateQuery';

export const useGetExchangeSource = (type?: string) => {
  const { organizationId } = useSession();

  const getKey = useOrgBasedQueryKey();

  return useQuery(
    getKey(QUERY_KEY.EXCHANGE_SOURCES, { type }),
    async ({ pageParam = 0 }) => {
      const response = await getExchangeSource({ organizationId, page: pageParam, legalEntityIds: [] });
      return response.data;
    },
    {
      enabled: !!organizationId,
      onError: async (error) => {
        await toast.error((error as any).message);
      },
      staleTime: 30000,
      cacheTime: 30000,
      refetchOnWindowFocus: false,
    },
  );
};
