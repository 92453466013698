import { useEffect, useRef, useState } from 'react';
import { Dropdown, DropdownBody, DropdownContent, DropdownTrigger } from '../dropdown';
import { Button } from '../button';
import { classNames, currencyImg } from '../utils';
import { IoMdCloseCircle } from 'react-icons/io';
import { MdArrowDropDown, MdSearch } from 'react-icons/md';
import { DropdownOption, ListProps, RowProps, SingleSelectProps } from './types';
import { InputWithExtras } from '../input-with-extras';
import { Avatar } from '../avatar';

export const SingleSelectMenu = ({
  disabled,
  value,
  isLoading,
  'data-cy': dataCy,
  placeholder = 'Select',
  onClearValue,
  onChange,
  options: defaultOptions = [],
  fullWidth,
  isOnSidepanel = false,
  enableSearch = true,
  onInputChange,
  enableAvatar = false,
  type = 'chain',
  isModal = false,
  clearable = true,
  enableBottomText = false,
  ...props
}: SingleSelectProps) => {
  const triggerRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState(defaultOptions);
  const [filter, setFilter] = useState('');

  const handleOnChange = (newValue: DropdownOption) => {
    setOpen(false); // Close the dropdown
    onChange && onChange(newValue); // Call the onChange callback if provided
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value);
  };

  useEffect(() => {
    // refresh options when they change
    setOptions(defaultOptions);
  }, [defaultOptions]);

  const isValueSelected = (value: DropdownOption | undefined) => (value && value.value !== '') ?? false;

  return (
    <Dropdown open={open} onOpenChange={setOpen}>
      <DropdownTrigger ref={triggerRef}>
        <Button
          data-cy={dataCy}
          isFocused={open}
          emphasis='medium'
          disabled={disabled}
          isLoading={isLoading}
          onClick={() => setOpen((prev) => !prev)}
          label={
            isValueSelected(value) ? (
              <div className='flex items-center gap-2 overflow-hidden w-full'>
                {enableAvatar && value?.icon && (
                  <div className='[&>span]:!w-6 [&>span]:!h-6 flex items-center'>
                    <Avatar
                      size='medium'
                      src={currencyImg((value.icon as string)?.toLowerCase())}
                      alt={value.icon as string}
                    />
                  </div>
                )}
                <p className='whitespace-nowrap text-ellipsis overflow-hidden'>{value?.label}</p>
              </div>
            ) : (
              placeholder
            )
          }
          className={classNames('duration-100 py-0 text-left', fullWidth && 'w-full flex justify-between', value && '')}
          labelContainerClassname='font-normal w-full overflow-hidden'
          trailingIconContainerClassname={classNames(value && 'mr-0 p-2 duration-100 rounded-r-lg')}
          trailingIcon={
            <div className='flex items-center'>
              {!!value?.value?.length && clearable && onClearValue && (
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    onClearValue && onClearValue();
                  }}
                >
                  <IoMdCloseCircle className='w-6 h-6 text-zinc-500' />
                </span>
              )}

              <MdArrowDropDown className={classNames('duration-300 w-6 h-6 text-black', open && 'rotate-180')} />
            </div>
          }
        />
      </DropdownTrigger>
      <DropdownContent
        triggerRef={triggerRef}
        sameWidthAsTrigger={fullWidth}
        className={classNames('p-1', isOnSidepanel && '!z-[60]')}
        withoutPortal={isModal}
      >
        <DropdownBody className='w-full'>
          {enableSearch && (
            <div className='p-1 mb-2'>
              <InputWithExtras
                placeholder={placeholder.replace('Select', 'Search')}
                onChange={handleInputChange}
                leading={<MdSearch className='w-5 h-5 text-zinc-500 ml-1' />}
                containerClassName='min-w-[none] w-full'
                className='rounded-lg text-zinc-500 items-center leading-5 text-base placeholder:-translate-y-0.5 shadow-[0px_2px_4px_0px_rgba(0,_0,_0,_0.05)] '
              />
            </div>
          )}

          {options.length !== 0 ? (
            <List
              type={type}
              filter={filter}
              options={options}
              enableAvatar={enableAvatar}
              enableBottomText={enableBottomText}
              handleOnChange={(option) => handleOnChange(option)}
            />
          ) : (
            <p className='text-zinc-900 text-center font-medium py-1.5 px-2'>
              {isLoading ? 'Loading...' : 'No options available'}
            </p>
          )}
        </DropdownBody>
      </DropdownContent>
    </Dropdown>
  );
};

const Row = ({ option, handleOnChange, enableAvatar, enableBottomText, type }: RowProps) => {
  return (
    <button
      onClick={() => handleOnChange(option)}
      className={classNames(
        'text-left mb-1 last:mb-0 w-full flex items-center text-base text-zinc-900  justify-between hover:bg-indigo-50 hover:cursor-pointer mb-1 last:mb-0 px-2 py-1.5 rounded-md',
        enableAvatar && 'flex justify-start items-center gap-2',
      )}
    >
      {enableAvatar && option.icon && type === 'chain' && (
        <div className='flex items-center'>
          <Avatar size='medium' src={currencyImg((option.icon as string)?.toLowerCase())} alt={option.icon as string} />
        </div>
      )}
      <div className='w-full'>
        <p
          className={classNames('whitespace-nowrap text-ellipsis overflow-hidden ', enableBottomText && 'font-medium')}
        >
          {option.label}
        </p>
        {enableBottomText && (
          <p className='whitespace-nowrap text-ellipsis overflow-hidden text-zinc-500'>{option.bottomText ?? ''}</p>
        )}
      </div>
    </button>
  );
};

const List = ({ options, filter, enableBottomText, ...props }: ListProps) => {
  if (filter) {
    return options.filter((option) => (option.label as string).toLowerCase().includes(filter.toLowerCase())).length ===
      0 ? (
      <p className='text-zinc-900 text-center font-medium py-1.5 px-2'>No options available</p>
    ) : (
      options
        .filter((option) => (option.label as string).toLowerCase().includes(filter.toLowerCase()))
        .map((option, idx) => <Row key={idx} option={option} enableBottomText={enableBottomText} {...props} />)
    );
  }
  return options.map((option, idx) => <Row key={idx} option={option} enableBottomText={enableBottomText} {...props} />);
};
