import axios from 'axios';
import { SERVER_URL_JOB_QUEUE } from '../../config';
import { convertQuery } from '../utils';

export const getRuleMetrics = async (data) =>
  axios.get(
    `${SERVER_URL_JOB_QUEUE}/get-rule-metrics?organizationId=${data.organizationId}&ruleId=${data.ruleId}&page=${data.pageParam}`,
  );

export const computeRuleMetrics = async (data) =>
  axios.post(
    `${SERVER_URL_JOB_QUEUE}/compute-rule-metrics?ruleId=${data.ruleId}&isOneTime=${data.isOneTime}${convertQuery(
      data.accountingPeriodIds,
      'accountingPeriodIds',
    )}`,
    {
      topLevelCondition: data.topLevelCondition,
    },
  );

export const deleteOneTimeRuleMetrics = async (data) =>
  axios.delete(
    `${SERVER_URL_JOB_QUEUE}/delete-one-time-rule-metrics?ruleId=${data.ruleId}&organizationId=${data.organizationId}`,
  );
