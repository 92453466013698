import { Card, CardProps } from '@tremor/react';
import { FC } from 'react';

export const TremorCard: FC<CardProps> = ({ children, className = '', ...rest }) => {
  return (
    <Card
      className={`${className} !shadow-[rgba(16,24,40,0.05)_0px_1px_2px_0px] border-[#c9c9c9] border ring-0`}
      {...rest}
    >
      {children}
    </Card>
  );
};
