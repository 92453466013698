import axios from 'axios';
import { SERVER_URL_CORE } from '../../config';
import { ReportResponse, TrialBalanceResponse } from '../response.types';
import { buildMultiParams } from './utils';

export interface getTrialBalancePayload {
  legalEntityIds?: string[];
  accountingPeriodIds?: string[];
  organizationId: string;
  tagId?: string;
  startDate?: string;
  endDate?: string;
}

export const getTrialBalance = async (data: getTrialBalancePayload) => {
  const { startDate, endDate } = data;
  const legalEntityIdsString = buildMultiParams(data?.legalEntityIds, 'legalEntityIds');
  const accountingPeriodIdsString = buildMultiParams(data?.accountingPeriodIds, 'accountingPeriodIds');
  return axios.get<{ trialBalance: TrialBalanceResponse }>(
    `${SERVER_URL_CORE}/trial-balance?organizationId=${
      data.organizationId
    }${legalEntityIdsString}${accountingPeriodIdsString}&tagId=${data?.tagId}${
      startDate ? '&startDate=' + startDate : ''
    }${endDate ? '&endDate=' + endDate : ''}`,
  );
};

export const getIncomeStatement = async (data) => {
  const legalEntityIdsString = buildMultiParams(data?.legalEntityIds, 'legalEntityIds');
  const accountingPeriodIdsString = buildMultiParams(data?.accountingPeriodIds, 'accountingPeriodIds');
  return axios.get<{ incomeStatement: ReportResponse }>(
    `${SERVER_URL_CORE}/income-statement?organizationId=${data.organizationId}${legalEntityIdsString}${accountingPeriodIdsString}&tagId=${data?.tagId}`,
  );
};

export const getBalanceSheet = async (data) => {
  const legalEntityIdsString = buildMultiParams(data?.legalEntityIds, 'legalEntityIds');
  const accountingPeriodIdsString = buildMultiParams(data?.accountingPeriodIds, 'accountingPeriodIds');
  return axios.get<{ balanceSheet: ReportResponse }>(
    `${SERVER_URL_CORE}/balance-sheet?organizationId=${data.organizationId}${legalEntityIdsString}${accountingPeriodIdsString}&tagId=${data?.tagId}`,
  );
};
