import { BarChart, Tab, TabGroup, TabList } from '@tremor/react';
import { AppBarChartProps } from './types';
import { exportChart } from '../../../../apps/next/src/lib/utils';
import { ChartExportDropdown } from '../chart-export-dropdown';
import { TremorCard } from '../tremor-card';
import { Dropdown, DropdownContent, DropdownTrigger } from '../dropdown';
import { Button } from '../button';
import { classNames } from '../utils';
import { MdArrowDropDown } from 'react-icons/md';
import { useState } from 'react';

const calculateYAxisWidth = (maxValue: number) => {
  return maxValue.toFixed(2).toString().length * 9;
};

const getMaxValue = (data: Record<string, any>[]) => {
  let maxValue = 0;
  data.forEach((val) => {
    for (const i of Object.values(val)) {
      if (typeof i === 'number' && i > maxValue) {
        maxValue = i;
      }
    }
  });
  return maxValue;
};

export const AppBarChart: React.FC<AppBarChartProps> = ({
  isLoading,
  tabs,
  containerId,
  title,
  selectedTab,
  setSelectedTab,
  category,
  setCategory,
  data,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const valueFormatter = (value: number) => {
    if (category === 'Dollar Value') return '$ ' + Intl.NumberFormat('us').format(value).toString();
    return value.toString();
  };
  const maxValue = getMaxValue(data);
  const yAxisWidth = calculateYAxisWidth(maxValue);

  return (
    <div className='px-6 py-6 relative'>
      <TremorCard id={containerId} className='border-gray-200 ring-0'>
        <div className='flex justify-between mb-6 flex-wrap gap-y-2'>
          <TabGroup onIndexChange={(index) => setSelectedTab(index)} index={selectedTab} className='w-fit'>
            <TabList variant='solid' className='border-b-0 !h-10 flex w-fit items-center px-1'>
              {tabs.map((tab) => {
                return (
                  <Tab className='border-0 data-[headlessui-state=selected]:!text-indigo-600' key={tab}>
                    {tab}
                  </Tab>
                );
              })}
            </TabList>
          </TabGroup>
          <div className='max-w-fit flex gap-x-4 ignore-export'>
            <Dropdown open={isOpen} onOpenChange={setIsOpen}>
              <DropdownTrigger>
                <Button
                  label={category}
                  emphasis='medium'
                  className='ignore-export'
                  trailingIcon={
                    <MdArrowDropDown
                      className={classNames('duration-300 w-6 h-6 text-black', isOpen && 'rotate-180')}
                    />
                  }
                />
              </DropdownTrigger>
              <DropdownContent className='p-1 flex flex-col gap-1'>
                {props.categories.map((category, index) => {
                  return (
                    <button
                      className='!border-0 font-normal px-3 py-2 text-left hover:cursor-pointer hover:bg-indigo-50 rounded-md'
                      key={index}
                      onClick={() => {
                        setCategory && setCategory(category);
                        setIsOpen(false);
                      }}
                    >
                      {category}
                    </button>
                  );
                })}
              </DropdownContent>
            </Dropdown>
            {/* <SelectMenu
              className='[&>button]:!h-10] w-[190px] font-semibold text-base'
              onValueChange={(value) => setCategory && setCategory(value)}
              value={category}
              enableClear={false}
            >
              {props.categories.map((category, index) => {
                return (
                  <SelectItemMenu className='!border-0 font-normal' value={category} key={index}>
                    {category}
                  </SelectItemMenu>
                );
              })}
            </SelectMenu> */}
            <ChartExportDropdown
              onPngExport={() => exportChart({ chartId: containerId, format: 'png', title })}
              onSvgExport={() => exportChart({ chartId: containerId, format: 'svg', title })}
            />
          </div>
        </div>
        {isLoading ? (
          <div className='h-[300px] animate-pulse bg-gray-50' />
        ) : (
          <BarChart
            {...props}
            categories={category ? [category] : props.categories}
            showLegend={false}
            data={data}
            className='h-[300px]'
            valueFormatter={valueFormatter}
            yAxisWidth={yAxisWidth}
          />
        )}
      </TremorCard>
    </div>
  );
};
