import { PickLedgerAccount } from '../form-elements';
import React, { useEffect, useState } from 'react';
import { Sidebar, SidebarTopBar, SidebarBody, SidebarFooter } from '../atoms/Sidebar';
import {
  useLegalEntities,
  useTags,
  useLedgerAccount,
  useUpdateJournalEntryLineTemplate,
  useCreateJournalEntryLineTemplate,
  useBulkDeleteJournalEntryLineTemplate,
} from '../../hooks/http';
import {
  convertSelectedJournalEntryLineTemplateToState,
  deriveError,
  getDisplayedLegalEntities,
  getDisplayedTags,
  mergeLedgerAccounts,
  mergeLegalEntities,
  mergeTags,
  TEMPLATES_COPY,
} from './utils';
import { SidebarSectionHeader } from '../atoms/Sidebar/SidebarBody/SidebarSectionHeader';
import { SidebarSection } from '../atoms/Sidebar/SidebarBody/SidebarSection';
import SidebarHeader from '../atoms/Sidebar/SidebarHeader/SidebarHeader';
import {
  InputLabel,
  TextareaInput,
  Button,
  SelectableCard,
  PercentageInputWithSelectableValues,
  SingleSelectMenu,
  MultiSelectMenu,
} from 'ui';
import { addTemplateLineValidation } from '../utils';
import { toast } from 'react-hot-toast';
import { useSession } from '../../hooks/useSession';
import { LedgerAccount } from 'services/http/response.types';
import { useInvalidateQuery } from '../../hooks';
import { DefaultLineRadioGroupData, FormState } from './types';

const DEFAULT_LINE_RADIO_GROUP_DATA: DefaultLineRadioGroupData[] = [
  {
    label: 'Credit',
    value: 'CREDIT',
    labelLeft: false,
    checked: true,
  },
  {
    label: 'Debit',
    value: 'DEBIT',
    labelLeft: false,
    checked: false,
  },
];

const AMOUNT_TYPE_OPTIONS = [
  { label: 'Gross Amount', value: 'GROSS_AMOUNT' },
  { label: 'Net Amount', value: 'NET_AMOUNT' },
  { label: 'Fee', value: 'FEE' },
  { label: 'Realized Gain Loss (+/-)', value: 'GAIN_OR_LOSS' },
  { label: 'Realized Gain / Loss (Absolute Value)', value: 'GAIN_OR_LOSS_ABS' },
  { label: 'Asset Cost Basis', value: 'ASSET_COST_BASIS' },
  { label: 'Impairment Amount', value: 'IMPAIRMENT_AMOUNT' },
  { label: 'Vested Amount', value: 'VESTED_AMOUNT' },
  { label: 'Swap Fee', value: 'SWAP_FEE' },
];

function AddTemplateLines({
  onClose,
  loading,
  journalEntryTemplateId,
  selectedJournalEntryLineTemplate,
  createDraftLine,
  deleteDraftLine,
  'data-cy': dataCy = 'addTemplateLine',
}) {
  const [form, setForm] = useState<FormState>({
    creditOrDebit: DEFAULT_LINE_RADIO_GROUP_DATA[0].value,
  });

  const { memo, legalEntityId, ledgerAccountId, creditOrDebit, amountType, allocation, tags: _tags } = form;

  const { invalidateJournalEntryTemplates } = useInvalidateQuery();

  const { organizationId } = useSession();

  const { data: tags } = useTags();
  const mergedTags = mergeTags(tags);
  const displayedTags = getDisplayedTags(mergedTags);

  const { data: legalEntities, isLoading: isLoadingLegalEntities } = useLegalEntities();
  const mergedLegalEntities = mergeLegalEntities(legalEntities);
  const displayedLegalEntities = getDisplayedLegalEntities(mergedLegalEntities);

  const { data: ledgerAccounts } = useLedgerAccount({
    pageSize: 1000,
    isLeaf: true,
  });
  const mergedLedgerAccounts = mergeLedgerAccounts(ledgerAccounts) as LedgerAccount[];

  const { mutateAsync: createLine } = useCreateJournalEntryLineTemplate();
  const getLineFromState = () => {
    const line = {
      ledgerAccountId,
      tags: _tags,
      memo,
      creditOrDebit: creditOrDebit?.toLowerCase() === 'debit' ? 'DEBIT' : 'CREDIT',
      amountType,
      allocation: Number(allocation),
      legalEntityId: legalEntityId === 'transactionLegalEntity' ? undefined : legalEntityId,
      ledgerAccount: mergedLedgerAccounts?.find((account) => account._id === ledgerAccountId)?.ledgerAccountName,
      useTransactionLegalEntity: legalEntityId === 'transactionLegalEntity',
    };
    return line;
  };
  const addJournalEntryLineTemplateToDB = async (e) => {
    e.target.classList.add('loading');
    const payload: any = {
      journalEntryTemplateId,
      journalEntryLineTemplate: { ...getLineFromState(), organizationId },
    };
    try {
      await createLine(payload);

      invalidateJournalEntryTemplates();
      toast.success('Your journal entry line template has been successfully created.');
      onClose();
    } catch (error) {
      console.error(error);
      toast.error(deriveError(error));
    }
    e.target.classList.remove('loading');
  };
  const { mutateAsync: mutateLine } = useUpdateJournalEntryLineTemplate();
  const updateLineByIdFromDB = async (e) => {
    e.target.classList.add('loading');
    const payload: any = {
      journalEntryLineTemplateId: selectedJournalEntryLineTemplate._id,
      body: { ...getLineFromState(), organizationId },
    };
    try {
      await mutateLine(payload);
      invalidateJournalEntryTemplates();
      toast.success('Your journal entry line template has been successfully updated.');
      onClose();
    } catch (error) {
      console.error(error);
      toast.error(deriveError(error));
    }
    e.target.classList.remove('loading');
  };

  const COPY_PREFIX = selectedJournalEntryLineTemplate ? 'EDIT_' : 'CREATE_';

  const { mutateAsync: deleteLine, isLoading: isDeleting } = useBulkDeleteJournalEntryLineTemplate();
  const deleteJournalEntryLineTemplateFromDB = async () => {
    const data: any = {
      journalEntryTemplateId,
      journalEntryLineTemplateIds: selectedJournalEntryLineTemplate._id,
      organizationId,
    };
    try {
      await deleteLine(data);
      toast.success('Template line deleted');
      invalidateJournalEntryTemplates();
    } catch (error) {
      console.error(error);
      toast.error(deriveError(error));
    }
  };

  useEffect(() => {
    if (selectedJournalEntryLineTemplate) {
      setForm(convertSelectedJournalEntryLineTemplateToState(selectedJournalEntryLineTemplate));
    }
  }, [selectedJournalEntryLineTemplate]);

  const entitiesWithTransactionLegalEntity = displayedLegalEntities.concat([
    {
      label: 'Transaction Legal Entity',
      value: 'transactionLegalEntity',
    },
  ]);
  const legalEntitySelectDropdownValue = selectedJournalEntryLineTemplate?.useTransactionLegalEntity
    ? 'transactionLegalEntity'
    : legalEntityId;
  return (
    <>
      <Sidebar data-cy={dataCy}>
        <SidebarTopBar
          onClose={() => {
            onClose();
          }}
        />
        <SidebarHeader data-cy={dataCy} title={TEMPLATES_COPY[`${COPY_PREFIX}LINE_SIDEBAR_HEADING`]} />
        <SidebarBody>
          <SidebarSectionHeader />
          <SidebarSection numberOfColumns={1}>
            <div>
              <InputLabel heading='Legal entity' />

              <SingleSelectMenu
                fullWidth
                isOnSidepanel
                data-cy={`${dataCy}__legalEntity`}
                placeholder='Select legal entity'
                options={entitiesWithTransactionLegalEntity ?? []}
                onChange={({ value }) => setForm({ ...form, legalEntityId: value })}
                value={entitiesWithTransactionLegalEntity.find((item) => item.value === legalEntitySelectDropdownValue)}
                isLoading={isLoadingLegalEntities}
                clearable
                onClearValue={() => setForm({ ...form, legalEntityId: undefined })}
              />
            </div>
            <div>
              <PickLedgerAccount
                value={ledgerAccountId}
                onChange={(selectedLedgerAccountId) => setForm({ ...form, ledgerAccountId: selectedLedgerAccountId })}
                dataCy={dataCy}
              />
            </div>
            <div>
              <InputLabel heading='Select type' />
              <div className='grid grid-cols-2 gap-4'>
                {DEFAULT_LINE_RADIO_GROUP_DATA.map((radioItem, i) => (
                  <SelectableCard
                    data-cy={`${dataCy}__${radioItem.label}`}
                    label={radioItem.label}
                    selected={form.creditOrDebit === radioItem.value}
                    onClick={() => setForm({ ...form, creditOrDebit: radioItem.value })}
                    key={i}
                  />
                ))}
              </div>
            </div>
            <div>
              <InputLabel heading='Amount type' />

              <SingleSelectMenu
                fullWidth
                isOnSidepanel
                isModal={false}
                data-cy={`${dataCy}__amountType`}
                placeholder='Select amount type'
                options={AMOUNT_TYPE_OPTIONS}
                onChange={({ value }: any) => setForm({ ...form, amountType: value })}
                value={AMOUNT_TYPE_OPTIONS?.find((item) => item.value === amountType)}
                onClearValue={() => setForm({ ...form, amountType: undefined })}
              />
            </div>
            <div>
              <InputLabel heading='Allocation' />
              <PercentageInputWithSelectableValues
                data-cy={`${dataCy}__allocation`}
                value={allocation !== undefined ? `${allocation}` : undefined}
                onChange={(e) =>
                  setForm({
                    ...form,
                    allocation: e.target.value
                      ? +e.target.value < 0
                        ? 0
                        : +e.target.value > 100
                          ? 100
                          : +e.target.value
                      : undefined,
                  })
                }
              />
            </div>
            <div>
              <InputLabel heading='Tags' />

              <MultiSelectMenu
                side='top'
                data-cy={`${dataCy}__tags`}
                isOnSidepanel
                dropdownContentClassName='!max-h-[300px]'
                isModal={false}
                fullWidth
                options={displayedTags ?? []}
                onChange={(values) => {
                  setForm({ ...form, tags: values.map(({ value }) => value) });
                }}
                value={displayedTags?.filter((item) => _tags?.includes(item.value))}
                getLabelFromOption={(option: any) => option.label}
                getTriggerLabel={(option: any) => option?.label}
              />
            </div>
            <div>
              <InputLabel heading='Memo' />
              <TextareaInput
                data-cy={`${dataCy}__memo`}
                placeholder='Enter memo'
                onChange={(e) => setForm({ ...form, memo: e.target.value })}
                value={memo}
              />
            </div>
          </SidebarSection>
        </SidebarBody>
        <SidebarFooter
          primaryBtn={
            <>
              <Button
                data-cy={`${dataCy}__saveButton`}
                label='Save'
                emphasis='high'
                isLoading={loading}
                onClick={async (e) => {
                  // start loading
                  if (addTemplateLineValidation(getLineFromState())) {
                    if (selectedJournalEntryLineTemplate) {
                      // we are editing
                      if (selectedJournalEntryLineTemplate._id) {
                        await updateLineByIdFromDB(e);
                      } else {
                        createDraftLine({
                          ...getLineFromState(),
                          draftId: selectedJournalEntryLineTemplate.draftId,
                        });
                        onClose();
                      }
                      // stop loading
                      return;
                    }
                    // we are creating a new line
                    if (journalEntryTemplateId) {
                      await addJournalEntryLineTemplateToDB(e);
                    } else {
                      // create draft
                      createDraftLine(getLineFromState());
                      onClose();
                    }
                    setForm({});
                  }
                  // stop loading
                }}
                disabled={loading}
              />
            </>
          }
          secondaryBtn={
            <Button
              label={TEMPLATES_COPY[`${COPY_PREFIX}LINE_SECONDARY_BUTTON`]}
              emphasis='medium'
              isLoading={isDeleting}
              onClick={async () => {
                // start loading
                if (selectedJournalEntryLineTemplate && !journalEntryTemplateId) {
                  deleteDraftLine(selectedJournalEntryLineTemplate.draftId);
                } else if (selectedJournalEntryLineTemplate) {
                  await deleteJournalEntryLineTemplateFromDB();
                }
                onClose();
              }}
              disabled={loading}
            />
          }
        />
      </Sidebar>
    </>
  );
}

export default AddTemplateLines;
