import { SourceData } from './data';
import { FACT } from './types';

export function getMappedValue(fact: FACT, type, id?: string) {
  for (const item of SourceData) {
    if (item.label === fact) {
      // options data found in RuleDropdownMockData
      // asking for specific option by id
      if (id) {
        // check if we have a specific option for the type
        if (!item.ids) throw new Error(`No ids exist for ${fact} in RuleDropdownMockData`);
        if (!item.ids[type]) throw new Error(`No ids exist for ${fact} of type ${type} in RuleDropdownMockData`);
        for (const select of item.ids[type]) {
          if (select.value === id) {
            return select;
          }
        }
      }
      // asking for all options
      if (!item.ids) throw new Error(`No ids exist for ${fact} in RuleDropdownMockData`);
      return item.ids[type];
    }
  }
}

export function getDataId(fact, name, key) {
  for (const item of SourceData) {
    if (item.label === fact && item.ids) {
      for (const value of item.ids[key]) {
        if (value.label === name) {
          console.log('getDataId: ', value.value);
          return value.value;
        }
      }
    }
  }
}
