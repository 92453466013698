import { GPTServiceIntentEnum } from 'services';
import { WalletHit } from '../../controllers/algolia';
import { InvalidateQueries } from '../../hooks';
import { AssistantsConversation } from 'schemas';

export const getWalletNameToDisplay = (wallet: WalletHit, searchTerm: string): string => {
  if (wallet.name) {
    return wallet.name;
  }

  if (wallet.addresses && wallet.addresses.length > 0) {
    return wallet.addresses.find((address) => address.toLowerCase().includes(searchTerm.toLowerCase().trim())) || '';
  }

  if (wallet.address) {
    return wallet.address;
  }

  return '';
};

export const invalidateQueriesBasedOnIntent = (intent: GPTServiceIntentEnum, invalidateQueries: InvalidateQueries) => {
  const {
    invalidateWallets,
    invalidateLedgerAccounts,
    invalidateAssets,
    invalidateLegalEntities,
    invalidateTags,
    invalidateTransactions,
  } = invalidateQueries;
  switch (intent) {
    case GPTServiceIntentEnum.WALLET_ADD_TAG:
    case GPTServiceIntentEnum.WALLET_CREATE:
    case GPTServiceIntentEnum.WALLET_DELETE:
    case GPTServiceIntentEnum.WALLET_EDIT_ADDRESS:
    case GPTServiceIntentEnum.WALLET_EDIT_ALIAS:
    case GPTServiceIntentEnum.WALLET_EDIT_LEGAL_ENTITY:
    case GPTServiceIntentEnum.WALLET_EDIT_STATUS:
      invalidateWallets();
      break;
    case GPTServiceIntentEnum.LEDGER_ACCOUNT_CREATE:
    case GPTServiceIntentEnum.LEDGER_ACCOUNT_DELETE:
    case GPTServiceIntentEnum.LEDGER_ACCOUNT_DELETE_PARENT:
    case GPTServiceIntentEnum.LEDGER_ACCOUNT_EDIT_CLEARING:
    case GPTServiceIntentEnum.LEDGER_ACCOUNT_EDIT_PARENT:
    case GPTServiceIntentEnum.LEDGER_ACCOUNT_EDIT_SEQUENCE:
    case GPTServiceIntentEnum.LEDGER_ACCOUNT_EDIT_TYPE:
    case GPTServiceIntentEnum.LEDGER_ACCOUNT_RENAME:
      invalidateLedgerAccounts();
      break;
    case GPTServiceIntentEnum.ASSET_CREATE:
      invalidateAssets();
      break;
    case GPTServiceIntentEnum.LEGAL_ENTITY_CREATE:
    case GPTServiceIntentEnum.LEGAL_ENTITY_DELETE_SPECIFIC_ENTITY:
    case GPTServiceIntentEnum.LEGAL_ENTITY_EDIT_ADDRESS:
    case GPTServiceIntentEnum.LEGAL_ENTITY_EDIT_CURRENCY:
    case GPTServiceIntentEnum.LEGAL_ENTITY_EDIT_TYPE:
    case GPTServiceIntentEnum.LEGAL_ENTITY_RENAME:
      invalidateLegalEntities();
      break;
    case GPTServiceIntentEnum.TAG_CREATE:
    case GPTServiceIntentEnum.TAG_DELETE:
    case GPTServiceIntentEnum.TAG_EDIT_VALUE:
      invalidateTags();
      break;
    case GPTServiceIntentEnum.SPAM_ASSISTANT:
      invalidateTransactions();
      break;
    default:
      break;
  }
};

export const getDataFromConversation = (assistantConversation: AssistantsConversation) => {
  const interactionHistory = assistantConversation?.interactionHistory[0];
  let message = 'Please try again';
  let outputs = [];
  let functionName = '';
  if (interactionHistory) {
    const { functionCallResponses } = interactionHistory;
    if (functionCallResponses[0]?.output) {
      outputs = functionCallResponses[0].output;
    }
    functionName = functionCallResponses[0].functionName;
    message = interactionHistory.message.content[0].text.value;
  }

  return { message, outputs, functionName };
};
