import { useMemo } from 'react';
import { useAllLegalEntities, useAllTags } from '../../hooks';
import { getDisplayedLegalEntities, getDisplayedTags } from '../templates/utils';
import { InputLabel, InputWithExtras, MultiSelectMenu, SelectableCard, SimpleMenu, SingleSelectMenu } from 'ui';
import { TagsInput } from '../atoms';
import { WALLET_CHAIN, WALLET_TYPE } from 'services/http/response.types';
import { SUPPORTED_CHAIN, WALLET_TYPE_OPTIONS } from '../Sources';
//import { SUPPORTED_CHAINS_WITHOUT_ICON, WALLET_TYPE_OPTIONS } from '../Sources/constants';

export interface CreateSourceFormState {
  walletType: WALLET_TYPE;
  name: string;
  chain: WALLET_CHAIN;
  address: string;
  addresses: string[];
  legalEntityId?: string;
  parentWalletId?: string;
  tags: string[];
  import?: string;
  importStartDate?: Date;
}

export interface CreateSourceFormElementsProps {
  formState: CreateSourceFormState;
  setFormState: React.Dispatch<React.SetStateAction<CreateSourceFormState>>;
  showTagsFormElement?: boolean;
  'data-cy'?: string;
}

export const CreateSourceFormElements = ({
  formState,
  setFormState,
  showTagsFormElement = true,
  'data-cy': dataCy = 'createSource',
}: CreateSourceFormElementsProps) => {
  const { legalEntities, isLoading: isLoadingEntities } = useAllLegalEntities();
  const LEGAL_ENTITY_SELECT_OPTIONS = useMemo(() => getDisplayedLegalEntities(legalEntities), [legalEntities]);

  const { tags, isLoading: isLoadingTags } = useAllTags();
  const TAG_OPTIONS = useMemo(() => getDisplayedTags(tags) ?? [], [tags]);

  const pastThreeMonthData = useMemo(() => {
    const date = new Date();
    date.setMonth(date.getMonth() - 3);
    date.setDate(1);

    const data = {
      value: 'Past three months to date',
      date,
    };
    return data;
  }, []);

  const currentYearFirstDate = useMemo(() => {
    const date = new Date(`January 1, ${new Date().getFullYear()}`);
    const data = {
      value: 'Year to date',
      date,
    };
    return data;
  }, []);

  return (
    <>
      <div>
        <InputLabel heading='Wallet type' />

        <SingleSelectMenu
          isModal
          fullWidth
          isOnSidepanel
          placeholder='Select wallet type'
          enableSearch={false}
          onClearValue={() => setFormState((prev) => ({ ...prev, walletType: WALLET_TYPE.INTERNAL }))}
          options={WALLET_TYPE_OPTIONS}
          onChange={(option) => {
            setFormState((prev) => {
              const values = {
                walletType: option.value as WALLET_TYPE,
                legalEntityId: option.value === 'external' ? undefined : prev.legalEntityId,
                import: option.value === 'external' ? undefined : prev.import,
              };
              return { ...prev, ...values };
            });
          }}
          value={WALLET_TYPE_OPTIONS.find((item) => item.value === formState.walletType)}
          data-cy={`${dataCy}__walletType`}
        />
      </div>
      <div>
        <InputLabel heading='Wallet name' />
        <InputWithExtras
          onChange={(e) => setFormState((prev) => ({ ...prev, name: e.target.value }))}
          value={formState.name}
          data-cy={`${dataCy}__walletName`}
        />
      </div>
      <div>
        <InputLabel heading='Chain' />
        <SimpleMenu
          options={SUPPORTED_CHAIN}
          onChange={(option) => setFormState((prev) => ({ ...prev, chain: option.value }))}
          selectedValue={formState.chain}
          data-cy={`${dataCy}__walletChain`}
        />
      </div>
      {/**
       * @todo: use a constant for the chain types
       */}
      {formState.chain && Object.values(WALLET_CHAIN).includes(formState.chain) && (
        <div>
          <InputLabel heading='Wallet address' />
          <InputWithExtras
            value={formState.address}
            onChange={(e) => setFormState((prev) => ({ ...prev, address: e.target.value }))}
            data-cy={`${dataCy}__walletAddress`}
          />
        </div>
      )}

      {formState.chain === 'btc' && (
        <div>
          <TagsInput
            label='Wallet address'
            tags={formState.addresses}
            onEnter={(addressesString) => {
              const addressesArray = addressesString.split(',').map((str) => str.trim());
              setFormState((prev) => ({
                ...prev,
                addresses: prev?.addresses ? [...prev.addresses, ...addressesArray] : addressesArray,
              }));
            }}
            onRemove={(removedAddress) =>
              setFormState((prev) => ({
                ...prev,
                addresses: prev.addresses.filter((address) => address !== removedAddress),
              }))
            }
          />
        </div>
      )}

      <div>
        <InputLabel heading='Legal entity' />
        <SingleSelectMenu
          isModal
          fullWidth
          isOnSidepanel
          options={LEGAL_ENTITY_SELECT_OPTIONS}
          disabled={formState.walletType === 'external'}
          placeholder='Assign legal entity'
          isLoading={isLoadingEntities}
          data-cy={`${dataCy}__legalEntity`}
          onChange={(option) => {
            setFormState((prev) => ({ ...prev, legalEntityId: option.value }));
          }}
          value={LEGAL_ENTITY_SELECT_OPTIONS.find((item) => item.value === formState.legalEntityId)}
          onClearValue={() => setFormState((prev) => ({ ...prev, legalEntityId: undefined as any }))}
        />
      </div>
      {showTagsFormElement && (
        <div>
          <InputLabel heading='Tags' />
          <MultiSelectMenu
            side='top'
            dropdownContentClassName='!max-h-[300px]'
            fullWidth
            isOnSidepanel
            options={TAG_OPTIONS as any[]}
            onChange={(options) => {
              setFormState((prev) => ({ ...prev, tags: options.map((option) => option.value) }));
            }}
            value={TAG_OPTIONS.filter((tag) => formState.tags.includes(tag.value))}
            isLoading={isLoadingTags}
            data-cy={`${dataCy}__tags`}
            getLabelFromOption={(option) => option.label}
            getTriggerLabel={(option) => option.label}
            onClearValue={() => setFormState((prev) => ({ ...prev, tags: [] }))}
          />
        </div>
      )}
      {formState.walletType === 'internal' && (
        <div className='mb-4'>
          <InputLabel heading='Pull transactions for past' />
          <div className='grid grid-cols-2 gap-4'>
            <SelectableCard
              label={'3 months to date'}
              selected={formState.import === pastThreeMonthData.value}
              onClick={() =>
                setFormState((prev) => ({
                  ...prev,
                  import: pastThreeMonthData.value,
                  importStartDate: pastThreeMonthData.date,
                }))
              }
              data-cy={`${dataCy}__pastThreeMonths`}
            />
            <SelectableCard
              label={'Year to date'}
              selected={formState.import === currentYearFirstDate.value}
              onClick={() =>
                setFormState((prev) => ({
                  ...prev,
                  import: currentYearFirstDate.value,
                  importStartDate: currentYearFirstDate.date,
                }))
              }
              data-cy={`${dataCy}__yearToDate`}
            />
          </div>
        </div>
      )}
    </>
  );
};
