import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { styled } from '@stitches/react';

export const StyledContent = styled(DropdownMenu.Content, {
  position: 'relative',
  width: 250,
  padding: 8,
  borderRadius: 8,
  boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.15)',
  backgroundColor: 'White',
  zIndex: 6,
  maxHeight: 450,
  overflowY: 'auto',
});

export const StyledTrigger = styled(DropdownMenu.Trigger, {
  '&[data-state="open"] button': { borderColor: 'black' },
});
