import React from 'react';
import { InputLabel, SimpleMenu } from 'ui';
import { useAccountingPeriods } from '../../../../hooks/http';
import { getDisplayedAccountingPeriods, mergeAccountingPeriods } from '../../../templates/utils';
import { ReviewBalances } from '../../ReviewBalanceSheet';

export interface SelectAndReviewAccountingPeriodProps {
  setAccountingPeriodId: (v: string) => void;
  accountingPeriodId?: string;
}

export function SelectAndReviewAccountingPeriod({
  setAccountingPeriodId,
  accountingPeriodId,
}: SelectAndReviewAccountingPeriodProps) {
  const { data, isLoading } = useAccountingPeriods({
    pageSize: 1000,
    status: 'Open',
  });
  const merged = mergeAccountingPeriods(data);
  const displayedRes = getDisplayedAccountingPeriods(merged) || [];

  return (
    <div>
      <div className='mb-6'>
        <InputLabel heading={'Accounting period'} tooltip={'Select the accounting period you want to close'} />
        <SimpleMenu
          isLoading={isLoading}
          onChange={({ value }) => setAccountingPeriodId(value)}
          options={displayedRes.filter(({ label }) => !label.includes('Closed'))}
          placeholder='Select periods'
          selectedValue={accountingPeriodId}
        />
      </div>
      {accountingPeriodId && <ReviewBalances accountingPeriodId={accountingPeriodId} />}
    </div>
  );
}
