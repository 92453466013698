import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'ui';
import { handleDownloadSources } from './utils';
import dynamic from 'next/dynamic';

const CSVLink = dynamic(() => import('react-csv').then((res) => res.CSVLink), { loading: () => <></> });

export const SourcesCSV = ({ filterHelpers, sourcesIQData, organizationId, isLoading, debouncedSearchTerm }) => {
  const CSVDownload = useRef(null);
  const [pages, setPages] = useState([]);
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);

  useEffect(() => {
    if (pages.length > 0) {
      (CSVDownload as any).current?.click();
    }
  }, [pages, CSVDownload]);
  return (
    <>
      <CSVLink
        data={pages}
        target='_blank'
        className='hidden'
        filename={`sources-export-${new Date().toLocaleDateString()}.csv`}
      >
        <span ref={CSVDownload}></span>
      </CSVLink>
      <Button
        label='Download'
        emphasis='medium'
        onClick={async () => {
          setIsLoadingDownload(true);
          await handleDownloadSources({
            organizationId,
            setPages,
            sourcesIQData,
            isLoading,
            filterHelpers,
            debouncedSearchTerm,
          });
          setIsLoadingDownload(false);
        }}
        isLoading={isLoadingDownload}
        disabled={isLoading || isLoadingDownload}
      />
    </>
  );
};

export default SourcesCSV;
