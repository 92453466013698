import clsx from 'clsx';
import { classNames as cn } from '../utils';
import { Avatar } from '../avatar';
import { TwoLineAvatarProps } from './types';

export function TwoLineAvatar({
  label,
  bottomText,
  src = '',
  alt = '',
  type = 'person',
  size = 'extra-large',
  isAvatarVisible,
  state = 'default',
  textAlign = 'text-center',
  withoutPaddingLeft = false,
  ...rest
}: TwoLineAvatarProps) {
  return (
    <div
      className={cn(
        clsx(
          'group relative overflow-hidden w-[inherit]',
          'px-6 flex items-center gap-3',
          'hover:cursor-pointer',
          { 'bg-indigo-50': state === 'selected' },
          { 'border-b-2 bg-indigo-100': state === 'active' },
          { 'text-center justify-center': textAlign === 'text-center' },
          { 'text-left justify-start': textAlign === 'text-left' },
          { 'text-right justify-end': textAlign === 'text-right' },
          { '!pl-0': withoutPaddingLeft },
        ),
      )}
    >
      {isAvatarVisible && <Avatar src={src} alt={alt} type={type} size={size} {...rest} />}

      <div className='flex-shrink-0  flex-grow w-full'>
        <p className='text-base text-zinc-900 leading-6 truncate'>{label}</p>
        <p className='text-base text-zinc-600 leading-6 truncate'>{bottomText}</p>
      </div>
    </div>
  );
}
