import {
  Button,
  Dropdown,
  DropdownBody,
  DropdownContent,
  DropdownTrigger,
  InputLabel,
  SearchInput,
  classNames,
} from 'ui';
import { useJournalEntryTemplates } from '../../hooks';
import { getDisplayedJournalEntryTemplates, mergeJournalEntryTemplates } from '../templates/utils';
import { useRef, useState } from 'react';
import { MdArrowDropDown } from 'react-icons/md';
import { IoMdCloseCircle } from 'react-icons/io';

export const PickJournalEntryTemplateFE = ({
  value,
  onChange,
}: {
  value?: string;
  onChange: (value: string) => void;
}) => {
  const { data: templatePages, isLoading: isLoadingTemplates } = useJournalEntryTemplates({
    pageSize: 1000,
  });

  const merged = mergeJournalEntryTemplates(templatePages);
  const displayedTemplates = getDisplayedJournalEntryTemplates(merged);

  const triggerRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState('');
  return (
    <div>
      <InputLabel heading='Journal entry template' />
      <Dropdown open={open} onOpenChange={setOpen}>
        <DropdownTrigger ref={triggerRef} className='w-full '>
          <span>
            <Button
              label={
                !value ? (
                  'Select Journal Entry Template'
                ) : (
                  <p className=' truncate'>{displayedTemplates.find((template) => template.value === value)?.label}</p>
                )
              }
              className={classNames('duration-100 py-0 w-full justify-between overflow-hidden')}
              labelContainerClassname='font-normal overflow-hidden text-base'
              emphasis='medium'
              isLoading={isLoadingTemplates}
              trailingIcon={
                <div className='flex items-center'>
                  {value && (
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        onChange('');
                      }}
                    >
                      <IoMdCloseCircle className='w-6 h-6 text-zinc-500' />
                    </span>
                  )}
                  <MdArrowDropDown className={classNames('duration-300 w-6 h-6 text-black', open && 'rotate-180')} />
                </div>
              }
            />
          </span>
        </DropdownTrigger>
        <DropdownContent triggerRef={triggerRef} sameWidthAsTrigger className='z-sidepanel  p-1'>
          <DropdownBody className='relative'>
            <SearchInput
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              containerClassName='w-full p-2 shadow-none'
              className='max-w-none w-full'
            />
            {displayedTemplates.map((template) => {
              if (template.label.toLowerCase().includes(filter.toLocaleLowerCase()) || !filter.length) {
                return (
                  <button
                    key={template.id}
                    onClick={() => {
                      onChange(template.value);
                      setOpen(false);
                    }}
                    className={classNames(
                      'w-full text-left mb-1 last:mb-0 text-zinc-900 pl-3 pr-2 py-2 cursor-pointer flex justify-between items-center duration-300 rounded-md hover:cursor-pointer hover:bg-indigo-50 overflow-hidden whitespace-nowrap text-ellipsis',
                      value === template.value && 'bg-indigo-50',
                    )}
                  >
                    <span className='truncate'>{template.label}</span>
                  </button>
                );
              }
            })}
          </DropdownBody>
        </DropdownContent>
      </Dropdown>
    </div>
  );
};
