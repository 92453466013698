import React, { useContext, useEffect, useState } from 'react';
import MetricItem from './MetricItem';
import { useComputeRuleMetrics, useDeleteOneTimeRuleMetrics, useGetRuleMetricsQuery } from '../../../hooks/http';
import { deriveError, millisecondsToTime } from '../../templates/utils';
import { Button, LoaderIcon, classNames } from 'ui';
import { toast } from 'react-hot-toast';
import { JOB_NAME } from 'services/http';
import { BG_JOB_STATE } from '../../job-progress/types';
import { useBgJobTrackerState } from '../../job-progress/useBgJobTrackerState';
import { useSyncedBgJobTrackerProps } from '../../job-progress/useSyncedBgJobTrackerProps';
import { JobProgressContext } from '../../../context/JobProgressProvider';
import * as Progress from '@radix-ui/react-progress';
import { ViewMatches } from './ViewMatches';
import { mergeMetrics } from './utils';
const HitRateMetricItem = ({ value }) => (
  <MetricItem value={value} label='Hit rate' formatInPercentage={value !== '-'} />
);
const MatchesMetricItem = ({ value }) => <MetricItem value={value} label='Matches' />;
const RuntimeMetricItem = ({ value }) => <MetricItem value={value} label='Runtime' />;

// const getLoaderDescriptionFromBgState = (bgJobState: BG_JOB_STATE) => {
//   switch (bgJobState) {
//     case BG_JOB_STATE.PENDING_CREATE:
//       return 'Ready';
//     case BG_JOB_STATE.BEING_CREATED:
//       return 'Preparing job';
//     case BG_JOB_STATE.PROCESSING:
//       return 'Processing';
//     case BG_JOB_STATE.COULD_NOT_BE_CREATED:
//       return 'Failure';
//     case BG_JOB_STATE.CANCELLED:
//       return 'Cancelled';
//     case BG_JOB_STATE.FAILURE:
//       return 'Failure';
//     case BG_JOB_STATE.SUCCESS:
//       return 'Success';
//   }
// };

const defaultMetricsState: RuleMetricsState = { hitRate: '-', matchCount: '-', execTime: '-', totalTransactions: '' };

interface RuleMetricsState {
  hitRate: string;
  matchCount: string;
  execTime: string;
  totalTransactions: string;
}

function RuleMetrics({
  organizationId,
  ruleId,
  accountingPeriodIds,
  isOneTime,
  topLevelCondition,
  setRuleMetricsBgTask,
}) {
  const [loading, setLoading] = useState(false);
  const { startTrackingJob } = useContext(JobProgressContext);
  const [error, setError] = useState<string>('');
  const [progressPercent, setProgressPercent] = useState(0);
  const [bgJobState, setBgJobState] = useBgJobTrackerState(BG_JOB_STATE.PENDING_CREATE);

  const { mutateAsync: deleteOneTimeRuleMetrics, isLoading: isLoadingDeleteOneTimeRuleMetrics } =
    useDeleteOneTimeRuleMetrics();
  const { mutateAsync: computeRuleMetrics, isLoading: isLoadingStartRuleMetricsTest } = useComputeRuleMetrics();
  const postRequestsLoading = isLoadingDeleteOneTimeRuleMetrics || isLoadingStartRuleMetricsTest;
  const startTest = async () => {
    if (!accountingPeriodIds?.length) {
      toast.error('Accounting period is required');
      return;
    }
    try {
      setLoading(true);
      setBgJobState(BG_JOB_STATE.PENDING_CREATE);
      setRuleMetricsBgTask((prev) => ({ ...prev, referenceId: ruleId }));
      setMetrics(defaultMetricsState);
      setBgJobState(BG_JOB_STATE.BEING_CREATED);
      await deleteOneTimeRuleMetrics({
        ruleId,
        organizationId,
      });
      await computeRuleMetrics({
        organizationId,
        ruleId,
        accountingPeriodIds,
        isOneTime,
        topLevelCondition,
      });

      startTrackingJob(ruleId, JOB_NAME.RULE_METRICS_JOB, false);
    } catch (error: any) {
      console.error(error);
      if (error.response?.status === 500) {
        toast.error('Something went wrong while creating the job');
        setError(deriveError(error));
      } else toast.error(deriveError(error));
    } finally {
      setLoading(false);
    }
  };

  const { jobConfig } = useSyncedBgJobTrackerProps({
    jobName: JOB_NAME.RULE_METRICS_JOB,
    referenceId: ruleId,
    bgJobState,
    setBgJobState,
  });

  useEffect(() => {
    setRuleMetricsBgTask((prev) => ({ ...prev, bgJobState }));
  }, [bgJobState]);

  const [metrics, setMetrics] = useState(defaultMetricsState);

  const { data: metricsFromServer, fetchNextPage, isFetchingNextPage } = useGetRuleMetricsQuery({ ruleId, bgJobState });
  const getMetrics = mergeMetrics(metricsFromServer);
  useEffect(() => {
    if (jobConfig?.toProcessCount)
      setProgressPercent(Math.round((100 * (jobConfig.completedCount ?? 0)) / jobConfig.toProcessCount));
    else setProgressPercent(0);
  }, [jobConfig]);

  useEffect(() => {
    if (metricsFromServer)
      setMetrics({
        hitRate: `${getMetrics.hitRate}`,
        matchCount: `${getMetrics.matchCount}`,
        execTime: millisecondsToTime(getMetrics.executionTime) ?? '-',
        totalTransactions: `${getMetrics.totalTransactions}`,
      });
    // console.log({ metricsFromServer })
  }, [metricsFromServer]);
  let jobStatus = <></>;
  if (postRequestsLoading) {
    jobStatus = (
      <div className='grid grid-cols-[max-content_max-content] gap-x-4 justify-center items-center'>
        <div>Job Queued</div>
        <div>
          <LoaderIcon />
        </div>
      </div>
    );
  } else if (jobConfig?.jobStatus === 'COMPLETED') {
    jobStatus = <>Job Completed</>;
  } else if (bgJobState === BG_JOB_STATE.PENDING_CREATE) {
    jobStatus = <></>;
  } else {
    jobStatus = (
      <div className='grid grid-cols-[max-content_max-content] gap-x-4 justify-center items-center'>
        <div>Job in progress {metrics.totalTransactions} transactions</div>
      </div>
    );
  }
  return (
    <div className='flex flex-col gap-4 py-8'>
      <div className='border rounded-lg flex flex-col justify-between'>
        <div className={classNames('py-6 px-10', 'relative')}>
          <div className='w-full  overflow-clip grid content-center '>
            <Progress.Root
              className='w-75 rounded-full overflow-hidden relative h-6 bg-gradient-to-r from-blue-600 to-purple-900'
              value={progressPercent}
            >
              <Progress.Indicator
                className='bg-gradient-to-r from-blue-900 to-blue-800 w-full h-full transition-colors'
                style={{ transform: `translateX(-${100 - progressPercent}%)` }}
              />
              {jobConfig?.jobStatus !== 'COMPLETED' && (
                <div className='absolute inset-0 flex items-center justify-center'>
                  <span className='text-white font-semibold'>{progressPercent}%</span>
                </div>
              )}
            </Progress.Root>
            <div className='mt-2'>
              <MetricItem label={jobStatus} value='' />
            </div>
          </div>
        </div>
        <div className='flex w-full px-8 justify-between items-center border-y py-4'>
          <HitRateMetricItem value={metrics.hitRate} />
          <MatchesMetricItem value={metrics.matchCount} />
          <RuntimeMetricItem value={metrics.execTime} />
        </div>
        {!!getMetrics?.matches?.length && (
          <ViewMatches
            isFetchingNextPage={isFetchingNextPage}
            fetchNextPage={fetchNextPage}
            matches={getMetrics?.matches}
          />
        )}
        {error && <div className='border-red p-2 rounded-lg'>{error}</div>}
      </div>

      <div>
        <Button
          label='Start test'
          emphasis='medium'
          className={`place-self-end`}
          onClick={startTest}
          disabled={loading}
        />
      </div>
    </div>
  );
}

export default RuleMetrics;
