import { useState } from 'react';
import { loginUser } from 'services/http/auth';
import { useSession } from './useSession';
import { toast } from 'react-hot-toast';
import { deriveError } from '../components/templates/utils';
import { Organization, OrganizationMembers } from 'schemas';
import { setCookie } from 'cookies-next';
import { UserInLocalStorage } from '../components/types';
import { useRouter } from 'next/navigation';

export const getSessionPayloadOnNewSessionCreate = (httpResponseData: {
  user: UserInLocalStorage;
  organizations: OrganizationMembers[];
}) => {
  const { user, organizations } = httpResponseData;
  const sessionPayload = {
    user,
    memberships: organizations,
    organizationId: (organizations[0]?.organizationId as Organization)?._id,
  };
  return sessionPayload;
};

export const useLogin = () => {
  const { setSessionData } = useSession();
  const [isLoading, setIsLoading] = useState(false);
  const router = useRouter();

  const login = async (formState) => {
    setIsLoading(true);
    try {
      const response = await loginUser({ user: formState });
      setSessionData(getSessionPayloadOnNewSessionCreate(response.data));
      setCookie('jwt', response.data.user.jwt);
      // const { user } = response.data;
      // await segmentEvents.login(user._id, {
      //   name: user.email,
      //   email: user.email,
      // });

      setIsLoading(false);
      if (response.data.organizations[0]?.organizationId?._id) {
        setCookie('organizationId', response.data.organizations[0].organizationId._id);
        router.replace('/overview');
      } else router.replace('/onboarding/workspace');
    } catch (error: any) {
      setIsLoading(false);
      console.log(error?.response || error.message);
      toast.error(deriveError(error));
    }
  };

  return { login, isLoading };
};
