import axios from 'axios';
import { prepareSearchParams } from './utils';
import { SERVER_URL_CORE, SERVER_URL_IMPAIRMENT_ENGINE } from '../../config';

export const getAssets = async (params) => {
  const stringifiedParams = prepareSearchParams(params, {
    isPaginated: true,
  });
  return axios.get(`${SERVER_URL_CORE}/asset?${stringifiedParams}`);
};

export const createAsset = async (data) => {
  return axios.post(`${SERVER_URL_CORE}/asset?organizationId=${data.organizationId}`, data);
};

export const manualAssetImpairment = (data) => {
  return axios.post(
    `${SERVER_URL_IMPAIRMENT_ENGINE}/manual-asset-impairment?organizationId=${data.organizationId}`,
    data,
  );
};

export const getAssetMetrics = async (params) => {
  const stringifiedParams = prepareSearchParams(params);

  return axios.get(`${SERVER_URL_CORE}/get-asset-metrics?${stringifiedParams}`);
};

export const getAssetTypes = async () => {
  return axios.get<{ assetTypes: string[] }>(`${SERVER_URL_CORE}/asset-types`);
};
