import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY, useOrgBasedQueryKey } from '../useInvalidateQuery';
import { useSession } from '../useSession';
import { getJournalEntryVolume } from 'services/http/analytics/journals';

type useGetJournalVolumeParams = {
  legalEntityIds?: string[];
  accountingPeriodIds?: string[];
  status?: string[];
};

export const useGetJournalVolume = (params: useGetJournalVolumeParams) => {
  const { legalEntityIds, accountingPeriodIds, status } = params;

  const getKey = useOrgBasedQueryKey();
  const { organizationId, userId } = useSession();

  return useQuery(
    getKey(QUERY_KEY.JOURNAL_ENTRY_VOLUME, params),
    async () => {
      const response = await getJournalEntryVolume({
        legalEntityIds,
        userId,
        accountingPeriodIds,
        status,
      });
      console.log(response.data);
      return response.data;
    },
    {
      enabled: !!organizationId,
      staleTime: 1000 * 60 * 30,
      cacheTime: 1000 * 60 * 5,
    },
  );
};
