import { useState } from 'react';
import { Button, Modal, ModalContent, ModalTrigger } from 'ui';
import { Table } from '../../dashboard';
import { useRouter } from 'next/router';
import { TransactionsHeaderData } from '../../../constants';
import { tableTransactionsFormatter } from '../../PageComponents';
import { useSources } from '../../../hooks';
import { mergeSources } from '../../templates/utils';
export const ViewMatches = ({ matches, fetchNextPage, isFetchingNextPage }) => {
  const [open, setOpen] = useState(false);
  const { push } = useRouter();
  const { data: sourcesIQData } = useSources({ pageSize: 2000, walletTypes: ['internal', 'external'] });
  const mergedSources = mergeSources(sourcesIQData);
  const onClose = () => setOpen(false);
  const getTableData = tableTransactionsFormatter(matches, mergedSources);
  const renderMatches = () => {
    if (!matches.length) return <p>No matches found</p>;
    return (
      <div className='max-h-[450px] overflow-hidden overflow-y-auto'>
        <Table
          tableData={{ Data: getTableData }}
          tableHeader={TransactionsHeaderData}
          onRowClick={(row) => {
            push(`/ledger/transactions/${row.original._id}`);
          }}
          isFetchingNextPage={isFetchingNextPage}
          useInViewCallback={() => fetchNextPage()}
        />
      </div>
    );
  };

  return (
    <div className='view-matches'>
      <div className='py-2 px-8'>
        <Modal open={open} onOpenChange={setOpen}>
          <ModalTrigger asChild>
            <Button
              className='w-full'
              label='View Matches'
              emphasis='medium'
              onClick={onClose}
              disabled={!matches || matches.length === 0}
            />
          </ModalTrigger>
          <ModalContent className='overflow-hidden' onClose={onClose}>
            {renderMatches()}
          </ModalContent>
        </Modal>
      </div>
    </div>
  );
};
