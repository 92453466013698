import { IndeterminateCheckbox } from './IndeterminateCheckbox';
import { Avatar } from '../avatar';

import clsx from 'clsx';
import { classNames as cn } from '../utils';
import { LeadingCellProps } from './types';

export function LeadingCell({
  state,
  label,
  bottomText,
  src = '',
  alt = '',
  textAlign = 'text-left',
  type = 'chain',
  isAvatarVisible = true,
  isCheckboxVisible = false,
  isHeader = false,
  checked,
  onChange,
  indeterminate,
  withoutPaddingLeft = false,
  size = 'extra-large',
  icon,
  emoji = '',
  containerClassNames = '',
  initials,
  secondAvatar,
  secondAvatarPosition,
  isFlipped,
}: LeadingCellProps) {
  return (
    <div
      className={cn(
        clsx(
          'px-6 overflow-hidden ',
          'flex items-center gap-3',
          'before:absolute before:w-1 before:inset-y-0 before:left-0 before:hidden before:bg-indigo-600',
          { 'bg-indigo-50 ': state === 'selected' },
          { 'border-b-2 border-b-zinc-300 bg-indigo-100 before:block': state === 'active' },
          { 'text-center justify-center': textAlign === 'text-center' },
          { 'text-left justify-start': textAlign === 'text-left' },
          { 'text-right justify-end': textAlign === 'text-right' },
          { 'pl-0': withoutPaddingLeft },
        ),
      )}
    >
      {isCheckboxVisible && (
        <IndeterminateCheckbox
          isHeader={isHeader}
          checked={checked}
          indeterminate={indeterminate}
          onChange={onChange}
        />
      )}

      {isAvatarVisible && (
        <Avatar
          src={src}
          alt={alt}
          type={type}
          size={size}
          containerClassNames={containerClassNames}
          emoji={emoji}
          icon={icon}
          initials={initials}
          secondAvatar={secondAvatar}
          secondAvatarPosition={secondAvatarPosition}
          isFlipped={isFlipped}
        />
      )}

      <div className='flex-shrink-0 flex-grow w-full'>
        <p className='text-lg text-zinc-900 leading-6 font-medium'>{label}</p>
        {bottomText && <p className='text-zinc-600 leading-6'>{bottomText}</p>}
      </div>
    </div>
  );
}
