const SERVER_URL_PRODUCTION = 'https://api.entendre.finance';
const SERVER_URL_STAGING = 'https://api-staging.entendre.finance';
const SERVER_URL_PREVIEW = 'https://api-preview.entendre.finance';
const WS_URL_PREVIEW = 'wss://79p4yqou70.execute-api.us-east-1.amazonaws.com/preview/';
const WS_URL_STAGING = 'wss://dbs17d3169.execute-api.us-east-1.amazonaws.com/staging/';
const WS_URL_PRODUCTION = 'wss://7oket40lcj.execute-api.us-east-1.amazonaws.com/production/';
const PORT_LOCAL_AUTH = 3003;
const PORT_LOCAL_CORE = 4000;
const PORT_LOCAL_JOB_QUEUE = 5020;
const PORT_LOCAL_FILE_UPLOAD = 6000;
const PORT_LOCAL_IMPAIRMENT_ENGINE = 5006;
const PORT_LOCAL_CONFIGURATION_SERVICE = 5010;
const PORT_LOCAL_ORG_SERVICE = 5030;
const PORT_LOCAL_GL_INTEGRATION_SERVICE = 5080;
const PORT_LOCAL_GPT = 5095;
const PORT_LOCAL_ANALYTICS = 6000;
const PORT_LOCAL_RAINCARDS = 6005;
const PORT_LOCAL_NFT_SERVICE = 7000;
const PORT_LOCAL_BILLING_SERVICE = 9000;

const AUTH = '/auth';
const CORE = '/core';
const ANALYTICS = '/analytics-service';
const GPT = '/gpt-service';
const JOB_QUEUE = '/job-queue';
const FILE_UPLOAD = '/file-upload';
const IMPAIRMENT_ENGINE = '/impairment-engine';
const CONFIGURATION_SERVICE = '/configuration-service';
const ORG_SERVICE = '/org-service';
const TABS_SERVICE = '/tabs-service';
const GL_INTEGRATION_SERVICE = '/gl-integration-service';
const RAINCARDS_SERVICE = '/raincards';
const LOOP_SERVICE = '/loop';
const NFT_SERVICE = '/nft-service';
const BILLING_SERVICE = '/billing-service';

console.log({ vercelEnv: process.env.NEXT_PUBLIC_VERCEL_STAGE, stageEnv: process.env.NEXT_PUBLIC_STAGE });
const NEXT_PUBLIC_VERCEL_STAGE = process.env.NEXT_PUBLIC_VERCEL_STAGE || 'staging';

const IS_LOCAL = NEXT_PUBLIC_VERCEL_STAGE === 'local';
const IS_STAGING = NEXT_PUBLIC_VERCEL_STAGE === 'staging';
const IS_PREVIEW = NEXT_PUBLIC_VERCEL_STAGE === 'preview';

const ALGOLIA_TRANSACTION_MAP = {
  local: 'dev_Transactions',
  preview: 'preview_Transactions',
  staging: 'dev_Transactions',
  production: 'Transactions',
};
const ALGOLIA_JOURNAL_ENTRIES_MAP = {
  local: 'dev_JournalEntries',
  preview: 'preview_JournalEntries',
  staging: 'dev_JournalEntries',
  production: 'JournalEntries',
};

const ALGOLIA_JOURNAL_ENTRY_TEMPLATES_MAP = {
  local: 'dev_JournalEntryTemplates',
  preview: 'preview_JournalEntryTemplates',
  staging: 'dev_JournalEntryTemplates',
  production: 'JournalEntryTemplates',
};

const ALGOLIA_ACCOUNT_POSTING_RULESET_MAP = {
  local: 'dev_AccountPostingRuleSets',
  preview: 'preview_AccountPostingRuleSets',
  staging: 'dev_AccountPostingRuleSets',
  production: 'AccountPostingRuleSets',
};

const ALGOLIA_WALLETS_MAP = {
  local: 'dev_Wallets',
  preview: 'preview_Wallets',
  staging: 'dev_Wallets',
  production: 'Wallets',
};

let SERVER_URL = SERVER_URL_STAGING;
let ws_url = WS_URL_STAGING;

export const IS_PRODUCTION = NEXT_PUBLIC_VERCEL_STAGE === 'production';
if (IS_PRODUCTION) {
  SERVER_URL = SERVER_URL_PRODUCTION;
  ws_url = WS_URL_PRODUCTION;
}
if (IS_STAGING) {
  SERVER_URL = SERVER_URL_STAGING;
  ws_url = WS_URL_STAGING;
}
if (IS_PREVIEW) {
  SERVER_URL = SERVER_URL_PREVIEW;
  ws_url = WS_URL_PREVIEW;
}

export const WS_URL = ws_url;

const getPort = (layer) => {
  if (layer === 'CORE') return PORT_LOCAL_CORE;
  if (layer === 'AUTH') return PORT_LOCAL_AUTH;
  if (layer === 'JOB_QUEUE') return PORT_LOCAL_JOB_QUEUE;
  if (layer === 'FILE_UPLOAD') return PORT_LOCAL_FILE_UPLOAD;
  if (layer === 'IMPAIRMENT_ENGINE') return PORT_LOCAL_IMPAIRMENT_ENGINE;
  if (layer === 'CONFIGURATION_SERVICE') return PORT_LOCAL_CONFIGURATION_SERVICE;
  if (layer === 'ORG_SERVICE') return PORT_LOCAL_ORG_SERVICE;
  if (layer === 'TABS_SERVICE') return PORT_LOCAL_ORG_SERVICE;
  if (layer === 'GL_INTEGRATION_SERVICE') return PORT_LOCAL_GL_INTEGRATION_SERVICE;
  if (layer === 'GPT') return PORT_LOCAL_GPT;
  if (layer === 'ANALYTICS') return PORT_LOCAL_ANALYTICS;
  if (layer === 'RAINCARDS') return PORT_LOCAL_RAINCARDS;
  if (layer === 'NFT_SERVICE') return PORT_LOCAL_NFT_SERVICE;
  if (layer === 'BILLING_SERVICE') return PORT_LOCAL_BILLING_SERVICE;
};

const getLocalServerUrl = (port, services) => `http://localhost:${port}/dev${services}`;

export const SERVER_URL_AUTH = IS_LOCAL ? getLocalServerUrl(getPort('AUTH'), AUTH) : SERVER_URL + AUTH;
export const SERVER_URL_CORE = IS_LOCAL ? getLocalServerUrl(getPort('CORE'), CORE) : SERVER_URL + CORE;
export const SERVER_URL_ANALYTICS = IS_LOCAL
  ? getLocalServerUrl(getPort('ANALYTICS'), ANALYTICS)
  : SERVER_URL + ANALYTICS;
export const SERVER_URL_GPT = IS_LOCAL ? getLocalServerUrl(getPort('GPT'), GPT) : SERVER_URL + GPT;
export const SERVER_URL_JOB_QUEUE = IS_LOCAL
  ? getLocalServerUrl(getPort('JOB_QUEUE'), JOB_QUEUE)
  : SERVER_URL + JOB_QUEUE;
export const SERVER_URL_GL_INTEGRATION = IS_LOCAL
  ? getLocalServerUrl(getPort('GL_INTEGRATION_SERVICE'), GL_INTEGRATION_SERVICE)
  : SERVER_URL + GL_INTEGRATION_SERVICE;
export const SERVER_URL_IMPAIRMENT_ENGINE = IS_LOCAL
  ? getLocalServerUrl(getPort('IMPAIRMENT_ENGINE'), IMPAIRMENT_ENGINE)
  : SERVER_URL + IMPAIRMENT_ENGINE;
export const SERVER_URL_FILE_UPLOAD = IS_LOCAL
  ? getLocalServerUrl(getPort('FILE_UPLOAD'), FILE_UPLOAD)
  : SERVER_URL + FILE_UPLOAD;
export const SERVER_URL_CONFIGURATION_SERVICE = IS_LOCAL
  ? getLocalServerUrl(getPort('CONFIGURATION_SERVICE'), CONFIGURATION_SERVICE)
  : SERVER_URL + CONFIGURATION_SERVICE;
export const SERVER_URL_ORG_SERVICE = IS_LOCAL
  ? getLocalServerUrl(getPort('ORG_SERVICE'), ORG_SERVICE)
  : SERVER_URL + ORG_SERVICE;
export const SERVER_URL_RAINCARDS_SERVICE = IS_LOCAL
  ? getLocalServerUrl(getPort('RAINCARDS'), RAINCARDS_SERVICE)
  : SERVER_URL + RAINCARDS_SERVICE;
export const SERVER_URL_TABS_SERVICE = IS_LOCAL
  ? getLocalServerUrl(getPort('TABS_SERVICE'), TABS_SERVICE)
  : SERVER_URL + TABS_SERVICE;
export const SERVER_URL_LOOP_SERVICE = IS_LOCAL
  ? getLocalServerUrl(getPort('LOOP_SERVICE'), LOOP_SERVICE)
  : SERVER_URL + LOOP_SERVICE;
export const SERVER_URL_NFT_SERVICE = IS_LOCAL
  ? getLocalServerUrl(getPort('NFT_SERVICE'), NFT_SERVICE)
  : SERVER_URL + NFT_SERVICE;

export const SERVER_URL_BILLING_SERVICE = IS_LOCAL
  ? getLocalServerUrl(getPort('BILLING_SERVICE'), BILLING_SERVICE)
  : SERVER_URL + BILLING_SERVICE;

export const ALGOLIA_TRANSACTION_INDEX = ALGOLIA_TRANSACTION_MAP[NEXT_PUBLIC_VERCEL_STAGE];
export const ALGOLIA_JOURNAL_ENTRIES_INDEX = ALGOLIA_JOURNAL_ENTRIES_MAP[NEXT_PUBLIC_VERCEL_STAGE];
export const ALGOLIA_JOURNAL_ENTRY_TEMPLATES_INDEX = ALGOLIA_JOURNAL_ENTRY_TEMPLATES_MAP[NEXT_PUBLIC_VERCEL_STAGE];
export const ALGOLIA_ACCOUNT_POSTING_RULESET_INDEX = ALGOLIA_ACCOUNT_POSTING_RULESET_MAP[NEXT_PUBLIC_VERCEL_STAGE];
export const ALGOLIA_WALLETS_INDEX = ALGOLIA_WALLETS_MAP[NEXT_PUBLIC_VERCEL_STAGE];
