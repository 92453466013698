export const ASSETS = [
  { abbreviation: 'BTC', name: 'Bitcoin' },
  { abbreviation: 'USDC', name: 'USD Coin' },
  { abbreviation: 'DAI', name: 'Dai' },
  { abbreviation: 'TKX', name: 'Tokenize Xchange' },
  { abbreviation: 'LDO', name: 'Lido' },
  { abbreviation: 'ETH', name: 'Ethereum' },
  { abbreviation: 'SAFE', name: 'SafeCoin' },
  { abbreviation: 'AAVE', name: 'Aave' },
  { abbreviation: 'FEI', name: 'Fei' },
  { abbreviation: 'TRIBE', name: 'Tribe' },
  { abbreviation: 'POOL', name: 'PoolTogether' },
  { abbreviation: 'FWB', name: 'Friends With Benefits' },
  { abbreviation: 'SUSD', name: 'sUSD' },
  { abbreviation: 'ENS', name: 'Ethereum Name Service' },
  { abbreviation: 'WETH', name: 'Wrapped Ethereum' },
  { abbreviation: 'GRT', name: 'The Graph' },
  { abbreviation: 'COVAL', name: 'Circuits of Value' },
  { abbreviation: 'BUSD', name: 'Binance USD' },
  { abbreviation: 'USDT', name: 'Tether' },
  { abbreviation: 'RAD', name: 'Radicle' },
  { abbreviation: 'GTC', name: 'Gitcoin' },
  { abbreviation: 'GALA', name: 'Gala' },
  { abbreviation: 'AUSDC', name: 'Algorand USD Coin' },
  { abbreviation: 'APE', name: 'ApeCoin' },
  { abbreviation: 'SOL', name: 'Solana' },
  { abbreviation: 'DUST', name: 'DUST' },
  { abbreviation: 'LTC', name: 'Litecoin' },
];

export function getAssetsInfo(query: string): { name: string; abbreviation: string } {
  const coin = ASSETS.find((c) => c.abbreviation === query || c.name === query);

  if (coin) {
    return coin;
  } else {
    // If no match is found, return an object with the same attributes
    return { name: query, abbreviation: query };
  }
}
