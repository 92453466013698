import { ReactNode } from 'react';

export interface PageHeaderProps {
  title: string;
  description?: ReactNode;
  actions?: ReactNode;
}

export enum PageHeaderDataCy {
  Title = 'pageHeader__title_',
  Description = 'pageHeader__description_',
}
