import { useQuery } from '@tanstack/react-query';
import { getBalanceSheet } from 'services/http/core';
import { useSession } from '../useSession';
import { QUERY_KEY, useOrgBasedQueryKey } from '../useInvalidateQuery';

export const useBalanceSheet = (legalEntityIds?: string[], accountingPeriodIds?: string[], tagId?: string) => {
  const { organizationId } = useSession();

  const getKey = useOrgBasedQueryKey();

  return useQuery(
    getKey(QUERY_KEY.BALANCE_SHEET, { accountingPeriodIds, legalEntityIds, tagId }),
    async () => {
      const response = await getBalanceSheet({
        organizationId,
        legalEntityIds,
        accountingPeriodIds,
        tagId,
      });
      const { balanceSheet } = response.data;
      return balanceSheet;
    },
    {
      enabled: !!organizationId,
    },
  );
};
