import { createGlobalStyle } from 'styled-components';
import styled from 'styled-components';

export const SelectedTable = styled.div`
  position: fixed;
  left: 55%;
  bottom: 0%;
  transform: translate(-50%, -50%);
  background-color: white;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 555px;
  height: 65px;
  border-radius: 0.75rem;
  color: black;
  box-sizing: content-box;
  font-size: 1.25rem;
  line-height: 1.75rem;
  border: 1px solid #e8e8e8;
  button {
    background-color: #f2f2f21f;
    padding: 0.5rem 1rem;
    margin-right: 0.5rem;
    border: 2px solid rgba(232, 232, 232, 0.06);
    border-radius: 0.375rem;
  }
`;
const StyledTable = createGlobalStyle`

  .table-btn-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    min-width: 1220px;
    margin: 12px 0px 24px
  }
  table {
    width: 100%;
    min-width: 1220px;
    border-collapse: collapse;
    display: table-auto;
    max-height: 100vh;
    overflow-y: scroll;
  }
  tbody {
    max-height: 100vh;
    overflow-y: scroll;
  }
  th {
    text-align: left;
    padding: 12px;
    border-bottom: 2px solid #e0e0e0;
    font-weight: 500;
    background-color: rgb(233 233 233 / 58%);
    position: relative;
    color: #11111199;
    white-space: nowrap;
    img {
      display: inline;
      margin-left: 8px;
    }
  }
  td {
    font-weight: 400;
    padding: 12px;
    background-color: #ffffff;
    color: rgba(17,17,17, 0.8);
    border-bottom: 1px solid rgb(17 17 17 / 6%);
    height: 70px;
  }
  tbody tr:last-child td {
    border: none !important;
  }
  td:first-child {
    position: relative;
  }
  td:first-child:before {
    content: '';
    display: none;
    position: absolute;
    top: 0;
    width: 4px;
    height: 100%;
    left: 0;
    background-color: #4D4DFF;
  }
  td svg {
    font-size: 20px;
  }
  tr:hover {
    cursor: pointer;
  }
  tr:hover > td {
    transition: 0.3s all ease-in-out;
    background-color: #F8F8F8;
    color: rgba(17,17,17, 1);
  }
  tr:hover .viewBtn {
    display: flex !important;
  }

  tbody tr:first-child {
    border-bottom: 1px solid #e5e7eb;
  }
  tbody tr:last-child {
    border-bottom: 1px solid #e5e7eb;
  }
  p {
    margin-bottom: 0px;
  }
  .resizer {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 5px;
    background: rgba(0, 0, 0, 0.5);
    cursor: col-resize;
    user-select: none;
    touch-action: none;
  }

  .resizer.isResizing {
      background: blue;
      opacity: 1;
  }

  .sticky-table {
    position: sticky !important;
    left: 0;
    top: 0;
    z-index: 1;
  }
  @media (hover: hover) {
      .resizer {
          opacity: 0;
      }

      *:hover>.resizer {
          opacity: 1;
      }
  }
`;

export default StyledTable;
