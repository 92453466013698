import { useMutation, useInfiniteQuery } from '@tanstack/react-query';
import { removeEmpty } from '../../components/templates/utils';
import { getAccountingPeriods, closeAccountingPeriod } from 'services';
import { useSession } from '../useSession';
import { QUERY_KEY, useOrgBasedQueryKey } from '../useInvalidateQuery';

export interface UseAccountingPeriodsPayload {
  startDate?: Date;
  endDate?: Date;
  status?: 'Open' | 'Closed';
  pageSize?: number;
  sort?: number;
  page?: number;
}

export interface GetAccountingPeriodsPayload extends UseAccountingPeriodsPayload {
  organizationId: string;
}

const MINIMUM_START_DATE = new Date('12/01/2020');

export const useAccountingPeriods = (payload?: UseAccountingPeriodsPayload) => {
  const { startDate: sd, endDate: ed, status, pageSize, sort } = payload || {};

  const { organizationId } = useSession();
  const getKey = useOrgBasedQueryKey();

  const startDate = sd || MINIMUM_START_DATE; // minimum start dec 2021
  const endDate = ed || new Date();

  return useInfiniteQuery(
    getKey(QUERY_KEY.ACCOUNTING_PERIODS, { status, sort }),
    async ({ pageParam = 0 }) => {
      const queryFilter = removeEmpty({
        startDate,
        endDate,
        organizationId,
        status,
        page: pageParam,
        sort: sort ?? -1,
        pageSize: pageSize || 25,
      });
      const response = await getAccountingPeriods(queryFilter);
      return response.data;
    },
    {
      enabled: !!organizationId,
      staleTime: 1000 * 60 * 5,
      cacheTime: 1000 * 60 * 5,
    },
  );
};

export type closeAccountingPeriodPayload = {
  accountingPeriodId: string;
  organizationId: string;
};

export const useCloseAccountingPeriod = () =>
  useMutation((data: closeAccountingPeriodPayload) => closeAccountingPeriod(data));
