import { DESCRIPTIVE_LOADER_STATES } from 'ui';

export enum CLOSE_ACCOUNTING_PERIOD_PROCESS_STATE {
  REVIEW = 'FORM',
  SET_REVALUATION_CONFIG = 'SET_REVALUATION_CONFIG',
  UPDATING_REVALUATION_CONFIG = 'UPDATING_REVALUATION_CONFIG',
  COULD_NOT_UPDATE_REVALUATION_CONFIG = 'COULD_NOT_UPDATE_REVALUATION_CONFIG',
  REVALUATING = 'REVALUATING',
  COULD_NOT_REVALUATE = 'COULD_NOT_REVALUATE',
  CLOSING_PERIOD = 'CLOSING_PERIOD',
  COULD_NOT_CLOSE_PERIOD = 'COULD_NOT_CLOSE_PERIOD',
  SYNC_ACCOUNTING_APPS = 'SYNC_ACCOUNTING_APPS',
  CREATING_SYNC_BG_JOB_FOR_QUICKBOOKS = 'CREATING_SYNC_BG_JOB_FOR_QUICKBOOKS',
  SYNCING_QUICKBOOKS = 'SYNCING_QUICKBOOKS',
  QUICKBOOKS_SYNC_SUCCESSFUL = 'QUICKBOOKS_SYNC_SUCCESSFUL',
  QUICKBOOKS_SYNC_FAILED = 'QUICKBOOKS_SYNC_FAILED',
  CREATING_SYNC_BG_JOB_FOR_XERO = 'CREATING_SYNC_BG_JOB_FOR_XERO',
  SYNCING_XERO = 'SYNCING_XERO',
  XERO_SYNC_SUCCESSFUL = 'XERO_SYNC_SUCCESSFUL',
  XERO_SYNC_FAILED = 'XERO_SYNC_FAILED',
  REVALUATE_HEDGEY = 'REVALUATE_HEDGEY',
  CREATE_ENTRIES_FOR_HEDGEY = 'CREATING_ENTRIES_FOR_HEDGEY',
}

export const getSyncJournalEntriesProcessStateDescriptorProps = (
  processState: CLOSE_ACCOUNTING_PERIOD_PROCESS_STATE,
  error?: string,
) => {
  switch (processState) {
    case CLOSE_ACCOUNTING_PERIOD_PROCESS_STATE.REVALUATING:
      return {
        title: 'Revaluating',
        status: DESCRIPTIVE_LOADER_STATES.LOADING,
      };
    case CLOSE_ACCOUNTING_PERIOD_PROCESS_STATE.CLOSING_PERIOD:
      return {
        title: 'Closing period',
        status: DESCRIPTIVE_LOADER_STATES.LOADING,
      };
    case CLOSE_ACCOUNTING_PERIOD_PROCESS_STATE.CREATING_SYNC_BG_JOB_FOR_QUICKBOOKS:
      return {
        title: 'Creating sync job for quickbooks',
        status: DESCRIPTIVE_LOADER_STATES.LOADING,
      };
    case CLOSE_ACCOUNTING_PERIOD_PROCESS_STATE.QUICKBOOKS_SYNC_SUCCESSFUL:
      return {
        title: 'Success',
        description: 'Quickbooks Sync successful',
        status: DESCRIPTIVE_LOADER_STATES.LOADING,
      };
    case CLOSE_ACCOUNTING_PERIOD_PROCESS_STATE.QUICKBOOKS_SYNC_FAILED:
      return {
        title: 'Failure',
        description: error ?? 'Could not perform sync operation for xero',
        status: DESCRIPTIVE_LOADER_STATES.ERROR,
      };
    case CLOSE_ACCOUNTING_PERIOD_PROCESS_STATE.CREATING_SYNC_BG_JOB_FOR_XERO:
      return {
        title: 'Creating sync job for xero',
        status: DESCRIPTIVE_LOADER_STATES.LOADING,
      };
    case CLOSE_ACCOUNTING_PERIOD_PROCESS_STATE.XERO_SYNC_SUCCESSFUL:
      return {
        title: 'Success',
        description: 'Xero Sync successful',
        status: DESCRIPTIVE_LOADER_STATES.LOADING,
      };
    case CLOSE_ACCOUNTING_PERIOD_PROCESS_STATE.XERO_SYNC_FAILED:
      return {
        title: 'Failure',
        description: error ?? 'Could not perform sync operation for xero',
        status: DESCRIPTIVE_LOADER_STATES.ERROR,
      };

    case CLOSE_ACCOUNTING_PERIOD_PROCESS_STATE.CREATE_ENTRIES_FOR_HEDGEY:
      return {
        title: 'Creating entries for Hedgey vesting',
        status: DESCRIPTIVE_LOADER_STATES.LOADING,
      };

    default:
      return {
        title: 'unreachable',
        description: 'unreachable',
        status: DESCRIPTIVE_LOADER_STATES.LOADING,
      };
  }
};
