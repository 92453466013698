import React from 'react';
import { currencyImg } from '../../../utils';
import { Avatar, capitalizeFirstLetter, classNames, getLeftIcon } from 'ui';
import { MdChevronRight } from 'react-icons/md';
import { RULE_CONFIGURATOR_TYPE } from '../types';
import { useRuleConfigurator } from '../rule-configurator-context';
import { ConditionSelectorRowProps, NormalizedConditionSelectorRowData } from './types';
import { getAssetsInfo } from '../../../../constants/assetsNames';

export const ConditionSelectorRow = <T,>({
  isSelected,
  onClick,
  rowData,
  isMenuTrigger,
  asChild,
  children,
  showOnSelectorTypes = [RULE_CONFIGURATOR_TYPE.IMPAIRMENT, RULE_CONFIGURATOR_TYPE.ACCOUNT_POSTING],
  isTransactionClassification = false,
}: ConditionSelectorRowProps<T>) => {
  const { state } = useRuleConfigurator();

  if (!showOnSelectorTypes.includes(state.configuratorType)) {
    // console.log('Condition configurator row hidden for this configurator type', state.type, facts);
    return null;
  }

  if (asChild) return children;

  const normalizedData: NormalizedConditionSelectorRowData = rowData as NormalizedConditionSelectorRowData;

  return (
    <button
      onClick={onClick}
      className={`w-full flex flex-col gap-2 bg-white duration-300 cursor-pointer hover:bg-indigo-50 py-1.5 px-2 mb-1 rounded-md ${
        isSelected && 'bg-indigo-50'
      }`}
    >
      <span className={classNames('w-full flex items-center justify-between', isSelected && 'bg-indigo-50')}>
        {typeof normalizedData?.label === 'string' ? (
          <span className='flex items-center gap-2'>
            {!!normalizedData?.symbol?.length && !isTransactionClassification && (
              <Avatar size='large' src={currencyImg(normalizedData?.symbol)} alt={normalizedData?.symbol} />
            )}
            {isTransactionClassification && (
              <div className='[&>svg]:w-6 [&>svg]:h-6'>{getLeftIcon(normalizedData?.label)}</div>
            )}
            <div>
              <p className='block truncate overflow-hidden'>
                {isTransactionClassification
                  ? capitalizeFirstLetter(normalizedData?.label.toLowerCase())
                  : getAssetsInfo(normalizedData?.label).name}
              </p>

              {!!normalizedData?.symbol?.length && (
                <p className=' block text-left truncate overflow-hidden text-zinc-500'>
                  {getAssetsInfo(normalizedData?.label).abbreviation}
                </p>
              )}
            </div>
          </span>
        ) : (
          <>{normalizedData?.label}</>
        )}
        {isMenuTrigger && <MdChevronRight className='w-6 h-6 text-zinc-500' />}
      </span>
    </button>
  );
};
