import { useQuery } from '@tanstack/react-query';
import { getQuickBooksRedirectUrl, getXeroRedirectUrl } from 'services/http';
import { GLType, GlAuthRedirectPayload } from 'services/http/types';
import { useSession } from '../useSession';
import { QUERY_KEY, useOrgBasedQueryKey } from '../useInvalidateQuery';

const getGlAuths = async (type, payload) => {
  if (type === GLType.QUICKBOOKS) {
    const response = await getQuickBooksRedirectUrl(payload);
    return response.data;
  } else {
    const response = await getXeroRedirectUrl(payload);
    return response.data;
  }
};

export const useGLAuthConnect = (payload: GlAuthRedirectPayload, type: GLType, enabled) => {
  const { organizationId } = useSession();

  const getKey = useOrgBasedQueryKey();
  const queryKey = type === GLType.QUICKBOOKS ? QUERY_KEY.QUICKBOOKS_REDIRECT_URL : QUERY_KEY.XERO_REDIRECT_URL;
  return useQuery(
    getKey(queryKey, { payload }),
    async () => {
      const response = await getGlAuths(type, payload);
      return response;
    },
    {
      enabled: enabled && !!organizationId,
      staleTime: 30000,
      cacheTime: 30000,
      refetchOnWindowFocus: false,
    },
  );
};
