import { CheckboxListItem, DropdownGroup, DropdownGroupBody, DropdownGroupTitle } from 'ui';
import { currencyImg } from '../../../utils';
import { getAssetsInfo } from '../../../../constants/assetsNames';

export const ChainsFilterRowGroup = ({ state, onSingleChange }) => {
  if (!state) return null;
  return (
    <DropdownGroup>
      <DropdownGroupTitle title='Chains' />
      <DropdownGroupBody>
        {state.ids.length > 1 ? (
          state.ids
            .map((_id) => state.entities[_id])
            .map((chain) => (
              <CheckboxListItem
                key={chain._id}
                label={getAssetsInfo(chain.value?.toLowerCase()).name}
                bottomText={getAssetsInfo(chain.value?.toLowerCase()).abbreviation}
                checked={chain.selected}
                onCheckedChange={(selected: boolean) => onSingleChange({ ...chain, selected })}
                enableAvatar
                src={currencyImg(chain.value?.toLowerCase())}
              />
            ))
        ) : (
          <p className='text-zinc-900 text-sm px-2'>No chains to show.</p>
        )}
      </DropdownGroupBody>
    </DropdownGroup>
  );
};
