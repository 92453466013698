import { useEffect, useState } from 'react';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import {
  AddOrgPayload,
  DeleteOrganizationMemberType,
  PatchOrganizationMemberRoleType,
  addOrganization,
  deleteOrganizationMember,
  getAccountOrganizations,
  getAllMembershipsByMemberId,
  getMembershipById,
  getOrganizationMembers,
  inviteOrganizationMember,
  patchOrganizationById,
  patchOrganizationMemberRole,
} from 'services/http/core';
import { useSession } from '../useSession';
import { QUERY_KEY, useInvalidateQuery, useOrgBasedQueryKey } from '../useInvalidateQuery';
import { Organization } from 'schemas';

export const useAccountOrganizations = () => {
  const { userId, token } = useSession();

  const getKey = useOrgBasedQueryKey();

  return useInfiniteQuery(
    getKey(QUERY_KEY.ORGANIZATIONS, { userId }),
    async ({ pageParam = 0 }) => {
      const response = await getAccountOrganizations(
        {
          page: pageParam,
          sort: -1,
          userId,
        },
        {
          headers: {
            Authorization: token,
          },
        },
      );
      return response?.data?.organizations ?? [];
    },
    {
      enabled: !!userId,
      getPreviousPageParam: (firstPage) => firstPage.prevPage ?? undefined,
      getNextPageParam: (lastPage) => lastPage.nextPage,
    },
  );
};

export const useGetOrganizationMembers = (organizationId) => {
  const getKey = useOrgBasedQueryKey();

  return useInfiniteQuery(
    getKey(QUERY_KEY.ORGANIZATION_MEMBERS),
    async ({ pageParam = 0 }) => {
      const response = await getOrganizationMembers({
        page: pageParam,
        sort: -1,
        organizationId,
      });
      return response?.data?.organizations ?? [];
    },
    {
      enabled: !!organizationId,
      getPreviousPageParam: (firstPage) => firstPage.prevPage ?? undefined,
      getNextPageParam: (lastPage) => lastPage.nextPage,
    },
  );
};

export const useUpdateOrganizationMember = () =>
  useMutation((data: PatchOrganizationMemberRoleType) => patchOrganizationMemberRole(data));

export const useInviteOrganizationMember = () => useMutation((data) => inviteOrganizationMember(data));

export const useDeleteOrganizationMember = () =>
  useMutation((data: DeleteOrganizationMemberType) => deleteOrganizationMember(data));

export const useUserFirstOrganization = () => {
  const { data: organizations } = useAccountOrganizations();
  const [_mergedOrganizations, setOrganizations] = useState<any[]>([]);
  useEffect(() => {
    let mergedOrganizations: unknown[] = [];
    if (organizations?.pages?.length) {
      organizations.pages.forEach((page) => {
        mergedOrganizations = [...mergedOrganizations, ...page];
      });
    }
    setOrganizations(mergedOrganizations);
  }, [organizations]);
  return _mergedOrganizations[0];
};

export const useGetAllUserMemberships = (options = { enabled: true }) => {
  const { userId } = useSession();
  const getKey = useOrgBasedQueryKey();

  return useQuery(
    getKey(QUERY_KEY.MEMBERSHIPS),
    async () => {
      const response = await getAllMembershipsByMemberId(userId);
      return response.data.memberships;
    },
    { enabled: options.enabled && !!userId, staleTime: 5 * 60 * 1000 },
  );
};

export const useAddOrganization = () => {
  const { userId } = useSession();

  return useMutation((data: AddOrgPayload) => addOrganization({ userId, ...data }));
};

export const useAddDefaultOrganizationIfNeeded = () => {
  console.log('useAddDefaultOrganizationIfNeeded called');
  const { data: memberships, isLoading } = useGetAllUserMemberships();
  const { organizationId, userId } = useSession();

  const { mutateAsync: addOrganization, isLoading: isAddingOrganization } = useAddOrganization();
  const { invalidateMemberships } = useInvalidateQuery();

  useEffect(() => {
    const asyncCall = async () => {
      await addOrganization({
        organizationName: `org-${userId || Date.now()}`,
        webAddress: `org-${userId || Date.now()}`,
      });
      invalidateMemberships();
    };

    if (!isAddingOrganization && !isLoading && !organizationId && memberships?.length === 0) {
      console.log('Trying to create new organization for user');
      asyncCall();
    }
  }, [organizationId, memberships, isAddingOrganization, isLoading]);
};

export const useGetMembershipById = (membershipId: string) => {
  const getKey = useOrgBasedQueryKey();

  return useQuery(
    getKey(QUERY_KEY.MEMBERSHIPS, { membershipId }),
    async () => {
      const response = await getMembershipById(membershipId);
      return response.data.membership;
    },
    { enabled: !!membershipId },
  );
};

export type PatchOrganizationByIdPayload = {
  organizationId: string;
  update: Partial<Omit<Organization, '_id'>>;
};

export const usePatchOrganizationById = () =>
  useMutation(({ organizationId, update }: PatchOrganizationByIdPayload) =>
    patchOrganizationById(organizationId, update),
  );
