import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { useSsr } from 'usehooks-ts';
import {
  getAccountPostingRules,
  createAccountPostingRule,
  patchAccountPostingRule,
  deleteAccountPostingRule,
  computeRuleMetrics,
  getRuleMetrics,
  deleteOneTimeRuleMetrics,
} from 'services/http/core';
import { useRouter } from 'next/router';
import { useSession } from '../useSession';
import { QUERY_KEY, useOrgBasedQueryKey } from '../useInvalidateQuery';
import { BG_JOB_STATE } from '../../components/job-progress/types';

export const useAccountPostingRules = () => {
  const { userId } = useSession();
  const location = useRouter();
  const { isBrowser } = useSsr();
  const accountPostingRuleSetId = isBrowser ? location.pathname.split('/')[4] : '';

  const getKey = useOrgBasedQueryKey();

  return useInfiniteQuery(
    getKey(QUERY_KEY.ACCOUNT_POSTING_RULESETS),
    async ({ pageParam = 0 }) => {
      const response = await getAccountPostingRules({
        userId,
        accountPostingRuleSetId,
        page: pageParam,
        sort: -1,
      });
      return response.data;
    },
    {
      getPreviousPageParam: (firstPage) => firstPage.prevPage ?? undefined,
      getNextPageParam: (lastPage) => lastPage.nextPage,
    },
  );
};

export const useGetRuleMetricsQuery = ({ ruleId, bgJobState }) => {
  const { organizationId } = useSession();
  const getKey = useOrgBasedQueryKey();

  return useInfiniteQuery(
    getKey(QUERY_KEY.ACCOUNT_POSTING_RULE_METRICS, { ruleId }),
    ({ pageParam = 0 }) =>
      getRuleMetrics({ ruleId, organizationId, pageParam }).then((response) => response.data.metrics),
    {
      refetchInterval: 2500,
      enabled: !!ruleId && bgJobState === BG_JOB_STATE.PROCESSING && !!organizationId,
      getPreviousPageParam: (firstPage) => firstPage.prevPage ?? undefined,
      getNextPageParam: (lastPage) => lastPage.nextPage,
    },
  );
};

export const useCreateAccountPostingRule = () => useMutation((data) => createAccountPostingRule(data));

export const usePatchAccountPostingRule = () => useMutation((data) => patchAccountPostingRule(data));

export const useDeleteAccountPostingRule = () => useMutation((data) => deleteAccountPostingRule(data));

export const useComputeRuleMetrics = () => useMutation((data: any) => computeRuleMetrics(data));

export const useDeleteOneTimeRuleMetrics = () => useMutation((data: any) => deleteOneTimeRuleMetrics(data));
