import { CheckboxProps } from './types';
import { classNames } from '../utils';

export const Checkbox = ({ label, isSelected = false, showCheckbox = true, onChange }: CheckboxProps) => (
  <div
    className='flex items-center w-full py-1'
    onClick={() => {
      if (onChange) onChange(!isSelected);
    }}
  >
    {showCheckbox && (
      <span>
        <input
          type='checkbox'
          checked={isSelected}
          className={classNames(
            'form-checkbox mr-2 mb-[2px] text-{#6565F1] w-[20px] h-[20px] border rounded',
            isSelected ? 'border-[#8A8AF4]' : 'border-zinc-200',
          )}
          onChange={(e) => {
            if (onChange) onChange(e.target.checked);
          }}
        />
      </span>
    )}
    <div className='inline-block flex-grow select-none'>{label}</div>
  </div>
);
