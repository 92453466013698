import toast from 'react-hot-toast';
import { useUpdateJobConfigurationMutation } from './http';
import { useInvalidateQuery } from './useInvalidateQuery';
import { deriveError } from '../components/templates/utils';

export const useScheduleJobCallbacks = () => {
  const { mutateAsync: updateJobConfiguration } = useUpdateJobConfigurationMutation();
  // const { mutateAsync: deleteJobConfiguration } = useDeleteJobConfigurationMutation();

  const { invalidateJobProgress, invalidateAccountPostingRulesetConfigs, invalidateOrganizationImpairmentConfig } =
    useInvalidateQuery();
  const onPause = async (jobConfigurationId: string) => {
    const toastId = toast.loading('Pausing schedule');
    try {
      await updateJobConfiguration({ jobConfigurationId, update: { isPaused: true } });
      toast.success('Schedule is paused', { id: toastId });
      await invalidateJobProgress();
      await invalidateAccountPostingRulesetConfigs();
      await invalidateOrganizationImpairmentConfig();
    } catch (error) {
      toast.error(deriveError(error), { id: toastId });
    }
  };
  const onResume = async (jobConfigurationId: string) => {
    const toastId = toast.loading('Resuming schedule');
    try {
      await updateJobConfiguration({ jobConfigurationId, update: { isPaused: false } });
      toast.success('Schedule resumed', { id: toastId });
      await invalidateJobProgress();
      await invalidateAccountPostingRulesetConfigs();
      await invalidateOrganizationImpairmentConfig();
    } catch (error) {
      toast.error(deriveError(error), { id: toastId });
    }
  };
  return { onPause, onResume };
};
