import { SERVER_URL_RAINCARDS_SERVICE } from '../config';
import axios from 'axios';

export const getRaincardsConfiguration = async () => {
  return axios.get(`${SERVER_URL_RAINCARDS_SERVICE}/configuration`);
};

export const createRaincardsConfiguration = async (body) => {
  return axios.post(`${SERVER_URL_RAINCARDS_SERVICE}/configuration`, body);
};

export const importRaincardSources = async (body) => {
  // console.log({ body });
  return axios.post(`${SERVER_URL_RAINCARDS_SERVICE}/cards?userId=${body.userId}`, body);
};

export const getRaincardSources = async (params) => {
  const { legalEntityId, populateFields } = params;
  return axios.get(`${SERVER_URL_RAINCARDS_SERVICE}/sources`, {
    params: {
      legalEntityId,
      populateFields,
    },
  });
};

export const importRaincardTransactions = async (body) => {
  return axios.post(`${SERVER_URL_RAINCARDS_SERVICE}/transactions?userId=${body.userId}`);
};

export const getRaincardJobHistory = async () => {
  return axios.get(`${SERVER_URL_RAINCARDS_SERVICE}/history`);
};

export const deleteRaincardsConfiguration = async (body) => {
  return axios.delete(`${SERVER_URL_RAINCARDS_SERVICE}/configuration`, {
    data: body,
  });
};

export const getRaincardsRuleMeta = async () => {
  return axios.get(`${SERVER_URL_RAINCARDS_SERVICE}/meta`);
};
