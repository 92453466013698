import { useEffect, useMemo, useRef, useState } from 'react';
import {
  Button,
  CheckboxListItem,
  Dropdown,
  DropdownBody,
  DropdownContent,
  DropdownTrigger,
  DropdownFooter,
  DropdownGroupBody,
  DropdownHeader,
  DropdownTitleWithToggle,
  DropdownSearchInput,
  SelectableCard,
  classNames,
  getFilterDropdownSectionDataCy,
  capitalizeFirstLetter,
  DropdownSubHeader,
} from 'ui';
import { FILTER_TYPE, FilterDropdownRowProps, FilterState } from '../types';
import { MdArrowDropDown } from 'react-icons/md';
import { Wallet } from 'services/http/response.types';
import { useFiltered } from '../useFiltered';
import { useSearch } from '../useSearch';
import { walletSearchFilterFn } from './walletSearchFilterFn';
import { extraFiltersForWalletsFn } from './extraFiltersForWalletsFn';
import { shortText } from '../../../rules/utils';
import { currencyImg } from '../../../utils';
import { IoMdCloseCircle } from 'react-icons/io';

const DEFAULT_TRIGGER_LABEL = 'Wallets';

export const WalletsFilterDropdown = ({
  state,
  onSelectAll,
  onClearAll,
  onSingleRowSelectChange,
  onOnlySingleRowSelect,
  'data-cy': dataCy,
}: FilterDropdownRowProps<Wallet>) => {
  const [open, setOpen] = useState(false);
  const triggerRef = useRef<HTMLButtonElement>(null);

  const [triggerLabel, setTriggerLabel] = useState(DEFAULT_TRIGGER_LABEL);
  const [isHoveringClearAllTrailingIcon, setIsHoveringClearAllTrailingIcon] = useState(false);
  const [isHoveringTrigger, setIsHoveringTrigger] = useState(false);

  const { searched, setSearched, results } = useSearch<Wallet>(state, walletSearchFilterFn);
  const [extraFilters, setExtraFilters] = useState({
    isInternal: false,
    isExternal: false,
    isTagged: false,
  });

  const filteredWallets = useFiltered<FilterState<Wallet>>(results, extraFilters, extraFiltersForWalletsFn);

  useEffect(() => {
    setTriggerLabel(() => {
      const selectedIds = state.ids.filter((id) => state.entities[id].selected);
      if (selectedIds.length === 0) return DEFAULT_TRIGGER_LABEL;
      if (selectedIds.length === 1) return `${shortText(state.entities[selectedIds[0]].address)}`;
      return `${shortText(state.entities[selectedIds[0]].address)} +${selectedIds.length - 1}`;
    });
  }, [state]);

  const walletsFilterDataCy = useMemo(() => `${dataCy}_${FILTER_TYPE.WALLET}`, [dataCy]);

  return (
    <Dropdown data-cy={walletsFilterDataCy} open={open} onOpenChange={setOpen}>
      <DropdownTrigger ref={triggerRef}>
        <span onMouseEnter={() => setIsHoveringTrigger(true)} onMouseLeave={() => setIsHoveringTrigger(false)}>
          <Button
            data-cy={getFilterDropdownSectionDataCy(walletsFilterDataCy).trigger}
            isFocused={open}
            isLoading={state.isLoading}
            onClick={() => setOpen((prev) => !prev)}
            label={
              triggerLabel === DEFAULT_TRIGGER_LABEL ? (
                triggerLabel
              ) : (
                <span className='text-indigo-600'>{capitalizeFirstLetter(triggerLabel?.toLowerCase())}</span>
              )
            }
            className={classNames(
              'duration-100 py-0',

              triggerLabel !== DEFAULT_TRIGGER_LABEL &&
                isHoveringTrigger &&
                !isHoveringClearAllTrailingIcon &&
                'shadow',
              triggerLabel !== DEFAULT_TRIGGER_LABEL && 'bg-indigo-50 border-indigo-600',
            )}
            labelContainerClassname={classNames(
              'font-medium ',
              triggerLabel !== DEFAULT_TRIGGER_LABEL && 'text-indigo-600',
            )}
            emphasis='medium'
            trailingIconContainerClassname={classNames(
              triggerLabel !== DEFAULT_TRIGGER_LABEL && 'mr-0 p-2 duration-100 bg-indigo-50 text-blue-500 rounded-r-lg',
              triggerLabel !== DEFAULT_TRIGGER_LABEL && isHoveringTrigger && 'bg-indigo-50',
            )}
            trailingIcon={
              <div className='flex items-center'>
                {triggerLabel !== DEFAULT_TRIGGER_LABEL && (
                  <span
                    onMouseEnter={() => setIsHoveringClearAllTrailingIcon(true)}
                    onMouseLeave={() => setIsHoveringClearAllTrailingIcon(false)}
                    onClick={(e) => {
                      e.stopPropagation();
                      onClearAll();
                    }}
                  >
                    <IoMdCloseCircle className='w-6 h-6 text-zinc-500' />
                  </span>
                )}

                <MdArrowDropDown className={classNames('duration-300 w-6 h-6 text-black', open && 'rotate-180')} />
              </div>
            }
          />
        </span>
      </DropdownTrigger>

      <DropdownContent triggerRef={triggerRef}>
        <DropdownHeader title='Wallets' data-cy={getFilterDropdownSectionDataCy(walletsFilterDataCy).header} />
        <DropdownBody data-cy={getFilterDropdownSectionDataCy(walletsFilterDataCy).body}>
          <DropdownSubHeader>
            <div className='' data-cy={getFilterDropdownSectionDataCy(walletsFilterDataCy).subHeader}>
              <div className=' mb-2'>
                <DropdownSearchInput
                  value={searched}
                  onChange={(e) => setSearched(e.target.value)}
                  data-cy={getFilterDropdownSectionDataCy(walletsFilterDataCy).searchInput}
                />
              </div>
              <div className='grid grid-cols-3 gap-2'>
                <SelectableCard
                  className='px-2 py-0 h-10'
                  label='Internal'
                  selected={extraFilters.isInternal}
                  onClick={() => setExtraFilters((prev) => ({ ...prev, isInternal: !prev.isInternal }))}
                />
                <SelectableCard
                  className='px-2 py-0 h-10'
                  label='External'
                  selected={extraFilters.isExternal}
                  onClick={() => setExtraFilters((prev) => ({ ...prev, isExternal: !prev.isExternal }))}
                />
                <SelectableCard
                  className='px-2 py-0 h-10'
                  label='Tagged'
                  selected={extraFilters.isTagged}
                  onClick={() => setExtraFilters((prev) => ({ ...prev, isTagged: !prev.isTagged }))}
                />
              </div>
              {filteredWallets?.ids.length !== 0 && (
                <DropdownTitleWithToggle
                  className='px-0 pl-2'
                  title='Select all'
                  checked={state.ids.length === state.ids.filter((id) => state.entities[id].selected).length}
                  onChange={(checked: boolean) => (checked ? onSelectAll() : onClearAll())}
                />
              )}
            </div>
          </DropdownSubHeader>
          <div>
            {filteredWallets?.ids.length === 0 && (
              <div className='flex items-center justify-center'>
                {filteredWallets?.ids.length === 0 && <div className='p-4 text-center'>No wallets to show</div>}
              </div>
            )}
            {filteredWallets?.ids.length !== 0 && (
              <DropdownGroupBody>
                {filteredWallets?.ids.map((rowId) => (
                  <CheckboxListItem
                    key={rowId}
                    label={
                      filteredWallets.entities[rowId].name ??
                      '' + ' ' + shortText(filteredWallets.entities[rowId].address)
                    }
                    bottomText={filteredWallets.entities[rowId].walletType}
                    enableAvatar
                    src={currencyImg(filteredWallets.entities[rowId].chain)?.toLowerCase()}
                    checked={filteredWallets.entities[rowId].selected}
                    onCheckedChange={(selected: boolean) => onSingleRowSelectChange(rowId, selected)}
                    selectOnlyFn={() => onOnlySingleRowSelect(rowId)}
                  />
                ))}

                {
                  // also show rows which might not be included in search results but are currently selected as they affect the results
                  state.ids
                    .filter((id) => !filteredWallets?.ids.includes(id) && state.entities[id].selected)
                    .map((rowId) => (
                      <CheckboxListItem
                        key={rowId}
                        label={state.entities[rowId].name ?? '' + ' ' + shortText(state.entities[rowId].address)}
                        bottomText={state.entities[rowId].walletType}
                        src={currencyImg(state.entities[rowId].chain)?.toLowerCase()}
                        checked={state.entities[rowId].selected}
                        enableAvatar
                        onCheckedChange={(selected: boolean) => onSingleRowSelectChange(rowId, selected)}
                        selectOnlyFn={() => onOnlySingleRowSelect(rowId)}
                      />
                    ))
                }
              </DropdownGroupBody>
            )}
          </div>
        </DropdownBody>
        <DropdownFooter>
          <Button
            data-cy={getFilterDropdownSectionDataCy(walletsFilterDataCy).clearAllButton}
            onClick={filteredWallets?.ids.length !== 0 ? onClearAll : undefined}
            emphasis='low'
            label='Clear'
            labelContainerClassname='text-zinc-500'
          />
        </DropdownFooter>
      </DropdownContent>
    </Dropdown>
  );
};
