import React, { useContext } from 'react';
import { SidebarSection } from '../../../atoms';
import { Table } from '../../../dashboard';
import { useJournalEntries, useTabState } from '../../../../hooks';
import { SidebarGlobalContext } from '../../../../context';
import { journalEntryTableHeader } from '../../../../constants';
import { mergeJournalEntries } from '../../../templates/utils';

const JournalEntriesTable = ({ transactionId }) => {
  const { updateTabSidebarState } = useTabState();
  const { openSidebar } = useContext(SidebarGlobalContext);
  const { data: jeData, isLoading: jeLoading } = useJournalEntries({
    transactionIds: [transactionId],
    status: ['POSTED', 'SUBMITTED', 'REVERSED', 'DRAFT'],
  });
  const mergedJournalEntries = mergeJournalEntries(jeData);

  return (
    <SidebarSection loading={jeLoading}>
      <Table
        className='px-0'
        tableHeader={journalEntryTableHeader.filter((col) => col.key !== '_id')}
        hideCheckboxes
        isSidePanel
        onRowClick={(row) => {
          updateTabSidebarState({ secondRouteUnStack: {} });
          openSidebar('journals', { id: row.original._id, primaryOrSecondary: 'secondary' });
        }}
        isLoading={jeLoading}
        tableData={{
          Data:
            mergedJournalEntries?.map((je) => {
              let totalCredits = 0;
              let totalDebits = 0;
              je.journalEntryLineIds?.forEach((jel) => {
                if (jel.creditOrDebit === 'CREDIT') {
                  totalCredits += parseFloat(jel.amount.$numberDecimal);
                } else {
                  totalDebits += parseFloat(jel.amount.$numberDecimal);
                }
              });
              return {
                journalSequenceNumber: je.journalSequenceNumber,
                status: je.status,
                credits: totalCredits,
                debits: totalDebits,
                createdAt: je.createdAt,
                createdBy: je?.originatedBy,
                synced: je?.synced,
                _id: je._id,
              };
            }) || [],
        }}
        enableColumnPinning={false}
      />
    </SidebarSection>
  );
};

export default JournalEntriesTable;
