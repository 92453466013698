import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { journalEntryLines } from '../../constants/tableHeaders';
import { Sidebar, SidebarTopBar, SidebarBody, SidebarFooter } from '../atoms/Sidebar';
import {
  useLegalEntities,
  useAddJournalEntry,
  useAccountingPeriods,
  useAlgoliaKey,
  useLedgerAccount,
  usePatchJournalEntry,
  useDeleteJournalEntry,
  useTransactions,
} from '../../hooks/http';
import { Table } from '../dashboard';
import {
  deriveError,
  getDisplayedAccountingPeriods,
  getDisplayedLegalEntities,
  getDisplayedTransactions,
  getIdFromPopulatedInstance,
  mergeAccountingPeriods,
  mergeLedgerAccounts,
  mergeLegalEntities,
  mergeTransactions,
} from '../templates/utils';
import { searchAlgolia } from '../../controllers/algolia';
import { CreateJournalEntryLinePanel } from './CreateJournalEntryLinePanel';

import { SidebarSectionHeader } from '../atoms/Sidebar/SidebarBody/SidebarSectionHeader';
import { SidebarSection } from '../atoms/Sidebar/SidebarBody/SidebarSection';
import SidebarHeader from '../atoms/Sidebar/SidebarHeader/SidebarHeader';
import { InputLabel, TextareaInput, Button, SelectableCard, InputWithExtras, SingleSelectMenu } from 'ui';
import { toast } from 'react-hot-toast';
import { JournalEntryLineDB, Tag } from 'schemas';
import { getUpdatedTabSidebarObject } from '../utils';
import { useSession } from '../../hooks/useSession';
import { JOURNAL_ENTRY_STATUS, JournalEntry } from 'services/http/response.types';
import { useInvalidateQuery, useTabState } from '../../hooks';

/**
 * Small note on this component we cant test it locally unless we provide hardcoded wallet id and transaction id
 *
 * we need to somehow sync our local db with algolia for free to test. I dont know if thats possible
 *
 * One work around is to query transactions directly and show them in the dropdown but in prod default to the algolia search
 */

interface CreateJournalEntryProps {
  onCancel?: () => void;
  onBack?: () => void;
  onClose?: () => void;
  title?: string;
  selectedJournalEntry?: any;
  shouldNestCreateJournalLinePanel?: boolean;
  defaultTransaction?: {
    transactionId: string;
    walletId: string;
  };
  'data-cy'?: string;
}

const CreateJournalEntry = ({
  onCancel = () => undefined,
  title = 'Create journal entry',
  selectedJournalEntry,
  onBack,
  onClose = () => undefined,
  shouldNestCreateJournalLinePanel = false,
  defaultTransaction,
  'data-cy': dataCy = 'createJournalEntry',
}: CreateJournalEntryProps) => {
  const { activeTab, secondRouteUnStack, sidebarState, updateTabSidebarState } = useTabState();

  const [formData, setFormData] = useState<JournalEntry | any>(
    selectedJournalEntry || {
      status: secondRouteUnStack?.journalEntryFormData?.status ?? JOURNAL_ENTRY_STATUS.POSTED,
      memo: secondRouteUnStack?.journalEntryFormData?.memo ?? '',
      externalReference: secondRouteUnStack?.journalEntryFormData?.externalReference ?? '',
      tags: secondRouteUnStack?.journalEntryFormData?.tags ?? [],
      accountingDate: secondRouteUnStack?.journalEntryFormData?.accountingDate ?? '',
      accountingPeriodId: secondRouteUnStack?.journalEntryFormData?.accountingPeriodId ?? '',
      accountPostingRuleId: secondRouteUnStack?.journalEntryFormData?.accountPostingRuleId ?? '',
      legalEntityId: secondRouteUnStack?.journalEntryFormData?.legalEntityId ?? undefined,
      transactionId: secondRouteUnStack?.journalEntryFormData?.transactionId ?? defaultTransaction?.transactionId ?? '',
      walletId: secondRouteUnStack?.journalEntryFormData?.walletId ?? defaultTransaction?.walletId ?? '',
    },
  );

  const { mutate: createJournalEntryByParams } = useAddJournalEntry();
  const { mutate: editJournalEntryByParams } = usePatchJournalEntry();

  const { organizationId, userId } = useSession();

  const [showCreateJournalEntryLinePanel, setShowCreateJournalEntryLinePanel] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const { data: legalEntities } = useLegalEntities();
  const mergedLegalEntities = mergeLegalEntities(legalEntities);
  const displayedLegalEntities = getDisplayedLegalEntities(mergedLegalEntities);
  const { data: accountingPeriods, isLoading: isLoadingAccountingPeriods } = useAccountingPeriods({ status: 'Open' });
  const mergedAccountingPeriods = mergeAccountingPeriods(accountingPeriods);
  const displayedAccountingPeriods = getDisplayedAccountingPeriods(mergedAccountingPeriods);
  const key = useAlgoliaKey();
  const [displayedTransactions, setDisplayedTransactions] = useState<
    {
      value: string;
      label: string;
      bottomText?: string;
      icon?: string | ReactNode;
    }[]
  >([]);
  const { mutateAsync: deleteJournalEntry } = useDeleteJournalEntry();
  const { data: _transactions }: any = useTransactions({
    showTransactionsWithoutJournalEntries: false,
  });

  const { invalidateJournalEntries } = useInvalidateQuery();

  const handleClickCreateJournalEntry = async () => {
    const tags = [...new Set(lines.map((line) => line.tags).flatMap((tag) => (tag as unknown as Tag)?._id))].filter(
      (item) => item,
    );
    const getLineTagids = (line) => {
      if (line.tags && line.tags.length) return line.tags.map((tag) => tag._id);
      else return [];
    };
    const payload = {
      organizationId,
      journalEntryModelInput: {
        journalEntry: {
          idempotencyKey: `${new Date().getTime()} ${userId} Manual JE`,
          accountingDate: new Date(), // this should get overidden in the backend anyway
          legalEntityId: formData.legalEntityId,
          organizationId,
          originatedBy: 'user',
          status: formData.status,
          userId,
          transactionId: formData?.transactionId,
          walletId: formData?.walletId,
          accountingPeriodId: formData.accountingPeriodId,
          tags,
        },
        journalEntryLines: lines.map((line, i) => ({
          ...line,
          tags: getLineTagids(line),
          idempotencyKey: `${new Date().getTime()} Manual JEL ${i}`,
        })),
      },
    };
    const editPayload = {
      organizationId,
      journalEntryModel: {
        journalEntry: {
          _id: null,
          idempotencyKey: `${new Date().getTime()} ${userId} Manual JE`,
          accountingDate: new Date(),
          legalEntityId: formData.legalEntityId,
          organizationId,
          originatedBy: 'user',
          status: formData.status,
          userId,
          transactionId: formData?.transactionId,
          walletId: formData?.walletId,
          accountingPeriodId: formData.accountingPeriodId,
          tags,
        },
        journalEntryLines: lines.map((line, i) => ({
          ...line,
          tags: getLineTagids(line),
          idempotencyKey: `${new Date().getTime()} Manual JEL ${i}`,
        })),
      },
    };

    const onSuccess = async () => {
      invalidateJournalEntries();
      if (selectedJournalEntry) {
        // invalidate query for getJournalEntryById
        invalidateJournalEntries({ journalEntryId: selectedJournalEntry._id });
      }
      toast.success(`Your journal entry has been ${selectedJournalEntry ? 'edited' : 'created'}.`);
      setIsSaving(false);
      onCancel();
    };
    const onError = (error) => {
      toast.error(deriveError(error));
      setIsSaving(false);
    };
    try {
      setIsSaving(true);
      if (selectedJournalEntry) {
        editPayload.journalEntryModel.journalEntry._id = selectedJournalEntry._id;
        await editJournalEntryByParams(editPayload as any, {
          onSuccess,
          onError,
        });
      } else {
        await createJournalEntryByParams(payload as any, {
          onSuccess,
          onError,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickDeleteJorunal = async () => {
    if (
      selectedJournalEntry?.status?.toLowerCase() === 'draft' ||
      selectedJournalEntry?.status?.toLowerCase() === 'unposted'
    ) {
      setIsDeleting(true);
      await deleteJournalEntry(
        { journalEntryId: selectedJournalEntry?._id, organizationId: selectedJournalEntry?.organizationId },
        {
          onSuccess: () => {
            toast.success('Journal entry deleted successfully');

            onClose();
            invalidateJournalEntries();
          },
          onError: (error) => {
            toast.error(deriveError(error));
          },
        },
      );
    }
  };

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') return;
    // on page load populate some data for displayed transactions
    if (selectedJournalEntry) return;
    if (!key) return;
    if (key && displayedTransactions.length) return;

    searchAlgolia('', key).then((res) => {
      if (res) {
        const transactionsFromSearch = res.transactions.map((transaction) => ({
          value: transaction.objectID,

          label: transaction.operationalTxnId,
        }));

        setDisplayedTransactions((prev) => [...prev, ...transactionsFromSearch]);
      }
    });
  }, [key]);

  const [lines, setLines] = useState<(JournalEntryLineDB & { draftId?: string })[]>([]);
  const [selectedLine, setSelectedLine] = useState<(JournalEntryLineDB & { draftId?: string }) | null>(null);
  const { data: ledgerAccounts } = useLedgerAccount({
    pageSize: 1000,
  });
  const mergedLedgeAccounts = mergeLedgerAccounts(ledgerAccounts);
  const displayedLinesForTable =
    lines.map((line) => ({
      ...line,
      ledgerAccount: mergedLedgeAccounts.find((account) => account._id === line.ledgerAccountId)?.ledgerAccountName,
      company: mergedLegalEntities.find((entity) => entity._id === line.legalEntityId)?.entityName,
      legalEntityId: {
        _id: line.legalEntityId,
        entityName: mergedLegalEntities.find((entity) => entity._id === line.legalEntityId)?.entityName,
      },
    })) || [];

  useEffect(() => {
    console.log('test selectedJournalEntry');

    if (selectedJournalEntry) {
      const updatedFormObject = getUpdatedTabSidebarObject({
        sidebarState,
        keys: ['secondRouteUnStack', 'journalEntryFormData'],
        ...selectedJournalEntry,
      });

      updateTabSidebarState(updatedFormObject, true);

      setFormData({ ...selectedJournalEntry });

      setLines(
        selectedJournalEntry.journalEntryLineIds?.map((line) => ({
          ...line,
          amount: parseFloat(line?.amount?.$numberDecimal),
          company: line?.legalEntityId?.entityName,
          ledgerAccount: line?.ledgerAccountId?.ledgerAccountName,
          draftId: new Date().getTime() + line._id,
          ledgerAccountId: line?.ledgerAccountId?._id,
          legalEntityId: line?.legalEntityId?._id,
        })) || [],
      );

      setDisplayedTransactions([
        {
          value: selectedJournalEntry.transactionId?._id,
          label: selectedJournalEntry.transactionId?.sequenceNumber,
        },
      ]);
    }
  }, [selectedJournalEntry]);
  const mergedTransactions = mergeTransactions(_transactions ?? []);

  useEffect(() => {
    if (!displayedTransactions.length) {
      setDisplayedTransactions(getDisplayedTransactions(mergedTransactions));
    }
  }, [_transactions]);

  useEffect(() => {
    if (defaultTransaction && !formData?.transactionId && !formData?.walletId) {
      const updatedFormObject = getUpdatedTabSidebarObject({
        sidebarState,
        keys: ['secondRouteUnStack', 'journalEntryFormData'],
        transactionId: defaultTransaction.transactionId,
        walletId: defaultTransaction.walletId,
      });

      updateTabSidebarState(updatedFormObject, true);

      setFormData({
        ...formData,
        transactionId: defaultTransaction.transactionId,
        walletId: defaultTransaction.walletId,
      });
    }
  }, [defaultTransaction]);

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      onCancel && onCancel();
    }
  };
  const memoizedTabUpdate = useMemo(() => activeTab?.id, [activeTab?.id]);

  useEffect(() => {
    setFormData({
      status: secondRouteUnStack?.journalEntryFormData?.status ?? JOURNAL_ENTRY_STATUS.POSTED,
      memo: secondRouteUnStack?.journalEntryFormData?.memo ?? '',
      externalReference: secondRouteUnStack?.journalEntryFormData?.externalReference ?? '',
      tags: secondRouteUnStack?.journalEntryFormData?.tags ?? [],
      accountingDate: secondRouteUnStack?.journalEntryFormData?.accountingDate ?? '',
      accountingPeriodId: secondRouteUnStack?.journalEntryFormData?.accountingPeriodId ?? '',
      accountPostingRuleId: secondRouteUnStack?.journalEntryFormData?.accountPostingRuleId ?? '',
      legalEntityId: secondRouteUnStack?.journalEntryFormData?.legalEntityId ?? undefined,
      transactionId: secondRouteUnStack?.journalEntryFormData?.transactionId ?? defaultTransaction?.transactionId ?? '',
      walletId: secondRouteUnStack?.journalEntryFormData?.walletId ?? defaultTransaction?.walletId ?? '',
    });
  }, [memoizedTabUpdate]);

  return (
    <>
      <div className={showCreateJournalEntryLinePanel && !shouldNestCreateJournalLinePanel ? 'hidden' : ''}>
        <Sidebar data-cy={dataCy}>
          <SidebarTopBar onBack={onBack} onClose={handleClose} />
          <SidebarHeader data-cy={dataCy} title={title} />
          <SidebarBody>
            <SidebarSectionHeader title='Details' />
            <SidebarSection numberOfColumns={1}>
              <div>
                <InputLabel heading='Legal entity' />
                <SingleSelectMenu
                  fullWidth
                  isOnSidepanel
                  data-cy={`${dataCy}__legalEntity`}
                  options={displayedLegalEntities}
                  placeholder='Select legal entity'
                  value={displayedLegalEntities.find(
                    (entity) => entity.value === getIdFromPopulatedInstance(formData.legalEntityId),
                  )}
                  onChange={(data) => {
                    const updatedFormObject = getUpdatedTabSidebarObject({
                      sidebarState,
                      keys: ['secondRouteUnStack', 'journalEntryFormData'],
                      legalEntityId: data.value,
                    });
                    updateTabSidebarState(updatedFormObject, true);
                    setFormData({ ...formData, legalEntityId: data.value });
                  }}
                  onClearValue={() => {
                    const updatedFormObject = getUpdatedTabSidebarObject({
                      sidebarState,
                      keys: ['secondRouteUnStack', 'journalEntryFormData'],
                      legalEntityId: undefined,
                    });
                    updateTabSidebarState(updatedFormObject, true);
                    setFormData({ ...formData, legalEntityId: undefined });
                  }}
                />
              </div>
              <div>
                <InputLabel heading='Operational transaction' />

                <SingleSelectMenu
                  fullWidth
                  isOnSidepanel
                  enableSearch
                  enableAvatar
                  enableBottomText
                  data-cy={`${dataCy}__operationalTransaction`}
                  options={displayedTransactions}
                  placeholder='Select operational transaction'
                  onInputChange={(value) => {
                    if (process.env.NODE_ENV === 'development') return;
                    searchAlgolia(value, key).then((res) => {
                      if (res) {
                        const transactions = res.transactions.map((transaction) => ({
                          value: transaction.objectID,
                          label: transaction.operationalTxnId,
                        }));
                        setDisplayedTransactions([
                          ...transactions,
                          {
                            value: selectedJournalEntry?.transactionId?._id,
                            label: selectedJournalEntry?.transactionId?.sequenceNumber,
                          },
                        ]);
                      }
                    });
                  }}
                  value={displayedTransactions.find(
                    (transaction) => transaction.value === getIdFromPopulatedInstance(formData.transactionId),
                  )}
                  onChange={(data) => {
                    const updatedFormObject = getUpdatedTabSidebarObject({
                      sidebarState,
                      keys: ['secondRouteUnStack', 'journalEntryFormData'],
                      transactionId: data.value,
                      walletId: mergedTransactions.find((transaction) => transaction._id === data.value)?.walletId?._id,
                    });
                    updateTabSidebarState(updatedFormObject, true);
                    setFormData({
                      ...formData,
                      transactionId: data.value,
                      walletId: mergedTransactions.find((transaction) => transaction._id === data.value)?.walletId?._id,
                    });
                  }}
                  onClearValue={() => {
                    const updatedFormObject = getUpdatedTabSidebarObject({
                      sidebarState,
                      keys: ['secondRouteUnStack', 'journalEntryFormData'],
                      transactionId: undefined,
                      walletId: undefined,
                    });
                    updateTabSidebarState(updatedFormObject, true);
                    setFormData({ ...formData, transactionId: undefined, walletId: undefined });
                  }}
                />
              </div>
              <div>
                <div className='grid grid-cols-2 gap-4'>
                  <SelectableCard
                    data-cy={`${dataCy}__autoPost`}
                    label='Auto Post'
                    onClick={() => {
                      const updatedFormObject = getUpdatedTabSidebarObject({
                        sidebarState,
                        keys: ['secondRouteUnStack', 'journalEntryFormData'],
                        status: JOURNAL_ENTRY_STATUS.POSTED,
                      });
                      updateTabSidebarState(updatedFormObject, true);

                      setFormData({ ...formData, status: JOURNAL_ENTRY_STATUS.POSTED });
                    }}
                    selected={formData.status === JOURNAL_ENTRY_STATUS.POSTED}
                  />
                  <SelectableCard
                    label='Draft'
                    data-cy={`${dataCy}__draft`}
                    onClick={() => {
                      const updatedFormObject = getUpdatedTabSidebarObject({
                        sidebarState,
                        keys: ['secondRouteUnStack', 'journalEntryFormData'],
                        status: JOURNAL_ENTRY_STATUS.DRAFT,
                      });
                      updateTabSidebarState(updatedFormObject, true);

                      setFormData({ ...formData, status: JOURNAL_ENTRY_STATUS.DRAFT });
                    }}
                    selected={formData.status === JOURNAL_ENTRY_STATUS.DRAFT}
                  />
                </div>
              </div>
              <div>
                <InputLabel heading='External reference' />
                <InputWithExtras
                  data-cy={`${dataCy}__externalReference`}
                  onChange={(e) => {
                    const updatedFormObject = getUpdatedTabSidebarObject({
                      sidebarState,
                      keys: ['secondRouteUnStack', 'journalEntryFormData'],
                      externalReference: e.target.value,
                    });
                    updateTabSidebarState(updatedFormObject, true);

                    setFormData({ ...formData, externalReference: e.target.value });
                  }}
                  value={formData?.externalReference}
                  placeholder='Enter external reference'
                />
              </div>
              <div>
                <InputLabel heading='Accounting period' />
                <SingleSelectMenu
                  fullWidth
                  isOnSidepanel
                  data-cy={`${dataCy}__accountingPeriod`}
                  options={displayedAccountingPeriods}
                  placeholder='Select accounting period'
                  isLoading={isLoadingAccountingPeriods}
                  value={displayedAccountingPeriods.find(
                    (period) => period.value === getIdFromPopulatedInstance(formData.accountingPeriodId),
                  )}
                  onChange={(data) => {
                    const updatedFormObject = getUpdatedTabSidebarObject({
                      sidebarState,
                      keys: ['secondRouteUnStack', 'journalEntryFormData'],
                      accountingPeriodId: data.value,
                    });
                    updateTabSidebarState(updatedFormObject, true);

                    setFormData({ ...formData, accountingPeriodId: data.value });
                  }}
                  onClearValue={() => {
                    const updatedFormObject = getUpdatedTabSidebarObject({
                      sidebarState,
                      keys: ['secondRouteUnStack', 'journalEntryFormData'],
                      accountingPeriodId: undefined,
                    });
                    updateTabSidebarState(updatedFormObject, true);

                    setFormData({ ...formData, accountingPeriodId: undefined });
                  }}
                />
              </div>
              <div>
                <InputLabel heading='Memo' />
                <TextareaInput
                  data-cy={`${dataCy}__memo`}
                  onChange={(e) => {
                    const updatedFormObject = getUpdatedTabSidebarObject({
                      sidebarState,
                      keys: ['secondRouteUnStack', 'journalEntryFormData'],
                      memo: e.target.value,
                    });
                    updateTabSidebarState(updatedFormObject, true);
                    setFormData({ ...formData, memo: e.target.value });
                  }}
                  value={formData?.memo}
                />
              </div>
            </SidebarSection>
            <SidebarSectionHeader
              title='Journal lines'
              actions={[
                {
                  label: 'Add journal lines',
                  onClick: () => {
                    setShowCreateJournalEntryLinePanel(true);
                  },
                  variant: 'tertiary',
                  'data-cy': `${dataCy}__addJournalLinesButton`,
                },
              ]}
            />
            <SidebarSection>
              <Table
                tableData={{ Data: displayedLinesForTable }}
                tableHeader={journalEntryLines}
                onRowClick={(row) => {
                  const line = lines.find((line) => line.draftId === row.original.draftId);
                  if (!line) {
                    toast.error('Error opening journal entry line');
                    return;
                  }
                  setSelectedLine(line);
                  setShowCreateJournalEntryLinePanel(true);
                }}
                isSidePanel
                hideCheckboxes
                enableColumnPinning={false}
                emptyBtn={{
                  emptyMsg: 'No journal lines yet',
                }}
              />
            </SidebarSection>
          </SidebarBody>
          <SidebarFooter
            primaryBtn={
              <Button
                emphasis='high'
                data-cy={`${dataCy}__createJournalEntryButton`}
                label='Create journal entry'
                isLoading={isSaving}
                onClick={async () => {
                  try {
                    await handleClickCreateJournalEntry();
                  } catch (error) {
                    toast.error(deriveError(error));
                  }
                }}
              />
            }
            secondaryBtn={
              <Button
                label='Cancel'
                emphasis='medium'
                onClick={() => {
                  onCancel && onCancel();
                }}
              />
            }
            destructiveBtn={
              selectedJournalEntry && (
                <Button
                  data-cy={`${dataCy}__deleteJournalEntryButton`}
                  label='Delete'
                  emphasis='medium'
                  status='danger'
                  isLoading={isDeleting}
                  onClick={async () => {
                    await handleClickDeleteJorunal();
                  }}
                />
              )
            }
          />
        </Sidebar>
      </div>
      {showCreateJournalEntryLinePanel && (
        <CreateJournalEntryLinePanel
          setSelectedLine={setSelectedLine}
          selectedLine={selectedLine}
          setLines={setLines}
          onCancel={() => {
            setShowCreateJournalEntryLinePanel(false);
          }}
        />
      )}
    </>
  );
};

export default CreateJournalEntry;
