import React from 'react';
import { REVALUATION_MODE, useLedgerAccount } from '../../../../hooks/http';
import { HeadlessUIRadioGroup } from '../../../atoms/headlessUI/RadioGroup';
import { getDisplayedLedgerAccounts, mergeLedgerAccounts } from '../../../templates/utils';
import { SimpleMenu, InputLabel, LoaderIcon, LOADER_ICON_SIZE } from 'ui';

const RevaluateWithSeparateGainLossAccounts = ({ revaluationConfig, setRevaluationConfig }) => {
  const { data: ledgerAccounts, isLoading: isLoadingLedgerAccounts } = useLedgerAccount({
    pageSize: 1000,
  });
  const mergedLedgerAccounts = mergeLedgerAccounts(ledgerAccounts);
  const displayedLedgerAccounts = getDisplayedLedgerAccounts(mergedLedgerAccounts);

  return (
    <>
      <div className='mt-4 mb-6'>
        <InputLabel
          heading='Gain ledger account'
          description={'Choose separate account for gain'}
          tooltip={'An account to record gain'}
        />
        <SimpleMenu
          options={displayedLedgerAccounts}
          onChange={(option) =>
            setRevaluationConfig((prev) => ({ ...prev, tuple: { ...prev.tuple, gain: option.value } }))
          }
          selectedValue={displayedLedgerAccounts.find((item) => item.value === revaluationConfig.tuple?.gain)?.value}
          isLoading={isLoadingLedgerAccounts}
        />

        <div className='mt-4' />
        <InputLabel
          heading='Loss ledger account'
          description={'Choose separate account for loss'}
          tooltip={'An account to record loss'}
        />

        <SimpleMenu
          options={displayedLedgerAccounts}
          onChange={(option) =>
            setRevaluationConfig((prev) => ({ ...prev, tuple: { ...prev.tuple, loss: option.value } }))
          }
          selectedValue={displayedLedgerAccounts.find((item) => item.value === revaluationConfig.tuple?.loss)?.value}
          isLoading={isLoadingLedgerAccounts}
        />
      </div>
    </>
  );
};
const RevaluateWithSingleAccount = ({ revaluationConfig, setRevaluationConfig }) => {
  const { data: ledgerAccounts, isLoading: isLoadingLedgerAccounts } = useLedgerAccount({
    pageSize: 1000,
  });
  const mergedLedgerAccounts = mergeLedgerAccounts(ledgerAccounts);
  const displayedLedgerAccounts = getDisplayedLedgerAccounts(mergedLedgerAccounts);

  return (
    <div className='mt-4'>
      <InputLabel
        heading='Ledger account'
        description={'Choose account for both gain anad loss'}
        tooltip={'An account to record gain and loss'}
      />

      <SimpleMenu
        options={displayedLedgerAccounts}
        onChange={(option) => setRevaluationConfig((prev) => ({ ...prev, single: option.value }))}
        selectedValue={displayedLedgerAccounts.find((item) => item.value === revaluationConfig.single)?.value}
        isLoading={isLoadingLedgerAccounts}
      />
    </div>
  );
};

export const RevaluationDialogForm = ({ revaluationConfig, setRevaluationConfig, isLoading }) => {
  return (
    <div>
      {isLoading ? (
        <div className='flex justify-center items-center my-12'>
          <LoaderIcon size={LOADER_ICON_SIZE.LARGE} />
        </div>
      ) : (
        <HeadlessUIRadioGroup
          label={''}
          radioGroupOptions={[
            {
              label: 'Use separate accounts for unrealized gains and losses',
              value: REVALUATION_MODE.SEPARATE_GAIN_LOSS_ACCOUNTS,
            },
            {
              label: 'Combine unrealized gains and losses into a single account',
              value: REVALUATION_MODE.SINGLE_ACCOUNT,
            },
          ]}
          value={revaluationConfig.setting}
          setValue={(value) => setRevaluationConfig((prev) => ({ ...prev, setting: value }))}
        />
      )}
      {revaluationConfig.setting === REVALUATION_MODE.SEPARATE_GAIN_LOSS_ACCOUNTS && (
        <RevaluateWithSeparateGainLossAccounts
          revaluationConfig={revaluationConfig}
          setRevaluationConfig={setRevaluationConfig}
        />
      )}
      {revaluationConfig.setting === REVALUATION_MODE.SINGLE_ACCOUNT && (
        <RevaluateWithSingleAccount revaluationConfig={revaluationConfig} setRevaluationConfig={setRevaluationConfig} />
      )}
    </div>
  );
};
