import React from 'react';
import Select, {
  components,
  OptionProps,
  SingleValueProps,
  DropdownIndicatorProps,
  ClearIndicatorProps,
} from 'react-select';

import { SimpleMenuProps, SimpleMenuRowProps } from './types';
import { MdArrowDropDown, MdArrowDropUp, MdCancel, MdClose, MdDone } from 'react-icons/md';
import { classNames } from '../utils';
import useDimensions from 'react-cool-dimensions';
import { LOADER_ICON_SIZE, LoaderIcon } from '../loader-icon';
import { getAssetsInfo } from '../../../../apps/next/src/constants/assetsNames';

export const SimpleMenuRow = ({ label, icon, isSelected = false }: SimpleMenuRowProps) => {
  const { observe: rowRef, width: rowWidth } = useDimensions();
  const labelWidthHandler = () => {
    if (icon) return rowWidth - 56;
    else return rowWidth - 36;
  };
  return (
    <div className='flex items-center w-full' ref={rowRef}>
      {
        <span className='flex-grow flex items-center gap-2'>
          {icon && <span className='w-8 h-8'>{icon}</span>}
          {typeof label === 'string' ? (
            <span className='block'>
              <span className='inline-block truncate' style={{ width: labelWidthHandler() }}>
                {label}
              </span>
              {icon && (
                <p className='truncate w-full -mt-2 text-zinc-500'>
                  {getAssetsInfo((label as string) ?? '').abbreviation}
                </p>
              )}
            </span>
          ) : (
            <>{label}</>
          )}
        </span>
      }
    </div>
  );
};

const Option = (props: OptionProps<SimpleMenuRowProps>) => (
  <components.Option {...props}>
    <SimpleMenuRow icon={props.data.icon} label={props.data.label} isSelected={props.isSelected} />
  </components.Option>
);

const SingleValue = (props: SingleValueProps<SimpleMenuRowProps>) => {
  return (
    <components.SingleValue {...props}>
      {props?.data?.value ? (
        <div className='flex items-end gap-2'>
          {props.data.icon && <span className='w-5 h-5'>{props.data.icon}</span>}
          <div className='truncate w-full'>{props.data.label}</div>
        </div>
      ) : (
        <p className='text-zinc-400'>Select...</p>
      )}
    </components.SingleValue>
  );
};

const ClearIndicator = (props: ClearIndicatorProps<SimpleMenuRowProps>) => {
  return (
    <div
      ref={props.innerProps.ref}
      aria-hidden={props.innerProps['aria-hidden']}
      hidden={!props?.getValue()[0]?.value?.length}
      onMouseDown={(e) => {
        props.setValue({ label: '', value: '' }, 'deselect-option');
        e.preventDefault();
      }}
    ></div>
  );
};

const DropdownIndicator = (props: DropdownIndicatorProps<SimpleMenuRowProps>) => {
  const {
    selectProps: { menuIsOpen },
  } = props;

  return (
    <components.DropdownIndicator {...props}>
      <MdArrowDropDown
        className={classNames('duration-300 w-6 h-6 transition-transform', menuIsOpen && 'rotate-180')}
      />
    </components.DropdownIndicator>
  );
};

const LoadingIndicator = () => {
  return <LoaderIcon size={LOADER_ICON_SIZE.SMALL} />;
};

export const SimpleMenu = ({
  isError,
  options = [],
  disabled,
  selectedValue,
  isLoading,
  isClearable = true,
  'data-cy': dataCy,
  ...rest
}: SimpleMenuProps) => {
  return (
    <div className='w-full border border-1.5 border-zinc-300 rounded-lg'>
      <Select
        value={selectedValue?.length && options.find((option) => option.value === selectedValue)}
        {...rest}
        options={isLoading ? [] : options}
        isClearable={isClearable}
        components={{
          Option,
          SingleValue,
          DropdownIndicator,
          LoadingIndicator,
          ClearIndicator,
        }}
        backspaceRemovesValue={false}
        escapeClearsValue={false}
        unstyled
        isLoading={isLoading}
        isDisabled={disabled}
        id={`${dataCy}__select-container`}
        classNamePrefix={dataCy}
        classNames={{
          control: (state) =>
            classNames(
              '!min-h-[40px] h-fit border border-1.5 bg-white rounded-lg pl-3 pr-[14px] py-2 leading-[22px] ',
              isError && 'border-[#ff0000] ring-[#ff0000] hover:ring-[#ff0000]',
              !isError && state.menuIsOpen && 'ring-2 ring-[#0000ff] hover:ring-[#0000ff]',
              'hover:ring-2 hover:ring-[#808080]',
              state.isDisabled && 'opacity-50 pointer-none',
            ),
          menu: () =>
            'rounded-lg mt-2 bg-white border-0 shadow-[0px_10px_10px_-5px_rgba(0,_0,_0,_0.04),_0px_20px_25px_-5px_rgba(0,_0,_0,_0.10)]',
          menuList: () => 'border-0 rounded-lg p-1 grid grid-cols-1 gap-1',
          placeholder: () => 'text-zinc-400',
          loadingMessage: () => 'text-sm',
          option: ({ isFocused, isSelected }) =>
            classNames(
              'rounded bg-white py-[6px] px-[12px]',
              isFocused && 'bg-indigo-50',
              isSelected && 'bg-indigo-50',
            ),
        }}
      />
    </div>
  );
};
