import axios from 'axios';
import { SERVER_URL_CONFIGURATION_SERVICE, SERVER_URL_JOB_QUEUE } from '../../config';

export const getHedgeySource = (data) => {
  return axios.get(`${SERVER_URL_CONFIGURATION_SERVICE}/hedgey-source`, data);
};

export const createHedgeySource = (data) => {
  return axios.post(`${SERVER_URL_CONFIGURATION_SERVICE}/hedgey-source`, data);
};

export const updateHedgeySource = (data) => {
  return axios.patch(`${SERVER_URL_CONFIGURATION_SERVICE}/hedgey-source`, data);
};

export const deleteHedgeySource = (data) => {
  return axios.delete(`${SERVER_URL_CONFIGURATION_SERVICE}/hedgey-source`, {
    data,
  });
};

export const createHedgeyJob = (body) => {
  return axios.post(`${SERVER_URL_JOB_QUEUE}/hedgey-producer`, body);
};

export const getHedgeyJobHistories = (body) => {
  return axios.get(`${SERVER_URL_CONFIGURATION_SERVICE}/hedgey-job-histories`, body);
};

export const getHedgeyVestedBalances = (data) => {
  return axios.get(`${SERVER_URL_JOB_QUEUE}/hedgey-vested-balances?accountingPeriodId=${data.accountingPeriodId}`);
};

export const createEntriesForHedgey = (body) => {
  return axios.post(`${SERVER_URL_JOB_QUEUE}/hedgey-vested-balances`, body);
};
