import { toast } from 'react-hot-toast';
import { useCreateExchangeSyncBgJob, useCreateSyncJournalEntriesBgJob, useSession } from '../../../../hooks';
import { ExchangeSourceType, GLType } from 'services/http/types';
import { useBgJobTrackerState } from '../../../job-progress/useBgJobTrackerState';
import { BG_JOB_STATE } from '../../../job-progress/types';
import { JOB_NAME } from 'services';

export const useCreateSyncJournalEntriesJob = ({ realmId }: { realmId?: string }) => {
  const { userId, organizationId } = useSession();

  const [bgJobState, setBgJobState] = useBgJobTrackerState();

  const createExchangeSyncBgJob = useCreateExchangeSyncBgJob();
  const createSyncJournalEntriesBgJob = useCreateSyncJournalEntriesBgJob({
    realmId: realmId || '',
  });

  const createJob = async (referenceId: string, clientType: GLType | ExchangeSourceType) => {
    const isExchange = [
      ExchangeSourceType.KRAKEN,
      ExchangeSourceType.CIRCLE,
      ExchangeSourceType.COINBASE_PRIME,
    ].includes(clientType as ExchangeSourceType);
    if (!userId || !organizationId) return toast.error('Something wrong with the session.');
    if (isExchange) {
      try {
        setBgJobState(BG_JOB_STATE.BEING_CREATED);
        await createExchangeSyncBgJob(referenceId);
        setBgJobState(BG_JOB_STATE.QUEUED);
      } catch (error) {
        return setBgJobState(BG_JOB_STATE.COULD_NOT_BE_CREATED);
      }
      return { jobName: JOB_NAME.IMPORT_EXCHANGE_SOURCE_JOB, referenceId }; // return the job type so that it can be tracked
    } else if (clientType === GLType.QUICKBOOKS || clientType === GLType.XERO) {
      try {
        setBgJobState(BG_JOB_STATE.BEING_CREATED);
        await createSyncJournalEntriesBgJob(referenceId, clientType);
        setBgJobState(BG_JOB_STATE.QUEUED);
      } catch (error) {
        return setBgJobState(BG_JOB_STATE.COULD_NOT_BE_CREATED);
      }
      return { jobName: JOB_NAME.SYNC_JOURNAL_ENTRY_JOB, referenceId };
    } else {
      console.log('useCreateSyncJob: unsupported clientType');
    }
  };

  return { createJob, bgJobState, setBgJobState };
};
