import { AccountingPeriod, LegalEntity, Tag, Wallet } from 'services/http/response.types';
import { DatePickerCompProps } from '../../DatePickerComp.types';

export enum FILTER_TYPE {
  ACCOUNTING_TREATMENT = 'ACCOUNTING_TREATMENTS_FILTER',
  ACCOUNTING_PERIOD = 'ACCOUNTING_PERIODS_FILTER',
  ASSET = 'ASSETS_FILTER',
  CHAIN = 'CHAINS_FILTER',
  CLASSIFICATION = 'CLASSIFICATIONS_FILTER',
  DATE = 'DATES_FILTER',
  DIRECTION = 'DIRECTIONS_FILTER',
  IMPAIRED = 'IMPAIRED_FILTER',
  LEGAL_ENTITY = 'LEGAL_ENTITIES_FILTER',
  ORIGINATED_BY = 'ORIGINATED_BY_FILTER',
  STATUS = 'STATUS_FILTER',
  SPAM_TOKEN = 'SPAN_TOKEN',
  TAG = 'TAGS_FILTER',
  WALLET = 'WALLETS_FILTER',
  WALLET_TYPE = 'WALLET_TYPES_FILTER',
}

export type FilterRowState<T> = T & {
  _id: string;
  selected: boolean;
};

export interface FilterState<T> {
  entities: {
    [key: string]: FilterRowState<T>;
  };
  ids: string[];
  isLoading?: boolean;
}

export type GenericFilterFn<T> = (state: T, filter: any) => T;
export type FilterFn<T> = (state: FilterState<T>, searched: string) => string[];
export type OnFilterStateChangeFn<T> = (value: FilterState<T>) => void;
export type StringFilter = {
  value: string;
};
export interface FiltersProps extends Partial<DatePickerCompProps> {
  state: IAllFilters;
  helpers: HelpersForAllFilters;
  'data-cy'?: string;
}

export interface IAllFilters {
  [FILTER_TYPE.ACCOUNTING_PERIOD]?: FilterState<AccountingPeriod>;
  [FILTER_TYPE.ACCOUNTING_TREATMENT]?: FilterState<StringFilter>;
  [FILTER_TYPE.ASSET]?: FilterState<StringFilter>;
  [FILTER_TYPE.CHAIN]?: FilterState<StringFilter>;
  [FILTER_TYPE.CLASSIFICATION]?: FilterState<StringFilter>;
  [FILTER_TYPE.DIRECTION]?: FilterState<StringFilter>;
  [FILTER_TYPE.IMPAIRED]?: FilterState<StringFilter>;
  [FILTER_TYPE.LEGAL_ENTITY]?: FilterState<LegalEntity>;
  [FILTER_TYPE.ORIGINATED_BY]?: FilterState<StringFilter>;
  [FILTER_TYPE.TAG]?: FilterState<Tag>;
  [FILTER_TYPE.STATUS]?: FilterState<StringFilter>;
  [FILTER_TYPE.WALLET]?: FilterState<Wallet>;
}

export enum ACTION_TYPE {
  SET_ALL = 'setAll',
  SET_BY_ID = 'setById',
  SET_ONLY = 'setOnly',
  CLEAR = 'clear',
  DESELECT_ALL = 'deselectAll',
  SELECT_ALL = 'selectAll',
  SET_IS_LOADING = 'setIsLoading',
  SET_SELECTED_BY_ID = 'setSelectedById',
}

export type AllFiltersAction = {
  type: ACTION_TYPE;
  payload?: any;
  filterType: FILTER_TYPE;
};

export type OnSingleRowChangeFn<T> = (value: FilterRowState<T>) => void;
export interface AllFiltersDropdownProps {
  state: IAllFilters;
  onSingleRowChange: {
    [FILTER_TYPE.ACCOUNTING_PERIOD]?: OnSingleRowChangeFn<AccountingPeriod>;
    [FILTER_TYPE.ACCOUNTING_TREATMENT]?: OnSingleRowChangeFn<StringFilter>;
    [FILTER_TYPE.ASSET]?: OnSingleRowChangeFn<StringFilter>;
    [FILTER_TYPE.CLASSIFICATION]?: OnSingleRowChangeFn<StringFilter>;
    [FILTER_TYPE.CHAIN]?: OnSingleRowChangeFn<StringFilter>;
    [FILTER_TYPE.DIRECTION]?: OnSingleRowChangeFn<StringFilter>;
    [FILTER_TYPE.IMPAIRED]?: OnSingleRowChangeFn<StringFilter>;
    [FILTER_TYPE.LEGAL_ENTITY]?: OnSingleRowChangeFn<LegalEntity>;
    [FILTER_TYPE.ORIGINATED_BY]?: OnSingleRowChangeFn<StringFilter>;
    [FILTER_TYPE.STATUS]?: OnSingleRowChangeFn<StringFilter>;
    [FILTER_TYPE.TAG]?: OnSingleRowChangeFn<Tag>;
    [FILTER_TYPE.WALLET]?: OnSingleRowChangeFn<Wallet>;
    [FILTER_TYPE.WALLET_TYPE]?: OnSingleRowChangeFn<Wallet>;
    [FILTER_TYPE.SPAM_TOKEN]?: OnSingleRowChangeFn<StringFilter>;
  };
  onClearAll: () => void;
  'data-cy'?: string;
}
export type FilterValueTransformFn<T, R = any> = (value: T) => R;

export type FilterTransforms = {
  [key in FILTER_TYPE]?: FilterValueTransformFn<any>;
};

export type FilterSetAllFn<T> = (values: FilterRowState<T>[]) => void;
export type FilterSetByIdFn<T> = (values: FilterRowState<T>) => void;
export type FilterSetIsLoadingFn = (isLoading: boolean) => void;
export type FilterSetOnlyFn = (id: string) => void;
export type FilterSetSelectedFn = (id: string, selected: boolean) => void;
export type FilterSelectAllFn = () => void;
export type FilterDeselectAllFn = () => void;
export type FilterClearFn = () => void;
export type GetAllSelectedFn<T> = () => T[];
export type GetAllSelectedWithTransformFn<R> = () => R;

export type FilterHelpers<T, R = any> = {
  [ACTION_TYPE.SET_ALL]: FilterSetAllFn<T>;
  [ACTION_TYPE.SET_BY_ID]: FilterSetByIdFn<T>;
  [ACTION_TYPE.SET_IS_LOADING]: FilterSetIsLoadingFn;
  [ACTION_TYPE.SET_ONLY]: FilterSetOnlyFn;
  [ACTION_TYPE.SET_SELECTED_BY_ID]: FilterSetSelectedFn;
  [ACTION_TYPE.SELECT_ALL]: FilterSelectAllFn;
  [ACTION_TYPE.DESELECT_ALL]: FilterDeselectAllFn;
  [ACTION_TYPE.CLEAR]: FilterClearFn;
  getAllSelected: GetAllSelectedFn<T>;
  getAllSelectedWithTransform: GetAllSelectedWithTransformFn<R[]>;
};

export type HelpersForAllFilters = {
  [FILTER_TYPE.ACCOUNTING_PERIOD]?: FilterHelpers<AccountingPeriod>;
  [FILTER_TYPE.ACCOUNTING_TREATMENT]?: FilterHelpers<StringFilter>;
  [FILTER_TYPE.ASSET]?: FilterHelpers<StringFilter>;
  [FILTER_TYPE.CHAIN]?: FilterHelpers<StringFilter>;
  [FILTER_TYPE.CLASSIFICATION]?: FilterHelpers<StringFilter>;
  [FILTER_TYPE.DIRECTION]?: FilterHelpers<StringFilter>;
  [FILTER_TYPE.IMPAIRED]?: FilterHelpers<StringFilter>;
  [FILTER_TYPE.LEGAL_ENTITY]?: FilterHelpers<LegalEntity>;
  [FILTER_TYPE.ORIGINATED_BY]?: FilterHelpers<StringFilter>;
  [FILTER_TYPE.STATUS]?: FilterHelpers<StringFilter>;
  [FILTER_TYPE.TAG]?: FilterHelpers<Tag>;
  [FILTER_TYPE.WALLET]?: FilterHelpers<Wallet>;
};

export interface FilterDropdownRowProps<T> {
  state: FilterState<T>;
  onSelectAll: () => void;
  onClearAll: () => void;
  onSingleRowSelectChange: (rowId: string, selected: boolean) => void;
  onOnlySingleRowSelect: (rowId: string) => void;
  'data-cy'?: string;
}

export interface DateFilter {
  key: string;
  startDate: string | undefined;
  endDate: string | undefined;
}

export enum IMPAIRED_FILTER_ROW {
  TRUE = 'True',
  FALSE = 'False',
}

export enum STATUS_FILTER_ROW {
  DRAFT = 'Draft',
  POSTED = 'Posted',
  REVERSED = 'Reversed',
  UNPOSTED = 'Unposted',
}
export enum ORIGINATED_BY_FILTER_ROW {
  USER = 'user',
  SYSTEM = 'system',
}

export enum ACCOUNTING_TREATMENT_ROW {
  SHOW_TRANSACTIONS_WITHOUT_JOURNAL_ENTRIES = 'False', // true
  SHOW_TRANSACTIONS_WITH_JOURNAL_ENTRIES = 'True', // false
}

export enum SPAM_TOKEN_ROW {
  SHOW_SPAM = 'Show spam',
}

export enum CLASSIFICATION_ROW {
  SWAP = 'SWAP',
  DEPOSIT = 'DEPOSIT',
  WITHDRAWAL = 'WITHDRAWAL',
  BRIDGE = 'BRIDGE',
  INTERCOMPANY_TRANSFER = 'INTERCOMPANY TRANSFER',
  INTERNAL_TRANSFER = 'INTERNAL TRANSFER',
  FEE = 'FEE',
  MINTING = 'MINTING',
  SPAM = 'SPAM',
  NFT = 'NFT',
  UNKNOWN = 'UNKNOWN',
}

export type AllQueryFiltersType = {
  directions: string[];
  chains: string[];
  transactionFrom: string;
  transactionTo: string;
  accountingPeriodIds: string[];
  legalEntityIds: string[];
  assetTypes: string[];
  addresses: string[];
  walletIds: string[];
  sourceIds: string[];
  showTransactionsWithoutJournalEntries?: ACCOUNTING_TREATMENT_ROW;
  pageSize: number;
  includeSpam: SPAM_TOKEN_ROW;
  nameOrAddress: string;
  walletTypes: string[];
  tags: string[];
  startDate: string;
  endDate: string;
  originatedBy: string[];
  status: string[];
  transactionIds: string[];
  searchTerm: string;
  impaired: string[];
  tagId: string[];
  periodIds: string[];
  filteredBy: string;
  debouncedSearchTerm: string;
  classifications: string[];
};
