import { useEffect, useState } from 'react';
import { formatDollars, mergeJournalEntries, mergeLegalEntities } from '../../templates/utils';
import { dateConverter } from '../../utils';
import { DateProps } from '../../utils/types';
import { DisplayedJournalEntry, LineWithDecimalAmount, PopulatedJournalEntry } from './type';
import { AllQueryFiltersType, FILTER_TYPE, useFilters } from '../../filters';
import { JOURNAL_ENTRIES_PAGE_FILTERS, ORIGINATED_BY_FILTER_ROWS, STATUS_FILTER_ROWS } from '../../../constants';
import { useAccountingPeriods, useDateSelections, useLegalEntities, useMemoizedQuery } from '../../../hooks';

export const useDisplayedJEs = (jeIQData?: { pages: PopulatedJournalEntry[] }) => {
  const [displayedJEs, setDisplayedJEs] = useState<DisplayedJournalEntry[]>([]);

  useEffect(() => {
    if (jeIQData)
      setDisplayedJEs(
        mergeJournalEntries(jeIQData).map((je: PopulatedJournalEntry) => {
          const getLinesByType = (type: string) =>
            je.journalEntryLineIds.filter((item: { creditOrDebit: any }) => item.creditOrDebit === type);
          const debitLines = getLinesByType('DEBIT');
          const creditLines = getLinesByType('CREDIT');
          const sumDebits = (debitLines as LineWithDecimalAmount[]).reduce(
            (a, item) => a + parseFloat(item.amount.$numberDecimal),
            0,
          );
          const sumCredits = (creditLines as LineWithDecimalAmount[]).reduce(
            (a, item) => a + parseFloat(item.amount.$numberDecimal),
            0,
          );
          const journalId = {
            title: je.journalSequenceNumber || 'Unknown',
            desc: dateConverter(je.accountingDate),
          };
          const created = {
            title: je.originatedBy,
            desc: dateConverter(je.createdAt as DateProps),
          };
          return {
            ...je,
            journalId,
            created,
            debits: formatDollars(sumDebits),
            credits: formatDollars(sumCredits),
            legalEntityId: null,
          };
        }),
      );
  }, [jeIQData]);
  return displayedJEs;
};

export const useJournalsPageFilters = (query: Partial<AllQueryFiltersType> = {}) => {
  const memoizedQuery = useMemoizedQuery(query);
  const { state, helpers } = useFilters(JOURNAL_ENTRIES_PAGE_FILTERS);
  const {
    dateSelections: datePickerSelections,
    setDateSelections: setDatePickerSelections,
    datesWithTzOffset,
  } = useDateSelections();

  useEffect(() => {
    if (memoizedQuery?.startDate?.length && memoizedQuery?.endDate?.length) {
      const updatedDatePickerSelections = [
        {
          key: 'selection',
          startDate: new Date(memoizedQuery.startDate),
          endDate: new Date(memoizedQuery.endDate),
        },
      ];

      setDatePickerSelections([...updatedDatePickerSelections]);
    }
  }, []);

  useEffect(() => {
    helpers[FILTER_TYPE.ACCOUNTING_PERIOD]?.deselectAll();
  }, [datePickerSelections, memoizedQuery]);

  const { data: legalEntitiesData, isLoading: isLoadingLegalEntities } = useLegalEntities();
  useEffect(() => {
    helpers[FILTER_TYPE.LEGAL_ENTITY]?.setIsLoading(isLoadingLegalEntities);
    if (!isLoadingLegalEntities && legalEntitiesData)
      helpers[FILTER_TYPE.LEGAL_ENTITY]?.setAll(
        mergeLegalEntities(legalEntitiesData).map((le) => ({
          ...le,
          selected: memoizedQuery?.legalEntityIds?.includes(le._id) ?? false,
        })),
      );
  }, [legalEntitiesData, isLoadingLegalEntities, memoizedQuery]);

  const { data: accountingPeriodPages, isLoading: isLoadingAccountingPeriods } = useAccountingPeriods();
  useEffect(() => {
    helpers[FILTER_TYPE.ACCOUNTING_PERIOD]?.setIsLoading(isLoadingAccountingPeriods);
    if (!isLoadingAccountingPeriods && accountingPeriodPages) {
      // console.log(accountingPeriodPages.pages);
      helpers[FILTER_TYPE.ACCOUNTING_PERIOD]?.setAll(
        accountingPeriodPages.pages
          .map((apr) =>
            apr.accountingPeriods.map((ap) => ({
              ...ap,
              selected: memoizedQuery?.accountingPeriodIds?.includes(ap._id) ?? false,
            })),
          )
          .flat(),
      );
    }
  }, [isLoadingAccountingPeriods, memoizedQuery]);

  useEffect(() => {
    helpers[FILTER_TYPE.STATUS]?.setAll(
      STATUS_FILTER_ROWS.map((value) => ({
        value,
        _id: value,
        selected: memoizedQuery?.status?.includes(value) ?? false,
      })),
    );
    helpers[FILTER_TYPE.ORIGINATED_BY]?.setAll(
      ORIGINATED_BY_FILTER_ROWS.map((value) => ({
        value,
        _id: value,
        selected: memoizedQuery?.originatedBy?.includes(value.toLowerCase()) ?? false,
      })),
    );
  }, [memoizedQuery]);

  return { state, helpers, datesWithTzOffset, datePickerSelections, setDatePickerSelections };
};

export const getJournalEntrySidepanelTitle = (isLoading, journalEntriesIQData) => {
  if (isLoading && !journalEntriesIQData?.pages[0]?.length) {
    return 'Create journal entry';
  }

  const sequenceNumber = journalEntriesIQData?.pages[0]?.journalEntryModels[0]?.journalSequenceNumber;
  if (!sequenceNumber) return 'Create journal entry';
  const formattedTitle = `${sequenceNumber.slice(0, 3)} ${Number(sequenceNumber.slice(3)) + 1}`;
  return formattedTitle;
};
