import { createWalletJob } from 'services/http/core';
import { removeEmpty } from '../templates/utils';
import { dateConverter } from '../utils';

export const importTransactions = async (wallet, startDate) => {
  await createWalletJob(
    removeEmpty({
      chain: wallet.chain,
      startDate,
      organizationIds: [wallet.organizationId],
      legalEntityIds: [wallet.legalEntityId],
      walletIds: [wallet._id],
    }),
  );
};

export const getDisplayedTransactions = (recentTransactions) => {
  const txs = recentTransactions.pages[0].transactions.map((transaction) => {
    const sequenceNumber = {
      title: transaction.sequenceNumber,
      desc: dateConverter(transaction.transactionDate),
      chain: transaction.chain,
    };
    const grossAmount = parseFloat(transaction.grossPrice.$numberDecimal);
    const netAmount = parseFloat(transaction.netPrice.$numberDecimal);
    const fee = parseFloat(transaction.feePrice.$numberDecimal);

    const isDebitFromKraken =
      transaction?.exchangeRefId && transaction?.transactionDirection?.toLowerCase() === 'debit';
    const isCreditFromKraken =
      transaction?.exchangeRefId && transaction?.transactionDirection?.toLowerCase() === 'credit';
    const fromAddress = {
      address: transaction.fromAddress,
      exchange: isDebitFromKraken ? <img src='/kraken.png' className='w-6 h-6' /> : null,
    };

    const toAddress = {
      address: transaction.toAddress,
      exchange: isCreditFromKraken ? <img src='/kraken.png' className='w-6 h-6' /> : null,
    };

    const transactionAssets = {
      title: transaction?.assetType,
      img: transaction?.assetType,
    };

    return {
      ...transaction,
      sequenceNumber,
      grossAmount,
      netAmount,
      fee,
      fromAddress,
      toAddress,
      transactionAssets,
    };
  });

  return txs;
};
