import { Dispatch, SetStateAction, createContext, useState } from 'react';
import { KBarSections } from '../constants';
import { Action } from 'farish-kbar';
import { MdAdd } from 'react-icons/md';
import { GPTServiceResponse } from 'services/http/gpt-service';

export type GPTChatResponse = GPTServiceResponse & { query: string };

export type Intent = 'Create' | 'Edit' | 'Delete' | 'Analyze';
export type ObjectType = 'LEGAL_ENTITY' | 'LEDGER_ACCOUNT' | 'WALLET';

export const CommandPalleteContext = createContext<{
  secondViewPath: string;
  setSecondViewPath: (path: string) => void;
  actions: Action[];
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  gptChatResponses: GPTChatResponse[];
  setGptChatResponses: Dispatch<SetStateAction<GPTChatResponse[]>>;
  searchBarDisabled: boolean;
  setSearchBarDisabled: (disabled: boolean) => void;
  selectedIntent: Intent | null;
  setSelectedIntent: (intent: Intent | null) => void;
  mentionedObject: ObjectType | null;
  setMentionedObject: (object: ObjectType | null) => void;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}>({
  secondViewPath: '',
  setSecondViewPath: () => null,
  actions: [],
  isLoading: false,
  setIsLoading: () => null,
  gptChatResponses: [],
  setGptChatResponses: () => null,
  setSearchBarDisabled: () => null,
  searchBarDisabled: false,
  selectedIntent: null,
  setSelectedIntent: () => null,
  mentionedObject: null,
  setMentionedObject: () => null,
  isOpen: false,
  setIsOpen: () => null,
});

export const CommandPalleteProvider = ({ children }) => {
  const [secondViewPath, setSecondViewPath] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [gptChatResponses, setGptChatResponses] = useState<GPTChatResponse[]>([]);
  const [searchBarDisabled, setSearchBarDisabled] = useState(false);
  const [selectedIntent, setSelectedIntent] = useState<Intent | null>(null);
  const [mentionedObject, setMentionedObject] = useState<ObjectType | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const actions: Action[] = [
    {
      id: 'create-legal-entity',
      name: 'Create Legal Entity',
      shortcut: ['c', 'e'],
      section: KBarSections.create,
      perform: () => {
        setSecondViewPath('create-legal-entity');
      },
      disableToggleAfterPerform: true,
      icon: <MdAdd />,
    },
    {
      id: 'create-ledger-account',
      name: 'Create Ledger Account',
      shortcut: ['c', 'l'],
      section: KBarSections.create,
      perform: () => {
        setSecondViewPath('create-ledger-account');
      },
      disableToggleAfterPerform: true,
      icon: <MdAdd />,
    },
    {
      id: 'create-wallet',
      name: 'Create Wallet',
      shortcut: ['c', 'w'],
      section: KBarSections.create,
      perform: () => {
        setSecondViewPath('create-wallet');
      },
      disableToggleAfterPerform: true,
      icon: <MdAdd />,
    },
  ];

  return (
    <CommandPalleteContext.Provider
      value={{
        secondViewPath,
        setSecondViewPath,
        actions,
        isLoading,
        setIsLoading,
        gptChatResponses,
        setGptChatResponses,
        searchBarDisabled,
        setSearchBarDisabled,
        selectedIntent,
        setSelectedIntent,
        mentionedObject,
        setMentionedObject,
        isOpen,
        setIsOpen,
      }}
    >
      {children}
    </CommandPalleteContext.Provider>
  );
};
