import React, { useContext, useState } from 'react';
import { useJournalEntryTemplates } from '../../hooks/http';
import { DetailsItem } from '../atoms';
import { SidebarBody } from '../atoms/Sidebar';
import { JobProgressContext } from '../../context/JobProgressProvider';
import { SidebarSectionHeader } from '../atoms/Sidebar/SidebarBody/SidebarSectionHeader';
import { SidebarSection } from '../atoms/Sidebar/SidebarBody/SidebarSection';
import SidebarHeader from '../atoms/Sidebar/SidebarHeader/SidebarHeader';
import { getHost } from '../../lib/utils';
import { JOB_NAME } from 'services/http/job-queue';
import { useDuplicateImpairmentRule, useGetImpairmentRuleById } from '../../hooks/http/useImpairmentRules';
import { toast } from 'react-hot-toast';
import { deriveError, mergeJournalEntryTemplates } from '../templates/utils';
import { useTriggerImpairmentJob } from '../../hooks/http/useImpairment';
import { RULE_CONFIGURATOR_TYPE } from '../rules/configurator/types';
import { useDeriveRuleConfiguratorState, RulesConfigurator } from '../rules/configurator';
import { useInvalidateQuery } from '../../hooks';
export const ViewImpairmentRuleSidebarContent = ({ onPatch, impairmentRuleId, 'data-cy': dataCy }) => {
  const { data: impairmentRule, isLoading: isLoadingImpairmentRule } = useGetImpairmentRuleById(impairmentRuleId);

  const derivedRuleConfiguratorState = useDeriveRuleConfiguratorState(
    RULE_CONFIGURATOR_TYPE.IMPAIRMENT,
    impairmentRule?.topLevelCondition,
    impairmentRule?.configuratorState,
  );

  const { data: journalEntryTemplates, isLoading: isLoadingJournalEntryTemplates } = useJournalEntryTemplates({
    pageSize: 1000,
  });
  const mergedTemplates = mergeJournalEntryTemplates(journalEntryTemplates);

  const [isLoadingOnRun, setIsLoadingOnRun] = useState(false);
  const { startTrackingJob } = useContext(JobProgressContext);

  const { mutateAsync: triggerImpairmentJob } = useTriggerImpairmentJob();

  const { mutateAsync: duplicateImpairmentRule, isLoading: isDuplicating } = useDuplicateImpairmentRule();
  const { invalidateImpairmentRules } = useInvalidateQuery();
  const onRun = async () => {
    setIsLoadingOnRun(true);
    if (!impairmentRuleId) {
      toast.error('Please try again after some time');
    }

    const toastId = toast.loading('Triggering impairment run');

    try {
      await triggerImpairmentJob({
        ruleId: impairmentRuleId,
      });

      toast.success('Impairment run started', { id: toastId });
      startTrackingJob(impairmentRuleId, JOB_NAME.IMPAIRMENT_JOB);
    } catch (error) {
      toast.error(deriveError(error), { id: toastId });
    }
    setIsLoadingOnRun(false);
  };

  const onDuplicate = async () => {
    try {
      await duplicateImpairmentRule(impairmentRuleId);
      invalidateImpairmentRules();
      toast.success('Impairment rule duplicated successfully');
    } catch (error) {
      toast.error(deriveError(error));
    }
  };

  return (
    <>
      <SidebarHeader
        data-cy={dataCy}
        title={impairmentRule?.name}
        link={`${getHost()}/configure/impairment-rules/${impairmentRuleId}`}
        actions={[
          {
            label: 'Run rule',
            onClick: onRun,
            variant: 'primary',
            isLoading: isLoadingOnRun,
          },
          {
            label: 'Duplicate',
            onClick: onDuplicate,
            isLoading: isDuplicating,
            variant: 'tertiary',
          },
          {
            label: 'Edit',
            onClick: onPatch,
            variant: 'tertiary',
            'data-cy': 'editButton',
          },
        ]}
        loading={isLoadingJournalEntryTemplates}
      />
      <SidebarBody>
        <SidebarSectionHeader title='Details' />
        <SidebarSection numberOfColumns={1} loading={isLoadingImpairmentRule}>
          <DetailsItem tag='Name' value={impairmentRule?.name} />
          <DetailsItem tag='Description' value={impairmentRule?.description || 'No description found'} />
          <DetailsItem
            tag='Journal Entry Template'
            value={
              mergedTemplates?.find((template) => template._id === impairmentRule?.journalEntryTemplateId)
                ?.name as string
            }
          />
        </SidebarSection>
        <SidebarSectionHeader title='Rules' />
        <SidebarSection numberOfColumns={1} loading={isLoadingJournalEntryTemplates}>
          <RulesConfigurator
            configuratorType={RULE_CONFIGURATOR_TYPE.IMPAIRMENT}
            defaultValue={impairmentRule?.configuratorState ?? derivedRuleConfiguratorState}
            isDisabled
          />
        </SidebarSection>
      </SidebarBody>
    </>
  );
};
