import DatePicker from 'react-datepicker';
import { InputWithExtras } from '../input-with-extras';

import { MdCalendarToday, MdCancel } from 'react-icons/md';
import { DateInputProps } from './types';
import './styles.css';
import { isEqual, isValid } from 'date-fns';
import { InputTrailingAction } from '../input-trailing-action';
import { LoaderIcon } from '../loader-icon';

export const DateInput = ({ date, setDate, isLoading, ...rest }: DateInputProps) => {
  return (
    <DatePicker
      selected={date}
      onChange={(d) => setDate(d)}
      customInput={
        <InputWithExtras
          leading={isLoading ? <LoaderIcon /> : <MdCalendarToday />}
          trailing={
            date && (
              <InputTrailingAction
                icon={<MdCancel className='w-5 h-5' />}
                onClick={() => {
                  setDate(null);
                }}
              />
            )
          }
        />
      }
      showPopperArrow={false}
      dayClassName={(d) => (isValid(date) && isEqual(d, date as Date) ? 'bg-[#0000ff] text-white' : '')}
      {...rest}
    />
  );
};
