import React, { useMemo } from 'react';
import { Card } from '../../../styles';
import { ProgressItem } from '../../atoms';

export const SetupGuide = ({ completed, data }) => {
  const completedPercentage = useMemo(() => `${(completed / 5) * 100}%`, [completed]);

  return (
    <div>
      <Card className='w-full mr-10'>
        <div>
          <div className='text-2xl font-medium'>Setup guide</div>
          <div className='mt-1'>Follow the task list to complete your set up!</div>
        </div>
        <div className='w-full h-full bg-gray-200 duration-300 border rounded-full'>
          <div
            className={`bg-blue-600 h-2.5 duration-300 rounded-full`}
            style={{
              width: completedPercentage,
            }}
          />
        </div>
        <div className='text-blue-600'>{completedPercentage} completed</div>
      </Card>
      <Card className='!gap-0 my-6'>
        <div>
          <p className='font-semibold text-2xl'>Task list</p>
          <p className='text-lg mt-2'>{completed}/5 setup tasks complete</p>
        </div>
        <ProgressItem title='Enter legal entities to account for' completed={!!(data?.legalEntitiesCount > 0)} />
        <ProgressItem title='Connect wallets to pull transactions' completed={!!(data?.walletsCount > 0)} />
        <ProgressItem title='Structure your chart of accounts' completed={!!(data?.ledgerAccountsCount > 0)} />
        <ProgressItem title='Create rules to automate entries' completed={!!(data?.journalEntriesCount > 0)} />
        <ProgressItem title='View financial statements in realtime' completed={!!(data?.journalEntriesCount > 0)} />
      </Card>
    </div>
  );
};

export default SetupGuide;
