import React, { useState } from 'react';
import { AppBarChart } from 'ui';
import { useGetSourcesData } from '../../../hooks/http/useGetSourcesData';
import { FILTER_TYPE } from '../../filters';
import { SOURCE_CHART_PERIODS } from '../../../constants';

enum TABS {
  DAY = 0,
  WEEK = 1,
  MONTH = 2,
  YEAR = 3,
  ALL_TIME = 4,
}

const mapTabToKey = {
  0: 'day',
  1: 'week',
  2: 'month',
  3: 'year',
  4: 'allTime',
};

const mapTabToPeriodName = {
  0: 'Day',
  1: 'Week',
  2: 'Month',
  3: 'Year',
  4: 'All Time',
};

const mapCategoryToKey: Record<string, string> = {
  'Total Transactions': 'totalTransactions',
  'Dollar Value': 'transactionsValue',
};

const processDataForChart = (data: any[], category: string, period: TABS) => {
  const processedData = data.map((value) => {
    return {
      walletName: value.walletName,
      'Total Transactions': value[mapTabToKey[period]][mapCategoryToKey[category]],
      'Dollar Value': value[mapTabToKey[period]][mapCategoryToKey[category]],
    };
  });
  return processedData;
};

const categories = ['Total Transactions', 'Dollar Value'];

export const SourcesChart = ({ filterHelpers }) => {
  const [selectedTab, setSelectedTab] = useState(TABS.MONTH);
  const [category, setCategory] = useState(categories[0]);
  const { data: sourcesVolumeData, isLoading: isLoadingSourcesData } = useGetSourcesData({
    legalEntityIds: filterHelpers[FILTER_TYPE.LEGAL_ENTITY]?.getAllSelectedWithTransform(),
    tags: filterHelpers[FILTER_TYPE.TAG]?.getAllSelectedWithTransform(),
    chains: filterHelpers[FILTER_TYPE.CHAIN]?.getAllSelectedWithTransform(),
  });

  let sourcesVolumeDataArray: any[] = [];

  for (const [key, value] of Object.entries(sourcesVolumeData?.data || {})) {
    sourcesVolumeDataArray.push({
      ...value,
      walletName: key,
    });
  }

  const processedData = processDataForChart(sourcesVolumeDataArray, category, selectedTab);

  return (
    <AppBarChart
      categories={categories}
      category={category}
      setCategory={setCategory}
      isLoading={isLoadingSourcesData}
      index={'walletName'}
      tabs={[
        SOURCE_CHART_PERIODS.DAY,
        SOURCE_CHART_PERIODS.WEEK,
        SOURCE_CHART_PERIODS.MONTH,
        SOURCE_CHART_PERIODS.YEAR,
        SOURCE_CHART_PERIODS.ALLTIME,
      ]}
      setSelectedTab={setSelectedTab}
      data={processedData}
      selectedTab={selectedTab}
      containerId='sources-chart'
      title={`Sources Volume Chart - ${mapTabToPeriodName[selectedTab]}`}
    />
  );
};

export default SourcesChart;
