import axios, { AxiosResponse } from 'axios';
import { SERVER_URL_FILE_UPLOAD } from '../config';
import { FileMetadata } from './response.types';

// add config params for axios interceptor
export async function uploadFile(
  file: any,
  metadata: Omit<FileMetadata, 'userid' | 'uploaderversion' | 'fileid'>,
): Promise<AxiosResponse> {
  // request a presigned upload url
  // console.log({ metadata });
  const initiateResult = await axios.post(`${SERVER_URL_FILE_UPLOAD}/v2/initiate-upload`, metadata);
  // console.log({ initiateResult: initiateResult.data });
  // rename file to match the fileid in presigned url
  const renamedFile = new File([file], initiateResult.data.fileId, {
    type: file.type,
  });
  // upload file on presigned upload url
  return axios.put(initiateResult.data.s3PutObjectUrl, renamedFile, {
    headers: {
      'Content-Type': metadata.contenttype as string,
    },
    excludeAuthorizationHeader: true,
    skipOrganizationId: true,
  });
}
