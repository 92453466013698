import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import { useOnClickOutside } from 'usehooks-ts';
import { checkboxStyle, styleEdit } from '../../../constants/customStyles';
import { DropDownCheckboxOptions, DropDownCheckboxProps } from './types';
import {
  ValueContainer,
  MultiValueRemove,
  Option,
  Menu,
  MenuList,
  ClearIndicator,
  NoOptionsMessage,
  DropdownIndicator,
} from './DropDownCheckboxComponents';
import { dropdownCheckboxCheckItem } from '../filter/utils';
import { optionsData } from './DropDownCheckboxComponents/type';
import { classNames } from 'ui';

export function DropDownCheckbox({
  label,
  menuLabel = '',
  className,
  allowSelectAll = false,
  isSearchable = false,
  options,
  getItems,
  showClearButton = true,
  showOnlyButton = true,
  hasTabs = false,
  tabs = [],
  activeTabId = '',
  setActiveTabId,
  isLoading = false,
  ...props
}: DropDownCheckboxProps) {
  const ref = useRef(null);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [optionsData, setOptionsData] = useState<optionsData>(options ?? []);

  useEffect(() => {
    if (Array.isArray(options) && !!options.length) {
      if (allowSelectAll && options[0]?.label !== 'Select all') {
        setOptionsData([{ label: 'Select all', value: 'Select all', checked: false }, ...options]);
      } else {
        setOptionsData([...options]);
      }
    } else {
      setOptionsData([]);
    }
  }, [options, allowSelectAll]);

  useOnClickOutside(ref, () => {
    setMenuIsOpen(false);
  });

  const handleGetItems = (items: DropDownCheckboxOptions) => {
    // we are using slice (1) to remove { label: 'Select all', value: 'Select all', checked: false } if the allowSelectAll === true
    if (allowSelectAll) return getItems(items.slice(1));
    return getItems(items);
  };

  return (
    <div
      ref={ref}
      onClick={() => {
        setMenuIsOpen(!menuIsOpen);
      }}
      className={className}
    >
      {!!label && <label className='block mb-1 font-medium'>{label}</label>}
      <Select
        isMulti
        options={isLoading ? [] : optionsData}
        isSearchable={isSearchable}
        isLoading={isLoading}
        hideSelectedOptions={false}
        backspaceRemovesValue={false}
        menuIsOpen={menuIsOpen}
        value={options?.filter(({ checked }) => checked === true)}
        onChange={(items: any) => handleGetItems(dropdownCheckboxCheckItem(items, optionsData))}
        styles={{
          ...styleEdit,
          ...checkboxStyle,
        }}
        {...props}
        classNames={{
          control: () =>
            classNames(
              menuIsOpen ? 'border border-[#b3b3b3]' : 'border hover:bg-white hover:border-[#b3b3b3]',
              '!h-10 !shadow',
            ),
          dropdownIndicator: () =>
            `${options?.filter(({ checked }) => checked === true).length ? '!hidden' : '[&_svg]:fill-black'}`,
          clearIndicator: () => `${!options?.filter(({ checked }) => checked === true).length && '!hidden'}`,
          menu: () => '!z-[7]',
        }}
        components={{
          Menu,
          Option: (props) => (
            <Option
              setOptionsData={(data) => {
                setOptionsData([...data]);
                handleGetItems(data);
              }}
              optionsData={optionsData}
              showOnlyButton={showOnlyButton}
              {...props}
            />
          ),
          MultiValueRemove,
          MenuList: (props) => (
            <MenuList
              isLoading={isLoading}
              optionsData={optionsData}
              setOptionsData={(data) => {
                setOptionsData([...data]);
                handleGetItems(data);
              }}
              menuLabel={menuLabel}
              setMenuIsOpen={setMenuIsOpen}
              showClearButton={showClearButton}
              tabs={tabs}
              activeTabId={activeTabId}
              hasTabs={hasTabs}
              setActiveTabId={setActiveTabId}
              {...props}
            />
          ),
          ValueContainer,
          ClearIndicator,
          NoOptionsMessage,
          DropdownIndicator,
        }}
      />
    </div>
  );
}

export default DropDownCheckbox;
