import axios from 'axios';
import { SERVER_URL_CONFIGURATION_SERVICE } from '../../config';
export type AddTokenToSpamListPayload = {
  rawContractAddress: string;
  assetType: string;
  chain: string;
  organizationId: string;
};
export const addTokenToSpamList = async ({
  rawContractAddress,
  assetType,
  chain,
  organizationId,
}: AddTokenToSpamListPayload) => {
  return await axios.post(`${SERVER_URL_CONFIGURATION_SERVICE}/spam-token`, {
    rawContractAddress,
    assetType,
    chain,
    organizationId,
  });
};

export type RemoveTokenFromSpamListPayload = {
  rawContractAddress: string;
  assetType: string;
};

export const removeTokenFromSpamList = async ({ rawContractAddress, assetType }: RemoveTokenFromSpamListPayload) => {
  return await axios.delete(
    `${SERVER_URL_CONFIGURATION_SERVICE}/spam-token?rawContractAddress=${rawContractAddress}&assetType=${assetType}`,
  );
};

export type MarkTransactionAsSpamPayload = {
  transactionIds: string[];
};

export const markTransactionsAsSpam = async ({ transactionIds }: MarkTransactionAsSpamPayload) => {
  return await axios.post(`${SERVER_URL_CONFIGURATION_SERVICE}/bulk-spam-transactions`, {
    transactionIds,
  });
};
