export const USDC_CONTRACTS = [
  '0xaf88d065e77c8cC2239327C5EDb3A432268e5831', //arb
  '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48', // eth
  '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E', // avax
  '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913', // base
  '0x0b2C639c533813f4Aa9D7837CAf62653d097Ff85', // opt
  '0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359', // 'polygon
  'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v', // sol
].map((x) => x?.toLowerCase());
export const USDT_CONTRACTS = [
  '0xdAC17F958D2ee523a2206206994597C13D831ec7', // eth
  '0x94b008aa00579c1307b0ef2c499ad98a8ce58e58', // opt
  '0xc2132D05D31c914a87C6611C10748AEb04B58e8F', // polygon
  '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9', // arb
  '0x55d398326f99059ff775485246999027b3197955', // bsc
].map((x) => x?.toLowerCase());

export const ETH_CONTRACTS = [
  undefined,
  '0x0000000000a39bb272e79075ade125fd351887ac', // blur pool token is ETH
].map((x) => x?.toLowerCase());

export const CONTRACTS = {
  USDC: USDC_CONTRACTS,
  USDT: USDT_CONTRACTS,
  ETH: ETH_CONTRACTS,
};

export const checkValidity = (contract: string, asset: string) => {
  if (!asset) return false;
  asset = asset.toUpperCase();
  if (!contract) return true;
  if (!CONTRACTS[asset]) return true;
  if (CONTRACTS[asset].includes(contract.toLowerCase())) return true;
  return false;
};
