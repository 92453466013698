import { DropDownSelect } from '../../../../atoms';
import { useRuleConfigurator } from '../../rule-configurator-context';
import { selectorStyle } from './selector-styles';
import { EQUAL_NOTEQUAL_OPERATORS } from '../../data';
import { HEDGEY_EVENTS } from '../../../../atoms/NewConditionSelector/ConditionsComponents';
import { ConditionRow } from './ConditionRow';
import { FACT } from '../../types';
import { useCondition } from '../useCondition';

const Row = () => {
  const { updateCondition, isDisabled } = useRuleConfigurator();

  const condition = useCondition();
  return (
    <>
      <DropDownSelect
        customStyle={selectorStyle}
        defaultValue={{ label: 'Hedgey Event', value: 'Hedgey Event' }}
        isDisabled
      />
      <DropDownSelect
        customStyle={selectorStyle}
        options={EQUAL_NOTEQUAL_OPERATORS}
        value={EQUAL_NOTEQUAL_OPERATORS.find((item) => item.value === condition.operator)}
        onChange={({ value }) => {
          updateCondition(condition.id, {
            operator: value,
          });
        }}
        isDisabled={isDisabled}
      />

      <DropDownSelect
        customStyle={selectorStyle}
        options={HEDGEY_EVENTS}
        value={HEDGEY_EVENTS.find((item) => item.value === condition.value)}
        onChange={({ value }) => {
          updateCondition(condition.id, {
            value,
          });
        }}
        isDisabled={isDisabled}
      />
    </>
  );
};

export const HedgeyRuleCondition = () => (
  <ConditionRow facts={[FACT.HEDGEY]}>
    <Row />
  </ConditionRow>
);
