import React from 'react';
import { DescriptiveLoader, DialogContent } from 'ui';
import { useSyncedBgJobTrackerProps } from './useSyncedBgJobTrackerProps';

const TrackJobInDialog = ({ jobName, referenceId, onClose, bgJobState, setBgJobState, error }) => {
  const { title, description, status } = useSyncedBgJobTrackerProps({
    jobName,
    referenceId,
    bgJobState,
    setBgJobState,
    error,
  });

  return (
    <DialogContent onClose={onClose}>
      <div className='pb-3'>
        <DescriptiveLoader title={title} description={description} status={status} />
      </div>
    </DialogContent>
  );
};

export default TrackJobInDialog;
