import { LedgerAccount, LegalEntity, Tag, Wallet } from 'schemas';
import { GPTServiceIntentEnum, GPTServiceResponse } from 'services/http/gpt-service';
import { useCommandPallete } from './useCommandPallete';
import { LegalEntityForm } from './FollowUpForm/LegalEntityForm';
import { useEffect } from 'react';
import { LedgerAccountForm } from './FollowUpForm/LedgerAccountForm';
import { WalletForm } from './FollowUpForm/WalletForm';
import { TagForm } from './FollowUpForm/TagForm';
import { ResponseContainer } from './ResponseContainer';

export const FollowUpForms: React.FC<{ gptResponse: GPTServiceResponse; query: string }> = ({ gptResponse, query }) => {
  const { setSearchBarDisabled } = useCommandPallete();

  if (!gptResponse) return null;

  useEffect(() => {
    setSearchBarDisabled(true);
    return () => setSearchBarDisabled(false);
  }, []);

  // On missing field cases from gpt instruction, gpt-service will return the extracted information
  const extractedObjects = gptResponse?.entityRecognitionResponse?.entitiesJsons;

  if (!extractedObjects) return null;

  const FollowUpComponent = ({
    extractedObject,
    index = 0,
  }: {
    extractedObject: Record<string, any>;
    index: number;
  }) => {
    switch (gptResponse.intent) {
      case GPTServiceIntentEnum.LEGAL_ENTITY_CREATE: {
        const initialValues = extractedObject as Partial<LegalEntity>;

        return <LegalEntityForm initialFormValues={initialValues} index={index} />;
      }
      case GPTServiceIntentEnum.LEDGER_ACCOUNT_CREATE: {
        const initialValues = extractedObject as Partial<LedgerAccount>;

        return <LedgerAccountForm initialFormValues={initialValues} index={index} />;
      }
      case GPTServiceIntentEnum.WALLET_CREATE: {
        const initialValues = extractedObject as Partial<Wallet>;

        return <WalletForm initialFormValues={initialValues} index={index} />;
      }
      case GPTServiceIntentEnum.TAG_CREATE: {
        const initialValues = extractedObject as Partial<Tag>;

        return <TagForm initialFormValues={initialValues} index={index} />;
      }
      default:
        return null;
    }
  };

  return (
    <>
      <ResponseContainer isQuery>{query}</ResponseContainer>
      {extractedObjects.map((extractedObject, index) => {
        return <FollowUpComponent key={index} index={index} extractedObject={extractedObject} />;
      })}
    </>
  );
};
