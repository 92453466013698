const theme = {
  screens: {
    sm: '375px',
    md: '720px',
    lg: '1025px',
    xl: '1280px',
    '2xl': '1440px',
    xxl: '1728px',
    /** Landing page breakpoint  */
    lpxl: '1512px',
  },

  transparent: 'transparent',
  current: 'currentColor',
  extend: {
    keyframes: {
      fade: {
        '0%': { opacity: 0 },
        '100%': { opacity: 1 },
      },
      fadeOverlay: {
        '0%': { opacity: 0 },
        '100%': { opacity: 0.5 },
      },
      dot1: {
        '0%': {
          backgroundColor: '#6366F1',
        },
        '50%': {
          backgroundColor: '#A5B4FC',
        },
        '100%': {
          backgroundColor: '#C7D2FE',
        },
      },

      dot2: {
        '0%': {
          backgroundColor: '#A5B4FC',
        },
        '50%': {
          backgroundColor: '#6366F1',
        },
        '100%': {
          backgroundColor: '#C7D2FE',
        },
      },
      dot3: {
        '0%': {
          backgroundColor: '#A5B4FC',
        },
        '50%': {
          backgroundColor: '#C7D2FE',
        },
        '100%': {
          backgroundColor: '#6366F1',
        },
      },
    },
    animation: {
      fade: 'fade .25s ease-in-out',
      'typing-dot1': 'typing-dot1 3s infinite ease-in-out',
      'typing-dot2': 'typing-dot2 3s infinite ease-in-out',
      'typing-dot3': 'typing-dot3 3s infinite ease-in-out',
    },
    width: {
      50: '12.625rem',
      128: '28.75rem',
      524: '32.75rem',
    },
    height: {
      21: '5.25rem',
    },
    zIndex: {
      normal: '10', // z-normal
      navbar: '20', // z-navbar
      sidepanel: '30', // z-sidepanel
      modal: '40', // z-modal
      commandpallet: '50', // z-commandpallet
      commandmenu: '60', // for a menu on a command
    },
    colors: {
      'primary-dark': '#1E2024',
      'black-1': '#111111',
      'blue-1': '#0000FF',
      eyebrow: '#EE82EE',
      'side-navigation-version': 'rgba(17, 17, 17, 0.3)',
      'side-navigation-inactive': 'rgba(17, 17, 17, 0.6)',
      'input-border': '#c9c9c9',
      'authentication-card-border': 'rgba(0, 0, 0, 0.2)',
      // status
      neutral: {
        fill: '#dddddd',
        stroke: '#C9C9C9',
        'label-dark': '#111111',
        'label-light': '#3a3a3a',
      },
      info: {
        fill: '#dadaf3',
        stroke: '#0000FF',
        'label-dark': '#0000FF',
        'label-light': '#2c2cfd',
      },
      positive: {
        fill: '#dae7da',
        stroke: '#008000',
        'label-dark': '#008000',
        'label-light': '#2c942c',
      },
      danger: {
        fill: '#f5c2c2',
        stroke: '#FF0000',
        'label-dark': '#FF0000',
        'label-light': '#fd2727',
      },
      warning: {
        fill: '#f3eada',
        stroke: '#FFA500',
        'label-dark': '#FFA500',
        'label-light': '#fdb32c',
      },
      'btn-focus-stroke': '#4d4dff',
      // light mode
      tremor: {
        brand: {
          faint: '#eff6ff', // blue-50
          muted: '#bfdbfe', // blue-200
          subtle: '#60a5fa', // blue-400
          DEFAULT: '#3b82f6', // blue-500
          emphasis: '#1d4ed8', // blue-700
          inverted: '#ffffff', // white
        },
        background: {
          muted: '#f9fafb', // gray-50
          subtle: '#f3f4f6', // gray-100
          DEFAULT: '#ffffff', // white
          emphasis: '#374151', // gray-700
        },
        border: {
          DEFAULT: '#c9c9c9',
        },
        ring: {
          DEFAULT: '#e5e7eb', // gray-200
        },
        content: {
          subtle: '#9ca3af', // gray-400
          DEFAULT: '#6b7280', // gray-500
          emphasis: '#000', // black
          strong: '#111827', // gray-900
          inverted: '#ffffff', // white
        },
      },
      // dark mode
      'dark-tremor': {
        brand: {
          faint: '#0B1229', // custom
          muted: '#172554', // blue-950
          subtle: '#1e40af', // blue-800
          DEFAULT: '#3b82f6', // blue-500
          emphasis: '#60a5fa', // blue-400
          inverted: '#030712', // gray-950
        },
        background: {
          muted: '#131A2B', // custom
          subtle: '#1f2937', // gray-800
          DEFAULT: '#111827', // gray-900
          emphasis: '#d1d5db', // gray-300
        },
        border: {
          DEFAULT: '#1f2937', // gray-800
        },
        ring: {
          DEFAULT: '#1f2937', // gray-800
        },
        content: {
          subtle: '#4b5563', // gray-600
          DEFAULT: '#6b7280', // gray-600
          emphasis: '#e5e7eb', // gray-200
          strong: '#f9fafb', // gray-50
          inverted: '#000000', // black
        },
      },
    },
    gridTemplateRows: {
      'side-navigation': 'min-content min-content 1fr',
    },
    borderRadius: {
      input: '8px',
      'authentication-card': '16px',
      'tremor-small': '0.375rem',
      'tremor-default': '0.5rem',
      'tremor-full': '9999px',
    },
    spacing: {
      4.5: '18px',
      6.5: '26px',
      7.5: '30px',
      9.5: '38px',
      12.5: '50px',
      15: '60px',
      15.5: '62px',
      22: '88px',
      30: '120px',
    },
    fontFamily: {
      eighties: ['Eighties Comeback Serif', 'sans-serif'],
    },
    boxShadow: {
      // light
      'tremor-input': '0 1px 2px 0 rgb(0 0 0 / 0.05)',
      'tremor-card': '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
      'tremor-dropdown': '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
      // dark
      'dark-tremor-input': '0 1px 2px 0 rgb(0 0 0 / 0.05)',
      'dark-tremor-card': '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
      'dark-tremor-dropdown': '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
    },
    fontSize: {
      'tremor-label': ['0.75rem'],
      'tremor-default': ['0.875rem', { lineHeight: '1.25rem' }],
      'tremor-title': ['1.125rem', { lineHeight: '1.75rem' }],
      'tremor-metric': ['1.875rem', { lineHeight: '2.25rem' }],
    },
  },
};

module.exports = {
  theme,
};
