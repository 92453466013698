import { format } from 'date-fns';
import { useCsvExport, useGetAll } from '..';
import { transactionDetailType } from '../../components/Transaction/types';
import { formatDollars } from '../../components/templates/utils';
import { getTransactions } from 'services/http';
import { Transaction } from 'services/http/response.types';
import { formatTableNumbers } from 'global-utils';

export enum TRANSACTION_CSV_HEADERS {
  TRANSACTION_ID = 'Transaction ID',
  TRANSACTION_DATE = 'Transaction Date',
  UTC_TIMESTAMP = 'UTC Timestamp',
  CHAIN = 'Chain',
  DEBIT_CREDIT = 'Debit/Credit',
  CURRENCY = 'Currency',
  GROSS_TOKEN_AMOUNT = 'Gross Token Amount',
  NET_TOKEN_AMOUNT = 'Net Token Amount',
  FEE_TOKEN_AMOUNT = 'Fee Token Amount',
  GROSS_VALUE = 'Gross Value',
  NET_VALUE = 'Net Value',
  FEE_VALUE = 'Fee Value',
  ASSET = 'Asset',
  TO_ADDRESS = 'To Address',
  FROM_ADDRESS = 'From Address',
  INTERNAL_ALIAS = 'Internal Alias',
  PERIOD = 'Period',
  TRANSACTION_HASH = 'Transaction Hash',
  EXTERNAL_ALIAS = 'External Alias',
  COST_BASIS = 'Cost Basis',
  MEMO = 'Memo',
  MERCHANT = 'Merchant',
  CATEGORY = 'Category',
}

const getCsvColumnValueFromTransaction = (
  transactionDetail: transactionDetailType,
  column: TRANSACTION_CSV_HEADERS,
) => {
  if (!transactionDetail) return;
  // console.log({ date: transactionDetail.transactionDate });
  switch (column) {
    case TRANSACTION_CSV_HEADERS.TRANSACTION_ID:
      return transactionDetail.sequenceNumber;
    case TRANSACTION_CSV_HEADERS.TRANSACTION_DATE:
      return `${new Date(transactionDetail.transactionDate).toLocaleDateString()}`;
    case TRANSACTION_CSV_HEADERS.UTC_TIMESTAMP:
      return new Date(transactionDetail.transactionDate).toISOString();
    case TRANSACTION_CSV_HEADERS.CHAIN:
      return transactionDetail.chain?.toUpperCase();
    case TRANSACTION_CSV_HEADERS.DEBIT_CREDIT:
      return transactionDetail.transactionDirection;
    case TRANSACTION_CSV_HEADERS.CURRENCY:
      return transactionDetail.currency;
    case TRANSACTION_CSV_HEADERS.GROSS_TOKEN_AMOUNT:
      return transactionDetail.grossAmount?.$numberDecimal;
    case TRANSACTION_CSV_HEADERS.NET_TOKEN_AMOUNT:
      return transactionDetail.netPrice?.$numberDecimal;
    case TRANSACTION_CSV_HEADERS.FEE_TOKEN_AMOUNT:
      return transactionDetail.feePrice?.$numberDecimal;
    case TRANSACTION_CSV_HEADERS.GROSS_VALUE:
      return formatTableNumbers({ value: transactionDetail.grossPrice?.$numberDecimal });
    case TRANSACTION_CSV_HEADERS.NET_VALUE:
      return formatTableNumbers({ value: transactionDetail.netPrice?.$numberDecimal });
    case TRANSACTION_CSV_HEADERS.FEE_VALUE:
      return formatTableNumbers({ value: transactionDetail.feePrice?.$numberDecimal });
    case TRANSACTION_CSV_HEADERS.ASSET:
      return transactionDetail.assetType?.toUpperCase();
    case TRANSACTION_CSV_HEADERS.TRANSACTION_HASH:
      return transactionDetail.transactionHash;
    case TRANSACTION_CSV_HEADERS.FROM_ADDRESS:
      if (transactionDetail?.meta?.cardName && transactionDetail.transactionDirection === 'Debit')
        return transactionDetail?.meta?.cardName;
      else if (transactionDetail?.meta?.merchantName && transactionDetail.transactionDirection === 'Credit')
        return transactionDetail?.meta?.merchantName;
      return transactionDetail.fromAddress;
    case TRANSACTION_CSV_HEADERS.TO_ADDRESS:
      if (transactionDetail?.meta?.cardName && transactionDetail.transactionDirection === 'Credit')
        return transactionDetail?.meta?.cardName;
      else if (transactionDetail?.meta?.merchantName && transactionDetail.transactionDirection === 'Debit')
        return transactionDetail?.meta?.merchantName;
      return transactionDetail.toAddress;
    case TRANSACTION_CSV_HEADERS.PERIOD:
      return format(new Date(transactionDetail.transactionDate), 'MMM	y');
    case TRANSACTION_CSV_HEADERS.INTERNAL_ALIAS:
      return transactionDetail.walletId?.name;
    case TRANSACTION_CSV_HEADERS.EXTERNAL_ALIAS:
      return transactionDetail.externalSource?.name;
    case TRANSACTION_CSV_HEADERS.COST_BASIS:
      return formatDollars(transactionDetail.assetUnitPrice?.$numberDecimal);
    case TRANSACTION_CSV_HEADERS.MEMO:
      return transactionDetail.memo;
    case TRANSACTION_CSV_HEADERS.CATEGORY: {
      let category = transactionDetail?.meta?.category;
      if (category) {
        category = category.replace(/_/g, ' ').toUpperCase();
      }
      return category;
    }
    case TRANSACTION_CSV_HEADERS.MERCHANT:
      return transactionDetail?.meta?.merchantName;
    default:
      return '';
  }
};

export const getCsvRowForSingleTransaction = (transactionDetail): string[][] => {
  return transactionDetail
    ? [
        Object.values(TRANSACTION_CSV_HEADERS) as string[],
        Object.values(TRANSACTION_CSV_HEADERS).map(
          (column) => getCsvColumnValueFromTransaction(transactionDetail, column) ?? '',
        ),
      ]
    : [];
};

export const getCsvRowsForTransactions = (transactionDetails): string[][] => {
  return transactionDetails.reduce(
    (rows, td) => {
      return [
        ...rows,
        Object.values(TRANSACTION_CSV_HEADERS).map((column) => getCsvColumnValueFromTransaction(td, column)),
      ];
    },
    [Object.values(TRANSACTION_CSV_HEADERS) as string[]],
  );
};

const getAllTransactionsQueryFn = async (filters) => {
  const response = await getTransactions(filters);
  return response.data.transactions;
};

export const useGetAllTransactions = (transactionsQueryFilters) =>
  useGetAll<Transaction>(getAllTransactionsQueryFn, transactionsQueryFilters, 500);

export const useCsvExportForTransactions = (transactionsQueryFilters) => {
  const getAllJEs = useGetAllTransactions(transactionsQueryFilters);

  const prepareCallback = async () => {
    const allJournalEntries = await getAllJEs();
    const data = getCsvRowsForTransactions(allJournalEntries);
    return data;
  };

  const { prepare, csvData } = useCsvExport(prepareCallback);
  return { prepare, csvData };
};

export const useCsvExportForSingleTransaction = (transaction) => {
  const prepareCallback = async () => {
    const data = getCsvRowForSingleTransaction(transaction);
    return data;
  };

  const { prepare, csvData } = useCsvExport(prepareCallback);
  return { prepare, csvData };
};
