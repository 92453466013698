import { useTransactionById } from '../../../../hooks/http';
import { Sidebar, SidebarBody, SidebarTopBar } from '../../../atoms/Sidebar';
import { CSVDownload } from 'react-csv';
import AddTransactionMemo from '../../../Transaction/AddTransactionMemo';
import CreateJournalEntry from '../../../jorunalEntry/CreateJournalEntry';
import { SidebarProps } from '../../SidebarGlobal';
import { useCsvExportForSingleTransaction, useTabState } from '../../../../hooks';
import NFTDetails from './NFTDetails';
import JournalEntriesTable from './JournalEntriesTable';
import Traits from './Traits';
import CostBasis from './CostBasis';
import PriceBreakdown from './PriceBreakdown';
import Details from './Details';
import Memo from './Memo';
import TransactionSidebarHeader from './TransactionSidebarHeader';

export const TransactionSidebar: React.FC<SidebarProps> = ({
  id,
  isDockPanelAvailable,
  onBack,
  isPrimary,
  handlePrimaryPanelClose,
  'data-cy': dataCy = 'transaction',
}) => {
  const { data, isLoading } = useTransactionById(id);

  const transaction = data?.pages[0].transactions[0];

  const { secondRouteUnStack, updateTabSidebarState } = useTabState();

  const { csvData, prepare: onExportAsCsv } = useCsvExportForSingleTransaction(transaction);

  return (
    <>
      {(!secondRouteUnStack?.showCreateJournal || isPrimary) && (
        <Sidebar data-cy={dataCy}>
          <SidebarTopBar
            isDockPanelAvailable={isDockPanelAvailable}
            onClose={() => handlePrimaryPanelClose && handlePrimaryPanelClose()}
            onBack={onBack}
            itemId={id}
            isPrimary={isPrimary}
            data-cy={dataCy}
          />
          <TransactionSidebarHeader
            transaction={transaction}
            transactionId={id}
            isLoading={isLoading}
            isPrimary={isPrimary}
            onExportAsCsv={onExportAsCsv}
            dataCy={dataCy}
          />
          <SidebarBody>
            <NFTDetails transaction={transaction} isLoading={isLoading} />
            <Details transaction={transaction} isLoading={isLoading} />
            <PriceBreakdown transaction={transaction} transactionId={id} />
            <CostBasis transaction={transaction} isLoading={isLoading} />
            <Traits transaction={transaction} />
            <Memo transaction={transaction} isLoading={isLoading} isPrimary={isPrimary} />
            <JournalEntriesTable transactionId={id} />
          </SidebarBody>
        </Sidebar>
      )}
      {secondRouteUnStack?.showAddTransactionMemo && (
        <AddTransactionMemo
          onClose={() => updateTabSidebarState({ secondRouteUnStack: {} })}
          transactionId={transaction?._id}
          organizationId={transaction?.organizationId}
          savedMemo={secondRouteUnStack?.memo ?? transaction?.memo}
        />
      )}
      {secondRouteUnStack?.showCreateJournal && (
        <CreateJournalEntry
          onCancel={() => updateTabSidebarState({ secondRouteUnStack: {} })}
          selectedJournalEntry={null}
          onClose={() => updateTabSidebarState({ secondRouteUnStack: {} })}
          defaultTransaction={{
            transactionId: transaction?._id,
            walletId: transaction?.walletId?._id,
          }}
        />
      )}

      {csvData && (
        <CSVDownload
          data={csvData}
          target='_blank'
          filename={`transaction-export-${new Date().toLocaleDateString()}.csv`}
        />
      )}
    </>
  );
};

export default TransactionSidebar;
