import { useMutation } from '@tanstack/react-query';
import { callGPTService } from 'services';
import { Intent } from '../../context';

export type useGPTPayloadType = {
  instruction: string;
  organizationId: string;
  userId: string;
  selectedIntent?: Intent | null;
};

export const useGPT = () => useMutation((data: useGPTPayloadType) => callGPTService(data));
