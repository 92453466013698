import { useEffect, useState } from 'react';
import { Button, InputLabel, InputWithExtras } from 'ui';
import { DetailsItem, LoadingSpinner } from '../../atoms';
import { useCreateTag, useInvalidateQuery, useSession } from '../../../hooks';
import { getHost } from '../../../lib/utils';
import { useRouter } from 'next/router';
import { useCommandPallete } from '../useCommandPallete';
import { useKBar } from 'farish-kbar';
import { DetailsCard } from '../DetailsCard';
import { MdEdit } from 'react-icons/md';
import { ResponseContainer } from '../ResponseContainer';

enum STEPS {
  TAG_KEY = 1,
  TAG_VALUE = 2,
  CONFIRMATION = 3,
}

export const TagForm: React.FC<{ initialFormValues: any; index: number }> = ({ initialFormValues = {}, index = 0 }) => {
  const [tagEntryForm, setTagEntryForm] = useState(initialFormValues);
  const [step, setStep] = useState(STEPS.TAG_KEY);
  const [isEditing, setIsEditing] = useState(false);

  const { mutateAsync: createTagAsync, isLoading: isCreating, data } = useCreateTag();
  const { invalidateTags } = useInvalidateQuery();
  const router = useRouter();
  const { setGptChatResponses } = useCommandPallete();
  const { query } = useKBar();
  const { organizationId, userId } = useSession();

  useEffect(() => {
    invalidateTags();
  }, [data]);

  switch (step) {
    case STEPS.TAG_KEY: {
      if (initialFormValues.tagKey) setStep(STEPS.TAG_VALUE);

      return (
        <ResponseContainer>
          Please enter the tag&apos;s key
          <InputWithExtras
            value={tagEntryForm.tagKey}
            onChange={({ target }) => {
              setTagEntryForm((prev) => ({ ...prev, tagKey: target.value }));
            }}
            className='mt-2'
            placeholder='Enter tag key'
            onEnter={() => setStep(STEPS.TAG_VALUE)}
          />
          <div className='mt-4 flex justify-end'>
            <Button label='Confirm' onClick={() => setStep(STEPS.TAG_VALUE)} disabled={!tagEntryForm.tagKey} />
          </div>
        </ResponseContainer>
      );
    }
    case STEPS.TAG_VALUE: {
      if (initialFormValues.tagValue) setStep(STEPS.CONFIRMATION);

      return (
        <ResponseContainer>
          Please enter the tag&apos;s value
          <InputWithExtras
            value={tagEntryForm.tagValue}
            onChange={({ target }) => {
              setTagEntryForm((prev) => ({ ...prev, tagValue: target.value }));
            }}
            className='mt-2'
            placeholder='Enter tag value'
            onEnter={() => setStep(STEPS.CONFIRMATION)}
          />
          <div className='mt-4 flex justify-end'>
            <Button label='Confirm' onClick={() => setStep(STEPS.CONFIRMATION)} disabled={!tagEntryForm.tagValue} />
          </div>
        </ResponseContainer>
      );
    }
    case STEPS.CONFIRMATION: {
      if (isCreating) {
        return (
          <div className='text-center'>
            <LoadingSpinner />
          </div>
        );
      }

      if (data) {
        const { entry } = data.data.tag;

        return (
          <ResponseContainer>
            <DetailsCard
              number={index + 1}
              title={entry.key}
              subtitle={entry.value}
              onClick={() => {
                router.push(`${getHost()}/configure/tags`);
                setGptChatResponses([]);
                query.toggle();
              }}
            />
          </ResponseContainer>
        );
      }

      if (isEditing) {
        return (
          <ResponseContainer className='flex gap-y-2 flex-col'>
            <div>
              <InputLabel heading='Tag key' />
              <InputWithExtras
                value={tagEntryForm.tagKey}
                onChange={({ target }) => {
                  setTagEntryForm((prev) => ({ ...prev, tagKey: target.value }));
                }}
                className='mt-2'
                placeholder='Enter tag key'
              />
            </div>
            <div>
              <InputLabel heading='Tag value' />
              <InputWithExtras
                value={tagEntryForm.tagValue}
                onChange={({ target }) => {
                  setTagEntryForm((prev) => ({ ...prev, tagValue: target.value }));
                }}
                className='mt-2'
                placeholder='Enter tag value'
              />
            </div>
            <div className='flex justify-end gap-x-2'>
              <Button label='Confirm' onClick={() => setIsEditing(false)} />
            </div>
          </ResponseContainer>
        );
      }

      return (
        <ResponseContainer>
          <DetailsItem itemId='' value={tagEntryForm.tagKey} tag='Tag key' />
          <DetailsItem value={tagEntryForm.tagValue} tag='Tag value' />
          <div className='flex justify-end gap-x-2'>
            <Button
              label='Edit'
              leadingIcon={<MdEdit size={20} />}
              onClick={() => setIsEditing(true)}
              emphasis='medium'
            />
            <Button
              label='Confirm'
              onClick={async () => {
                await createTagAsync({
                  entry: {
                    key: tagEntryForm.tagKey,
                    value: tagEntryForm.tagValue,
                    organizationId,
                  },
                  usageCount: 0,
                  status: 'unused',
                  createdBy: userId,
                  lastUpdatedBy: userId,
                  organizationId,
                } as any);
              }}
            />
          </div>
        </ResponseContainer>
      );
    }
  }
};
