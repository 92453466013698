import clsx from 'clsx';
import { classNames as cn } from '../utils';
import { TwoLineNumberProps } from './types';

export function TwoLineNumber({
  label,
  bottomText,
  state = 'default',
  textAlign = 'text-right',
  withoutPaddingLeft = false,
  labelClassNames,
  bottomTextClassNames,
  containerClassNames,
}: TwoLineNumberProps) {
  return (
    <div
      className={cn(
        clsx(
          'group relative overflow-hidden w-full min-w-max',
          'px-6 flex items-center',
          'hover:cursor-pointer',
          { 'bg-indigo-50': state === 'selected' },
          { 'border-b-2 bg-indigo-100': state === 'active' },
          { 'text-center justify-center': textAlign === 'text-center' },
          { 'text-left justify-start': textAlign === 'text-left' },
          { 'text-right justify-end': textAlign === 'text-right' },
          { '!pl-0': withoutPaddingLeft },
          containerClassNames,
        ),
      )}
    >
      <div className='flex-shrink-0'>
        <p className={cn('text-base text-zinc-900 leading-6', labelClassNames)}>{label}</p>
        <p className={cn('text-base text-zinc-600 leading-6', bottomTextClassNames)}>{bottomText}</p>
      </div>
    </div>
  );
}
