import React from 'react';
import ConditionItem from '../ConditionItem';
import { setSaveData } from '../../../rules/utils';
import { InputWithExtras } from 'ui';
import { MdSearch } from 'react-icons/md';
import ConditionItemsWrapper from '../ConditionItemsWrapper';
import { ConditionMenusProps } from './types';

export const RecentsCondition = ({ ruleSetData, getRuleSetData, isFirstLevel }: ConditionMenusProps) => {
  return (
    <ConditionItem label='Recents'>
      <div className='my-2 absolute'>
        <InputWithExtras type='text' placeholder='Search...' leading={<MdSearch className='w-5 h-5' />} />
      </div>
      <ConditionItemsWrapper className='mt-[70px]'>
        <ConditionItem
          onClick={() => setSaveData('Nest Condition', undefined, isFirstLevel, ruleSetData, getRuleSetData)}
          label='Nest Condition'
        />
      </ConditionItemsWrapper>
    </ConditionItem>
  );
};

export default RecentsCondition;
