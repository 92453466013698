import React from 'react';
import { DescriptiveLoader } from 'ui';
import { useSyncedBgJobTrackerProps } from './useSyncedBgJobTrackerProps';

const TrackJobInSidebar = ({ jobName, referenceId, bgJobState, setBgJobState, error }) => {
  const { title, description, status } = useSyncedBgJobTrackerProps({
    jobName,
    referenceId,
    bgJobState,
    setBgJobState,
    error,
  });

  // console.log('TrackJobInSidebar', { title, description, status });

  return (
    <div className='h-[300px] w-full flex items-center justify-center'>
      <DescriptiveLoader title={title} description={description} status={status} />
    </div>
  );
};

export default TrackJobInSidebar;
