import { useEffect, useMemo, useRef, useState } from 'react';
import {
  Button,
  CheckboxListItem,
  Dropdown,
  DropdownBody,
  DropdownContent,
  DropdownTrigger,
  DropdownFooter,
  DropdownGroupBody,
  DropdownHeader,
  Toggle,
  capitalizeFirstLetter,
  classNames,
  getFilterDropdownSectionDataCy,
} from 'ui';
import { FILTER_TYPE, FilterDropdownRowProps, StringFilter } from '../types';
import { MdArrowDropDown } from 'react-icons/md';
import { IoMdCloseCircle } from 'react-icons/io';
const DEFAULT_TRIGGER_LABEL = 'Impaired';
export const ImpairedFilterDropdown = ({
  state,
  onSelectAll,
  onClearAll,
  onSingleRowSelectChange,
  onOnlySingleRowSelect,
  'data-cy': dataCy,
}: FilterDropdownRowProps<StringFilter>) => {
  const [open, setOpen] = useState(false);
  const triggerRef = useRef<HTMLButtonElement>(null);

  const [triggerLabel, setTriggerLabel] = useState(DEFAULT_TRIGGER_LABEL);
  const [isHoveringClearAllTrailingIcon, setIsHoveringClearAllTrailingIcon] = useState(false);
  const [isHoveringTrigger, setIsHoveringTrigger] = useState(false);

  useEffect(() => {
    setTriggerLabel(() => {
      const selectedIds = state.ids.filter((id) => state.entities[id].selected);
      if (selectedIds.length === 0) return DEFAULT_TRIGGER_LABEL;
      if (selectedIds.length === 1) return `${state.entities[selectedIds[0]].value}`;
      return `${state.entities[selectedIds[0]].value} +${selectedIds.length - 1}`;
    });
  }, [state]);

  const impairedFilterDataCy = useMemo(() => `${dataCy}_${FILTER_TYPE.IMPAIRED}`, [dataCy]);

  return (
    <Dropdown data-cy={impairedFilterDataCy} open={open} onOpenChange={setOpen}>
      <DropdownTrigger ref={triggerRef}>
        <span onMouseEnter={() => setIsHoveringTrigger(true)} onMouseLeave={() => setIsHoveringTrigger(false)}>
          <Button
            data-cy={getFilterDropdownSectionDataCy(impairedFilterDataCy).trigger}
            isFocused={open}
            isLoading={state.isLoading}
            onClick={() => setOpen((prev) => !prev)}
            label={
              triggerLabel === DEFAULT_TRIGGER_LABEL ? (
                triggerLabel
              ) : (
                <span className='text-indigo-600'>{capitalizeFirstLetter(triggerLabel)}</span>
              )
            }
            className={classNames(
              'duration-100 py-0',

              triggerLabel !== DEFAULT_TRIGGER_LABEL &&
                isHoveringTrigger &&
                !isHoveringClearAllTrailingIcon &&
                'shadow',
              triggerLabel !== DEFAULT_TRIGGER_LABEL && 'bg-indigo-50 border-indigo-600',
            )}
            labelContainerClassname={classNames(
              'font-medium ',
              triggerLabel !== DEFAULT_TRIGGER_LABEL && 'text-indigo-600',
            )}
            emphasis='medium'
            trailingIconContainerClassname={classNames(
              triggerLabel !== DEFAULT_TRIGGER_LABEL && 'mr-0 p-2 duration-100 bg-indigo-50 text-blue-500 rounded-r-lg',
              triggerLabel !== DEFAULT_TRIGGER_LABEL && isHoveringTrigger && 'bg-indigo-50',
            )}
            trailingIcon={
              <div className='flex items-center'>
                {triggerLabel !== DEFAULT_TRIGGER_LABEL && (
                  <span
                    onMouseEnter={() => setIsHoveringClearAllTrailingIcon(true)}
                    onMouseLeave={() => setIsHoveringClearAllTrailingIcon(false)}
                    onClick={(e) => {
                      e.stopPropagation();
                      onClearAll();
                    }}
                  >
                    <IoMdCloseCircle className='w-6 h-6 text-zinc-500' />
                  </span>
                )}

                <MdArrowDropDown className={classNames('duration-300 w-6 h-6 text-black', open && 'rotate-180')} />
              </div>
            }
          />
        </span>
      </DropdownTrigger>
      <DropdownContent triggerRef={triggerRef}>
        <DropdownHeader title='Impaired' data-cy={getFilterDropdownSectionDataCy(impairedFilterDataCy).header} />
        <DropdownBody data-cy={getFilterDropdownSectionDataCy(impairedFilterDataCy).body}>
          {state?.ids.length === 0 && <div className='p-4 text-center'>No status to show</div>}
          {state?.ids.length !== 0 && (
            <>
              <div className='p-4 w-full flex items-center justify-between gap-x-3'>
                <span className='select-none font-semibold'>Select all</span>
                <Toggle
                  checked={state.ids.length === state.ids.filter((id) => state.entities[id].selected).length}
                  onChange={(checked: boolean) => (checked ? onSelectAll() : onClearAll())}
                />
              </div>
              <DropdownGroupBody>
                {state?.ids.map((rowId) => (
                  <CheckboxListItem
                    key={rowId}
                    label={state.entities[rowId].value}
                    checked={state.entities[rowId].selected}
                    onCheckedChange={(selected: boolean) => onSingleRowSelectChange(rowId, selected)}
                    selectOnlyFn={() => onOnlySingleRowSelect(rowId)}
                  />
                ))}
              </DropdownGroupBody>
            </>
          )}
        </DropdownBody>

        <DropdownFooter>
          <Button
            data-cy={getFilterDropdownSectionDataCy(impairedFilterDataCy).clearAllButton}
            onClick={state?.ids.length !== 0 ? onClearAll : undefined}
            emphasis='low'
            label='Clear'
            labelContainerClassname='text-zinc-500'
          />
        </DropdownFooter>
      </DropdownContent>
    </Dropdown>
  );
};
