import { CheckboxListItem, DropdownGroup, DropdownGroupBody, DropdownGroupTitle } from 'ui';

export const StatusFilterRowGroup = ({ state, onSingleChange, onClearAll }) => {
  if (!state) return null;
  return (
    <DropdownGroup>
      <DropdownGroupTitle title='Status' />
      <DropdownGroupBody>
        {state.ids.length > 1 ? (
          state.ids
            .map((_id) => state.entities[_id])
            .map((status) => (
              <CheckboxListItem
                key={status._id}
                label={status.value}
                isStatusLabel
                checked={status.selected}
                onCheckedChange={(selected: boolean) => onSingleChange({ ...status, selected })}
                selectOnlyFn={() => {
                  onClearAll();
                  onSingleChange({ ...status, selected: true });
                }}
              />
            ))
        ) : (
          <p className='text-zinc-900 text-sm px-2'>No status to show.</p>
        )}
      </DropdownGroupBody>
    </DropdownGroup>
  );
};
