import React, { useContext, useEffect, useState } from 'react';
import { AccountPostingRulesHeader } from '../../constants/tableHeaders';
import {
  useBulkDuplicateAccountPostingRules,
  useBulkRemoveAccountPostingRules,
  useDeleteAccountPostingRuleSet,
  useLegalEntities,
  usePatchAccountPostingRuleSet,
} from '../../hooks/http';
import {
  Button,
  CheckboxListItem,
  Dropdown,
  DropdownContent,
  DropdownGroupBody,
  DropdownTitleWithToggle,
  DropdownTrigger,
  InputLabel,
  InputWithExtras,
  classNames,
} from 'ui';
import { SidebarBody, SidebarFooter } from '../atoms/Sidebar';
import { SidebarSection } from '../atoms/Sidebar/SidebarBody/SidebarSection';
import { SidebarSectionHeader } from '../atoms/Sidebar/SidebarBody/SidebarSectionHeader';
import SidebarHeader from '../atoms/Sidebar/SidebarHeader/SidebarHeader';
import { Table } from '../dashboard';
import {
  deriveError,
  getDisplayedLegalEntities,
  getInputTagDefaultValue,
  getObjectIdsFromInstances,
  mergeLegalEntities,
} from '../templates/utils';
import { useSession } from '../../hooks/useSession';
import { toast } from 'react-hot-toast';
import { SidebarGlobalContext } from '../../context/SidebarGlobalProvider';
import { useInvalidateQuery } from '../../hooks';
import { Tooltip } from '../atoms';
import { MoveRulesModal } from './MoveRulesModal';
import { TABLE_TYPE } from '../../context';
import { MdArrowDropDown } from 'react-icons/md';
import { IoMdCloseCircle } from 'react-icons/io';
import { getCheckedCheckboxListItem } from 'global-utils';

/**
 * used for editing or creating a ruleset
 */
export const EditRulesetMultiSelectActionsComponent = ({
  selectedRows,
  selectedAccountPostingRuleSet,
  showCopyRulesModal,
  setShowCopyRulesModal,
}) => {
  console.log(selectedRows);
  console.log(selectedAccountPostingRuleSet);
  console.log(showCopyRulesModal);
  console.log(setShowCopyRulesModal);
  const { mutateAsync: duplicate, isLoading: isCreatingDuplicates } = useBulkDuplicateAccountPostingRules();

  const { mutateAsync: remove, isLoading: isRemovingRules } = useBulkRemoveAccountPostingRules();

  const { invalidateAccountPostingRulesets } = useInvalidateQuery();

  const onDuplicate = async () => {
    if (isCreatingDuplicates) return;
    const toastId = toast.loading(`Creating duplicate ${selectedRows.length === 1 ? 'rule' : 'rules'}`);
    try {
      const ruleset = selectedAccountPostingRuleSet._id;
      const rules = selectedRows.map((row) => row.original._id);

      await duplicate({ ruleset, rules });
      toast.success(`Duplicate ${rules.length === 1 ? 'rule' : 'rules'} created`, { id: toastId });

      await invalidateAccountPostingRulesets();
    } catch (error) {
      toast.error(deriveError(error), { id: toastId });
    }
  };

  const onRemove = async () => {
    if (isRemovingRules) return;
    const toastId = toast.loading('Removing rules');
    try {
      const ruleset = selectedAccountPostingRuleSet._id;
      const rules = selectedRows.map((row) => row.original._id);

      await remove({ ruleset, rules });
      toast.success('Rules removed', { id: toastId });

      await invalidateAccountPostingRulesets();
    } catch (error) {
      toast.error(deriveError(error), { id: toastId });
    }
  };

  return (
    <div className='flex items-center gap-x-2'>
      <MoveRulesModal
        open={showCopyRulesModal}
        onOpenChange={setShowCopyRulesModal}
        sourceRulesetId={selectedAccountPostingRuleSet._id}
        selectedRuleIds={selectedRows.map((row) => row.original._id)}
      />
      <Tooltip content={`Duplicate ${selectedRows.length} ${selectedRows.length === 1 ? 'rule' : 'rules'} in ruleset`}>
        <Button label={`Duplicate`} onClick={onDuplicate} variant='sm' emphasis='medium' />
      </Tooltip>

      <Tooltip content={`Remove ${selectedRows.length} ${selectedRows.length === 1 ? 'rule' : 'rules'} in ruleset`}>
        <Button label={`Remove`} onClick={onRemove} variant='sm' emphasis='medium' />
      </Tooltip>
    </div>
  );
};

export function EditRuleSet({
  onClose,
  selectedAccountPostingRuleSet,
  onCancel,
  setSelectedAccountPostingRule,
  setShowCreateAccountPostingRule,
}) {
  const { organizationId, userId } = useSession();
  const [accountPostingRules, setAccountPostingRules] = useState([]);
  const [displayedAccountPostingRules, setDisplayedAccountPostingRules] = useState([]);
  const [formData, setFormData] = useState({});
  const [loading] = useState(false);
  const { data: legalEntities } = useLegalEntities();
  const [sortedAccountPostingRuleIds, setSortedAccountPostingRuleIds] = useState([]);

  useEffect(() => {
    if (selectedAccountPostingRuleSet)
      setSortedAccountPostingRuleIds(selectedAccountPostingRuleSet.accountPostingRuleIds.map((rule) => rule._id));
  }, [selectedAccountPostingRuleSet]);

  const { closeSidebar } = useContext(SidebarGlobalContext);

  const [displayedLegalEntities, setDisplayedLegalEntities] = useState(
    getDisplayedLegalEntities(mergeLegalEntities(legalEntities)),
  );

  useEffect(() => {
    setDisplayedLegalEntities(getDisplayedLegalEntities(mergeLegalEntities(legalEntities)));
  }, [legalEntities]);

  const { mutateAsync: editAsync, isLoading: isUpdating } = usePatchAccountPostingRuleSet();
  const { invalidateAccountPostingRulesets } = useInvalidateQuery();
  const [draftRules] = useState([]);
  const { mutateAsync: deleteRuleSetAsync, isLoading: isDeleting } = useDeleteAccountPostingRuleSet();
  useEffect(() => {
    if (selectedAccountPostingRuleSet) {
      const _legalEntities = getObjectIdsFromInstances(selectedAccountPostingRuleSet.legalEntities);

      const defaultValues = getInputTagDefaultValue(
        selectedAccountPostingRuleSet?.legalEntities,
        displayedLegalEntities,
      );
      setFormData({ ...selectedAccountPostingRuleSet, _legalEntities, legalEntities: defaultValues });
      setAccountPostingRules(selectedAccountPostingRuleSet.accountPostingRuleIds);
      setDisplayedAccountPostingRules(
        selectedAccountPostingRuleSet.accountPostingRuleIds.map((rule) => ({ ...rule, name: { title: rule.name } })),
      );
    }
  }, [selectedAccountPostingRuleSet, displayedLegalEntities]);

  const [showCopyRulesModal, setShowCopyRulesModal] = useState(false);

  const triggerRef = React.useRef(null);

  const dataCy = 'editRuleset';

  return (
    <>
      <SidebarHeader data-cy={dataCy} title='Edit rule set' />
      <SidebarBody>
        <SidebarSectionHeader title='Details' />
        <SidebarSection numberOfColumns={1}>
          <div>
            <InputLabel heading='Rule set name' />
            <InputWithExtras
              data-testid='rulesetName__input'
              value={formData.name}
              onChange={(e) => {
                setFormData({ ...formData, name: e.target.value });
              }}
            />
          </div>
          <div>
            <InputLabel heading='Legal entities' />

            <Dropdown>
              <DropdownTrigger
                ref={triggerRef}
                className='w-full [&:has(data-[state=open]).trigger-chevron]:rotate-180'
              >
                <span>
                  <Button
                    label={
                      formData.legalEntities?.length === 0 ? (
                        'Select legal entities'
                      ) : (
                        <p>
                          {formData.legalEntities?.at(0)?.label + ' '}
                          <span>{formData.legalEntities?.length > 1 ? formData.legalEntities?.length + '+' : ''}</span>
                        </p>
                      )
                    }
                    className={classNames('duration-100 py-0 w-full justify-between')}
                    labelContainerClassname={classNames('font-medium')}
                    emphasis='medium'
                    trailingIconContainerClassname={classNames(
                      formData.legalEntities?.length > 0 && 'mr-0 p-2 duration-100 rounded-r-lg',
                    )}
                    trailingIcon={
                      <div className='flex items-center'>
                        {formData.legalEntities?.length > 0 && (
                          <span
                            onClick={(e) => {
                              e.stopPropagation();
                              setFormData({ ...formData, legalEntities: [] });
                            }}
                          >
                            <IoMdCloseCircle className='w-6 h-6 text-zinc-500' />
                          </span>
                        )}

                        <MdArrowDropDown className={classNames('duration-300 w-6 h-6 text-black trigger-chevron')} />
                      </div>
                    }
                  />
                </span>
              </DropdownTrigger>
              <DropdownContent triggerRef={triggerRef} sameWidthAsTrigger className='z-sidepanel'>
                <DropdownTitleWithToggle
                  title='Select all'
                  checked={formData.legalEntities?.length === displayedLegalEntities.length}
                  onChange={(checked) => {
                    if (checked) {
                      setFormData({ ...formData, legalEntities: displayedLegalEntities });
                    } else {
                      setFormData({ ...formData, legalEntities: [] });
                    }
                  }}
                />
                <DropdownGroupBody>
                  {displayedLegalEntities.map((legalEntity) => {
                    return (
                      <CheckboxListItem
                        key={legalEntity.value}
                        label={legalEntity.label}
                        checked={getCheckedCheckboxListItem(formData.legalEntities, legalEntity)}
                        onCheckedChange={(selected) => {
                          if (selected) {
                            if (formData.legalEntities?.length === 0) {
                              setFormData({ ...formData, legalEntities: [legalEntity] });
                            } else {
                              setFormData({
                                ...formData,
                                legalEntities: [...(formData.legalEntities ?? []), legalEntity],
                              });
                            }
                          } else {
                            setFormData({
                              ...formData,
                              legalEntities: formData.legalEntities?.filter((le) => le !== legalEntity),
                            });
                          }
                        }}
                      />
                    );
                  })}
                </DropdownGroupBody>
              </DropdownContent>
            </Dropdown>
          </div>
        </SidebarSection>
        <SidebarSectionHeader
          title='Posting rules'
          actions={[
            {
              label: 'Add rule',
              onClick: () => {
                closeSidebar('secondary');
                setSelectedAccountPostingRule(null);
                setShowCreateAccountPostingRule(true);
              },
              variant: 'tertiary',
            },
          ]}
        />
        <SidebarSection>
          <Table
            isSidePanel
            tableData={{
              Data: accountPostingRules.length ? displayedAccountPostingRules : draftRules,
            }}
            tableHeader={AccountPostingRulesHeader}
            onRowClick={(row) => {
              closeSidebar('secondary');
              setShowCreateAccountPostingRule(false);
              setSelectedAccountPostingRule({ ...row.original, name: row.original.name.title });
            }}
            tableType={TABLE_TYPE.EDIT_ACCOUNT_POSTING_RULESET}
            multiSelectActionBarProps={{
              showCopyRulesModal,
              setShowCopyRulesModal,
              selectedAccountPostingRuleSet,
            }}
            isSortable
            onSortOrderChange={(rules) => setSortedAccountPostingRuleIds(rules.map((rule) => rule._id))}
            sortableWidth={1220}
            hideCheckboxes
            enableColumnPinning={false}
          />
        </SidebarSection>
      </SidebarBody>
      <SidebarFooter
        primaryBtn={
          <Button
            label='Update'
            emphasis='high'
            onClick={async () => {
              try {
                const payload = {
                  ...formData,
                  legalEntities: formData.legalEntities?.map((le) => le.value),
                  userId,
                  organizationId,
                  accountPostingRuleIds: sortedAccountPostingRuleIds,
                };
                await editAsync(payload, {
                  onSuccess: () => invalidateAccountPostingRulesets(),
                });
                toast.success('Your ruleset has been updated');
              } catch (error) {
                console.error(error);
                toast.deriveError(error);
              }
            }}
            disabled={loading || !formData?.legalEntities?.length || !formData?.name?.length}
            isLoading={isUpdating}
          />
        }
        secondaryBtn={
          <Button label='Cancel' emphasis='medium' onClick={onCancel ? onCancel : onClose} disabled={loading} />
        }
        destructiveBtn={
          <Button
            data-cy={`${dataCy}__deleteButton`}
            label='Delete'
            onClick={async () => {
              try {
                const payload = {
                  _id: selectedAccountPostingRuleSet?._id,
                  organizationId,
                };
                await deleteRuleSetAsync(payload, {
                  onSuccess: () => {
                    invalidateAccountPostingRulesets();
                    onClose();
                    toast.success('Your ruleset has been deleted.');
                  },
                  onError: (error) => {
                    console.error(error);
                    toast.error(deriveError(error));
                  },
                });
              } catch (error) {
                console.error(error);
                toast.error(deriveError(error));
              }
            }}
            status='danger'
            emphasis='medium'
            disabled={loading}
            isLoading={isDeleting}
          />
        }
      />
    </>
  );
}
