import React, { useContext } from 'react';
import { SidebarHeader } from '../../../atoms';
import { formatDate } from 'services';
import { handlePatchTransactionExternalSource } from './utils';
import toast from 'react-hot-toast';
import {
  useAddExternalSource,
  useInvalidateQuery,
  useMarkTransactionsAsSpam,
  useSession,
  useTabState,
} from '../../../../hooks';
import { getHost } from '../../../../lib/utils';
import { deriveError } from '../../../templates/utils';
import { SidebarGlobalContext } from '../../../../context';

const TransactionSidebarHeader = ({ transaction, isLoading, transactionId, isPrimary, onExportAsCsv, dataCy }) => {
  const { secondRouteStack, updateTabSidebarState } = useTabState();
  const { invalidateTransactions, invalidateWallets, invalidateTransactionById } = useInvalidateQuery();
  const { mutateAsync: patchExternalSource, isLoading: isLoadingExternalSource } = useAddExternalSource();

  const { mutateAsync: markTransactionsAsSpam } = useMarkTransactionsAsSpam();
  const isSpam = transaction?.isSpam;
  const spamLabel = isSpam ? 'Remove from spam' : 'Mark as spam';

  const { closeSidebar } = useContext(SidebarGlobalContext);
  const { userId } = useSession();

  return (
    <SidebarHeader
      data-cy={dataCy}
      title={transaction?.sequenceNumber}
      loading={isLoading}
      subtitles={[transaction?.createdAt ? `Created ${formatDate(new Date(transaction?.createdAt))}` : '']}
      link={`${getHost()}/ledger/transactions/${transactionId}`}
      status={{
        label: transaction?.transactionDirection,
        type: 'neutral',
        emphasis: 'high',
      }}
      actions={[
        {
          label: 'Create journal entry',
          onClick: () => {
            if (isPrimary) {
              closeSidebar('secondary');
            }
            const updatedTabQuery = {
              secondRouteUnStack: {
                showCreateJournal: true,
                journalEntryFormData: {},
              },
              secondRouteStack: isPrimary ? [] : secondRouteStack,
              secondRouteDockPanel: false,
            };
            updateTabSidebarState(updatedTabQuery);
          },
          variant: 'primary',
        },
        {
          label: 'Add External Source',
          variant: 'tertiary',
          onClick: () =>
            handlePatchTransactionExternalSource({
              transaction,
              patchExternalSource,
              userId,
              invalidateTransactions,
              invalidateWallets,
            }),
          isLoading: isLoadingExternalSource,
        },
        {
          label: 'Download',
          onClick: onExportAsCsv,
          variant: 'tertiary',
        },
        {
          label: spamLabel,
          onClick: async () => {
            const commonPayload = {
              transactionIds: [transaction._id],
            };
            try {
              if (isSpam) {
                await markTransactionsAsSpam(commonPayload);
                toast.success(transaction.sequenceNumber + ' removed from spam');
              } else {
                await markTransactionsAsSpam(commonPayload);
                await closeSidebar('primary');
                toast.success(transaction.sequenceNumber + ' marked as spam');
              }
              await invalidateTransactionById({ transactionId: transaction._id });
              await invalidateTransactions();
            } catch (error) {
              toast.error(deriveError(error));
            }
          },
          variant: 'tertiary',
        },
      ]}
    />
  );
};

export default TransactionSidebarHeader;
