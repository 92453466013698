import { classNames } from 'ui';
import { ResponseContainerProps } from './types';

const ResponseContainer = ({ children, isQuery, className }: ResponseContainerProps) => {
  return isQuery ? (
    <div className='flex justify-end'>
      <div
        className={classNames(
          'py-2 px-3 border rounded-md bg-white border-[#e4e4e7] font-medium shadow-[0px_2px_4px_0px_rgba(0,_0,_0,_0.05)] w-fit',
          isQuery && 'bg-indigo-500 border-indigo-600 text-white',
          className,
        )}
      >
        {children}
      </div>
    </div>
  ) : (
    <div
      className={classNames(
        'py-2 px-3 border rounded-md bg-white border-[#e4e4e7] font-medium shadow-[0px_2px_4px_0px_rgba(0,_0,_0,_0.05)] w-fit',
        isQuery && 'bg-indigo-500 border-indigo-600 text-white',
        className,
      )}
    >
      {children}
    </div>
  );
};

const ChatLoader = () => {
  return (
    <ResponseContainer className='mt-4 h-10 flex flex-col justify-center'>
      <div className='flex items-center gap-1 '>
        <span className='w-2.5 h-2.5 bg-indigo-600 rounded-full animate-[dot1_3s_infinite_ease-in-out]'></span>
        <span className='w-2.5 h-2.5 bg-[#A5B4FC] rounded-full animate-[dot2_3s_infinite_ease-in-out]'></span>
        <span className='w-2.5 h-2.5 bg-[#C7D2FE] rounded-full animate-[dot3_3s_infinite_ease-in-out]'></span>
      </div>
    </ResponseContainer>
  );
};

export { ResponseContainer, ChatLoader };
