import { useMutation } from '@tanstack/react-query';
import { useSession } from '../useSession';
import {
  AddTokenToSpamListPayload,
  RemoveTokenFromSpamListPayload,
  addTokenToSpamList,
  markTransactionsAsSpam,
  removeTokenFromSpamList,
  MarkTransactionAsSpamPayload,
} from 'services/http/configuration-service/token-spam-list';

export const useAddTokenToSpamList = () => {
  const { organizationId } = useSession();
  return useMutation(async (body: Omit<AddTokenToSpamListPayload, 'organizationId'>) => {
    return await addTokenToSpamList({ ...body, organizationId });
  });
};

export const useRemoveTokenFromSpamList = () => {
  return useMutation(async (body: RemoveTokenFromSpamListPayload) => {
    return await removeTokenFromSpamList({ ...body });
  });
};

export const useMarkTransactionsAsSpam = () => {
  return useMutation(async (body: MarkTransactionAsSpamPayload) => {
    return await markTransactionsAsSpam({ ...body });
  });
};
