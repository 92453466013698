import { useState } from 'react';
import { MdArrowDropDown } from 'react-icons/md';
import { Dropdown, DropdownContent, DropdownTrigger } from '../dropdown';
import { Button } from '../button';
import { ChartExportDropdownProps } from './types';
import { classNames } from '../utils';

export const ChartExportDropdown = ({ onSvgExport, onPngExport }: ChartExportDropdownProps) => {
  const [open, setOpen] = useState(false);
  return (
    <Dropdown open={open} onOpenChange={setOpen}>
      <DropdownTrigger asChild>
        <Button
          label='Export'
          emphasis='medium'
          className='ignore-export'
          trailingIcon={
            <MdArrowDropDown className={classNames('duration-300 w-6 h-6 text-black', open && 'rotate-180')} />
          }
        />
      </DropdownTrigger>
      <DropdownContent
        align='end'
        withoutPortal
        className='grid grid-cols-1 gap-1 p-1 min-w-[95px] w-28 ignore-export !z-commandmenu'
      >
        <Button
          emphasis='low'
          className='font-normal justify-start hover:!bg-indigo-50'
          label='SVG'
          onClick={onSvgExport}
          disableRingOnFocus
        />
        <Button
          emphasis='low'
          label='PNG'
          className='font-normal justify-start hover:!bg-indigo-50'
          onClick={onPngExport}
          disableRingOnFocus
        />
      </DropdownContent>
    </Dropdown>
  );
};
