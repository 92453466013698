import { useState } from 'react';
import { useDeleteSource, useGetSourceById, usePatchSource } from '../../hooks/http';
import { deriveError } from '../templates/utils';
import { SidebarBody, SidebarSection, SidebarSectionHeader } from '../atoms/Sidebar/SidebarBody';
import { Button } from 'ui';
import { toast } from 'react-hot-toast';
import { useSession } from '../../hooks/useSession';
import { WALLET_CHAIN, WALLET_STATUS, WALLET_TYPE } from 'services/http/response.types';
import SidebarHeader from '../atoms/Sidebar/SidebarHeader/SidebarHeader';
import { ConfirmationModal } from '../onboarding/ConfirmationModal';
import { SidebarFooter } from '../atoms/Sidebar';
import { useInvalidateQuery } from '../../hooks';
import { PatchSourceFormElements, PatchSourceFormState } from '../form-elements';

const DEFAULT_FORM_STATE = {
  walletType: WALLET_TYPE.INTERNAL,
  name: '',
  address: '',
  chain: WALLET_CHAIN.ETHEREUM,
  status: WALLET_STATUS.ACTIVE,
  addresses: [],
  tags: [],
};

export const PatchSourceSidebarContent = ({ walletId, onCancel, onDelete, defaultFormState }) => {
  const { organizationId } = useSession();
  const [formState, setFormState] = useState<PatchSourceFormState>(defaultFormState ?? DEFAULT_FORM_STATE);

  const { isLoading: isLoadingWallet } = useGetSourceById(walletId);

  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);

  const { mutateAsync: deleteSource, isLoading: isDeletingWallet } = useDeleteSource();
  const { mutateAsync: patchSource, isLoading: isPatchingWallet } = usePatchSource();

  const { invalidateWallets } = useInvalidateQuery();

  const onPatchClick = async () => {
    try {
      if (!formState.legalEntityId && formState.walletType === 'internal')
        return toast.error('Please select a legal entity for wallet');
      await patchSource({
        wallet: {
          ...formState,
          legalEntityId: formState.legalEntityId as string,
        },
        _id: walletId,
      });
      toast.success('Wallet updated successfully');
      onCancel();
      await invalidateWallets();
    } catch (error) {
      console.log('PatchSourceForm error: ', error);
      toast.error(deriveError(error));
    }
  };

  const onDeleteClick = async () => {
    try {
      await deleteSource({ organizationId, walletId });
      toast.success('Wallet deleted successfully');
      setShowDeleteConfirmationModal(false);
      onDelete();
      await invalidateWallets();
    } catch (error) {
      console.log('PatchSourceForm error on delete: ', error);
      setShowDeleteConfirmationModal(false);
      toast.error(deriveError(error));
    }
  };

  return (
    <>
      <SidebarHeader title={'Update Source'} />
      <SidebarBody>
        <SidebarSectionHeader title='Details' />
        <SidebarSection loading={isLoadingWallet} numberOfColumns={1}>
          <PatchSourceFormElements
            formState={formState}
            defaultFormState={defaultFormState}
            setFormState={setFormState}
            sourceId={walletId}
          />
        </SidebarSection>

        {showDeleteConfirmationModal && (
          <ConfirmationModal
            title='Are you sure you want to delete this wallet?'
            warningText='Delete wallet'
            onDelete={onDeleteClick}
            onClose={() => setShowDeleteConfirmationModal(false)}
            isDeleting={isDeletingWallet}
          />
        )}
      </SidebarBody>
      <SidebarFooter
        destructiveBtn={
          <Button
            onClick={() => setShowDeleteConfirmationModal(true)}
            emphasis='medium'
            label='Delete'
            status='danger'
            data-cy='updateSource__deleteButton'
          />
        }
        primaryBtn={<Button onClick={onPatchClick} isLoading={isPatchingWallet} label={'Update'} />}
        secondaryBtn={<Button onClick={onCancel} emphasis='medium' label='Cancel' />}
      />
    </>
  );
};
