import { createContext } from 'react';
import { LedgerAccount, LegalEntity, Wallet } from 'schemas';
import { useAllLedgerAccounts, useAllTags, useAllWallets, useAssetTypes, useLegalEntities } from '../hooks';
import { mergeLegalEntities } from '../components/templates/utils';
import { containsUrl } from '../components/utils';
import { Tag } from 'services/http/response.types';

export type UserObjectsContextType = {
  assetTypes: string[];
  legalEntities: LegalEntity[];
  wallets: Wallet[];
  ledgerAccounts: LedgerAccount[];
  tags: Tag[];
};

export const UserObjectsContext = createContext<UserObjectsContextType>({
  assetTypes: [],
  legalEntities: [],
  wallets: [],
  ledgerAccounts: [],
  tags: [],
});

export const UserObjectsProvider = ({ children }) => {
  const { assetTypes = [] } = useAssetTypes();
  const { data: paginatedLegalEntities } = useLegalEntities();
  const { wallets } = useAllWallets() as unknown as { wallets: Wallet[] };
  const { ledgerAccounts } = useAllLedgerAccounts() as unknown as { ledgerAccounts: LedgerAccount[] };
  const { tags } = useAllTags();

  // a quick spam filter for asset types (Spam tokens usually have URLS in the name)
  const filteredSpamAssetTypes = assetTypes.filter((assetType) => !containsUrl(assetType));

  // needed to cast to LegalEntity object from schemas as mergeLegalEntities() casts return to LegalEntity under services/http/response.types
  const legalEntities = mergeLegalEntities(paginatedLegalEntities) as unknown as LegalEntity[];

  return (
    <UserObjectsContext.Provider
      value={{ assetTypes: filteredSpamAssetTypes, legalEntities, wallets, ledgerAccounts, tags }}
    >
      {children}
    </UserObjectsContext.Provider>
  );
};
