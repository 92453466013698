import React from 'react';
import { MdSearch } from 'react-icons/md';
import { LoaderIcon } from '../loader-icon';
import { ClearInputTrailingAction } from '../clear-input-trailing-action';
import { InputWithExtras, useProbablyControlledStateForInput } from '../input-with-extras';
import { SearchInputProps } from './types';
import { classNames } from '../utils';

export const SearchInput = ({ resultCount, loading, ...props }: SearchInputProps) => {
  const { inputProps } = useProbablyControlledStateForInput({ value: props.value, onChange: props.onChange });

  return (
    <div className='flex items-center gap-3'>
      <InputWithExtras
        {...props}
        {...inputProps}
        leading={loading ? <LoaderIcon /> : <MdSearch className='w-5 h-5 text-zinc-500 ml-1' />}
        trailing={
          <ClearInputTrailingAction
            value={inputProps.value}
            setValue={inputProps.onChange}
            disabled={props.disabled}
            className=''
          />
        }
        className={classNames(
          'rounded-lg text-zinc-500 items-center leading-5 text-base placeholder:-translate-y-0.5 shadow-[0px_2px_4px_0px_rgba(0,_0,_0,_0.05)]',
          props.className,
        )}
      />
      {!loading && resultCount && (
        <p className='text-base text-zinc-600 hidden md:block mt-2 -translate-y-1'>
          {resultCount} {resultCount === 1 ? 'item' : 'items'}
        </p>
      )}
    </div>
  );
};
