import React, { useContext, useState } from 'react';
import { Table, TableWrapper } from '../../dashboard';
import { SearchInput } from 'ui';
import { EditColumns } from '../../atoms/filter';
import { TableHeaderProps } from '../../dashboard/types';
import { AssetHeadersData } from '../../../constants/tableHeaders';
import { SidebarGlobalContext } from '../../../context/SidebarGlobalProvider';
import { mapTableKeyToOriginalKey } from '../../utils';

export const AssetsTable = ({
  displayedAssets,
  searchTerm,
  setSearchTerm,
  isSearching,
  setSortState,
  setShowCreateAsset,
  fetchNextPage,
  isLoading,
  isFetchingNextPage,
}) => {
  const [tableHeader, setTableHeader] = useState<TableHeaderProps>(AssetHeadersData);
  const [hasTableStateChanged, setHasTableStateChanged] = useState(false);
  const { openSidebar } = useContext(SidebarGlobalContext);

  return (
    <TableWrapper>
      <Table
        data-cy='assets'
        tableTopActions={
          <div className='flex items-center justify-between gap-3 px-3 py-2'>
            <div className='flex items-center gap-3'>
              <SearchInput
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                loading={isSearching}
                placeholder='Search assets...'
                className='placeholder:text-zinc-500 '
                containerClassName=' min-w-0 '
                resultCount={displayedAssets?.length}
              />
            </div>
            <div className='flex gap-3'>
              <EditColumns
                tableHeader={AssetHeadersData}
                tableHeaderState={tableHeader}
                hasTableStateChanged={hasTableStateChanged}
                getFilteredTableHeader={(filteredTableHeader) => {
                  setTableHeader(filteredTableHeader);
                }}
              />
            </div>
          </div>
        }
        tableData={{
          Data: displayedAssets || [],
        }}
        useInViewCallback={() => {
          fetchNextPage();
        }}
        onRowClick={(row) => {
          setShowCreateAsset(false);
          openSidebar('assets', { id: row.original._id });
        }}
        tableHeader={tableHeader}
        isLoading={isLoading}
        isFetchingNextPage={isFetchingNextPage}
        onSortingChange={(state) => {
          if (state.length === 0) {
            setSortState([]);
            return;
          }
          setSortState([{ id: mapTableKeyToOriginalKey(state[0].id, 'Assets'), desc: state[0].desc }]);
        }}
        canHideColumns
        setHasTableStateChanged={setHasTableStateChanged}
        getFilteredTableHeader={(filteredTableHeader) => {
          setTableHeader(filteredTableHeader);
        }}
      />
    </TableWrapper>
  );
};

export default AssetsTable;
