import React, { useState } from 'react';
import {
  Button,
  DESCRIPTIVE_LOADER_STATES,
  DescriptiveLoader,
  Dialog,
  DialogContent,
  DialogTrigger,
  InputLabel,
  SimpleMenu,
} from 'ui';
import { deriveError } from '../../templates/utils';
import { useInvalidateQuery, useBulkRecategorizeTransactions } from '../../../hooks';
import { Tooltip } from '../../atoms';
import { toast } from 'react-hot-toast';
import { CLASSIFICATION_ROW } from '../../filters';

export const TransactionRecategorizationModal = ({ transactionIds, showTrigger = true, onSuccess }) => {
  const [open, onOpenChange] = useState(false);
  const { invalidateTransactions } = useInvalidateQuery();

  const [selectedOption, setSelectedOption] = useState<{ label: string; value: string }>();

  const { mutateAsync: bulkCategorizeTransactions, isLoading } = useBulkRecategorizeTransactions();
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      {showTrigger && (
        <DialogTrigger className='mx-4 my-0 py-0 px-0' asChild>
          <Tooltip content={`Move`}>
            <Button
              className='h-full mr-4'
              onClick={() => onOpenChange(true)}
              label={`Recategorize`}
              emphasis='medium'
              variant='sm'
            />
          </Tooltip>
        </DialogTrigger>
      )}

      <DialogContent
        actions={[
          {
            label: 'Confirm',
            onClick: async () => {
              try {
                await bulkCategorizeTransactions({
                  transactionIds,
                  classification: selectedOption?.value || '',
                });
                onOpenChange(false);
                toast.success('success');
                onSuccess();
                await invalidateTransactions();
              } catch (error) {
                toast.error(deriveError(error));
              }
            },
            disabled: isLoading,
            isLoading: isLoading,
          },
          {
            label: 'Cancel',
            emphasis: 'medium',
            onClick: () => {
              onOpenChange(false);
              setSelectedOption(undefined);
            },
          },
        ]}
      >
        <div>
          <InputLabel heading='Categories' />
          <SimpleMenu
            options={Object.keys(CLASSIFICATION_ROW).map((key) => ({
              label: CLASSIFICATION_ROW[key],
              value: key,
            }))}
            onChange={(option) => setSelectedOption(option)}
            value={selectedOption}
          />
        </div>
      </DialogContent>

      {false && (
        <DialogContent onClose={() => onOpenChange(false)}>
          <DescriptiveLoader
            status={DESCRIPTIVE_LOADER_STATES.LOADING}
            title='In progress'
            description={`Moving selected`}
          />
        </DialogContent>
      )}
    </Dialog>
  );
};
