import toast from 'react-hot-toast';
import { useBulkDeleteJournalEntryTemplateMutation, useInvalidateQuery } from '../../hooks';
import { deriveError } from '../templates/utils';
import { Button } from 'ui';

export function JETemplatesMultiSelectActionsComponent({ selectedRows, table }) {
  const { invalidateJournalEntryTemplates, invalidateLineTemplates } = useInvalidateQuery();
  const selectedIds = selectedRows?.map((item) => item?.original?._id);
  const { mutateAsync: bulkDeleteJournalEntryLineTemplate, isLoading: isBulkDeleting } =
    useBulkDeleteJournalEntryTemplateMutation();

  const handleBulkDelete = async () => {
    try {
      await bulkDeleteJournalEntryLineTemplate(
        {
          templateIds: selectedIds,
        },
        {
          onSuccess: () => {
            table.resetRowSelection();
            toast.success('Journal entry templates deleted');
            invalidateJournalEntryTemplates();
            invalidateLineTemplates();
          },
          onError: (error) => {
            toast.error(deriveError(error));
          },
        },
      );
    } catch (error) {
      toast.error(deriveError(error));
      console.error(error);
    }
  };
  return (
    <div className='flex ml-auto'>
      <Button label='Delete' emphasis='medium' onClick={handleBulkDelete} isLoading={isBulkDeleting} />
    </div>
  );
}
