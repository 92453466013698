// author: ABD

import React from 'react';

interface LoadingComponentProps {
  mainItemClassName?: string;
  secondaryItemClassName?: string;
}

export const LoadingComponent = ({ mainItemClassName = '', secondaryItemClassName = '' }: LoadingComponentProps) => {
  return (
    <div className='grid gap-y-4 gap-x-2 animate-pulse'>
      <div>
        <div
          className={`h-4 w-[160px] bg-gray-200 mb-4 bg-gradient-to-r from-gray-300 to-gray-100 ${mainItemClassName}`}
        />
        <div
          className={`h-4 w-[264px] bg-gray-200 bg-gradient-to-r from-gray-300 to-gray-100 ${secondaryItemClassName}`}
        />
      </div>
    </div>
  );
};

export default LoadingComponent;
