import React, { useState, useMemo, useEffect } from 'react';
import countryList from 'react-select-country-list';
import { useLocalStorage } from 'usehooks-ts';

import { DetailsItem } from '../atoms';
import EntityItem from './entity-item';
import { AddLegalEntityPlus } from '../../assets/generated/svg';
import {
  useLegalEntities,
  useCreateLegalEntity,
  useDeleteLegalEntity,
  usePatchLegalEntity,
} from '../../hooks/http/useLegalEntities';
import { UNITED_STATES, ENTITY_TYPES, DEFAULT_ONBOARDING_DATA } from '../../constants';
import { ConfirmationModal } from './ConfirmationModal';
import { changeRouteName, deriveError } from '../templates/utils';
import { SidebarSection } from '../atoms/Sidebar/SidebarBody/SidebarSection';
import { SidebarBody, SidebarFooter } from '../atoms/Sidebar';
import SidebarHeader from '../atoms/Sidebar/SidebarHeader/SidebarHeader';
import { navigate } from '../utils';
import {
  InputLabel,
  InputWithExtras,
  TextareaInput,
  CurrencyMenu,
  Button,
  currencyOptions,
  SingleSelectMenu,
} from 'ui';
import { getHost } from '../../lib/utils';
import CurrencyFlag from 'react-currency-flags';
import { SidebarSectionHeader } from '../atoms/Sidebar/SidebarBody';
import { useSession } from '../../hooks/useSession';
import { toast } from 'react-hot-toast';
import { useInvalidateQuery } from '../../hooks';

const AddLegalEntityForm = ({
  isOnboarding = true,
  selectedLegalEntity = false,
  editMode = false,
  onClose = () => {
    null;
  },
  isLoading = false,
  hideHeader = false,
  onCancel = () => {
    null;
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  onSuccess = (legalEntity) => {
    null;
  },
}) => {
  const { invalidateLegalEntities } = useInvalidateQuery();
  const [idToDelete, setIdToDelete] = useState(null);
  const [read, setRead] = useState(editMode);

  const getLegalEntity = useLegalEntities();
  const { mutateAsync: createAsync, isLoading: isCreating } = useCreateLegalEntity();
  const { mutateAsync: deleteAsync, isLoading: isDeleting } = useDeleteLegalEntity();
  const { mutateAsync: patchAsync, isLoading: isUpdating } = usePatchLegalEntity();

  const { organizationId, userId } = useSession();

  const [legalEntity, setLegalEntity] = useState({
    userId: '',
    organizationId,
    entityName: '',
    entityType: '',
    address: {
      line1: '',
      line2: '',
      city: '',
      state: '',
      country: '',
    },
    currency: 'USD',
  });
  const options = useMemo(() => countryList().getData(), []);
  const [data, setData] = useLocalStorage('onboarding', DEFAULT_ONBOARDING_DATA);

  useEffect(() => {
    // first check if api is work after that we save the api data on localStorage (onboarding.legal_entities)
    if (!getLegalEntity.isLoading) {
      const saveLegalEntities = getLegalEntity?.data?.pages?.map((legalEntities) => legalEntities) ?? [];
      setData({ ...data, legal_entities: saveLegalEntities[0] });
    }
  }, [getLegalEntity.data]);

  useEffect(() => {
    setLegalEntity({ ...legalEntity, ...selectedLegalEntity });
  }, [selectedLegalEntity]);

  changeRouteName({ name: legalEntity?.entityName, UUID: legalEntity._id, isLoading: getLegalEntity.isLoading });
  const handleDeleteLegalEntity = async () => {
    try {
      const _legalEntity = data?.legal_entities?.find((_, idx) => idx === idToDelete);
      await deleteAsync(
        { legalEntityId: _legalEntity?._id || idToDelete, organizationId },
        {
          onSuccess: () => {
            toast.success('Your legal entity has been successfully deleted.');

            getLegalEntity.refetch();
            setIdToDelete(null);
            onClose();
          },
        },
      );
    } catch (err) {
      console.error(err);
      setIdToDelete(null);
      toast.error(deriveError(err));
    }
  };

  const handleAddLegalEntity = async () => {
    try {
      const newLegalEntity = {
        ...legalEntity,
        address: {
          ...legalEntity.address,
        },
        userId,
        organizationId,
      };
      await createAsync(
        { legalEntity: newLegalEntity, organizationId },
        {
          onSuccess: async (res) => {
            onSuccess(res.data.legalEntity);
            invalidateLegalEntities();
            toast.success('Your legal entity has been successfully added.');

            setLegalEntity({
              userId: '',
              entityName: '',
              entityType: '',
              address: {
                line1: '',
                city: '',
                country: '',
                state: '',
                zip: '',
              },
              currency: '',
            });
            onClose();
          },
        },
      );
    } catch (error) {
      console.log(error.response || error.message);
      toast.error(deriveError(error));
    }
  };
  const addLegalEntiy = async (e) => {
    if (!selectedLegalEntity) {
      await handleAddLegalEntity(e, {
        ...legalEntity,
        organizationId,
        userId,
      });
    } else {
      await handleEditEntity(e, {
        ...legalEntity,
        organizationId,
        userId,
      });
    }
  };

  const handleEditEntity = async () => {
    try {
      await patchAsync(
        {
          legalEntity,
          organizationId: organizationId,
        },
        {
          onSuccess: async () => {
            invalidateLegalEntities(0);
            navigate('/configure/entities');
          },
        },
      );
      toast.success('Legal entity successfull updated');

      setRead(true);
    } catch (error) {
      console.log(error.response || error.message);
      toast.error(deriveError(error));
    }
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    } else {
      setRead(true);
    }
  };

  const renderHeading = () => {
    if (isOnboarding) return 'Legal entities';
    else return legalEntity?.entityName && !isOnboarding ? legalEntity?.entityName : 'Add legal entity';
  };

  const getCombinedAddress = (_legalEntity) => {
    const addressArray = [
      _legalEntity.address.line1,
      _legalEntity.address.city,
      _legalEntity.address.state,
      _legalEntity.address.zip,
      _legalEntity.address.country,
    ];
    const addressString = addressArray.filter((address) => address).join(', ');
    return addressString;
  };

  const ViewModeView = (
    <>
      <DetailsItem tag='Business name' value={legalEntity.entityName} />
      <DetailsItem tag='Business type' value={legalEntity.entityType} />
      <DetailsItem
        tag='Currency'
        value={legalEntity.currency}
        leadingComponent={<CurrencyFlag currency={legalEntity.currency} />}
      />
      <DetailsItem tag='Business address' value={getCombinedAddress(legalEntity)} />
    </>
  );

  const EditModeView = (
    <>
      <div>
        <InputLabel heading='Business name' />
        <InputWithExtras
          value={legalEntity.entityName}
          onChange={({ target }) => {
            setLegalEntity({ ...legalEntity, entityName: target.value });
          }}
          placeholder='Enter business name'
        />
      </div>
      <div>
        <InputLabel heading='Business type' />
        <SingleSelectMenu
          isOnSidepanel
          fullWidth
          isModal
          options={ENTITY_TYPES}
          placeholder='Select business type'
          onChange={({ value }) => {
            setLegalEntity({
              ...legalEntity,
              entityType: value,
            });
          }}
          value={ENTITY_TYPES.find((item) => item.value === legalEntity.entityType)}
          onClearValue={() => {
            setLegalEntity({
              ...legalEntity,
              entityType: '',
            });
          }}
        />
      </div>
      <div>
        <InputLabel heading='Currency' />
        <CurrencyMenu
          onChange={({ value }) => {
            setLegalEntity({ ...legalEntity, currency: value });
          }}
          value={currencyOptions.find((item) => item.value === legalEntity.currency)}
          options={currencyOptions}
        />
      </div>
      <div>
        <InputLabel heading='Business address' />
        <TextareaInput
          value={legalEntity.address.line1}
          onChange={({ target }) => {
            setLegalEntity({
              ...legalEntity,
              address: { ...legalEntity.address, line1: target.value },
            });
          }}
        />
      </div>
      <div>
        <InputLabel heading='Country' />

        <SingleSelectMenu
          isOnSidepanel
          fullWidth
          enableSearch
          isModal
          options={options}
          placeholder='Select country'
          onChange={({ value }) => {
            setLegalEntity({
              ...legalEntity,
              address: { ...legalEntity.address, country: value },
            });
          }}
          value={options.find((item) => item.value === legalEntity.address.country)}
          onClearValue={() => {
            setLegalEntity({
              ...legalEntity,
              address: { ...legalEntity.address, country: '' },
            });
          }}
        />
      </div>

      {legalEntity.address.country === 'US' && (
        <div>
          <InputLabel heading='State' />
          <SingleSelectMenu
            isModal
            fullWidth
            options={UNITED_STATES}
            placeholder='Select state'
            onChange={({ value }) => {
              setLegalEntity({
                ...legalEntity,
                address: { ...legalEntity.address, state: value },
              });
            }}
            value={UNITED_STATES.find((item) => item.value === legalEntity.address.state)}
          />
        </div>
      )}
      <div>
        <InputLabel heading='City' />
        <InputWithExtras
          value={legalEntity.address.city}
          onChange={({ target }) => {
            setLegalEntity({
              ...legalEntity,
              address: { ...legalEntity.address, city: target.value },
            });
          }}
          placeholder='Enter city'
        />
      </div>
      <div>
        <InputLabel heading='Zip' />
        <InputWithExtras
          value={legalEntity.address.zip}
          onChange={({ target }) => {
            setLegalEntity({
              ...legalEntity,
              address: { ...legalEntity.address, zip: target.value },
            });
          }}
          placeholder='Enter zip'
        />
      </div>
    </>
  );
  return (
    <>
      {!isOnboarding && !hideHeader ? (
        <SidebarHeader
          loading={isLoading}
          title={renderHeading()}
          link={`${getHost()}/configure/entities/${legalEntity?._id}`}
          actions={
            read
              ? [
                  {
                    label: 'Edit',
                    onClick: () => setRead(false),
                    variant: 'primary',
                  },
                ]
              : []
          }
        />
      ) : null}
      <SidebarBody>
        {isOnboarding && (
          <SidebarSection numberOfColumns={1} loading={isLoading}>
            {data.legal_entities?.map(({ entityName, entityType, _id }, index) => (
              <EntityItem
                key={_id}
                onDelete={() => setIdToDelete(index)}
                id={_id}
                name={entityName}
                type={entityType}
              />
            ))}
          </SidebarSection>
        )}
        <SidebarSectionHeader title='Details' />
        <SidebarSection numberOfColumns={!read ? 1 : 2} loading={isLoading}>
          {!read ? EditModeView : ViewModeView}
        </SidebarSection>
        {isOnboarding && (
          <SidebarSection numberOfColumns={1} loading={isLoading}>
            <Button
              leadingIcon={<AddLegalEntityPlus width='32px' height='32px' style={{ display: 'inline-block' }} />}
              label={<span className='font-medium text-xl'>{selectedLegalEntity ? 'Edit' : 'Add'} entity</span>}
              isLoading={isCreating}
              onClick={(e) => addLegalEntiy(e)}
              emphasis='medium'
            ></Button>
          </SidebarSection>
        )}
      </SidebarBody>
      {idToDelete !== null && (
        <ConfirmationModal
          title='Are you sure you want to delete this wallet address?'
          warningText='Delete legal entity'
          onDelete={({ current }) => handleDeleteLegalEntity(current)}
          onClose={() => setIdToDelete(null)}
          isDeleting={isDeleting}
        />
      )}
      {!read && !isOnboarding ? (
        <SidebarFooter
          destructiveBtn={
            editMode && (
              <Button
                onClick={() => {
                  setIdToDelete(selectedLegalEntity?._id);
                }}
                emphasis='medium'
                label='Delete'
                status='danger'
              />
            )
          }
          primaryBtn={
            <Button
              onClick={(e) => addLegalEntiy(e)}
              isLoading={isCreating | isUpdating}
              label={editMode ? 'Update' : 'Save'}
            />
          }
          secondaryBtn={<Button onClick={handleCancel} emphasis='medium' label='Cancel' />}
        />
      ) : null}
    </>
  );
};

export default AddLegalEntityForm;
