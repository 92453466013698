import React, { useContext, useEffect, useState } from 'react';
import { GoTriangleRight } from 'react-icons/go';
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  createColumnHelper,
  getSortedRowModel,
  getExpandedRowModel,
  SortingState,
  ExpandedState,
} from '@tanstack/react-table';
import StyledTable from '../../styles/Tables';
import { TableSkeletonLoader, Tooltip } from '../atoms';
import {
  DefaultCell,
  LeadingCellExpandable,
  classNames,
  DefaultTableHeader,
  SingleLineNumber,
  IndeterminateCheckbox,
} from 'ui';
import { capitalizeFirstLetter } from '../Nav/utils';
import { SidebarGlobalContext } from '../../context/SidebarGlobalProvider';
import clsx from 'clsx';
import { LedgerAccount } from 'schemas';

export type ResequencingTableProps = {
  data: (LedgerAccount & { oldSequence?: number; newSequence?: number })[];
  isLoading?: boolean;
  emptyMessage?: string;
  testId?: string;
  dataCy?: string;
  tableClasses?: string;
  onRowClick?: (row, cell) => void;
};

const tableHeader = [
  {
    header: 'Ledger account',
    key: 'ledgerAccountName',
    textAligned: 'text-left',
  },
  {
    header: 'Old seq #',
    key: 'oldSequence',
    textAligned: 'text-right',
  },
  {
    header: 'New seq #',
    key: 'newSequence',
    textAligned: 'text-right',
  },
];

export const ResequencingTable = ({
  data = [],
  isLoading = false,
  emptyMessage,
  testId,
  dataCy,
  tableClasses,
  onRowClick = () => {},
}: ResequencingTableProps) => {
  const columnHelper = createColumnHelper<any>();

  const { sidebarIds } = useContext(SidebarGlobalContext);

  const initialExpanded: ExpandedState = true;

  const hideCheckboxes = true;
  const disabledSorting = true;

  const defaultColumns = [
    ...tableHeader.map(({ header, key, textAligned }, index) => {
      const handleCells = (payload) => {
        const { getValue, row } = payload;

        const compare = (passedKey) => header === passedKey;

        if (compare('Type')) {
          return (
            <DefaultCell
              label={capitalizeFirstLetter(getValue().toLowerCase())}
              textAlign={textAligned}
              withoutPaddingLeft={index === 0}
            />
          );
        }

        if (compare('Ledger account')) {
          return (
            <LeadingCellExpandable
              label={capitalizeFirstLetter(getValue()?.toLowerCase())}
              isAvatarVisible={false}
              bottomText=''
              isCheckboxVisible={false}
              checked={row.getIsSelected()}
              indeterminate={row.getIsSomeSelected()}
              onChange={row.getToggleSelectedHandler()}
              expandFn={row.getToggleExpandedHandler()}
              textAlign='text-left'
              canExpand={false}
              depth={row.depth}
              withoutPaddingLeft={index === 0}
              labelClassNames={'!whitespace-nowrap !min-w-full'}
            />
          );
        }
        if (typeof getValue() === 'number') {
          return <SingleLineNumber textAlign={textAligned} label={getValue()} withoutPaddingLeft={index === 0} />;
        }
      };
      return columnHelper.accessor(key, {
        header,
        cell: (payload) => handleCells(payload),
        footer: (info) => info.column.id,
      });
    }),
  ];

  const [columns, setColumns] = useState(defaultColumns);

  useEffect(() => {
    setColumns([
      columnHelper.accessor('checkbox', {
        id: 'checkbox',
        header: ({ table }) => {
          return (
            <div className={`flex items-center ${!hideCheckboxes ? 'mr-3' : ''}`}>
              {table.getCanSomeRowsExpand() && (
                <Tooltip
                  removeTooltipContainerClassname
                  content={table.getIsAllRowsExpanded() ? 'Collapse all' : 'Expand all'}
                >
                  <GoTriangleRight
                    onClick={(e) => {
                      e.stopPropagation();
                      table.getToggleAllRowsExpandedHandler()(e);
                    }}
                    className={clsx(
                      'rounded-lg w-6 h-6 p-1 mr-3 text-zinc-800 transition-transform',
                      'hover:bg-zinc-200',
                      {
                        'rotate-90': table.getIsAllRowsExpanded(),
                      },
                    )}
                  />
                </Tooltip>
              )}
              {!hideCheckboxes && (
                <IndeterminateCheckbox
                  isHeader
                  checked={table.getIsAllRowsSelected()}
                  indeterminate={table.getIsSomeRowsSelected()}
                  onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
                />
              )}
            </div>
          );
        },
        cell: ({ row }) => (
          <div
            style={{
              transform: hideCheckboxes
                ? `translateX(calc(${row.depth * 2.25}rem + 1rem))`
                : `translateX(calc(${row.depth * 2.25}rem + 1.5rem))`,
            }}
            className='flex gap-3 items-center'
          >
            {row.getCanExpand() ? (
              <Tooltip content='Expand' removeTooltipContainerClassname>
                <GoTriangleRight
                  onClick={(e) => {
                    e.stopPropagation();
                    row.getToggleExpandedHandler()();
                  }}
                  className={clsx('rounded-lg w-6 h-6 p-1 text-zinc-800 transition-transform', 'hover:bg-zinc-200', {
                    'rotate-90': row.getIsExpanded(),
                  })}
                />
              </Tooltip>
            ) : table.getCanSomeRowsExpand() ? (
              <div className='opacity-0 pointer-events-none'>
                <Tooltip content='Expand' removeTooltipContainerClassname>
                  <GoTriangleRight
                    onClick={(e) => {
                      e.stopPropagation();
                      row.getToggleExpandedHandler()();
                    }}
                    className={clsx('rounded-lg w-6 h-6 p-1 text-zinc-800 transition-transform', 'hover:bg-zinc-200', {
                      'rotate-90': row.getIsExpanded(),
                    })}
                  />
                </Tooltip>
              </div>
            ) : null}
            {!hideCheckboxes && (
              <IndeterminateCheckbox
                checked={row.getIsSelected()}
                indeterminate={row.getIsSomeSelected()}
                onChange={row.getToggleSelectedHandler()}
              />
            )}
          </div>
        ),
        footer: (info) => info.column.id,
      }),
      ...defaultColumns,
    ]);
  }, []);

  const [sorting, setSorting] = useState<SortingState>([]);

  const [expanded, setExpanded] = useState<ExpandedState>(initialExpanded ?? {});
  const [rowSelection, setRowSelection] = React.useState({});

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      expanded,
      rowSelection,
    },
    defaultColumn: {
      minSize: 0,
    },
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.subRows,
    getExpandedRowModel: getExpandedRowModel(),
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onRowSelectionChange: setRowSelection,
    manualSorting: true,
  });

  return (
    <div
      className={classNames(
        'overflow-auto flex-auto mt-4 col-span-full rounded-xl',
        !data.length && !isLoading && 'flex',
        (data.length || isLoading) && 'border border-[#CBCBCB]',
      )}
    >
      {!data.length && !isLoading ? (
        <div className='w-full flex flex-col'>
          <div className='w-full flex flex-col gap-5 py-4 justify-center items-center flex-grow'>
            <p data-cy='table_emptyMessage' className='text-2xl font-medium'>
              {emptyMessage}
            </p>
          </div>
        </div>
      ) : (
        <>
          <StyledTable />
          <table data-testid={testId} className={classNames('w-fit', tableClasses)}>
            <thead data-cy={`${dataCy}__table_head`}>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header, idx) => {
                    const index = hideCheckboxes ? idx : idx - 1;
                    if (header.id === 'checkbox') {
                      return (
                        <th
                          key={idx}
                          {...{
                            colSpan: header.colSpan,
                            onClick: !disabledSorting ? header.column.getToggleSortingHandler() : () => {},
                          }}
                          className={`h-12 !p-0 ${
                            tableHeader[index]?.textAligned ? tableHeader[index].textAligned : 'text-center'
                          }
                          ${header.id === 'checkbox' && '!p-0 w-4 sticky fixedLeft !bg-[#F2F2F2] z-[5]'}
                          !border-b-zinc-200 !border-b 
                          `}
                        >
                          <div className={`flex ${table.getCanSomeRowsExpand() ? 'pl-4' : 'pl-6'}`}>
                            {flexRender(header.column.columnDef.header, header.getContext())}
                          </div>
                        </th>
                      );
                    }

                    return (
                      <th
                        key={idx}
                        {...{
                          colSpan: header.colSpan,
                        }}
                        className={`h-12 !p-0 ${
                          tableHeader[index - 1]?.textAligned ? tableHeader[index - 1].textAligned : 'text-center'
                        }
                        ${tableHeader[index - 1]?.key === 'newSequence' ? '!bg-indigo-200' : ''}
                          !border-b-zinc-200 !border-b 
                          `}
                      >
                        {header.id !== 'checkbox' && header.id !== 'viewBtn' && (
                          <DefaultTableHeader
                            isHeader
                            isCheckboxVisible={false}
                            sort={header.column.getIsSorted()}
                            label={flexRender(header.column.columnDef.header, header.getContext())?.toString() ?? ''}
                            checked={table.getIsAllRowsSelected()}
                            indeterminate={table.getIsSomeRowsSelected()}
                            onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
                            enableSort={!disabledSorting}
                            withoutPaddingLeft={
                              (index === 0 && !hideCheckboxes) ||
                              (index === 1 && table.getCanSomeRowsExpand()) ||
                              (index === 1 && hideCheckboxes)
                            }
                            sortFn={
                              !disabledSorting
                                ? (value: boolean | undefined) => header.column.toggleSorting(value)
                                : () => {}
                            }
                            textClassNames={tableHeader[index - 1]?.key === 'newSequence' ? 'text-black' : ''}
                            resetSorting={() => header.column.clearSorting()}
                            enableHide={false}
                            containerClassNames={`!bg-transparent`}
                            hideFn={() => {
                              //get the header from tableHeader by key
                              const headerToUpdate = tableHeader.findIndex((item) => item.key === header.id);

                              const newTableHeader = [...tableHeader];
                              newTableHeader.splice(headerToUpdate, 1);
                            }}
                          />
                        )}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody className={'z-[2]'} data-cy={`${dataCy}__table_body`}>
              {isLoading ? (
                <tr>
                  <td className='pt-0 px-0 pb-0 !bg-white overflow-hidden' colSpan={tableHeader.length + 2}>
                    <TableSkeletonLoader />
                  </td>
                </tr>
              ) : (
                <>
                  {table.getRowModel().rows.map((row) => {
                    let selectedRow = false;
                    if (row.original._id) {
                      selectedRow = sidebarIds.includes(row.original._id);
                    }

                    return (
                      <tr id={row.original._id} key={row.id} className=' group '>
                        {row.getVisibleCells().map((cell, idx) => {
                          const index = hideCheckboxes ? idx : idx - 1;
                          const newSequenceIndex = tableHeader.findIndex((item) => item.key === 'newSequence');
                          return (
                            <td
                              key={cell.id}
                              onClick={() => {
                                cell.row.getToggleExpandedHandler();
                                if (cell.column.columnDef.header !== 'Account' && onRowClick) {
                                  // setScrollPosition(window.scrollY);
                                  onRowClick(row, cell);
                                }
                              }}
                              data-cy={`${cell.column.id}__table_body_item`}
                              data-testid='tbody__td'
                              className={classNames(
                                clsx(
                                  'h-16 w-fit',
                                  { '!bg-indigo-50 !text-[#111111]': row.getIsSelected() },
                                  { '!bg-indigo-100 border-top-none border-b-2 border-b-zinc-300': selectedRow },
                                  {
                                    '!bg-indigo-100 before:!block border-b-2 border-b-zinc-300': selectedRow && !idx,
                                  },
                                  row?.original?.__className,
                                  { '!p-0 z-[1] !sticky min-w-[none]': cell.column.id === 'checkbox' },
                                  'left-0',
                                  { '!bg-indigo-50': newSequenceIndex === index - 1 },
                                ),
                              )}
                            >
                              <>{flexRender(cell.column.columnDef.cell, cell.getContext())}</>
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </>
              )}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};
