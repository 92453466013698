import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { styled } from '@stitches/react';

export const StyledItem = styled(DropdownMenu.Item, {
  all: 'unset',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '8px 12px',
  borderRadius: 8,
  transition: '300ms',
  marginBottom: '12px',
  '&[data-highlighted]': { backgroundColor: '#EEEEEE', cursor: 'pointer' },
});
