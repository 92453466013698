import React from 'react';
import { RxChevronRight as ChevronRightIcon } from 'react-icons/rx';
import { StyledSub, StyledSubContent, StyledSubTrigger } from '../../../styles/FilterNestedItemsStyle';
import { FilterNestedItemsProps } from './types';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

export function FilterNestedItems({ children, trigger = 'Trigger Me', open, onClick }: FilterNestedItemsProps) {
  return (
    <div>
      <StyledSub open={open}>
        <StyledSubTrigger onClick={onClick}>
          {trigger}
          <div className='RightSlot'>
            <ChevronRightIcon />
          </div>
        </StyledSubTrigger>
        <DropdownMenu.Portal>
          <StyledSubContent sideOffset={2} alignOffset={-5}>
            {children}
          </StyledSubContent>
        </DropdownMenu.Portal>
      </StyledSub>
    </div>
  );
}

export default FilterNestedItems;
