import React from 'react';
import { SCHEDULE_CADENCES } from '../../constants';
import { InputLabel, SingleSelectMenu } from 'ui';

export const ImpairmentScheduleDialogForm = ({ instance, cadence, onChange, isLoading }) => {
  const initialValue = SCHEDULE_CADENCES.find((item) => item.value === instance?.cronExpression);
  return (
    <div>
      <InputLabel heading='Cadence' />

      <SingleSelectMenu
        fullWidth
        isOnSidepanel
        isModal
        options={SCHEDULE_CADENCES}
        value={cadence}
        onChange={(option) => {
          onChange(option);
        }}
        isLoading={isLoading}
        placeholder='Select cadence'
        onClearValue={() => {
          onChange(initialValue);
        }}
        clearable={false}
      />
    </div>
  );
};
