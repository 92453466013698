import { DropDownSelect } from '../../../../atoms';
import { useRuleConfigurator } from '../../rule-configurator-context';
import { selectorStyle } from './selector-styles';
import { EQUAL_NOTEQUAL_OPERATORS, } from '../../data';
import { ConditionRow } from './ConditionRow';
import { FACT } from '../../types';
import { useCondition } from '../useCondition';
const exchanges = ['CIRCLE', 'KRAKEN', 'COINBASE PRIME']
const Row = () => {
    const { updateCondition, isDisabled } = useRuleConfigurator();
    const condition = useCondition();
    return (
        <>
            <DropDownSelect
                customStyle={selectorStyle}
                defaultValue={{ label: 'Exchange', value: 'Exchange' }}
                isDisabled
            />
            <DropDownSelect
                customStyle={selectorStyle}
                options={EQUAL_NOTEQUAL_OPERATORS}
                value={EQUAL_NOTEQUAL_OPERATORS.find((item) => item.value === condition.operator)}
                onChange={({ value }) => {
                    updateCondition(condition.id, {
                        operator: value,
                    });
                }}
                isDisabled={isDisabled}
            />
            <DropDownSelect
                customStyle={selectorStyle}
                isDisabled={isDisabled}
                options={exchanges.map((item) => ({ label: item, value: item }))}
                onChange={({ value }) => {
                    updateCondition(condition.id, {
                        value: value,
                    });
                }}
            />
        </>
    );
};

export const ExchangeRuleCondition = () => (
    <ConditionRow facts={[FACT.EXCHANGE]}>
        <Row />
    </ConditionRow>
);
