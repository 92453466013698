import * as Dropdown from '@radix-ui/react-dropdown-menu';
import { styled } from '@stitches/react';

export const StyledContent = styled(Dropdown.Content, {
  width: 250,
  padding: 8,
  marginRight: '1.5rem',
  borderRadius: 8,
  boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.15)',
  backgroundColor: 'White',
  zIndex: 50,
  maxHeight: 450,
  overflowY: 'auto',
});

export const StyledTrigger = styled(Dropdown.Trigger, {
  '&[data-state="open"] button': { borderColor: 'black' },
});

export const StyledItem = styled(Dropdown.Item, {
  all: 'unset',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '8px 12px',
  borderRadius: 8,
  transition: '300ms',
  '&[data-highlighted]': { backgroundColor: '#EEEEEE', cursor: 'pointer' },
});
