import React, { useEffect, useState } from 'react';
import {
  Button,
  Dropdown,
  DropdownContent,
  DropdownTitleWithToggle,
  DropdownTrigger,
  StyledCheckbox,
  StyledIndicator,
  classNames,
} from 'ui';
import { EditColumnsProps, SwitchDataProps } from './types';
import { MdArrowDropDown, MdTune } from 'react-icons/md';
import { RxCheck as CheckIcon } from 'react-icons/rx';
import { dataConvertToTabelHeader } from './utils';

const headerConverter = (data, checked?: boolean) => {
  if (typeof checked === 'undefined') {
    checked = true;
  }
  const convert = data?.map(({ header }, index) => {
    return { label: header, labelLeft: false, checked: checked, locked: false, id: `${index}`, type: 'scrolling' };
  });
  return convert;
};

export const EditColumns = ({
  tableHeader,
  tableHeaderState,
  hasTableStateChanged,
  getFilteredTableHeader,
}: EditColumnsProps) => {
  const [switchData, setSwitchData] = useState<SwitchDataProps>([]);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    // Check if tableHeader is an array
    if (Array.isArray(tableHeader)) {
      // Check if the table state has not changed
      if (isFirstRender) {
        // Convert the tableHeader into a reference format
        const tableHeaderReference = headerConverter(tableHeader, true);
        // Set the switch data state with the converted reference and return
        setSwitchData([...tableHeaderReference]);
        setIsFirstRender(false);
      } else {
        // Convert the tableHeader into a reference format (tableHeaderState might be a missing variable?)
        const tableHeaderReference = headerConverter(tableHeader, false);

        // Convert the current tableHeaderState (potentially missing variable) into a new format
        const newSwitchData = headerConverter(tableHeaderState);
        // Find missing objects from the reference tableHeader by label in the newSwitchData
        const missingObjectsFromFirstArray = tableHeaderReference.filter(
          (obj1) => !newSwitchData.find((obj2) => obj2.label === obj1.label),
        );

        // Concatenate the missing objects with the newSwitchData
        const mergedArray = missingObjectsFromFirstArray.concat(newSwitchData);

        // Set the switch data state with the merged array
        setSwitchData([...mergedArray]);
      }
    }
  }, [hasTableStateChanged]);

  useEffect(() => {
    if (Array.isArray(tableHeader)) {
      getFilteredTableHeader && getFilteredTableHeader(dataConvertToTabelHeader(switchData, tableHeader));
    }
  }, [switchData]);

  const toggleChecked = (label: string) => {
    setSwitchData((prevArray) => {
      return prevArray.map((item) => {
        if (item.label === label) {
          return { ...item, checked: !item.checked };
        }
        return item;
      });
    });
  };

  return (
    <Dropdown open={isOpen} onOpenChange={setIsOpen}>
      <DropdownTrigger asChild>
        <Button
          emphasis='medium'
          labelContainerClassname='font-medium text-base leading-5 text-zinc-900 mx-0 hidden md:block'
          isFocused={false}
          leadingIcon={<MdTune className='w-5 h-5  text-zinc-900' />}
          leadingIconContainerClassname='ml-0'
          trailingIconContainerClassname='mr-0 hidden  md:inline-flex'
          trailingIcon={
            <MdArrowDropDown
              className={classNames('w-6 h-6 md:mr-0 mr-0 text-zinc-900 transition-transform', isOpen && 'rotate-180')}
            />
          }
          label='Edit table'
          className='[&_.base-button-label]:hidden md:[&_.base-button-label]:inline-flex w-10 md:w-auto md:px-4 px-2  gap-2 !shadow-[0px_2px_4px_0px_rgba(0,_0,_0,_0.05)]'
        />
      </DropdownTrigger>

      <DropdownContent align='end' className='rounded-lg'>
        {tableHeader?.length && (
          <>
            <DropdownTitleWithToggle
              title='Select all'
              checked={(switchData && switchData.every((item) => item.checked)) ?? false}
              onChange={(checked: boolean) => {
                if (checked) {
                  setSwitchData((prevArray) => {
                    return prevArray.map((item) => {
                      return { ...item, checked: checked };
                    });
                  });
                } else {
                  setSwitchData((prevArray) => {
                    return prevArray.map((item) => {
                      return { ...item, checked: false };
                    });
                  });
                }
              }}
            />
            <div className='bg-white flex flex-col gap-1 p-1'>
              {switchData.map((switchItems) => (
                <button
                  key={switchItems.label}
                  onClick={() => toggleChecked(switchItems.label)}
                  className={classNames(
                    'hover:bg-indigo-50 rounded-md flex items-center py-2 px-3 bg-white',
                    switchItems.checked && 'bg-indigo-50',
                  )}
                >
                  <div className='flex gap-2 items-center w-full hover:cursor-pointer'>
                    <StyledCheckbox
                      id={switchItems.label}
                      checked={switchItems.checked}
                      className='hover:!border-zinc-700 data-[state=checked]:!bg-indigo-600 data-[state=checked]:!border-indigo-600'
                    >
                      <StyledIndicator>
                        <CheckIcon />
                      </StyledIndicator>
                    </StyledCheckbox>

                    <label
                      htmlFor={switchItems.label}
                      className='text-zinc-900 text-base leading-5 select-none hover:cursor-pointer'
                    >
                      {switchItems.label}
                    </label>
                  </div>
                </button>
              ))}
            </div>
          </>
        )}
      </DropdownContent>
    </Dropdown>
  );
};

export default EditColumns;
