import React from 'react';
import { FilterSectionProps } from './types';

export function FilterSection({ children, sectionName = 'Section' }: FilterSectionProps) {
  return (
    <div className='flex flex-col'>
      <p className='text-base font-medium px-3 my-7'>{sectionName}</p>
      {children}
    </div>
  );
}

export default FilterSection;
