import {
  ACTION_TYPE,
  AllFiltersAction,
  FILTER_TYPE,
  FilterHelpers,
  FilterRowState,
  FilterState,
  FilterTransforms,
  FilterValueTransformFn,
  HelpersForAllFilters,
  IAllFilters,
  StringFilter,
} from './filter-dropdowns';

import { AccountingPeriod, Asset, LegalEntity, Tag, Wallet } from 'services/http/response.types';
import { produce, castImmutable } from 'immer';
import { useMemo, useReducer } from 'react';
import { transformSelectedValuesIntoQueryParams } from './transforms';

const reducer = (state: IAllFilters, action: AllFiltersAction): IAllFilters => {
  switch (action.type) {
    case ACTION_TYPE.SET_ALL:
      return produce(state, (draft) => {
        draft[action.filterType] = {
          entities: action.payload.reduce((e, v) => {
            try {
              e[v._id] = v;
            } catch (error) {
              // console.log('useFilters:setAll:error:', error, v, e)
            }
            return e;
          }, {}),
          ids: action.payload.filter((v) => !!v).map((v) => v?._id),
        };
      });
    case ACTION_TYPE.SET_IS_LOADING:
      return produce(state, (draft) => {
        draft[action.filterType].isLoading = action.payload;
      });
    case ACTION_TYPE.SET_BY_ID:
      return produce(state, (draft) => {
        draft[action.filterType].entities[action.payload._id] = Object.assign(
          {},
          draft[action.filterType].entities[action.payload._id],
          action.payload,
        );
        if (!draft[action.filterType].ids.includes(action.payload._id))
          draft[action.filterType].ids.push(action.payload._id);
      });

    case ACTION_TYPE.SET_ONLY:
      return produce(state, (draft) => {
        draft[action.filterType].ids.forEach((id) => {
          draft[action.filterType].entities[id] = Object.assign({}, draft[action.filterType].entities[id], {
            selected: false,
          });
        });
        draft[action.filterType].entities[action.payload] = Object.assign(
          {},
          draft[action.filterType].entities[action.payload],
          { selected: true },
        );
      });
    case ACTION_TYPE.SET_SELECTED_BY_ID:
      return produce(state, (draft) => {
        // try to set selected only if the row exists
        if (draft[action.filterType].entities[action.payload.id])
          draft[action.filterType].entities[action.payload.id] = Object.assign(
            {},
            draft[action.filterType].entities[action.payload.id],
            { selected: !!action.payload.selected },
          );
      });

    case ACTION_TYPE.DESELECT_ALL:
      return produce(state, (draft) => {
        draft[action.filterType].ids.forEach((id) => {
          draft[action.filterType].entities[id] = Object.assign({}, draft[action.filterType].entities[id], {
            selected: false,
          });
        });
      });
    case ACTION_TYPE.SELECT_ALL:
      return produce(state, (draft) => {
        draft[action.filterType].ids.forEach((id) => {
          draft[action.filterType].entities[id] = Object.assign({}, draft[action.filterType].entities[id], {
            selected: true,
          });
        });
      });
    case ACTION_TYPE.CLEAR:
      return produce(state, (draft) => {
        draft[action.filterType] = {
          entities: {},
          ids: [],
          isLoading: false,
        };
      });
    default:
      return state;
  }
};

function createSetters<T>(
  filterType: FILTER_TYPE,
  dispatch: (args: {
    type: ACTION_TYPE;
    payload?: FilterRowState<T> | FilterRowState<T>[] | string | { id: string; selected: boolean } | boolean;
    filterType: FILTER_TYPE;
  }) => void,
): Omit<FilterHelpers<T>, 'getAllSelected' | 'getAllSelectedWithTransform'> {
  return {
    [ACTION_TYPE.SET_ALL]: (values: FilterRowState<T>[]) =>
      dispatch({ type: ACTION_TYPE.SET_ALL, payload: values, filterType }),
    [ACTION_TYPE.SET_BY_ID]: (value: FilterRowState<T>) =>
      dispatch({ type: ACTION_TYPE.SET_BY_ID, payload: value, filterType }),
    [ACTION_TYPE.SET_IS_LOADING]: (isLoading: boolean) =>
      dispatch({ type: ACTION_TYPE.SET_IS_LOADING, payload: isLoading, filterType }),
    [ACTION_TYPE.SET_ONLY]: (id: string) => dispatch({ type: ACTION_TYPE.SET_ONLY, payload: id, filterType }),
    [ACTION_TYPE.SET_SELECTED_BY_ID]: (id: string, selected: boolean) =>
      dispatch({ type: ACTION_TYPE.SET_SELECTED_BY_ID, payload: { id, selected }, filterType }),
    [ACTION_TYPE.SELECT_ALL]: () => dispatch({ type: ACTION_TYPE.SELECT_ALL, filterType }),
    [ACTION_TYPE.DESELECT_ALL]: () => dispatch({ type: ACTION_TYPE.DESELECT_ALL, filterType }),
    [ACTION_TYPE.CLEAR]: () => dispatch({ type: ACTION_TYPE.CLEAR, filterType }),
  };
}

function getAllSelected<T>(state?: FilterState<T>) {
  return state
    ? state.ids.reduce((filtered, id) => {
        if (state.entities[id].selected) filtered.push(state.entities[id]);
        return filtered;
      }, [] as T[])
    : [];
}

function createHelpers<T>(
  filterType: FILTER_TYPE,
  state: IAllFilters,
  dispatch: React.Dispatch<AllFiltersAction>,
  filterValueTransformFn: FilterValueTransformFn<T>,
): FilterHelpers<T> {
  return {
    ...createSetters<T>(filterType, dispatch),
    getAllSelected: () => getAllSelected(state[filterType]),
    getAllSelectedWithTransform: () => getAllSelected(state[filterType] as FilterState<T>).map(filterValueTransformFn),
  };
}

const createDefaultFilterState = (filterTypes: FILTER_TYPE[]): IAllFilters =>
  castImmutable(
    filterTypes.reduce((state, filterType) => {
      state[filterType] = {
        entities: {},
        ids: [],
        isLoading: false,
      };
      return state;
    }, {}),
  );

export const useFilterState = (filterTypes: FILTER_TYPE[]) =>
  useReducer(reducer, createDefaultFilterState(filterTypes));

export const useHelpersForFiltersState = (
  state: IAllFilters,
  dispatch: React.Dispatch<AllFiltersAction>,
  transforms: FilterTransforms = {},
): HelpersForAllFilters => {
  const helpers = useMemo(() => {
    const helperFns = {};
    const filterTransforms = Object.assign({}, transformSelectedValuesIntoQueryParams, transforms);
    if (state[FILTER_TYPE.ACCOUNTING_PERIOD])
      helperFns[FILTER_TYPE.ACCOUNTING_PERIOD] = createHelpers<AccountingPeriod>(
        FILTER_TYPE.ACCOUNTING_PERIOD,
        state,
        dispatch,
        filterTransforms[FILTER_TYPE.ACCOUNTING_PERIOD],
      );

    if (state[FILTER_TYPE.ACCOUNTING_TREATMENT])
      helperFns[FILTER_TYPE.ACCOUNTING_TREATMENT] = createHelpers<StringFilter>(
        FILTER_TYPE.ACCOUNTING_TREATMENT,
        state,
        dispatch,
        filterTransforms[FILTER_TYPE.ACCOUNTING_TREATMENT],
      );

    if (state[FILTER_TYPE.ASSET])
      helperFns[FILTER_TYPE.ASSET] = createHelpers<Asset>(
        FILTER_TYPE.ASSET,
        state,
        dispatch,
        filterTransforms[FILTER_TYPE.ASSET],
      );

    if (state[FILTER_TYPE.SPAM_TOKEN])
      helperFns[FILTER_TYPE.SPAM_TOKEN] = createHelpers<StringFilter>(
        FILTER_TYPE.SPAM_TOKEN,
        state,
        dispatch,
        filterTransforms[FILTER_TYPE.SPAM_TOKEN],
      );

    if (state[FILTER_TYPE.CHAIN])
      helperFns[FILTER_TYPE.CHAIN] = createHelpers<StringFilter>(
        FILTER_TYPE.CHAIN,
        state,
        dispatch,
        filterTransforms[FILTER_TYPE.CHAIN],
      );

    if (state[FILTER_TYPE.CLASSIFICATION])
      helperFns[FILTER_TYPE.CLASSIFICATION] = createHelpers<StringFilter>(
        FILTER_TYPE.CLASSIFICATION,
        state,
        dispatch,
        filterTransforms[FILTER_TYPE.CLASSIFICATION],
      );

    if (state[FILTER_TYPE.DIRECTION])
      helperFns[FILTER_TYPE.DIRECTION] = createHelpers<StringFilter>(
        FILTER_TYPE.DIRECTION,
        state,
        dispatch,
        filterTransforms[FILTER_TYPE.DIRECTION],
      );

    if (state[FILTER_TYPE.LEGAL_ENTITY])
      helperFns[FILTER_TYPE.LEGAL_ENTITY] = createHelpers<LegalEntity>(
        FILTER_TYPE.LEGAL_ENTITY,
        state,
        dispatch,
        filterTransforms[FILTER_TYPE.LEGAL_ENTITY],
      );

    if (state[FILTER_TYPE.TAG])
      helperFns[FILTER_TYPE.TAG] = createHelpers<Tag>(
        FILTER_TYPE.TAG,
        state,
        dispatch,
        filterTransforms[FILTER_TYPE.TAG],
      );

    if (state[FILTER_TYPE.ORIGINATED_BY])
      helperFns[FILTER_TYPE.ORIGINATED_BY] = createHelpers<StringFilter>(
        FILTER_TYPE.ORIGINATED_BY,
        state,
        dispatch,
        filterTransforms[FILTER_TYPE.ORIGINATED_BY],
      );

    if (state[FILTER_TYPE.STATUS])
      helperFns[FILTER_TYPE.STATUS] = createHelpers<StringFilter>(
        FILTER_TYPE.STATUS,
        state,
        dispatch,
        filterTransforms[FILTER_TYPE.STATUS],
      );

    if (state[FILTER_TYPE.IMPAIRED])
      helperFns[FILTER_TYPE.IMPAIRED] = createHelpers<StringFilter>(
        FILTER_TYPE.IMPAIRED,
        state,
        dispatch,
        filterTransforms[FILTER_TYPE.IMPAIRED],
      );

    if (state[FILTER_TYPE.WALLET])
      helperFns[FILTER_TYPE.WALLET] = createHelpers<Wallet>(
        FILTER_TYPE.WALLET,
        state,
        dispatch,
        filterTransforms[FILTER_TYPE.WALLET],
      );

    if (state[FILTER_TYPE.WALLET_TYPE])
      helperFns[FILTER_TYPE.WALLET_TYPE] = createHelpers<StringFilter>(
        FILTER_TYPE.WALLET_TYPE,
        state,
        dispatch,
        filterTransforms[FILTER_TYPE.WALLET_TYPE],
      );

    return helperFns;
  }, [state]);

  return helpers;
};

export const useFilters = (filterTypes: FILTER_TYPE[], transforms?: FilterTransforms) => {
  const [state, dispatch] = useFilterState(filterTypes);
  const helpers = useHelpersForFiltersState(state, dispatch, transforms);

  return { state, helpers, dispatch };
};
