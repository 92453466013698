import { Action, useKBar } from 'farish-kbar';
import { useEffect, useRef, useState } from 'react';
import { searchAlgolia } from '../../controllers/algolia';
import { formatAccountingPeriod, formatDate, formatDollars } from '../templates/utils';
import { CryptoIcon, navigate } from '../utils';
import {
  AnalyzeIntentActions,
  CreateIntentActions,
  DeleteIntentActions,
  EditIntentActions,
  KBarSections,
} from '../../constants';
import { getWalletNameToDisplay } from './utils';
import Results from './Results';
import { SearchBar } from './SearchBar';
import { useScrollLock } from '../../hooks/useScrollLock';
import { Footer } from './Footer';

import SecondView from './SecondView';
import { useCommandPallete } from './useCommandPallete';
import { useDebounce } from 'usehooks-ts';
import Suggestions from './Suggestions';
import { Intent } from '../../context';

function Container({ userAlgoliaKey }: { userAlgoliaKey: string }) {
  const [value, setValue] = useState('');
  const debouncedValue = useDebounce(value, 200);
  const { query } = useKBar();
  const { lockScroll, unlockScroll } = useScrollLock();

  const { secondViewPath, setSecondViewPath, gptChatResponses, setGptChatResponses, selectedIntent } =
    useCommandPallete();

  const unregisterActions = useRef(() => {});
  const unregisterSuggestions = useRef(() => {});

  const onSearch = async () => {
    unregisterActions.current();
    query.setSearch(debouncedValue);
    if (!userAlgoliaKey || !debouncedValue) return;
    const { transactions, journalEntries, accountPostingRuleSets, journalEntryTemplates, wallets } =
      (await searchAlgolia(debouncedValue, userAlgoliaKey)) || {};
    const actionsToRegister: Action[] = [];

    if (journalEntries && journalEntries.length > 0) {
      actionsToRegister.push(
        ...journalEntries.map((entry) => ({
          id: entry.objectID,
          name: entry.journalSequenceNumber,
          subtitles: [formatAccountingPeriod(new Date(entry.accountingPeriod.startDate))],
          section: KBarSections.journal,
          perform: () => navigate(`/ledger/journals/${entry.objectID}`),
        })),
      );
    }
    if (transactions && transactions.length > 0) {
      actionsToRegister.push(
        ...transactions.map((transaction) => ({
          id: transaction.objectID,
          name: transaction.operationalTxnId,
          subtitles: [transaction.chain, formatDollars(transaction.grossPrice)],
          icon: <CryptoIcon symbol={transaction.chain} />,
          section: KBarSections.transaction,
          keywords: transaction.transactionHash,
          perform: () => navigate(`/ledger/transactions/${transaction.objectID}`),
        })),
      );
    }

    if (accountPostingRuleSets && accountPostingRuleSets.length > 0) {
      actionsToRegister.push(
        ...accountPostingRuleSets.map((ruleset) => ({
          id: ruleset.objectID,
          name: ruleset.name,
          section: KBarSections.rulesets,
          subtitles: [`rules: ${ruleset.accountPostingRuleIds?.length ?? 0}`, formatDate(new Date(ruleset.createdAt))],
          perform: () => navigate(`/configure/rulesets/${ruleset.objectID}`),
        })),
      );
    }

    if (journalEntryTemplates && journalEntryTemplates.length > 0) {
      actionsToRegister.push(
        ...journalEntryTemplates.map((template) => ({
          id: template.objectID,
          name: template.name,
          section: KBarSections.templates,
          subtitles: [
            `lines: ${template.journalEntryLineTemplateIds?.length ?? 0}`,
            formatDate(new Date(template.createdAt)),
          ],
          perform: () => navigate(`/configure/templates/${template.objectID}`),
        })),
      );
    }

    if (wallets && wallets.length > 0) {
      actionsToRegister.push(
        ...wallets.map((wallet) => ({
          id: wallet.objectID,
          name: getWalletNameToDisplay(wallet, debouncedValue),
          section: KBarSections.wallets,
          subtitles: [wallet.chain, wallet.walletType],
          icon: <CryptoIcon symbol={wallet.chain} />,
          keywords: `wallet.address ${wallet.addresses?.join(' ')}`,
          perform: () => navigate(`/ledger/sources/${wallet.objectID}`),
        })),
      );
    }
    const unregister = query.registerActions(actionsToRegister);
    unregisterActions.current = unregister;
  };

  const onUpdateIntents = (intent: Intent | null) => {
    // unregister previous actions
    unregisterSuggestions.current();
    const lowerCasedIntent = intent?.toLowerCase();
    const actionsToRegister: Action[] = [];
    if (debouncedValue) return;
    if (lowerCasedIntent?.startsWith('edit')) {
      actionsToRegister.push(
        ...EditIntentActions.map((action) => ({
          ...action,
          perform: () => {
            setValue(
              action.name.endsWith('...') ? action.name.slice(0, -3).slice('Edit'.length + 1) + ' ' : action.name,
            );
            query.getInput()?.focus();
          },
        })),
      );
    } else if (lowerCasedIntent?.startsWith('delete')) {
      actionsToRegister.push(
        ...DeleteIntentActions.map((action) => ({
          ...action,
          perform: () => {
            setValue(
              action.name.endsWith('...') ? action.name.slice(0, -3).slice('Delete'.length + 1) + ' ' : action.name,
            );
            query.getInput()?.focus();
          },
        })),
      );
    } else if (lowerCasedIntent?.startsWith('analyze')) {
      actionsToRegister.push(
        ...AnalyzeIntentActions.map((action) => ({
          ...action,
          perform: () => {
            setValue(action.name);
            query.getInput()?.focus();
          },
        })),
      );
    } else if (lowerCasedIntent?.startsWith('create')) {
      actionsToRegister.push(
        ...CreateIntentActions.map((action) => ({
          ...action,
          perform: () => {
            setValue(
              action.name.endsWith('...') ? action.name.slice(0, -3).slice('Create'.length + 1) + ' ' : action.name,
            );
            query.getInput()?.focus();
          },
        })),
      );
    }
    const unregister = query.registerActions(actionsToRegister);
    unregisterSuggestions.current = unregister;
  };

  useEffect(() => {
    onSearch();
  }, [debouncedValue]);

  useEffect(() => {
    onUpdateIntents(selectedIntent);
  }, [selectedIntent, debouncedValue]);

  useEffect(() => {
    lockScroll();
    return () => {
      unlockScroll();
      setSecondViewPath('');
      query.setSearch('');
      setGptChatResponses([]);
    };
  }, []);

  const onChange = (e: { target: { value: string } }) => {
    setValue(e.target.value);
  };

  const showSecondView = !!secondViewPath || gptChatResponses.length > 0;

  // reset searchBar on close secondary view
  useEffect(() => {
    if (!showSecondView) {
      setValue('');
    }
  }, [showSecondView]);

  return (
    <div id='reach-search-container'>
      <div className=' py-2'>
        <SecondView />
        <SearchBar query={query} value={value} onChange={onChange} disabled={!userAlgoliaKey} />
      </div>

      {!showSecondView && (
        <>
          <div className='justify-center w-full px-2'>
            <Suggestions value={value} onSearch={setValue} />
          </div>
          <Results />
          <Footer />
        </>
      )}
    </div>
  );
}

export default Container;
