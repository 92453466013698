import React from 'react';
import ConditionItem from '../ConditionItem';
import { setSaveData } from '../../../rules/utils';
import SearchFilter from './SearchFilter';
import { useLegalEntities } from '../../../../hooks/http';
import { mergeLegalEntities } from '../../../templates/utils';
import { ConditionMenusProps } from './types';

export const LegalEntityCondition = ({ ruleSetData, getRuleSetData, isFirstLevel }: ConditionMenusProps) => {
  const { data: legalEntities } = useLegalEntities();
  const mergedLegalEntities = mergeLegalEntities(legalEntities);
  return (
    <ConditionItem label='Legal entity'>
      <SearchFilter
        type='legalEntity'
        data={mergedLegalEntities}
        renderItem={(item) => (
          <ConditionItem
            onClick={() => {
              setSaveData('Legal entity', item, isFirstLevel, ruleSetData, getRuleSetData);
            }}
            key={item.entityName}
            label={item.entityName}
          />
        )}
      />
    </ConditionItem>
  );
};

export default LegalEntityCondition;
