export const useGetAll = <T>(queryFn, filters, pageSize) => {
  const getAll = async () => {
    let all: T[] = [],
      perFetch: T[] = [],
      page = 0;
    do {
      perFetch = await queryFn({
        ...filters,
        pageSize,
        page,
      });
      all = all.concat(perFetch);

      page++;
    } while (perFetch.length > 0);
    return all;
  };

  return getAll;
};
