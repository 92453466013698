import { RuleDropdownDataRow } from './types';

// TODO: more types

export enum RAINCARDS_PATH {
  CATEGORY = 'Category',
  MERCHANT = 'Merchant',
  TYPE = 'Type',
}

export type RaincardsPath = {
  label: RAINCARDS_PATH;
  value: string;
};

export const RaincardsValues = Object.values(RAINCARDS_PATH);
export const RaincardsPaths = [
  { label: RAINCARDS_PATH.CATEGORY, value: '$.category' },
  { label: RAINCARDS_PATH.MERCHANT, value: '$.merchantName' },
  { label: RAINCARDS_PATH.TYPE, value: '$.type' },
];
export const RaincardsOperators = [
  { label: 'Not equal to', placeholder: '!=', showSelectedIcon: false, value: 'notEqual' },
  { label: 'Equals', placeholder: '==', showSelectedIcon: false, value: 'equal' },
  { label: 'is Credited', value: 'raincardIsCredited' },
  { label: 'is Debited', value: 'raincardIsDebited' },
];

export enum LOOP_PATH {
  SUBSCRIPTIONS = 'Subscription Name',
  TRANSFER_TYPES = 'Transfer Type',
  SUBSCRIPTION_FREQUENCY = 'Subscription Frequency',
}
export const loopPathValues = Object.values(LOOP_PATH);
export const loopPaths = [
  { label: LOOP_PATH.SUBSCRIPTIONS, value: '$.loopSubscriptionName' },
  { label: LOOP_PATH.TRANSFER_TYPES, value: '$.loopTransferType' },
  { label: LOOP_PATH.SUBSCRIPTION_FREQUENCY, value: '$.loopSubscriptionFrequency' },
];
export const loopOperators = [
  { label: 'Not equal to', placeholder: '!=', showSelectedIcon: false, value: 'notEqual' },
  { label: 'Equals', placeholder: '==', showSelectedIcon: false, value: 'equal' },
];

export const SourceData = [
  {
    label: 'Wallet',
    description: 'Description',
    values: [],
    ids: {
      operators: [
        { label: 'is Credited', value: 'isCredited' },
        { label: 'is Debited', value: 'isDebited' },
        { label: 'is Debited by', value: 'isDebitedBy' },
        { label: 'is Credited by', value: 'isCreditedBy' },
      ],
      values: [],
      valuesTwo: [],
    },
  },
  {
    label: 'Coinbase Prime',
    description: 'Description',
    values: [],
    ids: {
      operators: [
        { label: 'is Credited', value: 'coinbasePrimeIsCredited' },
        { label: 'is Debited', value: 'coinbasePrimeIsDebited' },
      ],
      values: [],
      valuesTwo: [],
    },
  },
  {
    label: 'Tag',
    description: 'Description',
    values: [],
    ids: {
      operators: [
        { label: 'is Credited', value: 'isCredited' },
        { label: 'is Debited', value: 'isDebited' },
        { label: 'is Debited by', value: 'isDebitedBy' },
        { label: 'is Credited by', value: 'isCreditedBy' },
      ],
      values: [],
      valuesTwo: [],
    },
  },
  {
    label: 'Transaction',
    description: 'Description',
    values: ['Gross Amount', 'Net Amount', 'Fee'],
    ids: {
      values: [
        { label: 'Gross Amount', value: '$.grossPrice' },
        { label: 'Net Amount', value: '$.netPrice' },
        { label: 'Fee', value: '$.feePrice' },
      ],
      operators: [
        { label: 'Greater than', placeholder: '>', showSelectedIcon: false, value: 'greaterThanBigDecimal' },
        {
          label: 'Greater than or equal to',
          placeholder: '>=',
          showSelectedIcon: false,
          value: 'greaterThanInclusiveBigDecimal',
        },
        { label: 'Less than', placeholder: '<', showSelectedIcon: false, value: 'lessThanBigDecimal' },
        {
          label: 'Less than or equal to',
          placeholder: '<=',
          showSelectedIcon: false,
          value: 'lessThanInclusiveBigDecimal',
        },
        { label: 'Not equal to', placeholder: '!=', showSelectedIcon: false, value: 'notEqualsBigDecimal' },
        { label: 'Equals', placeholder: '==', showSelectedIcon: false, value: 'equalsBigDecimal' },
      ],
    },
  },
  {
    label: 'Raincards',
    description: 'Description',
    values: ['Category', 'Merchant', 'Type'],
    ids: {
      paths: [
        { label: 'Category', value: '$.category' },
        { label: 'Merchant', value: '$.merchantName' },
        { label: 'Type', value: '$.type' },
      ],
      operators: [
        { label: 'Not equal to', placeholder: '!=', showSelectedIcon: false, value: 'notEqual' },
        { label: 'Equals', placeholder: '==', showSelectedIcon: false, value: 'equal' },
        { label: 'is Credited', value: 'raincardIsCredited' },
        { label: 'is Debited', value: 'raincardIsDebited' },
      ],
    },
  },
  {
    label: 'Gain',
    description: 'Description',
    ids: {
      values: [
        { label: 'True', value: true },
        { label: 'False', value: false },
      ],
      operators: [
        { label: 'Equals', placeholder: '==', showSelectedIcon: false, value: 'equal' },
        { label: 'Not equal to', placeholder: '!=', showSelectedIcon: false, value: 'notEqual' },
      ],
    },
  },
  {
    label: 'Loss',
    description: 'Description',
    ids: {
      values: [
        { label: 'True', value: true },
        { label: 'False', value: false },
      ],
      operators: [
        { label: 'Equals', placeholder: '==', showSelectedIcon: false, value: 'equal' },
        { label: 'Not equal to', placeholder: '!=', showSelectedIcon: false, value: 'notEqual' },
      ],
    },
  },
  {
    label: 'Asset',
    description: 'Description',
    ids: {
      values: [
        { label: 'ETH', value: 'ETH' },
        { label: 'BTC', value: 'BTC' },
      ],
      operators: [
        { label: 'Not equal to', placeholder: '!=', showSelectedIcon: false, value: 'notEqual' },
        { label: 'Equals', placeholder: '==', showSelectedIcon: false, value: 'equal' },
      ],
    },
  },
  {
    label: 'Legal entity',
    description: 'Description',
    values: ['Entity 1', 'Entity 2', 'Entity 3'],
    ids: {
      values: [
        { label: 'Entity 1', value: '0x12345' },
        { label: 'Entity 2', value: '0x1234532' },
        { label: 'Entity 3', value: '0x1234543' },
      ],
      valuesTwo: [
        { label: 'Entity 1', value: '0x12345' },
        { label: 'Entity 2', value: '0x1234532' },
        { label: 'Entity 3', value: '0x1234543' },
      ],
      operators: [{ label: 'To', value: 'to' }],
    },
  },
];

export const RuleDropdownMockData: RuleDropdownDataRow[] = [
  {
    name: 'Recents',
    data: [
      {
        label: 'Nest Condition',
        description: 'Description',
      },
    ],
  },
  {
    name: 'Sources',
    data: [
      {
        label: 'Wallet',
        description: 'Description',
        values: [],
        ids: {
          operators: [
            { label: 'is Credited', value: 'isCredited' },
            { label: 'is Debited', value: 'isDebited' },
            { label: 'is Debited by', value: 'isDebitedBy' },
            { label: 'is Credited by', value: 'isCreditedBy' },
          ],
          values: [],
          valuesTwo: [],
        },
      },
      {
        label: 'Coinbase Prime',
        description: 'Description',
        values: [],
        ids: {
          operators: [
            { label: 'is Credited', value: 'coinbasePrimeIsCredited' },
            { label: 'is Debited', value: 'coinbasePrimeIsDebited' },
          ],
          values: [],
          valuesTwo: [],
        },
      },
      {
        label: 'Tag',
        description: 'Description',
        values: [],
        ids: {
          operators: [
            { label: 'is Credited', value: 'isCredited' },
            { label: 'is Debited', value: 'isDebited' },
            { label: 'is Debited by', value: 'isDebitedBy' },
            { label: 'is Credited by', value: 'isCreditedBy' },
          ],
          values: [],
          valuesTwo: [],
        },
      },
      {
        label: 'Transaction',
        description: 'Description',
        values: ['Gross Amount', 'Net Amount', 'Fee'],
        ids: {
          values: [
            { label: 'Gross Amount', value: '$.grossPrice' },
            { label: 'Net Amount', value: '$.netPrice' },
            { label: 'Fee', value: '$.feePrice' },
          ],
          operators: [
            { label: 'Greater than', placeholder: '>', showSelectedIcon: false, value: 'greaterThanBigDecimal' },
            {
              label: 'Greater than or equal to',
              placeholder: '>=',
              showSelectedIcon: false,
              value: 'greaterThanInclusiveBigDecimal',
            },
            { label: 'Less than', placeholder: '<', showSelectedIcon: false, value: 'lessThanBigDecimal' },
            {
              label: 'Less than or equal to',
              placeholder: '<=',
              showSelectedIcon: false,
              value: 'lessThanInclusiveBigDecimal',
            },
            { label: 'Not equal to', placeholder: '!=', showSelectedIcon: false, value: 'notEqualsBigDecimal' },
            { label: 'Equals', placeholder: '==', showSelectedIcon: false, value: 'equalsBigDecimal' },
          ],
        },
      },
      {
        label: 'Raincards',
        description: 'Description',
        values: ['Category', 'Merchant', 'Type'],
        ids: {
          paths: [
            { label: 'Category', value: '$.category' },
            { label: 'Merchant', value: '$.merchantName' },
            { label: 'Type', value: '$.type' },
          ],
          operators: [
            { label: 'Not equal to', placeholder: '!=', showSelectedIcon: false, value: 'notEqual' },
            { label: 'Equals', placeholder: '==', showSelectedIcon: false, value: 'equal' },
            { label: 'is Credited', value: 'raincardIsCredited' },
            { label: 'is Debited', value: 'raincardIsDebited' },
          ],
        },
      },
      {
        label: 'Gain',
        description: 'Description',
        ids: {
          values: [
            { label: 'True', value: true },
            { label: 'False', value: false },
          ],
          operators: [
            { label: 'Equals', placeholder: '==', showSelectedIcon: false, value: 'equal' },
            { label: 'Not equal to', placeholder: '!=', showSelectedIcon: false, value: 'notEqual' },
          ],
        },
      },
      {
        label: 'Loss',
        description: 'Description',
        ids: {
          values: [
            { label: 'True', value: true },
            { label: 'False', value: false },
          ],
          operators: [
            { label: 'Equals', placeholder: '==', showSelectedIcon: false, value: 'equal' },
            { label: 'Not equal to', placeholder: '!=', showSelectedIcon: false, value: 'notEqual' },
          ],
        },
      },
      {
        label: 'Asset',
        description: 'Description',
        ids: {
          values: [
            { label: 'ETH', value: 'ETH' },
            { label: 'BTC', value: 'BTC' },
          ],
          operators: [
            { label: 'Not equal to', placeholder: '!=', showSelectedIcon: false, value: 'notEqual' },
            { label: 'Equals', placeholder: '==', showSelectedIcon: false, value: 'equal' },
          ],
        },
      },
      {
        label: 'Legal entity',
        description: 'Description',
        values: ['Entity 1', 'Entity 2', 'Entity 3'],
        ids: {
          values: [
            { label: 'Entity 1', value: '0x12345' },
            { label: 'Entity 2', value: '0x1234532' },
            { label: 'Entity 3', value: '0x1234543' },
          ],
          valuesTwo: [
            { label: 'Entity 1', value: '0x12345' },
            { label: 'Entity 2', value: '0x1234532' },
            { label: 'Entity 3', value: '0x1234543' },
          ],
          operators: [{ label: 'To', value: 'to' }],
        },
      },
    ],
  },
];

export const Operators = [
  {
    label: 'Greater than',
    placeholder: '>',
    showSelectedIcon: false,
    value: 'greaterThanBigDecimal',
  },
  {
    label: 'Greater than or equal to',
    placeholder: '>=',
    showSelectedIcon: false,
    value: 'greaterThanInclusiveBigDecimal',
  },
  {
    label: 'Less than',
    placeholder: '<',
    showSelectedIcon: false,
    value: 'lessThanBigDecimal',
  },
  {
    label: 'Less than or equal to',
    placeholder: '<=',
    showSelectedIcon: false,
    value: 'lessThanInclusiveBigDecimal',
  },
  {
    label: 'Not equal to',
    placeholder: '!=',
    showSelectedIcon: false,
    value: 'notEqualsBigDecimal',
  },
  {
    label: 'Equals',
    placeholder: '==',
    showSelectedIcon: false,
    value: 'equalsBigDecimal',
  },
];

export const TimestampsData = [
  {
    Name: 'Legal entities',
    Data: 'Legal entity, legal entity',
  },
  {
    Name: 'Created at',
    Data: 'Mon Sep 19 at 1:20pm',
  },
  {
    Name: 'Updated at',
    Data: 'Tue Sep 20 at 3:00pm',
  },
  {
    Name: 'Created by',
    Data: 'John Doe',
  },
  {
    Name: 'Update by',
    Data: 'Jane Smith',
  },
];

export const EQUAL_NOTEQUAL_OPERATORS = [
  {
    label: 'Not equal to',
    placeholder: '!=',
    showSelectedIcon: false,
    value: 'notEqual',
  },
  {
    label: 'Equals',
    placeholder: '==',
    showSelectedIcon: false,
    value: 'equal',
  },
];

export const RAINCARD_DEBIT_CREDIT_OPERATORS = [
  {
    label: 'is Credited',
    value: 'raincardIsCredited',
  },
  {
    label: 'is Debited',
    value: 'raincardIsDebited',
  },
];

export const GREATER_THAN_AND_EQUAL_OPERATORS = [
  {
    label: 'Greater than',
    placeholder: '>',
    showSelectedIcon: false,
    value: 'greaterThan',
  },
  {
    label: 'Greater than or equal to',
    placeholder: '>=',
    showSelectedIcon: false,
    value: 'greaterThanInclusive',
  },
  {
    label: 'Equals',
    placeholder: '==',
    showSelectedIcon: false,
    value: 'equal',
  },
];

export const GREATER_LESS_OPERATORS = [
  {
    label: 'Greater than',
    placeholder: '>',
    showSelectedIcon: false,
    value: 'greaterThan',
  },
  {
    label: 'Greater than or equal to',
    placeholder: '>=',
    showSelectedIcon: false,
    value: 'greaterThanInclusive',
  },
  {
    label: 'Less than',
    placeholder: '<',
    showSelectedIcon: false,
    value: 'lessThan',
  },
  {
    label: 'Less than or equal to',
    placeholder: '<=',
    showSelectedIcon: false,
    value: 'lessThanInclusive',
  },
];

// there are leafs rows, and sub menu triggers

// sub menu triggers just push into the ConditionConfiguratorContext
// back button pops from ConditionConfiguratorContext
// ConditionConfiguratorMenuTrigger
// ConditionConfiguratorLeaf
// ConditionConfiguratorMenu
