import { useContext } from 'react';
import { JobProgressContext } from '../context/JobProgressProvider';
import { useSession } from './useSession';
import { JOB_NAME } from 'services/http/job-queue';
import { useTriggerManualTransactionHandler } from './http';
import { toast } from 'react-hot-toast';

export const useCreateRunAllRulesetsBgJob = (onSuccess?: () => void, onError?: (error: any) => void) => {
  const { startTrackingJob } = useContext(JobProgressContext);
  const { organizationId, userId } = useSession();

  const { mutateAsync } = useTriggerManualTransactionHandler();

  const createAllRuleSetsJob = async ({
    legalEntityIds,
    accountingPeriodIds,
    processTransactionsWithNoAccounting,
    overWrite,
  }) => {
    try {
      const response = await mutateAsync({
        organizationId,
        userId,
        legalEntityIds,
        accountingPeriodIds,
        processTransactionsWithNoAccounting,
        overWrite,
      });
      startTrackingJob(organizationId, JOB_NAME.ORGANIZATION_RULESET_JOB, false);

      if (response.data.message === 'no transaction found') {
        toast.error('No transactions found in the selected accounting period');
        throw new Error('no transaction found');
      }

      if (onSuccess) onSuccess();
    } catch (error) {
      if (onError) onError(error);
      else throw error;
    }
  };

  return createAllRuleSetsJob;
};
