import React, { useMemo } from 'react';
import { ConditionsGroup } from '../ConditionsGroup';
import { ConditionSelectorRow } from '../ConditionSelectorRow';
import { FACT } from '../../types';
import { HEDGEY_EVENTS } from '../../../../atoms/NewConditionSelector/ConditionsComponents';
import { useAddCondition } from '../useAddCondition';
import { useFilteredConditions } from '../utils';
import { CSRowProps, ConditionGroupFilterConfig, ConvertToRowFn, FilterFn } from '../types';
import { Virtuoso } from 'react-virtuoso';

type HedgeyEvent = {
  label: string;
  value: string;
};

const filterHedgeyEventRowsFn: FilterFn<HedgeyEvent> = (searchTerm) => (event) =>
  event.label?.toLowerCase().includes(searchTerm.toLowerCase());
const convertHedgeyEventToRowFn: ConvertToRowFn<HedgeyEvent> = (event) => ({
  rowData: {
    value: event.value,
    label: event.label,
  },
  isSelected: false,
});

export const useHedgeyConditionGroup = (): Omit<ConditionGroupFilterConfig, 'rows'> =>
  useMemo(
    () => ({
      heading: 'Hedgey',
      data: HEDGEY_EVENTS,
      filterFn: filterHedgeyEventRowsFn,
      convertToRowFn: convertHedgeyEventToRowFn,
      Row: HedgeyConditionSelectorRow,
    }),
    [],
  );

export const HedgeyConditionSelectorRow = ({ conditionData }: CSRowProps) => {
  const addCondition = useAddCondition();

  return (
    <ConditionSelectorRow
      rowData={conditionData.rowData}
      onClick={() =>
        addCondition({
          fact: FACT.HEDGEY,
          value: conditionData.rowData.value,
          operator: 'equal',
        })
      }
    />
  );
};

export const HedgeyConditionsMenu = () => {
  const conditionsData = useFilteredConditions(HEDGEY_EVENTS, filterHedgeyEventRowsFn, convertHedgeyEventToRowFn);

  return (
    <ConditionsGroup label='Hedgey'>
      {conditionsData.length === 0 && <div className='text-center'>No hedgey conditions to show</div>}
      {conditionsData.length > 0 && (
        <Virtuoso
          style={{ height: 250 }}
          data={conditionsData}
          itemContent={(index, data) => <HedgeyConditionSelectorRow key={index} conditionData={data} />}
        />
      )}
    </ConditionsGroup>
  );
};
