import { produce } from 'immer';
import { RuleConfiguratorState } from '../../types';

export const updateCondition = (state: RuleConfiguratorState, action) =>
  produce(state, (draft) => {
    draft.conditions[action.payload.id] = {
      ...draft.conditions[action.payload.id],
      ...action.payload.update,
    };
  });
