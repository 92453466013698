import { useQuery } from '@tanstack/react-query';
import { getIncomeStatement } from 'services/http/core';
import { useSession } from '../useSession';
import { QUERY_KEY, useOrgBasedQueryKey } from '../useInvalidateQuery';

export const useIncomeStatement = (legalEntityIds?: string[], accountingPeriodIds?: string[], tagId?: string) => {
  const { organizationId } = useSession();
  const getKey = useOrgBasedQueryKey();

  return useQuery(
    getKey(QUERY_KEY.INCOME_STATEMENT, { legalEntityIds, accountingPeriodIds, tagId }),
    async () => {
      const response = await getIncomeStatement({
        organizationId,
        legalEntityIds,
        accountingPeriodIds,
        tagId,
      });
      const { incomeStatement } = response.data;
      return incomeStatement;
    },
    {
      enabled: !!organizationId,
    },
  );
};
