import { CheckboxListItem, DropdownGroup, DropdownGroupBody, DropdownGroupTitle } from 'ui';

export const WalletTypesFilterRowGroup = ({ state, onSingleChange, onClearAll }) => {
  if (!state) return null;
  return (
    <DropdownGroup>
      <DropdownGroupTitle title='Types' />
      <DropdownGroupBody>
        {state.ids.length > 1 ? (
          state.ids
            .map((_id) => state.entities[_id])
            .map((wallet) => (
              <CheckboxListItem
                key={wallet._id}
                label={wallet.value}
                checked={wallet.selected}
                onCheckedChange={(selected: boolean) => onSingleChange({ ...wallet, selected })}
                selectOnlyFn={() => {
                  onClearAll();
                  onSingleChange({ ...wallet, selected: true });
                }}
              />
            ))
        ) : (
          <p className='text-zinc-900 text-sm px-2'>No types to show.</p>
        )}
      </DropdownGroupBody>
    </DropdownGroup>
  );
};
