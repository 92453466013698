import React, { useState } from 'react';
import { TableWrapper } from '../../dashboard';
import { Table } from '../../dashboard';
import { SearchInput } from 'ui';
import { EditColumns } from '../../atoms/filter';
import { TableHeaderProps } from '../../dashboard/types';
import { mapTableKeyToOriginalKey } from '../../utils';
import { TABLE_TYPE } from '../../../context';

export const SpamTransactionsTable = ({
  setSearchTerm,
  searchTerm,
  isSearching,
  TransactionsHeaderData,
  displayedTransactions,
  fetchNextPage,
  setSortState,
  isLoading,
  isFetchingNextPage,
}) => {
  const [tableHeader, setTableHeader] = useState<TableHeaderProps>(TransactionsHeaderData);
  /**
   * used to know when the hideFn on the table headers has been called.
   * It also updates the EditColumns preventing an infinite loop
   * if we were to use tableHeader state prop instead
   */
  const [hasTableStateChanged, setHasTableStateChanged] = useState(false);
  // const { openSidebar } = useContext(SidebarGlobalContext);

  return (
    <TableWrapper>
      <Table
        data-cy='transactions'
        tableTopActions={
          <div className='flex items-center justify-between gap-3 md:gap-0 px-3 py-2'>
            <div className='flex items-center gap-3'>
              <SearchInput
                data-cy='transactions_searchTermInput'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                loading={isSearching}
                placeholder='Search transactions...'
                className='placeholder:text-zinc-500 '
                containerClassName=' min-w-0 '
                resultCount={displayedTransactions?.length}
              />
            </div>
            <div className='flex gap-3'>
              <EditColumns
                tableHeader={TransactionsHeaderData}
                tableHeaderState={tableHeader}
                hasTableStateChanged={hasTableStateChanged}
                getFilteredTableHeader={(filteredTableHeader) => {
                  setTableHeader(filteredTableHeader);
                }}
              />
            </div>
          </div>
        }
        tableData={{
          Data: displayedTransactions,
        }}
        useInViewCallback={() => {
          fetchNextPage();
        }}
        // onRowClick={(row) => {
        //   openSidebar('transactions', { id: row.original._id });
        // }}
        onSortingChange={(state) => {
          if (state.length < 1) {
            setSortState([]);
          } else {
            setSortState([{ id: mapTableKeyToOriginalKey(state[0].id, 'Transactions'), desc: state[0].desc }]);
          }
        }}
        tableHeader={tableHeader}
        isLoading={isLoading}
        isFetchingNextPage={isFetchingNextPage}
        tableType={TABLE_TYPE.SPAM_TRANSACTIONS}
        getFilteredTableHeader={(filteredTableHeader) => {
          setTableHeader(filteredTableHeader);
        }}
        canHideColumns
        setHasTableStateChanged={setHasTableStateChanged}
      />
    </TableWrapper>
  );
};
