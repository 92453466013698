import { Badge } from 'ui';
import { optionsData } from '../DropDownCheckbox/DropDownCheckboxComponents/type';
import { ColumnStructureProps, SwitchDataProps } from './types';
import { parse } from 'date-fns';
import { AccountingPeriod } from 'services/http/response.types';
import { FilterRowState } from '../../filters';
import { TableHeaderProps } from '../../dashboard/types';

export const columnStructure: ColumnStructureProps = {
  fixedLeft: {
    name: 'Fixed left',
    items: [],
  },
  scrolling: {
    name: 'Scrolling',
    items: [],
  },
  fixedRight: {
    name: 'Fixed right',
    items: [],
  },
};

export const onDragEnd = (result, columns, setColumns) => {
  if (!result.destination) return;
  const { source, destination } = result;

  if (source.droppableId !== destination.droppableId) {
    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];
    const [removed] = sourceItems.splice(source.index, 1);
    if (typeof removed === 'object') removed.type = destination.droppableId;
    destItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...sourceColumn,
        items: sourceItems,
      },
      [destination.droppableId]: {
        ...destColumn,
        items: destItems,
      },
    });
  } else {
    const column = columns[source.droppableId];
    const copiedItems = [...column.items];
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...column,
        items: copiedItems,
      },
    });
  }
};

export const headerConverter = (data) => {
  const convert = data?.map(({ header }, index) => {
    return { label: header, labelLeft: false, checked: true, locked: false, id: `${index}`, type: 'scrolling' };
  });
  return convert;
};

export const dataConvertToTabelHeader = (switchData: SwitchDataProps, tableHeader) => {
  const saveTableHeader: TableHeaderProps = [];
  switchData.map((switchItems) => {
    tableHeader.map((items) => {
      if (items.header === switchItems.label && switchItems?.checked) {
        items.locked = switchItems.locked;
        items.type = switchItems.type;
        saveTableHeader.push(items);
      }
    });
  });
  return saveTableHeader;
};

export const columnConvertToData = (columns: ColumnStructureProps) => {
  const saveColumnItem: SwitchDataProps = [];
  Object.entries(columns).map(([, column]) => {
    saveColumnItem.push(...column.items);
  });
  return saveColumnItem;
};

export const dropdownCheckboxCheckItem = (items: optionsData, optionsData: optionsData) => {
  if (items.length === Number(optionsData?.length) - 1) {
    optionsData[0].checked = true;
  } else {
    optionsData[0].checked = false;
  }
  if (items.length) {
    // Check if the items are in the optionsData.
    for (let x = 0; optionsData.length > x; x++) {
      for (let i = 0; items.length > i; i++) {
        if (optionsData[x].value === items[i].value) {
          // If optionsData contains an item, set the optionsData checked to true.
          optionsData[x].checked = true;
          break;
        } else if (optionsData[x].value !== items[i].value && i === items.length - 1) {
          optionsData[x].checked = false;
        }
      }
    }
  } else {
    optionsData.map((item) => {
      item.checked = false;
    });
  }
  return optionsData;
};

export const getNumberOfSelectedFilters = (allFilter) => {
  let num = 0;
  for (const filterItems in allFilter) {
    if (Array.isArray(allFilter[filterItems]) && allFilter[filterItems].length) {
      for (const item of allFilter[filterItems]) {
        if (item.checked) {
          num++;
          break;
        }
      }
    }
  }
  if (num) {
    return <Badge className='!bg-blue-500'>{num}</Badge>;
  }
};

export const sortAscAccountingPeriodFilterRowsByLabels = (a: AccountingPeriod, b: AccountingPeriod) => {
  if (!a.accountingPeriodName || !b.accountingPeriodName) return 0;
  return (
    parse(a.accountingPeriodName, 'MMM yyyy', new Date()).getTime() -
    parse(b.accountingPeriodName, 'MMM yyyy', new Date()).getTime()
  );
};

export const sortDescAccountingPeriodFilterRowsByLabels = (a: AccountingPeriod, b: AccountingPeriod) => {
  if (!a.accountingPeriodName || !b.accountingPeriodName) return 0;
  return (
    parse(b.accountingPeriodName, 'MMM yyyy', new Date()).getTime() -
    parse(a.accountingPeriodName, 'MMM yyyy', new Date()).getTime()
  );
};

export const perpareDefaultAccountingPeriodsFilterState = (
  accountingPeriods: AccountingPeriod[],
): FilterRowState<AccountingPeriod>[] =>
  accountingPeriods
    .sort(sortDescAccountingPeriodFilterRowsByLabels)
    .map((period, i) => ({ ...period, selected: i < 3 }));

export const perpareYTDAccountingPeriodsFilterState = (
  accountingPeriods: AccountingPeriod[],
): FilterRowState<AccountingPeriod>[] =>
  accountingPeriods
    .sort(sortDescAccountingPeriodFilterRowsByLabels)
    .map((period) => ({ ...period, selected: new Date(period.startDate).getFullYear() === new Date().getFullYear() }));
