import React from 'react';
import ConditionItem from '../ConditionItem';
import { setSaveData } from '../../../rules/utils';
import SearchFilter from './SearchFilter';
import { ConditionMenusProps } from './types';
import { useGetRaincardSources } from '../../../../hooks/http/useRaincards';

const FIRST_LEVEL_OPTIONS = ['Category', 'Merchant', 'Type', 'Sources'];

export const RaincardsCondition = ({ ruleSetData, getRuleSetData, isFirstLevel }: ConditionMenusProps) => {
  const { data: raincards } = useGetRaincardSources({ legalEntityId: '' });
  const nameAndIds = raincards?.map((card) => ({ name: card.cardData.nickname, id: card._id })) || [];
  const options = [...FIRST_LEVEL_OPTIONS];
  return (
    <ConditionItem label='Raincards'>
      <SearchFilter
        type='raincards'
        data={options}
        renderItem={(item) => {
          if (item === 'Category' || item === 'Merchant' || item === 'Type') {
            return (
              <ConditionItem
                onClick={() => {
                  setSaveData('Raincards', item, isFirstLevel, ruleSetData, getRuleSetData);
                }}
                key={item}
                label={item}
              />
            );
          } else if (item === 'Sources') {
            // this falls under the Raincards fact in the backend but its just for ease of showing the right operator
            return (
              <ConditionItem key={item} label='Sources'>
                <SearchFilter
                  data={nameAndIds ?? []}
                  type={'raincards'}
                  renderItem={(item) => (
                    <ConditionItem
                      key={item}
                      onClick={() => {
                        setSaveData('Raincards Source', item.id, isFirstLevel, ruleSetData, getRuleSetData);
                      }}
                      label={item.name}
                    />
                  )}
                />
              </ConditionItem>
            );
          }
        }}
      />
    </ConditionItem>
  );
};

export default RaincardsCondition;
