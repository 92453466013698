import { InfiniteData } from '@tanstack/react-query';
import { dateConverter } from '../../utils';
import { DisplayedTagType, TagType } from './types';

export const handleDisplayedTags = (tags: InfiniteData<TagType[]> | undefined) => {
  let mergedTags: TagType[] = [];
  let displayedTags: DisplayedTagType[] = [];

  if (tags && tags.pages?.length) {
    tags.pages.forEach((page) => {
      mergedTags = [...mergedTags, ...page];
    });
  }

  displayedTags = mergedTags.map((tag) => ({
    tag: { name: `${tag.entry.key}: ${tag.entry.value}`, usage: tag.usageCount },
    organizationId: typeof tag.entry.organizationId === 'string' ? tag.entry.organizationId : '',
    status: tag.status,
    created: {
      title: tag?.createdBy?.email,
      desc: dateConverter(tag.createdAt ?? ''),
    },
    createdById: tag?.createdBy?._id,
    _id: tag._id,
    key: tag.entry.key,
    value: tag.entry.value,
  }));

  return displayedTags;
};
