import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ButtonProps, Dialog, DialogContent, DialogTrigger } from 'ui';
import { DESCRIPTIONS, TITLES } from '../close-period-wizard/constants';
import { useAccountingPeriods } from '../../../../hooks/http';
import { deriveError, getDisplayedAccountingPeriods, mergeAccountingPeriods } from '../../../templates/utils';
import { Button, SimpleMenu } from 'ui';
import { ExchangeSourceType, GLType } from 'services/http/types';
import { toast } from 'react-hot-toast';
import { JOB_NAME } from 'services';
import TrackJobInDialog from '../../../job-progress/TrackJobInDialog';
import { BG_JOB_STATE } from '../../../job-progress/types';
import { useCreateSyncJournalEntriesJob } from './useCreateSyncJournalEntriesJob';
import { JobProgressContext } from '../../../../context/JobProgressProvider';

export enum SYNC_JOURNAL_ENTRIES_PROCESS_STATE {
  FORM = 'form',
  PROCESSING = 'processing',
}

export const SyncJournalEntryWizard = ({
  label = 'Sync',
  clientType,
  exchangeSourceId,
  accountingPeriodFilter,
  realmId,
}: {
  label?: string;
  clientType: GLType | ExchangeSourceType;
  exchangeSourceId?: string;
  accountingPeriodFilter: 'Closed' | 'Open';
  realmId?: string /** @todo since realmId must be selected for QBO we can no longer sync when a period is closed unless we sync them all */;
}) => {
  const { onTrackerClose } = useContext(JobProgressContext);
  const [showSyncJournalEntryWizard, setShowSyncJournalEntryWizard] = useState(false);
  const [selectedAccountingPeriod, setSelectedAccountingPeriod] = useState({ label: '', value: '' });

  const { data: accountingPeriodsData, isLoading } = useAccountingPeriods({
    pageSize: 1000,
    status: accountingPeriodFilter,
  });
  const merged = mergeAccountingPeriods(accountingPeriodsData);
  const displayedRes = getDisplayedAccountingPeriods(merged) || [];

  const isExchange = useMemo(
    () =>
      [ExchangeSourceType.KRAKEN, ExchangeSourceType.CIRCLE, ExchangeSourceType.COINBASE_PRIME].includes(
        clientType as ExchangeSourceType,
      ),
    [clientType],
  );

  const [referenceId, setReferenceId] = useState<string>();

  useEffect(() => {
    setReferenceId(isExchange ? exchangeSourceId : selectedAccountingPeriod.value);
  }, [isExchange, selectedAccountingPeriod, exchangeSourceId]);

  const title = isExchange ? TITLES[3] : TITLES[2];
  const description = isExchange ? DESCRIPTIONS[3] : DESCRIPTIONS[2];

  const [error, setError] = useState<string>();

  const { createJob, bgJobState, setBgJobState } = useCreateSyncJournalEntriesJob({ realmId });

  const [processState, setProcessState] = useState<SYNC_JOURNAL_ENTRIES_PROCESS_STATE>(
    SYNC_JOURNAL_ENTRIES_PROCESS_STATE.FORM,
  );

  useEffect(() => {
    if (!showSyncJournalEntryWizard) {
      setProcessState(SYNC_JOURNAL_ENTRIES_PROCESS_STATE.FORM);
      setBgJobState(BG_JOB_STATE.PENDING_CREATE);
      setSelectedAccountingPeriod({ label: '', value: '' });
    }
  }, [showSyncJournalEntryWizard]);

  const onClose = () => {
    onTrackerClose(bgJobState, JOB_NAME.SYNC_JOURNAL_ENTRY_JOB, referenceId);
    setShowSyncJournalEntryWizard(false);
  };

  const onSync = async () => {
    try {
      setProcessState(SYNC_JOURNAL_ENTRIES_PROCESS_STATE.PROCESSING);
      if (!referenceId) return toast.error('Please select an accounting period');
      await createJob(referenceId, clientType);
    } catch (e) {
      console.log('CreateSyncBgJob error:', e);
      setError(deriveError(e));
      toast.error(deriveError(e));
    }
  };

  const actions: ButtonProps[] =
    processState === SYNC_JOURNAL_ENTRIES_PROCESS_STATE.FORM
      ? [
          { label: 'Sync', variant: 'sm', onClick: onSync },
          {
            label: 'Cancel',
            variant: 'sm',
            emphasis: 'medium',
            onClick: onClose,
          },
        ]
      : [
          {
            label: 'Hide',
            variant: 'sm',
            onClick: onClose,
          },
        ];

  return (
    <Dialog open={showSyncJournalEntryWizard} onOpenChange={setShowSyncJournalEntryWizard}>
      <DialogTrigger asChild>
        <Button onClick={() => setShowSyncJournalEntryWizard(true)} label={label} variant='sm' className='mr-2' />
      </DialogTrigger>
      {processState === SYNC_JOURNAL_ENTRIES_PROCESS_STATE.FORM ? (
        <DialogContent title={title} description={description} actions={actions} onClose={onClose}>
          <div>
            <SimpleMenu
              onChange={(value) => {
                setSelectedAccountingPeriod(value);
              }}
              options={displayedRes.filter(({ label }) => !label.includes('Closed'))}
              placeholder='Select periods'
              selectedValue={selectedAccountingPeriod?.value}
              isLoading={isLoading}
            />
          </div>
        </DialogContent>
      ) : (
        <TrackJobInDialog
          jobName={isExchange ? JOB_NAME.IMPORT_EXCHANGE_SOURCE_JOB : JOB_NAME.SYNC_JOURNAL_ENTRY_JOB}
          referenceId={referenceId}
          onClose={onClose}
          bgJobState={bgJobState}
          setBgJobState={setBgJobState}
          error={error}
        />
      )}
    </Dialog>
  );
};
