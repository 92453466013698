import React, { useEffect, useState } from 'react';
import { Condition } from '../types';
import { Button, Dropdown, DropdownBody, DropdownContent, DropdownTrigger, classNames } from 'ui';
import RuleCondition from './RuleCondition';
import { MdMoreHoriz } from 'react-icons/md';
import { useRuleConfigurator } from '../rule-configurator-context';
// import { MdClose } from 'react-icons/md';

export const ConditionComponent = ({ conditionId }) => {
  const {
    state,
    isDisabled,
    getConditionById,
    removeCondition,
    duplicate,
    // getParentCountByChildId
  } = useRuleConfigurator();
  const [condition, setCondition] = useState<Condition>(getConditionById(conditionId));
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    setCondition(getConditionById(conditionId));
  }, [state, conditionId]);

  if (!condition) return null;

  return (
    <div
      className={classNames(
        'grid grid-cols-1 gap-2 p-2 rounded-lg',
        // getParentCountByChildId(conditionId) % 2 ? 'bg-[#F2F2F2]' : 'bg-white',
      )}
    >
      <div className={classNames('flex items-center justify-between gap-x-2 rounded-lg')}>
        <RuleCondition conditionId={conditionId} />
        {!isDisabled && (
          <Dropdown open={dropdownOpen} onOpenChange={setDropdownOpen}>
            <DropdownTrigger asChild>
              <Button leadingIcon={<MdMoreHoriz />} emphasis='low' />
            </DropdownTrigger>
            <DropdownContent className='z-sidepanel w-fit' align='end'>
              <DropdownBody className='w-full z-30 p-1'>
                <ul className='flex flex-col gap-1 w-full'>
                  <li>
                    <Button
                      variant='sm'
                      onClick={() => {
                        setDropdownOpen(false);
                        duplicate(conditionId, condition.parentId);
                      }}
                      label='Duplicate'
                      className='w-full rounded-md px-3'
                      emphasis='low'
                      disableRingOnFocus
                    />
                  </li>
                  <li>
                    <Button
                      variant='xs'
                      onClick={() => {
                        setDropdownOpen(false);
                        removeCondition(conditionId);
                      }}
                      label='Remove'
                      className='w-full rounded-md px-3'
                      emphasis='low'
                      status='danger'
                      disableRingOnFocus
                    />
                  </li>
                </ul>
              </DropdownBody>
            </DropdownContent>
          </Dropdown>
        )}
      </div>
    </div>
  );
};
